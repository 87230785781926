import React from 'react'

import { IMainItem, IItem } from 'config/types'
// import { useTranslation } from 'react-i18next'
// import { useHistory } from 'react-router'
// import { PATH_BRANDING } from 'config/paths'
import MenuItem from '../menu-item/MenuItem'

import './MenuPannel.scss'

interface IMenuPannel {
    menuItems: IMainItem[] | IItem[]
    subcategories?: boolean
}

const MenuPannel = ({
    menuItems,
    subcategories,
}: IMenuPannel): React.ReactElement => (
    <div
        className={`menu-pannel ${
            subcategories ? 'menu-pannel--subcategories' : ''
        }`}
    >
        <ul className='menu-pannel__list'>
            {menuItems.map(item => (
                <MenuItem key={item.key} menuItem={item} />
            ))}
        </ul>
    </div>
)

export default MenuPannel
