import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import moment from 'moment'
import { RootState } from 'redux/reducers/rootReducer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import './BlogItem.scss'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

// eslint-disable-next-line max-len
import { fetchBlogAction } from 'containers/dashboard/features/blog/store/actions'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

type PageParams = {
    id: string
}

const BlogItem = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast'])
    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { id } = useParams<PageParams>()
    const { blog } = useSelector((state: RootState) => state.blog)

    useEffect(() => {
        if (!appId) return
        if (!id) return
        dispatch(
            fetchBlogAction({
                blogId: id,
                appId,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    return (
        <>
            {blog ? (
                <div className='m-blog-item'>
                    {blog.imageUrl ||
                    (!!blog.images && blog.images?.length < 2) ? (
                        <CustomImage
                            src={
                                blog.imageUrl ||
                                (blog?.images?.length ? blog.images[0] : '')
                            }
                        />
                    ) : (
                        <Swiper
                            className='m-blog-item_slider'
                            spaceBetween={0}
                            slidesPerView={1}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                        >
                            {blog.images?.map(image => (
                                <SwiperSlide key={image}>
                                    <CustomImage src={image} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    )}

                    <div className='m-blog-item__info new-loader--surface'>
                        <div className='m-blog-item__title new-loader--text'>
                            {blog.title}
                        </div>
                        <div className='m-blog-item__date new-loader--text'>
                            {blog.publicationDate
                                ? moment(blog.publicationDate).format(
                                      'DD/MM/yyyy',
                                  )
                                : moment(Date.now()).format('DD/MM/yyyy')}
                        </div>
                        <div className='m-blog-item__desc new-loader--text'>
                            {blog.description ? parse(blog.description) : null}
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default BlogItem
