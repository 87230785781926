import React from 'react'

export const Phone = (): React.ReactElement => (
    <svg
        width='330'
        height='681'
        viewBox='0 0 330 681'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='new-loader__iphone'
    >
        <path
            d='M279.091 0H51.0856C24.1086 0 2.24023 22.2353 2.24023 49.6649V631.245C2.24023 658.674 24.1086 680.91 51.0856 680.91H279.091C306.068 680.91 327.936 658.674 327.936 631.245V49.6649C327.936 22.2353 306.068 0 279.091 0V0ZM315.658 633.705C315.658 649.744 298.808 667.241 283.393 667.241H46.8733C31.4579 667.241 14.6084 649.653 14.6084 633.705V46.6576C14.6084 30.6191 31.4579 13.1225 46.8733 13.1225H76.1806C77.3457 13.1225 78.3315 14.0337 78.3315 15.2184V18.8635C78.3315 24.5135 83.2609 36.8158 97.9594 36.8158H233.382C246.647 36.8158 252.383 24.6957 252.383 18.499V15.2184C252.383 14.1249 253.368 13.1225 254.534 13.1225H283.303C298.719 13.1225 315.568 30.7102 315.568 46.6576V633.705H315.658Z'
            fill='#737373'
        />
        <path
            d='M276.76 3.64429H53.5047C27.0654 3.64429 5.64514 25.6062 5.64514 52.7624V628.146C5.64514 655.302 27.0654 677.264 53.5047 677.264H276.76C303.199 677.264 324.619 655.211 324.619 628.146V52.8535C324.619 25.6973 303.199 3.73542 276.76 3.73542V3.64429ZM315.657 633.705C315.657 649.743 298.807 667.24 283.392 667.24H46.8725C31.4571 667.24 14.6076 649.652 14.6076 633.705V46.6568C14.6076 30.6182 31.4571 13.1216 46.8725 13.1216H76.1798C77.3449 13.1216 78.3307 14.0329 78.3307 15.2176V18.8627C78.3307 24.5126 83.2601 36.815 97.9586 36.815H233.381C246.646 36.815 252.382 24.6949 252.382 18.4982V15.2176C252.382 14.124 253.368 13.1216 254.533 13.1216H283.302C298.718 13.1216 315.567 30.7094 315.567 46.6568V633.705H315.657Z'
            fill='#0D0D0D'
        />
        <path
            opacity='0.6'
            d='M298.273 9.11124C300.604 10.2048 302.755 11.4806 304.816 12.9386C311.448 15.3991 316.736 19.3176 321.128 25.2409C318.081 19.9555 314.047 15.3991 309.297 11.5717C305.802 9.38463 302.665 8.74673 298.273 9.11124Z'
            fill='url(#paint0_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M326.683 334.804V50.3022C326.683 41.1894 324.174 32.6234 319.872 25.242C315.48 19.3187 310.192 15.4002 303.56 12.9397C315.211 21.1412 322.919 34.8105 322.919 50.3022V334.896H326.683V334.804Z'
            fill='url(#paint1_linear_3054_8681)'
        />
        <path
            opacity='0.5'
            d='M32.4467 9.11124C30.1164 10.2048 27.9655 11.4806 25.9041 12.9386C19.2719 15.3991 13.984 19.3176 9.59241 25.2409C12.6396 19.9555 16.6728 15.3991 21.4229 11.5717C24.9182 9.38463 28.0551 8.74673 32.4467 9.11124Z'
            fill='url(#paint2_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M4.03479 333.893V50.302C4.03479 41.1892 6.5443 32.6231 10.8463 25.2418C15.2379 19.3184 20.5257 15.3999 27.158 12.9395C15.5068 21.141 7.79904 34.8102 7.79904 50.302V333.984H4.03479V333.893Z'
            fill='url(#paint3_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M278.374 1.18384H52.2513C41.0482 1.18384 30.7414 5.10235 22.5856 11.5725C26.0809 9.38537 29.2178 8.74748 33.6094 9.11199C39.2558 6.46927 45.6191 4.92009 52.2513 4.92009H278.374C285.007 4.92009 291.37 6.46927 297.016 9.11199C301.408 8.74748 304.545 9.38537 308.04 11.5725C299.884 5.01122 289.577 1.18384 278.374 1.18384V1.18384Z'
            fill='url(#paint4_linear_3054_8681)'
        />
        <path
            d='M298.182 671.798C300.512 670.705 302.663 669.429 304.725 667.971C311.357 665.511 316.645 661.592 321.036 655.669C317.989 660.954 313.956 665.511 309.206 669.338C305.71 671.525 302.574 672.163 298.182 671.798Z'
            fill='url(#paint5_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M326.683 334.716V630.7C326.683 639.813 324.174 648.379 319.872 655.761C315.48 661.684 310.192 665.602 303.56 668.063C315.211 659.861 322.919 646.192 322.919 630.7V334.716H326.683Z'
            fill='url(#paint6_linear_3054_8681)'
        />
        <path
            opacity='0.5'
            d='M32.7141 671.798C30.3839 670.705 28.2329 669.429 26.1715 667.971C19.5393 665.511 14.2515 661.592 9.85986 655.669C12.9071 660.954 16.9402 665.511 21.6903 669.338C25.1857 671.525 28.3225 672.163 32.7141 671.798Z'
            fill='url(#paint7_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M4.03479 333.713V630.699C4.03479 639.812 6.5443 648.378 10.8463 655.76C15.2379 661.683 20.5257 665.601 27.158 668.062C15.5068 659.86 7.79904 646.191 7.79904 630.699V333.713H4.03479V333.713Z'
            fill='url(#paint8_linear_3054_8681)'
        />
        <path
            opacity='0.3'
            d='M278.374 679.818H52.2513C41.0482 679.818 30.7414 675.9 22.5856 669.43C26.0809 671.617 29.2178 672.255 33.6094 671.89C39.2558 674.533 45.6191 676.082 52.2513 676.082H278.374C285.007 676.082 291.37 674.533 297.016 671.89C301.408 672.255 304.545 671.617 308.04 669.43C299.884 675.991 289.577 679.818 278.374 679.818V679.818Z'
            fill='url(#paint9_linear_3054_8681)'
        />
        <path
            opacity='0.5'
            d='M279.27 674.621H52.0715C28.0521 674.621 8.60352 654.846 8.60352 630.424V49.7551C8.60352 25.515 27.9624 5.8313 51.8026 5.8313H278.643C321.842 5.8313 322.2 51.2131 322.2 50.1196V631.062C322.2 655.12 303.021 674.621 279.36 674.621H279.27ZM51.5337 7.38048C28.6794 7.38048 10.1271 26.244 10.1271 49.4817V630.424C10.1271 654.026 28.9483 673.163 52.1611 673.163H279.36C302.214 673.163 320.677 654.299 320.677 631.153V50.1196C320.677 26.5174 301.855 7.38048 278.643 7.38048H51.5337Z'
            fill='url(#paint10_linear_3054_8681)'
        />
        <g opacity='0.6'>
            <path
                d='M191.259 20.3209C191.259 20.3209 190.99 19.7741 190.811 19.5007L190.632 19.683L191.259 20.3209Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.313 22.5999L148.506 23.3289L149.134 23.9668H149.492L150.03 23.3289L149.313 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M147.88 23.9695H148.239L148.06 23.7854L147.88 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M186.615 21.4089L185.853 22.1843L186.615 22.9589L187.377 22.1835L186.615 21.4089Z'
                fill='#1A1A1A'
            />
            <path
                d='M184.134 21.4045L183.372 22.1794L184.134 22.9544L184.896 22.1795L184.134 21.4045Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.48 20.1137L167.718 20.8887L168.48 21.6636L169.242 20.8887L168.48 20.1137Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.361 19.0463C190.361 19.0463 189.734 18.5906 189.376 18.4995L190.093 19.2285L190.272 19.0463H190.361Z'
                fill='#1A1A1A'
            />
            <path
                d='M154.064 22.5999L153.347 23.3289L153.884 23.9668H154.243L154.87 23.3289L154.064 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.893 22.5999L146.087 23.3289L146.714 23.9668H147.072L147.61 23.3289L146.893 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.301 23.9695H150.659L150.48 23.7854L150.301 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.76 22.5999L187.043 23.3289L187.581 23.9668H187.94L188.567 23.3289L187.76 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M152.716 23.9695H153.075L152.896 23.7854L152.716 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.99 22.5093C190.99 22.5093 191.349 21.7803 191.349 21.4158L190.632 22.1448L190.99 22.5093V22.5093Z'
                fill='#1A1A1A'
            />
            <path
                d='M189.376 23.3289L189.734 23.6934C189.734 23.6934 190.361 23.3289 190.63 23.0555L190.182 22.5999L189.376 23.42V23.3289Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.643 22.5999L150.926 23.3289L151.464 23.9668H151.822L152.45 23.3289L151.643 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.909 20.1046L170.146 20.8796L170.909 21.6546L171.671 20.8796L170.909 20.1046Z'
                fill='#1A1A1A'
            />
            <path
                d='M180.589 19.2282L181.306 18.4992L181.217 18.3169H179.872L179.783 18.4992L180.589 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.51 21.4372L173.748 22.2122L174.51 22.9871L175.273 22.2122L174.51 21.4372Z'
                fill='#1A1A1A'
            />
            <path
                d='M163.688 20.0673L162.926 20.8423L163.688 21.6173L164.45 20.8423L163.688 20.0673Z'
                fill='#1A1A1A'
            />
            <path
                d='M142.053 22.5999L141.246 23.4201L141.784 23.9668C141.784 23.9668 141.874 23.9668 141.963 23.9668H142.143L142.77 23.3289L141.963 22.5088L142.053 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M176.94 21.4284L176.177 22.2034L176.94 22.9783L177.702 22.2034L176.94 21.4284Z'
                fill='#1A1A1A'
            />
            <path
                d='M171.359 22.1451L172.165 22.9653L172.882 22.1451L172.165 21.325L171.359 22.1451Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.76 19.2282L188.567 18.4992L188.477 18.3169H187.133L187.043 18.4992L187.76 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.808 21.4035L140.047 22.1794L140.81 22.9535L141.571 22.1775L140.808 21.4035Z'
                fill='#1A1A1A'
            />
            <path
                d='M178.17 19.2282L178.976 18.4992L178.797 18.3169H177.453L177.363 18.4992L178.17 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.473 22.5999L143.666 23.3289L144.294 23.9668H144.652L145.28 23.3289L144.473 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M181.74 21.3067L180.978 22.0813L181.739 22.8567L182.502 22.0821L181.74 21.3067Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.34 19.2282L186.147 18.4992L186.057 18.3169H184.713L184.623 18.4992L185.34 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.051 20.1225L165.289 20.8975L166.051 21.6724L166.814 20.8974L166.051 20.1225Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.46 23.9695H145.819L145.64 23.7854L145.46 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M178.619 22.1451L179.336 22.9653L180.143 22.1451L179.336 21.325L178.619 22.1451Z'
                fill='#1A1A1A'
            />
            <path
                d='M183.01 19.2282L183.727 18.4992L183.637 18.3169H182.293L182.203 18.4992L183.01 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M143.128 23.9695H143.398L143.218 23.7854L143.128 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M183.998 23.9695H184.356L184.177 23.7854L183.998 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M173.334 22.5999L172.527 23.3289L173.155 23.9668H173.513L174.14 23.3289L173.334 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M180.589 21.6883L181.306 20.9593L180.589 20.1392L179.783 20.9593L180.589 21.6883Z'
                fill='#1A1A1A'
            />
            <path
                d='M162.309 23.9695H162.668L162.488 23.7854L162.309 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M171.989 23.9695H172.348L172.168 23.7854L171.989 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.675 20.0895L174.912 20.8645L175.675 21.6395L176.437 20.8645L175.675 20.0895Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.754 22.5999L174.947 23.3289L175.575 23.9668H175.933L176.561 23.3289L175.754 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.409 23.9695H174.679L174.589 23.7854L174.409 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M186.417 23.9695H186.776L186.596 23.7854L186.417 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.161 22.5999L165.355 23.3289L165.982 23.9668H166.341L166.878 23.3289L166.161 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M182.895 20.1294L182.133 20.9043L182.895 21.6793L183.658 20.9043L182.895 20.1294Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.149 23.9695H167.508L167.328 23.7854L167.149 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.492 22.5999L167.775 23.3289L168.312 23.9668H168.671L169.298 23.3289L168.492 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M178.104 20.0807L177.342 20.8557L178.104 21.6307L178.866 20.8557L178.104 20.0807Z'
                fill='#1A1A1A'
            />
            <path
                d='M163.741 22.5999L162.934 23.3289L163.562 23.9668H163.92L164.458 23.3289L163.741 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.912 22.5999L170.195 23.3289L170.733 23.9668H171.091L171.718 23.3289L170.912 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.569 23.9695H169.928L169.748 23.7854L169.569 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.321 22.5999L160.515 23.3289L161.142 23.9668H161.501L162.128 23.3289L161.321 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.481 22.5999L155.674 23.3289L156.302 23.9668H156.66L157.288 23.3289L156.481 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M183.01 22.5999L182.203 23.3289L182.741 23.9668H183.189L183.727 23.3289L183.01 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M175.754 19.2282L176.561 18.4992L176.381 18.3169H175.127L174.947 18.4992L175.754 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M173.281 20.0811L172.519 20.8557L173.28 21.6311L174.043 20.8565L173.281 20.0811Z'
                fill='#1A1A1A'
            />
            <path
                d='M176.737 23.9695H177.096L176.916 23.7854L176.737 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.76 21.6883L188.567 20.9593L187.76 20.1392L187.043 20.9593L187.76 21.6883Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.34 22.5999L184.623 23.3289L185.161 23.9668H185.519L186.147 23.3289L185.34 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M181.577 23.9695H181.936L181.756 23.7854L181.577 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.136 23.9695H155.495L155.315 23.7854L155.136 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M178.17 22.5999L177.363 23.3289L177.99 23.9668H178.349L178.976 23.3289L178.17 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M158.901 22.5999L158.094 23.3289L158.722 23.9668H159.08L159.708 23.3289L158.901 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M159.889 23.9695H160.247L160.068 23.7854L159.889 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.557 23.9695H157.827L157.737 23.7854L157.557 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M180.589 22.5999L179.783 23.3289L180.41 23.9668H180.769L181.306 23.3289L180.589 22.5999Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.324 20.1203L184.562 20.8953L185.324 21.6702L186.086 20.8952L185.324 20.1203Z'
                fill='#1A1A1A'
            />
            <path
                d='M179.157 23.9695H179.516L179.337 23.7854L179.157 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.729 23.9695H165.088L164.908 23.7854L164.729 23.9695Z'
                fill='#1A1A1A'
            />
            <path
                d='M141.987 20.0783L141.224 20.8533L141.987 21.6282L142.749 20.8533L141.987 20.0783Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.68 21.4093L144.918 22.1843L145.68 22.9593L146.442 22.1843L145.68 21.4093Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.741 18.902L168.978 19.6765L169.74 20.4519L170.502 19.6774L169.741 18.902Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.643 19.2282L152.45 18.4992L152.36 18.3169H151.016L150.926 18.4992L151.643 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.321 18.9733L166.558 19.7473L167.319 20.5232L168.082 19.7492L167.321 18.9733Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.893 19.2282L147.61 18.4992L147.521 18.3169H146.176L146.087 18.4992L146.893 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.944 18.9365L164.182 19.7114L164.944 20.4864L165.706 19.7115L164.944 18.9365Z'
                fill='#1A1A1A'
            />
            <path
                d='M143.251 21.4177L142.489 22.1926L143.251 22.9676L144.013 22.1927L143.251 21.4177Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.313 19.2282L150.03 18.4992L149.94 18.3169H148.596L148.506 18.4992L149.313 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.415 20.0695L143.653 20.8445L144.415 21.6195L145.178 20.8445L144.415 20.0695Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.481 19.2282L157.288 18.4992L157.108 18.3169H155.854L155.674 18.4992L156.481 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M160.086 18.9545L159.324 19.7295L160.086 20.5045L160.849 19.7295L160.086 18.9545Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.511 21.4194L149.749 22.1948L150.512 22.9694L151.274 22.194L150.511 21.4194Z'
                fill='#1A1A1A'
            />
            <path
                d='M154.064 19.2282L154.87 18.4992L154.781 18.3169H153.436L153.347 18.4992L154.064 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M162.515 18.9455L161.753 19.7205L162.515 20.4954L163.278 19.7204L162.515 18.9455Z'
                fill='#1A1A1A'
            />
            <path
                d='M148.017 21.4023L147.255 22.1772L148.017 22.9522L148.779 22.1772L148.017 21.4023Z'
                fill='#1A1A1A'
            />
            <path
                d='M181.788 18.9433L181.026 19.7183L181.788 20.4932L182.551 19.7182L181.788 18.9433Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.182 20.1023L189.419 20.8772L190.182 21.6522L190.944 20.8772L190.182 20.1023Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.44 20.9593L139.633 20.1392L139.364 20.4125C139.364 20.4125 139.185 20.9593 139.185 21.2327C139.185 21.2327 139.185 21.3238 139.185 21.415L139.544 21.7795L140.35 20.9593H140.44Z'
                fill='#1A1A1A'
            />
            <path
                d='M186.589 18.9132L185.827 19.6877L186.588 20.4631L187.351 19.6885L186.589 18.9132Z'
                fill='#1A1A1A'
            />
            <path
                d='M188.983 18.9193L188.22 19.6943L188.983 20.4693L189.745 19.6943L188.983 18.9193Z'
                fill='#1A1A1A'
            />
            <path
                d='M189.018 22.9653L189.735 22.1451L189.018 21.325L188.212 22.1451L189.018 22.9653Z'
                fill='#1A1A1A'
            />
            <path
                d='M139.811 22.6912C139.811 22.6912 140.169 23.1489 140.438 23.332L139.811 22.6912Z'
                fill='#1A1A1A'
            />
            <path
                d='M172.165 20.5048L172.882 19.6847L172.165 18.8645L171.359 19.6847L172.165 20.5048Z'
                fill='#1A1A1A'
            />
            <path
                d='M184.179 18.9025L183.416 19.6765L184.177 20.4524L184.94 19.6784L184.179 18.9025Z'
                fill='#1A1A1A'
            />
            <path
                d='M144.473 19.2282L145.28 18.4992L145.1 18.3169H143.846L143.666 18.4992L144.473 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M176.98 19.0136L176.216 19.7876L176.978 20.5635L177.741 19.7895L176.98 19.0136Z'
                fill='#1A1A1A'
            />
            <path
                d='M174.559 18.9873L173.797 19.7627L174.559 20.5373L175.321 19.7619L174.559 18.9873Z'
                fill='#1A1A1A'
            />
            <path
                d='M152.875 21.3844L152.113 22.1594L152.875 22.9344L153.637 22.1594L152.875 21.3844Z'
                fill='#1A1A1A'
            />
            <path
                d='M179.303 18.9369L178.54 19.7114L179.302 20.4868L180.065 19.7123L179.303 18.9369Z'
                fill='#1A1A1A'
            />
            <path
                d='M142.862 18.4998H142.055C142.055 18.4998 141.607 18.4998 141.338 18.4998L142.055 19.2288L142.862 18.4087V18.4998Z'
                fill='#1A1A1A'
            />
            <path
                d='M166.161 19.2282L166.878 18.4992L166.789 18.3169H165.444L165.355 18.4992L166.161 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M148.008 18.9318L147.246 19.7068L148.008 20.4818L148.77 19.7068L148.008 18.9318Z'
                fill='#1A1A1A'
            />
            <path
                d='M164.099 22.1451L164.905 22.9653L165.712 22.1451L164.905 21.325L164.099 22.1451Z'
                fill='#1A1A1A'
            />
            <path
                d='M158.799 20.0461L158.038 20.822L158.801 21.596L159.562 20.8202L158.799 20.0461Z'
                fill='#1A1A1A'
            />
            <path
                d='M150.437 18.9237L149.675 19.6987L150.437 20.4737L151.199 19.6987L150.437 18.9237Z'
                fill='#1A1A1A'
            />
            <path
                d='M162.524 21.4154L161.762 22.1904L162.524 22.9654L163.286 22.1904L162.524 21.4154Z'
                fill='#1A1A1A'
            />
            <path
                d='M170.912 19.2282L171.718 18.4992L171.629 18.3169H170.284L170.195 18.4992L170.912 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M168.492 19.2282L169.298 18.4992L169.209 18.3169H167.864L167.775 18.4992L168.492 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M156.494 20.0912L155.732 20.8662L156.494 21.6412L157.256 20.8662L156.494 20.0912Z'
                fill='#1A1A1A'
            />
            <path
                d='M145.649 18.97L144.887 19.7454L145.65 20.5199L146.412 19.7445L145.649 18.97Z'
                fill='#1A1A1A'
            />
            <path
                d='M143.242 18.9477L142.48 19.7227L143.242 20.4976L144.005 19.7227L143.242 18.9477Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.744 18.9263L139.982 19.7009L140.743 20.4763L141.506 19.7017L140.744 18.9263Z'
                fill='#1A1A1A'
            />
            <path
                d='M169.719 21.3906L168.957 22.1655L169.719 22.9405L170.481 22.1656L169.719 21.3906Z'
                fill='#1A1A1A'
            />
            <path
                d='M167.321 21.3778L166.558 22.1523L167.32 22.9277L168.082 22.1531L167.321 21.3778Z'
                fill='#1A1A1A'
            />
            <path
                d='M173.334 19.2282L174.14 18.4992L173.961 18.3169H172.707L172.527 18.4992L173.334 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.259 20.0761L160.497 20.8511L161.259 21.626L162.021 20.8511L161.259 20.0761Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.689 18.9977L156.926 19.7717L157.687 20.5477L158.45 19.7736L157.689 18.9977Z'
                fill='#1A1A1A'
            />
            <path
                d='M151.614 20.1333L150.852 20.9087L151.615 21.6833L152.376 20.9079L151.614 20.1333Z'
                fill='#1A1A1A'
            />
            <path
                d='M163.741 19.2282L164.458 18.4992L164.368 18.3169H163.024L162.934 18.4992L163.741 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M161.321 19.2282L162.128 18.4992L161.949 18.3169H160.694L160.515 18.4992L161.321 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.295 18.9084L154.532 19.6833L155.295 20.4583L156.057 19.6834L155.295 18.9084Z'
                fill='#1A1A1A'
            />
            <path
                d='M149.142 20.09L148.38 20.8645L149.141 21.6399L149.904 20.8653L149.142 20.09Z'
                fill='#1A1A1A'
            />
            <path
                d='M152.865 18.9147L152.103 19.6897L152.865 20.4647L153.628 19.6897L152.865 18.9147Z'
                fill='#1A1A1A'
            />
            <path
                d='M157.667 21.4303L156.904 22.2053L157.667 22.9803L158.429 22.2053L157.667 21.4303Z'
                fill='#1A1A1A'
            />
            <path
                d='M154.065 20.1001L153.303 20.875L154.065 21.65L154.827 20.875L154.065 20.1001Z'
                fill='#1A1A1A'
            />
            <path
                d='M146.844 20.0605L146.082 20.8354L146.844 21.6104L147.606 20.8354L146.844 20.0605Z'
                fill='#1A1A1A'
            />
            <path
                d='M158.901 19.2282L159.708 18.4992L159.528 18.3169H158.274L158.094 18.4992L158.901 19.2282Z'
                fill='#1A1A1A'
            />
            <path
                d='M159.351 22.1451L160.068 22.9653L160.874 22.1451L160.068 21.325L159.351 22.1451Z'
                fill='#1A1A1A'
            />
            <path
                d='M155.303 21.3754L154.541 22.1504L155.303 22.9254L156.065 22.1504L155.303 21.3754Z'
                fill='#1A1A1A'
            />
            <path
                d='M140.888 22.9637L140.082 22.1436L139.723 22.5081V22.6903L140.44 23.3282L140.888 22.8726V22.9637Z'
                fill='#262626'
            />
            <path
                d='M139.272 21.3249C139.272 21.3249 139.272 21.6911 139.362 21.8742L139.631 21.5996L139.272 21.2334V21.3249Z'
                fill='#262626'
            />
            <path
                d='M140.888 23.6958C140.888 23.6958 141.515 23.8788 141.784 23.8788L141.246 23.3296L140.888 23.6958V23.6958Z'
                fill='#262626'
            />
            <path
                d='M143.22 23.7873L143.399 23.9704H144.296L143.668 23.3296L143.22 23.7873Z'
                fill='#262626'
            />
            <path
                d='M142.496 22.1766L142.052 22.6287L142.814 23.4036L143.259 22.9516L142.496 22.1766Z'
                fill='#262626'
            />
            <path
                d='M140.124 19.6512L139.68 20.1033L140.442 20.8782L140.886 20.4262L140.124 19.6512Z'
                fill='#262626'
            />
            <path
                d='M141.271 20.869L140.826 21.3206L141.588 22.0963L142.033 21.6448L141.271 20.869Z'
                fill='#262626'
            />
            <path
                d='M142.487 19.7071L142.043 20.1592L142.805 20.9342L143.249 20.4821L142.487 19.7071Z'
                fill='#262626'
            />
            <path
                d='M144.925 22.1676L144.48 22.6196L145.242 23.3946L145.687 22.9425L144.925 22.1676Z'
                fill='#262626'
            />
            <path
                d='M145.64 23.7873L145.819 23.9704H146.715L146.088 23.3296L145.64 23.7873Z'
                fill='#262626'
            />
            <path
                d='M143.682 20.8909L143.237 21.3428L143.999 22.1182L144.444 21.6663L143.682 20.8909Z'
                fill='#262626'
            />
            <path
                d='M141.607 19.6842L142.055 19.2285L141.338 18.4995L140.979 18.864L141.786 19.6842H141.607Z'
                fill='#262626'
            />
            <path
                d='M148.06 23.7873L148.24 23.9704H149.136L148.508 23.3296L148.06 23.7873Z'
                fill='#262626'
            />
            <path
                d='M144.894 19.7271L144.45 20.1794L145.212 20.954L145.657 20.5017L144.894 19.7271Z'
                fill='#262626'
            />
            <path
                d='M147.354 22.1585L146.909 22.6106L147.671 23.3856L148.116 22.9335L147.354 22.1585Z'
                fill='#262626'
            />
            <path
                d='M146.133 20.9096L145.688 21.3608L146.449 22.1372L146.894 21.6859L146.133 20.9096Z'
                fill='#262626'
            />
            <path
                d='M143.656 18.4498L143.211 18.9019L143.973 19.6768L144.418 19.2248L143.656 18.4498Z'
                fill='#262626'
            />
            <path
                d='M142.952 18.3169H142.682L142.862 18.501L142.952 18.3169Z'
                fill='#262626'
            />
            <path
                d='M150.476 23.7873L150.655 23.9704H151.462L150.924 23.3296L150.476 23.7873Z'
                fill='#262626'
            />
            <path
                d='M146.15 18.4696L145.705 18.9219L146.468 19.6964L146.912 19.2441L146.15 18.4696Z'
                fill='#262626'
            />
            <path
                d='M149.783 22.1497L149.338 22.6018L150.1 23.3768L150.545 22.9247L149.783 22.1497Z'
                fill='#262626'
            />
            <path
                d='M147.345 19.6915L146.9 20.1436L147.663 20.9185L148.107 20.4665L147.345 19.6915Z'
                fill='#262626'
            />
            <path
                d='M148.54 20.8751L148.095 21.3269L148.857 22.1023L149.301 21.6505L148.54 20.8751Z'
                fill='#262626'
            />
            <path
                d='M145.372 18.3169H145.102L145.282 18.501L145.372 18.3169Z'
                fill='#262626'
            />
            <path
                d='M148.509 18.4317L148.065 18.8838L148.827 19.6588L149.271 19.2067L148.509 18.4317Z'
                fill='#262626'
            />
            <path
                d='M152.207 22.1429L151.762 22.595L152.525 23.3699L152.969 22.9179L152.207 22.1429Z'
                fill='#262626'
            />
            <path
                d='M150.908 20.9887L150.463 21.4402L151.224 22.2161L151.669 21.7646L150.908 20.9887Z'
                fill='#262626'
            />
            <path
                d='M152.896 23.7873L153.075 23.9704H153.882L153.344 23.3296L152.896 23.7873Z'
                fill='#262626'
            />
            <path
                d='M147.792 18.3169H147.522L147.612 18.501L147.792 18.3169Z'
                fill='#262626'
            />
            <path
                d='M149.747 19.7088L149.303 20.1611L150.066 20.9357L150.51 20.4834L149.747 19.7088Z'
                fill='#262626'
            />
            <path
                d='M150.899 18.4613L150.454 18.9131L151.216 19.6885L151.66 19.2366L150.899 18.4613Z'
                fill='#262626'
            />
            <path
                d='M154.548 22.1339L154.103 22.5859L154.865 23.3609L155.31 22.9088L154.548 22.1339Z'
                fill='#262626'
            />
            <path
                d='M150.212 18.3169H149.942L150.032 18.501L150.212 18.3169Z'
                fill='#262626'
            />
            <path
                d='M153.244 20.9239L152.799 21.3757L153.561 22.1511L154.006 21.6993L153.244 20.9239Z'
                fill='#262626'
            />
            <path
                d='M152.111 19.6737L151.666 20.1257L152.428 20.9007L152.873 20.4486L152.111 19.6737Z'
                fill='#262626'
            />
            <path
                d='M155.316 23.7873L155.495 23.9704H156.302L155.674 23.3296L155.316 23.7873Z'
                fill='#262626'
            />
            <path
                d='M155.673 20.9171L155.228 21.3689L155.99 22.1443L156.435 21.6924L155.673 20.9171Z'
                fill='#262626'
            />
            <path
                d='M153.301 18.4808L152.856 18.9329L153.619 19.7078L154.063 19.2558L153.301 18.4808Z'
                fill='#262626'
            />
            <path
                d='M156.932 22.1436L156.484 22.5992L157.291 23.3282L157.739 22.9637L156.932 22.1436Z'
                fill='#262626'
            />
            <path
                d='M157.736 23.7873L157.825 23.9704H158.722L158.094 23.3296L157.736 23.7873Z'
                fill='#262626'
            />
            <path
                d='M152.632 18.3169H152.362L152.452 18.501L152.632 18.3169Z'
                fill='#262626'
            />
            <path
                d='M154.539 19.6649L154.094 20.1169L154.857 20.8919L155.301 20.4399L154.539 19.6649Z'
                fill='#262626'
            />
            <path
                d='M159.34 22.181L158.896 22.6331L159.658 23.408L160.103 22.956L159.34 22.181Z'
                fill='#262626'
            />
            <path
                d='M158.167 20.9327L157.722 21.3848L158.485 22.1597L158.929 21.7077L158.167 20.9327Z'
                fill='#262626'
            />
            <path
                d='M160.068 23.7873L160.248 23.9704H161.144L160.517 23.3296L160.068 23.7873Z'
                fill='#262626'
            />
            <path
                d='M154.962 18.3169H154.782L154.872 18.501L154.962 18.3169Z'
                fill='#262626'
            />
            <path
                d='M155.703 18.4964L155.259 18.9487L156.021 19.7233L156.466 19.271L155.703 18.4964Z'
                fill='#262626'
            />
            <path
                d='M157.739 20.5035L156.932 19.6833L156.484 20.139L157.291 20.9591L157.739 20.5035Z'
                fill='#262626'
            />
            <path
                d='M159.358 19.6898L158.913 20.1416L159.675 20.917L160.12 20.4652L159.358 19.6898Z'
                fill='#262626'
            />
            <path
                d='M160.531 20.8106L160.086 21.2625L160.848 22.0378L161.292 21.586L160.531 20.8106Z'
                fill='#262626'
            />
            <path
                d='M157.38 18.3169H157.11L157.29 18.501L157.38 18.3169Z'
                fill='#262626'
            />
            <path
                d='M158.158 18.463L157.714 18.915L158.476 19.69L158.921 19.2379L158.158 18.463Z'
                fill='#262626'
            />
            <path
                d='M162.488 23.7873L162.667 23.9704H163.564L162.936 23.3296L162.488 23.7873Z'
                fill='#262626'
            />
            <path
                d='M161.769 22.1743L161.324 22.6265L162.086 23.4014L162.531 22.9493L161.769 22.1743Z'
                fill='#262626'
            />
            <path
                d='M159.8 18.3169H159.53L159.71 18.501L159.8 18.3169Z'
                fill='#262626'
            />
            <path
                d='M161.76 19.7046L161.315 20.1567L162.077 20.9317L162.522 20.4796L161.76 19.7046Z'
                fill='#262626'
            />
            <path
                d='M162.959 20.8038L162.515 21.2556L163.276 22.031L163.721 21.5792L162.959 20.8038Z'
                fill='#262626'
            />
            <path
                d='M164.908 23.7873L165.088 23.9704H165.984L165.356 23.3296L164.908 23.7873Z'
                fill='#262626'
            />
            <path
                d='M160.587 18.4566L160.143 18.9087L160.905 19.6837L161.349 19.2316L160.587 18.4566Z'
                fill='#262626'
            />
            <path
                d='M164.198 22.1656L163.753 22.6177L164.515 23.3926L164.96 22.9406L164.198 22.1656Z'
                fill='#262626'
            />
            <path
                d='M167.328 23.7873L167.508 23.9704H168.314L167.776 23.3296L167.328 23.7873Z'
                fill='#262626'
            />
            <path
                d='M165.336 20.9242L164.891 21.3757L165.652 22.1517L166.097 21.7001L165.336 20.9242Z'
                fill='#262626'
            />
            <path
                d='M162.22 18.3169H161.95L162.13 18.501L162.22 18.3169Z'
                fill='#262626'
            />
            <path
                d='M164.909 20.5035L164.103 19.6833L163.744 20.139L164.461 20.9591L164.909 20.5035Z'
                fill='#262626'
            />
            <path
                d='M162.924 18.4476L162.48 18.8997L163.242 19.6746L163.686 19.2226L162.924 18.4476Z'
                fill='#262626'
            />
            <path
                d='M166.592 22.1168L166.147 22.5684L166.908 23.3441L167.353 22.8926L166.592 22.1168Z'
                fill='#262626'
            />
            <path
                d='M169.748 23.7873L169.928 23.9704H170.734L170.197 23.3296L169.748 23.7873Z'
                fill='#262626'
            />
            <path
                d='M164.641 18.3169H164.37L164.461 18.501L164.641 18.3169Z'
                fill='#262626'
            />
            <path
                d='M166.557 19.6668L166.112 20.1187L166.874 20.894L167.319 20.4422L166.557 19.6668Z'
                fill='#262626'
            />
            <path
                d='M167.786 20.9837L167.342 21.436L168.104 22.2106L168.549 21.7583L167.786 20.9837Z'
                fill='#262626'
            />
            <path
                d='M168.94 22.1436L168.492 22.5992L169.299 23.3282L169.747 22.9637L168.94 22.1436Z'
                fill='#262626'
            />
            <path
                d='M165.292 18.5035L164.847 18.9556L165.609 19.7305L166.054 19.2785L165.292 18.5035Z'
                fill='#262626'
            />
            <path
                d='M172.168 23.7873L172.348 23.9704H173.154L172.527 23.3296L172.168 23.7873Z'
                fill='#262626'
            />
            <path
                d='M167.06 18.3169H166.79L166.88 18.501L167.06 18.3169Z'
                fill='#262626'
            />
            <path
                d='M167.716 18.4942L167.271 18.9463L168.034 19.7213L168.478 19.2692L167.716 18.4942Z'
                fill='#262626'
            />
            <path
                d='M169.748 21.3245L170.555 22.1446L170.914 21.689L170.197 20.96L169.748 21.3245Z'
                fill='#262626'
            />
            <path
                d='M171.361 22.1436L170.912 22.5992L171.719 23.3282L172.167 22.9637L171.361 22.1436Z'
                fill='#262626'
            />
            <path
                d='M169.747 20.5035L168.94 19.6833L168.492 20.139L169.299 20.9591L169.747 20.5035Z'
                fill='#262626'
            />
            <path
                d='M170.914 19.2285L170.197 18.4995L169.748 18.864L170.555 19.6842L170.914 19.2285Z'
                fill='#262626'
            />
            <path
                d='M173.817 22.1614L173.372 22.613L174.133 23.3888L174.578 22.9372L173.817 22.1614Z'
                fill='#262626'
            />
            <path
                d='M174.589 23.7873L174.678 23.9704H175.574L174.947 23.3296L174.589 23.7873Z'
                fill='#262626'
            />
            <path
                d='M169.39 18.3169H169.21L169.3 18.501L169.39 18.3169Z'
                fill='#262626'
            />
            <path
                d='M172.526 20.8375L172.081 21.2893L172.843 22.0647L173.288 21.6129L172.526 20.8375Z'
                fill='#262626'
            />
            <path
                d='M171.428 19.7189L170.983 20.1704L171.744 20.9463L172.189 20.4948L171.428 19.7189Z'
                fill='#262626'
            />
            <path
                d='M173.333 19.2285L172.527 18.4995L172.168 18.864L172.885 19.6842L173.333 19.2285Z'
                fill='#262626'
            />
            <path
                d='M171.81 18.3169H171.63L171.72 18.501L171.81 18.3169Z'
                fill='#262626'
            />
            <path
                d='M176.201 22.1436L175.753 22.5992L176.559 23.3282L176.918 22.9637L176.201 22.1436Z'
                fill='#262626'
            />
            <path
                d='M173.747 19.7274L173.302 20.1794L174.064 20.9544L174.509 20.5024L173.747 19.7274Z'
                fill='#262626'
            />
            <path
                d='M175.055 20.9003L174.61 21.3518L175.372 22.1277L175.817 21.6762L175.055 20.9003Z'
                fill='#262626'
            />
            <path
                d='M176.917 23.7873L177.096 23.9704H177.992L177.365 23.3296L176.917 23.7873Z'
                fill='#262626'
            />
            <path
                d='M178.613 22.1788L178.168 22.6309L178.93 23.4058L179.375 22.9538L178.613 22.1788Z'
                fill='#262626'
            />
            <path
                d='M176.918 20.5035L176.201 19.6833L175.753 20.139L176.559 20.9591L176.918 20.5035Z'
                fill='#262626'
            />
            <path
                d='M179.337 23.7873L179.516 23.9704H180.412L179.785 23.3296L179.337 23.7873Z'
                fill='#262626'
            />
            <path
                d='M175.002 18.4698L174.558 18.9219L175.32 19.6968L175.765 19.2448L175.002 18.4698Z'
                fill='#262626'
            />
            <path
                d='M176.917 21.3245L177.723 22.1446L178.171 21.689L177.365 20.96L176.917 21.3245Z'
                fill='#262626'
            />
            <path
                d='M174.228 18.3169H173.958L174.138 18.501L174.228 18.3169Z'
                fill='#262626'
            />
            <path
                d='M177.392 18.421L176.947 18.8726L177.709 19.6485L178.154 19.1969L177.392 18.421Z'
                fill='#262626'
            />
            <path
                d='M181.757 23.7873L181.936 23.9704H182.742L182.205 23.3296L181.757 23.7873Z'
                fill='#262626'
            />
            <path
                d='M176.649 18.3169H176.379L176.559 18.501L176.649 18.3169Z'
                fill='#262626'
            />
            <path
                d='M178.653 19.765L178.208 20.2166L178.969 20.9925L179.414 20.541L178.653 19.765Z'
                fill='#262626'
            />
            <path
                d='M180.951 22.1436L180.593 22.5992L181.31 23.3282L181.758 22.9637L180.951 22.1436Z'
                fill='#262626'
            />
            <path
                d='M179.337 21.3245L180.144 22.1446L180.592 21.689L179.785 20.96L179.337 21.3245Z'
                fill='#262626'
            />
            <path
                d='M181.758 20.5035L180.951 19.6833L180.593 20.139L181.31 20.9591L181.758 20.5035Z'
                fill='#262626'
            />
            <path
                d='M183.371 22.1436L183.013 22.5992L183.73 23.3282L184.178 22.9637L183.371 22.1436Z'
                fill='#262626'
            />
            <path
                d='M179.807 18.4004L179.363 18.853L180.126 19.627L180.57 19.1744L179.807 18.4004Z'
                fill='#262626'
            />
            <path
                d='M184.177 23.7873L184.356 23.9704H185.163L184.625 23.3296L184.177 23.7873Z'
                fill='#262626'
            />
            <path
                d='M179.068 18.3169H178.798L178.978 18.501L179.068 18.3169Z'
                fill='#262626'
            />
            <path
                d='M181.757 21.3245L182.563 22.1446L183.011 21.689L182.205 20.96L181.757 21.3245Z'
                fill='#262626'
            />
            <path
                d='M183.011 19.2285L182.205 18.4995L181.757 18.864L182.563 19.6842L183.011 19.2285Z'
                fill='#262626'
            />
            <path
                d='M185.842 22.1131L185.398 22.5662L186.162 23.3395L186.605 22.8865L185.842 22.1131Z'
                fill='#262626'
            />
            <path
                d='M184.177 21.3245L184.984 22.1446L185.342 21.689L184.625 20.96L184.177 21.3245Z'
                fill='#262626'
            />
            <path
                d='M181.489 18.3169H181.219L181.309 18.501L181.489 18.3169Z'
                fill='#262626'
            />
            <path
                d='M186.597 23.7873L186.776 23.9704H187.583L187.045 23.3296L186.597 23.7873Z'
                fill='#262626'
            />
            <path
                d='M184.178 20.5035L183.371 19.6833L183.013 20.139L183.73 20.9591L184.178 20.5035Z'
                fill='#262626'
            />
            <path
                d='M183.909 18.3169H183.638L183.728 18.501L183.909 18.3169Z'
                fill='#262626'
            />
            <path
                d='M185.342 19.2285L184.625 18.4995L184.177 18.864L184.984 19.6842L185.342 19.2285Z'
                fill='#262626'
            />
            <path
                d='M185.886 19.6847L185.442 20.1367L186.204 20.9117L186.648 20.4596L185.886 19.6847Z'
                fill='#262626'
            />
            <path
                d='M188.249 22.1693L187.805 22.6218L188.568 23.396L189.012 22.9434L188.249 22.1693Z'
                fill='#262626'
            />
            <path
                d='M189.015 23.7866C189.015 23.7866 189.463 23.7866 189.732 23.7866L189.373 23.4204L188.925 23.8781L189.015 23.7866Z'
                fill='#262626'
            />
            <path
                d='M187.05 20.9725L186.606 21.4248L187.368 22.1994L187.813 21.7471L187.05 20.9725Z'
                fill='#262626'
            />
            <path
                d='M186.239 18.3169H186.059L186.149 18.501L186.239 18.3169Z'
                fill='#262626'
            />
            <path
                d='M190.181 22.5992L190.629 23.0548C190.629 23.0548 190.898 22.6903 190.988 22.5081L190.629 22.1436L190.181 22.5992Z'
                fill='#262626'
            />
            <path
                d='M187.041 18.5052L186.597 18.9575L187.359 19.7321L187.804 19.2798L187.041 18.5052Z'
                fill='#262626'
            />
            <path
                d='M188.279 19.6893L187.835 20.1416L188.598 20.9162L189.042 20.4639L188.279 19.6893Z'
                fill='#262626'
            />
            <path
                d='M189.017 21.3245L189.734 22.1446L190.182 21.689L189.375 20.96L189.017 21.3245Z'
                fill='#262626'
            />
            <path
                d='M190.181 20.1387L190.988 20.9588L191.256 20.6855C191.256 20.6855 191.256 20.5032 191.256 20.4121L190.629 19.7742L190.181 20.2298V20.1387Z'
                fill='#262626'
            />
            <path
                d='M189.732 19.6842L190.18 19.2285L189.463 18.4995L189.105 18.864L189.911 19.6842H189.732Z'
                fill='#262626'
            />
            <path
                d='M188.659 18.3169H188.479L188.569 18.501L188.659 18.3169Z'
                fill='#262626'
            />
            <path
                d='M140.889 18.8639L140.709 18.6816C140.709 18.6816 140.082 19.1373 139.903 19.4107L140.082 19.5929L140.889 18.7728V18.8639Z'
                fill='#333333'
            />
            <path
                d='M139.542 20.0474C139.542 20.0474 139.542 20.322 139.452 20.4135L139.722 20.1389H139.632L139.542 20.0474Z'
                fill='#333333'
            />
            <path
                d='M140.082 19.6838L139.902 19.5007C139.902 19.5007 139.634 19.8669 139.544 20.05H139.634L140.082 19.7754V19.6838Z'
                fill='#333333'
            />
            <path
                d='M141.246 18.4995C141.246 18.4995 140.887 18.5911 140.708 18.7741L140.887 18.9572L141.246 18.5911V18.4995Z'
                fill='#333333'
            />
            <path
                d='M143.849 18.3169H143.579L143.669 18.501L143.849 18.3169Z'
                fill='#333333'
            />
            <path
                d='M141.623 19.7887L140.861 20.5637L141.306 21.0158L142.068 20.2408L141.623 19.7887Z'
                fill='#333333'
            />
            <path
                d='M142.835 18.4998L142.073 19.2756L142.518 19.7272L143.28 18.9514L142.835 18.4998Z'
                fill='#333333'
            />
            <path
                d='M140.437 20.9473L139.675 21.7227L140.12 22.1745L140.882 21.3991L140.437 20.9473Z'
                fill='#333333'
            />
            <path
                d='M139.633 21.6902L139.364 21.9636C139.364 21.9636 139.544 22.4192 139.633 22.6015L139.992 22.237L139.544 21.7813L139.633 21.6902Z'
                fill='#333333'
            />
            <path
                d='M140.808 20.4822L140.362 20.9355L140.808 21.3889L141.254 20.9355L140.808 20.4822Z'
                fill='#333333'
            />
            <path
                d='M142.126 19.1963L141.68 19.6497L142.126 20.103L142.571 19.6497L142.126 19.1963Z'
                fill='#333333'
            />
            <path
                d='M143.22 18.8662L143.668 18.5L143.578 18.3169H142.951L142.861 18.5L143.22 18.8662Z'
                fill='#333333'
            />
            <path
                d='M144.052 19.7797L143.29 20.5547L143.735 21.0068L144.497 20.2318L144.052 19.7797Z'
                fill='#333333'
            />
            <path
                d='M142.888 20.9086L142.126 21.6836L142.571 22.1357L143.333 21.3607L142.888 20.9086Z'
                fill='#333333'
            />
            <path
                d='M146.175 18.3169H145.995L146.085 18.501L146.175 18.3169Z'
                fill='#333333'
            />
            <path
                d='M145.155 18.4962L144.393 19.2712L144.837 19.7233L145.6 18.9483L145.155 18.4962Z'
                fill='#333333'
            />
            <path
                d='M141.567 22.1923L140.804 22.9673L141.249 23.4194L142.011 22.6444L141.567 22.1923Z'
                fill='#333333'
            />
            <path
                d='M140.441 23.3296V23.4213H140.532L140.441 23.3296Z'
                fill='#333333'
            />
            <path
                d='M140.889 22.9646L140.441 23.4202C140.441 23.4202 140.71 23.6936 140.889 23.7848L141.248 23.4202L140.8 22.9646H140.889Z'
                fill='#333333'
            />
            <path
                d='M142.134 21.6655L141.688 22.1189L142.134 22.5723L142.58 22.1189L142.134 21.6655Z'
                fill='#333333'
            />
            <path
                d='M143.277 20.4133L142.831 20.8662L143.276 21.32L143.722 20.8672L143.277 20.4133Z'
                fill='#333333'
            />
            <path
                d='M144.507 19.2209L144.06 19.6738L144.506 20.1277L144.952 19.6748L144.507 19.2209Z'
                fill='#333333'
            />
            <path
                d='M145.64 18.8662L146.088 18.5L145.998 18.3169H145.371L145.281 18.5L145.64 18.8662Z'
                fill='#333333'
            />
            <path
                d='M146.895 20.139L146.447 19.6833L145.64 20.5035L146.088 20.9591L146.895 20.139Z'
                fill='#333333'
            />
            <path
                d='M147.627 18.5448L146.865 19.3206L147.311 19.7721L148.072 18.9963L147.627 18.5448Z'
                fill='#333333'
            />
            <path
                d='M143.995 22.1853L143.233 22.9602L143.678 23.4123L144.44 22.6373L143.995 22.1853Z'
                fill='#333333'
            />
            <path
                d='M142.858 23.3296L142.231 23.9704H143.127L143.217 23.7873L142.858 23.3296Z'
                fill='#333333'
            />
            <path
                d='M148.594 18.3169H148.414L148.504 18.501L148.594 18.3169Z'
                fill='#333333'
            />
            <path
                d='M145.164 20.9635L144.401 21.7385L144.846 22.1906L145.608 21.4156L145.164 20.9635Z'
                fill='#333333'
            />
            <path
                d='M143.294 22.906L142.848 23.3599L143.294 23.8127L143.74 23.3589L143.294 22.906Z'
                fill='#333333'
            />
            <path
                d='M144.563 21.6565L144.117 22.1099L144.563 22.5632L145.009 22.1099L144.563 21.6565Z'
                fill='#333333'
            />
            <path
                d='M145.64 21.3243L146.088 20.9598L145.64 20.5042L145.281 20.9598L145.64 21.3243Z'
                fill='#333333'
            />
            <path
                d='M146.917 19.2454L146.471 19.6987L146.917 20.1521L147.363 19.6987L146.917 19.2454Z'
                fill='#333333'
            />
            <path
                d='M148.058 18.8662L148.506 18.5L148.416 18.3169H147.789L147.609 18.5L148.058 18.8662Z'
                fill='#333333'
            />
            <path
                d='M151.017 18.3169H150.747L150.927 18.501L151.017 18.3169Z'
                fill='#333333'
            />
            <path
                d='M146.377 22.1213L145.614 22.8955L146.058 23.348L146.821 22.5739L146.377 22.1213Z'
                fill='#333333'
            />
            <path
                d='M147.588 20.9565L146.826 21.7314L147.271 22.1835L148.033 21.4085L147.588 20.9565Z'
                fill='#333333'
            />
            <path
                d='M145.278 23.3296L144.651 23.9704H145.458L145.637 23.7873L145.278 23.3296Z'
                fill='#333333'
            />
            <path
                d='M150.477 18.864L150.029 18.4995L149.312 19.2285L149.67 19.6842L150.477 18.864Z'
                fill='#333333'
            />
            <path
                d='M149.315 20.139L148.867 19.6833L148.06 20.5035L148.508 20.9591L149.315 20.139Z'
                fill='#333333'
            />
            <path
                d='M145.281 23.3291L145.64 23.7848L146.088 23.3291L145.64 22.9646L145.281 23.3291Z'
                fill='#333333'
            />
            <path
                d='M146.878 21.7505L146.432 22.2034L146.877 22.6572L147.324 22.2043L146.878 21.7505Z'
                fill='#333333'
            />
            <path
                d='M148.058 21.3243L148.506 20.9598L148.058 20.5042L147.609 20.9598L148.058 21.3243Z'
                fill='#333333'
            />
            <path
                d='M149.254 19.2363L148.808 19.6897L149.254 20.1431L149.7 19.6897L149.254 19.2363Z'
                fill='#333333'
            />
            <path
                d='M150.477 18.8662L150.926 18.5L150.746 18.3169H150.209L150.029 18.5L150.477 18.8662Z'
                fill='#333333'
            />
            <path
                d='M153.437 18.3169H153.167L153.347 18.501L153.437 18.3169Z'
                fill='#333333'
            />
            <path
                d='M150.039 20.922L149.277 21.6965L149.721 22.1488L150.484 21.3743L150.039 20.922Z'
                fill='#333333'
            />
            <path
                d='M148.06 22.9637L148.508 23.3282L149.315 22.5992L148.867 22.1436L148.06 22.9637Z'
                fill='#333333'
            />
            <path
                d='M147.609 23.3296L147.071 23.9704H147.878L148.057 23.7873L147.609 23.3296Z'
                fill='#333333'
            />
            <path
                d='M151.334 19.756L150.572 20.531L151.017 20.9831L151.779 20.2081L151.334 19.756Z'
                fill='#333333'
            />
            <path
                d='M152.899 18.864L152.451 18.4995L151.644 19.2285L152.092 19.6842L152.899 18.864Z'
                fill='#333333'
            />
            <path
                d='M147.609 23.3291L148.058 23.7848L148.506 23.3291L148.058 22.9646L147.609 23.3291Z'
                fill='#333333'
            />
            <path
                d='M149.311 21.7583L148.865 22.2122L149.312 22.665L149.757 22.2112L149.311 21.7583Z'
                fill='#333333'
            />
            <path
                d='M150.477 21.3243L150.926 20.9598L150.477 20.5042L150.029 20.9598L150.477 21.3243Z'
                fill='#333333'
            />
            <path
                d='M151.683 19.2276L151.237 19.6809L151.683 20.1343L152.129 19.6809L151.683 19.2276Z'
                fill='#333333'
            />
            <path
                d='M152.898 18.8662L153.346 18.5L153.166 18.3169H152.629L152.449 18.5L152.898 18.8662Z'
                fill='#333333'
            />
            <path
                d='M150.029 23.3296L149.491 23.9704H150.298L150.477 23.7873L150.029 23.3296Z'
                fill='#333333'
            />
            <path
                d='M151.239 22.1077L150.476 22.8818L150.92 23.3344L151.683 22.5602L151.239 22.1077Z'
                fill='#333333'
            />
            <path
                d='M155.857 18.3169H155.587L155.677 18.501L155.857 18.3169Z'
                fill='#333333'
            />
            <path
                d='M153.61 19.8112L152.848 20.5862L153.292 21.0383L154.055 20.2633L153.61 19.8112Z'
                fill='#333333'
            />
            <path
                d='M152.424 20.9651L151.662 21.7405L152.107 22.1923L152.869 21.4169L152.424 20.9651Z'
                fill='#333333'
            />
            <path
                d='M154.813 18.5133L154.051 19.2891L154.496 19.7407L155.258 18.9649L154.813 18.5133Z'
                fill='#333333'
            />
            <path
                d='M150.029 23.3291L150.477 23.7848L150.926 23.3291L150.477 22.9646L150.029 23.3291Z'
                fill='#333333'
            />
            <path
                d='M151.692 21.697L151.246 22.1504L151.692 22.6037L152.138 22.1504L151.692 21.697Z'
                fill='#333333'
            />
            <path
                d='M152.905 20.3894L152.458 20.8423L152.904 21.2961L153.35 20.8432L152.905 20.3894Z'
                fill='#333333'
            />
            <path
                d='M154.112 19.2185L153.666 19.6719L154.112 20.1252L154.558 19.6719L154.112 19.2185Z'
                fill='#333333'
            />
            <path
                d='M155.317 18.8662L155.676 18.5L155.586 18.3169H154.959L154.869 18.5L155.317 18.8662Z'
                fill='#333333'
            />
            <path
                d='M158.277 18.3169H158.007L158.097 18.501L158.277 18.3169Z'
                fill='#333333'
            />
            <path
                d='M156.039 19.8022L155.276 20.5771L155.721 21.0292L156.483 20.2542L156.039 19.8022Z'
                fill='#333333'
            />
            <path
                d='M153.557 22.125L152.795 22.8999L153.24 23.352L154.002 22.577L153.557 22.125Z'
                fill='#333333'
            />
            <path
                d='M157.256 18.4971L156.493 19.2712L156.937 19.7238L157.7 18.9496L157.256 18.4971Z'
                fill='#333333'
            />
            <path
                d='M152.451 23.3296L151.824 23.9704H152.72L152.899 23.7873L152.451 23.3296Z'
                fill='#333333'
            />
            <path
                d='M154.875 20.9299L154.112 21.7048L154.557 22.1569L155.319 21.382L154.875 20.9299Z'
                fill='#333333'
            />
            <path
                d='M152.449 23.3291L152.898 23.7848L153.346 23.3291L152.898 22.9646L152.449 23.3291Z'
                fill='#333333'
            />
            <path
                d='M154.121 21.6882L153.675 22.1416L154.121 22.595L154.566 22.1416L154.121 21.6882Z'
                fill='#333333'
            />
            <path
                d='M155.317 21.3243L155.676 20.9598L155.317 20.5042L154.869 20.9598L155.317 21.3243Z'
                fill='#333333'
            />
            <path
                d='M156.58 19.2598L156.134 19.7136L156.581 20.1665L157.026 19.7127L156.58 19.2598Z'
                fill='#333333'
            />
            <path
                d='M157.738 18.8662L158.096 18.5L158.007 18.3169H157.379L157.29 18.5L157.738 18.8662Z'
                fill='#333333'
            />
            <path
                d='M160.697 18.3169H160.427L160.517 18.501L160.697 18.3169Z'
                fill='#333333'
            />
            <path
                d='M157.303 20.9233L156.541 21.6982L156.986 22.1503L157.748 21.3753L157.303 20.9233Z'
                fill='#333333'
            />
            <path
                d='M159.723 18.4448L158.961 19.2197L159.406 19.6718L160.168 18.8969L159.723 18.4448Z'
                fill='#333333'
            />
            <path
                d='M158.901 20.139L158.453 19.6833L157.736 20.5035L158.094 20.9591L158.901 20.139Z'
                fill='#333333'
            />
            <path
                d='M156.035 22.1592L155.272 22.9333L155.716 23.3859L156.479 22.6117L156.035 22.1592Z'
                fill='#333333'
            />
            <path
                d='M154.871 23.3296L154.244 23.9704H155.14L155.319 23.7873L154.871 23.3296Z'
                fill='#333333'
            />
            <path
                d='M154.869 23.3291L155.317 23.7848L155.676 23.3291L155.317 22.9646L154.869 23.3291Z'
                fill='#333333'
            />
            <path
                d='M156.549 21.6792L156.103 22.1326L156.549 22.5859L156.995 22.1326L156.549 21.6792Z'
                fill='#333333'
            />
            <path
                d='M157.738 21.3243L158.096 20.9598L157.738 20.5042L157.29 20.9598L157.738 21.3243Z'
                fill='#333333'
            />
            <path
                d='M158.969 19.2031L158.524 19.6565L158.969 20.1099L159.415 19.6565L158.969 19.2031Z'
                fill='#333333'
            />
            <path
                d='M160.068 18.8662L160.516 18.5L160.426 18.3169H159.799L159.709 18.5L160.068 18.8662Z'
                fill='#333333'
            />
            <path
                d='M157.291 23.3296L156.664 23.9704H157.56L157.739 23.7873L157.291 23.3296Z'
                fill='#333333'
            />
            <path
                d='M158.41 22.1069L157.648 22.8818L158.093 23.3339L158.855 22.559L158.41 22.1069Z'
                fill='#333333'
            />
            <path
                d='M160.896 19.7865L160.134 20.5615L160.579 21.0136L161.341 20.2386L160.896 19.7865Z'
                fill='#333333'
            />
            <path
                d='M162.029 18.5467L161.267 19.3225L161.712 19.7741L162.474 18.9983L162.029 18.5467Z'
                fill='#333333'
            />
            <path
                d='M159.696 20.876L158.935 21.6519L159.38 22.1034L160.141 21.3275L159.696 20.876Z'
                fill='#333333'
            />
            <path
                d='M163.027 18.3169H162.847L162.937 18.501L163.027 18.3169Z'
                fill='#333333'
            />
            <path
                d='M157.29 23.3291L157.738 23.7848L158.096 23.3291L157.738 22.9646L157.29 23.3291Z'
                fill='#333333'
            />
            <path
                d='M158.978 21.6724L158.532 22.1257L158.978 22.5791L159.424 22.1257L158.978 21.6724Z'
                fill='#333333'
            />
            <path
                d='M160.134 20.4334L159.688 20.8862L160.133 21.3401L160.58 20.8872L160.134 20.4334Z'
                fill='#333333'
            />
            <path
                d='M161.333 19.2581L160.887 19.7114L161.333 20.1648L161.779 19.7114L161.333 19.2581Z'
                fill='#333333'
            />
            <path
                d='M162.488 18.8662L162.936 18.5L162.846 18.3169H162.219L162.129 18.5L162.488 18.8662Z'
                fill='#333333'
            />
            <path
                d='M159.711 23.3296L159.084 23.9704H159.89L160.07 23.7873L159.711 23.3296Z'
                fill='#333333'
            />
            <path
                d='M160.839 22.0981L160.077 22.873L160.522 23.3251L161.284 22.5501L160.839 22.0981Z'
                fill='#333333'
            />
            <path
                d='M162.003 20.9704L161.241 21.7454L161.686 22.1974L162.448 21.4224L162.003 20.9704Z'
                fill='#333333'
            />
            <path
                d='M163.325 19.7773L162.563 20.5522L163.007 21.0043L163.769 20.2293L163.325 19.7773Z'
                fill='#333333'
            />
            <path
                d='M164.485 18.5418L163.722 19.3159L164.166 19.7685L164.929 18.9943L164.485 18.5418Z'
                fill='#333333'
            />
            <path
                d='M165.446 18.3169H165.176L165.356 18.501L165.446 18.3169Z'
                fill='#333333'
            />
            <path
                d='M160.124 22.9004L159.679 23.3542L160.125 23.8071L160.571 23.3533L160.124 22.9004Z'
                fill='#333333'
            />
            <path
                d='M161.341 21.7283L160.896 22.1816L161.341 22.635L161.787 22.1816L161.341 21.7283Z'
                fill='#333333'
            />
            <path
                d='M162.563 20.4268L162.116 20.8796L162.562 21.3335L163.008 20.8806L162.563 20.4268Z'
                fill='#333333'
            />
            <path
                d='M163.727 19.2988L163.281 19.7517L163.726 20.2055L164.172 19.7527L163.727 19.2988Z'
                fill='#333333'
            />
            <path
                d='M164.906 18.8662L165.354 18.5L165.175 18.3169H164.637L164.458 18.5L164.906 18.8662Z'
                fill='#333333'
            />
            <path
                d='M162.131 23.3296L161.504 23.9704H162.31L162.49 23.7873L162.131 23.3296Z'
                fill='#333333'
            />
            <path
                d='M167.865 18.3169H167.595L167.775 18.501L167.865 18.3169Z'
                fill='#333333'
            />
            <path
                d='M164.432 20.9614L163.67 21.7363L164.115 22.1884L164.877 21.4135L164.432 20.9614Z'
                fill='#333333'
            />
            <path
                d='M165.692 19.7194L164.93 20.4951L165.375 20.9467L166.137 20.171L165.692 19.7194Z'
                fill='#333333'
            />
            <path
                d='M163.268 22.0912L162.506 22.8662L162.95 23.3183L163.713 22.5433L163.268 22.0912Z'
                fill='#333333'
            />
            <path
                d='M166.882 18.5267L166.121 19.3025L166.566 19.7541L167.327 18.9783L166.882 18.5267Z'
                fill='#333333'
            />
            <path
                d='M162.548 22.8892L162.103 23.343L162.549 23.7959L162.995 23.3421L162.548 22.8892Z'
                fill='#333333'
            />
            <path
                d='M163.77 21.7193L163.324 22.1726L163.77 22.626L164.216 22.1726L163.77 21.7193Z'
                fill='#333333'
            />
            <path
                d='M164.906 21.3243L165.354 20.9598L164.906 20.5042L164.458 20.9598L164.906 21.3243Z'
                fill='#333333'
            />
            <path
                d='M166.16 19.2925L165.714 19.7454L166.159 20.1992L166.605 19.7463L166.16 19.2925Z'
                fill='#333333'
            />
            <path
                d='M167.326 18.8662L167.774 18.5L167.595 18.3169H167.057L166.878 18.5L167.326 18.8662Z'
                fill='#333333'
            />
            <path
                d='M165.697 22.0827L164.935 22.8577L165.379 23.3097L166.141 22.5348L165.697 22.0827Z'
                fill='#333333'
            />
            <path
                d='M166.861 20.9545L166.099 21.7295L166.543 22.1816L167.306 21.4066L166.861 20.9545Z'
                fill='#333333'
            />
            <path
                d='M168.146 19.6968L167.381 20.4685L167.824 20.9225L168.589 20.1507L168.146 19.6968Z'
                fill='#333333'
            />
            <path
                d='M164.457 23.3296L163.919 23.9704H164.726L164.905 23.7873L164.457 23.3296Z'
                fill='#333333'
            />
            <path
                d='M170.285 18.3169H170.015L170.195 18.501L170.285 18.3169Z'
                fill='#333333'
            />
            <path
                d='M169.747 18.864L169.299 18.4995L168.492 19.2285L168.94 19.6842L169.747 18.864Z'
                fill='#333333'
            />
            <path
                d='M164.458 23.3291L164.906 23.7848L165.354 23.3291L164.906 22.9646L164.458 23.3291Z'
                fill='#333333'
            />
            <path
                d='M166.107 21.7105L165.661 22.1638L166.107 22.6172L166.553 22.1638L166.107 21.7105Z'
                fill='#333333'
            />
            <path
                d='M167.393 20.4019L166.948 20.8557L167.394 21.3086L167.84 20.8548L167.393 20.4019Z'
                fill='#333333'
            />
            <path
                d='M168.492 20.14L168.94 19.6844L168.492 19.2288L168.134 19.6844L168.492 20.14Z'
                fill='#333333'
            />
            <path
                d='M169.746 18.8662L170.194 18.5L170.015 18.3169H169.387L169.298 18.5L169.746 18.8662Z'
                fill='#333333'
            />
            <path
                d='M169.299 20.96L168.492 21.689L168.94 22.1446L169.747 21.3245L169.299 20.96Z'
                fill='#333333'
            />
            <path
                d='M166.877 23.3296L166.339 23.9704H167.146L167.325 23.7873L166.877 23.3296Z'
                fill='#333333'
            />
            <path
                d='M168.062 22.0904L167.298 22.8638L167.742 23.3168L168.505 22.5434L168.062 22.0904Z'
                fill='#333333'
            />
            <path
                d='M172.167 18.864L171.719 18.4995L170.912 19.2285L171.361 19.6842L172.167 18.864Z'
                fill='#333333'
            />
            <path
                d='M170.486 19.7418L169.722 20.5151L170.166 20.9682L170.929 20.1948L170.486 19.7418Z'
                fill='#333333'
            />
            <path
                d='M172.706 18.3169H172.436L172.526 18.501L172.706 18.3169Z'
                fill='#333333'
            />
            <path
                d='M167.402 22.8689L166.957 23.3228L167.403 23.7756L167.848 23.3218L167.402 22.8689Z'
                fill='#333333'
            />
            <path
                d='M168.492 21.6902L168.134 22.1458L168.492 22.6015L168.94 22.1458L168.492 21.6902Z'
                fill='#333333'
            />
            <path
                d='M169.695 20.4969L169.25 20.9512L169.696 21.4036L170.141 20.9493L169.695 20.4969Z'
                fill='#333333'
            />
            <path
                d='M170.912 20.14L171.36 19.6844L170.912 19.2288L170.553 19.6844L170.912 20.14Z'
                fill='#333333'
            />
            <path
                d='M172.166 18.8662L172.524 18.5L172.435 18.3169H171.807L171.718 18.5L172.166 18.8662Z'
                fill='#333333'
            />
            <path
                d='M172.945 19.7717L172.182 20.5459L172.626 20.9984L173.389 20.2243L172.945 19.7717Z'
                fill='#333333'
            />
            <path
                d='M175.126 18.3169H174.856L174.946 18.501L175.126 18.3169Z'
                fill='#333333'
            />
            <path
                d='M169.299 23.3296L168.672 23.9704H169.568L169.748 23.7873L169.299 23.3296Z'
                fill='#333333'
            />
            <path
                d='M174.16 18.4955L173.398 19.2712L173.843 19.7228L174.605 18.9471L174.16 18.4955Z'
                fill='#333333'
            />
            <path
                d='M169.748 22.9637L170.197 23.3282L170.914 22.5992L170.555 22.1436L169.748 22.9637Z'
                fill='#333333'
            />
            <path
                d='M171.719 20.9364L170.956 21.7114L171.401 22.1635L172.163 21.3885L171.719 20.9364Z'
                fill='#333333'
            />
            <path
                d='M169.764 22.9454L169.32 23.4006L169.767 23.8521L170.211 23.3968L169.764 22.9454Z'
                fill='#333333'
            />
            <path
                d='M170.912 21.6902L170.553 22.1458L170.912 22.6015L171.36 22.1458L170.912 21.6902Z'
                fill='#333333'
            />
            <path
                d='M172.129 20.4648L171.683 20.9177L172.128 21.3716L172.574 20.9187L172.129 20.4648Z'
                fill='#333333'
            />
            <path
                d='M173.359 19.2722L172.912 19.7251L173.358 20.1789L173.804 19.7261L173.359 19.2722Z'
                fill='#333333'
            />
            <path
                d='M174.586 18.8662L174.944 18.5L174.855 18.3169H174.227L174.138 18.5L174.586 18.8662Z'
                fill='#333333'
            />
            <path
                d='M171.719 23.3296L171.092 23.9704H171.988L172.167 23.7873L171.719 23.3296Z'
                fill='#333333'
            />
            <path
                d='M175.337 19.7437L174.575 20.5195L175.021 20.9711L175.782 20.1953L175.337 19.7437Z'
                fill='#333333'
            />
            <path
                d='M174.086 20.911L173.323 21.6855L173.768 22.1379L174.53 21.3633L174.086 20.911Z'
                fill='#333333'
            />
            <path
                d='M177.456 18.3169H177.276L177.366 18.501L177.456 18.3169Z'
                fill='#333333'
            />
            <path
                d='M176.542 18.4175L175.779 19.1917L176.223 19.6442L176.986 18.87L176.542 18.4175Z'
                fill='#333333'
            />
            <path
                d='M172.168 22.9637L172.527 23.3282L173.333 22.5992L172.885 22.1436L172.168 22.9637Z'
                fill='#333333'
            />
            <path
                d='M172.096 22.9295L171.652 23.3848L172.1 23.8362L172.544 23.381L172.096 22.9295Z'
                fill='#333333'
            />
            <path
                d='M173.419 21.7212L172.974 22.175L173.42 22.6279L173.866 22.1741L173.419 21.7212Z'
                fill='#333333'
            />
            <path
                d='M174.558 20.458L174.111 20.9109L174.557 21.3647L175.003 20.9118L174.558 20.458Z'
                fill='#333333'
            />
            <path
                d='M175.835 19.2427L175.389 19.6965L175.836 20.1494L176.281 19.6956L175.835 19.2427Z'
                fill='#333333'
            />
            <path
                d='M176.916 18.8662L177.364 18.5L177.275 18.3169H176.647L176.558 18.5L176.916 18.8662Z'
                fill='#333333'
            />
            <path
                d='M175.254 22.1137L174.492 22.8887L174.937 23.3407L175.699 22.5657L175.254 22.1137Z'
                fill='#333333'
            />
            <path
                d='M179.876 18.3169H179.696L179.786 18.501L179.876 18.3169Z'
                fill='#333333'
            />
            <path
                d='M177.715 19.7588L176.952 20.533L177.396 20.9855L178.159 20.2113L177.715 19.7588Z'
                fill='#333333'
            />
            <path
                d='M174.139 23.3296L173.512 23.9704H174.408L174.587 23.7873L174.139 23.3296Z'
                fill='#333333'
            />
            <path
                d='M178.86 18.5402L178.098 19.3159L178.543 19.7675L179.305 18.9918L178.86 18.5402Z'
                fill='#333333'
            />
            <path
                d='M176.576 20.9215L175.814 21.6965L176.259 22.1486L177.021 21.3736L176.576 20.9215Z'
                fill='#333333'
            />
            <path
                d='M174.53 22.9046L174.085 23.3584L174.531 23.8113L174.977 23.3575L174.53 22.9046Z'
                fill='#333333'
            />
            <path
                d='M175.796 21.6438L175.35 22.0967L175.795 22.5505L176.242 22.0976L175.796 21.6438Z'
                fill='#333333'
            />
            <path
                d='M176.965 20.4184L176.519 20.8708L176.964 21.3252L177.41 20.8728L176.965 20.4184Z'
                fill='#333333'
            />
            <path
                d='M178.17 20.14L178.618 19.6844L178.17 19.2288L177.722 19.6844L178.17 20.14Z'
                fill='#333333'
            />
            <path
                d='M179.336 18.8662L179.784 18.5L179.695 18.3169H179.067L178.978 18.5L179.336 18.8662Z'
                fill='#333333'
            />
            <path
                d='M176.559 23.3296L175.932 23.9704H176.739L176.918 23.7873L176.559 23.3296Z'
                fill='#333333'
            />
            <path
                d='M182.294 18.3169H182.024L182.204 18.501L182.294 18.3169Z'
                fill='#333333'
            />
            <path
                d='M177.709 22.1353L176.947 22.9111L177.392 23.3627L178.154 22.5869L177.709 22.1353Z'
                fill='#333333'
            />
            <path
                d='M178.939 20.977L178.177 21.752L178.622 22.204L179.384 21.4291L178.939 20.977Z'
                fill='#333333'
            />
            <path
                d='M180.592 20.139L180.144 19.6833L179.337 20.5035L179.785 20.9591L180.592 20.139Z'
                fill='#333333'
            />
            <path
                d='M181.758 18.864L181.31 18.4995L180.593 19.2285L180.951 19.6842L181.758 18.864Z'
                fill='#333333'
            />
            <path
                d='M176.929 22.9536L176.483 23.407L176.929 23.8603L177.375 23.407L176.929 22.9536Z'
                fill='#333333'
            />
            <path
                d='M178.207 21.7649L177.761 22.2188L178.208 22.6716L178.653 22.2178L178.207 21.7649Z'
                fill='#333333'
            />
            <path
                d='M179.336 21.3243L179.784 20.9598L179.336 20.5042L178.978 20.9598L179.336 21.3243Z'
                fill='#333333'
            />
            <path
                d='M180.552 19.3199L180.107 19.7742L180.554 20.2266L180.999 19.7723L180.552 19.3199Z'
                fill='#333333'
            />
            <path
                d='M181.758 18.8662L182.207 18.5L182.027 18.3169H181.49L181.31 18.5L181.758 18.8662Z'
                fill='#333333'
            />
            <path
                d='M184.178 18.864L183.73 18.4995L183.013 19.2285L183.371 19.6842L184.178 18.864Z'
                fill='#333333'
            />
            <path
                d='M178.98 23.3296L178.352 23.9704H179.159L179.338 23.7873L178.98 23.3296Z'
                fill='#333333'
            />
            <path
                d='M184.714 18.3169H184.444L184.624 18.501L184.714 18.3169Z'
                fill='#333333'
            />
            <path
                d='M183.011 20.139L182.563 19.6833L181.757 20.5035L182.205 20.9591L183.011 20.139Z'
                fill='#333333'
            />
            <path
                d='M181.31 20.96L180.593 21.689L180.951 22.1446L181.758 21.3245L181.31 20.96Z'
                fill='#333333'
            />
            <path
                d='M180.066 22.1312L179.302 22.9045L179.745 23.3576L180.509 22.5842L180.066 22.1312Z'
                fill='#333333'
            />
            <path
                d='M178.978 23.3291L179.336 23.7848L179.784 23.3291L179.336 22.9646L178.978 23.3291Z'
                fill='#333333'
            />
            <path
                d='M180.567 21.7576L180.12 22.21L180.565 22.6643L181.012 22.2119L180.567 21.7576Z'
                fill='#333333'
            />
            <path
                d='M181.758 21.3243L182.207 20.9598L181.758 20.5042L181.31 20.9598L181.758 21.3243Z'
                fill='#333333'
            />
            <path
                d='M183.01 20.14L183.368 19.6844L183.01 19.2288L182.562 19.6844L183.01 20.14Z'
                fill='#333333'
            />
            <path
                d='M184.179 18.8662L184.627 18.5L184.448 18.3169H183.91L183.731 18.5L184.179 18.8662Z'
                fill='#333333'
            />
            <path
                d='M186.125 18.4831L185.363 19.2581L185.808 19.7101L186.57 18.9351L186.125 18.4831Z'
                fill='#333333'
            />
            <path
                d='M185.022 19.6755L184.26 20.4504L184.705 20.9025L185.467 20.1276L185.022 19.6755Z'
                fill='#333333'
            />
            <path
                d='M181.757 22.9637L182.205 23.3282L183.011 22.5992L182.563 22.1436L181.757 22.9637Z'
                fill='#333333'
            />
            <path
                d='M181.31 23.3296L180.772 23.9704H181.579L181.758 23.7873L181.31 23.3296Z'
                fill='#333333'
            />
            <path
                d='M187.134 18.3169H186.864L187.044 18.501L187.134 18.3169Z'
                fill='#333333'
            />
            <path
                d='M183.73 20.96L183.013 21.689L183.371 22.1446L184.178 21.3245L183.73 20.96Z'
                fill='#333333'
            />
            <path
                d='M181.31 23.3291L181.758 23.7848L182.207 23.3291L181.758 22.9646L181.31 23.3291Z'
                fill='#333333'
            />
            <path
                d='M182.979 21.7275L182.531 22.1794L182.976 22.6342L183.423 22.1823L182.979 21.7275Z'
                fill='#333333'
            />
            <path
                d='M184.179 21.3243L184.627 20.9598L184.179 20.5042L183.731 20.9598L184.179 21.3243Z'
                fill='#333333'
            />
            <path
                d='M185.371 19.239L184.925 19.6924L185.371 20.1457L185.817 19.6924L185.371 19.239Z'
                fill='#333333'
            />
            <path
                d='M186.599 18.8662L187.047 18.5L186.868 18.3169H186.24L186.151 18.5L186.599 18.8662Z'
                fill='#333333'
            />
            <path
                d='M183.73 23.3296L183.192 23.9704H183.999L184.178 23.7873L183.73 23.3296Z'
                fill='#333333'
            />
            <path
                d='M184.177 22.9637L184.625 23.3282L185.342 22.5992L184.984 22.1436L184.177 22.9637Z'
                fill='#333333'
            />
            <path
                d='M189.016 18.864L188.567 18.4995L187.761 19.2285L188.209 19.6842L189.016 18.864Z'
                fill='#333333'
            />
            <path
                d='M186.148 20.96L185.341 21.689L185.789 22.1446L186.596 21.3245L186.148 20.96Z'
                fill='#333333'
            />
            <path
                d='M187.315 19.7572L186.553 20.533L186.998 20.9846L187.76 20.2087L187.315 19.7572Z'
                fill='#333333'
            />
            <path
                d='M183.731 23.3291L184.179 23.7848L184.627 23.3291L184.179 22.9646L183.731 23.3291Z'
                fill='#333333'
            />
            <path
                d='M185.397 21.7346L184.952 22.1885L185.398 22.6414L185.843 22.1875L185.397 21.7346Z'
                fill='#333333'
            />
            <path
                d='M186.599 21.3243L187.047 20.9598L186.599 20.5042L186.151 20.9598L186.599 21.3243Z'
                fill='#333333'
            />
            <path
                d='M187.812 19.2554L187.367 19.7092L187.813 20.1621L188.259 19.7083L187.812 19.2554Z'
                fill='#333333'
            />
            <path
                d='M189.014 18.8662L189.373 18.5C189.373 18.5 188.925 18.3169 188.656 18.3169H188.566L189.014 18.8662Z'
                fill='#333333'
            />
            <path
                d='M190.631 19.6872L190.811 19.5041C190.811 19.5041 190.542 19.1379 190.452 19.0464L190.273 19.2295L190.721 19.6872H190.631Z'
                fill='#333333'
            />
            <path
                d='M189.375 20.9591L190.182 20.139L189.734 19.6833L189.017 20.5035L189.375 20.9591Z'
                fill='#333333'
            />
            <path
                d='M188.546 20.9202L187.783 21.6943L188.227 22.1469L188.99 21.3727L188.546 20.9202Z'
                fill='#333333'
            />
            <path
                d='M187.333 22.1361L186.571 22.9111L187.015 23.3632L187.778 22.5882L187.333 22.1361Z'
                fill='#333333'
            />
            <path
                d='M186.148 23.3296L185.52 23.9704H186.417L186.596 23.7873L186.148 23.3296Z'
                fill='#333333'
            />
            <path
                d='M186.596 22.9177L186.151 23.3716L186.597 23.8245L187.042 23.3706L186.596 22.9177Z'
                fill='#333333'
            />
            <path
                d='M187.792 21.6775L187.345 22.1304L187.791 22.5842L188.237 22.1313L187.792 21.6775Z'
                fill='#333333'
            />
            <path
                d='M189.014 21.3243L189.373 20.9598L189.014 20.5042L188.566 20.9598L189.014 21.3243Z'
                fill='#333333'
            />
            <path
                d='M190.631 19.6844L190.183 19.2288L189.735 19.6844L190.183 20.14L190.631 19.6844Z'
                fill='#333333'
            />
            <path
                d='M190.181 21.689L190.629 22.1446L191.346 21.4156L190.988 20.96L190.181 21.7801V21.689Z'
                fill='#333333'
            />
            <path
                d='M189.017 22.9637L189.375 23.3282L190.182 22.5992L189.734 22.1436L189.017 22.9637Z'
                fill='#333333'
            />
            <path
                d='M188.568 23.3296L187.94 23.9704H188.568C188.568 23.9704 188.747 23.9704 188.837 23.9704H188.926L188.478 23.4211L188.568 23.3296Z'
                fill='#333333'
            />
            <path
                d='M188.566 23.3291L189.014 23.7848L189.373 23.3291L189.014 22.9646L188.566 23.3291Z'
                fill='#333333'
            />
            <path
                d='M190.22 21.6707L189.774 22.1235L190.219 22.5774L190.666 22.1245L190.22 21.6707Z'
                fill='#333333'
            />
            <path
                d='M191.344 21.3238C191.344 21.3238 191.344 21.2326 191.344 21.1415C191.344 20.9592 191.344 20.777 191.255 20.5947L190.986 20.8681L191.344 21.2326V21.3238Z'
                fill='#333333'
            />
        </g>
        <g opacity='0.3'>
            <path
                d='M223.167 21.6888C223.167 24.6049 220.837 26.9742 217.969 26.9742C215.101 26.9742 212.771 24.6049 212.771 21.6888C212.771 18.7727 215.101 16.4033 217.969 16.4033C220.837 16.4033 223.167 18.7727 223.167 21.6888Z'
                fill='url(#paint11_radial_3054_8681)'
            />
            <path
                d='M220.119 17.9519C217.43 18.1342 216.086 20.6857 218.685 24.7865C221.553 24.5131 222.718 21.8704 220.119 17.9519Z'
                fill='url(#paint12_linear_3054_8681)'
            />
        </g>
        <path
            d='M322.736 616.118H328.024V612.108H322.736V616.118ZM322.736 64.3369V68.3465H328.024V64.3369H322.736Z'
            fill='url(#paint13_linear_3054_8681)'
        />
        <path
            d='M7.44093 612.108H2.15308V616.118H7.44093V612.108ZM2.15308 64.3369V68.3465H7.44093V64.3369H2.15308Z'
            fill='url(#paint14_linear_3054_8681)'
        />
        <path
            d='M264.573 675.624V681H268.516V675.624H264.573ZM59.5118 681H63.4553V675.624H59.5118V681Z'
            fill='url(#paint15_linear_3054_8681)'
        />
        <path
            d='M264.573 0V5.37656H268.516V0H264.573V0ZM59.5118 5.37656H63.4553V0H59.5118V5.37656Z'
            fill='url(#paint16_linear_3054_8681)'
        />
        <path
            d='M329.821 173.871C329.104 174.509 329.104 176.605 329.104 180.523V246.865C329.104 248.14 329.104 249.598 329.731 249.781C330 249.598 330 248.869 330 248.049V176.696C330 175.693 330 174.235 329.731 174.053L329.821 173.871Z'
            fill='url(#paint17_linear_3054_8681)'
        />
        <path
            d='M329.193 246.866V180.524C329.193 176.697 329.193 174.601 329.91 173.872L328.028 172.687C327.042 174.145 327.132 177.335 327.132 178.519V245.134C327.132 248.141 327.311 250.146 327.849 250.602L329.821 249.782C329.193 249.599 329.193 248.141 329.193 246.866V246.866Z'
            fill='url(#paint18_linear_3054_8681)'
        />
        <path
            d='M0.358663 161.387C1.07566 162.025 1.07568 164.121 1.07568 168.04V206.04C1.07568 207.316 1.07568 208.774 0.448306 208.956C0.179432 208.774 0.179443 208.045 0.179443 207.225V164.212C0.179443 163.21 0.179432 161.752 0.448306 161.57L0.358663 161.387Z'
            fill='url(#paint19_linear_3054_8681)'
        />
        <path
            d='M1.07602 205.95V167.95C1.07602 164.122 1.07601 162.026 0.359009 161.297L2.24115 160.113C3.22702 161.571 3.13739 164.76 3.13739 165.945V204.219C3.13739 207.226 2.95815 209.231 2.42041 209.687L0.448653 208.866C1.07603 208.684 1.07602 207.226 1.07602 205.95V205.95Z'
            fill='url(#paint20_linear_3054_8681)'
        />
        <path
            d='M0.358663 222.534C1.07566 223.172 1.07568 225.268 1.07568 229.187V267.187C1.07568 268.463 1.07568 269.921 0.448306 270.103C0.179432 269.921 0.179443 269.192 0.179443 268.372V225.359C0.179443 224.357 0.179432 222.899 0.448306 222.717L0.358663 222.534Z'
            fill='url(#paint21_linear_3054_8681)'
        />
        <path
            d='M1.07602 267.097V229.097C1.07602 225.269 1.07601 223.173 0.359009 222.444L2.24115 221.26C3.22702 222.718 3.13739 225.907 3.13739 227.092V265.366C3.13739 268.373 2.95815 270.378 2.42041 270.833L0.448653 270.013C1.07603 269.831 1.07602 268.373 1.07602 267.097V267.097Z'
            fill='url(#paint22_linear_3054_8681)'
        />
        <path
            d='M0 109.901V133.867L0.62737 134.414V109.263L0 109.901Z'
            fill='#555658'
        />
        <path
            d='M2.2411 109.262H0.538208V134.414H2.33071C2.33071 134.414 2.59957 133.685 2.59957 133.047V110.538C2.59957 109.809 2.59958 109.353 2.15145 109.171L2.2411 109.262Z'
            fill='url(#paint23_linear_3054_8681)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_3054_8681'
                x1='299.439'
                y1='7.65319'
                x2='323.975'
                y2='23.7703'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint1_linear_3054_8681'
                x1='303.56'
                y1='173.872'
                x2='326.773'
                y2='173.872'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint2_linear_3054_8681'
                x1='7.53104'
                y1='23.7829'
                x2='32.4279'
                y2='7.48851'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint3_linear_3054_8681'
                x1='27.158'
                y1='173.416'
                x2='3.94517'
                y2='173.416'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint4_linear_3054_8681'
                x1='165.358'
                y1='11.8458'
                x2='165.358'
                y2='1.09271'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint5_linear_3054_8681'
                x1='299.347'
                y1='673.348'
                x2='323.796'
                y2='657.139'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint6_linear_3054_8681'
                x1='303.739'
                y1='501.389'
                x2='326.683'
                y2='501.389'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint7_linear_3054_8681'
                x1='31.7283'
                y1='673.53'
                x2='8.45723'
                y2='658.123'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint8_linear_3054_8681'
                x1='26.9787'
                y1='500.842'
                x2='3.85554'
                y2='500.842'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint9_linear_3054_8681'
                x1='165.358'
                y1='669.156'
                x2='165.358'
                y2='679.909'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint10_linear_3054_8681'
                x1='8.60352'
                y1='340.272'
                x2='322.111'
                y2='340.272'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#2E2E2E' />
                <stop offset='0.07' />
                <stop offset='0.18' stopColor='#363636' />
                <stop offset='0.8' stopColor='#363636' />
                <stop offset='0.94' />
                <stop offset='1' stopColor='#2E2E2E' />
            </linearGradient>
            <radialGradient
                id='paint11_radial_3054_8681'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(206.538 26.7409) scale(5.14982 5.23623)'
            >
                <stop offset='0.12' />
                <stop offset='0.3' stopColor='#050505' />
                <stop offset='0.51' stopColor='#141414' />
                <stop offset='0.74' stopColor='#2C2C2C' />
                <stop offset='0.98' stopColor='#4E4E4E' />
                <stop offset='0.99' stopColor='#515151' />
            </radialGradient>
            <linearGradient
                id='paint12_linear_3054_8681'
                x1='217.609'
                y1='19.0454'
                x2='222.272'
                y2='24.7106'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#828282' />
                <stop offset='0.12' stopColor='#646464' />
                <stop offset='0.3' stopColor='#404040' />
                <stop offset='0.47' stopColor='#242424' />
                <stop offset='0.65' stopColor='#101010' />
                <stop offset='0.82' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint13_linear_3054_8681'
                x1='322.736'
                y1='340.182'
                x2='328.024'
                y2='340.182'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint14_linear_3054_8681'
                x1='2.2427'
                y1='340.182'
                x2='7.44093'
                y2='340.182'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint15_linear_3054_8681'
                x1='164.014'
                y1='681'
                x2='164.014'
                y2='675.624'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint16_linear_3054_8681'
                x1='164.014'
                y1='5.37656'
                x2='164.014'
                y2='0'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint17_linear_3054_8681'
                x1='329.193'
                y1='211.78'
                x2='330.089'
                y2='211.78'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.17' stopColor='#4D4D4D' />
                <stop offset='0.33' stopColor='#595959' />
                <stop offset='0.48' stopColor='#6C6C6C' />
                <stop offset='0.64' stopColor='#878787' />
                <stop offset='0.79' stopColor='#AAAAAA' />
                <stop offset='0.94' stopColor='#D4D4D4' />
                <stop offset='1' stopColor='#E6E6E6' />
            </linearGradient>
            <linearGradient
                id='paint18_linear_3054_8681'
                x1='328.476'
                y1='172.505'
                x2='328.476'
                y2='250.693'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint19_linear_3054_8681'
                x1='1.07568'
                y1='185.081'
                x2='0.0898201'
                y2='185.081'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.29' stopColor='#4D4D4D' />
                <stop offset='0.56' stopColor='#595959' />
                <stop offset='0.83' stopColor='#6C6C6C' />
                <stop offset='1' stopColor='#7D7D7D' />
            </linearGradient>
            <linearGradient
                id='paint20_linear_3054_8681'
                x1='1.79301'
                y1='160.022'
                x2='1.79301'
                y2='209.778'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint21_linear_3054_8681'
                x1='1.07568'
                y1='246.228'
                x2='0.0898201'
                y2='246.228'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.4' stopColor='#4D4D4D' />
                <stop offset='0.79' stopColor='#595959' />
                <stop offset='1' stopColor='#636363' />
            </linearGradient>
            <linearGradient
                id='paint22_linear_3054_8681'
                x1='1.79301'
                y1='221.168'
                x2='1.79301'
                y2='270.924'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint23_linear_3054_8681'
                x1='1.6137'
                y1='109.262'
                x2='1.6137'
                y2='134.505'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
        </defs>
    </svg>
)

export default Phone
