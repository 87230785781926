import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'
import { PATH_NEW_LOADER } from 'config/paths'
import { IPagination, TError } from 'config/types'
import { fetchPaymentsAction } from 'containers/dashboard/features/payments/store/actions'
import { IPayment } from 'containers/dashboard/features/payments/store/types'
// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'
import { getAppIdCookie } from 'utils/token'
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg'

import './PaymentsPage.scss'

export enum PaymentVariants {
    LiqPay = 'LiqPay',
    WayForPay = 'WayForPay',
}

const PaymentPage = (): React.ReactElement => {
    const { t } = useTranslation(['toast', 'payments'])

    const appId = getAppIdCookie()
    const { pathname } = useLocation()
    const { push } = useHistory()
    const dispatch = useDispatch()
    const { payments } = useSelector((state: RootState) => state.payments)
    const [isLoading, setIsLoading] = useState(false)

    const statusOptions = [
        {
            value: 'Unpaid',
            label: t('payments:table:statusUnpaid'),
            class: 'processing',
        },
        {
            value: 'Success',
            label: t('payments:table:statusSuccess'),
            class: 'success',
        },
        {
            value: 'Paid',
            label: t('payments:table:statusPaid'),
            class: 'processing',
        },
        {
            value: 'Error',
            label: t('payments:table:statusError'),
            class: 'cancel',
        },
        {
            value: 'Reversed',
            label: t('payments:table:statusReversed'),
            class: 'processing',
        },
        {
            value: 'Failure',
            label: t('payments:table:statusFailure'),
            class: 'processing',
        },
    ]

    const paymentsLength = !!payments?.length

    useEffect(() => {
        if (
            (pathname.includes('loader') ||
                pathname.includes('payments/create')) &&
            appId &&
            !paymentsLength
        ) {
            setIsLoading(true)
            dispatch(
                fetchPaymentsAction({
                    appId,
                    page: 1,
                    paymentSystem: PaymentVariants.LiqPay,
                    callback: (
                        type: string,
                        data: IPagination<IPayment>,
                        errorArray?: TError[],
                    ) => {
                        if (type === 'error' || !data) {
                            console.log('error emulator')
                            errorArray?.forEach(err => {
                                console.error(err.description)
                            })
                            toast.error(t('toast:backendError'))
                        }
                        setIsLoading(false)
                    },
                }),
            )
        }
    }, [dispatch, t, pathname, appId, paymentsLength])

    return (
        <div className='m-payments__wrapper'>
            {!isLoading ? (
                payments && payments.length ? (
                    payments.map(payment => (
                        <button
                            type='button'
                            key={payment.id}
                            className='m-payment new-loader--surface'
                            onClick={() =>
                                push(
                                    // eslint-disable-next-line max-len
                                    `${PATH_NEW_LOADER.pages.payments}/${payment.id}`,
                                )
                            }
                        >
                            <CustomImage className='m-payment__image' />
                            <div className='m-payment__info'>
                                <div className='m-payment__block'>
                                    {/* eslint-disable-next-line max-len */}
                                    <div className='m-payment__title new-loader--text'>
                                        {payment.description}
                                    </div>
                                    <div
                                        className={`m-payment__status ${
                                            statusOptions.find(
                                                option =>
                                                    option.value ===
                                                    payment.status,
                                            )?.class || ''
                                        }`}
                                    >
                                        {
                                            statusOptions.find(
                                                option =>
                                                    option.value ===
                                                    payment.status,
                                            )?.label
                                        }
                                    </div>
                                </div>
                                <div className='m-payment__block'>
                                    {/* eslint-disable-next-line max-len */}
                                    <div className='m-payment__date new-loader--text'>
                                        {payment.expiredDate &&
                                            new Date(
                                                payment.expiredDate,
                                            ).toLocaleDateString('uk-UK')}
                                    </div>
                                    {/* eslint-disable-next-line max-len */}
                                    <div className='m-payment__link new-loader--primary-color'>
                                        <ArrowRight />
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))
                ) : (
                    <div className='m-payments__no-data new-loader--surface'>
                        <div className='m-payments__no-data-title'>
                            {t('payments:noDataTitle')}
                        </div>
                        <div className='m-payments__no-data-text'>
                            {t('payments:noDataText')}
                        </div>
                    </div>
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default PaymentPage
