import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { APPLICATION_URLS } from 'config/urls'

import { IApplication, IAppTypes } from '../store/types'

export const getApplications = (): Promise<AxiosResponse<IApplication[]>> =>
    axiosInstance.get(`${APPLICATION_URLS.applications}`)

export const getApplicationTypes = ({
    lang,
}: {
    lang: string
}): Promise<AxiosResponse<IAppTypes[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${APPLICATION_URLS.applications}/application-types/page?language=${lang}`,
    )

export const getApplicationById = ({
    id,
}: {
    id: string
}): Promise<AxiosResponse<IApplication>> =>
    axiosInstance.get(`${APPLICATION_URLS.applications}/${id}`)

export const createApplication = ({
    name,
    packageName,
    isUsingFirebase,
    type,
    lang,
}: {
    name: string
    packageName?: string
    isUsingFirebase: boolean
    type: string
    lang?: string[]
}): Promise<AxiosResponse<IApplication>> =>
    axiosInstance.post(`${APPLICATION_URLS.applications}`, {
        applicationName: name,
        isUsingFirebase,
        packageName,
        type,
        languages: lang,
    })

export const updateApplication = ({
    application,
    appId,
}: {
    application: IApplication
    appId: string
}): Promise<AxiosResponse<IApplication>> =>
    axiosInstance.put(`${APPLICATION_URLS.applications}/${appId}`, application)

export const deleteApplication = ({
    appId,
}: {
    appId: string
}): Promise<AxiosResponse<IApplication>> =>
    axiosInstance.delete(`${APPLICATION_URLS.applications}/${appId}`)
