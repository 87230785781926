import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const WelcomeRoutes = [
    {
        id: 1,
        module: 'welcome',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.welcome,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/welcome/WelcomePage'
                ),
        ),
    },
]
