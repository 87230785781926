export enum forumThemeTypes {
    FETCH_THEMES_REQUEST = 'FETCH_THEMES_REQUEST',
    FETCH_THEME_BY_ID = 'FETCH_THEME_BY_ID',
    CREATE_THEME_REQUEST = 'CREATE_THEME_REQUEST',
    EDIT_THEME_REQUEST = 'EDIT_THEME_REQUEST',
    DELETE_THEME_REQUEST = 'DELETE_THEME_REQUEST',
    SET_THEMES = 'SET_THEMES',
    SET_THEME = 'SET_THEME',
    FETCH_FORUM_USERS_REQUEST = 'FETCH_FORUM_USERS_REQUEST',
    SET_FORUM_USERS = 'SET_FORUM_USERS',
}
