import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'
import { MOBILE_USERS } from 'config/urls'

import { IUser, IUserRequest } from '../store/types'

export const getUserRoles = ({
    appId,
    page,
}: {
    appId: string
    page: number
}): Promise<AxiosResponse<IUser[]>> =>
    axiosInstance.get(
        `${appId}${MOBILE_USERS.main}?pageNumber=${page}&pageSize=10`,
    )

export const editUserRoles = ({
    appId,
    users,
}: {
    appId: string
    users: IUserRequest[]
}): Promise<AxiosResponse<IUser[]>> =>
    axiosInstance.put(`${appId}${MOBILE_USERS.main}`, users)

export const removeUserRole = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<IUser>> =>
    axiosInstance.delete(`${appId}${MOBILE_USERS.main}/${id}`)

export const fetchAdditional = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<IUser>> =>
    axiosInstance.get(`${appId}${MOBILE_USERS.main}/${id}`)
