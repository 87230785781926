import React from 'react'

import { useTranslation } from 'react-i18next'

import SwipeIcon from 'assets/images/new-loader/todo/SwipeIcon.svg'
import CloseIcon from 'assets/images/new-loader/todo/CloseIcon'

// eslint-disable-next-line max-len
import LoaderButton from 'containers/emulator/components/shared/button/LoaderButton'
import Overlay from 'containers/emulator/components/shared/overlay/Overlay'
import CustomImage from 'components/custom-image/CustomImage'

import './SwipePopup.scss'

interface ISwipePopup {
    onClose: () => void
}

const SwipePopup = ({ onClose }: ISwipePopup): React.ReactElement => {
    const { t } = useTranslation(['emulator'])

    return (
        <Overlay>
            <div className='m-swipe-popup'>
                <button
                    className='m-swipe-popup__close new-loader--text'
                    type='button'
                    onClick={onClose}
                >
                    <CloseIcon />
                </button>
                <div className='m-swipe-popup__wrapper'>
                    <div className='m-swipe-popup__image'>
                        <CustomImage src={SwipeIcon} />
                    </div>
                    <div className='m-swipe-popup__text'>
                        <div>{t('emulator:swipe')}</div>
                        <div>({t('emulator:archive')})</div>
                    </div>
                </div>
                <LoaderButton
                    handleClick={onClose}
                    // eslint-disable-next-line max-len
                    className='m-swipe-popup__button new-loader--primary-background new-loader--contrast-color'
                >
                    {t('emulator:ok')}
                </LoaderButton>
            </div>
        </Overlay>
    )
}

export default SwipePopup
