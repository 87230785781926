import React from 'react'

const TaskMedium = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        className='m-task__med'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect width='22' height='22' fill='white' fillOpacity='0.01' />
        <path
            d='M4.58317 7.33325H17.4165C17.6596 7.33325 17.8928 7.42983 18.0647 7.60174C18.2366 7.77365 18.3332 8.0068 18.3332 8.24992C18.3332 8.49303 18.2366 8.72619 18.0647 8.8981C17.8928 9.07001 17.6596 9.16659 17.4165 9.16659H4.58317C4.34006 9.16659 4.1069 9.07001 3.93499 8.8981C3.76308 8.72619 3.6665 8.49303 3.6665 8.24992C3.6665 8.0068 3.76308 7.77365 3.93499 7.60174C4.1069 7.42983 4.34006 7.33325 4.58317 7.33325ZM4.58317 12.8333H17.4165C17.6596 12.8333 17.8928 12.9298 18.0647 13.1017C18.2366 13.2736 18.3332 13.5068 18.3332 13.7499C18.3332 13.993 18.2366 14.2262 18.0647 14.3981C17.8928 14.57 17.6596 14.6666 17.4165 14.6666H4.58317C4.34006 14.6666 4.1069 14.57 3.93499 14.3981C3.76308 14.2262 3.6665 13.993 3.6665 13.7499C3.6665 13.5068 3.76308 13.2736 3.93499 13.1017C4.1069 12.9298 4.34006 12.8333 4.58317 12.8333Z'
            fill='#FFAB00'
        />
    </svg>
)

export default TaskMedium
