import React, { useEffect } from 'react'
import './PaymentItem.scss'
import { getAppIdCookie } from 'utils/token'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { TError } from 'config/types'
import { toast } from 'react-toastify'
// eslint-disable-next-line max-len
import { fetchPaymentAction } from 'containers/dashboard/features/payments/store/actions'
import Preloader from 'components/preloader/Preloader'
// import moment from 'moment'
import CustomButton from 'components/custom-button/CustomButton'
// eslint-disable-next-line max-len
import { ReactComponent as DownloadIcon } from 'assets/images/icons/Download.svg'

interface IPageParams {
    id: string
}

const PaymentItem = (): React.ReactElement => {
    const { t } = useTranslation(['toast', 'payments'])
    const appId = getAppIdCookie()
    const { id } = useParams<IPageParams>()
    const { payment } = useSelector((state: RootState) => state.payments)
    const dispatch = useDispatch()

    const statusOptions = [
        {
            value: 'Unpaid',
            label: t('payments:table:statusUnpaid'),
            class: 'processing',
        },
        {
            value: 'Success',
            label: t('payments:table:statusSuccess'),
            class: 'success',
        },
        {
            value: 'Paid',
            label: t('payments:table:statusPaid'),
            class: 'processing',
        },
        {
            value: 'Error',
            label: t('payments:table:statusError'),
            class: 'cancel',
        },
        {
            value: 'Reversed',
            label: t('payments:table:statusReversed'),
            class: 'processing',
        },
        {
            value: 'Failure',
            label: t('payments:table:statusFailure'),
            class: 'processing',
        },
    ]

    useEffect(() => {
        if (!appId || !id) return
        dispatch(
            fetchPaymentAction({
                appId,
                id,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(error => {
                            console.error(error)
                            toast.error(t('toast.backendError'))
                        })
                    }
                },
            }),
        )
    }, [dispatch, appId, id, t])

    console.log(payment, 'payment')

    return payment ? (
        <div className='m-payment-item__wrapper new-loader--surface'>
            <div className='m-payment-item__title-wrapper'>
                <div className='m-payment-item__title new-loader--text'>
                    {t('payments:paymentItem:title', { number: 2322 })}
                </div>
                <div
                    className={`m-payment-item__status ${
                        statusOptions.find(
                            option => option.value === payment.status,
                        )?.class || ''
                    }`}
                >
                    {
                        statusOptions.find(
                            option => option.value === payment.status,
                        )?.label
                    }
                </div>
            </div>
            <div className='m-payment-item__info new-loader--text'>
                {payment.items.map(item => (
                    <div className='m-payment-item__block' key={item.name}>
                        <div className='m-payment-item__block-title'>
                            {t('payments:paymentItem:serviceName')}
                        </div>
                        <div className='m-payment-item__block-subtitle'>
                            {item.name}
                        </div>

                        <div className='m-payment-item__block-title'>
                            {t('payments:paymentItem:noVatPrice')}
                        </div>
                        <div className='m-payment-item__block-subtitle'>
                            {item.price} {payment.currency}
                        </div>
                    </div>
                ))}
                <div className='m-payment-item__block'>
                    <div className='m-payment-item__block-title'>
                        {t('payments:paymentItem:purpose')}
                    </div>
                    <div className='m-payment-item__block-subtitle'>
                        {payment.description}
                    </div>
                </div>
                {payment?.name ? (
                    <div className='m-payment-item__block'>
                        <div className='m-payment-item__block-title'>
                            {t('payments:paymentItem:legalName')}
                        </div>
                        <div className='m-payment-item__block-subtitle'>
                            {payment.name}
                        </div>
                    </div>
                ) : null}

                <div className='m-payment-item__block'>
                    <div className='m-payment-item__block-title'>
                        {t('payments:paymentItem:infoDate')}
                    </div>
                    <div className='m-payment-item__block-subtitle'>
                        {payment.expiredDate &&
                            new Date(payment.expiredDate).toLocaleDateString()}
                    </div>
                </div>
            </div>
            <div className='m-payment-item__actions'>
                <CustomButton design='secondary'>
                    {payment.status === 'Unpaid1' ? (
                        t('payments:paymentItem:pay')
                    ) : (
                        <>
                            <DownloadIcon />
                            {t('payments:paymentItem:download')}
                        </>
                    )}
                </CustomButton>
            </div>
        </div>
    ) : (
        <Preloader />
    )
}

export default PaymentItem
