import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { getSurveyUsers } from '../service/surveyUsers'
import { surveyUsersTypes } from './actionTypes'
import { FetchSurveyUsersRequest, ISurveyUsers } from './types'

function* fetchSurveyUsersSaga(action: FetchSurveyUsersRequest) {
    try {
        const response: AxiosResponse<ISurveyUsers> = yield call(
            getSurveyUsers,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: surveyUsersTypes.SET_SURVEY_USERS,
                payload: { surveyUsers: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* surveyUsersSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            surveyUsersTypes.FETCH_SURVEY_USERS_REQUEST,
            fetchSurveyUsersSaga,
        ),
    ])
}

export default surveyUsersSaga
