import { lazy } from 'react'

import { PATH_BILLING } from 'config/paths'

// Add invoices here
export const BillingRoutes = [
    {
        id: 1,
        module: 'billing',
        protected: true,
        exact: true,
        path: PATH_BILLING.root,
        component: lazy(
            () => import('containers/billing-information/BillingInformation'),
        ),
    },
    {
        id: 2,
        module: 'billing',
        protected: true,
        exact: true,
        path: PATH_BILLING.invoices,
        component: lazy(
            () => import('containers/billing-information/InvoicesPage'),
        ),
    },
]
