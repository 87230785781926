import { paymentsTypes } from './actionTypes'
import {
    CreatePaymentRequest,
    CreatePaymentPayload,
    FetchPaymentPayload,
    FetchPaymentRequest,
    FetchPaymentsPayload,
    FetchPaymentsRequest,
    EditPaymentPayload,
    EditPaymentRequest,
    DeletePaymentPayload,
    DeletePaymentRequest,
    IPayment,
    SetPayments,
    SetPayment,
    SetExpandedValue,
} from './types'

export const fetchPaymentsAction = (
    payload: FetchPaymentsPayload,
): FetchPaymentsRequest => ({
    type: paymentsTypes.FETCH_PAYMENTS_REQUEST,
    payload,
})

export const fetchPaymentAction = (
    payload: FetchPaymentPayload,
): FetchPaymentRequest => ({
    type: paymentsTypes.FETCH_PAYMENT_BY_ID,
    payload,
})

export const createPaymentAction = (
    payload: CreatePaymentPayload,
): CreatePaymentRequest => ({
    type: paymentsTypes.CREATE_PAYMENT_REQUEST,
    payload,
})

export const editPaymentAction = (
    payload: EditPaymentPayload,
): EditPaymentRequest => ({
    type: paymentsTypes.EDIT_PAYMENT_REQUEST,
    payload,
})

export const deletePaymentAction = (
    payload: DeletePaymentPayload,
): DeletePaymentRequest => ({
    type: paymentsTypes.DELETE_PAYMENT_REQUEST,
    payload,
})

export const setPayments = (payload: {
    payments: IPayment[] | undefined
}): SetPayments => ({
    type: paymentsTypes.SET_PAYMENTS,
    payload,
})

export const setPayment = (payload: { payment: IPayment }): SetPayment => ({
    type: paymentsTypes.SET_PAYMENT,
    payload,
})

export const setExpandedValue = (payload: {
    isExpanded: boolean
}): SetExpandedValue => ({
    type: paymentsTypes.SET_EXPANDED_VALUE,
    payload,
})
