import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const ServicesRoutes = [
    {
        id: 1,
        module: 'services',
        protected: true,
        exact: true,
        path: PATH_FEATURES.services.root,
        component: lazy(
            () => import('containers/dashboard/features/services/Services'),
        ),
    },
    {
        id: 2,
        module: 'services',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.services.edit}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/services/EditService'),
        ),
    },
    {
        id: 3,
        module: 'services',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.services.create}`,
        component: lazy(
            () =>
                import('containers/dashboard/features/services/CreateServices'),
        ),
    },
]
