export enum servicesTypes {
    FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST',
    FETCH_SERVICE_BY_ID = 'FETCH_SERVICE_BY_ID',
    CREATE_SERVICES_REQUEST = 'CREATE_SERVICES_REQUEST',
    EDIT_SERVICE_REQUEST = 'EDIT_SERVICE_REQUEST',
    DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST',
    SET_SERVICES = 'SET_SERVICES',
    SET_SERVICE = 'SET_SERVICE',
    SET_RELATIVE_SERVICE = 'SET_RELATIVE_SERVICE',
}
