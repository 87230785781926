import React from 'react'

export const IosIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '33' : '39'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 39 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M39 33.75C37.9362 36.1445 37.4236 37.2148 36.0554 39.3281C34.1399 42.2812 31.4381 45.9688 28.1004 45.9922C25.1289 46.0195 24.3619 44.0273 20.3266 44.0625C16.2951 44.082 15.4549 46.0312 12.4795 46C9.13796 45.9688 6.58265 42.6484 4.66713 39.6992C-0.690156 31.4297 -1.25672 21.7344 2.05401 16.5781C4.39734 12.9219 8.10119 10.7734 11.5815 10.7734C15.1273 10.7734 17.355 12.7461 20.2842 12.7461C23.1286 12.7461 24.8591 10.7695 28.9599 10.7695C32.0587 10.7695 35.3347 12.4805 37.678 15.4336C30.0159 19.6914 31.2608 30.7812 39 33.75ZM25.8457 7.46875C27.3373 5.52734 28.4704 2.78906 28.058 0C25.6261 0.167969 22.7817 1.74219 21.1205 3.78125C19.6136 5.64062 18.3648 8.39844 18.8504 11.0664C21.506 11.1523 24.254 9.54688 25.8457 7.46875Z'
            fill={isActive ? '#FFED00' : '#9D9E9E'}
        />
    </svg>
)

export default IosIcon
