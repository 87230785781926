import { GalleryRoutes } from './Gallery.routes'
import { TodoRoutes } from './Todo.routes'
import { CloudRoutes } from './Cloud.routes'
import { BlogRoutes } from './Blog.routes'
import { BillingRoutes } from './Billing.routes'
import { ServicesRoutes } from './Service.routes'
import { CalendarRoutes } from './Calendar.routes'
import { AboutRoutes } from './About.routes'
import { NewsRoutes } from './News.routes'
import { MapRoutes } from './Map.routes'
import { EmployeeRoutes } from './Employees.routes'
import { BookingRoutes } from './Booking.routes'
import { EreaderRoutes } from './Ereader.routes'
import { WelcomeRoutes } from './Welcome.routes'
import { ChatPremiumRoutes } from './ChatPremium.routes'
import { VideoRoutes } from './Video.routes'
import { PaymentsRoutes } from './Payments.routes'

export default [
    ...GalleryRoutes,
    ...TodoRoutes,
    ...CloudRoutes,
    ...BlogRoutes,
    ...BillingRoutes,
    ...ServicesRoutes,
    ...EmployeeRoutes,
    ...BookingRoutes,
    ...CalendarRoutes,
    ...AboutRoutes,
    ...NewsRoutes,
    ...MapRoutes,
    ...EreaderRoutes,
    ...WelcomeRoutes,
    ...ChatPremiumRoutes,
    ...VideoRoutes,
    ...PaymentsRoutes,
]
