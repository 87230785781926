import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const VideoRoutes = [
    {
        id: 1,
        module: 'loader-video',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.video}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/cloud/tabs/video/loader/VideoItem'
                ),
        ),
    },
]
