export enum applicationTypes {
    FETCH_APPLICATIONS_REQUEST = 'FETCH_APPLICATIONS_REQUEST',
    FETCH_APPLICATION_TYPES = 'FETCH_APPLICATION_TYPES',
    FETCH_APPLICATION_BY_ID = 'FETCH_APPLICATION_BY_ID',
    CREATE_APPLICATION = 'CREATE_APPLICATION',
    EDIT_APPLICATION = 'EDIT_APPLICATION',
    DELETE_APPLICATION = 'DELETE_APPLICATION',
    SET_APPLICATION_DETAILS = 'SET_APPLICATION_DETAILS',
    SET_APPLICATIONS = 'SET_APPLICATIONS',
    SET_APP_TYPES = 'SET_APP_TYPES',
}
