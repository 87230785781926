import React, { useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

// eslint-disable-next-line max-len
import { IFeature } from 'containers/dashboard/settings/features-activation/store/types'
import { useTranslation } from 'react-i18next'

import { data, IFeatureMenu } from './data'

import './BurgerMenu.scss'
import { IconRender } from './IconRender'
import Overlay from '../shared/overlay/Overlay'

const BurgerMenu = ({
    setMenuOpen,
    isMenuOpen,
}: {
    isMenuOpen: boolean
    setMenuOpen: (isMenuOpen: boolean) => void
}): React.ReactElement => {
    const cookies = useCookies(['paid_plan', 'active_features'])[0]
    const history = useHistory()
    const ref = useRef<HTMLDivElement>(null)
    const { t, i18n } = useTranslation(['emulator'])

    useClickedOutsideHandler({ ref, func: () => setMenuOpen(false) })

    const pushOnPage = (url: string) => {
        history.push(url)
    }

    let updatedFeatures: IFeatureMenu[] = cookies.active_features
        .map((feature: IFeature) => {
            const updIncludes = data.filter(
                subCat =>
                    feature.type.toLowerCase() === subCat.type.toLowerCase(),
            )

            return updIncludes
        })
        .flat()

    if (i18n.language !== 'ua') {
        updatedFeatures = updatedFeatures.filter(
            feature => feature.icon !== 'payments',
        )
    }

    console.log(updatedFeatures, 'updatedFeatures')

    return (
        <>
            {isMenuOpen && (
                <Overlay>
                    <div
                        ref={ref}
                        className='burger-nav 
                            new-loader--tabbar'
                    >
                        <div className='burger-nav__wrapper'>
                            {updatedFeatures.map((item: IFeatureMenu) => (
                                <button
                                    type='button'
                                    aria-hidden='true'
                                    key={item.key}
                                    onClick={() => {
                                        setMenuOpen(!isMenuOpen)
                                        document.body.style.overflowY = 'scroll'
                                        pushOnPage(item.path)
                                    }}
                                    className='new-loader--text'
                                >
                                    <div className='burger-nav__icon'>
                                        <IconRender iconName={item.icon} />
                                    </div>

                                    <span>{t(item.name)}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </Overlay>
            )}
        </>
    )
}

export default BurgerMenu
