/* eslint-disable max-len */
import Discord from 'assets/images/social-media-icons/smm/Discord.svg'
import Facebook from 'assets/images/social-media-icons/smm/Facebook.svg'
import FacebookHollow from 'assets/images/social-media-icons/smm/HFacebook.svg'
import Instagram from 'assets/images/social-media-icons/smm/Instagram.svg'
import InstagramHollow from 'assets/images/social-media-icons/smm/HInstagram.svg'
import LinkedIn from 'assets/images/social-media-icons/smm/LinkedIn.svg'
import LinkedInHollow from 'assets/images/social-media-icons/smm/LinkedInHollow.svg'
import Pinterest from 'assets/images/social-media-icons/smm/Pinterest.svg'
import Reddit from 'assets/images/social-media-icons/smm/Reddit.svg'
import TikTok from 'assets/images/social-media-icons/smm/TikTok.svg'
import Twitter from 'assets/images/social-media-icons/smm/Twitter.svg'
import TwitterHollow from 'assets/images/social-media-icons/smm/TwitterHollow.svg'
import YouTube from 'assets/images/social-media-icons/smm/YouTube.svg'
import YoutubeHollow from 'assets/images/social-media-icons/smm/YoutubeHollow.svg'
import MXTakatak from 'assets/images/social-media-icons/smm/MXTakatak.svg'
import Moj from 'assets/images/social-media-icons/smm/Moj.svg'
import Messenger from 'assets/images/social-media-icons/chat/Messenger.svg'
import WhatsApp from 'assets/images/social-media-icons/chat/WhatsApp.svg'
import Viber from 'assets/images/social-media-icons/chat/Viber.svg'
import Telegram from 'assets/images/social-media-icons/chat/Telegram.svg'
import Email from 'assets/images/social-media-icons/chat/Email.svg'
import Zoom from 'assets/images/social-media-icons/conference/Zoom.svg'
import TeamViewer from 'assets/images/social-media-icons/conference/TeamViewer.svg'
import Skype from 'assets/images/social-media-icons/conference/Skype.svg'
import GoogleMeet from 'assets/images/social-media-icons/conference/GoogleMeet.svg'
import MicrosoftTeams from 'assets/images/social-media-icons/conference/MicrosoftTeams.svg'
import AdobeConnect from 'assets/images/social-media-icons/conference/AdobeConnect.svg'
import Intermedia from 'assets/images/social-media-icons/conference/Intermedia.svg'
import Genesys from 'assets/images/social-media-icons/conference/Genesys.svg'
import Twitch from 'assets/images/social-media-icons/video/Twitch.svg'
import Dailymotion from 'assets/images/social-media-icons/video/Dailymotion.svg'
import Ted from 'assets/images/social-media-icons/video/Ted.svg'
import Vimeo from 'assets/images/social-media-icons/video/Vimeo.svg'
import Amazon from 'assets/images/social-media-icons/cloud/Amazon.svg'
import Dropbox from 'assets/images/social-media-icons/cloud/Dropbox.svg'
import GoogleDrive from 'assets/images/social-media-icons/cloud/GoogleDrive.svg'
import ICloud from 'assets/images/social-media-icons/cloud/ICloud.svg'
import IDrive from 'assets/images/social-media-icons/cloud/IDrive.svg'
import Mega from 'assets/images/social-media-icons/cloud/Mega.svg'
import OneDrive from 'assets/images/social-media-icons/cloud/OneDrive.svg'
import Sync from 'assets/images/social-media-icons/cloud/Sync.svg'
import Tresorit from 'assets/images/social-media-icons/cloud/Tresorit.svg'

const socialMediaIcons = {
    smm: {
        facebook: Facebook,
        fbHollow: FacebookHollow,
        linkedIn: LinkedIn,
        linkedHollow: LinkedInHollow,
        pinterest: Pinterest,
        instagram: Instagram,
        instaHollow: InstagramHollow,
        twitter: Twitter,
        xHollow: TwitterHollow,
        mXTakatak: MXTakatak,
        moj: Moj,
        discord: Discord,
        tikTok: TikTok,
        reddit: Reddit,
        youTube: YouTube,
        youtubeHollow: YoutubeHollow,
    },
    chat: {
        messenger: Messenger,
        telegram: Telegram,
        viber: Viber,
        whatsApp: WhatsApp,
        email: Email,
    },
    conference: {
        zoom: Zoom,
        teamViewer: TeamViewer,
        skype: Skype,
        googleMeet: GoogleMeet,
        microsoftTeams: MicrosoftTeams,
        adobe: AdobeConnect,
        intermedia: Intermedia,
        genesys: Genesys,
    },
    video: {
        twitch: Twitch,
        dailymotion: Dailymotion,
        ted: Ted,
        tikTok: TikTok,
        youTube: YouTube,
        vimeo: Vimeo,
        instagram: Instagram,
        pinterest: Pinterest,
        facebook: Facebook,
    },
    cloud: {
        googleDrive: GoogleDrive,
        oneDrive: OneDrive,
        dropbox: Dropbox,
        iCloud: ICloud,
        iDrive: IDrive,
        tresorit: Tresorit,
        mega: Mega,
        amazon: Amazon,
        sync: Sync,
    },
}

export default socialMediaIcons
