import { cloudTypes } from './actionTypes'
import { CloudActions, CloudState } from './types'

const initialState: CloudState = {
    cloudDetails: undefined,
    activeTab: '',
}

export default (state = initialState, action: CloudActions): CloudState => {
    switch (action.type) {
        case cloudTypes.SET_CLOUD:
            return {
                ...state,
                cloudDetails: action.payload.cloudDetails,
            }

        case cloudTypes.SET_TAB:
            return {
                ...state,
                activeTab: action.payload.activeTab,
            }
        default:
            return {
                ...state,
            }
    }
}
