import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const CloudRoutes = [
    {
        id: 1,
        module: 'loader-cloud',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.cloud,
        component: lazy(
            () => import('containers/emulator/pages/cloud/CloudPage'),
        ),
    },
]
