import { paymentLinkTypes } from './actionTypes'
import { PaymenyLinkActions, PaymentLinksState } from './types'

const initialState: PaymentLinksState = {
    paymentLinks: undefined,
    paymentLink: undefined,
}

export default (
    state = initialState,
    action: PaymenyLinkActions,
): PaymentLinksState => {
    switch (action.type) {
        case paymentLinkTypes.SET_LINKS:
            return {
                ...state,
                paymentLinks: action.payload.paymentLinks,
            }
        case paymentLinkTypes.SET_LINK:
            return {
                ...state,
                paymentLink: action.payload.paymentLink,
            }
        default:
            return {
                ...state,
            }
    }
}
