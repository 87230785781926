import { userRolesTypes } from './actionTypes'
import { UserRolesActions, UserRolesState } from './types'

const initialState: UserRolesState = {
    users: undefined,
}

export default (
    state = initialState,
    action: UserRolesActions,
): UserRolesState => {
    switch (action.type) {
        case userRolesTypes.SET_USER_ROLES:
            return {
                ...state,
                users: action.payload.users,
            }
        default:
            return {
                ...state,
            }
    }
}
