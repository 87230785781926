import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { galleryTypes } from './actionTypes'
import {
    getGallery,
    getGalleryItem,
    createGalleryItem,
    editGalleryItem,
    deleteGalleryItem,
} from '../service/gallery'

import {
    IGalleryItem,
    FetchGalleryRequest,
    FetchGalleryItemByIdRequest,
    CreateGalleryRequest,
    EditGalleryItemRequest,
    DeleteGalleryItemRequest,
} from './types'

function* fetchGallerySaga(action: FetchGalleryRequest) {
    try {
        const response: AxiosResponse<IGalleryItem> = yield call(
            getGallery,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: galleryTypes.SET_GALLERY,
                payload: { gallery: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchGalleryItemByIdSaga(action: FetchGalleryItemByIdRequest) {
    try {
        const response: AxiosResponse<IGalleryItem> = yield call(
            getGalleryItem,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: galleryTypes.SET_GALLERY_ITEM,
                payload: { galleryItem: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createGallerySaga(action: CreateGalleryRequest) {
    try {
        const response: AxiosResponse<IGalleryItem> = yield call(
            createGalleryItem,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editGalleryItemSaga(action: EditGalleryItemRequest) {
    try {
        const response: AxiosResponse<IGalleryItem> = yield call(
            editGalleryItem,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteGalleryItemSaga(action: DeleteGalleryItemRequest) {
    try {
        const response: AxiosResponse<IGalleryItem> = yield call(
            deleteGalleryItem,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { gallery } = yield select(state => state.gallery)
            const updatedGallery = gallery.filter(
                (galleryItem: IGalleryItem) =>
                    galleryItem.id !== action.payload.galleryItemId,
            )
            yield put({
                type: galleryTypes.SET_GALLERY,
                payload: { gallery: updatedGallery },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* galleryItemSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(galleryTypes.FETCH_GALLERY_REQUEST, fetchGallerySaga),
    ])
    yield all([
        takeLatest(
            galleryTypes.FETCH_GALLERY_ITEM_BY_ID,
            fetchGalleryItemByIdSaga,
        ),
    ])
    yield all([
        takeLatest(galleryTypes.CREATE_GALLERY_REQUEST, createGallerySaga),
    ])
    yield all([
        takeLatest(galleryTypes.EDIT_GALLERY_ITEM_REQUEST, editGalleryItemSaga),
    ])
    yield all([
        takeLatest(
            galleryTypes.DELETE_GALLERY_ITEM_REQUEST,
            deleteGalleryItemSaga,
        ),
    ])
}

export default galleryItemSaga
