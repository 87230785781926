import { PATH_PLACEMENT } from 'config/paths'
import { lazy } from 'react'

export const PlacementRoutes = [
    {
        id: 1,
        module: 'placement',
        exact: true,
        path: PATH_PLACEMENT.success,
        component: lazy(() => import('containers/placement/status/Success')),
    },
    {
        id: 2,
        module: 'placement',
        exact: true,
        path: PATH_PLACEMENT.error,
        component: lazy(() => import('containers/placement/status/Error')),
    },
]
