import React from 'react'

export const SmmIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M27.5652 33.9565V35.7826H30.3957C30.3358 35.4876 30.3043 35.1823 30.3043 34.8696C30.3043 34.5569 30.3358 34.2515 30.3957 33.9565H27.5652Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.7391 29.3913C25.7391 27.3743 27.3743 25.7391 29.3913 25.7391H40.3478C42.3649 25.7391 44 27.3743 44 29.3913V40.3478C44 42.3649 42.3649 44 40.3478 44H29.3913C27.3743 44 25.7391 42.3649 25.7391 40.3478V29.3913ZM30.3957 35.7826C30.3358 35.4876 30.3043 35.1823 30.3043 34.8696C30.3043 34.5569 30.3358 34.2515 30.3957 33.9565C30.8187 31.8727 32.661 30.3043 34.8696 30.3043C37.0782 30.3043 38.9205 31.8727 39.3435 33.9565H42.1739V29.3913C42.1739 28.3828 41.3563 27.5652 40.3478 27.5652H29.3913C28.3828 27.5652 27.5652 28.3828 27.5652 29.3913V33.9565V35.7826V40.3478C27.5652 41.3563 28.3828 42.1739 29.3913 42.1739H40.3478C41.3563 42.1739 42.1739 41.3563 42.1739 40.3478V35.7826H39.3435C38.9205 37.8664 37.0782 39.4348 34.8696 39.4348C32.661 39.4348 30.8187 37.8664 30.3957 35.7826ZM32.2863 33.9565C32.6623 32.8927 33.6769 32.1304 34.8696 32.1304C36.0622 32.1304 37.0768 32.8927 37.4528 33.9565C37.5538 34.2421 37.6087 34.5494 37.6087 34.8696C37.6087 35.1897 37.5538 35.497 37.4528 35.7826C37.0768 36.8465 36.0622 37.6087 34.8696 37.6087C33.6769 37.6087 32.6623 36.8465 32.2863 35.7826C32.1854 35.497 32.1304 35.1897 32.1304 34.8696C32.1304 34.5494 32.1854 34.2421 32.2863 33.9565Z'
            fill='currentColor'
        />
        <path
            d='M13.8696 5.65217H13.349C12.9189 5.65217 12.5075 5.72666 12.1319 5.86098C11.7416 6.00056 11.39 6.2051 11.0962 6.45834C10.8022 6.71157 10.5648 7.01447 10.4028 7.35074C10.2469 7.67431 10.2174 8.02878 10.2174 8.39937V10.2174H8.3913V12.0435H10.2174V16.6087H12.0435V12.0435H13.8696V10.2174H12.0435V8.3913C12.0435 7.88704 12.4523 7.47826 12.9565 7.47826H13.8696V5.65217Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M5.65217 2C3.63513 2 2 3.63513 2 5.65217V16.6087C2 18.6257 3.63513 20.2609 5.65217 20.2609H16.6087C18.6257 20.2609 20.2609 18.6257 20.2609 16.6087V5.65217C20.2609 3.63513 18.6257 2 16.6087 2H5.65217ZM16.6087 3.82609H5.65217C4.64365 3.82609 3.82609 4.64365 3.82609 5.65217V16.6087C3.82609 17.6172 4.64365 18.4348 5.65217 18.4348H16.6087C17.6172 18.4348 18.4348 17.6172 18.4348 16.6087V5.65217C18.4348 4.64365 17.6172 3.82609 16.6087 3.82609ZM13.349 5.65217H13.8696V7.47826H12.9565C12.4523 7.47826 12.0435 7.88704 12.0435 8.3913V10.2174H13.8696V12.0435H12.0435V16.6087H10.2174V12.0435H8.3913V10.2174H10.2174V8.39937C10.2174 8.02878 10.2469 7.67431 10.4028 7.35074C10.5648 7.01447 10.8022 6.71157 11.0962 6.45834C11.39 6.2051 11.7416 6.00056 12.1319 5.86098C12.5075 5.72666 12.9189 5.65217 13.349 5.65217Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 29.3913C2 27.3743 3.63513 25.7391 5.65217 25.7391H16.6087C18.6257 25.7391 20.2609 27.3743 20.2609 29.3913V40.3478C20.2609 42.3649 18.6257 44 16.6087 44H5.65217C3.63513 44 2 42.3649 2 40.3478V29.3913ZM5.65217 27.5652H16.6087C17.6172 27.5652 18.4348 28.3828 18.4348 29.3913V40.3478C18.4348 41.3563 17.6172 42.1739 16.6087 42.1739H5.65217C4.64365 42.1739 3.82609 41.3563 3.82609 40.3478V29.3913C3.82609 28.3828 4.64365 27.5652 5.65217 27.5652ZM15.1773 33.938L8.83522 30.4156C8.21179 30.0694 7.47826 30.573 7.47826 31.3472V38.3919C7.47826 39.1662 8.21179 39.6697 8.83522 39.3235L15.1773 35.8011C15.8684 35.4173 15.8684 34.3218 15.1773 33.938ZM12.9364 34.7764L9.48923 32.8619C9.42689 32.8273 9.35354 32.8776 9.35354 32.9551V36.7841C9.35354 36.8615 9.42689 36.9119 9.48923 36.8772L12.9364 34.9627C13.0055 34.9243 13.0055 34.8148 12.9364 34.7764Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M29.3913 2C27.3743 2 25.7391 3.63513 25.7391 5.65217V16.6087C25.7391 18.6257 27.3743 20.2609 29.3913 20.2609H40.3478C42.3649 20.2609 44 18.6257 44 16.6087V5.65217C44 3.63513 42.3649 2 40.3478 2H29.3913ZM33.0435 12.5L34.2642 13.3138C34.3598 13.3775 34.4275 13.4754 34.4534 13.5873C34.4916 13.7522 34.4343 13.9245 34.3051 14.0337L34.0845 14.2201C35.1661 13.9464 38.0652 12.5 38.0652 9.30435C37.8893 8.75604 37.6087 8.3913 36.9865 8.3913C36.2391 8.3913 35.7826 8.84783 35.7826 9.30435L35.6 10.2174C35.5998 10.231 35.5975 10.2437 35.5935 10.2555C35.3754 11.2871 32.1193 10.2071 31.1905 9.35065C31.1946 10.158 31.5611 11.5148 33.0435 12.5ZM40.3478 3.82609H29.3913C28.3828 3.82609 27.5652 4.64365 27.5652 5.65217V16.6087C27.5652 17.6172 28.3828 18.4348 29.3913 18.4348H40.3478C41.3563 18.4348 42.1739 17.6172 42.1739 16.6087V5.65217C42.1739 4.64365 41.3563 3.82609 40.3478 3.82609ZM34.3104 9.20406C34.3364 7.74222 35.5246 6.56522 36.9865 6.56522C37.7312 6.56522 38.4049 6.87062 38.8901 7.3635C38.9505 7.36305 39.0095 7.36505 39.0689 7.36706C39.3914 7.37799 39.7241 7.38926 40.3478 7.00931C40.1653 7.90909 40.0747 8.29961 39.6848 8.83665C39.6848 13.0282 37.1191 15.0666 34.5156 15.5883C32.7307 15.946 30.1348 15.3587 29.3913 14.5783C29.7701 14.5491 31.3108 14.3827 32.201 13.7284C32.1778 13.713 32.1526 13.6965 32.1255 13.6787C31.2683 13.1174 28.5101 11.3108 30.4195 6.69487C31.3446 7.7791 32.2824 8.51712 33.2329 8.90893C33.7894 9.13833 33.9763 9.16177 34.2109 9.19119C34.2428 9.19519 34.2756 9.1993 34.3104 9.20406Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M36.9865 6.56522C35.5246 6.56522 34.3364 7.74222 34.3104 9.20406C34.2756 9.1993 34.2428 9.19519 34.2109 9.19119C33.9763 9.16177 33.7894 9.13833 33.2329 8.90893C32.2824 8.51712 31.3446 7.7791 30.4195 6.69487C28.5101 11.3108 31.2683 13.1174 32.1255 13.6787C32.1526 13.6965 32.1778 13.713 32.201 13.7284C31.3108 14.3827 29.7701 14.5491 29.3913 14.5783C30.1348 15.3587 32.7307 15.946 34.5156 15.5883C37.1191 15.0666 39.6848 13.0282 39.6848 8.83665C40.0747 8.29961 40.1653 7.90909 40.3478 7.00931C39.7241 7.38926 39.3914 7.37799 39.0689 7.36706C39.0095 7.36505 38.9505 7.36305 38.8901 7.3635C38.4049 6.87062 37.7312 6.56522 36.9865 6.56522ZM34.2642 13.3138L33.0435 12.5C31.5611 11.5148 31.1946 10.158 31.1905 9.35065C32.1193 10.2071 35.3754 11.2871 35.5935 10.2555C35.5975 10.2437 35.5998 10.231 35.6 10.2174L35.7826 9.30435C35.7826 8.84783 36.2391 8.3913 36.9865 8.3913C37.6087 8.3913 37.8893 8.75604 38.0652 9.30435C38.0652 12.5 35.1661 13.9464 34.0845 14.2201L34.3051 14.0337C34.4343 13.9245 34.4916 13.7522 34.4534 13.5873C34.4275 13.4754 34.3598 13.3775 34.2642 13.3138Z'
            fill='currentColor'
        />
    </svg>
)

export default SmmIcon
