import { MutableRefObject, useEffect } from 'react'

interface IUseClickedOutsideHandler {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: MutableRefObject<any>
    func: () => void
}

export const useClickedOutsideHandler = ({
    ref,
    func,
}: IUseClickedOutsideHandler): void => {
    useEffect(() => {
        // TODO
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [func, ref])
}
