import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { CHAT_URLS } from 'config/urls'

import { IChat, IChatMultilang } from '../store/types'

export const getChats = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IChat[]>> =>
    axiosInstance.get(`${appId}${CHAT_URLS.main}/language/${language}`)

export const getChatById = ({
    appId,
    chatId,
}: {
    appId: string
    chatId: string
}) => axiosInstance.get(`${appId}${CHAT_URLS.main}/${chatId}`)

export const editChat = ({
    item,
    appId,
    id,
}: {
    item: IChat
    appId: string
    id: string
}): Promise<AxiosResponse<IChat>> =>
    axiosInstance.put(`${appId}${CHAT_URLS.main}/${id}`, item)

export const createChat = ({
    item,
    appId,
}: {
    item: IChatMultilang
    appId: string
}): Promise<AxiosResponse<IChat>> =>
    axiosInstance.post(`${appId}${CHAT_URLS.main}`, item)

export const deleteChat = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<IChat>> =>
    axiosInstance.delete(`${appId}${CHAT_URLS.main}/${id}`)
