import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { applicationTypes } from './actionTypes'
import {
    getApplications,
    getApplicationById,
    getApplicationTypes,
    updateApplication,
    createApplication,
    deleteApplication,
} from '../services/applications'

import {
    IApplication,
    IAppTypes,
    FetchAppsRequest,
    FetchApplicationByIdRequest,
    FetchAppTypesRequest,
    CreateAppsRequest,
    EditAppsRequest,
} from './types'

function* fetchApplicationsSaga(action: FetchAppsRequest) {
    try {
        const response: AxiosResponse<IApplication[]> = yield call(
            getApplications,
        )
        if (response.data) {
            if (action.payload?.callback) {
                action.payload.callback('success', undefined, response.data)
            }
            yield put({
                type: applicationTypes.SET_APPLICATIONS,
                payload: { applications: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchAppTypesSaga(action: FetchAppTypesRequest) {
    try {
        const response: AxiosResponse<IPagination<IAppTypes[]>> = yield call(
            getApplicationTypes,
            action.payload,
        )
        if (response.data) {
            console.log(action)
            yield put({
                type: applicationTypes.SET_APP_TYPES,
                payload: { appTypes: response.data.items },
            })
        }
    } catch (error) {
        console.log(error)
        // sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchApplicationByIdSaga(action: FetchApplicationByIdRequest) {
    try {
        const response: AxiosResponse<IApplication> = yield call(
            getApplicationById,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: applicationTypes.SET_APPLICATION_DETAILS,
                payload: { applicationDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* updateApplicationSaga(action: EditAppsRequest) {
    try {
        const response: AxiosResponse<IApplication> = yield call(
            updateApplication,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createApplicationSaga(action: CreateAppsRequest) {
    try {
        const response: AxiosResponse<IApplication> = yield call(
            createApplication,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success', undefined, response.data)
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteApplicationSaga(action: EditAppsRequest) {
    try {
        const response: AxiosResponse<IApplication> = yield call(
            deleteApplication,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { applications } = yield select(state => state.applications)
            const updatedApps = applications.filter(
                (application: IApplication) =>
                    application.id !== action.payload.appId,
            )
            yield put({
                type: applicationTypes.SET_APPLICATIONS,
                payload: { applications: updatedApps },
            })
        }
    } catch (error) {
        console.log(error)
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* applicationsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            applicationTypes.FETCH_APPLICATIONS_REQUEST,
            fetchApplicationsSaga,
        ),
        takeLatest(applicationTypes.FETCH_APPLICATION_TYPES, fetchAppTypesSaga),
        takeLatest(
            applicationTypes.FETCH_APPLICATION_BY_ID,
            fetchApplicationByIdSaga,
        ),
        takeLatest(applicationTypes.DELETE_APPLICATION, deleteApplicationSaga),
        takeLatest(applicationTypes.CREATE_APPLICATION, createApplicationSaga),
        takeLatest(applicationTypes.EDIT_APPLICATION, updateApplicationSaga),
    ])
}

export default applicationsSaga
