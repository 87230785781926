import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { servicesTypes } from './actionTypes'
import {
    getServices,
    getServiceById,
    createServices,
    editService,
    deleteService,
} from '../service/services'

import {
    IService,
    FetchServicesRequest,
    FetchServiceByIdRequest,
    CreateServicesRequest,
    EditServiceRequest,
    DeleteServiceRequest,
    IServiceMultiLang,
} from './types'

function* fetchServicesSaga(action: FetchServicesRequest) {
    try {
        const response: AxiosResponse<IPagination<IService>> = yield call(
            getServices,
            action.payload,
        )

        if (response.data) {
            const updatedData = response.data.items.map(service => ({
                ...service,
                hours: service?.duration
                    ? Math.floor(service.duration / 60)
                    : 0,
                minutes: service?.duration ? service.duration % 60 : 0,
            }))

            const updatedResponse = { ...response.data, items: updatedData }

            yield put({
                type: servicesTypes.SET_SERVICES,
                payload: { services: updatedData },
            })
            action.payload.callback('success', updatedResponse)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchServiceByIdSaga(action: FetchServiceByIdRequest) {
    try {
        const response: AxiosResponse<IService> = yield call(
            getServiceById,
            action.payload,
        )

        const updateService = (data: IService) => ({
            ...data,
            hours: response.data?.duration
                ? Math.floor(response.data.duration / 60)
                : 0,
            minutes: response.data?.duration ? response.data.duration % 60 : 0,
        })

        if (response.data) {
            if (action.payload.isEdit) {
                const { relativeId } = response.data

                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'

                let relativeService: IService = {
                    id: '3',
                    name: '',
                    description: '',
                    price: '',
                    imageUrl: '',
                    currency: 'none',
                    hours: undefined,
                    minutes: undefined,
                    language: oppositeLang,
                    duration: 0,
                }
                if (relativeId) {
                    const props = {
                        appId: action.payload.appId,
                        serviceId: relativeId,
                    }

                    const relativeResp: AxiosResponse<IService> = yield call(
                        getServiceById,
                        props,
                    )

                    if (relativeResp.data) {
                        relativeService = updateService(relativeResp.data)
                    }
                }
                yield put({
                    type: servicesTypes.SET_RELATIVE_SERVICE,
                    payload: { relativeService },
                })
            }
            const updatedService = updateService(response.data)

            yield put({
                type: servicesTypes.SET_SERVICE,
                payload: { service: updatedService },
            })

            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createServicesSaga(action: CreateServicesRequest) {
    try {
        const response: AxiosResponse<IServiceMultiLang[]> = yield call(
            createServices,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editServiceSaga(action: EditServiceRequest) {
    try {
        const response: AxiosResponse<IService> = yield call(
            editService,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteServiceSaga(action: DeleteServiceRequest) {
    try {
        const response: AxiosResponse<IService> = yield call(
            deleteService,
            action.payload,
        )
        if (response.status === 200) {
            const { services } = yield select(state => state.services)
            const newServices = services.filter(
                (item: IService) => item.id !== action.payload.serviceId,
            )

            yield put({
                type: servicesTypes.SET_SERVICES,
                payload: {
                    bookings: newServices,
                },
            })

            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* servicesSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(servicesTypes.FETCH_SERVICES_REQUEST, fetchServicesSaga),
    ])
    yield all([
        takeLatest(servicesTypes.FETCH_SERVICE_BY_ID, fetchServiceByIdSaga),
    ])
    yield all([
        takeLatest(servicesTypes.CREATE_SERVICES_REQUEST, createServicesSaga),
    ])
    yield all([takeLatest(servicesTypes.EDIT_SERVICE_REQUEST, editServiceSaga)])
    yield all([
        takeLatest(servicesTypes.DELETE_SERVICE_REQUEST, deleteServiceSaga),
    ])
}

export default servicesSaga
