import React from 'react'

export const TodoIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.65 29.1994C13.6492 29.0602 13.674 28.9223 13.7228 28.7941C13.7671 28.6721 13.8342 28.5619 13.9196 28.4709C14.0054 28.3787 14.1053 28.3045 14.2148 28.2519C14.3292 28.1974 14.4525 28.1698 14.5769 28.1708H17.0554C17.47 28.1695 17.8829 28.2318 18.2823 28.3559C19.4554 28.7202 20.4595 29.5693 21.0894 30.7297C21.2974 31.1128 21.4595 31.5245 21.5716 31.9544C21.6874 32.3967 21.7458 32.8552 21.7447 33.316C21.749 34.0005 21.6205 34.6779 21.3679 35.302C20.8735 36.533 19.9752 37.5072 18.8569 38.0252C18.2868 38.2928 17.674 38.4292 17.0554 38.4261H14.5857C14.4614 38.4269 14.3383 38.3997 14.2236 38.3461C14.1144 38.293 14.0145 38.2189 13.9285 38.1271C13.8429 38.0341 13.7758 37.9221 13.7317 37.7984C13.6829 37.671 13.6581 37.5338 13.6589 37.3953V29.1994H13.65ZM15.5195 36.3437V30.2565L17.1036 30.2543C17.4679 30.2534 17.8284 30.3381 18.1613 30.5029C18.8255 30.8276 19.3566 31.4173 19.65 32.1559C19.7985 32.5253 19.8746 32.9256 19.8733 33.3302C19.8744 33.7288 19.7982 34.123 19.65 34.4859C19.5049 34.8457 19.2999 35.1717 19.0458 35.4466C18.7893 35.7242 18.4892 35.947 18.1603 36.1038C17.8261 36.2633 17.4662 36.3449 17.1026 36.3437H15.5195Z'
            fill='currentColor'
        />
        <path
            d='M11.25 5.65217H34.75C35.3023 5.65217 35.75 6.06096 35.75 6.56522C35.75 7.06948 35.3023 7.47826 34.75 7.47826H11.25C10.6977 7.47826 10.25 7.06948 10.25 6.56522C10.25 6.06096 10.6977 5.65217 11.25 5.65217Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.6978 12.9565C27.1185 12.9556 27.5374 13.0194 27.9424 13.146C28.3328 13.2683 28.7079 13.4442 29.0592 13.6696C29.4061 13.8939 29.7253 14.1675 30.0087 14.4835C30.2929 14.7996 30.5396 15.1548 30.7427 15.5406C30.9501 15.9316 31.1118 16.3505 31.2238 16.7872C31.5049 17.9176 31.4356 19.1205 31.027 20.2016C30.7881 20.8193 30.4462 21.3809 30.0195 21.8568C29.5923 22.3325 29.0869 22.7122 28.5308 22.9752C27.9533 23.2449 27.3334 23.3838 26.7071 23.3838C26.0809 23.3838 25.4609 23.2449 24.8834 22.9752C24.3286 22.7137 23.8252 22.3338 23.4016 21.8568C22.9794 21.3802 22.6425 20.8185 22.4089 20.2016C22.0041 19.1195 21.9348 17.9181 22.2121 16.7872C22.3195 16.3512 22.4766 15.9323 22.6794 15.5406C22.8801 15.1553 23.1241 14.8001 23.4056 14.4835C23.6865 14.1676 24.0034 13.894 24.3482 13.6696C24.6985 13.4436 25.073 13.2676 25.463 13.146C25.8649 13.0204 26.2803 12.9566 26.6978 12.9565ZM25.6155 15.2876C25.9591 15.1213 26.33 15.0362 26.7047 15.0378L26.7106 15.0389C27.0884 15.0366 27.4626 15.1212 27.8096 15.2876C28.4839 15.6062 29.0203 16.2046 29.3052 16.9559C29.4523 17.3406 29.5267 17.7548 29.5236 18.1729C29.5269 18.5963 29.4526 19.016 29.3052 19.4064C29.1651 19.7782 28.9609 20.1157 28.704 20.3999C28.4412 20.6886 28.1299 20.9168 27.7879 21.0715C27.4458 21.2262 27.0797 21.3043 26.7106 21.3014C26.3433 21.3044 25.9791 21.2263 25.6391 21.0715C25.2992 20.9168 24.9901 20.6885 24.7299 20.3999C24.2035 19.8072 23.9095 19.0064 23.9123 18.1729C23.9102 17.7546 23.9856 17.3403 24.1337 16.9559C24.275 16.5855 24.4772 16.2478 24.7299 15.9602C24.984 15.6753 25.2845 15.447 25.6155 15.2876Z'
            fill='currentColor'
        />
        <path
            d='M18.6828 15.1189V22.2632C18.6836 22.4019 18.6581 22.5393 18.608 22.6663C18.5605 22.7894 18.492 22.901 18.4063 22.9949C18.3212 23.088 18.221 23.1623 18.1111 23.214C17.9947 23.2672 17.87 23.2944 17.7441 23.294C17.6198 23.2948 17.4966 23.2676 17.382 23.214C17.2722 23.1623 17.172 23.0879 17.0868 22.9949C17.002 22.9013 16.9351 22.7895 16.8901 22.6663C16.8417 22.5388 16.8172 22.4016 16.8182 22.2632V15.1189H14.9203C14.7955 15.1195 14.672 15.0903 14.5582 15.0334C14.4479 14.98 14.3478 14.9038 14.263 14.8089C14.1788 14.7147 14.1119 14.603 14.0662 14.4803C14.0179 14.3508 13.9935 14.2118 13.9944 14.0717C13.9934 13.9325 14.0178 13.7946 14.0662 13.6664C14.1125 13.5439 14.1793 13.4324 14.263 13.3377C14.4379 13.1451 14.6742 13.0375 14.9203 13.0387H20.5739C20.6994 13.0375 20.8238 13.0659 20.9389 13.1219C21.0481 13.175 21.148 13.2492 21.2341 13.341C21.3189 13.4347 21.3858 13.5465 21.4309 13.6696C21.4787 13.7993 21.5031 13.9381 21.5027 14.0782C21.5033 14.2166 21.4789 14.3537 21.4309 14.4814C21.3855 14.6043 21.3186 14.7161 21.2341 14.81C21.1484 14.9023 21.0484 14.9766 20.9389 15.0291C20.8234 15.0831 20.6992 15.1103 20.5739 15.109L18.6828 15.1189Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6 7.47826C6 4.4527 8.28335 2 11.1 2H34.9C37.7167 2 40 4.4527 40 7.47826V38.5217C40 41.5473 37.7167 44 34.9 44H11.1C8.28335 44 6 41.5473 6 38.5217V7.47826ZM34.9 3.82609H11.1C9.22223 3.82609 7.7 5.46122 7.7 7.47826V38.5217C7.7 40.5388 9.22223 42.1739 11.1 42.1739H34.9C36.7778 42.1739 38.3 40.5388 38.3 38.5217V7.47826C38.3 5.46122 36.7778 3.82609 34.9 3.82609ZM13.65 29.1994C13.6492 29.0602 13.674 28.9223 13.7228 28.7941C13.7671 28.6721 13.8342 28.5619 13.9196 28.4709C14.0054 28.3787 14.1053 28.3045 14.2148 28.2519C14.3292 28.1974 14.4525 28.1698 14.5769 28.1708H17.0554C17.47 28.1695 17.8829 28.2318 18.2823 28.3559C19.4554 28.7202 20.4595 29.5693 21.0894 30.7297C21.2974 31.1128 21.4595 31.5245 21.5716 31.9544C21.6874 32.3967 21.7458 32.8552 21.7447 33.316C21.749 34.0005 21.6205 34.6779 21.3679 35.302C20.8735 36.533 19.9752 37.5072 18.8569 38.0252C18.2868 38.2928 17.674 38.4292 17.0554 38.4261H14.5857C14.4614 38.4269 14.3383 38.3997 14.2236 38.3461C14.1144 38.293 14.0145 38.2189 13.9285 38.1271C13.8429 38.0341 13.7758 37.9221 13.7317 37.7984C13.6829 37.671 13.6581 37.5338 13.6589 37.3953V29.1994H13.65ZM15.5195 36.3437V30.2565L17.1036 30.2543C17.4679 30.2534 17.8284 30.3381 18.1613 30.5029C18.8255 30.8276 19.3566 31.4173 19.65 32.1559C19.7985 32.5253 19.8746 32.9256 19.8733 33.3302C19.8744 33.7288 19.7982 34.123 19.65 34.4859C19.5049 34.8457 19.2999 35.1717 19.0458 35.4466C18.7893 35.7242 18.4892 35.947 18.1603 36.1038C17.8261 36.2633 17.4662 36.3449 17.1026 36.3437H15.5195Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.6581 28.0941C28.0788 28.0932 28.4977 28.157 28.9027 28.2836C29.2942 28.4054 29.6703 28.5813 30.0224 28.8072C30.3693 29.0315 30.6885 29.3051 30.9719 29.6211C31.2561 29.9371 31.5028 30.2924 31.7059 30.6782C31.9112 31.0699 32.0705 31.4892 32.1802 31.9259C32.4612 33.0563 32.3919 34.2592 31.9834 35.3403C31.7445 35.9576 31.4026 36.5189 30.9759 36.9944C30.5485 37.4701 30.0431 37.8501 29.4872 38.1139C28.9096 38.3831 28.2897 38.5217 27.6635 38.5217C27.0373 38.5217 26.4174 38.3831 25.8398 38.1139C25.2852 37.8516 24.7818 37.4713 24.358 36.9944C23.9351 36.5194 23.5971 35.9592 23.3623 35.3436C23.1178 34.7018 22.9939 34.011 22.9982 33.3138C22.9967 32.846 23.0524 32.3803 23.1635 31.9292C23.2708 31.4928 23.4279 31.0735 23.6309 30.6815C23.8316 30.2962 24.0756 29.9409 24.357 29.6244C24.638 29.3085 24.9549 29.0349 25.2996 28.8105C25.6499 28.5844 26.0245 28.4085 26.4144 28.2869C26.8195 28.1603 27.2383 28.0965 27.6591 28.0974L27.6581 28.0941ZM26.5718 30.4241C26.9167 30.2575 27.289 30.1729 27.665 30.1754L27.666 30.1765C28.0434 30.1743 28.4173 30.259 28.764 30.4252C29.4374 30.7425 29.9736 31.3388 30.2596 32.088C30.4066 32.4727 30.481 32.887 30.478 33.305C30.4813 33.7284 30.407 34.1481 30.2596 34.5385C30.1195 34.9103 29.9153 35.2478 29.6584 35.532C29.1275 36.1148 28.4106 36.4394 27.665 36.4346C27.2897 36.4371 26.9179 36.3551 26.5718 36.1936C26.2411 36.0407 25.9403 35.8175 25.6863 35.5364C25.1599 34.9437 24.8659 34.1429 24.8687 33.3094C24.8667 32.8911 24.942 32.4769 25.0901 32.0924C25.2313 31.722 25.4336 31.3843 25.6863 31.0967C25.9403 30.8118 26.2409 30.5835 26.5718 30.4241Z'
            fill='currentColor'
        />
    </svg>
)

export default TodoIcon
