import { applicationTypes } from './actionTypes'
import { ApplicationActions, ApplicationsState } from './types'

const initialState: ApplicationsState = {
    applications: undefined,
    applicationDetails: undefined,
    appTypes: undefined,
}

export default (
    state = initialState,
    action: ApplicationActions,
): ApplicationsState => {
    switch (action.type) {
        case applicationTypes.SET_APPLICATION_DETAILS:
            return {
                ...state,
                applicationDetails: action.payload.applicationDetails,
            }
        case applicationTypes.SET_APPLICATIONS:
            return {
                ...state,
                applications: action.payload.applications,
            }
        case applicationTypes.SET_APP_TYPES:
            return {
                ...state,
                appTypes: action.payload.appTypes,
            }
        default:
            return {
                ...state,
            }
    }
}
