import React from 'react'

export const PhoneBig = (): React.ReactElement => (
    <svg
        width='428'
        height='877'
        viewBox='0 0 428 877'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='new-loader__iphone'
    >
        <path
            d='M361.973 0H66.2568C31.2684 0 2.90576 28.6349 2.90576 63.959V812.925C2.90576 848.249 31.2684 876.884 66.2568 876.884H361.973C396.961 876.884 425.324 848.249 425.324 812.925V63.959C425.324 28.6349 396.961 0 361.973 0ZM409.399 816.093C409.399 836.748 387.545 859.28 367.552 859.28H60.7935C40.8001 859.28 18.9469 836.631 18.9469 816.093V60.0863C18.9469 39.4316 40.8001 16.8993 60.7935 16.8993H98.8041C100.315 16.8993 101.594 18.0728 101.594 19.5984V24.2927C101.594 31.5688 107.987 47.4118 127.051 47.4118H302.69C319.893 47.4118 327.333 31.8035 327.333 23.8233V19.5984C327.333 18.1902 328.612 16.8993 330.123 16.8993H367.436C387.429 16.8993 409.282 39.549 409.282 60.0863V816.093H409.399Z'
            fill='#737373'
        />
        <path
            d='M358.949 4.69312H69.3939C35.103 4.69312 7.32153 32.9759 7.32153 67.948V808.934C7.32153 843.906 35.103 872.188 69.3939 872.188H358.949C393.24 872.188 421.021 843.788 421.021 808.934V68.0653C421.021 33.0933 393.24 4.81047 358.949 4.81047V4.69312ZM409.397 816.092C409.397 836.747 387.544 859.279 367.551 859.279H60.7922C40.7988 859.279 18.9456 836.63 18.9456 816.092V60.0851C18.9456 39.4305 40.7988 16.8981 60.7922 16.8981H98.8028C100.314 16.8981 101.593 18.0717 101.593 19.5973V24.2916C101.593 31.5676 107.986 47.4107 127.049 47.4107H302.689C319.892 47.4107 327.332 31.8023 327.332 23.8221V19.5973C327.332 18.1891 328.61 16.8981 330.121 16.8981H367.435C387.428 16.8981 409.281 39.5478 409.281 60.0851V816.092H409.397Z'
            fill='#0D0D0D'
        />
        <path
            opacity='0.6'
            d='M386.852 11.7337C389.874 13.142 392.664 14.7849 395.337 16.6626C403.939 19.8313 410.797 24.8776 416.493 32.5057C412.541 25.6991 407.31 19.8313 401.149 14.9023C396.616 12.0858 392.547 11.2643 386.852 11.7337Z'
            fill='url(#paint0_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M423.698 431.165V64.7798C423.698 53.0442 420.444 42.0127 414.864 32.5069C409.168 24.8787 402.31 19.8324 393.708 16.6638C408.82 27.2259 418.816 44.8293 418.816 64.7798V431.282H423.698V431.165Z'
            fill='url(#paint1_linear_3054_7601)'
        />
        <path
            opacity='0.5'
            d='M42.0822 11.7335C39.06 13.1417 36.2702 14.7847 33.5967 16.6624C24.9949 19.831 18.1367 24.8773 12.4409 32.5055C16.3931 25.6988 21.6239 19.831 27.7847 14.9021C32.318 12.0855 36.3864 11.264 42.0822 11.7335Z'
            fill='url(#paint2_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M5.23291 429.991V64.7795C5.23291 53.0439 8.48767 42.0125 14.0672 32.5066C19.763 24.8785 26.6212 19.8322 35.223 16.6636C20.1117 27.2256 10.115 44.829 10.115 64.7795V430.109H5.23291V429.991Z'
            fill='url(#paint3_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M361.043 1.52466H67.7683C53.2382 1.52466 39.8706 6.57097 29.2927 14.9032C33.8261 12.0867 37.8945 11.2652 43.5903 11.7346C50.9134 8.33131 59.1665 6.33625 67.7683 6.33625H361.043C369.645 6.33625 377.898 8.33131 385.221 11.7346C390.917 11.2652 394.985 12.0867 399.519 14.9032C388.941 6.45361 375.573 1.52466 361.043 1.52466Z'
            fill='url(#paint4_linear_3054_7601)'
        />
        <path
            d='M386.733 865.15C389.755 863.742 392.545 862.099 395.218 860.221C403.82 857.053 410.678 852.006 416.374 844.378C412.422 851.185 407.191 857.053 401.03 861.982C396.497 864.798 392.429 865.62 386.733 865.15Z'
            fill='url(#paint5_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M423.698 431.051V812.223C423.698 823.959 420.444 834.99 414.864 844.496C409.168 852.124 402.31 857.171 393.708 860.339C408.82 849.777 418.816 832.174 418.816 812.223V431.051H423.698Z'
            fill='url(#paint6_linear_3054_7601)'
        />
        <path
            opacity='0.5'
            d='M42.4292 865.15C39.4069 863.742 36.6171 862.099 33.9436 860.221C25.3418 857.053 18.4836 852.006 12.7878 844.378C16.74 851.185 21.9708 857.053 28.1316 861.982C32.665 864.798 36.7334 865.62 42.4292 865.15Z'
            fill='url(#paint7_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M5.23291 429.759V812.222C5.23291 823.958 8.48767 834.989 14.0672 844.495C19.763 852.123 26.6212 857.169 35.223 860.338C20.1117 849.776 10.115 832.173 10.115 812.222V429.759H5.23291Z'
            fill='url(#paint8_linear_3054_7601)'
        />
        <path
            opacity='0.3'
            d='M361.043 875.478H67.7683C53.2382 875.478 39.8706 870.432 29.2927 862.1C33.8261 864.916 37.8945 865.738 43.5903 865.268C50.9134 868.672 59.1665 870.667 67.7683 870.667H361.043C369.645 870.667 377.898 868.672 385.221 865.268C390.917 865.738 394.985 864.916 399.519 862.1C388.941 870.549 375.573 875.478 361.043 875.478Z'
            fill='url(#paint9_linear_3054_7601)'
        />
        <path
            opacity='0.5'
            d='M362.205 868.785H67.5351C36.3826 868.785 11.1584 843.319 11.1584 811.867V64.0751C11.1584 32.8584 36.2664 7.50952 67.1864 7.50952H361.391C417.419 7.50952 417.884 65.9528 417.884 64.5445V812.689C417.884 843.671 393.008 868.785 362.321 868.785H362.205ZM66.8377 9.50457C37.1963 9.50457 13.1345 33.7973 13.1345 63.723V811.867C13.1345 842.262 37.545 866.907 67.6513 866.907H362.321C391.962 866.907 415.908 842.615 415.908 812.806V64.5445C415.908 34.1493 391.497 9.50457 361.391 9.50457H66.8377Z'
            fill='url(#paint10_linear_3054_7601)'
        />
        <g opacity='0.6'>
            <path
                d='M248.057 26.1695C248.057 26.1695 247.709 25.4653 247.476 25.1133L247.244 25.348L248.057 26.1695Z'
                fill='#1A1A1A'
            />
            <path
                d='M193.655 29.1042L192.608 30.0431L193.422 30.8646H193.887L194.585 30.0431L193.655 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M191.797 30.8682H192.262L192.029 30.6311L191.797 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M242.034 27.5705L241.046 28.5691L242.035 29.5666L243.023 28.568L242.034 27.5705Z'
                fill='#1A1A1A'
            />
            <path
                d='M238.816 27.565L237.827 28.563L238.816 29.561L239.804 28.563L238.816 27.565Z'
                fill='#1A1A1A'
            />
            <path
                d='M218.513 25.9027L217.525 26.9006L218.513 27.8987L219.502 26.9007L218.513 25.9027Z'
                fill='#1A1A1A'
            />
            <path
                d='M246.893 24.5281C246.893 24.5281 246.079 23.9413 245.615 23.824L246.544 24.7628L246.777 24.5281H246.893Z'
                fill='#1A1A1A'
            />
            <path
                d='M199.816 29.1042L198.886 30.0431L199.583 30.8646H200.048L200.862 30.0431L199.816 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.516 29.1042L189.47 30.0431L190.283 30.8646H190.748L191.446 30.0431L190.516 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M194.935 30.8682H195.4L195.168 30.6311L194.935 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M243.52 29.1042L242.59 30.0431L243.287 30.8646H243.752L244.566 30.0431L243.52 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M198.069 30.8682H198.534L198.301 30.6311L198.069 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M247.709 28.9879C247.709 28.9879 248.174 28.049 248.174 27.5796L247.244 28.5184L247.709 28.9879Z'
                fill='#1A1A1A'
            />
            <path
                d='M245.615 30.0431L246.079 30.5125C246.079 30.5125 246.893 30.0431 247.242 29.691L246.661 29.1042L245.615 30.1604V30.0431Z'
                fill='#1A1A1A'
            />
            <path
                d='M196.677 29.1042L195.747 30.0431L196.444 30.8646H196.909L197.723 30.0431L196.677 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M221.664 25.8911L220.675 26.8892L221.664 27.8872L222.652 26.8891L221.664 25.8911Z'
                fill='#1A1A1A'
            />
            <path
                d='M234.219 24.7624L235.149 23.8236L235.033 23.5889H233.289L233.173 23.8236L234.219 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M226.335 27.6072L225.346 28.6052L226.335 29.6032L227.323 28.6053L226.335 27.6072Z'
                fill='#1A1A1A'
            />
            <path
                d='M212.298 25.8428L211.31 26.8408L212.298 27.8388L213.287 26.8408L212.298 25.8428Z'
                fill='#1A1A1A'
            />
            <path
                d='M184.238 29.1044L183.192 30.1606L183.89 30.8648C183.89 30.8648 184.006 30.8648 184.122 30.8648H184.355L185.168 30.0433L184.122 28.9871L184.238 29.1044Z'
                fill='#1A1A1A'
            />
            <path
                d='M229.485 27.5957L228.497 28.5938L229.485 29.5918L230.474 28.5937L229.485 27.5957Z'
                fill='#1A1A1A'
            />
            <path
                d='M222.247 28.5189L223.293 29.5751L224.223 28.5189L223.293 27.4626L222.247 28.5189Z'
                fill='#1A1A1A'
            />
            <path
                d='M243.52 24.7624L244.566 23.8236L244.449 23.5889H242.706L242.59 23.8236L243.52 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M182.624 27.5637L181.637 28.563L182.626 29.5598L183.614 28.5605L182.624 27.5637Z'
                fill='#1A1A1A'
            />
            <path
                d='M231.081 24.7624L232.127 23.8236L231.894 23.5889H230.151L230.034 23.8236L231.081 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.377 29.1042L186.331 30.0431L187.145 30.8646H187.61L188.423 30.0431L187.377 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M235.712 27.439L234.723 28.4365L235.711 29.4351L236.7 28.4375L235.712 27.439Z'
                fill='#1A1A1A'
            />
            <path
                d='M240.38 24.7624L241.427 23.8236L241.31 23.5889H239.567L239.45 23.8236L240.38 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M215.364 25.9141L214.375 26.9121L215.364 27.9101L216.352 26.9121L215.364 25.9141Z'
                fill='#1A1A1A'
            />
            <path
                d='M188.658 30.8682H189.123L188.89 30.6311L188.658 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M231.663 28.5189L232.593 29.5751L233.639 28.5189L232.593 27.4626L231.663 28.5189Z'
                fill='#1A1A1A'
            />
            <path
                d='M237.358 24.7624L238.288 23.8236L238.172 23.5889H236.428L236.312 23.8236L237.358 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.633 30.8682H185.983L185.749 30.6311L185.633 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M238.639 30.8682H239.104L238.872 30.6311L238.639 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M224.809 29.1042L223.763 30.0431L224.576 30.8646H225.041L225.855 30.0431L224.809 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M234.219 27.9306L235.149 26.9917L234.219 25.9355L233.173 26.9917L234.219 27.9306Z'
                fill='#1A1A1A'
            />
            <path
                d='M210.51 30.8682H210.975L210.743 30.6311L210.51 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M223.065 30.8682H223.53L223.297 30.6311L223.065 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M227.845 25.8716L226.856 26.8696L227.845 27.8676L228.833 26.8696L227.845 25.8716Z'
                fill='#1A1A1A'
            />
            <path
                d='M227.947 29.1042L226.901 30.0431L227.715 30.8646H228.18L228.994 30.0431L227.947 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M226.203 30.8682H226.554L226.437 30.6311L226.203 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M241.778 30.8682H242.243L242.01 30.6311L241.778 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M215.506 29.1042L214.46 30.0431L215.274 30.8646H215.739L216.436 30.0431L215.506 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M237.21 25.9227L236.221 26.9207L237.21 27.9187L238.198 26.9207L237.21 25.9227Z'
                fill='#1A1A1A'
            />
            <path
                d='M216.787 30.8682H217.252L217.02 30.6311L216.787 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M218.529 29.1042L217.599 30.0431L218.296 30.8646H218.761L219.575 30.0431L218.529 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M230.995 25.8601L230.007 26.8582L230.995 27.8562L231.984 26.8581L230.995 25.8601Z'
                fill='#1A1A1A'
            />
            <path
                d='M212.367 29.1042L211.321 30.0431L212.135 30.8646H212.6L213.297 30.0431L212.367 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M221.667 29.1042L220.738 30.0431L221.435 30.8646H221.9L222.714 30.0431L221.667 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M219.926 30.8682H220.391L220.158 30.6311L219.926 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M209.229 29.1042L208.183 30.0431L208.997 30.8646H209.462L210.275 30.0431L209.229 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M202.951 29.1042L201.905 30.0431L202.719 30.8646H203.184L203.998 30.0431L202.951 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M237.358 29.1042L236.312 30.0431L237.009 30.8646H237.591L238.288 30.0431L237.358 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M227.947 24.7624L228.994 23.8236L228.761 23.5889H227.134L226.901 23.8236L227.947 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M224.741 25.8606L223.752 26.8582L224.74 27.8567L225.729 26.8592L224.741 25.8606Z'
                fill='#1A1A1A'
            />
            <path
                d='M229.223 30.8682H229.688L229.455 30.6311L229.223 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M243.52 27.9306L244.566 26.9917L243.52 25.9355L242.59 26.9917L243.52 27.9306Z'
                fill='#1A1A1A'
            />
            <path
                d='M240.38 29.1042L239.45 30.0431L240.148 30.8646H240.613L241.427 30.0431L240.38 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M235.5 30.8682H235.965L235.733 30.6311L235.5 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M201.207 30.8682H201.672L201.439 30.6311L201.207 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M231.081 29.1042L230.034 30.0431L230.848 30.8646H231.313L232.127 30.0431L231.081 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M206.09 29.1042L205.044 30.0431L205.857 30.8646H206.322L207.136 30.0431L206.09 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M207.371 30.8682H207.836L207.603 30.6311L207.371 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M204.346 30.8682H204.696L204.58 30.6311L204.346 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M234.219 29.1042L233.173 30.0431L233.987 30.8646H234.452L235.149 30.0431L234.219 29.1042Z'
                fill='#1A1A1A'
            />
            <path
                d='M240.359 25.9111L239.371 26.9092L240.359 27.9072L241.348 26.9092L240.359 25.9111Z'
                fill='#1A1A1A'
            />
            <path
                d='M232.362 30.8682H232.827L232.594 30.6311L232.362 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M213.648 30.8682H214.113L213.881 30.6311L213.648 30.8682Z'
                fill='#1A1A1A'
            />
            <path
                d='M184.152 25.8572L183.164 26.8552L184.152 27.8532L185.141 26.8552L184.152 25.8572Z'
                fill='#1A1A1A'
            />
            <path
                d='M188.943 27.571L187.954 28.5691L188.943 29.5671L189.931 28.5691L188.943 27.571Z'
                fill='#1A1A1A'
            />
            <path
                d='M220.149 24.3421L219.16 25.3396L220.148 26.3381L221.137 25.3407L220.149 24.3421Z'
                fill='#1A1A1A'
            />
            <path
                d='M196.677 24.7624L197.723 23.8236L197.607 23.5889H195.863L195.747 23.8236L196.677 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M217.011 24.4341L216.021 25.4309L217.008 26.4302L217.998 25.4333L217.011 24.4341Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.516 24.7624L191.446 23.8236L191.33 23.5889H189.586L189.47 23.8236L190.516 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M213.927 24.3868L212.939 25.3848L213.927 26.3828L214.916 25.3848L213.927 24.3868Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.792 27.5821L184.804 28.5801L185.792 29.5781L186.781 28.5801L185.792 27.5821Z'
                fill='#1A1A1A'
            />
            <path
                d='M193.655 24.7624L194.585 23.8236L194.468 23.5889H192.725L192.608 23.8236L193.655 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.302 25.8458L186.314 26.8438L187.302 27.8418L188.291 26.8438L187.302 25.8458Z'
                fill='#1A1A1A'
            />
            <path
                d='M202.951 24.7624L203.998 23.8236L203.765 23.5889H202.138L201.905 23.8236L202.951 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M207.627 24.4099L206.639 25.408L207.627 26.406L208.616 25.4079L207.627 24.4099Z'
                fill='#1A1A1A'
            />
            <path
                d='M195.208 27.5842L194.22 28.5828L195.209 29.5803L196.197 28.5817L195.208 27.5842Z'
                fill='#1A1A1A'
            />
            <path
                d='M199.816 24.7624L200.862 23.8236L200.746 23.5889H199.002L198.886 23.8236L199.816 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M210.778 24.3982L209.789 25.3962L210.778 26.3943L211.766 25.3962L210.778 24.3982Z'
                fill='#1A1A1A'
            />
            <path
                d='M191.974 27.562L190.985 28.5601L191.974 29.5581L192.962 28.56L191.974 27.562Z'
                fill='#1A1A1A'
            />
            <path
                d='M235.774 24.3953L234.785 25.3933L235.774 26.3913L236.762 25.3933L235.774 24.3953Z'
                fill='#1A1A1A'
            />
            <path
                d='M246.66 25.888L245.671 26.886L246.66 27.884L247.648 26.886L246.66 25.888Z'
                fill='#1A1A1A'
            />
            <path
                d='M182.146 26.9917L181.1 25.9355L180.752 26.2876C180.752 26.2876 180.519 26.9917 180.519 27.3438C180.519 27.3438 180.519 27.4612 180.519 27.5785L180.984 28.048L182.03 26.9917H182.146Z'
                fill='#1A1A1A'
            />
            <path
                d='M242 24.3565L241.011 25.354L241.999 26.3525L242.988 25.355L242 24.3565Z'
                fill='#1A1A1A'
            />
            <path
                d='M245.105 24.3645L244.116 25.3625L245.105 26.3606L246.093 25.3625L245.105 24.3645Z'
                fill='#1A1A1A'
            />
            <path
                d='M245.151 29.5751L246.081 28.5189L245.151 27.4626L244.105 28.5189L245.151 29.5751Z'
                fill='#1A1A1A'
            />
            <path
                d='M181.33 29.2219C181.33 29.2219 181.795 29.8114 182.144 30.0472L181.33 29.2219Z'
                fill='#1A1A1A'
            />
            <path
                d='M223.293 26.4064L224.223 25.3502L223.293 24.2939L222.247 25.3502L223.293 26.4064Z'
                fill='#1A1A1A'
            />
            <path
                d='M238.874 24.3428L237.884 25.3396L238.872 26.3388L239.861 25.3421L238.874 24.3428Z'
                fill='#1A1A1A'
            />
            <path
                d='M187.377 24.7624L188.423 23.8236L188.191 23.5889H186.564L186.331 23.8236L187.377 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M229.537 24.4858L228.547 25.4827L229.535 26.4819L230.524 25.4851L229.537 24.4858Z'
                fill='#1A1A1A'
            />
            <path
                d='M226.397 24.4522L225.409 25.4507L226.398 26.4482L227.386 25.4497L226.397 24.4522Z'
                fill='#1A1A1A'
            />
            <path
                d='M198.274 27.5391L197.285 28.5371L198.274 29.5351L199.262 28.5371L198.274 27.5391Z'
                fill='#1A1A1A'
            />
            <path
                d='M232.55 24.3873L231.561 25.3848L232.549 26.3833L233.538 25.3859L232.55 24.3873Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.287 23.8244H184.241C184.241 23.8244 183.66 23.8244 183.311 23.8244L184.241 24.7632L185.287 23.707V23.8244Z'
                fill='#1A1A1A'
            />
            <path
                d='M215.506 24.7624L216.436 23.8236L216.32 23.5889H214.576L214.46 23.8236L215.506 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M191.962 24.3806L190.974 25.3787L191.962 26.3767L192.951 25.3786L191.962 24.3806Z'
                fill='#1A1A1A'
            />
            <path
                d='M212.831 28.5189L213.877 29.5751L214.923 28.5189L213.877 27.4626L212.831 28.5189Z'
                fill='#1A1A1A'
            />
            <path
                d='M205.957 25.8157L204.97 26.8149L205.96 27.8117L206.947 26.8125L205.957 25.8157Z'
                fill='#1A1A1A'
            />
            <path
                d='M195.112 24.3701L194.124 25.3682L195.112 26.3662L196.101 25.3681L195.112 24.3701Z'
                fill='#1A1A1A'
            />
            <path
                d='M210.789 27.5791L209.8 28.5771L210.789 29.5752L211.777 28.5771L210.789 27.5791Z'
                fill='#1A1A1A'
            />
            <path
                d='M221.667 24.7624L222.714 23.8236L222.597 23.5889H220.854L220.738 23.8236L221.667 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M218.529 24.7624L219.575 23.8236L219.459 23.5889H217.715L217.599 23.8236L218.529 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M202.968 25.8738L201.979 26.8718L202.968 27.8698L203.956 26.8718L202.968 25.8738Z'
                fill='#1A1A1A'
            />
            <path
                d='M188.903 24.4297L187.915 25.4282L188.904 26.4257L189.892 25.4271L188.903 24.4297Z'
                fill='#1A1A1A'
            />
            <path
                d='M185.781 24.4009L184.793 25.3989L185.781 26.3969L186.77 25.399L185.781 24.4009Z'
                fill='#1A1A1A'
            />
            <path
                d='M182.541 24.3736L181.552 25.3711L182.54 26.3696L183.529 25.3721L182.541 24.3736Z'
                fill='#1A1A1A'
            />
            <path
                d='M220.12 27.5472L219.132 28.5452L220.12 29.5432L221.109 28.5452L220.12 27.5472Z'
                fill='#1A1A1A'
            />
            <path
                d='M217.01 27.5306L216.021 28.5281L217.009 29.5266L217.998 28.5291L217.01 27.5306Z'
                fill='#1A1A1A'
            />
            <path
                d='M224.809 24.7624L225.855 23.8236L225.623 23.5889H223.995L223.763 23.8236L224.809 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M209.148 25.8543L208.16 26.8523L209.148 27.8503L210.137 26.8523L209.148 25.8543Z'
                fill='#1A1A1A'
            />
            <path
                d='M204.518 24.4653L203.528 25.4622L204.516 26.4614L205.505 25.4646L204.518 24.4653Z'
                fill='#1A1A1A'
            />
            <path
                d='M196.638 25.9279L195.65 26.9265L196.639 27.924L197.627 26.9254L196.638 25.9279Z'
                fill='#1A1A1A'
            />
            <path
                d='M212.367 24.7624L213.297 23.8236L213.181 23.5889H211.437L211.321 23.8236L212.367 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M209.229 24.7624L210.275 23.8236L210.043 23.5889H208.415L208.183 23.8236L209.229 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M201.412 24.3504L200.424 25.3484L201.412 26.3464L202.401 25.3484L201.412 24.3504Z'
                fill='#1A1A1A'
            />
            <path
                d='M193.433 25.8722L192.444 26.8696L193.432 27.8682L194.421 26.8707L193.433 25.8722Z'
                fill='#1A1A1A'
            />
            <path
                d='M198.262 24.3586L197.273 25.3567L198.262 26.3547L199.251 25.3567L198.262 24.3586Z'
                fill='#1A1A1A'
            />
            <path
                d='M204.489 27.5984L203.5 28.5964L204.489 29.5945L205.477 28.5964L204.489 27.5984Z'
                fill='#1A1A1A'
            />
            <path
                d='M199.817 25.8851L198.829 26.8831L199.817 27.8811L200.806 26.8831L199.817 25.8851Z'
                fill='#1A1A1A'
            />
            <path
                d='M190.452 25.8342L189.464 26.8323L190.452 27.8303L191.441 26.8323L190.452 25.8342Z'
                fill='#1A1A1A'
            />
            <path
                d='M206.09 24.7624L207.136 23.8236L206.904 23.5889H205.276L205.044 23.8236L206.09 24.7624Z'
                fill='#1A1A1A'
            />
            <path
                d='M206.673 28.5189L207.603 29.5751L208.649 28.5189L207.603 27.4626L206.673 28.5189Z'
                fill='#1A1A1A'
            />
            <path
                d='M201.424 27.5274L200.435 28.5254L201.424 29.5234L202.412 28.5254L201.424 27.5274Z'
                fill='#1A1A1A'
            />
            <path
                d='M182.728 29.5728L181.682 28.5166L181.217 28.986V29.2207L182.146 30.0422L182.728 29.4554V29.5728Z'
                fill='#262626'
            />
            <path
                d='M180.632 27.4626C180.632 27.4626 180.632 27.9342 180.748 28.17L181.097 27.8163L180.632 27.3447V27.4626Z'
                fill='#262626'
            />
            <path
                d='M182.727 30.5158C182.727 30.5158 183.54 30.7515 183.889 30.7515L183.192 30.0442L182.727 30.5158Z'
                fill='#262626'
            />
            <path
                d='M185.752 30.6336L185.985 30.8694H187.147L186.333 30.0442L185.752 30.6336Z'
                fill='#262626'
            />
            <path
                d='M184.813 28.5592L184.237 29.1414L185.225 30.1394L185.802 29.5572L184.813 28.5592Z'
                fill='#262626'
            />
            <path
                d='M181.737 25.307L181.16 25.8892L182.149 26.8872L182.726 26.305L181.737 25.307Z'
                fill='#262626'
            />
            <path
                d='M183.224 26.8752L182.647 27.4568L183.635 28.4559L184.212 27.8743L183.224 26.8752Z'
                fill='#262626'
            />
            <path
                d='M184.802 25.379L184.225 25.9612L185.214 26.9592L185.79 26.377L184.802 25.379Z'
                fill='#262626'
            />
            <path
                d='M187.963 28.5477L187.386 29.1299L188.375 30.1279L188.952 29.5457L187.963 28.5477Z'
                fill='#262626'
            />
            <path
                d='M188.891 30.6336L189.123 30.8694H190.286L189.472 30.0442L188.891 30.6336Z'
                fill='#262626'
            />
            <path
                d='M186.352 26.9037L185.775 27.4856L186.763 28.4841L187.34 27.9023L186.352 26.9037Z'
                fill='#262626'
            />
            <path
                d='M183.659 25.3496L184.241 24.7628L183.311 23.824L182.846 24.2934L183.892 25.3496H183.659Z'
                fill='#262626'
            />
            <path
                d='M192.03 30.6336L192.262 30.8694H193.424L192.611 30.0442L192.03 30.6336Z'
                fill='#262626'
            />
            <path
                d='M187.923 25.4048L187.347 25.9873L188.336 26.9848L188.912 26.4023L187.923 25.4048Z'
                fill='#262626'
            />
            <path
                d='M191.114 28.536L190.537 29.1182L191.525 30.1162L192.102 29.534L191.114 28.536Z'
                fill='#262626'
            />
            <path
                d='M189.531 26.9276L188.953 27.5088L189.94 28.5086L190.518 27.9274L189.531 26.9276Z'
                fill='#262626'
            />
            <path
                d='M186.317 23.7599L185.741 24.342L186.729 25.3401L187.306 24.7579L186.317 23.7599Z'
                fill='#262626'
            />
            <path
                d='M185.404 23.5889H185.054L185.287 23.826L185.404 23.5889Z'
                fill='#262626'
            />
            <path
                d='M195.163 30.6336L195.395 30.8694H196.441L195.744 30.0442L195.163 30.6336Z'
                fill='#262626'
            />
            <path
                d='M189.552 23.7854L188.976 24.3679L189.965 25.3654L190.541 24.7829L189.552 23.7854Z'
                fill='#262626'
            />
            <path
                d='M194.264 28.5248L193.687 29.1069L194.676 30.105L195.252 29.5228L194.264 28.5248Z'
                fill='#262626'
            />
            <path
                d='M191.102 25.359L190.525 25.9412L191.514 26.9392L192.091 26.357L191.102 25.359Z'
                fill='#262626'
            />
            <path
                d='M192.651 26.8832L192.074 27.4651L193.062 28.4636L193.639 27.8818L192.651 26.8832Z'
                fill='#262626'
            />
            <path
                d='M188.543 23.5889H188.193L188.426 23.826L188.543 23.5889Z'
                fill='#262626'
            />
            <path
                d='M192.612 23.7367L192.035 24.3188L193.024 25.3169L193.6 24.7347L192.612 23.7367Z'
                fill='#262626'
            />
            <path
                d='M197.408 28.516L196.831 29.0981L197.82 30.0962L198.396 29.514L197.408 28.516Z'
                fill='#262626'
            />
            <path
                d='M195.723 27.0296L195.145 27.6111L196.133 28.6103L196.71 28.0289L195.723 27.0296Z'
                fill='#262626'
            />
            <path
                d='M198.301 30.6336L198.534 30.8694H199.58L198.882 30.0442L198.301 30.6336Z'
                fill='#262626'
            />
            <path
                d='M191.681 23.5889H191.331L191.448 23.826L191.681 23.5889Z'
                fill='#262626'
            />
            <path
                d='M194.218 25.3814L193.641 25.9639L194.63 26.9614L195.207 26.3789L194.218 25.3814Z'
                fill='#262626'
            />
            <path
                d='M195.711 23.7746L195.134 24.3564L196.122 25.355L196.699 24.7731L195.711 23.7746Z'
                fill='#262626'
            />
            <path
                d='M200.444 28.5042L199.867 29.0864L200.856 30.0844L201.432 29.5023L200.444 28.5042Z'
                fill='#262626'
            />
            <path
                d='M194.82 23.5889H194.47L194.587 23.826L194.82 23.5889Z'
                fill='#262626'
            />
            <path
                d='M198.753 26.946L198.176 27.5278L199.164 28.5264L199.741 27.9445L198.753 26.946Z'
                fill='#262626'
            />
            <path
                d='M197.283 25.336L196.706 25.9182L197.695 26.9162L198.271 26.3341L197.283 25.336Z'
                fill='#262626'
            />
            <path
                d='M201.44 30.6336L201.672 30.8694H202.718L201.905 30.0442L201.44 30.6336Z'
                fill='#262626'
            />
            <path
                d='M201.904 26.9372L201.327 27.519L202.315 28.5176L202.892 27.9357L201.904 26.9372Z'
                fill='#262626'
            />
            <path
                d='M198.827 23.7999L198.25 24.3821L199.239 25.3801L199.815 24.7979L198.827 23.7999Z'
                fill='#262626'
            />
            <path
                d='M203.536 28.5166L202.955 29.1034L204.001 30.0422L204.583 29.5728L203.536 28.5166Z'
                fill='#262626'
            />
            <path
                d='M204.579 30.6336L204.695 30.8694H205.857L205.043 30.0442L204.579 30.6336Z'
                fill='#262626'
            />
            <path
                d='M197.959 23.5889H197.609L197.725 23.826L197.959 23.5889Z'
                fill='#262626'
            />
            <path
                d='M200.433 25.3246L199.856 25.9067L200.844 26.9048L201.421 26.3226L200.433 25.3246Z'
                fill='#262626'
            />
            <path
                d='M206.659 28.565L206.083 29.1472L207.071 30.1452L207.648 29.5631L206.659 28.565Z'
                fill='#262626'
            />
            <path
                d='M205.138 26.9574L204.561 27.5396L205.55 28.5376L206.126 27.9554L205.138 26.9574Z'
                fill='#262626'
            />
            <path
                d='M207.604 30.6336L207.836 30.8694H208.999L208.185 30.0442L207.604 30.6336Z'
                fill='#262626'
            />
            <path
                d='M200.981 23.5889H200.747L200.864 23.826L200.981 23.5889Z'
                fill='#262626'
            />
            <path
                d='M201.942 23.8199L201.366 24.4023L202.355 25.3998L202.931 24.8174L201.942 23.8199Z'
                fill='#262626'
            />
            <path
                d='M204.583 26.4046L203.536 25.3484L202.955 25.9352L204.001 26.9914L204.583 26.4046Z'
                fill='#262626'
            />
            <path
                d='M206.682 25.3566L206.105 25.9385L207.093 26.937L207.67 26.3552L206.682 25.3566Z'
                fill='#262626'
            />
            <path
                d='M208.203 26.8002L207.626 27.3821L208.614 28.3806L209.191 27.7988L208.203 26.8002Z'
                fill='#262626'
            />
            <path
                d='M204.117 23.5889H203.767L204 23.826L204.117 23.5889Z'
                fill='#262626'
            />
            <path
                d='M205.126 23.777L204.55 24.3591L205.538 25.3572L206.115 24.775L205.126 23.777Z'
                fill='#262626'
            />
            <path
                d='M210.742 30.6336L210.975 30.8694H212.137L211.324 30.0442L210.742 30.6336Z'
                fill='#262626'
            />
            <path
                d='M209.809 28.5564L209.232 29.1387L210.221 30.1367L210.798 29.5545L209.809 28.5564Z'
                fill='#262626'
            />
            <path
                d='M207.256 23.5889H206.906L207.139 23.826L207.256 23.5889Z'
                fill='#262626'
            />
            <path
                d='M209.798 25.3758L209.221 25.958L210.21 26.956L210.786 26.3738L209.798 25.3758Z'
                fill='#262626'
            />
            <path
                d='M211.354 26.7914L210.777 27.3733L211.765 28.3718L212.342 27.79L211.354 26.7914Z'
                fill='#262626'
            />
            <path
                d='M213.881 30.6336L214.114 30.8694H215.276L214.463 30.0442L213.881 30.6336Z'
                fill='#262626'
            />
            <path
                d='M208.277 23.7687L207.7 24.3508L208.689 25.3489L209.266 24.7667L208.277 23.7687Z'
                fill='#262626'
            />
            <path
                d='M212.959 28.545L212.383 29.1272L213.371 30.1252L213.948 29.543L212.959 28.545Z'
                fill='#262626'
            />
            <path
                d='M217.02 30.6336L217.252 30.8694H218.298L217.601 30.0442L217.02 30.6336Z'
                fill='#262626'
            />
            <path
                d='M214.436 26.9464L213.859 27.5278L214.846 28.5271L215.424 27.9456L214.436 26.9464Z'
                fill='#262626'
            />
            <path
                d='M210.395 23.5889H210.044L210.278 23.826L210.395 23.5889Z'
                fill='#262626'
            />
            <path
                d='M213.883 26.4046L212.837 25.3484L212.372 25.9352L213.302 26.9914L213.883 26.4046Z'
                fill='#262626'
            />
            <path
                d='M211.308 23.7572L210.731 24.3394L211.72 25.3374L212.296 24.7552L211.308 23.7572Z'
                fill='#262626'
            />
            <path
                d='M216.065 28.4822L215.487 29.0637L216.475 30.0628L217.052 29.4812L216.065 28.4822Z'
                fill='#262626'
            />
            <path
                d='M220.158 30.6336L220.391 30.8694H221.437L220.74 30.0442L220.158 30.6336Z'
                fill='#262626'
            />
            <path
                d='M213.534 23.5889H213.183L213.3 23.826L213.534 23.5889Z'
                fill='#262626'
            />
            <path
                d='M216.019 25.3273L215.442 25.9092L216.43 26.9077L217.007 26.3259L216.019 25.3273Z'
                fill='#262626'
            />
            <path
                d='M217.613 27.023L217.037 27.6055L218.026 28.603L218.602 28.0205L217.613 27.023Z'
                fill='#262626'
            />
            <path
                d='M219.111 28.5166L218.529 29.1034L219.575 30.0422L220.157 29.5728L219.111 28.5166Z'
                fill='#262626'
            />
            <path
                d='M214.379 23.829L213.802 24.4111L214.791 25.4092L215.367 24.827L214.379 23.829Z'
                fill='#262626'
            />
            <path
                d='M223.297 30.6336L223.53 30.8694H224.576L223.762 30.0442L223.297 30.6336Z'
                fill='#262626'
            />
            <path
                d='M216.672 23.5889H216.322L216.439 23.826L216.672 23.5889Z'
                fill='#262626'
            />
            <path
                d='M217.523 23.8172L216.946 24.3994L217.935 25.3974L218.511 24.8153L217.523 23.8172Z'
                fill='#262626'
            />
            <path
                d='M220.158 27.4619L221.205 28.5181L221.67 27.9313L220.74 26.9924L220.158 27.4619Z'
                fill='#262626'
            />
            <path
                d='M222.25 28.5166L221.668 29.1034L222.715 30.0422L223.296 29.5728L222.25 28.5166Z'
                fill='#262626'
            />
            <path
                d='M220.157 26.4046L219.111 25.3484L218.529 25.9352L219.575 26.9914L220.157 26.4046Z'
                fill='#262626'
            />
            <path
                d='M221.67 24.7628L220.74 23.824L220.158 24.2934L221.205 25.3496L221.67 24.7628Z'
                fill='#262626'
            />
            <path
                d='M225.435 28.5398L224.858 29.1213L225.846 30.1204L226.423 29.5388L225.435 28.5398Z'
                fill='#262626'
            />
            <path
                d='M226.436 30.6336L226.552 30.8694H227.715L226.901 30.0442L226.436 30.6336Z'
                fill='#262626'
            />
            <path
                d='M219.694 23.5889H219.461L219.577 23.826L219.694 23.5889Z'
                fill='#262626'
            />
            <path
                d='M223.761 26.8346L223.184 27.4165L224.172 28.415L224.749 27.8332L223.761 26.8346Z'
                fill='#262626'
            />
            <path
                d='M222.337 25.3941L221.759 25.9756L222.747 26.9748L223.324 26.3934L222.337 25.3941Z'
                fill='#262626'
            />
            <path
                d='M224.808 24.7628L223.762 23.824L223.297 24.2934L224.227 25.3496L224.808 24.7628Z'
                fill='#262626'
            />
            <path
                d='M222.833 23.5889H222.599L222.716 23.826L222.833 23.5889Z'
                fill='#262626'
            />
            <path
                d='M228.527 28.5166L227.946 29.1034L228.992 30.0422L229.457 29.5728L228.527 28.5166Z'
                fill='#262626'
            />
            <path
                d='M225.344 25.4051L224.767 25.9873L225.756 26.9853L226.333 26.4032L225.344 25.4051Z'
                fill='#262626'
            />
            <path
                d='M227.042 26.9156L226.464 27.4971L227.452 28.4963L228.029 27.9149L227.042 26.9156Z'
                fill='#262626'
            />
            <path
                d='M229.456 30.6336L229.688 30.8694H230.85L230.037 30.0442L229.456 30.6336Z'
                fill='#262626'
            />
            <path
                d='M231.655 28.5621L231.079 29.1443L232.067 30.1423L232.644 29.5601L231.655 28.5621Z'
                fill='#262626'
            />
            <path
                d='M229.457 26.4046L228.527 25.3484L227.946 25.9352L228.992 26.9914L229.457 26.4046Z'
                fill='#262626'
            />
            <path
                d='M232.594 30.6336L232.827 30.8694H233.989L233.176 30.0442L232.594 30.6336Z'
                fill='#262626'
            />
            <path
                d='M226.973 23.7857L226.396 24.3679L227.385 25.3659L227.961 24.7838L226.973 23.7857Z'
                fill='#262626'
            />
            <path
                d='M229.456 27.4619L230.502 28.5181L231.083 27.9313L230.037 26.9924L229.456 27.4619Z'
                fill='#262626'
            />
            <path
                d='M225.969 23.5889H225.619L225.852 23.826L225.969 23.5889Z'
                fill='#262626'
            />
            <path
                d='M230.073 23.7229L229.495 24.3044L230.482 25.3037L231.06 24.7222L230.073 23.7229Z'
                fill='#262626'
            />
            <path
                d='M235.733 30.6336L235.965 30.8694H237.012L236.314 30.0442L235.733 30.6336Z'
                fill='#262626'
            />
            <path
                d='M229.108 23.5889H228.758L228.991 23.826L229.108 23.5889Z'
                fill='#262626'
            />
            <path
                d='M231.707 25.4537L231.13 26.0352L232.117 27.0344L232.695 26.4529L231.707 25.4537Z'
                fill='#262626'
            />
            <path
                d='M234.688 28.5166L234.223 29.1034L235.153 30.0422L235.734 29.5728L234.688 28.5166Z'
                fill='#262626'
            />
            <path
                d='M232.594 27.4619L233.641 28.5181L234.222 27.9313L233.176 26.9924L232.594 27.4619Z'
                fill='#262626'
            />
            <path
                d='M235.734 26.4046L234.688 25.3484L234.223 25.9352L235.153 26.9914L235.734 26.4046Z'
                fill='#262626'
            />
            <path
                d='M237.827 28.5166L237.362 29.1034L238.292 30.0422L238.873 29.5728L237.827 28.5166Z'
                fill='#262626'
            />
            <path
                d='M233.204 23.6964L232.628 24.2793L233.618 25.2761L234.194 24.6932L233.204 23.6964Z'
                fill='#262626'
            />
            <path
                d='M238.872 30.6336L239.105 30.8694H240.151L239.453 30.0442L238.872 30.6336Z'
                fill='#262626'
            />
            <path
                d='M232.246 23.5889H231.896L232.13 23.826L232.246 23.5889Z'
                fill='#262626'
            />
            <path
                d='M235.733 27.4619L236.779 28.5181L237.36 27.9313L236.314 26.9924L235.733 27.4619Z'
                fill='#262626'
            />
            <path
                d='M237.36 24.7628L236.314 23.824L235.733 24.2934L236.779 25.3496L237.36 24.7628Z'
                fill='#262626'
            />
            <path
                d='M241.031 28.4776L240.455 29.061L241.446 30.057L242.021 29.4736L241.031 28.4776Z'
                fill='#262626'
            />
            <path
                d='M238.872 27.4619L239.918 28.5181L240.383 27.9313L239.453 26.9924L238.872 27.4619Z'
                fill='#262626'
            />
            <path
                d='M235.385 23.5889H235.035L235.152 23.826L235.385 23.5889Z'
                fill='#262626'
            />
            <path
                d='M242.01 30.6336L242.243 30.8694H243.289L242.591 30.0442L242.01 30.6336Z'
                fill='#262626'
            />
            <path
                d='M238.873 26.4046L237.827 25.3484L237.362 25.9352L238.292 26.9914L238.873 26.4046Z'
                fill='#262626'
            />
            <path
                d='M238.524 23.5889H238.174L238.29 23.826L238.524 23.5889Z'
                fill='#262626'
            />
            <path
                d='M240.383 24.7628L239.453 23.824L238.872 24.2934L239.918 25.3496L240.383 24.7628Z'
                fill='#262626'
            />
            <path
                d='M241.089 25.3502L240.512 25.9324L241.501 26.9304L242.077 26.3482L241.089 25.3502Z'
                fill='#262626'
            />
            <path
                d='M244.153 28.55L243.577 29.1328L244.567 30.1298L245.143 29.547L244.153 28.55Z'
                fill='#262626'
            />
            <path
                d='M245.146 30.6327C245.146 30.6327 245.727 30.6327 246.076 30.6327L245.611 30.1611L245.03 30.7506L245.146 30.6327Z'
                fill='#262626'
            />
            <path
                d='M242.598 27.0086L242.022 27.5911L243.011 28.5886L243.587 28.0061L242.598 27.0086Z'
                fill='#262626'
            />
            <path
                d='M241.546 23.5889H241.312L241.429 23.826L241.546 23.5889Z'
                fill='#262626'
            />
            <path
                d='M246.659 29.1034L247.24 29.6902C247.24 29.6902 247.589 29.2207 247.705 28.986L247.24 28.5166L246.659 29.1034Z'
                fill='#262626'
            />
            <path
                d='M242.587 23.8313L242.01 24.4138L242.999 25.4113L243.576 24.8288L242.587 23.8313Z'
                fill='#262626'
            />
            <path
                d='M244.193 25.356L243.616 25.9385L244.606 26.936L245.182 26.3535L244.193 25.356Z'
                fill='#262626'
            />
            <path
                d='M245.149 27.4619L246.079 28.5181L246.661 27.9313L245.614 26.9924L245.149 27.4619Z'
                fill='#262626'
            />
            <path
                d='M246.659 25.9348L247.705 26.991L248.054 26.6389C248.054 26.6389 248.054 26.4042 248.054 26.2868L247.24 25.4653L246.659 26.0521V25.9348Z'
                fill='#262626'
            />
            <path
                d='M246.077 25.3496L246.658 24.7628L245.728 23.824L245.263 24.2934L246.309 25.3496H246.077Z'
                fill='#262626'
            />
            <path
                d='M244.685 23.5889H244.451L244.568 23.826L244.685 23.5889Z'
                fill='#262626'
            />
            <path
                d='M182.728 24.2933L182.496 24.0586C182.496 24.0586 181.682 24.6454 181.45 24.9974L181.682 25.2322L182.728 24.176V24.2933Z'
                fill='#333333'
            />
            <path
                d='M180.982 25.8174C180.982 25.8174 180.982 26.1711 180.865 26.2889L181.215 25.9353H181.099L180.982 25.8174Z'
                fill='#333333'
            />
            <path
                d='M181.682 25.3491L181.449 25.1133C181.449 25.1133 181.101 25.5848 180.984 25.8206H181.101L181.682 25.4669V25.3491Z'
                fill='#333333'
            />
            <path
                d='M183.192 23.824C183.192 23.824 182.727 23.9419 182.494 24.1776L182.727 24.4134L183.192 23.9419V23.824Z'
                fill='#333333'
            />
            <path
                d='M186.568 23.5889H186.217L186.334 23.826L186.568 23.5889Z'
                fill='#333333'
            />
            <path
                d='M183.681 25.4841L182.693 26.4822L183.269 27.0644L184.258 26.0663L183.681 25.4841Z'
                fill='#333333'
            />
            <path
                d='M185.252 23.8241L184.265 24.8232L184.842 25.4048L185.83 24.4057L185.252 23.8241Z'
                fill='#333333'
            />
            <path
                d='M182.143 26.9763L181.155 27.9749L181.731 28.5567L182.719 27.5582L182.143 26.9763Z'
                fill='#333333'
            />
            <path
                d='M181.1 27.9329L180.751 28.2849C180.751 28.2849 180.984 28.8717 181.1 29.1064L181.565 28.637L180.984 28.0502L181.1 27.9329Z'
                fill='#333333'
            />
            <path
                d='M182.624 26.3771L182.046 26.9609L182.624 27.5448L183.202 26.9609L182.624 26.3771Z'
                fill='#333333'
            />
            <path
                d='M184.332 24.7213L183.754 25.3052L184.332 25.889L184.911 25.3052L184.332 24.7213Z'
                fill='#333333'
            />
            <path
                d='M185.752 24.2962L186.333 23.8246L186.217 23.5889H185.403L185.287 23.8246L185.752 24.2962Z'
                fill='#333333'
            />
            <path
                d='M186.832 25.4727L185.843 26.4707L186.42 27.0529L187.408 26.0549L186.832 25.4727Z'
                fill='#333333'
            />
            <path
                d='M185.322 26.9263L184.333 27.9243L184.91 28.5065L185.898 27.5084L185.322 26.9263Z'
                fill='#333333'
            />
            <path
                d='M189.584 23.5889H189.351L189.467 23.826L189.584 23.5889Z'
                fill='#333333'
            />
            <path
                d='M188.262 23.8198L187.273 24.8179L187.85 25.4L188.838 24.402L188.262 23.8198Z'
                fill='#333333'
            />
            <path
                d='M183.607 28.5793L182.619 29.5774L183.196 30.1596L184.184 29.1615L183.607 28.5793Z'
                fill='#333333'
            />
            <path
                d='M182.148 30.0442V30.1623H182.265L182.148 30.0442Z'
                fill='#333333'
            />
            <path
                d='M182.729 29.574L182.148 30.1608C182.148 30.1608 182.497 30.5128 182.729 30.6302L183.194 30.1608L182.613 29.574H182.729Z'
                fill='#333333'
            />
            <path
                d='M184.344 27.9013L183.766 28.4851L184.344 29.0689L184.922 28.4851L184.344 27.9013Z'
                fill='#333333'
            />
            <path
                d='M185.826 26.2886L185.247 26.8718L185.825 27.4563L186.404 26.8731L185.826 26.2886Z'
                fill='#333333'
            />
            <path
                d='M187.421 24.753L186.842 25.3362L187.419 25.9206L187.998 25.3374L187.421 24.753Z'
                fill='#333333'
            />
            <path
                d='M188.891 24.2962L189.472 23.8246L189.355 23.5889H188.542L188.426 23.8246L188.891 24.2962Z'
                fill='#333333'
            />
            <path
                d='M190.518 25.9352L189.937 25.3484L188.891 26.4046L189.472 26.9914L190.518 25.9352Z'
                fill='#333333'
            />
            <path
                d='M191.468 23.882L190.48 24.8811L191.057 25.4627L192.045 24.4636L191.468 23.882Z'
                fill='#333333'
            />
            <path
                d='M186.758 28.5704L185.769 29.5684L186.346 30.1505L187.334 29.1526L186.758 28.5704Z'
                fill='#333333'
            />
            <path
                d='M185.283 30.0442L184.469 30.8694H185.632L185.748 30.6336L185.283 30.0442Z'
                fill='#333333'
            />
            <path
                d='M192.723 23.5889H192.489L192.606 23.826L192.723 23.5889Z'
                fill='#333333'
            />
            <path
                d='M188.273 26.9971L187.284 27.9951L187.861 28.5773L188.85 27.5792L188.273 26.9971Z'
                fill='#333333'
            />
            <path
                d='M185.847 29.4988L185.27 30.0833L185.849 30.6665L186.426 30.082L185.847 29.4988Z'
                fill='#333333'
            />
            <path
                d='M187.494 27.8896L186.916 28.4734L187.494 29.0572L188.072 28.4734L187.494 27.8896Z'
                fill='#333333'
            />
            <path
                d='M188.891 27.4617L189.472 26.9923L188.891 26.4055L188.426 26.9923L188.891 27.4617Z'
                fill='#333333'
            />
            <path
                d='M190.547 24.7843L189.969 25.3682L190.547 25.952L191.126 25.3682L190.547 24.7843Z'
                fill='#333333'
            />
            <path
                d='M192.026 24.2962L192.607 23.8246L192.491 23.5889H191.678L191.445 23.8246L192.026 24.2962Z'
                fill='#333333'
            />
            <path
                d='M195.865 23.5889H195.514L195.748 23.826L195.865 23.5889Z'
                fill='#333333'
            />
            <path
                d='M189.846 28.4881L188.857 29.4851L189.433 30.0679L190.422 29.0709L189.846 28.4881Z'
                fill='#333333'
            />
            <path
                d='M191.417 26.988L190.429 27.9861L191.005 28.5683L191.994 27.5702L191.417 26.988Z'
                fill='#333333'
            />
            <path
                d='M188.422 30.0442L187.608 30.8694H188.654L188.887 30.6336L188.422 30.0442Z'
                fill='#333333'
            />
            <path
                d='M195.164 24.2934L194.583 23.824L193.653 24.7628L194.118 25.3496L195.164 24.2934Z'
                fill='#333333'
            />
            <path
                d='M193.657 25.9352L193.076 25.3484L192.03 26.4046L192.611 26.9914L193.657 25.9352Z'
                fill='#333333'
            />
            <path
                d='M188.426 30.0434L188.891 30.6302L189.472 30.0434L188.891 29.574L188.426 30.0434Z'
                fill='#333333'
            />
            <path
                d='M190.497 28.0105L189.918 28.5938L190.496 29.1782L191.075 28.595L190.497 28.0105Z'
                fill='#333333'
            />
            <path
                d='M192.026 27.4617L192.607 26.9923L192.026 26.4055L191.445 26.9923L192.026 27.4617Z'
                fill='#333333'
            />
            <path
                d='M193.578 24.7729L193 25.3567L193.578 25.9405L194.156 25.3567L193.578 24.7729Z'
                fill='#333333'
            />
            <path
                d='M195.165 24.2962L195.746 23.8246L195.513 23.5889H194.816L194.583 23.8246L195.165 24.2962Z'
                fill='#333333'
            />
            <path
                d='M199.004 23.5889H198.653L198.887 23.826L199.004 23.5889Z'
                fill='#333333'
            />
            <path
                d='M194.596 26.9437L193.607 27.9412L194.184 28.5236L195.173 27.5262L194.596 26.9437Z'
                fill='#333333'
            />
            <path
                d='M192.03 29.5728L192.611 30.0422L193.657 29.1034L193.076 28.5166L192.03 29.5728Z'
                fill='#333333'
            />
            <path
                d='M191.444 30.0442L190.747 30.8694H191.793L192.025 30.6336L191.444 30.0442Z'
                fill='#333333'
            />
            <path
                d='M196.276 25.4419L195.287 26.4399L195.864 27.0221L196.852 26.0241L196.276 25.4419Z'
                fill='#333333'
            />
            <path
                d='M198.305 24.2934L197.724 23.824L196.678 24.7628L197.259 25.3496L198.305 24.2934Z'
                fill='#333333'
            />
            <path
                d='M191.445 30.0434L192.026 30.6302L192.607 30.0434L192.026 29.574L191.445 30.0434Z'
                fill='#333333'
            />
            <path
                d='M193.651 28.0208L193.074 28.6052L193.653 29.1885L194.23 28.604L193.651 28.0208Z'
                fill='#333333'
            />
            <path
                d='M195.165 27.4617L195.746 26.9923L195.165 26.4055L194.583 26.9923L195.165 27.4617Z'
                fill='#333333'
            />
            <path
                d='M196.728 24.7614L196.15 25.3452L196.728 25.9291L197.307 25.3452L196.728 24.7614Z'
                fill='#333333'
            />
            <path
                d='M198.304 24.2962L198.885 23.8246L198.652 23.5889H197.955L197.722 23.8246L198.304 24.2962Z'
                fill='#333333'
            />
            <path
                d='M194.583 30.0442L193.885 30.8694H194.931L195.164 30.6336L194.583 30.0442Z'
                fill='#333333'
            />
            <path
                d='M196.152 28.4705L195.163 29.4675L195.739 30.0503L196.728 29.0533L196.152 28.4705Z'
                fill='#333333'
            />
            <path
                d='M202.142 23.5889H201.792L201.909 23.826L202.142 23.5889Z'
                fill='#333333'
            />
            <path
                d='M199.228 25.513L198.239 26.511L198.816 27.0932L199.804 26.0952L199.228 25.513Z'
                fill='#333333'
            />
            <path
                d='M197.689 26.9992L196.701 27.9978L197.278 28.5797L198.266 27.5811L197.689 26.9992Z'
                fill='#333333'
            />
            <path
                d='M200.787 23.8418L199.8 24.8408L200.377 25.4224L201.365 24.4234L200.787 23.8418Z'
                fill='#333333'
            />
            <path
                d='M194.583 30.0434L195.165 30.6302L195.746 30.0434L195.165 29.574L194.583 30.0434Z'
                fill='#333333'
            />
            <path
                d='M196.74 27.9416L196.161 28.5254L196.74 29.1092L197.318 28.5254L196.74 27.9416Z'
                fill='#333333'
            />
            <path
                d='M198.313 26.2575L197.734 26.8408L198.311 27.4253L198.89 26.842L198.313 26.2575Z'
                fill='#333333'
            />
            <path
                d='M199.878 24.7499L199.3 25.3337L199.878 25.9176L200.457 25.3337L199.878 24.7499Z'
                fill='#333333'
            />
            <path
                d='M201.442 24.2962L201.907 23.8246L201.791 23.5889H200.977L200.861 23.8246L201.442 24.2962Z'
                fill='#333333'
            />
            <path
                d='M205.281 23.5889H204.931L205.047 23.826L205.281 23.5889Z'
                fill='#333333'
            />
            <path
                d='M202.377 25.5015L201.389 26.4995L201.965 27.0817L202.954 26.0836L202.377 25.5015Z'
                fill='#333333'
            />
            <path
                d='M199.159 28.4927L198.171 29.4907L198.747 30.0729L199.736 29.0749L199.159 28.4927Z'
                fill='#333333'
            />
            <path
                d='M203.956 23.8209L202.966 24.8179L203.542 25.4007L204.532 24.4037L203.956 23.8209Z'
                fill='#333333'
            />
            <path
                d='M197.724 30.0442L196.911 30.8694H198.073L198.306 30.6336L197.724 30.0442Z'
                fill='#333333'
            />
            <path
                d='M200.868 26.9537L199.879 27.9517L200.456 28.5338L201.444 27.5359L200.868 26.9537Z'
                fill='#333333'
            />
            <path
                d='M197.722 30.0434L198.304 30.6302L198.885 30.0434L198.304 29.574L197.722 30.0434Z'
                fill='#333333'
            />
            <path
                d='M199.89 27.9303L199.312 28.5142L199.89 29.098L200.468 28.5142L199.89 27.9303Z'
                fill='#333333'
            />
            <path
                d='M201.442 27.4617L201.907 26.9923L201.442 26.4055L200.861 26.9923L201.442 27.4617Z'
                fill='#333333'
            />
            <path
                d='M203.079 24.803L202.501 25.3875L203.08 25.9707L203.658 25.3862L203.079 24.803Z'
                fill='#333333'
            />
            <path
                d='M204.581 24.2962L205.046 23.8246L204.93 23.5889H204.116L204 23.8246L204.581 24.2962Z'
                fill='#333333'
            />
            <path
                d='M208.419 23.5889H208.069L208.186 23.826L208.419 23.5889Z'
                fill='#333333'
            />
            <path
                d='M204.018 26.9451L203.029 27.9431L203.606 28.5253L204.594 27.5272L204.018 26.9451Z'
                fill='#333333'
            />
            <path
                d='M207.156 23.7535L206.168 24.7515L206.744 25.3336L207.733 24.3357L207.156 23.7535Z'
                fill='#333333'
            />
            <path
                d='M206.09 25.9352L205.508 25.3484L204.579 26.4046L205.043 26.9914L206.09 25.9352Z'
                fill='#333333'
            />
            <path
                d='M202.372 28.5367L201.383 29.5337L201.959 30.1165L202.948 29.1195L202.372 28.5367Z'
                fill='#333333'
            />
            <path
                d='M200.863 30.0442L200.049 30.8694H201.212L201.444 30.6336L200.863 30.0442Z'
                fill='#333333'
            />
            <path
                d='M200.861 30.0434L201.442 30.6302L201.907 30.0434L201.442 29.574L200.861 30.0434Z'
                fill='#333333'
            />
            <path
                d='M203.039 27.9186L202.461 28.5024L203.039 29.0863L203.618 28.5024L203.039 27.9186Z'
                fill='#333333'
            />
            <path
                d='M204.581 27.4617L205.046 26.9923L204.581 26.4055L204 26.9923L204.581 27.4617Z'
                fill='#333333'
            />
            <path
                d='M206.179 24.7299L205.6 25.3137L206.179 25.8976L206.757 25.3137L206.179 24.7299Z'
                fill='#333333'
            />
            <path
                d='M207.603 24.2962L208.184 23.8246L208.068 23.5889H207.254L207.138 23.8246L207.603 24.2962Z'
                fill='#333333'
            />
            <path
                d='M204.002 30.0442L203.188 30.8694H204.35L204.583 30.6336L204.002 30.0442Z'
                fill='#333333'
            />
            <path
                d='M205.453 28.4695L204.465 29.4675L205.041 30.0497L206.03 29.0517L205.453 28.4695Z'
                fill='#333333'
            />
            <path
                d='M208.678 25.4812L207.689 26.4792L208.266 27.0614L209.254 26.0634L208.678 25.4812Z'
                fill='#333333'
            />
            <path
                d='M210.146 23.8847L209.159 24.8838L209.736 25.4654L210.723 24.4663L210.146 23.8847Z'
                fill='#333333'
            />
            <path
                d='M207.121 26.8843L206.134 27.8835L206.711 28.465L207.698 27.4658L207.121 26.8843Z'
                fill='#333333'
            />
            <path
                d='M211.442 23.5889H211.208L211.325 23.826L211.442 23.5889Z'
                fill='#333333'
            />
            <path
                d='M204 30.0434L204.581 30.6302L205.046 30.0434L204.581 29.574L204 30.0434Z'
                fill='#333333'
            />
            <path
                d='M206.19 27.9101L205.612 28.4939L206.19 29.0777L206.768 28.4939L206.19 27.9101Z'
                fill='#333333'
            />
            <path
                d='M207.689 26.3142L207.11 26.8975L207.688 27.4819L208.267 26.8987L207.689 26.3142Z'
                fill='#333333'
            />
            <path
                d='M209.244 24.8009L208.665 25.3848L209.244 25.9686L209.822 25.3848L209.244 24.8009Z'
                fill='#333333'
            />
            <path
                d='M210.742 24.2962L211.323 23.8246L211.207 23.5889H210.393L210.277 23.8246L210.742 24.2962Z'
                fill='#333333'
            />
            <path
                d='M207.14 30.0442L206.327 30.8694H207.373L207.605 30.6336L207.14 30.0442Z'
                fill='#333333'
            />
            <path
                d='M208.604 28.458L207.615 29.4561L208.192 30.0382L209.18 29.0402L208.604 28.458Z'
                fill='#333333'
            />
            <path
                d='M210.113 27.0059L209.125 28.0039L209.701 28.5861L210.69 27.588L210.113 27.0059Z'
                fill='#333333'
            />
            <path
                d='M211.827 25.4695L210.839 26.4675L211.415 27.0497L212.404 26.0517L211.827 25.4695Z'
                fill='#333333'
            />
            <path
                d='M213.332 23.8783L212.343 24.8752L212.919 25.458L213.908 24.4611L213.332 23.8783Z'
                fill='#333333'
            />
            <path
                d='M214.578 23.5889H214.228L214.461 23.826L214.578 23.5889Z'
                fill='#333333'
            />
            <path
                d='M207.677 29.4915L207.099 30.0759L207.678 30.6592L208.255 30.0747L207.677 29.4915Z'
                fill='#333333'
            />
            <path
                d='M209.255 27.9818L208.677 28.5657L209.255 29.1495L209.833 28.5657L209.255 27.9818Z'
                fill='#333333'
            />
            <path
                d='M210.839 26.3059L210.26 26.8892L210.838 27.4736L211.417 26.8904L210.839 26.3059Z'
                fill='#333333'
            />
            <path
                d='M212.349 24.8533L211.77 25.4365L212.348 26.021L212.927 25.4378L212.349 24.8533Z'
                fill='#333333'
            />
            <path
                d='M213.878 24.2962L214.459 23.8246L214.226 23.5889H213.529L213.297 23.8246L213.878 24.2962Z'
                fill='#333333'
            />
            <path
                d='M210.279 30.0442L209.466 30.8694H210.512L210.744 30.6336L210.279 30.0442Z'
                fill='#333333'
            />
            <path
                d='M217.716 23.5889H217.366L217.599 23.826L217.716 23.5889Z'
                fill='#333333'
            />
            <path
                d='M213.264 26.9942L212.275 27.9922L212.852 28.5744L213.84 27.5764L213.264 26.9942Z'
                fill='#333333'
            />
            <path
                d='M214.897 25.3948L213.91 26.3938L214.487 26.9754L215.474 25.9763L214.897 25.3948Z'
                fill='#333333'
            />
            <path
                d='M211.754 28.4495L210.765 29.4475L211.342 30.0297L212.331 29.0316L211.754 28.4495Z'
                fill='#333333'
            />
            <path
                d='M216.441 23.8588L215.454 24.8579L216.031 25.4395L217.018 24.4404L216.441 23.8588Z'
                fill='#333333'
            />
            <path
                d='M210.82 29.4768L210.243 30.0613L210.822 30.6445L211.399 30.0601L210.82 29.4768Z'
                fill='#333333'
            />
            <path
                d='M212.405 27.9704L211.826 28.5542L212.405 29.138L212.983 28.5542L212.405 27.9704Z'
                fill='#333333'
            />
            <path
                d='M213.878 27.4617L214.459 26.9923L213.878 26.4055L213.297 26.9923L213.878 27.4617Z'
                fill='#333333'
            />
            <path
                d='M215.504 24.845L214.925 25.4282L215.503 26.0127L216.082 25.4295L215.504 24.845Z'
                fill='#333333'
            />
            <path
                d='M217.017 24.2962L217.598 23.8246L217.365 23.5889H216.668L216.435 23.8246L217.017 24.2962Z'
                fill='#333333'
            />
            <path
                d='M214.904 28.4385L213.915 29.4365L214.492 30.0187L215.48 29.0207L214.904 28.4385Z'
                fill='#333333'
            />
            <path
                d='M216.414 26.9856L215.425 27.9836L216.002 28.5658L216.99 27.5678L216.414 26.9856Z'
                fill='#333333'
            />
            <path
                d='M218.081 25.3658L217.088 26.3596L217.662 26.9442L218.655 25.9504L218.081 25.3658Z'
                fill='#333333'
            />
            <path
                d='M213.296 30.0442L212.599 30.8694H213.645L213.877 30.6336L213.296 30.0442Z'
                fill='#333333'
            />
            <path
                d='M220.855 23.5889H220.505L220.738 23.826L220.855 23.5889Z'
                fill='#333333'
            />
            <path
                d='M220.157 24.2934L219.575 23.824L218.529 24.7628L219.111 25.3496L220.157 24.2934Z'
                fill='#333333'
            />
            <path
                d='M213.297 30.0434L213.878 30.6302L214.459 30.0434L213.878 29.574L213.297 30.0434Z'
                fill='#333333'
            />
            <path
                d='M215.436 27.9589L214.858 28.5427L215.436 29.1266L216.014 28.5427L215.436 27.9589Z'
                fill='#333333'
            />
            <path
                d='M217.104 26.2737L216.526 26.8582L217.105 27.4414L217.683 26.8569L217.104 26.2737Z'
                fill='#333333'
            />
            <path
                d='M218.529 25.9367L219.11 25.35L218.529 24.7632L218.064 25.35L218.529 25.9367Z'
                fill='#333333'
            />
            <path
                d='M220.155 24.2962L220.737 23.8246L220.504 23.5889H219.69L219.574 23.8246L220.155 24.2962Z'
                fill='#333333'
            />
            <path
                d='M219.575 26.9924L218.529 27.9313L219.111 28.5181L220.157 27.4619L219.575 26.9924Z'
                fill='#333333'
            />
            <path
                d='M216.434 30.0442L215.737 30.8694H216.783L217.016 30.6336L216.434 30.0442Z'
                fill='#333333'
            />
            <path
                d='M217.971 28.4484L216.98 29.4443L217.556 30.0277L218.547 29.0318L217.971 28.4484Z'
                fill='#333333'
            />
            <path
                d='M223.296 24.2934L222.715 23.824L221.668 24.7628L222.25 25.3496L223.296 24.2934Z'
                fill='#333333'
            />
            <path
                d='M221.115 25.4237L220.124 26.4197L220.7 27.0031L221.69 26.0071L221.115 25.4237Z'
                fill='#333333'
            />
            <path
                d='M223.994 23.5889H223.644L223.76 23.826L223.994 23.5889Z'
                fill='#333333'
            />
            <path
                d='M217.115 29.451L216.538 30.0354L217.116 30.6186L217.694 30.0342L217.115 29.451Z'
                fill='#333333'
            />
            <path
                d='M218.529 27.9329L218.064 28.5196L218.529 29.1064L219.11 28.5196L218.529 27.9329Z'
                fill='#333333'
            />
            <path
                d='M220.089 26.3961L219.511 26.9812L220.091 27.5638L220.668 26.9788L220.089 26.3961Z'
                fill='#333333'
            />
            <path
                d='M221.668 25.9367L222.249 25.35L221.668 24.7632L221.203 25.35L221.668 25.9367Z'
                fill='#333333'
            />
            <path
                d='M223.294 24.2962L223.759 23.8246L223.643 23.5889H222.829L222.713 23.8246L223.294 24.2962Z'
                fill='#333333'
            />
            <path
                d='M224.304 25.4622L223.314 26.4592L223.89 27.042L224.88 26.045L224.304 25.4622Z'
                fill='#333333'
            />
            <path
                d='M227.133 23.5889H226.782L226.899 23.826L227.133 23.5889Z'
                fill='#333333'
            />
            <path
                d='M219.576 30.0442L218.762 30.8694H219.925L220.157 30.6336L219.576 30.0442Z'
                fill='#333333'
            />
            <path
                d='M225.88 23.8188L224.892 24.8179L225.47 25.3994L226.457 24.4004L225.88 23.8188Z'
                fill='#333333'
            />
            <path
                d='M220.158 29.5728L220.74 30.0422L221.67 29.1034L221.205 28.5166L220.158 29.5728Z'
                fill='#333333'
            />
            <path
                d='M222.714 26.9622L221.725 27.9602L222.302 28.5424L223.291 27.5443L222.714 26.9622Z'
                fill='#333333'
            />
            <path
                d='M220.178 29.5492L219.602 30.1355L220.183 30.7169L220.759 30.1306L220.178 29.5492Z'
                fill='#333333'
            />
            <path
                d='M221.668 27.9329L221.203 28.5196L221.668 29.1064L222.249 28.5196L221.668 27.9329Z'
                fill='#333333'
            />
            <path
                d='M223.246 26.3548L222.667 26.938L223.245 27.5224L223.824 26.9392L223.246 26.3548Z'
                fill='#333333'
            />
            <path
                d='M224.841 24.8189L224.262 25.4021L224.84 25.9866L225.419 25.4033L224.841 24.8189Z'
                fill='#333333'
            />
            <path
                d='M226.433 24.2962L226.898 23.8246L226.781 23.5889H225.968L225.851 23.8246L226.433 24.2962Z'
                fill='#333333'
            />
            <path
                d='M222.715 30.0442L221.901 30.8694H223.064L223.296 30.6336L222.715 30.0442Z'
                fill='#333333'
            />
            <path
                d='M227.407 25.4262L226.419 26.4253L226.996 27.0069L227.984 26.0078L227.407 25.4262Z'
                fill='#333333'
            />
            <path
                d='M225.784 26.9293L224.795 27.9268L225.372 28.5092L226.361 27.5118L225.784 26.9293Z'
                fill='#333333'
            />
            <path
                d='M230.155 23.5889H229.921L230.038 23.826L230.155 23.5889Z'
                fill='#333333'
            />
            <path
                d='M228.969 23.7183L227.98 24.7153L228.556 25.2981L229.545 24.3011L228.969 23.7183Z'
                fill='#333333'
            />
            <path
                d='M223.297 29.5728L223.762 30.0422L224.808 29.1034L224.227 28.5166L223.297 29.5728Z'
                fill='#333333'
            />
            <path
                d='M223.204 29.529L222.628 30.1152L223.208 30.6966L223.784 30.1103L223.204 29.529Z'
                fill='#333333'
            />
            <path
                d='M224.919 27.9729L224.342 28.5574L224.921 29.1406L225.498 28.5562L224.919 27.9729Z'
                fill='#333333'
            />
            <path
                d='M226.396 26.346L225.817 26.9292L226.395 27.5137L226.974 26.9304L226.396 26.346Z'
                fill='#333333'
            />
            <path
                d='M228.052 24.781L227.475 25.3655L228.054 25.9487L228.631 25.3643L228.052 24.781Z'
                fill='#333333'
            />
            <path
                d='M229.455 24.2962L230.036 23.8246L229.92 23.5889H229.106L228.99 23.8246L229.455 24.2962Z'
                fill='#333333'
            />
            <path
                d='M227.299 28.4783L226.311 29.4763L226.887 30.0585L227.876 29.0605L227.299 28.4783Z'
                fill='#333333'
            />
            <path
                d='M233.293 23.5889H233.06L233.177 23.826L233.293 23.5889Z'
                fill='#333333'
            />
            <path
                d='M230.491 25.4456L229.501 26.4426L230.077 27.0254L231.067 26.0284L230.491 25.4456Z'
                fill='#333333'
            />
            <path
                d='M225.853 30.0442L225.04 30.8694H226.202L226.435 30.6336L225.853 30.0442Z'
                fill='#333333'
            />
            <path
                d='M231.976 23.8762L230.988 24.8752L231.565 25.4568L232.553 24.4578L231.976 23.8762Z'
                fill='#333333'
            />
            <path
                d='M229.014 26.9431L228.025 27.9412L228.602 28.5233L229.591 27.5253L229.014 26.9431Z'
                fill='#333333'
            />
            <path
                d='M226.361 29.4969L225.783 30.0813L226.362 30.6645L226.94 30.0801L226.361 29.4969Z'
                fill='#333333'
            />
            <path
                d='M228.002 27.8731L227.424 28.4563L228.001 29.0408L228.58 28.4575L228.002 27.8731Z'
                fill='#333333'
            />
            <path
                d='M229.519 26.2951L228.939 26.8777L229.517 27.4627L230.096 26.8801L229.519 26.2951Z'
                fill='#333333'
            />
            <path
                d='M231.081 25.9367L231.662 25.35L231.081 24.7632L230.5 25.35L231.081 25.9367Z'
                fill='#333333'
            />
            <path
                d='M232.594 24.2962L233.175 23.8246L233.059 23.5889H232.245L232.129 23.8246L232.594 24.2962Z'
                fill='#333333'
            />
            <path
                d='M228.992 30.0442L228.179 30.8694H229.225L229.457 30.6336L228.992 30.0442Z'
                fill='#333333'
            />
            <path
                d='M236.43 23.5889H236.079L236.313 23.826L236.43 23.5889Z'
                fill='#333333'
            />
            <path
                d='M230.483 28.506L229.495 29.5051L230.072 30.0867L231.06 29.0876L230.483 28.506Z'
                fill='#333333'
            />
            <path
                d='M232.079 27.0145L231.09 28.0125L231.667 28.5946L232.655 27.5966L232.079 27.0145Z'
                fill='#333333'
            />
            <path
                d='M234.222 25.9352L233.641 25.3484L232.594 26.4046L233.176 26.9914L234.222 25.9352Z'
                fill='#333333'
            />
            <path
                d='M235.734 24.2934L235.153 23.824L234.223 24.7628L234.688 25.3496L235.734 24.2934Z'
                fill='#333333'
            />
            <path
                d='M229.472 29.56L228.894 30.1438L229.472 30.7276L230.05 30.1438L229.472 29.56Z'
                fill='#333333'
            />
            <path
                d='M231.129 28.0291L230.551 28.6135L231.13 29.1968L231.708 28.6123L231.129 28.0291Z'
                fill='#333333'
            />
            <path
                d='M232.594 27.4617L233.175 26.9923L232.594 26.4055L232.129 26.9923L232.594 27.4617Z'
                fill='#333333'
            />
            <path
                d='M234.17 24.8803L233.593 25.4653L234.173 26.0479L234.75 25.4629L234.17 24.8803Z'
                fill='#333333'
            />
            <path
                d='M235.735 24.2962L236.316 23.8246L236.084 23.5889H235.386L235.154 23.8246L235.735 24.2962Z'
                fill='#333333'
            />
            <path
                d='M238.873 24.2934L238.292 23.824L237.362 24.7628L237.827 25.3496L238.873 24.2934Z'
                fill='#333333'
            />
            <path
                d='M232.131 30.0442L231.317 30.8694H232.364L232.596 30.6336L232.131 30.0442Z'
                fill='#333333'
            />
            <path
                d='M239.568 23.5889H239.218L239.452 23.826L239.568 23.5889Z'
                fill='#333333'
            />
            <path
                d='M237.36 25.9352L236.779 25.3484L235.733 26.4046L236.314 26.9914L237.36 25.9352Z'
                fill='#333333'
            />
            <path
                d='M235.153 26.9924L234.223 27.9313L234.688 28.5181L235.734 27.4619L235.153 26.9924Z'
                fill='#333333'
            />
            <path
                d='M233.539 28.5009L232.549 29.4968L233.124 30.0802L234.115 29.0843L233.539 28.5009Z'
                fill='#333333'
            />
            <path
                d='M232.129 30.0434L232.594 30.6302L233.175 30.0434L232.594 29.574L232.129 30.0434Z'
                fill='#333333'
            />
            <path
                d='M234.19 28.0197L233.611 28.6023L234.188 29.1874L234.767 28.6047L234.19 28.0197Z'
                fill='#333333'
            />
            <path
                d='M235.735 27.4617L236.316 26.9923L235.735 26.4055L235.154 26.9923L235.735 27.4617Z'
                fill='#333333'
            />
            <path
                d='M237.358 25.9367L237.823 25.35L237.358 24.7632L236.777 25.35L237.358 25.9367Z'
                fill='#333333'
            />
            <path
                d='M238.874 24.2962L239.456 23.8246L239.223 23.5889H238.526L238.293 23.8246L238.874 24.2962Z'
                fill='#333333'
            />
            <path
                d='M241.398 23.8027L240.41 24.8008L240.987 25.383L241.975 24.3849L241.398 23.8027Z'
                fill='#333333'
            />
            <path
                d='M239.968 25.3384L238.979 26.3364L239.556 26.9186L240.545 25.9206L239.968 25.3384Z'
                fill='#333333'
            />
            <path
                d='M235.733 29.5728L236.314 30.0422L237.36 29.1034L236.779 28.5166L235.733 29.5728Z'
                fill='#333333'
            />
            <path
                d='M235.153 30.0442L234.456 30.8694H235.502L235.735 30.6336L235.153 30.0442Z'
                fill='#333333'
            />
            <path
                d='M242.707 23.5889H242.357L242.59 23.826L242.707 23.5889Z'
                fill='#333333'
            />
            <path
                d='M238.292 26.9924L237.362 27.9313L237.827 28.5181L238.873 27.4619L238.292 26.9924Z'
                fill='#333333'
            />
            <path
                d='M235.154 30.0434L235.735 30.6302L236.316 30.0434L235.735 29.574L235.154 30.0434Z'
                fill='#333333'
            />
            <path
                d='M237.318 27.981L236.738 28.563L237.314 29.1487L237.894 28.5667L237.318 27.981Z'
                fill='#333333'
            />
            <path
                d='M238.874 27.4617L239.456 26.9923L238.874 26.4055L238.293 26.9923L238.874 27.4617Z'
                fill='#333333'
            />
            <path
                d='M240.421 24.7763L239.842 25.3601L240.421 25.9439L240.999 25.3601L240.421 24.7763Z'
                fill='#333333'
            />
            <path
                d='M242.013 24.2962L242.594 23.8246L242.362 23.5889H241.548L241.432 23.8246L242.013 24.2962Z'
                fill='#333333'
            />
            <path
                d='M238.292 30.0442L237.595 30.8694H238.641L238.873 30.6336L238.292 30.0442Z'
                fill='#333333'
            />
            <path
                d='M238.872 29.5728L239.453 30.0422L240.383 29.1034L239.918 28.5166L238.872 29.5728Z'
                fill='#333333'
            />
            <path
                d='M245.148 24.2934L244.566 23.824L243.52 24.7628L244.101 25.3496L245.148 24.2934Z'
                fill='#333333'
            />
            <path
                d='M241.428 26.9924L240.382 27.9313L240.963 28.5181L242.009 27.4619L241.428 26.9924Z'
                fill='#333333'
            />
            <path
                d='M242.941 25.4435L241.954 26.4426L242.531 27.0242L243.518 26.0251L242.941 25.4435Z'
                fill='#333333'
            />
            <path
                d='M238.293 30.0434L238.874 30.6302L239.456 30.0434L238.874 29.574L238.293 30.0434Z'
                fill='#333333'
            />
            <path
                d='M240.454 27.99L239.876 28.5745L240.455 29.1577L241.033 28.5732L240.454 27.99Z'
                fill='#333333'
            />
            <path
                d='M242.013 27.4617L242.594 26.9923L242.013 26.4055L241.432 26.9923L242.013 27.4617Z'
                fill='#333333'
            />
            <path
                d='M243.587 24.7974L243.009 25.3818L243.588 25.9651L244.166 25.3806L243.587 24.7974Z'
                fill='#333333'
            />
            <path
                d='M245.146 24.2962L245.611 23.8246C245.611 23.8246 245.03 23.5889 244.681 23.5889H244.565L245.146 24.2962Z'
                fill='#333333'
            />
            <path
                d='M247.243 25.3533L247.476 25.1175C247.476 25.1175 247.127 24.646 247.011 24.5281L246.778 24.7639L247.359 25.3533H247.243Z'
                fill='#333333'
            />
            <path
                d='M245.614 26.9914L246.661 25.9352L246.079 25.3484L245.149 26.4046L245.614 26.9914Z'
                fill='#333333'
            />
            <path
                d='M244.538 26.9412L243.548 27.9382L244.124 28.521L245.114 27.524L244.538 26.9412Z'
                fill='#333333'
            />
            <path
                d='M242.965 28.5071L241.977 29.5051L242.553 30.0873L243.542 29.0893L242.965 28.5071Z'
                fill='#333333'
            />
            <path
                d='M241.428 30.0442L240.614 30.8694H241.777L242.009 30.6336L241.428 30.0442Z'
                fill='#333333'
            />
            <path
                d='M242.009 29.5136L241.432 30.0981L242.011 30.6814L242.588 30.0969L242.009 29.5136Z'
                fill='#333333'
            />
            <path
                d='M243.56 27.9165L242.981 28.4998L243.559 29.0842L244.138 28.501L243.56 27.9165Z'
                fill='#333333'
            />
            <path
                d='M245.146 27.4617L245.611 26.9923L245.146 26.4055L244.565 26.9923L245.146 27.4617Z'
                fill='#333333'
            />
            <path
                d='M247.242 25.35L246.661 24.7632L246.08 25.35L246.661 25.9367L247.242 25.35Z'
                fill='#333333'
            />
            <path
                d='M246.659 27.9313L247.24 28.5181L248.17 27.5792L247.705 26.9924L246.659 28.0486V27.9313Z'
                fill='#333333'
            />
            <path
                d='M245.149 29.5728L245.614 30.0422L246.661 29.1034L246.079 28.5166L245.149 29.5728Z'
                fill='#333333'
            />
            <path
                d='M244.567 30.0442L243.753 30.8694H244.567C244.567 30.8694 244.799 30.8694 244.915 30.8694H245.032L244.45 30.1621L244.567 30.0442Z'
                fill='#333333'
            />
            <path
                d='M244.565 30.0434L245.146 30.6302L245.611 30.0434L245.146 29.574L244.565 30.0434Z'
                fill='#333333'
            />
            <path
                d='M246.71 27.9077L246.131 28.491L246.709 29.0754L247.287 28.4922L246.71 27.9077Z'
                fill='#333333'
            />
            <path
                d='M248.168 27.4611C248.168 27.4611 248.168 27.3437 248.168 27.2264C248.168 26.9916 248.168 26.7569 248.052 26.5222L247.703 26.8743L248.168 27.3437V27.4611Z'
                fill='#333333'
            />
        </g>
        <g opacity='0.3'>
            <path
                d='M289.441 27.9312C289.441 31.6865 286.419 34.7378 282.699 34.7378C278.98 34.7378 275.958 31.6865 275.958 27.9312C275.958 24.1758 278.98 21.1245 282.699 21.1245C286.419 21.1245 289.441 24.1758 289.441 27.9312Z'
                fill='url(#paint11_radial_3054_7601)'
            />
            <path
                d='M285.487 23.1187C282 23.3534 280.257 26.6393 283.628 31.9203C287.347 31.5683 288.858 28.165 285.487 23.1187Z'
                fill='url(#paint12_linear_3054_7601)'
            />
        </g>
        <path
            d='M418.579 793.444H425.437V788.281H418.579V793.444ZM418.579 82.854V88.0177H425.437V82.854H418.579Z'
            fill='url(#paint13_linear_3054_7601)'
        />
        <path
            d='M9.65043 788.281H2.79224V793.444H9.65043V788.281ZM2.79224 82.854V88.0177H9.65043V82.854H2.79224Z'
            fill='url(#paint14_linear_3054_7601)'
        />
        <path
            d='M343.143 870.076V877H348.258V870.076H343.143ZM77.1851 877H82.2996V870.076H77.1851V877Z'
            fill='url(#paint15_linear_3054_7601)'
        />
        <path
            d='M343.143 0V6.924H348.258V0H343.143ZM77.1851 6.924H82.2996V0H77.1851V6.924Z'
            fill='url(#paint16_linear_3054_7601)'
        />
        <path
            d='M427.768 223.913C426.838 224.735 426.838 227.434 426.838 232.48V317.915C426.838 319.558 426.838 321.436 427.651 321.671C428 321.436 428 320.497 428 319.441V227.551C428 226.26 428 224.383 427.651 224.148L427.768 223.913Z'
            fill='url(#paint17_linear_3054_7601)'
        />
        <path
            d='M426.954 317.916V232.481C426.954 227.552 426.954 224.853 427.884 223.914L425.443 222.389C424.164 224.266 424.28 228.374 424.28 229.899V315.687C424.28 319.559 424.513 322.141 425.21 322.728L427.768 321.672C426.954 321.437 426.954 319.559 426.954 317.916Z'
            fill='url(#paint18_linear_3054_7601)'
        />
        <path
            d='M0.465108 207.837C1.39503 208.658 1.39505 211.357 1.39505 216.404V265.341C1.39505 266.984 1.39506 268.862 0.581373 269.096C0.232652 268.862 0.232666 267.923 0.232666 266.867V211.475C0.232666 210.184 0.232652 208.306 0.581373 208.071L0.465108 207.837Z'
            fill='url(#paint19_linear_3054_7601)'
        />
        <path
            d='M1.39552 265.225V216.288C1.39552 211.359 1.3955 208.66 0.465576 207.721L2.90666 206.195C4.18531 208.073 4.06905 212.18 4.06905 213.706V262.996C4.06905 266.868 3.83659 269.45 3.13915 270.037L0.581841 268.981C1.39552 268.746 1.39552 266.868 1.39552 265.225Z'
            fill='url(#paint20_linear_3054_7601)'
        />
        <path
            d='M0.465108 286.583C1.39503 287.404 1.39505 290.103 1.39505 295.149V344.087C1.39505 345.73 1.39506 347.608 0.581373 347.842C0.232652 347.608 0.232666 346.669 0.232666 345.613V290.221C0.232666 288.93 0.232652 287.052 0.581373 286.817L0.465108 286.583Z'
            fill='url(#paint21_linear_3054_7601)'
        />
        <path
            d='M1.39552 343.971V295.033C1.39552 290.104 1.3955 287.405 0.465576 286.466L2.90666 284.941C4.18531 286.818 4.06905 290.926 4.06905 292.451V341.741C4.06905 345.614 3.83659 348.196 3.13915 348.782L0.581841 347.726C1.39552 347.491 1.39552 345.614 1.39552 343.971Z'
            fill='url(#paint22_linear_3054_7601)'
        />
        <path
            d='M0 141.531V172.396L0.81368 173.1V140.71L0 141.531Z'
            fill='#555658'
        />
        <path
            d='M2.9066 140.709H0.697998V173.099H3.02282C3.02282 173.099 3.37152 172.161 3.37152 171.339V142.352C3.37152 141.413 3.37153 140.827 2.79033 140.592L2.9066 140.709Z'
            fill='url(#paint23_linear_3054_7601)'
        />
        <defs>
            <linearGradient
                id='paint0_linear_3054_7601'
                x1='388.363'
                y1='9.856'
                x2='420.049'
                y2='30.8179'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint1_linear_3054_7601'
                x1='393.708'
                y1='223.914'
                x2='423.815'
                y2='223.914'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint2_linear_3054_7601'
                x1='9.76739'
                y1='30.6278'
                x2='41.9202'
                y2='9.4349'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint3_linear_3054_7601'
                x1='35.223'
                y1='223.327'
                x2='5.11667'
                y2='223.327'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint4_linear_3054_7601'
                x1='214.464'
                y1='15.2553'
                x2='214.464'
                y2='1.4073'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint5_linear_3054_7601'
                x1='388.244'
                y1='867.145'
                x2='419.816'
                y2='846.066'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint6_linear_3054_7601'
                x1='393.941'
                y1='645.695'
                x2='423.698'
                y2='645.695'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint7_linear_3054_7601'
                x1='41.1505'
                y1='867.38'
                x2='11.0994'
                y2='847.343'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4D4D4D' />
                <stop offset='1' stopColor='#1A1A1A' />
            </linearGradient>
            <linearGradient
                id='paint8_linear_3054_7601'
                x1='34.9905'
                y1='644.99'
                x2='5.00043'
                y2='644.99'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#7C7D7D' />
                <stop offset='0.14' stopColor='#7F8080' />
                <stop offset='0.26' stopColor='#898A8B' />
                <stop offset='0.38' stopColor='#9A9B9D' />
                <stop offset='0.49' stopColor='#B1B3B6' />
                <stop offset='0.59' stopColor='#CFD2D7' />
                <stop offset='0.7' stopColor='#F5F9FF' />
                <stop offset='0.78' stopColor='#F2F6FC' />
                <stop offset='0.83' stopColor='#EAEEF3' />
                <stop offset='0.87' stopColor='#DCDFE5' />
                <stop offset='0.91' stopColor='#C9CBD0' />
                <stop offset='0.94' stopColor='#B0B1B5' />
                <stop offset='0.97' stopColor='#919295' />
                <stop offset='1' stopColor='#737375' />
            </linearGradient>
            <linearGradient
                id='paint9_linear_3054_7601'
                x1='214.464'
                y1='861.748'
                x2='214.464'
                y2='875.596'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#BFC1C7' />
                <stop offset='0.35' stopColor='#C1C3C9' />
                <stop offset='0.57' stopColor='#CACCD1' />
                <stop offset='0.75' stopColor='#D9DADF' />
                <stop offset='0.92' stopColor='#EDEEF2' />
                <stop offset='1' stopColor='#FBFCFF' />
            </linearGradient>
            <linearGradient
                id='paint10_linear_3054_7601'
                x1='11.1584'
                y1='438.206'
                x2='417.768'
                y2='438.206'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#2E2E2E' />
                <stop offset='0.07' />
                <stop offset='0.18' stopColor='#363636' />
                <stop offset='0.8' stopColor='#363636' />
                <stop offset='0.94' />
                <stop offset='1' stopColor='#2E2E2E' />
            </linearGradient>
            <radialGradient
                id='paint11_radial_3054_7601'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(267.874 34.4374) scale(6.67916 6.74327)'
            >
                <stop offset='0.12' />
                <stop offset='0.3' stopColor='#050505' />
                <stop offset='0.51' stopColor='#141414' />
                <stop offset='0.74' stopColor='#2C2C2C' />
                <stop offset='0.98' stopColor='#4E4E4E' />
                <stop offset='0.99' stopColor='#515151' />
            </radialGradient>
            <linearGradient
                id='paint12_linear_3054_7601'
                x1='282.233'
                y1='24.5269'
                x2='288.23'
                y2='31.8643'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#828282' />
                <stop offset='0.12' stopColor='#646464' />
                <stop offset='0.3' stopColor='#404040' />
                <stop offset='0.47' stopColor='#242424' />
                <stop offset='0.65' stopColor='#101010' />
                <stop offset='0.82' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint13_linear_3054_7601'
                x1='418.579'
                y1='438.09'
                x2='425.437'
                y2='438.09'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint14_linear_3054_7601'
                x1='2.90848'
                y1='438.09'
                x2='9.65043'
                y2='438.09'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint15_linear_3054_7601'
                x1='212.721'
                y1='877'
                x2='212.721'
                y2='870.076'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#1F1F1F' />
                <stop offset='0.28' stopColor='#101010' />
                <stop offset='0.64' stopColor='#040404' />
                <stop offset='1' />
            </linearGradient>
            <linearGradient
                id='paint16_linear_3054_7601'
                x1='212.721'
                y1='6.924'
                x2='212.721'
                y2='0'
                gradientUnits='userSpaceOnUse'
            >
                <stop />
                <stop offset='0.36' stopColor='#040404' />
                <stop offset='0.72' stopColor='#101010' />
                <stop offset='1' stopColor='#1F1F1F' />
            </linearGradient>
            <linearGradient
                id='paint17_linear_3054_7601'
                x1='426.954'
                y1='272.733'
                x2='428.116'
                y2='272.733'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.17' stopColor='#4D4D4D' />
                <stop offset='0.33' stopColor='#595959' />
                <stop offset='0.48' stopColor='#6C6C6C' />
                <stop offset='0.64' stopColor='#878787' />
                <stop offset='0.79' stopColor='#AAAAAA' />
                <stop offset='0.94' stopColor='#D4D4D4' />
                <stop offset='1' stopColor='#E6E6E6' />
            </linearGradient>
            <linearGradient
                id='paint18_linear_3054_7601'
                x1='426.024'
                y1='222.154'
                x2='426.024'
                y2='322.845'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint19_linear_3054_7601'
                x1='1.39505'
                y1='238.349'
                x2='0.116427'
                y2='238.349'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.29' stopColor='#4D4D4D' />
                <stop offset='0.56' stopColor='#595959' />
                <stop offset='0.83' stopColor='#6C6C6C' />
                <stop offset='1' stopColor='#7D7D7D' />
            </linearGradient>
            <linearGradient
                id='paint20_linear_3054_7601'
                x1='2.32543'
                y1='206.078'
                x2='2.32543'
                y2='270.154'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint21_linear_3054_7601'
                x1='1.39505'
                y1='317.095'
                x2='0.116427'
                y2='317.095'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#4A4A4A' />
                <stop offset='0.4' stopColor='#4D4D4D' />
                <stop offset='0.79' stopColor='#595959' />
                <stop offset='1' stopColor='#636363' />
            </linearGradient>
            <linearGradient
                id='paint22_linear_3054_7601'
                x1='2.32543'
                y1='284.823'
                x2='2.32543'
                y2='348.9'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
            <linearGradient
                id='paint23_linear_3054_7601'
                x1='2.09288'
                y1='140.709'
                x2='2.09288'
                y2='173.217'
                gradientUnits='userSpaceOnUse'
            >
                <stop stopColor='#121212' />
                <stop offset='0.16' stopColor='#333333' />
                <stop offset='0.91' stopColor='#333333' />
                <stop offset='1' stopColor='#242424' />
            </linearGradient>
        </defs>
    </svg>
)

export default PhoneBig
