import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const NewsRoutes = [
    {
        id: 1,
        module: 'loader-news',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.news,
        component: lazy(
            () => import('containers/emulator/pages/blog/blog-page/BlogPage'),
        ),
    },
    {
        id: 2,
        module: 'loader-news',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.news}/:id`,
        component: lazy(
            () => import('containers/emulator/pages/blog/blog-item/BlogItem'),
        ),
    },
]
