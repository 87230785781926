import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { NOTIFICATION_URLS } from 'config/urls'

import { INotification } from '../store/types'

export const getNotifications = ({
    lang,
}: {
    lang: string
}): Promise<AxiosResponse<INotification>> =>
    axiosInstance.get(`${NOTIFICATION_URLS.main}/${lang}`)

export const deleteNotification = ({
    noteId,
}: {
    noteId: string
}): Promise<AxiosResponse<INotification>> =>
    axiosInstance.delete(`${NOTIFICATION_URLS.main}/${noteId}`)

export const readNotification = ({
    noteId,
    lang,
}: {
    noteId: string
    lang: string
}): Promise<AxiosResponse<INotification>> =>
    axiosInstance.put(
        `${NOTIFICATION_URLS.main}/${lang}${NOTIFICATION_URLS.read}/${noteId}`,
    )

export const readAllNotifications = ({
    lang,
}: {
    lang: string
}): Promise<AxiosResponse<INotification>> =>
    axiosInstance.put(
        `${NOTIFICATION_URLS.main}/${lang}${NOTIFICATION_URLS.read}`,
    )
