import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { generationTypes } from './actionTypes'
import { getAllGeneratedText, generateText } from '../service/ai'

import { IGeneration, CreateGenerationsRequest } from './types'

function* fetchGenerationsSaga() {
    try {
        const response: AxiosResponse<IGeneration[]> = yield call(
            getAllGeneratedText,
        )
        if (response.data) {
            yield put({
                type: generationTypes.SET_GENERETIONS,
                payload: { blogs: response.data },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* createGenerationsSaga(action: CreateGenerationsRequest) {
    try {
        const response: AxiosResponse<IGeneration> = yield call(
            generateText,
            action.payload,
        )
        if (response && response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback(response.data)
            }
        }
    } catch (error) {
        console.log('sagaerr', error)
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* blogSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(
            generationTypes.FETCH_GENERATIONS_REQUEST,
            fetchGenerationsSaga,
        ),
    ])
    yield all([
        takeLatest(
            generationTypes.CREATE_GENERATION_REQUEST,
            createGenerationsSaga,
        ),
    ])
}

export default blogSaga
