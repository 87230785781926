export const AUTH_URLS = {
    register: '/sign-up',
}

export const USER_URLS = {
    changeUser: '/user/user',
    resetPassword: '/user/reset-pwd',
    recoveryPassword: '/user/recovery-pwd',
    confirmEmail: '/user/confirm-email',
    resendEmail: '/user/resend-confirm-email',
}

export const GENERATION_URLS = {
    main: '/generation',
    completion: '/generation/completion',
    pictures: '/generation/picture',
}

export const APPLICATION_URLS = {
    applications: '/applications',
}

export const ICON_URLS = {
    main: '/icon',
}

// strange and wrong urls
export const LOGO_URLS = {
    main: '/logo',
}

export const VARIANT_URLS = {
    createSurvey: '/survey',
}

export const CLOUD_URLS = {
    main: '/cloud',
}

export const FLASH_CARD_URLS = {
    main: '/flashCard',
}

export const LINKS_URLS = {
    link: '/link',
    links: '/links',
}

export const LOCATION_URLS = {
    location: '/location',
}

export const QUOTES_URLS = {
    quotes: '/quotes',
    quote: '/quote',
}

export const SMM_URLS = {
    main: '/smm',
}

export const SPLASH_SCREEN_URLS = {
    main: '/splashScreen',
}

export const CONFERENCE_URLS = {
    main: '/video-conference',
}

export const BLOG_URLS = {
    blogs: '/blogs',
    blog: '/blog',
}

export const EMPLOYEES_URLS = {
    employees: '/employees',
}

export const SERVICES_URLS = {
    services: '/services',
}

export const BOOKING_URLS = {
    booking: '/booking',
    location: '/location',
}

export const E_READER_URLS = {
    main: '/e-reader',
}

export const CHAT_URLS = {
    main: '/chats',
}

export const INFO_URLS = {
    main: '/information',
}

export const IMAGE_UPLOAD = {
    main: '/file/upload',
}

export const CALENDAR_URLS = {
    events: '/calendar/events',
    event: '/calendar/event',
}

export const TEAM_URLS = {
    main: '/settings/team',
}

export const GROUP_URLS = {
    main: '/settings/group',
}

export const USERS_URLS = {
    main: '/settings/users',
    user: '/user',
}

export const ACCOUNT_URLS = {
    main: '/settings/account/general',
}

export const FIREBASE_URLS = {
    main: '/settings',
}

export const PUSH_URLS = {
    main: '/push-notifications',
}

export const MOBILE_USERS = {
    main: '/mobile-users',
}

export const PAYMENT_URLS = {
    subscription: '/checkout/subscriptiontypes',
    credits: '/checkout/point-types',
    main: '/Checkout',
    promocode: '/Checkout/apply-promotion-code',
}

export const LIQPAY_URLS = {
    main: '/payments',
}

export const NOTIFICATION_URLS = {
    main: '/notifications',
    read: '/read',
}

export const TODO_PREMIUM_URLS = {
    main: '/todoList/settings',
}

export const LAUNCH_SCREEN_URLS = {
    main: '/welcome-page',
}

export const GALLERY_URLS = {
    gallery: '/photos',
}

export const FORUM_URLS = {
    themes: '/forum/themes',
    messages: '/forum/messages',
    users: '/forum/users',
}

export const DESIGN_URLS = {
    designLogo: '/logo',
}

export const VIDEO_URLS = {
    main: '/video',
}

export const PUBLISHING_URLS = {
    main: '/checklists',
}

// Mocked
export const SURVEY_URLS = {
    main: '/surveys',
}

export const BUILD_URLS = {
    main: '/build',
}

export const SCHEDULE_URLS = {
    main: '/schedule',
}

export const FEATURES_URLS = {
    main: '/settings/features',
}

export const CONTACT_US_URLS = {
    main: '/email/contact-us',
}
