import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'

import { userRolesTypes } from './actionTypes'
import {
    editUserRoles,
    fetchAdditional,
    getUserRoles,
    removeUserRole,
} from '../service/userRoles'

import {
    IUser,
    FetchUserRolesRequest,
    EditUserRolesRequest,
    IUserRequest,
    RemoveUserRoleAction,
    FetchAdditionalAction,
} from './types'

function* fetchUserRolesSaga(action: FetchUserRolesRequest) {
    try {
        const response: AxiosResponse<IPagination<IUser>> = yield call(
            getUserRoles,
            action.payload,
        )
        if (response.data) {
            const updatedusers = response.data.items.map(user => ({
                ...user,
                isBlocked: user.isBlocked ? 'blocked' : 'not-blocked',
                isAdmin: user.isAdmin ? 'admin' : 'user',
                isEdit: false,
                isCollapsed: false,
            }))

            yield put({
                type: userRolesTypes.SET_USER_ROLES,
                payload: { users: updatedusers },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editUserRolesSaga(action: EditUserRolesRequest) {
    const { users } = action.payload

    const userTyped: IUser[] | IUserRequest[] = users.map(user => {
        const admin = user.isAdmin === 'admin'
        const blocked = user.isBlocked === 'blocked'
        return { ...user, isAdmin: admin, isBlocked: blocked }
    })
    console.log(userTyped, 'userTyped')

    try {
        const response: AxiosResponse<IUser[]> = yield call(editUserRoles, {
            ...action.payload,
            users: userTyped,
        })
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* removeUserRoleSaga(action: RemoveUserRoleAction) {
    try {
        const response: AxiosResponse<IUser> = yield call(
            removeUserRole,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { users } = yield select(state => state.userRoles)
            const updatedUsers = users.filter(
                (user: IUser) => user.id !== action.payload.id,
            )

            yield put({
                type: userRolesTypes.SET_USER_ROLES,
                payload: { users: updatedUsers },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchAdditionalSaga(action: FetchAdditionalAction) {
    try {
        const response: AxiosResponse<IUser> = yield call(
            fetchAdditional,
            action.payload,
        )

        if (response) {
            const { users } = yield select(state => state.userRoles)

            const updatedUsers = users.map((user: IUser) =>
                user.id === action.payload.id
                    ? {
                          ...user,
                          isCollapsed: true,
                          bookings: response.data.bookings,
                          dateOfBirthday: response.data.dateOfBirthday,
                      }
                    : user,
            )

            yield put({
                type: userRolesTypes.SET_USER_ROLES,
                payload: { users: updatedUsers },
            })

            if (action.payload.callback)
                action.payload.callback(response.statusText)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* userRolesSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(userRolesTypes.FETCH_USER_ROLES_REQUEST, fetchUserRolesSaga),
        takeLatest(userRolesTypes.EDIT_USER_ROLES, editUserRolesSaga),
        takeLatest(userRolesTypes.REMOVE_USER_ROLE, removeUserRoleSaga),
        takeLatest(
            userRolesTypes.FETCH_ADDITIONAL_REQUEST,
            fetchAdditionalSaga,
        ),
    ])
}

export default userRolesSaga
