/* eslint-disable max-len */
import React from 'react'

import BlogPage from 'containers/emulator/pages/blog/blog-page/BlogPage'
import BlogItem from 'containers/emulator/pages/blog/blog-item/BlogItem'
import BillingPage from 'containers/emulator/pages/billing/billing-page/BillingPage'
// import BillingItem from 'containers/emulator/pages/billing/billing-item/BillingItem'
import VideoPage from 'containers/emulator/pages/cloud/tabs/video/VideoPage'
import ConferencePage from 'containers/emulator/pages/cloud/tabs/conference/ConferencePage'
import CalendarPage from 'containers/emulator/pages/calendar/CalendarPage'
import GalleryPage from 'containers/emulator/pages/gallery/gallery-page/GalleryPage'
import ImagePage from 'containers/emulator/pages/gallery/image-page/ImagePage'
import AboutPage from 'containers/emulator/pages/about/about-page/AboutPage'
import AboutItem from 'containers/emulator/pages/about/about-item/AboutItem'
import MapPage from 'containers/emulator/pages/map/map-page/MapPage'
import MapItemPage from 'containers/emulator/pages/map/map-item/MapItemPage'
import CloudPage from 'containers/emulator/pages/cloud/CloudPage'
import ChatPage from 'containers/emulator/pages/cloud/tabs/chat/ChatPage'
import SmmPage from 'containers/emulator/pages/cloud/tabs/smm/SmmPage'
import TodoPage from 'containers/emulator/pages/todo/TodoPage'
import EReaderPage from 'containers/emulator/pages/e-reader/EReaderPage'
import WelcomePage from 'containers/emulator/pages/welcome/WelcomePage'
import ServicePage from 'containers/emulator/pages/services/service-page/ServicePage'
import ServiceItem from 'containers/emulator/pages/services/service-item/ServiceItem'
import EmployeesPage from 'containers/emulator/pages/employees/employee-page/EmployeePage'
import BookingPage from 'containers/emulator/pages/booking/booking-page/BookingPage'
// import BookingItem from 'containers/emulator/pages/booking/booking-item/BookingItem'
import BookingManagement from 'containers/emulator/pages/booking/booking-managment/BookingManagment'
import PaymentsPage from 'containers/emulator/pages/payments/payments-page/PaymentsPage'
import PaymentItem from 'containers/emulator/pages/payments/payments-item/PaymentItem'
import EmployeeItem from 'containers/emulator/pages/employees/employee-item/EmployeeItem'
import SurveyPage from 'containers/emulator/pages/survey/survey-page/SurveyPage'
import SurveyItem from 'containers/emulator/pages/survey/survey-item/SurveyItem'

interface Props {
    component?: string
}

export const ComponentRender = ({ component }: Props): React.ReactElement => {
    switch (component) {
        case 'welcome':
            return <WelcomePage />
        case 'blog':
            return <BlogPage />
        case 'blog-edit':
            return <BlogItem />
        case 'billing':
            return <BillingPage />
        // case 'billing-edit':
        //     return <BillingItem />
        case 'news':
            return <BlogPage />
        case 'news-edit':
            return <BlogItem />
        case 'service':
            return <ServicePage />
        case 'service-edit':
            return <ServiceItem />
        case 'employees':
            return <EmployeesPage />
        case 'employee-create':
            return <EmployeeItem />
        case 'employee-edit':
            return <EmployeeItem />
        case 'booking':
            return <BookingPage />
        case 'booking-edit':
            return <BookingPage />
        case 'booking-managment':
            return <BookingManagement />
        case 'video':
            return <VideoPage />
        case 'conference':
            return <ConferencePage />
        case 'calendar':
            return <CalendarPage />
        case 'gallery':
            return <GalleryPage />
        case 'gallery-edit':
            return <ImagePage />
        case 'map':
            return <MapPage />
        case 'map-edit':
            return <MapItemPage />
        case 'about':
            return <AboutPage />
        case 'about-edit':
            return <AboutItem />
        case 'cloud':
            return <CloudPage />
        case 'chat':
            return <ChatPage />
        case 'todo':
            return <TodoPage />
        case 'smm':
            return <SmmPage />
        case 'e-reader':
            return <EReaderPage />
        case 'payments':
            return <PaymentsPage />
        case 'payment':
            return <PaymentItem />
        case 'surveyList':
            return <SurveyPage />
        case 'surveyItem':
            return <SurveyItem />
        default:
            return <></>
    }
}
