import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { getUserById } from '../../services/forum'
import { forumUserTypes } from './actionTypes'

import { FetchUserByIdRequest, IForumUser } from './types'

function* fetchUserByIdSaga(action: FetchUserByIdRequest) {
    try {
        const response: AxiosResponse<IForumUser> = yield call(
            getUserById,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: forumUserTypes.SET_FORUM_USER,
                payload: { user: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* userSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(forumUserTypes.FETCH_FORUM_USER_BY_ID, fetchUserByIdSaga),
    ])
}

export default userSaga
