import {
    PATH_BRANDING,
    PATH_DESIGN,
    PATH_FEATURES,
    PATH_SETTINGS,
    PATH_BUILD,
    PATH_NEW_LOADER,
    // PATH_ANALYTICS,
} from 'config/paths'

export const data = [
    {
        key: 'app-name',
        path: PATH_BRANDING.root,
        name: 'appName',
        icon: 'app-name',
        includes: [],
    },
    {
        key: 'design',
        path: PATH_DESIGN.colorPalette,
        name: 'design',
        icon: 'design',
        includes: [
            {
                key: 'design1',
                path: PATH_DESIGN.colorPalette,
                name: 'generalDesign',
                type: 'generalDesign',
                comingSoon: false,
                icon: 'general-design',
            },
            {
                key: 'design2',
                path: PATH_DESIGN.background,
                name: 'ownDesign',
                type: 'ownDesign',
                comingSoon: false,
                icon: 'own-design',
            },
            {
                key: 'design3',
                path: PATH_DESIGN.launchScreen,
                name: 'welcomeScreen',
                type: 'welcomeScreen',
                comingSoon: false,
                icon: 'welcome-screen',
            },
            {
                key: 'design4',
                path: PATH_DESIGN.icon,
                name: 'icon',
                type: 'icon',
                comingSoon: false,
                icon: 'icon',
            },
        ],
    },
    {
        key: 'features',
        path: PATH_FEATURES.root,
        name: 'features',
        icon: 'features',
        includes: [
            {
                key: 'feature1',
                path: PATH_FEATURES.blog.root,
                name: 'blog',
                type: 'Blog',
                comingSoon: false,
                icon: 'blog',
            },
            // {
            //     key: 'features2',
            //     path: PATH_FEATURES.video.root,
            //     name: 'video',
            //     type: 'Video',
            //     comingSoon: false,
            //     icon: 'video',
            // },
            // {
            //     key: 'features3',
            //     path: PATH_FEATURES.videoConference.root,
            //     name: 'conference',
            //     type: 'VideoConference',
            //     comingSoon: false,
            //     icon: 'conference',
            // },
            {
                key: 'features4',
                path: PATH_FEATURES.calendar.root,
                name: 'calendar',
                type: 'Calendar',
                comingSoon: false,
                icon: 'calendar',
            },
            {
                key: 'features5',
                path: PATH_FEATURES.gallery.root,
                name: 'gallery',
                type: 'Gallery',
                comingSoon: false,
                icon: 'gallery',
            },
            {
                key: 'features6',
                path: PATH_FEATURES.information.root,
                name: 'about',
                type: 'Info',
                comingSoon: false,
                icon: 'about',
            },
            {
                key: 'features7',
                path: PATH_FEATURES.news.root,
                name: 'news',
                type: 'NewsAndPromotions',
                comingSoon: false,
                icon: 'news',
            },
            {
                key: 'features8',
                path: PATH_FEATURES.map.root,
                name: 'map',
                type: 'Map',
                comingSoon: false,
                icon: 'map',
            },
            {
                key: 'features9',
                path: PATH_FEATURES.eReader.root,
                name: 'reader',
                type: 'EReader',
                comingSoon: false,
                icon: 'e-reader',
            },
            {
                key: 'features10',
                path: PATH_FEATURES.employees.root,
                name: 'employee',
                type: 'Employee',
                comingSoon: false,
                icon: 'employee',
            },
            {
                key: 'features11',
                path: PATH_FEATURES.services.root,
                name: 'services',
                type: 'Service',
                comingSoon: false,
                icon: 'services',
            },
            // {
            //     key: 'features17',
            //     path: PATH_FEATURES.billingInfo.root,
            //     icon: 'billing-info',
            // },
            {
                key: 'features12',
                path: PATH_FEATURES.cloud.root,
                name: 'cloud',
                type: 'CloudContent',
                comingSoon: false,
                icon: 'cloud',
            },
            // {
            //     key: 'feature11',
            //     path: PATH_FEATURES.smm.root,
            //     name: 'smm',
            //     type: 'Smm',
            //     comingSoon: false,
            //     icon: 'smm',
            // },
            // {
            //     key: 'features12',
            //     path: PATH_FEATURES.chat.root,
            //     name: 'chat',
            //     type: 'Chat',
            //     comingSoon: false,
            //     icon: 'chat-links',
            // },
            // {
            //     key: 'features9',
            //     path: PATH_FEATURES.membersCard.root,
            //     icon: FeaturesIcon,
            // },
            {
                key: 'features15',
                path: PATH_FEATURES.test.root,
                name: 'survey',
                type: 'Survey',
                comingSoon: false,
                icon: 'survey',
            },
            // {
            //     key: 'features13',
            //     path: PATH_FEATURES.premium.todo.root,
            //     name: 'todo',
            //     type: 'Todo',
            //     comingSoon: false,
            //     icon: 'todo',
            // },
            // {
            //     key: 'features14',
            //     path: PATH_FEATURES.premium.chat.root,
            //     name: 'liveChat',
            //     type: 'LiveChat',
            //     comingSoon: false,
            //     icon: 'chat-premium',
            // },
            {
                key: 'features16',
                path: PATH_FEATURES.premium.forum.themes.root,
                name: 'forum',
                type: 'Forum',
                comingSoon: true,
                icon: 'forum',
            },

            {
                key: 'features18',
                path: PATH_FEATURES.booking.root,
                name: 'booking',
                type: 'Booking',
                comingSoon: false,
                icon: 'booking',
            },
            {
                key: 'features20',
                path: PATH_FEATURES.paymentLink.root,
                name: 'paymentLink',
                type: 'paymentLink',
                comingSoon: false,
                icon: 'payment-link',
            },
            {
                key: 'feature21',
                path: PATH_FEATURES.payments.root,
                name: 'payments',
                type: 'Payments',
                comingSoon: false,
                icon: 'payments',
            },
        ],
    },
    {
        key: 'app-settings',
        path: PATH_SETTINGS.appSettings.general,
        name: 'settings',
        icon: 'settings',
        includes: [
            {
                key: 'app-settings1',
                path: PATH_SETTINGS.appSettings.general,
                name: 'generalSettings',
                comingSoon: false,
                type: undefined,
                icon: 'general-settings',
            },
            {
                key: 'app-settings2',
                path: PATH_SETTINGS.featuresActivation.root,
                name: 'factivation',
                comingSoon: false,
                type: undefined,
                icon: 'feature-activation',
            },
            {
                key: 'app-settings3',
                path: PATH_SETTINGS.userRoles.root,
                name: 'userManagement',
                comingSoon: false,
                type: undefined,
                icon: 'user-roles',
            },
            // {
            //     key: 'app-settings4',
            //     path: PATH_SETTINGS.pushNotiications.root,
            //     name: 'pushNotifications',
            //     comingSoon: false,
            //     type: undefined,
            //     icon: 'push',
            // },
        ],
    },
    {
        key: 'build',
        path: `${PATH_BUILD.android}/?buildtype=apk`,
        name: 'build',
        icon: 'build',
        includes: [
            {
                key: 'build1',
                path: `${PATH_BUILD.android}/?buildtype=apk`,
                name: 'android',
                comingSoon: false,
                type: undefined,
                icon: 'android',
            },
            {
                key: 'build2',
                path: PATH_BUILD.ios,
                name: 'ios',
                comingSoon: false,
                type: undefined,
                icon: 'ios',
            },
        ],
    },
    // {
    //     key: 'analytics',
    //     path: PATH_ANALYTICS.root,
    //     name: 'analytics',
    //     icon: 'analytics',
    //     includes: [],
    // },
    {
        key: 'prototype',
        path: PATH_NEW_LOADER.welcome,
        name: 'prototype',
        icon: 'loader',
        includes: [],
    },
]
