import { lazy } from 'react'

import { PATH_AUTH } from 'config/paths'

export const AuthRoutes = [
    {
        id: 1,
        module: 'auth',
        protected: false,
        exact: true,
        path: PATH_AUTH.login,
        component: lazy(() => import('containers/auth/login/Login')),
    },
    {
        id: 2,
        module: 'auth',
        protected: false,
        exact: true,
        path: PATH_AUTH.registration,
        component: lazy(
            () => import('containers/auth/registration/Registration'),
        ),
    },
    {
        id: 3,
        module: 'auth',
        protected: false,
        exact: true,
        path: PATH_AUTH.sendResetPassword,
        component: lazy(
            () => import('containers/auth/reset-password/sendEmail'),
        ),
    },
]
