import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const ServicesRoutes = [
    {
        id: 1,
        module: 'loader-service',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.services,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/services/service-page/ServicePage'
                ),
        ),
    },
    {
        id: 2,
        module: 'loader-service',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.services}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/services/service-item/ServiceItem'
                ),
        ),
    },
]
