function path(root: string, subLink: string) {
    return `${root}${subLink}`
}

const ROOTS_ROOT = '/'
const ACCOUNT_ROOT = '/account'
const BILLING_ROOT = '/billing-information'
const FEATURES_ROOT = '/features'
const TUTORIAL_ROOT = '/tutorial'
const APP_SETTINGS = '/app-settings'
const APPLICATIONS = '/'
// const SETTINGS_ROOT = '/settings'
const APP_ROOT = '/app-list'
const WELCOME_ROOT = '/welcome'
const ANALYTICS_ROOT = '/analytics'
const DESIGN_ROOT = '/design'
const APP_NAME_ROOT = '/app-name'
const NEW_LOADER_ROOT = '/loader'
const BUILD_ROOT = '/build'
const DASHBOARD_ROOT = '/dashboard'
const NOTIFICATIONS_ROOT = '/notifications'
const LANGUAGE_ROOT = '/language'
const PATH_PLACEMENT_ROOT = '/placement'

export const PATH_AUTH = {
    login: path(ROOTS_ROOT, 'login'),
    registration: path(ROOTS_ROOT, 'registration'),
    sendResetPassword: path(ROOTS_ROOT, 'send-reset-password'),
    resetPassword: path(ROOTS_ROOT, 'recovery-password'),
}

export const PATH_TUTORIAL = {
    root: TUTORIAL_ROOT,
    appName: path(TUTORIAL_ROOT, '/app-name'),
    design: path(TUTORIAL_ROOT, '/design'),
    features: path(TUTORIAL_ROOT, '/features'),
    settings: path(TUTORIAL_ROOT, '/settings'),
    testing: path(TUTORIAL_ROOT, '/testing'),
    publishing: path(TUTORIAL_ROOT, '/publishing'),
    analytics: path(TUTORIAL_ROOT, '/analytics'),
    myApps: path(TUTORIAL_ROOT, '/my-apps'),
    billing: path(TUTORIAL_ROOT, '/billing'),
    profile: path(TUTORIAL_ROOT, '/profile'),
}

export const PATH_ACCOUNT = {
    general: path(ACCOUNT_ROOT, '/general'),
}

export const PATH_BILLING = {
    root: BILLING_ROOT,
    invoices: path(BILLING_ROOT, '/invoices'),
}

export const PATH_APPLICATIONS = {
    root: APPLICATIONS,
    create: '/applications/create',
}

export const PATH_APP = {
    create: path(APP_ROOT, '/create'),
    edit: path(APP_ROOT, '/edit'),
}

export const PATH_LANGUAGE = {
    root: LANGUAGE_ROOT,
}

export const PATH_WELCOME = {
    root: WELCOME_ROOT,
}

export const PATH_NOTIFICATIONS = {
    root: NOTIFICATIONS_ROOT,
}

export const PATH_BRANDING = {
    root: path(DASHBOARD_ROOT, APP_NAME_ROOT),
}

export const PATH_PLACEMENT = {
    success: path(PATH_PLACEMENT_ROOT, '/success'),
    error: path(PATH_PLACEMENT_ROOT, '/error'),
}

export const PATH_DESIGN = {
    launchScreen: path(DASHBOARD_ROOT, `${DESIGN_ROOT}/welcome-screen`),
    background: path(DASHBOARD_ROOT, `${DESIGN_ROOT}/own-design`),
    icon: path(DASHBOARD_ROOT, `${DESIGN_ROOT}/icon`),
    colorPalette: path(DASHBOARD_ROOT, `${DESIGN_ROOT}/general-design`),
}

export const PATH_FEATURES = {
    root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}`),
    blog: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/blog`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/blog/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/blog/edit`),
    },
    news: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/news`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/news/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/news/edit`),
    },
    services: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/services`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/services/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/services/edit`),
    },
    employees: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/employees`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/employee/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/employee/edit`),
    },
    booking: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/booking`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/booking/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/booking/edit`),
        management: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/booking/management`),
        managementItem: path(
            DASHBOARD_ROOT,
            `${FEATURES_ROOT}/booking/managementItem`,
        ),
    },
    video: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/video`),
    },
    paymentLink: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payment-links`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payment-links/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payment-links/edit`),
    },
    videoConference: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/conference`),
    },
    calendar: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/calendar`),
        createEvent: path(
            DASHBOARD_ROOT,
            `${FEATURES_ROOT}/calendar/event/create`,
        ),
        editEvent: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/calendar/event/edit`),
        proposed: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/calendar/proposed`),
    },
    chat: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/chat-links`),
        premium: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/chat-premium`),
    },
    cloud: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/cloud`),
    },
    gallery: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/gallery`),
        addPicture: path(
            DASHBOARD_ROOT,
            `${FEATURES_ROOT}/gallery/add-picture`,
        ),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/gallery/edit`),
    },
    information: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/about`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/about/edit`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/about/create`),
        createMultiple: path(
            DASHBOARD_ROOT,
            `${FEATURES_ROOT}/about/create-multiple`,
        ),
    },
    map: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/map`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/map/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/map/edit`),
    },
    quotes: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/quotes`),
    },
    smm: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/smm`),
    },

    schedule: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/schedule`),
    },

    eReader: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/e-reader`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/e-reader/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/e-reader/edit`),
    },
    membersCard: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/membersCard`),
        users: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/membersCard/users`),
    },
    premium: {
        todo: {
            root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/premium/todo`),
        },
        chat: {
            root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/chat-premium`),
        },
        todoArchive: {
            root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/premium/todoArchive`),
        },
        forum: {
            themes: {
                root: path(
                    DASHBOARD_ROOT,
                    `${FEATURES_ROOT}/premium/forum/themes`,
                ),
                edit: path(
                    DASHBOARD_ROOT,
                    `${FEATURES_ROOT}/premium/forum/themes/edit`,
                ),
                create: path(
                    DASHBOARD_ROOT,
                    `${FEATURES_ROOT}/premium/forum/themes/create`,
                ),
            },
        },
    },
    test: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/survey`),
        analytics: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/survey/summary`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/survey/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/survey/edit`),
    },
    profile: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/profile`),
    },
    payments: {
        root: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payments`),
        create: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payments/create`),
        edit: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payments/edit`),
        payment: path(DASHBOARD_ROOT, `${FEATURES_ROOT}/payments/payment`),
    },
}

export const PATH_SETTINGS = {
    appSettings: {
        general: path(DASHBOARD_ROOT, `${APP_SETTINGS}/general-settings`),
    },
    userRoles: {
        root: path(DASHBOARD_ROOT, `${APP_SETTINGS}/user-roles`),
    },
    teamUserGroup: {
        root: path(DASHBOARD_ROOT, `${APP_SETTINGS}/team-user-group`),
    },
    pushNotiications: {
        root: path(DASHBOARD_ROOT, `${APP_SETTINGS}/push-notifications`),
    },
    featuresActivation: {
        root: path(DASHBOARD_ROOT, `${APP_SETTINGS}/feature-activation`),
    },
}

export const PATH_BUILD = {
    android: path(DASHBOARD_ROOT, `${BUILD_ROOT}/android`),
    ios: path(DASHBOARD_ROOT, `${BUILD_ROOT}/ios`),
}

export const PATH_ANALYTICS = {
    root: path(DASHBOARD_ROOT, ANALYTICS_ROOT),
    setup: path(DASHBOARD_ROOT, `${ANALYTICS_ROOT}/setup`),
}

export const PATH_NEW_LOADER = {
    root: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/blog`),
    welcome: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/welcome`),
    pages: {
        gallery: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/gallery`),
        todo: {
            root: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/todo`),
            archive: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/archive`),
        },
        chat: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/chat`),
        cloud: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/cloud`),
        services: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/services`),
        booking: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/booking`),
        bookingManagment: path(
            DASHBOARD_ROOT,
            `${NEW_LOADER_ROOT}/booking/item`,
        ),
        bookingService: path(
            DASHBOARD_ROOT,
            `${NEW_LOADER_ROOT}/booking/service`,
        ),
        videoConference: path(
            DASHBOARD_ROOT,
            `${NEW_LOADER_ROOT}/video-conference`,
        ),
        smm: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/smm`),
        employee: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/employee`),
        blog: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/blog`),
        billing: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/payment-link`),
        news: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/news`),
        calendar: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/calendar`),
        video: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/video`),
        about: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/info`),
        map: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/map`),
        eReader: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/e-reader`),
        chatPremium: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/premium-chat`),
        payments: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/payments`),
        survey: path(DASHBOARD_ROOT, `${NEW_LOADER_ROOT}/survey`),
    },
}
