import { cloudTypes } from './actionTypes'
import {
    FetchCloudPayload,
    EditCloudPayload,
    FetchCloudRequest,
    EditCloudRequest,
    SetCloud,
    ICloud,
    CreateCloudPayload,
    CreateCloudRequest,
    DeleteCloudPayload,
    DeleteCloudRequest,
    SetTab,
    FetchCloudByIdPayload,
    FetchCloudByIdRequest,
} from './types'

export const fetchCloudAction = (
    payload: FetchCloudPayload,
): FetchCloudRequest => ({
    type: cloudTypes.FETCH_CLOUD,
    payload,
})

export const fetchCloudById = (
    payload: FetchCloudByIdPayload,
): FetchCloudByIdRequest => ({
    type: cloudTypes.FETCH_CLOUD_BY_ID,
    payload,
})

export const EditCloudAction = (
    payload: EditCloudPayload,
): EditCloudRequest => ({
    type: cloudTypes.EDIT_CLOUD,
    payload,
})

export const createCloudAction = (
    payload: CreateCloudPayload,
): CreateCloudRequest => ({
    type: cloudTypes.CREATE_CLOUD,
    payload,
})

export const DeleteCloudAction = (
    payload: DeleteCloudPayload,
): DeleteCloudRequest => ({
    type: cloudTypes.DELETE_CLOUD,
    payload,
})

export const SetCloudAction = (payload: {
    cloudDetails?: ICloud[]
}): SetCloud => ({
    type: cloudTypes.SET_CLOUD,
    payload,
})

export const SetActiveTab = (payload: { activeTab: string }): SetTab => ({
    type: cloudTypes.SET_TAB,
    payload,
})
