import { lazy } from 'react'

import { PATH_ACCOUNT } from 'config/paths'

export const AccountRoutes = [
    {
        id: 1,
        module: 'account',
        protected: true,
        exact: true,
        path: PATH_ACCOUNT.general,
        component: lazy(() => import('containers/account/Account')),
    },
]
