export enum billingTypes {
    FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST',
    FETCH_SUBSCRIPTION_TYPES = 'FETCH_SUBSCRIPTION_TYPES',
    FETCH_POINTS_TYPES = 'FETCH_POINTS_TYPES',
    FETCH_INVOICES = 'FETCH_INVOICES',
    FETCH_PLACING_LINK = 'FETCH_PLACING_LINK',
    SET_INVOICES = 'SET_INVOICES',
    SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
    SET_POINT_PACKS = 'SET_POINT_PACKS',
    SET_CUSTOMER = 'SET_CUSTOMER',
    CREATE_LINK = 'CREATE_LINK',
    FETCH_PLACING_TYPES = 'FETCH_PLACING_TYPES',
    SET_PLACING_TYPES = 'SET_PLACING_TYPES',
    GET_PAYMENT_LINK = 'GET_PAYMENT_LINK',
    GET_POINTS_LINK = 'GET_POINTS_LINK',
    GET_PLACEMENT_LINK = 'GET_PLACEMENT_LINK',
    GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS',
}
