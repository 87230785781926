import React, { ReactNode } from 'react'

import './Overlay.scss'

interface IOverlay {
    children: ReactNode
}

const Overlay = ({ children }: IOverlay): React.ReactElement => (
    <div className='overlay'>{children}</div>
)

export default Overlay
