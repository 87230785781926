import React from 'react'

export const YoutubeIcon = (): React.ReactElement => (
    <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            // eslint-disable-next-line max-len
            d='M20.3665 6.36917C20.2827 6.03407 20.0456 5.78124 19.7741 5.70324C19.2918 5.5657 16.95 5.25 12 5.25C7.05 5.25 4.70869 5.56582 4.22439 5.70363C3.95546 5.78013 3.71845 6.03292 3.63353 6.36917C3.51385 6.84663 3.2 8.84544 3.2 12C3.2 15.1545 3.51385 17.1534 3.63386 17.6322C3.71731 17.966 3.95436 18.2188 4.22468 18.2964C4.70869 18.4342 7.05 18.75 12 18.75C16.95 18.75 19.2918 18.4343 19.7756 18.2964C20.0445 18.2199 20.2816 17.9671 20.3665 17.6308C20.4862 17.1534 20.8 15.15 20.8 12C20.8 8.85 20.4862 6.84663 20.3665 6.36917ZM22.4975 5.81045C23 7.815 23 12 23 12C23 12 23 16.185 22.4975 18.1895C22.2176 19.2982 21.4006 20.1695 20.3651 20.4641C18.4857 21 12 21 12 21C12 21 5.51812 21 3.63493 20.4641C2.59553 20.1655 1.77859 19.2941 1.50244 18.1895C1 16.185 1 12 1 12C1 12 1 7.815 1.50244 5.81045C1.78242 4.70182 2.59937 3.83045 3.63493 3.5359C5.51812 3 12 3 12 3C12 3 18.4857 3 20.3651 3.5359C21.4045 3.83455 22.2214 4.7059 22.4975 5.81045ZM9.80001 15.9375V8.0625L16.4 12L9.80001 15.9375Z'
            fill='white'
        />
    </svg>
)

export default YoutubeIcon
