import React from 'react'

const MenuIcon = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='new-loader--text'
    >
        <path
            d='M10.9849 6.51758C11.9062 6.51758 12.645 5.77051 12.645 4.83252C12.645 3.91943 11.9062 3.17236 10.9849 3.17236C10.0635 3.17236 9.32471 3.91943 9.32471 4.83252C9.32471 5.77051 10.0635 6.51758 10.9849 6.51758ZM10.9849 12.6602C11.9062 12.6602 12.645 11.9214 12.645 11C12.645 10.0869 11.9062 9.34814 10.9849 9.34814C10.0635 9.34814 9.32471 10.0869 9.32471 11C9.32471 11.9214 10.0635 12.6602 10.9849 12.6602ZM10.9849 18.8276C11.9062 18.8276 12.645 18.0889 12.645 17.1675C12.645 16.2295 11.9062 15.4907 10.9849 15.4907C10.0635 15.4907 9.32471 16.2295 9.32471 17.1675C9.32471 18.0889 10.0635 18.8276 10.9849 18.8276Z'
            fill='currentColor'
        />
    </svg>
)

export default MenuIcon
