import React, { SyntheticEvent } from 'react'

import AltPhoto from 'assets/images/shared/AltPhoto.svg'

interface ICustomImage {
    src?: string
    srcSet?: string
    alt?: string
    className?: string
    id?: string
}

export const CustomImage = ({
    src,
    srcSet,
    alt,
    id,
    className,
    ...props
}: ICustomImage): React.ReactElement => (
    <img
        {...props}
        id={id}
        src={src}
        srcSet={srcSet}
        className={className}
        alt={alt}
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.onerror = null
            e.currentTarget.src = AltPhoto
        }}
        draggable={false}
        loading='lazy'
    />
)

CustomImage.defaultProps = {
    alt: 'img',
    src: '',
}

export default CustomImage
