export enum pushNotificationsTypes {
    FETCH_PUSHES_REQUEST = 'FETCH_PUSHES_REQUEST',
    FETCH_HISTORY_REQUEST = 'FETCH_HISTORY_REQUEST',
    FETCH_PUSHE_BY_ID_REQUEST = 'FETCH_PUSHE_BY_ID_REQUEST',
    SEND_PUSHES_REQUEST = 'SEND_PUSHES_REQUEST',
    CREATE_PUSH_REQUEST = 'CREATE_PUSH_REQUEST',
    EDIT_PUSH_REQUEST = 'EDIT_PUSH_REQUEST',
    DELETE_PUSH_REQUEST = 'DELETE_PUSH_REQUEST',
    SET_PUSHES = 'SET_PUSHES',
    SET_PUSH_BY_ID = 'SET_PUSH_BY_ID',
}
