import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { designLogoTypes } from './actionTypes'
import { getDesignLogo, editDesignLogo } from '../service/designLogo'

import {
    IDesignLogo,
    FetchDesignLogoRequest,
    EditDesignLogoRequest,
} from './types'

function* fetchDesignLogoSaga(action: FetchDesignLogoRequest) {
    try {
        const response: AxiosResponse<IDesignLogo> = yield call(
            getDesignLogo,
            action.payload,
        )
        if (response.status === 204) {
            yield put({
                type: designLogoTypes.SET_DESIGN_LOGO,
                payload: {
                    designLogo: {
                        backgroundColor: undefined,
                        backgroundPicture: undefined,
                        image: undefined,
                    },
                },
            })
        }
        if (response.data) {
            yield put({
                type: designLogoTypes.SET_DESIGN_LOGO,
                payload: { designLogo: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editDesignLogoSaga(action: EditDesignLogoRequest) {
    try {
        const response: AxiosResponse<IDesignLogo> = yield call(
            editDesignLogo,
            action.payload,
        )
        if (response.status === 200) {
            yield put({
                type: designLogoTypes.SET_DESIGN_LOGO,
                payload: {
                    designLogo: action.payload.designLogo,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* designLogoSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            designLogoTypes.FETCH_DESIGN_LOGO_REQUEST,
            fetchDesignLogoSaga,
        ),
    ])
    yield all([
        takeLatest(
            designLogoTypes.EDIT_DESIGN_LOGO_REQUEST,
            editDesignLogoSaga,
        ),
    ])
}

export default designLogoSaga
