import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const BookingRoutes = [
    {
        id: 1,
        module: 'booking',
        protected: true,
        exact: true,
        path: PATH_FEATURES.booking.root,
        component: lazy(
            () => import('containers/dashboard/features/booking/Booking'),
        ),
    },
    {
        id: 2,
        module: 'booking',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.booking.edit}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/booking/EditBooking'),
        ),
    },
    {
        id: 3,
        module: 'booking',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.booking.create}`,
        component: lazy(
            () => import('containers/dashboard/features/booking/CreateBooking'),
        ),
    },
    {
        id: 4,
        module: 'booking',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.booking.management}/:id`,
        component: lazy(
            () =>
                import(
                    'containers/dashboard/features/booking/BookingManagement'
                ),
        ),
    },
]
