import React from 'react'

export const MyAppsIcon = ({
    isActive,
    isSmall,
}: {
    isActive?: boolean
    isSmall?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : '46'}
        height={isSmall ? '25' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M33.1786 11.5C33.1786 17.169 28.6148 21.75 23 21.75C17.3852 21.75 12.8214 17.169 12.8214 11.5C12.8214 5.83105 17.3852 1.25 23 1.25C28.6148 1.25 33.1786 5.83105 33.1786 11.5ZM23 28.5625C25.411 28.5625 27.7052 28.0425 29.7784 27.125H31C36.9273 27.125 41.75 31.9666 41.75 37.95V41.6875C41.75 43.3854 40.3827 44.75 38.7143 44.75H7.28571C5.61733 44.75 4.25 43.3854 4.25 41.6875V37.95C4.25 31.9666 9.07269 27.125 15 27.125H16.2226C18.3014 28.0418 20.5874 28.5625 23 28.5625Z'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2.5'
        />
    </svg>
)

export default MyAppsIcon
