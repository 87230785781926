import React from 'react'

export const CloudIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M12.5903 15.0603C12.3243 15.4809 11.821 15.5695 11.4601 15.2595C11.0991 14.9496 11.0232 14.363 11.2891 13.9423C11.8685 13.0347 12.5713 12.2267 13.3881 11.5736C14.1764 10.9427 15.0597 10.4556 16.038 10.1457C16.9783 9.84686 17.909 9.73617 18.8303 9.79151C19.7896 9.84686 20.7109 10.0904 21.5752 10.4889C21.9931 10.677 22.1925 11.2305 22.0311 11.7175C21.8696 12.2045 21.3947 12.437 20.9768 12.2488C20.2645 11.9167 19.5142 11.7286 18.7448 11.6732C17.9945 11.6289 17.2252 11.7175 16.4654 11.961C15.6771 12.2156 14.9457 12.6141 14.2999 13.1232C13.635 13.6656 13.0557 14.3187 12.5903 15.0603Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.47826 32.4444C4.4527 32.4444 2 29.9074 2 26.7778C2 24.2458 3.60542 22.1017 5.82077 21.3751C5.70985 20.6745 5.65217 19.9553 5.65217 19.2222C5.65217 11.9198 11.3751 6 18.4348 6C22.8861 6 26.806 8.35351 29.0949 11.9232C29.776 11.7555 30.4867 11.6667 31.2174 11.6667C36.26 11.6667 40.3478 15.8951 40.3478 21.1111C40.3478 21.2184 40.3461 21.3253 40.3427 21.4317C42.4731 22.2081 44 24.3085 44 26.7778C44 29.9074 41.5473 32.4444 38.5217 32.4444H23.9669V36.6137L26.0747 34.2923C26.4157 33.9168 26.9685 33.9168 27.3095 34.2923C27.6505 34.6679 27.6505 35.2767 27.3095 35.6523L23.6173 39.7184C23.2763 40.0939 22.7235 40.0939 22.3825 39.7183L18.6905 35.6522C18.3495 35.2767 18.3495 34.6678 18.6905 34.2923C19.0315 33.9167 19.5844 33.9167 19.9253 34.2923L22.2206 36.8201V32.4444H18.4348H7.47826ZM3.82609 26.7778C3.82609 28.8642 5.46122 30.5556 7.47826 30.5556H22.2206V22.0727C22.2206 21.5416 22.6115 21.1111 23.0937 21.1111C23.576 21.1111 23.9669 21.5416 23.9669 22.0727V30.5556H31.2174H38.5217C40.5388 30.5556 42.1739 28.8642 42.1739 26.7778C42.1739 25.2706 41.3207 23.9696 40.0866 23.3634C39.6123 23.1304 39.0817 23 38.5217 23H38.2916C38.4418 22.3963 38.5217 21.7633 38.5217 21.1111C38.5217 16.9383 35.2515 13.5556 31.2174 13.5556C30.8139 13.5556 30.418 13.5894 30.0322 13.6545C29.4117 13.7592 28.8174 13.9449 28.2598 14.2006C27.9864 13.6292 27.6677 13.0852 27.3087 12.5734C25.3175 9.73484 22.0846 7.88889 18.4348 7.88889C12.3837 7.88889 7.47826 12.963 7.47826 19.2222C7.47826 19.8658 7.53012 20.4968 7.62975 21.1111C7.73518 21.7612 7.89412 22.3925 8.10171 23H7.47826C7.04617 23 6.63161 23.0776 6.24689 23.2201C4.8357 23.7428 3.82609 25.1383 3.82609 26.7778Z'
            fill='currentColor'
        />
    </svg>
)

export default CloudIcon
