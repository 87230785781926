import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'
import { LIQPAY_URLS } from 'config/urls'
import { IPayment, PaymentVariants } from '../store/types'

export const getPayments = ({
    // language,
    appId,
    page,
    paymentSystem,
}: {
    // language: string
    appId: string
    page: number
    paymentSystem: PaymentVariants
}): Promise<AxiosResponse<IPayment[]>> =>
    // axiosInstance.get(
    // eslint-disable-next-line max-len
    // `${appId}${LIQPAY_URLS.main}/${language}?pageNumber=${page}&pageSize=10`,
    // )
    axiosInstance.get(
        `${appId}${LIQPAY_URLS.main}?pageNumber=${page}&pageSize=10&paymentSystem=${paymentSystem}`,
    )

export const getPayment = ({
    // language,
    appId,
    id,
}: {
    // language: string
    appId: string
    id: string
}): Promise<AxiosResponse<IPayment>> =>
    // axiosInstance.get(`${appId}${LIQPAY_URLS.main}/${language}/${id}`)
    axiosInstance.get(`${appId}${LIQPAY_URLS.main}/${id}`)

export const createPayments = ({
    // language,
    appId,
    payment,
}: {
    appId: string
    // language: string
    payment: IPayment
}): Promise<AxiosResponse<IPayment>> =>
    // axiosInstance.post(`${appId}${LIQPAY_URLS.main}/${language}`, payment)
    axiosInstance.post(`${appId}${LIQPAY_URLS.main}`, payment)

// --------------------------------

export const deletePayment = ({ appId, id }: { appId: string; id: string }) =>
    axiosInstance.delete(`${appId}${LIQPAY_URLS.main}/${id}`)

export const editPayment = ({
    appId,
    payment,
}: {
    appId: string
    payment: IPayment
}): Promise<AxiosResponse<IPayment>> =>
    // eslint-disable-next-line max-len
    // axiosInstance.put(`${appId}${LIQPAY_URLS.main}/${language}/${id}`, payment)
    axiosInstance.put(`${appId}${LIQPAY_URLS.main}/${payment.id}`, payment)
