import React, { useState } from 'react'

import { Link, useHistory, useLocation } from 'react-router-dom'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'

import { ReactComponent as EyeSlash } from 'assets/images/icons/EyeSlash.svg'
import { ReactComponent as Eye } from 'assets/images/icons/Eye.svg'
import BackIcon from 'assets/images/button-icons/BackIcon.svg'
import AuthModuleLogo from 'assets/images/AuthModuleLogo.svg'

import Language from 'layouts/header-navigation/components/language/Language'
import CustomInput from 'components/custom-input/CustomInput'
import CustomButton from 'components/custom-button/CustomButton'

import { useUserAuthorized } from 'hooks/useUserAuthorized'
import { PATH_APPLICATIONS, PATH_AUTH } from 'config/paths'
import { recoverySchema } from 'schemas/auth'
import { TError } from 'config/types'

import { resetPassword } from '../services/auth'
import '../style.scss'

const ResetPassword = (): React.ReactElement => {
    const { t } = useTranslation(['auth', 'toast'])

    const { push } = useHistory()
    const { pathname } = useLocation()
    const pathEmail = pathname.split('/')[2]
    const pathToken = pathname.split('/')[3]
    const [passwordVisibility, setPasswordVisibility] = useState(false)

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: recoverySchema,
        initialValues: {
            newPassword: '',
            email: pathEmail,
            validationToken: pathToken,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            const formData = new URLSearchParams()
            formData.append('newPassword', values.newPassword.trim())
            try {
                const response = await resetPassword(
                    values.email,
                    values.validationToken,
                    formData,
                )
                if (response) {
                    toast.success(t('toast:pwdChanged'))
                    setSubmitting(false)
                    push(PATH_AUTH.login)
                }
            } catch (err) {
                if (Array.isArray(err)) {
                    err.forEach((error: TError) => {
                        console.error(error.description)
                        toast.error(t('toast:backendError'))
                    })
                }
            }
        },
    })

    const { handleSubmit, getFieldProps, isSubmitting, touched, errors } =
        formik

    if (useUserAuthorized()) {
        push(PATH_APPLICATIONS.root)
    }

    return (
        <div className='page-form'>
            <a
                className='back-btn'
                href={process.env.REACT_APP_PROMO_REDIRECT_URL}
            >
                <img src={BackIcon} alt='BackIcon' />
                <span>{t('auth:backToPromo')}</span>
            </a>
            <img
                className='module-logo'
                src={AuthModuleLogo}
                alt='ModuleLogo'
            />
            <div className='page-form__language'>
                <Language design='white' />
            </div>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                    <div className='header'>
                        <div className='title'>{t('auth:changePwd')}</div>
                        <p>{t('auth:changePwdInfo')}</p>
                    </div>
                    <div className='page-form--heading'>{t('auth:newPwd')}</div>

                    <Stack spacing='20px' sx={{ mb: '25px' }} marginTop='20px'>
                        <div className='password'>
                            <button
                                className='password__btn'
                                type='button'
                                onClick={() =>
                                    setPasswordVisibility(!passwordVisibility)
                                }
                            >
                                {passwordVisibility === true ? (
                                    <Eye />
                                ) : (
                                    <EyeSlash />
                                )}
                            </button>

                            <CustomInput
                                type={passwordVisibility ? 'text' : 'password'}
                                {...getFieldProps('newPassword')}
                                label={t('auth:newPwd')}
                                placeholder={t('auth:newPwd')}
                                touched={touched?.newPassword}
                                error={errors?.newPassword}
                            />
                        </div>
                    </Stack>
                    <div className='auth-form-button'>
                        <CustomButton
                            type='submit'
                            size='large'
                            design='primary'
                            loading={isSubmitting}
                        >
                            {t('auth:confirm')}
                        </CustomButton>
                    </div>
                    <div className='auth-form-bottom--account'>
                        <p className='auth-form-bottom--account-text'>
                            {t('auth:haveAcc')}
                        </p>
                        <Link
                            to='/login'
                            className='
                auth-form-bottom--link-base
                auth-form-bottom--account-link 
              '
                        >
                            {t('auth:login')}
                        </Link>
                    </div>
                </Form>
            </FormikProvider>
        </div>
    )
}

export default ResetPassword as React.FC
