import React from 'react'

export const Firebase = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_7788_2113)'>
            <path
                d='M39.8125 24.7188V23.2813C40.2092 23.2813 40.5313 22.9595 40.5313 22.5625V18.25C40.5313 17.853 40.2092 17.5313 39.8125 17.5313H35.9313L35.792 16.9913C35.4742 15.7566 34.9806 14.5737 34.3256 13.4796L34.0381 12.9986L36.7938 10.2382C37.0711 9.96023 37.0711 9.50989 36.7938 9.23193L33.7413 6.175C33.4594 5.90631 33.0167 5.90631 32.7348 6.175L29.9741 8.93573L29.4934 8.64823C28.3976 7.99293 27.2136 7.49935 25.9771 7.18125L25.4375 7.04087V3.15625C25.4375 2.75925 25.1157 2.4375 24.7188 2.4375H20.4063C20.0093 2.4375 19.6875 2.75925 19.6875 3.15625V7.0375L19.1476 7.17704C17.9128 7.49458 16.73 7.98816 15.6353 8.64233L15.1543 8.93012L12.3944 6.175C12.1123 5.90407 11.6667 5.90407 11.3845 6.175L8.33125 9.22744C8.19705 9.36137 8.12152 9.5433 8.12152 9.73281C8.12152 9.92261 8.19705 10.1043 8.33125 10.2382L11.092 12.9986L10.8045 13.4796C10.1497 14.5737 9.65588 15.7566 9.33834 16.9913L9.1974 17.5313H5.3125C4.9155 17.5313 4.59375 17.853 4.59375 18.25V22.5625C4.59375 22.9595 4.9155 23.2813 5.3125 23.2813V24.7188C4.12179 24.7188 3.15625 23.7532 3.15625 22.5625V18.25C3.15625 17.0593 4.12179 16.0938 5.3125 16.0938H8.09345C8.38993 15.0999 8.79086 14.1402 9.28949 13.2311L7.31293 11.2545C6.90863 10.8511 6.68149 10.3036 6.68149 9.73281C6.68149 9.16202 6.90863 8.61454 7.31293 8.21137L10.3676 5.15668C11.2186 4.34219 12.5604 4.34219 13.4114 5.15668L15.3874 7.1324C16.2965 6.63405 17.2564 6.23396 18.25 5.93776V3.15625C18.25 1.96554 19.2155 1 20.4063 1H24.7188C25.9095 1 26.875 1.96554 26.875 3.15625V5.93776C27.8689 6.23396 28.8283 6.63489 29.7376 7.13324L31.7142 5.15668C32.5655 4.34219 33.907 4.34219 34.7582 5.15668L37.8124 8.21052C38.2164 8.61398 38.4432 9.1609 38.4432 9.73197C38.4432 10.303 38.2164 10.8505 37.8124 11.2537L35.8358 13.2303C36.3341 14.1399 36.7348 15.0999 37.0316 16.0938H39.8125C41.0032 16.0938 41.9688 17.0593 41.9688 18.25V22.5625C41.9688 23.7532 41.0032 24.7188 39.8125 24.7188Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M32.625 20.4063H31.1875C31.1875 15.6428 27.3259 11.7813 22.5625 11.7813C17.7991 11.7813 13.9375 15.6428 13.9375 20.4063H12.5C12.5 14.8489 17.0051 10.3438 22.5625 10.3438C28.1196 10.3438 32.625 14.8489 32.625 20.4063Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M22.5625 26.1563C20.1808 26.1563 18.25 24.2252 18.25 21.8438C18.25 19.462 20.1808 17.5312 22.5625 17.5312C24.9445 17.5312 26.875 19.462 26.875 21.8438C26.8725 24.2246 24.9434 26.1537 22.5625 26.1563ZM22.5625 18.9688C20.9748 18.9688 19.6875 20.256 19.6875 21.8438C19.6875 23.4315 20.9748 24.7188 22.5625 24.7188C24.1502 24.7188 25.4375 23.4315 25.4375 21.8438C25.4375 20.256 24.1502 18.9688 22.5625 18.9688Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M34.7813 29.0313C32.3995 29.0313 30.4688 27.1002 30.4688 24.7188C30.4688 22.337 32.3995 20.4062 34.7813 20.4062C37.163 20.4062 39.0938 22.337 39.0938 24.7188C39.0912 27.0996 37.1621 29.0287 34.7813 29.0313ZM34.7813 21.8438C33.1935 21.8438 31.9063 23.131 31.9063 24.7188C31.9063 26.3065 33.1935 27.5938 34.7813 27.5938C36.369 27.5938 37.6563 26.3065 37.6563 24.7188C37.6563 23.131 36.369 21.8438 34.7813 21.8438Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M10.3438 29.0313C7.96205 29.0313 6.03125 27.1002 6.03125 24.7188C6.03125 22.337 7.96205 20.4062 10.3438 20.4062C12.7257 20.4062 14.6563 22.337 14.6563 24.7188C14.6537 27.0996 12.7246 29.0287 10.3438 29.0313ZM10.3438 21.8438C8.75604 21.8438 7.46875 23.131 7.46875 24.7188C7.46875 26.3065 8.75604 27.5938 10.3438 27.5938C11.9315 27.5938 13.2188 26.3065 13.2188 24.7188C13.2188 23.131 11.9315 21.8438 10.3438 21.8438Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M41.2972 30.8753C40.2261 30.1391 38.9562 29.7464 37.6563 29.75H31.9063C31.5567 29.7531 31.2077 29.7848 30.8632 29.8446C30.4033 29.1253 29.805 28.5048 29.1031 28.0183C28.9978 27.9388 28.8872 27.8667 28.7718 27.8029C27.7664 27.194 26.6128 26.8733 25.4375 26.875H19.6875C17.4894 26.8671 15.4413 27.9888 14.2637 29.8446C13.9184 29.7848 13.5689 29.7531 13.2188 29.75H7.46875C6.16995 29.7464 4.90034 30.1386 3.8298 30.8739C2.06044 32.0778 1.00112 34.0788 1 36.2188V39.0938C1.00253 41.0563 2.14438 42.8386 3.92694 43.6606C4.58505 43.9706 5.30408 44.1292 6.03125 44.125H13.2188V42.6875H6.75V34.7813H5.3125V42.6154C5.04185 42.5612 4.77905 42.4736 4.52974 42.3554C3.25648 41.7683 2.44031 40.4956 2.4375 39.0938V36.2188C2.43918 34.5533 3.26434 32.9965 4.6412 32.0598C5.4731 31.4888 6.45941 31.1847 7.46875 31.1875H13.2188C13.3454 31.1875 13.4675 31.201 13.591 31.2094C13.3479 31.895 13.2218 32.6166 13.2188 33.3438V40.5313C13.2213 42.5151 14.8289 44.1225 16.8125 44.125H18.9688V32.625H17.5313V42.6875H16.8125C15.6218 42.6875 14.6563 41.722 14.6563 40.5313V33.3438C14.6563 30.5648 16.9091 28.3125 19.6875 28.3125H25.4375C26.3623 28.3131 27.2686 28.5691 28.0573 29.052C28.1303 29.0919 28.2005 29.1368 28.267 29.1862C28.9406 29.6475 29.4934 30.2638 29.8786 30.9831C30.2658 31.7097 30.4688 32.5203 30.4688 33.3438V40.5313C30.4688 41.722 29.5032 42.6875 28.3125 42.6875H27.5938V32.625H26.1563V44.125H28.3125C30.2964 44.1225 31.9037 42.5151 31.9063 40.5313V33.3438C31.9029 32.6166 31.7774 31.8953 31.5345 31.2094C31.6583 31.201 31.7805 31.1875 31.9063 31.1875H37.6563C38.6662 31.1847 39.6533 31.4896 40.4858 32.0612C41.8615 32.9984 42.6855 34.5541 42.6875 36.2188V39.0938C42.685 40.5007 41.8626 41.777 40.5832 42.361C40.3375 42.4772 40.0787 42.5626 39.8125 42.6154V34.7813H38.375V42.6875H31.9063V44.125H39.0938C39.8167 44.1295 40.5315 43.9728 41.186 43.6662C42.9744 42.8467 44.1222 41.0611 44.125 39.0938V36.2188C44.1239 34.0796 43.0654 32.0795 41.2972 30.8753Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
            <path
                d='M19.6875 42.6875H25.4375V44.125H19.6875V42.6875Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='0.6'
            />
        </g>
        <defs>
            <clipPath id='clip0_7788_2113'>
                <rect width='46' height='46' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default Firebase
