import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { BUILD_URLS, FIREBASE_URLS } from 'config/urls'

import { IBuild, IAppleSettings } from '../store/types'

export interface IFile {
    url?: string
    size?: number
}

export const getBuildDetails = ({
    appId,
    type,
}: {
    appId: string
    type: 'Apple' | 'AndroidApk' | 'AndroidAab'
}): Promise<AxiosResponse<IBuild>> =>
    axiosInstance.get(`${appId}${BUILD_URLS.main}/${type}`)

export const getAppleSettings = ({
    appId,
    type,
}: {
    appId: string
    type: 'Apple'
}): Promise<AxiosResponse<IAppleSettings>> =>
    axiosInstance.get(`${appId}${BUILD_URLS.main}/${type}/build/settings`)

export const putAppleSettings = ({
    appId,
    type,
    settings,
}: {
    appId: string
    type: 'Apple'
    settings?: IAppleSettings
}): Promise<AxiosResponse<IAppleSettings>> =>
    axiosInstance.put(
        `${appId}${BUILD_URLS.main}/${type}/build/settings`,
        settings,
    )

export const createBuild = ({
    appId,
    type,
    filePassword,
}: {
    appId: string
    type: 'Apple' | 'AndroidApk' | 'AndroidAab'
    filePassword?: string
}): Promise<AxiosResponse<IBuild>> =>
    axiosInstance.post(
        `${appId}${BUILD_URLS.main}/${type}${
            filePassword ? `?filepassword=${filePassword}` : ''
        }`,
    )

export const getBuildLink = ({
    appId,
    type,
}: {
    appId: string
    type: 'Apple' | 'AndroidApk' | 'AndroidAab'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> =>
    axiosInstance.get(`${appId}${BUILD_URLS.main}/${type}/download`)

export const getFileUrl = ({
    appId,
    file,
    fileType,
}: {
    appId: string
    file: FormData
    fileType: string
}): Promise<AxiosResponse<IFile>> =>
    axiosInstance.post(
        `${appId}${FIREBASE_URLS.main}/file/upload?fileType=${fileType}`,
        file,
    )
