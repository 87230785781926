import { employeeTypes } from './actionTypes'
import {
    IEmployee,
    FetchEmployeesRequest,
    FetchEmployeesPayload,
    FetchEmployeeByIdRequest,
    FetchEmployeePayload,
    CreateEmployeesRequest,
    CreateEmployeesPayload,
    EditEmployeeRequest,
    EditEmployeePayload,
    DeleteEmployeeRequest,
    DeleteEmployeePayload,
    SetEmployees,
    SetEmployee,
} from './types'

export const fetchEmployeesAction = (
    payload: FetchEmployeesPayload,
): FetchEmployeesRequest => ({
    type: employeeTypes.FETCH_EMPLOYEES_REQUEST,
    payload,
})

export const fetchEmployeeAction = (
    payload: FetchEmployeePayload,
): FetchEmployeeByIdRequest => ({
    type: employeeTypes.FETCH_EMPLOYEE_BY_ID,
    payload,
})

export const createEmployeesAction = (
    payload: CreateEmployeesPayload,
): CreateEmployeesRequest => ({
    type: employeeTypes.CREATE_EMPLOYEE_REQUEST,
    payload,
})

export const editEmployeeAction = (
    payload: EditEmployeePayload,
): EditEmployeeRequest => ({
    type: employeeTypes.EDIT_EMPLOYEE_REQUEST,
    payload,
})

export const deleteEmployeeAction = (
    payload: DeleteEmployeePayload,
): DeleteEmployeeRequest => ({
    type: employeeTypes.DELETE_EMPLOYEE_REQUEST,
    payload,
})

export const SetEmployeesAction = (payload: {
    employees: IEmployee[] | undefined
}): SetEmployees => ({
    type: employeeTypes.SET_EMPLOYEES,
    payload,
})

export const SetEmployeeAction = (payload: {
    employee?: IEmployee
}): SetEmployee => ({
    type: employeeTypes.SET_EMPLOYEE,
    payload,
})
