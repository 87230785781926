/* eslint-disable max-len */
import React from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'

// eslint-disable-next-line max-len
import NewLoaderBackground from 'containers/emulator/components/content/NewLoaderBackground'
// eslint-disable-next-line max-len
import NewLoaderContent from 'containers/emulator/components/content/NewLoaderContent'
import CustomButton from 'components/custom-button/CustomButton'

import ProfileIcon from 'assets/images/new-loader/ProfileIcon'
import PhoneSmall from 'assets/images/new-loader/PhoneSmall'
import IconPage from 'containers/emulator/pages/icon/IconPage'

import { PATH_NEW_LOADER, PATH_APPLICATIONS } from 'config/paths'
import { getAppIdCookie } from 'utils/token'

import { ComponentRender } from './ComponentRender'
import { data } from '../data'

const RightEmulator = (): React.ReactElement => {
    const { t } = useTranslation(['nav', 'emulator', 'toast'])

    const { push } = useHistory()
    const appId = getAppIdCookie()
    const cookies = useCookies(['emulator_palette', 'active_features'])[0]
    const viewItem = data.find(
        // TODO
        // eslint-disable-next-line react-hooks/rules-of-hooks
        dataItem => useRouteMatch(dataItem.path)?.isExact,
    )

    const goToEmulator = () => {
        if (!appId) {
            push(PATH_APPLICATIONS.root)
            toast.error(t('toast:chooseApp'))
            return
        }
        if (!cookies.active_features || cookies.active_features.lenght === 0)
            return
        push(PATH_NEW_LOADER.welcome)
    }

    if (viewItem && viewItem.component === 'icon') {
        return <IconPage />
    }

    return (
        <>
            <>
                {!viewItem ? (
                    <></>
                ) : (
                    <div className='new-loader__wrapper-small'>
                        <PhoneSmall />
                        <NewLoaderBackground palette={cookies.emulator_palette}>
                            <NewLoaderContent
                                palette={cookies.emulator_palette}
                            >
                                {viewItem.component !== 'welcome' &&
                                    viewItem.component !== 'icon' && (
                                        <div className='right-emulator'>
                                            <div className='right-emulator__screen new-loader--navbar new-loader--text'>
                                                {/* Header maybe common? */}
                                                <div className='right-emulator__header'>
                                                    <MenuIcon />
                                                    <div className='right-emulator__title'>
                                                        {t(viewItem.name)}
                                                    </div>
                                                    <ProfileIcon />
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                <div className='right-emulator__page'>
                                    <ComponentRender
                                        component={viewItem.component}
                                    />
                                </div>
                            </NewLoaderContent>
                        </NewLoaderBackground>
                    </div>
                )}
            </>
            <CustomButton
                type='button'
                design='primary'
                handleClick={() => goToEmulator()}
                size='large'
            >
                {t('nav:preview')}
            </CustomButton>
        </>
    )
}

export default RightEmulator
