import { lazy } from 'react'

import { PATH_SETTINGS } from 'config/paths'

export const SettingsRoutes = [
    {
        id: 1,
        module: 'settings',
        protected: true,
        exact: true,
        path: PATH_SETTINGS.appSettings.general,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/settings/app-settings/GeneralSettings'
                ),
        ),
    },
    {
        id: 2,
        module: 'settings',
        protected: true,
        exact: true,
        path: PATH_SETTINGS.featuresActivation.root,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/settings/features-activation/FeaturesActivation'
                ),
        ),
    },
    {
        id: 3,
        module: 'settings',
        protected: true,
        exact: true,
        path: PATH_SETTINGS.userRoles.root,
        component: lazy(
            // eslint-disable-next-line max-len
            () => import('containers/dashboard/settings/user-roles/UserRoles'),
        ),
    },
]
