import React from 'react'

export const General = ({
    // isActive,
    isSmall,
    isMedium,
}: {
    // isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '29' : '36'}
        height={isSmall ? '24' : isMedium ? '28' : '35'}
        viewBox='0 0 28 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1 41.3335C1.00115 42.3056 1.38197 43.2375 2.05894 43.9249C2.7359 44.6123 3.65374 44.999 4.61111 45.0002H23.3889C24.3463 44.999 25.2641 44.6123 25.9411 43.9249C26.618 43.2375 26.9989 42.3056 27 41.3335V25.7222C27 25.3234 26.6767 25 26.2778 25C25.8789 25 25.5556 25.3234 25.5556 25.7222V30.3334H10.7399H2.44444V25.7222C2.44444 25.3233 2.12109 25 1.72222 25C1.32335 25 1 25.3233 1 25.7222V41.3335ZM4.61111 43.5335H23.3889C23.9635 43.5335 24.5146 43.3017 24.921 42.8891C25.3273 42.4765 25.5556 41.917 25.5556 41.3335V31.8001H9.81111H2.44444V41.3335C2.44444 41.917 2.67272 42.4765 3.07905 42.8891C3.48538 43.3017 4.03648 43.5335 4.61111 43.5335Z'
            fill='#2B2A29'
        />
        <path
            d='M2.44444 30.3334H10.7399H25.5556M2.44444 30.3334V25.7222C2.44444 25.3233 2.12109 25 1.72222 25V25C1.32335 25 1 25.3233 1 25.7222V41.3335C1.00115 42.3056 1.38197 43.2375 2.05894 43.9249C2.7359 44.6123 3.65374 44.999 4.61111 45.0002H23.3889C24.3463 44.999 25.2641 44.6123 25.9411 43.9249C26.618 43.2375 26.9989 42.3056 27 41.3335V25.7222C27 25.3234 26.6767 25 26.2778 25V25C25.8789 25 25.5556 25.3234 25.5556 25.7222V30.3334M2.44444 30.3334V31.8001M25.5556 31.8001V41.3335C25.5556 41.917 25.3273 42.4765 24.921 42.8891C24.5146 43.3017 23.9635 43.5335 23.3889 43.5335H4.61111C4.03648 43.5335 3.48538 43.3017 3.07905 42.8891C2.67272 42.4765 2.44444 41.917 2.44444 41.3335V31.8001M25.5556 31.8001H9.81111H2.44444M25.5556 31.8001V30.3334'
            stroke='#2B2A29'
            strokeWidth='0.6'
        />
        <path
            d='M19.0555 40.6001H8.94439C8.75284 40.6001 8.56915 40.6774 8.4337 40.8149C8.29826 40.9524 8.22217 41.1389 8.22217 41.3334C8.22217 41.5279 8.29826 41.7145 8.4337 41.852C8.56915 41.9895 8.75284 42.0668 8.94439 42.0668H19.0555C19.247 42.0668 19.4307 41.9895 19.5662 41.852C19.7016 41.7145 19.7777 41.5279 19.7777 41.3334C19.7777 41.1389 19.7016 40.9524 19.5662 40.8149C19.4307 40.6774 19.247 40.6001 19.0555 40.6001Z'
            fill='#2B2A29'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.3889 1H4.61111C3.65374 1.00116 2.7359 1.38785 2.05894 2.07523C1.38197 2.76262 1.00115 3.69457 1 4.66668V29.7778C1 30.1767 1.32335 30.5 1.72222 30.5C2.12109 30.5 2.44444 30.1767 2.44444 29.7778V4.66668C2.44444 4.0832 2.67272 3.52362 3.07905 3.11104C3.48538 2.69846 4.03648 2.46667 4.61111 2.46667H7.48889C7.8939 2.46667 8.22222 2.795 8.22222 3.20001C8.22222 3.78349 8.4505 4.34307 8.85682 4.75565C9.26315 5.16823 9.81425 5.40002 10.3889 5.40002H17.6111C18.1857 5.40002 18.7368 5.16823 19.1432 4.75565C19.5495 4.34307 19.7778 3.78349 19.7778 3.20001C19.7778 2.795 20.1061 2.46667 20.5111 2.46667H23.3889C23.9635 2.46667 24.5146 2.69846 24.921 3.11104C25.3273 3.52362 25.5556 4.0832 25.5556 4.66668V29.7778C25.5556 30.1767 25.8789 30.5 26.2778 30.5C26.6766 30.5 27 30.1767 27 29.7778V4.66668C26.9989 3.69457 26.618 2.76262 25.9411 2.07523C25.2641 1.38785 24.3463 1.00116 23.3889 1ZM18.3333 3.20001C18.3333 2.795 18.005 2.46667 17.6 2.46667H10.4C9.99499 2.46667 9.66667 2.795 9.66667 3.20001C9.66667 3.3945 9.74276 3.58103 9.8782 3.71855C10.0136 3.85608 10.1973 3.93334 10.3889 3.93334H17.6111C17.8027 3.93334 17.9864 3.85608 18.1218 3.71855C18.2572 3.58103 18.3333 3.3945 18.3333 3.20001Z'
            fill='#2B2A29'
            stroke='#2B2A29'
            strokeWidth='0.6'
        />
    </svg>
)
