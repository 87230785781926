import { launchScreenTypes } from './actionTypes'
import { LaunchScreenActions, LaunchScreenState } from './types'

const initialState: LaunchScreenState = {
    settings: {},
}

export default (
    state = initialState,
    action: LaunchScreenActions,
): LaunchScreenState => {
    switch (action.type) {
        case launchScreenTypes.SET_LAUNCH_SCREEN:
            return {
                ...state,
                settings: action.payload.settings,
            }
        default:
            return {
                ...state,
            }
    }
}
