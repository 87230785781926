import React from 'react'

import { RootState } from 'redux/reducers/rootReducer'
import { useSelector } from 'react-redux'

import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'
import Preloader from 'components/preloader/Preloader'
import CloudView from './components/cloud-view/CloudView'

export const CloudTab = (): React.ReactElement => {
    const { cloudDetails } = useSelector((state: RootState) => state.cloud)

    return cloudDetails ? (
        cloudDetails.length > 0 ? (
            <CloudView data={cloudDetails} />
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}

export default CloudTab
