import { forumUserTypes } from './actionTypes'
import { UserState, UserActions } from './types'

const initialState: UserState = {
    user: undefined,
}

export default (state = initialState, action: UserActions): UserState => {
    if (action.type === forumUserTypes.SET_FORUM_USER) {
        return {
            ...state,
            user: action.payload.user,
        }
    }
    return {
        ...state,
    }
}
