import { lazy } from 'react'

import { PATH_APPLICATIONS } from 'config/paths'

export const AppListRoutes = [
    {
        id: 1,
        module: 'app-list',
        protected: true,
        exact: false,
        path: PATH_APPLICATIONS.root,
        component: lazy(() => import('containers/app-list/AppList')),
    },
    {
        id: 2,
        module: 'app-list',
        protected: true,
        exact: true,
        path: PATH_APPLICATIONS.create,
        component: lazy(
            () => import('containers/app-list/components/create-app/CreateApp'),
        ),
    },
]
