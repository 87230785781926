import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useFormik, FormikProvider, Form } from 'formik'
import { Stack } from '@mui/material'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import CloseIcon from 'assets/images/new-loader/todo/CloseIcon'

import { TError } from 'config/types'

import Overlay from 'containers/emulator/components/shared/overlay/Overlay'
// eslint-disable-next-line max-len
import LoaderButton from 'containers/emulator/components/shared/button/LoaderButton'
// eslint-disable-next-line max-len
import LoaderTextArea from 'containers/emulator/components/shared/textarea/LoaderTextArea'
// eslint-disable-next-line max-len
import { createEventAction } from 'containers/dashboard/features/calendar/store/actions'

import { calendarEditSchema } from 'schemas/features/calendar'
import { getAppIdCookie } from 'utils/token'
import { IEventMultiLang } from 'containers/dashboard/features/calendar/store/types'

import UploadImage from './upload-image/UploadImage'

interface IAddEventPopup {
    onClose: () => void
}

const AddEventPopup = ({ onClose }: IAddEventPopup): React.ReactElement => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation('toast')
    const appId = getAppIdCookie()

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: calendarEditSchema,
        initialValues: {
            id: '1',
            description: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            pictureUrl: '',
            status: 'Confirmed',
        },
        onSubmit: (values, { setSubmitting }) => {
            if (!values) return
            setSubmitting(true)

            const isUa = i18n.language === 'uk'

            const updatedEvents: IEventMultiLang[] = isUa
                ? [
                      {
                          uk: {
                              description: values.description.trim(),
                              pictureUrl: values.pictureUrl,
                              applicationId: appId,
                              startTime: new Date(
                                  `${moment(values.startDate).format(
                                      'yyyy-MM-DD',
                                  )}T${moment(values.startTime).format(
                                      'HH:mm:ss',
                                  )}`,
                              ).toISOString(),
                              endTime: new Date(
                                  `${moment(values.endDate).format(
                                      'yyyy-MM-DD',
                                  )}T${moment(values.endTime).format(
                                      'HH:mm:ss',
                                  )}`,
                              ).toISOString(),
                              status: values.status,
                              language: 'uk',
                          },
                          en: null,
                      },
                  ]
                : [
                      {
                          en: {
                              description: values.description.trim(),
                              pictureUrl: values.pictureUrl,
                              applicationId: appId,
                              startTime: new Date(
                                  `${moment(values.startDate).format(
                                      'yyyy-MM-DD',
                                  )}T${moment(values.startTime).format(
                                      'HH:mm:ss',
                                  )}`,
                              ).toISOString(),
                              endTime: new Date(
                                  `${moment(values.endDate).format(
                                      'yyyy-MM-DD',
                                  )}T${moment(values.endTime).format(
                                      'HH:mm:ss',
                                  )}`,
                              ).toISOString(),
                              status: values.status,
                              language: 'en',
                          },
                          uk: null,
                      },
                  ]

            dispatch(
                createEventAction({
                    event: updatedEvents,
                    appId,
                    callback: (type: string, errorArray?: TError[]) => {
                        setSubmitting(false)
                        if (type === 'success') {
                            toast.success(t('toast:createEvent'))
                        } else if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                        onClose()
                    },
                }),
            )
        },
    })

    const {
        values,
        setFieldValue,
        getFieldProps,
        handleSubmit,
        touched,
        errors,
    } = formik

    return (
        <Overlay>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                    <Stack
                        spacing='16px'
                        borderRadius='13px'
                        fontSize='15px'
                        className='new-loader--background'
                        marginTop='20%'
                        padding='16px 0'
                    >
                        <Stack
                            padding='0 10px 0 16px'
                            borderBottom='1px solid #AAAAAD'
                        >
                            <Stack
                                alignSelf='flex-end'
                                width='22px'
                                onClick={onClose}
                            >
                                <CloseIcon />
                            </Stack>
                            <Stack fontWeight={600}>Add New Event</Stack>
                        </Stack>
                        <Stack padding='0 16px' spacing='10px'>
                            <LoaderTextArea
                                {...getFieldProps('description')}
                                placeholder='Description'
                                error={errors?.description}
                                touched={touched?.description}
                            />
                            <Stack>
                                <Stack fontWeight={600} fontSize='12px'>
                                    From
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    spacing='10px'
                                >
                                    {/* <LoaderDatePicker
                                        value={values.startDate}
                                        changeValue={(
                                            newValue: string | Date | null,
                                        ) => {
                                            setFieldValue('startDate', newValue)
                                        }}
                                        touched={touched?.startDate}
                                        errors={errors?.startDate}
                                    />
                                    <LoaderTimePicker
                                        value={values.startTime}
                                        changeValue={(
                                            newValue: string | Date | null,
                                        ) => {
                                            setFieldValue('startTime', newValue)
                                        }}
                                        touched={touched?.startTime}
                                        errors={errors?.startTime}
                                    /> */}
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack fontWeight={600} fontSize='12px'>
                                    To
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    spacing='10px'
                                >
                                    {/* <LoaderDatePicker
                                        value={values.endDate}
                                        changeValue={(
                                            newValue: string | Date | null,
                                        ) => {
                                            setFieldValue('endDate', newValue)
                                        }}
                                        touched={touched?.endDate}
                                        errors={errors?.endDate}
                                    />
                                    <LoaderTimePicker
                                        value={values.endTime}
                                        changeValue={(
                                            newValue: string | Date | null,
                                        ) => {
                                            setFieldValue('endTime', newValue)
                                        }}
                                        touched={touched?.endTime}
                                        errors={errors?.endTime}
                                    /> */}
                                </Stack>
                            </Stack>
                            <UploadImage
                                onChange={setFieldValue}
                                pictureUrl={values.pictureUrl}
                            />
                        </Stack>
                        {values.description !== '' &&
                            values.startDate !== '' &&
                            values.startTime !== '' &&
                            values.endDate !== '' &&
                            values.endTime !== '' && (
                                <Stack padding='10px 16px 0 16px'>
                                    <LoaderButton
                                        type='submit'
                                        // isFull
                                        // size='big'
                                    >
                                        Create
                                    </LoaderButton>
                                </Stack>
                            )}
                    </Stack>
                </Form>
            </FormikProvider>
        </Overlay>
    )
}

export default AddEventPopup
