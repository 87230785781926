import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import { ReactComponent as Price } from 'assets/images/new-loader/Price.svg'
import { ReactComponent as Clock } from 'assets/images/new-loader/Clock.svg'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError, IPagination } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

// eslint-disable-next-line max-len
import { fetchServicesAction } from 'containers/dashboard/features/services/store/actions'
import { IService } from 'containers/dashboard/features/services/store/types'

import './ServicePage.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

const BlogPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const [isLoading, setIsLoading] = useState(false)
    const { services } = useSelector((state: RootState) => state.services)

    useEffect(() => {
        if (
            (pathname.includes('loader') ||
                pathname.includes('services/create')) &&
            appId
        ) {
            setIsLoading(true)
            dispatch(
                fetchServicesAction({
                    appId,
                    page: 1,
                    language: i18n.language,
                    callback: (
                        type: string,
                        data: IPagination<IService>,
                        errorArray?: TError[],
                    ) => {
                        console.log(data)
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
            setIsLoading(false)
        }
    }, [appId, dispatch, pathname, t, i18n.language])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    const displayCurrency = (currency: string) => {
        switch (currency) {
            case 'UAH':
                return <>&#x20b4;</>
            case 'USD':
                return <>&#x24;</>
            case 'EUR':
                return <>&#8364;</>
            case 'IRP':
                return <>&#8377;</>
            default:
                return ''
        }
    }

    return (
        <div className='m-service__wrapper'>
            {!isLoading ? (
                services && services.length > 0 ? (
                    services.map(item => (
                        <button
                            type='button'
                            key={item.id}
                            className='m-service new-loader--surface'
                            onClick={() =>
                                push(
                                    // eslint-disable-next-line max-len
                                    `${PATH_NEW_LOADER.pages.services}/${item.id}`,
                                )
                            }
                        >
                            <CustomImage
                                src={item.imageUrl}
                                className='m-service__image'
                            />
                            <div className='m-service__info'>
                                {/* eslint-disable-next-line max-len */}
                                <div className='m-service__title new-loader--text'>
                                    {item.name}
                                </div>
                                {/* eslint-disable-next-line max-len */}
                                <div className='m-service__desc new-loader--text'>
                                    {item.description.replace(
                                        regexForHtmlCharts,
                                        ' ',
                                    )}
                                </div>
                                <div className='m-service__values'>
                                    {/* eslint-disable-next-line max-len */}
                                    <div className='m-service__tag new-loader--primary-color'>
                                        <Price />
                                        {displayCurrency(item.currency)}
                                        {item.price}
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-service__background new-loader--primary-background' />
                                    </div>

                                    {item.duration ? (
                                        // eslint-disable-next-line max-len
                                        <div className='m-service__tag new-loader--primary-color'>
                                            <Clock />
                                            {`${item.hours}:${item.minutes}`}
                                            {/* eslint-disable-next-line max-len */}
                                            <div className='m-service__background new-loader--primary-background' />
                                        </div>
                                    ) : null}
                                </div>
                                {/* eslint-disable-next-line max-len */}
                                <div className='m-service__read-more new-loader--text'>
                                    {t('emulator:more')}
                                </div>
                            </div>
                        </button>
                    ))
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default BlogPage
