import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { videoTypes } from './actionTypes'
import {
    getVideo,
    editVideo,
    createVideo,
    deleteVideo,
    getVideoByID,
} from '../service/video'

import {
    FetchVideoRequest,
    EditVideoRequest,
    CreateVideoRequest,
    DeleteVideoRequest,
    IVideo,
    FetchVideoByIdRequest,
} from './types'

function* fetchVideoSaga(action: FetchVideoRequest) {
    try {
        const response: AxiosResponse<IVideo[]> = yield call(
            getVideo,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: videoTypes.SET_VIDEO,
                payload: { videoDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchVideoByIdSaga(action: FetchVideoByIdRequest) {
    try {
        const response: AxiosResponse<IVideo> = yield call(
            getVideoByID,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: videoTypes.SET_RELATIVE_VIDEO,
                payload: { relativeVideo: response.data },
            })

            if (action.payload.callback) {
                action.payload.callback('success', [], response.data)
            }
        }
    } catch (err) {
        sagaErrorHandle(action.payload.callback, err)
    }
}

function* editVideoSaga(action: EditVideoRequest) {
    try {
        const response: AxiosResponse<IVideo> = yield call(
            editVideo,
            action.payload,
        )
        if (response.status === 200) {
            const { videoDetails } = yield select(state => state.video)
            const videoIdx = videoDetails.findIndex(
                (video: IVideo) => video.id === action.payload.id,
            )
            const videoCopy = [...videoDetails]

            videoCopy[videoIdx] = response.data

            yield put({
                type: videoTypes.SET_VIDEO,
                payload: {
                    videoDetails: videoCopy,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createVideoSaga(action: CreateVideoRequest) {
    try {
        const response: AxiosResponse<IVideo[]> = yield call(
            createVideo,
            action.payload,
        )

        if (response.status === 200) {
            const { videoDetails }: { videoDetails: IVideo[] } = yield select(
                state => state.video,
            )
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            let newItems: IVideo[] = []
            if (videoDetails.length) {
                newItems = response.data.filter(
                    item => item.language === videoDetails[0].language,
                )
            } else {
                newItems = response.data.filter(
                    item => item.language === action.payload.language,
                )
            }

            yield put({
                type: videoTypes.SET_VIDEO,
                payload: {
                    videoDetails: [...videoDetails].concat(newItems),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteVideoSaga(action: DeleteVideoRequest) {
    try {
        const response: AxiosResponse<IVideo> = yield call(
            deleteVideo,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { videoDetails } = yield select(state => state.video)
            const updatedVideo = videoDetails.filter(
                (video: IVideo) => video.id !== action.payload.id,
            )
            yield put({
                type: videoTypes.SET_VIDEO,
                payload: { videoDetails: updatedVideo },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* videoSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(videoTypes.FETCH_VIDEO_REQUEST, fetchVideoSaga),
        takeLatest(videoTypes.EDIT_VIDEO, editVideoSaga),
        takeLatest(videoTypes.CREATE_VIDEO_REQUEST, createVideoSaga),
        takeLatest(videoTypes.DELETE_VIDEO_REQUEST, deleteVideoSaga),
        takeLatest(videoTypes.FETCH_VIDEO_BY_ID_REQUEST, fetchVideoByIdSaga),
    ])
}

export default videoSaga
