import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { informationTypes } from './actionTypes'
import {
    getInformation,
    getInformationItem,
    createMultipleInformation,
    createInformation,
    editInformation,
    deleteInformation,
} from '../service/information'

import {
    IInformation,
    FetchInformationRequest,
    FetchInformationItemRequest,
    CreateMultipleInformationRequest,
    CreateInformationRequest,
    EditInformationRequest,
    DeleteInformationRequest,
    IMultiLangInformation,
} from './types'

function* fetchInformationSaga(action: FetchInformationRequest) {
    try {
        const response: AxiosResponse<IInformation> = yield call(
            getInformation,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: informationTypes.SET_INFORMATION,
                payload: { information: response.data },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchInformationItemSaga(action: FetchInformationItemRequest) {
    try {
        const response: AxiosResponse<IInformation> = yield call(
            getInformationItem,
            action.payload,
        )
        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'
                let relativeDetails = {
                    id: '2',
                    title: '',
                    description: '',
                    type: response.data.type,
                    imageUrl: '',
                    language: oppositeLang,
                }
                if (response.data.relativeId) {
                    const props = {
                        infoId: response.data.relativeId,
                        appId: action.payload.appId,
                    }
                    const relativeReps: AxiosResponse<IInformation> =
                        yield call(getInformationItem, props)

                    const { data } = relativeReps
                    if (data) {
                        relativeDetails = {
                            id: data.id || '2',
                            title: data.title,
                            description: data.description,
                            type: response.data.type,
                            imageUrl: data.imageUrl || '',
                            language: data.language,
                        }
                    }
                }

                yield put({
                    type: informationTypes.SET_RELATIVE_INFORMATION,
                    payload: { relativeDetails },
                })
            }
            yield put({
                type: informationTypes.SET_INFORMATION_ITEM,
                payload: { informationDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createMultipleInformationSaga(
    action: CreateMultipleInformationRequest,
) {
    try {
        const response: AxiosResponse<IMultiLangInformation> = yield call(
            createMultipleInformation,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createInformationSaga(action: CreateInformationRequest) {
    console.log(action, 'createInformationSaga')

    try {
        const response: AxiosResponse<IInformation> = yield call(
            createInformation,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editInformationSaga(action: EditInformationRequest) {
    try {
        const response: AxiosResponse<IInformation> = yield call(
            editInformation,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteInformationSaga(action: DeleteInformationRequest) {
    try {
        const response: AxiosResponse<IInformation> = yield call(
            deleteInformation,
            action.payload,
        )

        if (response.status === 200) {
            if (response.status === 200) {
                if (action.payload.callback) {
                    action.payload.callback('success')
                }
            }
            const { information } = yield select(state => state.information)
            const updatedInfo = information.filter(
                (info: IInformation) => info.id !== action.payload.infoId,
            )

            yield put({
                type: informationTypes.SET_INFORMATION,
                payload: { information: updatedInfo },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* informationSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            informationTypes.FETCH_INFORMATION_REQUEST,
            fetchInformationSaga,
        ),
        takeLatest(
            informationTypes.FETCH_INFORMATION_ITEM_REQUEST,
            fetchInformationItemSaga,
        ),
        takeLatest(
            informationTypes.CREATE_MULTIPLE_INFORMATION,
            createMultipleInformationSaga,
        ),
        takeLatest(informationTypes.CREATE_INFORMATION, createInformationSaga),
        takeLatest(informationTypes.EDIT_INFORMATION, editInformationSaga),
        takeLatest(informationTypes.DELETE_INFORMATION, deleteInformationSaga),
    ])
}

export default informationSaga
