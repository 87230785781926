import { conferenceTypes } from './actionTypes'
import { ConferenceActions, ConferenceState } from './types'

const initialState: ConferenceState = {
    conferenceDetails: undefined,
}

export default (
    state = initialState,
    action: ConferenceActions,
): ConferenceState => {
    switch (action.type) {
        case conferenceTypes.SET_CONFERENCE:
            return {
                ...state,
                conferenceDetails: action.payload.conferenceDetails,
            }

        default:
            return {
                ...state,
            }
    }
}
