export enum blogTypes {
    FETCH_BLOGS_REQUEST = 'FETCH_BLOGS_REQUEST',
    FETCH_BLOG_BY_ID = 'FETCH_BLOG_BY_ID',
    CREATE_BLOGS_REQUEST = 'CREATE_BLOGS_REQUEST',
    EDIT_BLOG_REQUEST = 'EDIT_BLOG_REQUEST',
    DELETE_BLOG_REQUEST = 'DELETE_BLOG_REQUEST',
    SET_BLOGS = 'SET_BLOGS',
    SET_BLOG = 'SET_BLOG',
    SET_RELATIVE = 'SET_REALTIVE',
}
