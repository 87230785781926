import { designLogoTypes } from './actionTypes'
import {
    EditDesignLogoPayload,
    EditDesignLogoRequest,
    FetchDesignLogoPayload,
    FetchDesignLogoRequest,
    IDesignLogo,
    SetDesignLogo,
} from './types'

export const fetchDesignLogoAction = (
    payload: FetchDesignLogoPayload,
): FetchDesignLogoRequest => ({
    type: designLogoTypes.FETCH_DESIGN_LOGO_REQUEST,
    payload,
})

export const editDesignLogoAction = (
    payload: EditDesignLogoPayload,
): EditDesignLogoRequest => ({
    type: designLogoTypes.EDIT_DESIGN_LOGO_REQUEST,
    payload,
})

export const SetDesignLogoAction = (payload: {
    designLogo?: IDesignLogo
}): SetDesignLogo => ({
    type: designLogoTypes.SET_DESIGN_LOGO,
    payload,
})
