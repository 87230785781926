import React from 'react'

const CategoryPurchases = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18.2236 8.24951H15.4689L11.5501 3.02451C11.4861 2.93913 11.4031 2.86982 11.3076 2.82209C11.2121 2.77436 11.1069 2.74951 11.0001 2.74951C10.8934 2.74951 10.7881 2.77436 10.6927 2.82209C10.5972 2.86982 10.5142 2.93913 10.4501 3.02451L6.53139 8.24951H3.77666C3.40609 8.24894 3.05042 8.39537 2.78766 8.65668C2.5249 8.91799 2.3765 9.27285 2.37502 9.64342C2.37422 9.77442 2.3932 9.9048 2.43131 10.0301L4.41948 17.0401C4.54876 17.4969 4.82371 17.8991 5.20252 18.1854C5.58132 18.4716 6.04326 18.6264 6.51807 18.626H15.4822C15.9571 18.6246 16.4187 18.4695 16.7982 18.1841C17.1777 17.8986 17.4546 17.498 17.5877 17.0422L19.5784 10.0228C19.5876 9.98959 19.5942 9.95569 19.5982 9.92143L19.621 9.70959C19.6235 9.68748 19.6248 9.66525 19.6248 9.64299C19.6232 9.27256 19.4749 8.91788 19.2122 8.65668C18.9495 8.39547 18.594 8.24905 18.2236 8.24951ZM11.0001 4.58299L13.7501 8.24951H8.25014L11.0001 4.58299ZM11.0001 15.1103C10.6801 15.1088 10.3677 15.0125 10.1023 14.8336C9.8369 14.6546 9.63048 14.4011 9.50905 14.105C9.38763 13.8089 9.35665 13.4834 9.42003 13.1697C9.4834 12.8559 9.63829 12.568 9.86515 12.3422C10.092 12.1165 10.3807 11.963 10.6947 11.9011C11.0087 11.8392 11.334 11.8717 11.6296 11.9946C11.9251 12.1174 12.1777 12.325 12.3553 12.5912C12.533 12.8575 12.6278 13.1704 12.6278 13.4904C12.6254 13.9205 12.4529 14.3321 12.1479 14.6354C11.8429 14.9386 11.4302 15.1087 11.0001 15.1086V15.1103Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default CategoryPurchases
