import React from 'react'

export const BillingIcon = ({
    isMobile,
}: {
    isMobile?: boolean
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M3.22222 3.63635C1.99653 3.63635 1 4.57982 1 5.74025V6.7922H21V5.74025C21 4.57982 20.0035 3.63635 18.7778 3.63635H3.22222ZM21 9.94804H1V16.2597C1 17.4202 1.99653 18.3636 3.22222 18.3636H18.7778C20.0035 18.3636 21 17.4202 21 16.2597V9.94804ZM4.88889 14.1558H7.11111C7.41667 14.1558 7.66667 14.3925 7.66667 14.6818C7.66667 14.9711 7.41667 15.2078 7.11111 15.2078H4.88889C4.58333 15.2078 4.33333 14.9711 4.33333 14.6818C4.33333 14.3925 4.58333 14.1558 4.88889 14.1558ZM8.77778 14.6818C8.77778 14.3925 9.02778 14.1558 9.33333 14.1558H13.7778C14.0833 14.1558 14.3333 14.3925 14.3333 14.6818C14.3333 14.9711 14.0833 15.2078 13.7778 15.2078H9.33333C9.02778 15.2078 8.77778 14.9711 8.77778 14.6818Z'
            fill='currentColor'
        />
    </svg>
)

export default BillingIcon
