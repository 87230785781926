import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const ForumRoutes = [
    {
        id: 1,
        module: 'forum',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.premium.forum.themes.root}/:themeId/users`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/forum/forum-users/ForumUsers'
                ),
        ),
    },
    {
        id: 2,
        module: 'forum',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.premium.forum.themes.root}/:themeId/users/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/forum/forum-users/forum-users-item/ForumUsersItem'
                ),
        ),
    },
    {
        id: 3,
        module: 'forum',
        protected: true,
        exact: true,
        path: PATH_FEATURES.premium.forum.themes.root,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/forum/forum-themes/ForumThemes'
                ),
        ),
    },
    {
        id: 4,
        module: 'forum',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.premium.forum.themes.edit}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/forum/forum-themes/forum-themes-item/ForumThemesItem'
                ),
        ),
    },
    {
        id: 5,
        module: 'forum',
        protected: true,
        exact: true,
        path: PATH_FEATURES.premium.forum.themes.create,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/forum/forum-themes/forum-themes-item/ForumThemesItem'
                ),
        ),
    },
]
