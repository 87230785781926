import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NewLoader from 'containers/emulator/NewLoader'
import emulatorRoutes from 'router/routes/emulator'

const NewLoaderWrapper = (): React.ReactElement => (
    <NewLoader>
        <React.Suspense fallback={<span />}>
            <Switch>
                {emulatorRoutes.map(route => (
                    <Route
                        exact
                        path={route.path}
                        component={route.component}
                        key={route.module + route.id}
                    />
                ))}
            </Switch>
        </React.Suspense>
    </NewLoader>
)

export default NewLoaderWrapper
