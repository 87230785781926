import { surveyUsersTypes } from './actionTypes'
import { SurveyUsersActions, SurveyUsersState } from './types'

const initialState: SurveyUsersState = {
    surveyUsers: undefined,
}

export default (
    state = initialState,
    action: SurveyUsersActions,
): SurveyUsersState => {
    switch (action.type) {
        case surveyUsersTypes.SET_SURVEY_USERS:
            return {
                ...state,
                surveyUsers: action.payload.surveyUsers,
            }
        default:
            return {
                ...state,
            }
    }
}
