import React, { useState, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'

import { data } from 'config/menuItems'
import { IMainItem, IItem } from 'config/types'

import { RootState } from 'redux/reducers/rootReducer'
import { useTranslation } from 'react-i18next'

import MenuPannel from '../menu-pannel/MenuPannel'

import './Navigation.scss'

const Navigation = (): React.ReactElement => {
    const { showAd } = useSelector((state: RootState) => state.menus)
    const queryMax = useMediaQuery('(max-width:960px)')
    const cookies = useCookies([
        'isExpired',
        'active_features',
        'isTrialUsed',
    ])[0]
    const { pathname } = useLocation()
    const { push } = useHistory()
    const { i18n } = useTranslation()

    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [subcategory, setSubcategory] = useState<IItem[]>([])

    useEffect(
        () => () => {
            document.body.style.overflowY = 'scroll'
        },
        [],
    )

    // TODO
    // one more rerender because of this but fixed problem with navigation
    useEffect(() => {
        const categoryKey = pathname.split('/')[2]
        const category: IMainItem | undefined = data.find(
            cat => cat.key === categoryKey,
        )
        if (!category) {
            setSubcategory([])
            return
        }

        if (category.name !== 'features') {
            setSubcategory(category.includes)
            return
        }

        // const survey = category.includes.find(item => item.name === 'survey')!

        const subCats: IItem[] = cookies.active_features
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              cookies.active_features.map((feature: any) => {
                  const updIncludes = category.includes.filter(
                      (subCat: IItem) =>
                          feature.type.toLowerCase() ===
                          subCat.type?.toLowerCase(),
                  )

                  return updIncludes
              })
            : []

        // setSubcategory([...subCats.flat(), survey])
        setSubcategory(subCats.flat())
    }, [i18n.language, pathname, cookies.active_features, push])

    return (
        <>
            {/* standard case */}
            <div
                className={`${
                    // (cookies.isTrialUsed === 'false' ||
                    //     cookies.isExpired === 'true') &&
                    showAd ? 'constructor-nav--sale' : 'constructor-nav'
                }`}
            >
                {/* sale case */}
                {/* <div
                className={`${
                    showAd ? 'constructor-nav--sale' : 'constructor-nav'
                }
                `}
            > */}
                <MenuPannel menuItems={data} />
                {subcategory.length > 0 && (
                    <MenuPannel subcategories menuItems={subcategory} />
                )}
            </div>
            {queryMax && (
                <div
                    className={`constructor-overlay ${
                        showAd ? 'constructor-overlay--sale' : ''
                    }`}
                />
                // <div
                //     className={`constructor-overlay ${
                //         (cookies.isTrialUsed === 'false' ||
                //             cookies.isExpired === 'true') &&
                //         showAd
                //             ? 'constructor-overlay--sale'
                //             : ''
                //     }`}
                // />
            )}
        </>
    )
}

export default Navigation as React.FC
