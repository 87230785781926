import React from 'react'

export const FeaturesIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M45.9999 26.7859C46.0039 26.4447 45.8145 26.1307 45.5109 25.9752L34.9492 20.4784V6.69115C34.9492 6.21265 34.5282 6.03091 34.0508 6C33.5275 5.96604 33.1524 5.98403 33.1524 6.5V20.4784L23.8985 25.2945V12.9877L34.451 7.49562C34.4539 7.49418 34.4576 7.4922 34.462 7.48969C35.0787 7.14316 35.0773 6.24831 34.4649 5.89433C34.4591 5.89101 34.4546 5.88858 34.4514 5.88696L23.4147 0.142982C23.1548 0.00767773 22.8451 0.00767773 22.5853 0.142982L11.548 5.88732L11.543 5.88975C11.2432 6.03521 11.0523 6.33915 11.0509 6.67229C11.0508 6.68891 11.0508 6.70041 11.0509 6.70517V20.4784L0.48975 25.9749C0.188504 26.128 -0.00169559 26.4388 1.14381e-05 26.7768C1.14381e-05 26.7824 0.000101282 26.7864 0.000101282 26.7889V39.3093C0.000101282 39.6443 0.186527 39.9515 0.48382 40.1062L11.5251 45.8525C11.5332 45.8568 11.5449 45.8626 11.5596 45.8694C11.8138 45.9882 12.1094 45.9841 12.3607 45.8591C12.3647 45.8571 12.3678 45.8555 12.3699 45.8544L23 40.322L33.6265 45.8525C33.6312 45.8551 33.6373 45.8582 33.6444 45.8619C33.9041 45.9929 34.2125 45.9904 34.47 45.8551L34.4713 45.8545L45.5162 40.1062C45.8135 39.9515 45.9999 39.6443 45.9999 39.3093V26.7888V26.7859ZM11.0509 43.5802L1.79698 38.7641V28.2553L11.0509 33.0713V43.5802ZM11.9493 31.5134L2.84455 26.7749L11.9493 22.0365L21.0541 26.7749L11.9493 31.5134ZM22.1016 38.7641L12.8478 43.5802V33.0714L22.1016 28.2553V38.7641ZM22.1016 25.2945L12.8478 20.4784V8.17151L22.1016 12.9877V25.2945ZM13.8953 6.69115L23 1.95279L32.1047 6.69115L23 11.4297L13.8953 6.69115ZM33.1524 43.5802L23.8984 38.7641V28.2553L33.1524 33.0714V43.5802ZM34.0508 31.5134L24.946 26.7749L34.0508 22.0365L43.1556 26.7749L34.0508 31.5134ZM44.203 38.7641L34.9492 43.5802V33.0714L44.203 28.2554V38.7641Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
    </svg>
)

export default FeaturesIcon
