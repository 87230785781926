import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const AboutRoutes = [
    {
        id: 1,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.about,
        component: lazy(
            () =>
                import('containers/emulator/pages/about/about-page/AboutPage'),
        ),
    },
    {
        id: 2,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.about}/:id`,
        component: lazy(
            () =>
                import('containers/emulator/pages/about/about-item/AboutItem'),
        ),
    },
]
