import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const TodoPremiumRoutes = [
    {
        id: 1,
        module: 'todoPremium',
        protected: true,
        exact: true,
        path: PATH_FEATURES.premium.todo.root,
        component: lazy(
            () =>
                import(
                    'containers/dashboard/features/premium/todo/TodoPremium'
                ),
        ),
    },
]
