import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'
import { FIREBASE_URLS } from 'config/urls'

import { IGeneralSettings } from '../store/types'

export const getGeneralSettings = ({
    appId,
}: {
    appId: string
}): Promise<AxiosResponse<IGeneralSettings>> =>
    axiosInstance.get(`${appId}${FIREBASE_URLS.main}`)

export const editGeneralSettings = ({
    appId,
    links,
}: {
    appId: string
    links: IGeneralSettings
}): Promise<AxiosResponse<IGeneralSettings>> =>
    axiosInstance.put(`${appId}${FIREBASE_URLS.main}`, links)

export const getFileUrl = ({
    appId,
    file,
    fileType,
}: {
    appId: string
    file: FormData
    fileType: string
}): Promise<AxiosResponse<IGeneralSettings>> =>
    axiosInstance.post(
        `${appId}${FIREBASE_URLS.main}/file/upload?fileType=${fileType}`,
        file,
    )
