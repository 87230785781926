import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SMM_URLS } from 'config/urls'

import { ISmm, ISmmMultilang } from '../store/types'

export const getSmm = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ISmm[]>> =>
    axiosInstance.get(`${appId}${SMM_URLS.main}/language/${language}`)

export const getSmmById = ({
    appId,
    smmId,
}: {
    appId: string
    smmId: string
}) => axiosInstance.get(`${appId}${SMM_URLS.main}/${smmId}`)

export const editSmm = ({
    item,
    appId,
    id,
}: {
    item: ISmm
    appId: string
    id: string
}): Promise<AxiosResponse<ISmm>> =>
    // axiosInstance.put(`${appId}${SMM_URLS.main}/${language}/${id}`, smm)
    axiosInstance.put(`${appId}${SMM_URLS.main}/${id}`, item)

export const createSmm = ({
    item,
    appId,
}: {
    item: ISmmMultilang
    appId: string
}): Promise<AxiosResponse<ISmm>> =>
    axiosInstance.post(`${appId}${SMM_URLS.main}`, item)

export const deleteSmm = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<ISmm>> =>
    axiosInstance.delete(`${appId}${SMM_URLS.main}/${id}`)
