import { calendarTypes } from './actionTypes'
import {
    FetchEventDates,
    FetchEventDatesPayload,
    SetDates,
    SetCalendarDate,
    CalendarDatePayload,
    FetchEventsByDateRequest,
    FetchEventsPayload,
    FetchEventRequest,
    FetchEventPayload,
    CreateEventsRequest,
    CreateEventsPayload,
    EditEventRequest,
    EditEventPayload,
    DeleteEventRequest,
    DeleteEventPayload,
    IEvent,
    IProposedEvent,
    IEventData,
    SetEvents,
    SetEvent,
    FetchUnconfirmedEventsPayload,
    FetchUnconfirmedEventsRequest,
    FetchAllEventsRequest,
    FetchAllEventsPayload,
    SetUnconfirmedEvents,
    SetAllEvents,
    AcceptEventPayload,
    AcceptEventRequest,
    RejectEventPayload,
    RejectEventRequest,
    SetCurrentDay,
    SetActiveStartDate,
} from './types'

export const fetchEventDatesAction = (
    payload: FetchEventDatesPayload,
): FetchEventDates => ({
    type: calendarTypes.FETCH_DATES_WITH_EVENTS,
    payload,
})

export const setEventDateAction = (
    payload: CalendarDatePayload,
): SetCalendarDate => ({
    type: calendarTypes.SET_EVENT_DATE,
    payload,
})

export const fetchEventsByDateAction = (
    payload: FetchEventsPayload,
): FetchEventsByDateRequest => ({
    type: calendarTypes.FETCH_EVENTS_BY_DATE_REQUEST,
    payload,
})

export const fetchEventAction = (
    payload: FetchEventPayload,
): FetchEventRequest => ({
    type: calendarTypes.FETCH_EVENT_REQUEST,
    payload,
})

export const createEventAction = (
    payload: CreateEventsPayload,
): CreateEventsRequest => ({
    type: calendarTypes.CREATE_EVENT_REQUEST,
    payload,
})

export const editEventAction = (
    payload: EditEventPayload,
): EditEventRequest => ({
    type: calendarTypes.EDIT_EVENT_REQUEST,
    payload,
})

export const deleteEventAction = (
    payload: DeleteEventPayload,
): DeleteEventRequest => ({
    type: calendarTypes.DELETE_EVENT_REQUEST,
    payload,
})

export const SetEventsAction = (payload: { events?: IEvent[] }): SetEvents => ({
    type: calendarTypes.SET_EVENTS,
    payload,
})

export const SetEventAction = (payload: {
    calendarEvent: IEventData
}): SetEvent => ({
    type: calendarTypes.SET_EVENT,
    payload,
})

export const SetCurrentDayAction = (payload: {
    currentDay: string
}): SetCurrentDay => ({
    type: calendarTypes.SET_CURRENT_DAY,
    payload,
})

export const SetActiveStartDateAction = (payload: {
    activeRangeDate?: Date
}): SetActiveStartDate => ({
    type: calendarTypes.SET_ACTIVE_START_DATE,
    payload,
})

export const SetDatesAction = (payload: {
    datesWithEvents: string[]
}): SetDates => ({
    type: calendarTypes.SET_DATES,
    payload,
})

export const fetchUnconfirmedEventsAction = (
    payload: FetchUnconfirmedEventsPayload,
): FetchUnconfirmedEventsRequest => ({
    type: calendarTypes.FETCH_UNCONFIRMED_EVENTS,
    payload,
})

export const SetUnconfirmedEventsAction = (payload: {
    unconfirmedEvents?: number
}): SetUnconfirmedEvents => ({
    type: calendarTypes.SET_UNCONFIRMED_EVENTS,
    payload,
})

export const fetchAllEventsAction = (
    payload: FetchAllEventsPayload,
): FetchAllEventsRequest => ({
    type: calendarTypes.FETCH_ALL_EVENTS,
    payload,
})

export const SetAllEventsAction = (payload: {
    allEvents?: IProposedEvent[]
}): SetAllEvents => ({
    type: calendarTypes.SET_ALL_EVENTS,
    payload,
})

export const acceptEventAction = (
    payload: AcceptEventPayload,
): AcceptEventRequest => ({
    type: calendarTypes.ACCEPT_CALENDAR_EVENT,
    payload,
})

export const rejectEventAction = (
    payload: RejectEventPayload,
): RejectEventRequest => ({
    type: calendarTypes.REJECT_CALENDAR_EVENT,
    payload,
})
