import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { BLOG_URLS } from 'config/urls'

import { IBlog, IBlogMultiLang } from '../store/types'

export const getBlogs = ({
    language,
    appId,
    page,
}: {
    appId: string
    page: number
    language: string
}): Promise<AxiosResponse<IBlog[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${BLOG_URLS.blogs}/language/${language}?pageNumber=${page}&pageSize=10`,
    )

export const getBlogById = ({
    blogId,
    appId,
}: {
    blogId: string | number | undefined
    appId: string
}): Promise<AxiosResponse<IBlog>> =>
    axiosInstance.get(`${appId}${BLOG_URLS.blogs}/${blogId}`)

export const createBlogs = ({
    blogs,
    appId,
}: {
    blogs: IBlogMultiLang[]
    appId: string
}): Promise<AxiosResponse<IBlog[]>> =>
    axiosInstance.post(`${appId}${BLOG_URLS.blogs}/bulk`, blogs)

export const editBlog = ({
    blogId,
    blog,
    appId,
}: {
    appId: string
    blogId: string
    blog: IBlog
}): Promise<AxiosResponse<IBlog>> =>
    // eslint-disable-next-line max-len
    axiosInstance.put(`${appId}${BLOG_URLS.blogs}/${blogId}`, blog)
// axiosInstance.put(`${appId}${BLOG_URLS.blogs}/${blogId}`, blog)

export const deleteBlog = ({
    blogId,
    appId,
}: {
    appId: string
    blogId: string
}): Promise<AxiosResponse<IBlog>> =>
    axiosInstance.delete(`${appId}${BLOG_URLS.blogs}/${blogId}`)
