import CustomButton from 'components/custom-button/CustomButton'
import CustomCheckbox from 'components/custom-checkbox/CustomCheckbox'
import Preloader from 'components/preloader/Preloader'
import { PATH_NEW_LOADER } from 'config/paths'
import { TError } from 'config/types'
import { fetchTestAction } from 'containers/dashboard/features/premium/survey/store/actions'
import { ETypeAnswer } from 'containers/dashboard/features/premium/survey/store/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { RootState } from 'redux/reducers/rootReducer'
import { getAppIdCookie } from 'utils/token'

import './SurveyItem.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'
import CustomInput from 'components/custom-input/CustomInput'

interface IPageParams {
    id: string
}

const SurveyItem = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [futherText, setFutherText] = useState(false)
    const [inputVal, setInputVal] = useState('')
    const { test } = useSelector((state: RootState) => state.survey)
    const { i18n, t } = useTranslation(['toast', 'survey'])
    const [answers, setAnswers] = useState<string[]>([])

    const appId = getAppIdCookie()
    const { id } = useParams<IPageParams>()
    const { pathname } = useLocation()
    const { push } = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (appId && id && pathname.includes('loader')) {
            setIsLoading(false)
            dispatch(
                fetchTestAction({
                    appId,
                    language: i18n.language,
                    testId: id,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'success') {
                            errorArray?.forEach(err => {
                                console.error(err)
                            })
                            setIsLoading(false)
                        }
                    },
                }),
            )
        }
    }, [])

    const handleSelectAnswer = (answerId: string) => {
        if (answers.includes(answerId)) {
            setAnswers(answers.filter(item => item !== answerId))
            return
        }

        setAnswers([...answers, answerId])
    }

    console.log(test, 'test')

    return (
        <div className='m-survey'>
            {!isLoading ? (
                test?.tests[0] ? (
                    <>
                        <div className='m-survey-test new-loader--surface'>
                            <div className='m-survey-test__heading'>
                                <div className='m-survey-test__heading-questions'>
                                    1/{test?.tests.length}
                                </div>
                                <div className='m-survey-test__heading-name'>
                                    {test?.name}
                                </div>
                            </div>
                            <div className='m-survey-test__body'>
                                <div
                                    className='m-survey-test__body-answers'
                                    key={`item-${test?.tests[0].description}`}
                                >
                                    {test?.tests[0].answerType ===
                                    ETypeAnswer.SHORT_ANSWER ? (
                                        <div className='m-survey-test__body-answer__text'>
                                            <CustomInput
                                                type='text'
                                                value={inputVal}
                                                onChange={({ target }) =>
                                                    setInputVal(target.value)
                                                }
                                                name='short-answer'
                                                placeholder={t(
                                                    'survey:shortPlaceholder',
                                                )}
                                            />
                                        </div>
                                    ) : (
                                        test?.tests[0].answers.map(
                                            ({ answer, id: answerId }) => (
                                                <div
                                                    className='m-survey-test__body-answer'
                                                    key={answerId}
                                                >
                                                    <CustomCheckbox
                                                        isDefault
                                                        isChecked={answers.includes(
                                                            answerId,
                                                        )}
                                                        name={`answer-${id}`}
                                                        handleChange={() =>
                                                            handleSelectAnswer(
                                                                answerId,
                                                            )
                                                        }
                                                        id={answerId}
                                                        text={answer}
                                                    />
                                                </div>
                                            ),
                                        )
                                    )}
                                </div>
                            </div>
                            <div className='m-survey-test__actions'>
                                <CustomButton
                                    design='cancel'
                                    handleClick={() =>
                                        push(PATH_NEW_LOADER.pages.survey)
                                    }
                                >
                                    {t('survey:back')}
                                </CustomButton>
                                <CustomButton
                                    design='cancel'
                                    disabled={futherText}
                                    handleClick={() =>
                                        !futherText && setFutherText(true)
                                    }
                                >
                                    {t('survey:next')}
                                </CustomButton>
                            </div>
                        </div>
                        {futherText ? (
                            <div className='m-survey-test__futher'>
                                {t('survey:futherText')}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default SurveyItem as React.FC
