import { IPalette } from 'containers/dashboard/design/color-palette/store/types'
import styled from 'styled-components'

interface INewLoaderContent {
    palette?: IPalette
}

const NewLoaderContent = styled.div`
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: ${(props: INewLoaderContent) => props.palette?.text || '#c8c8c8'}};

    &::-webkit-scrollbar {
        display: none;
    }

    .new-loader--background {
        background: ${(props: INewLoaderContent) =>
            props.palette?.background || '#fafafa'}};
    }

    .new-loader--contrast-color {
        color: ${(props: INewLoaderContent) =>
            props.palette?.primaryContrast || '#f2f2f2'}};
    }

    .new-loader--primary-background {
        background: ${(props: INewLoaderContent) =>
            props.palette?.primary || '#9d9e9e'}};
    }

    .new-loader--primary-color {
        color: ${(props: INewLoaderContent) =>
            props.palette?.primary || '#9d9e9e'}};
    }

    .new-loader--surface {
        background: ${(props: INewLoaderContent) =>
            props.palette?.surface || '#f2f2f2'}};
    }

    .new-loader--text {
        color: ${(props: INewLoaderContent) =>
            props.palette?.text || '#55554'}};
    }

    .new-loader--navbar {
        background: ${(props: INewLoaderContent) =>
            props.palette?.navBar || '#fafafa'}};
    }

    .new-loader--tabbar {
        background: ${(props: INewLoaderContent) =>
            props.palette?.tabBar || '#fafafa'}};
    }

    .new-loader--bottom-border.active {
        border-bottom-color: ${(props: INewLoaderContent) =>
            props.palette?.primary || '#9d9e9e'}}
    }

    .new-loader--border{
        border-color:${(props: INewLoaderContent) =>
            props.palette?.text || '#9D9E9E'}
    }

    .new-loader--icon{
        stroke: ${(props: INewLoaderContent) =>
            props.palette?.primary || '#9d9e9e'}};
    }
    .new-loader--text-background {
        background: ${(props: INewLoaderContent) =>
            props.palette?.text || '#55554'}};
    }
`

export default NewLoaderContent
