import { smmTypes } from './actionTypes'
import {
    FetchSmmByIdRequest,
    EditSmmRequest,
    FetchSmmByIdPayload,
    EditSmmPayload,
    SetSmm,
    ISmm,
    CreateSmmPayload,
    CreateSmmRequest,
    DeleteSmmRequest,
    DeleteSmmPayload,
    FetchSmmPayload,
    FetchSmmRequest,
} from './types'

export const fetchSmmRequest = (payload: FetchSmmPayload): FetchSmmRequest => ({
    type: smmTypes.FETCH_SMM_REQUEST,
    payload,
})

export const fetchSmmByIdRequest = (
    payload: FetchSmmByIdPayload,
): FetchSmmByIdRequest => ({
    type: smmTypes.FETCH_SMM_BY_ID_REQUEST,
    payload,
})

export const EditSmmAction = (payload: EditSmmPayload): EditSmmRequest => ({
    type: smmTypes.EDIT_SMM,
    payload,
})

export const createSmmAction = (
    payload: CreateSmmPayload,
): CreateSmmRequest => ({
    type: smmTypes.CREATE_SMM,
    payload,
})

export const DeleteSmmAction = (
    payload: DeleteSmmPayload,
): DeleteSmmRequest => ({
    type: smmTypes.DELETE_SMM,
    payload,
})

export const SetSmmAction = (payload: { smmDetails?: ISmm[] }): SetSmm => ({
    type: smmTypes.SET_SMM,
    payload,
})
