import { employeeTypes } from './actionTypes'
import { EmployeeActions, EmployeeState } from './types'

const initialState: EmployeeState = {
    employees: undefined,
    employee: undefined,
    relativeEmployee: undefined,
}

export default (
    state = initialState,
    action: EmployeeActions,
): EmployeeState => {
    switch (action.type) {
        case employeeTypes.SET_EMPLOYEES:
            return {
                ...state,
                employees: action.payload.employees,
            }
        case employeeTypes.SET_EMPLOYEE:
            return {
                ...state,
                employee: action.payload.employee,
            }
        case employeeTypes.SET_REALTIVE_EMPLOYEE:
            return {
                ...state,
                relativeEmployee: action.payload.relativeEmployee,
            }
        default:
            return {
                ...state,
            }
    }
}
