import React from 'react'

const CategoryWishList = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M2.96143 15.6597H13.0303C14.7734 15.6597 15.6367 14.7964 15.6367 13.0864V2.95117C15.6367 1.24121 14.7734 0.37793 13.0303 0.37793H2.96143C1.22656 0.37793 0.35498 1.23291 0.35498 2.95117V13.0864C0.35498 14.8047 1.22656 15.6597 2.96143 15.6597ZM4.09033 2.61084C4.53857 2.61084 4.87891 2.86816 5.08643 3.25C5.29395 2.86816 5.65088 2.61084 6.06592 2.61084C6.77148 2.61084 7.27783 3.14209 7.27783 3.87256C7.27783 4.95166 6.14062 5.95605 5.30225 6.479C5.22754 6.52051 5.15283 6.57031 5.09473 6.57031C5.04492 6.57031 4.96191 6.52881 4.88721 6.479C4.06543 5.89795 2.90332 4.95166 2.90332 3.87256C2.90332 3.14209 3.40967 2.61084 4.09033 2.61084ZM3.42627 10.2808C3.11914 10.2808 2.90332 10.0566 2.90332 9.76611C2.90332 9.48389 3.11914 9.25977 3.42627 9.25977H11.7686C12.0591 9.25977 12.2749 9.48389 12.2749 9.76611C12.2749 10.0566 12.0591 10.2808 11.7686 10.2808H3.42627ZM3.42627 13.1196C3.11914 13.1196 2.90332 12.8955 2.90332 12.6133C2.90332 12.3228 3.11914 12.0986 3.42627 12.0986H9.57715C9.87598 12.0986 10.0918 12.3228 10.0918 12.6133C10.0918 12.8955 9.87598 13.1196 9.57715 13.1196H3.42627Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default CategoryWishList
