import React from 'react'

export const BillingIcon = ({
    isActive,
    isSmall
}: {
    isActive?: boolean
    isSmall?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25':'46'}
        height={isSmall ? '25':'46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M41 9H5C3.89543 9 3 9.89543 3 11V13H43V11C43 9.89543 42.1046 9 41 9ZM3 17V15H43V17H3ZM3 19V35C3 36.1046 3.89543 37 5 37H41C42.1046 37 43 36.1046 43 35V19H3ZM5 7C2.79086 7 1 8.79086 1 11V35C1 37.2091 2.79086 39 5 39H41C43.2091 39 45 37.2091 45 35V11C45 8.79086 43.2091 7 41 7H5Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M3 13H2.75V13.25H3V13ZM43 13V13.25H43.25V13H43ZM3 15V14.75H2.75V15H3ZM3 17H2.75V17.25H3V17ZM43 15H43.25V14.75H43V15ZM43 17V17.25H43.25V17H43ZM3 19V18.75H2.75V19H3ZM43 19H43.25V18.75H43V19ZM5 9.25H41V8.75H5V9.25ZM3.25 11C3.25 10.0335 4.0335 9.25 5 9.25V8.75C3.75736 8.75 2.75 9.75736 2.75 11H3.25ZM3.25 13V11H2.75V13H3.25ZM43 12.75H3V13.25H43V12.75ZM42.75 11V13H43.25V11H42.75ZM41 9.25C41.9665 9.25 42.75 10.0335 42.75 11H43.25C43.25 9.75736 42.2426 8.75 41 8.75V9.25ZM2.75 15V17H3.25V15H2.75ZM43 14.75H3V15.25H43V14.75ZM43.25 17V15H42.75V17H43.25ZM3 17.25H43V16.75H3V17.25ZM2.75 19V35H3.25V19H2.75ZM2.75 35C2.75 36.2426 3.75736 37.25 5 37.25V36.75C4.0335 36.75 3.25 35.9665 3.25 35H2.75ZM5 37.25H41V36.75H5V37.25ZM41 37.25C42.2426 37.25 43.25 36.2426 43.25 35H42.75C42.75 35.9665 41.9665 36.75 41 36.75V37.25ZM43.25 35V19H42.75V35H43.25ZM43 18.75H3V19.25H43V18.75ZM1.25 11C1.25 8.92893 2.92893 7.25 5 7.25V6.75C2.65279 6.75 0.75 8.65279 0.75 11H1.25ZM1.25 35V11H0.75V35H1.25ZM5 38.75C2.92893 38.75 1.25 37.0711 1.25 35H0.75C0.75 37.3472 2.65279 39.25 5 39.25V38.75ZM41 38.75H5V39.25H41V38.75ZM44.75 35C44.75 37.0711 43.0711 38.75 41 38.75V39.25C43.3472 39.25 45.25 37.3472 45.25 35H44.75ZM44.75 11V35H45.25V11H44.75ZM41 7.25C43.0711 7.25 44.75 8.92893 44.75 11H45.25C45.25 8.65279 43.3472 6.75 41 6.75V7.25ZM5 7.25H41V6.75H5V7.25Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <rect
            x='30'
            y='22'
            width='10'
            height='5'
            rx='1'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='1.8'
        />
        <line
            x1='6'
            y1='33'
            x2='9'
            y2='33'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2'
            strokeLinecap='round'
        />
        <line
            x1='12'
            y1='33'
            x2='15'
            y2='33'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2'
            strokeLinecap='round'
        />
        <line
            x1='18'
            y1='33'
            x2='21'
            y2='33'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2'
            strokeLinecap='round'
        />
        <line
            x1='24'
            y1='33'
            x2='27'
            y2='33'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2'
            strokeLinecap='round'
        />
    </svg>
)

export default BillingIcon
