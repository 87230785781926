import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const TestRoutes = [
    {
        id: 1,
        module: 'test',
        protected: true,
        exact: true,
        // eslint-disable-next-line max-len
        path: `${PATH_FEATURES.test.root}/:surveyId/users/:surveyCompletionId/individuals`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/survey/test-analytics/TestAnalytics'
                ),
        ),
    },
    {
        id: 2,
        module: 'test',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.test.root}`,
        component: lazy(
            () => import('containers/dashboard/features/premium/survey/Test'),
        ),
    },
    {
        id: 3,
        module: 'test',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.test.edit}/:id`,
        component: lazy(
            () =>
                import('containers/dashboard/features/premium/survey/EditTest'),
        ),
    },
    {
        id: 4,
        module: 'test',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.test.create}`,
        component: lazy(
            () =>
                import('containers/dashboard/features/premium/survey/AddTest'),
        ),
    },
    {
        id: 5,
        module: 'test',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.test.root}/:surveyId/users`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/survey/survey-users/SurveyUsers'
                ),
        ),
    },
    {
        id: 6,
        module: 'test',
        protected: true,
        exact: true,
        // eslint-disable-next-line max-len
        path: `${PATH_FEATURES.test.root}/:surveyId/statistic`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/premium/survey/test-summary/TestSummary'
                ),
        ),
    },
]
