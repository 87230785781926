import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const EmployeeRoute = [
    {
        id: 1,
        module: 'employee',
        protected: true,
        exact: false,
        path: PATH_FEATURES.employees.root,
        component: lazy(
            () => import('containers/dashboard/features/employees/Employees'),
        ),
    },
    {
        id: 2,
        module: 'employee',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.employees.edit}/:id`,
        component: lazy(
            () =>
                import('containers/dashboard/features/employees/EditEmployee'),
        ),
    },
    {
        id: 3,
        module: 'employee',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.employees.create}`,
        component: lazy(
            () =>
                import(
                    'containers/dashboard/features/employees/CreateEmployee'
                ),
        ),
    },
]
