import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const EReaderRoutes = [
    {
        id: 1,
        module: 'eReader',
        protected: true,
        exact: true,
        path: PATH_FEATURES.eReader.root,
        component: lazy(
            () => import('containers/dashboard/features/e-reader/Ereader'),
        ),
    },
    {
        id: 2,
        module: 'eReader',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.eReader.edit}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/e-reader/components/e-reader-item/EreaderItem'
                ),
        ),
    },
    {
        id: 3,
        module: 'eReader',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.eReader.create}`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/e-reader/CreateReader'
                ),
        ),
    },
]
