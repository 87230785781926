/* eslint-disable max-len */
import React from 'react'

import BlogIcon from 'assets/images/constructor/menu/features/BlogIcon'
import PaymentLinkIcon from 'assets/images/constructor/menu/features/PaymentLink'
import NewsIcon from 'assets/images/constructor/menu/features/NewsIcon'
import ServicesIcon from 'assets/images/constructor/menu/features/ServicesIcon'
import CloudIcon from 'assets/images/constructor/menu/features/CloudIcon'
import ChatIcon from 'assets/images/constructor/menu/features/ChatIcon'
import VideoIcon from 'assets/images/constructor/menu/features/VideoIcon'
import AboutIcon from 'assets/images/constructor/menu/features/AboutIcon'
import EmployeeIcon from 'assets/images/constructor/menu/features/EmployeeIcon'
import BookingIcon from 'assets/images/constructor/menu/features/BookingIcon'
import MapIcon from 'assets/images/constructor/menu/features/MapIcon'
import SurveyIcon from 'assets/images/constructor/menu/features/SurveyIcon'
import EReaderIcon from 'assets/images/constructor/menu/features/EReaderIcon'
import ConferenceIcon from 'assets/images/constructor/menu/features/ConferenceIcon'
import SmmIcon from 'assets/images/constructor/menu/features/SmmIcon'
import CalendarIcon from 'assets/images/constructor/menu/features/CalendarIcon'
import GalleryIcon from 'assets/images/constructor/menu/features/GalleryIcon'
import ForumIcon from 'assets/images/constructor/menu/features/ForumIcon'
import TodoIcon from 'assets/images/constructor/menu/features/TodoIcon'
import LiveChatIcon from 'assets/images/constructor/menu/features/LiveChat'
import PaymentsIcon from 'assets/images/constructor/menu/features/PaymentsIcon'

interface Props {
    iconName?: string
}

export const IconRender = ({ iconName }: Props): React.ReactElement => {
    switch (iconName) {
        case 'blog':
            return <BlogIcon />
        case 'billing':
            return <PaymentLinkIcon />
        case 'news':
            return <NewsIcon />
        case 'services':
            return <ServicesIcon />
        case 'video':
            return <VideoIcon />
        case 'conference':
            return <ConferenceIcon />
        case 'calendar':
            return <CalendarIcon />
        case 'gallery':
            return <GalleryIcon />
        case 'about':
            return <AboutIcon />
        case 'map':
            return <MapIcon />
        case 'ereader':
            return <EReaderIcon />
        case 'employee':
            return <EmployeeIcon />
        case 'booking':
            return <BookingIcon />
        case 'cloud':
            return <CloudIcon />
        case 'smm':
            return <SmmIcon />
        case 'chat':
            return <ChatIcon />
        case 'survey':
            return <SurveyIcon />
        case 'todo':
            return <TodoIcon />
        case 'forum':
            return <ForumIcon />
        case 'chat-premium':
            return <LiveChatIcon />
        case 'payments':
            return <PaymentsIcon />
        default:
            return <svg width='60' height='54' />
    }
}
