import { menuTypes } from './actionTypes'
import { MenuActions, MenuState } from './types'

const initialState: MenuState = {
    isOpen: false,
    isHeaderMenuOpen: false,
    showAiPopup: false,
    showAd: false,
}

export default (state = initialState, action: MenuActions): MenuState => {
    switch (action.type) {
        case menuTypes.SET_MENU_OPEN:
            return {
                ...state,
                isOpen: action.payload.isOpen,
            }
        case menuTypes.SET_HEADER_MENU_OPEN:
            return {
                ...state,
                isHeaderMenuOpen: action.payload.isOpen,
            }
        case menuTypes.SET_AI_POPUP_OPEN:
            return {
                ...state,
                showAiPopup: action.payload.isOpen,
            }
        case menuTypes.SET_SHOW_AD:
            return {
                ...state,
                showAd: action.payload.showAd,
            }
        default:
            return {
                ...state,
            }
    }
}
