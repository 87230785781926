import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { INFO_URLS } from 'config/urls'

import { IInformation, IMultiLangInformation } from '../store/types'

export const getInformation = ({
    language,
    appId,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IInformation>> =>
    axiosInstance.get(`${appId}${INFO_URLS.main}/language/${language}`)
// axiosInstance.get(`${appId}${INFO_URLS.main}`)

export const getInformationItem = ({
    infoId,
    appId,
}: {
    infoId: string
    appId: string
}): Promise<AxiosResponse<IInformation>> =>
    axiosInstance.get(`${appId}${INFO_URLS.main}/${infoId}`)
// axiosInstance.get(`${appId}${INFO_URLS.main}/${infoId}`)

export const createMultipleInformation = ({
    information,
    appId,
}: {
    information: IMultiLangInformation[]
    appId: string
}): Promise<AxiosResponse<IInformation>> =>
    // eslint-disable-next-line max-len
    axiosInstance.post(`${appId}${INFO_URLS.main}/bulk`, information)

export const createInformation = ({
    information,
    appId,
}: {
    information: IMultiLangInformation
    appId: string
}): Promise<AxiosResponse<IInformation>> =>
    axiosInstance.post(`${appId}${INFO_URLS.main}`, information)

export const editInformation = ({
    infoId,
    information,
    appId,
}: {
    information: IInformation
    infoId: string
    appId: string
}): Promise<AxiosResponse<IInformation>> =>
    axiosInstance.put(`${appId}${INFO_URLS.main}/${infoId}`, information)

export const deleteInformation = ({
    infoId,
    appId,
}: {
    infoId: string | number | undefined
    appId: string
}): Promise<AxiosResponse<IInformation>> =>
    axiosInstance.delete(`${appId}${INFO_URLS.main}/${infoId}`)
