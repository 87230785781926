import React from 'react'

import './SwitchCheckbox.scss'

interface ISwitch {
    id: string
    checked?: boolean
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SwitchCheckbox = ({
    checked,
    onChange,
    id,
}: ISwitch): React.ReactElement => (
    <label className='switch—checkbox' htmlFor={`checkbox—${id}`}>
        <input
            id={`checkbox—${id}`}
            type='checkbox'
            checked={checked}
            onChange={onChange}
        />
        <span className='slider round' />
    </label>
)

SwitchCheckbox.defaultProps = {
    checked: '',
    onChange: '',
}

export default SwitchCheckbox
