import { IPalette } from 'containers/dashboard/design/color-palette/store/types'
import styled from 'styled-components'

interface ICalendarContent {
    palette?: IPalette
}

const CalendarContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .react-calendar {
        width: 100%;
        padding: 16px;
        background: ${(props: ICalendarContent) =>
            props.palette?.surface || '#f2f2f2'};
        border-radius: 8px;
        border: none;
        border-radius: 8px;

        &__tile {
            color: ${(props: ICalendarContent) =>
                props.palette?.text || '#55554'};
            padding: 0;
            font-size: 24px;
            font-family: 'Geologica-Light';
            opacity: 0.8;
            border-radius: 8px;

            abbr {
                display: none;
            }

            &--now {
                background-color: transparent;
            }

            &--active {
                background-color: ${(props: ICalendarContent) =>
                    props.palette?.primary || '#9d9e9e'};
                color: ${(props: ICalendarContent) =>
                    props.palette?.primaryContrast || '#f2f2f2'};

                .calendar-tile__dot {
                    background: ${(props: ICalendarContent) =>
                        props.palette?.primaryContrast || '#f2f2f2'} !important;
                }
            }

            &:enabled:hover {
                background-color: ${(props: ICalendarContent) =>
                    props.palette?.primary || '#9d9e9e'};
                color: ${(props: ICalendarContent) =>
                    props.palette?.primaryContrast || '#f2f2f2'};
            }

            &:enabled:focus {
                background-color: ${(props: ICalendarContent) =>
                    props.palette?.primary || '#9d9e9e'};
                color: ${(props: ICalendarContent) =>
                    props.palette?.primaryContrast || '#f2f2f2'};
            }
        }
    }

    .react-calendar__month-view__weekdays {
        margin-bottom: 14px;
    }

    .react-calendar__month-view__weekdays__weekday {
        abbr {
            text-decoration: none;
            font-family: 'Geologica-Light';
            font-size: 11px;
        }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #757575;
    }

    .react-calendar__navigation {
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        &__arrow {
            font-size: 22px;
            font-weight: 500;
            padding: 0;
            color: ${(props: ICalendarContent) =>
                props.palette?.primary || '#9d9e9e'};

            &:enabled:hover {
                background-color: transparent !important;
            }

            &:enabled:focus {
                background-color: transparent !important;
            }
        }

        &__label {
            font-size: 17px;
            font-family: 'Geologica-Light';
            color: ${(props: ICalendarContent) =>
                props.palette?.text || '#55554'};
            padding: 0;
            pointer-events: none;

            &:enabled:hover {
                background-color: transparent !important;
            }

            &:enabled:focus {
                background-color: transparent !important;
            }
        }

        button {
            flex-grow: initial !important;
        }
    }

    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation__next2-button {
        display: none;
    }

    .calendar-tile {
        margin: 3px;
        font-size: 18px;
        line-height: 24px;
        border-radius: 5px;
        position: relative;

        &__today {
            background-color: transparent;
            border: 1px solid $color-dark-brown;
        }

        &__active {
            background-color: $color-dark-brown;
            color: $color-primary;
        }

        .calendar-tile__dot {
            font-weight: 700;
            width: 5px;
            height: 5px;
            position: absolute;
            border-radius: 50%;
            bottom: -1px;
            right: 50%;
            transform: translate(50%, 0);
            background: ${(props: ICalendarContent) =>
                props.palette?.primary || '#9d9e9e'};
        }

        &:hover {
            color: $color-primary;
            background-color: $color-dark-brown;
        }
    }

    .m-calendar {
        &__events {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        &__text {
            font-size: 16px;
            font-family: 'Geologica-Light';
            text-align: center;
        }
    }
`

export default CalendarContent
