import { galleryTypes } from './actionTypes'
import {
    IGalleryItem,
    FetchGalleryItemByIdRequest,
    FetchGalleryItemPayload,
    FetchGalleryPayload,
    FetchGalleryRequest,
    CreateGalleryRequest,
    CreateGalleryPayload,
    EditGalleryItemRequest,
    EditGalleryItemPayload,
    DeleteGalleryItemRequest,
    DeleteGalleryItemPayload,
    SetGallery,
    SetGalleryItem,
} from './types'

export const fetchGalleryAction = (
    payload: FetchGalleryPayload,
): FetchGalleryRequest => ({
    type: galleryTypes.FETCH_GALLERY_REQUEST,
    payload,
})

export const fetchGalleryItemAction = (
    payload: FetchGalleryItemPayload,
): FetchGalleryItemByIdRequest => ({
    type: galleryTypes.FETCH_GALLERY_ITEM_BY_ID,
    payload,
})

export const createGalleryAction = (
    payload: CreateGalleryPayload,
): CreateGalleryRequest => ({
    type: galleryTypes.CREATE_GALLERY_REQUEST,
    payload,
})

export const editGalleryItemAction = (
    payload: EditGalleryItemPayload,
): EditGalleryItemRequest => ({
    type: galleryTypes.EDIT_GALLERY_ITEM_REQUEST,
    payload,
})

export const deleteGalleryItemAction = (
    payload: DeleteGalleryItemPayload,
): DeleteGalleryItemRequest => ({
    type: galleryTypes.DELETE_GALLERY_ITEM_REQUEST,
    payload,
})

export const SetGalleryAction = (payload: {
    gallery?: IGalleryItem[]
}): SetGallery => ({
    type: galleryTypes.SET_GALLERY,
    payload,
})

export const SetGalleryItemAction = (payload: {
    galleryItem: IGalleryItem | undefined
}): SetGalleryItem => ({
    type: galleryTypes.SET_GALLERY_ITEM,
    payload,
})
