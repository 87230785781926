import React from 'react'

import { useTranslation } from 'react-i18next'

// eslint-disable-next-line max-len
import LinkIconRender from 'components/titled-link/link-icon-render/LinkIconRender'
// eslint-disable-next-line max-len
import { OpenButton } from 'containers/emulator/components/shared/open-button/OpenButton'
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg'

type TData = {
    type: string
    description: string
    url: string
}

interface IData {
    item: TData
}

const ChatItem = ({ item }: IData): React.ReactElement => {
    const { t } = useTranslation(['emulator'])

    return (
        <div className='m-chat__item new-loader--surface'>
            <div className='m-chat__header'>
                <LinkIconRender title={item.type} text='large' />
                <OpenButton
                    handleClick={() =>
                        window.open(
                            item.url.includes('https://')
                                ? item.url
                                : `https://${item.url}`,
                            '_blank',
                            'noopener,noreferrer',
                        )
                    }
                >
                    {t('emulator:open')}
                    <ArrowRight className='new-loader--icon' />
                </OpenButton>
            </div>
            <div className='m-chat__desc new-loader--text'>
                {item.description}
            </div>
        </div>
    )
}

export default ChatItem
