import React, { ReactElement } from 'react'

import ChatItem from '../chat-item/ChatItem'

import './ChatView.scss'

type TData = {
    type: string
    description: string
    url: string
}

interface IData {
    data: TData[]
}

const ChatView = ({ data }: IData): ReactElement => (
    <div className='m-chat'>
        {data.map(item => (
            <ChatItem item={item} key={item.url} />
        ))}
    </div>
)

export default ChatView
