import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { EMPLOYEES_URLS } from 'config/urls'

import { IEmployee, IEmployeeMultilang } from '../store/types'

export const getEmployees = ({
    language,
    appId,
    page,
    pageSize,
}: {
    appId: string
    page: number
    pageSize?: number
    language: string
}): Promise<AxiosResponse<IEmployee[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${
            EMPLOYEES_URLS.employees
        }/language/${language}?pageNumber=${page}&pageSize=${pageSize || '10'}`,
    )

export const getEmployeeById = ({
    employeeId,
    appId,
}: {
    employeeId: string
    appId: string
}): Promise<AxiosResponse<IEmployee>> =>
    axiosInstance.get(`${appId}${EMPLOYEES_URLS.employees}/${employeeId}`)

export const createEmployees = ({
    employees,
    appId,
}: {
    employees: IEmployeeMultilang
    appId: string
}): Promise<AxiosResponse<IEmployee[]>> =>
    axiosInstance.post(`${appId}${EMPLOYEES_URLS.employees}`, employees)

export const editEmployee = ({
    employeeId,
    employee,
    appId,
}: {
    appId: string
    employeeId: string
    employee: IEmployee
}): Promise<AxiosResponse<IEmployee>> =>
    axiosInstance.put(
        `${appId}${EMPLOYEES_URLS.employees}/${employeeId}`,
        employee,
    )

export const deleteEmployee = ({
    employeeId,
    appId,
}: {
    appId: string
    employeeId: string
}): Promise<AxiosResponse<IEmployee>> =>
    axiosInstance.delete(`${appId}${EMPLOYEES_URLS.employees}/${employeeId}`)
