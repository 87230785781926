import { lazy } from 'react'

import { PATH_BRANDING } from 'config/paths'

export const BrandingRoutes = [
    {
        id: 1,
        module: 'branding',
        protected: true,
        exact: true,
        path: PATH_BRANDING.root,
        component: lazy(() => import('containers/dashboard/app-name/AppName')),
    },
]
