import { paymentLinkTypes } from './actionTypes'
import {
    IPaymentLink,
    FetchLinksPayload,
    FetchLinksRequest,
    FetchLinkPayload,
    FetchLinkByIdRequest,
    CreateLinksPayload,
    CreateLinksRequest,
    EditLinkPayload,
    EditLinkRequest,
    DeleteLinkPayload,
    DeleteLinkRequest,
    SetLinks,
    SetLink,
} from './types'

export const fetchLinksAction = (
    payload: FetchLinksPayload,
): FetchLinksRequest => ({
    type: paymentLinkTypes.FETCH_LINKS_REQUEST,
    payload,
})

export const fetchLinkAction = (
    payload: FetchLinkPayload,
): FetchLinkByIdRequest => ({
    type: paymentLinkTypes.FETCH_LINKS_BY_ID,
    payload,
})

export const createLinksAction = (
    payload: CreateLinksPayload,
): CreateLinksRequest => ({
    type: paymentLinkTypes.CREATE_LINK_REQUEST,
    payload,
})

export const editLinkAction = (payload: EditLinkPayload): EditLinkRequest => ({
    type: paymentLinkTypes.EDIT_LINK_REQUEST,
    payload,
})

export const deleteLinkAction = (
    payload: DeleteLinkPayload,
): DeleteLinkRequest => ({
    type: paymentLinkTypes.DELETE_LINK_REQUEST,
    payload,
})

export const SetLinksAction = (payload: {
    paymentLinks: IPaymentLink[] | undefined
}): SetLinks => ({
    type: paymentLinkTypes.SET_LINKS,
    payload,
})

export const SetLinkAction = (payload: {
    paymentLink: IPaymentLink
}): SetLink => ({
    type: paymentLinkTypes.SET_LINK,
    payload,
})
