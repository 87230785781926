import { TCallBack } from 'config/types'
import { testTypes } from './actionTypes'

export interface TestState {
    tests?: ITest[]
    test?: ITest
}

export enum ETypeAnswer {
    SINGLE_CHOISE = 'SingleChoise',
    MULTIPLE_CHOICE = 'MultipleChoise',
    SHORT_ANSWER = 'ShortAnswerText',
}

export interface IAnswer {
    id: string
    answer: string
    isCorrect: boolean
}

export interface IQuestions {
    id?: string | number
    answers: IAnswer[]
    description: string
    answerType: string
    shortAnswerText?: string
    index: number
}

export interface ITest {
    id?: string
    name: string
    tests: IQuestions[]
    isAnonymous?: boolean
    applicationId?: string
    hide?: boolean
    exception?: {
        Message: string
    }
}
export interface IAddForm {
    name: string
    tests: IQuestions[]
    isAnonymous: boolean
    hidden: boolean
}

export interface FetchTestsPayload {
    appId: string
    language: string
    callback?: TCallBack
}

export interface FetchTestsRequest {
    type: typeof testTypes.FETCH_TESTS_REQUEST
    payload: FetchTestsPayload
}

export interface FetchTestPayload {
    testId: string
    language: string
    appId: string | undefined
    callback?: TCallBack
}

export interface FetchTestByIdRequest {
    type: typeof testTypes.FETCH_TEST_BY_ID
    payload: FetchTestPayload
}

export interface CreateTestPayload {
    test: ITest
    appId: string
    language: string
    callback?: TCallBack
}

export interface CreateTestRequest {
    type: typeof testTypes.CREATE_TEST_REQUEST
    payload: CreateTestPayload
}

export interface EditTestPayload {
    testId: string
    test: ITest
    appId: string | undefined
    language: string
    callback?: TCallBack
}

export interface EditTestRequest {
    type: typeof testTypes.EDIT_TEST_REQUEST
    payload: EditTestPayload
}

export interface DeleteTestPayload {
    testId: string | undefined
    appId: string | undefined
    language: string
    callback?: TCallBack
}

export interface DeleteTestRequest {
    type: typeof testTypes.DELETE_TEST_REQUEST
    payload: DeleteTestPayload
}

export interface SetTests {
    type: typeof testTypes.SET_TESTS
    payload: { tests: ITest[] | undefined }
}

export interface SetTest {
    type: typeof testTypes.SET_TEST
    payload: { test: ITest | undefined }
}

export type TestActions =
    | FetchTestsRequest
    | FetchTestByIdRequest
    | CreateTestRequest
    | EditTestRequest
    | DeleteTestRequest
    | SetTests
    | SetTest
