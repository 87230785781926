import * as Yup from 'yup'
import { MAIN_REGEX, SOCIAL_MEDIA_REGEX } from 'config/regex'

export const loginSchema = Yup.object().shape({
    username: Yup.string()
        .required('auth:emailRequired')
        .matches(SOCIAL_MEDIA_REGEX.chat.email, 'auth:emailInvalid'),
    password: Yup.string()
        .required('auth:passwordRequired')
        .matches(MAIN_REGEX.passwordRegex, 'auth:passwordInvalid'),
})

export const registerSchema = Yup.object().shape({
    email: Yup.string()
        .required('auth:emailRequired')
        .matches(SOCIAL_MEDIA_REGEX.chat.email, 'auth:emailInvalid'),
    password: Yup.string()
        .required('auth:passwordRequired')
        .matches(MAIN_REGEX.passwordRegex, 'auth:passwordInvalid'),
    first_name: Yup.string()
        .required('auth:firstNameRequired')
        .min(2, 'auth:firstNameLength')
        .matches(MAIN_REGEX.anyLettersRegex, 'auth:lettersAllowed')
        .test('len', 'Max length is 50 chars!', val =>
            val ? val.length <= 50 : true,
        ),
    last_name: Yup.string()
        .required('auth:lastNameRequired')
        .min(2, 'auth:lastNameLength')
        .matches(MAIN_REGEX.anyLettersRegex, 'auth:lettersAllowed')
        .test('len', 'Max length is 50 chars!', val =>
            val ? val.length <= 50 : true,
        ),
})

export const emailSchema = Yup.object().shape({
    email: Yup.string()
        .required('auth:emailRequired')
        .matches(SOCIAL_MEDIA_REGEX.chat.email, 'auth:emailInvalid'),
})

export const recoverySchema = Yup.object().shape({
    newPassword: Yup.string()
        .required('auth:passwordRequired')
        .matches(MAIN_REGEX.passwordRegex, 'auth:passwordInvalid'),
})
