import { eReaderTypes } from './actionTypes'
import {
    IEReader,
    FetchEReaderByIdRequest,
    FetchEReaderPayload,
    FetchEReadersPayload,
    FetchEReadersRequest,
    CreateEReadersRequest,
    CreateEReadersPayload,
    EditEReaderRequest,
    EditEReaderPayload,
    DeleteEReaderRequest,
    DeleteEReaderPayload,
    SetEReaders,
    SetEReader,
} from './types'

export const fetchEReadersAction = (
    payload: FetchEReadersPayload,
): FetchEReadersRequest => ({
    type: eReaderTypes.FETCH_E_READERS_REQUEST,
    payload,
})

export const fetchEReaderAction = (
    payload: FetchEReaderPayload,
): FetchEReaderByIdRequest => ({
    type: eReaderTypes.FETCH_E_READER_BY_ID,
    payload,
})

export const createEReadersAction = (
    payload: CreateEReadersPayload,
): CreateEReadersRequest => ({
    type: eReaderTypes.CREATE_E_READERS_REQUEST,
    payload,
})

export const editEReaderAction = (
    payload: EditEReaderPayload,
): EditEReaderRequest => ({
    type: eReaderTypes.EDIT_E_READER_REQUEST,
    payload,
})

export const deleteEReaderAction = (
    payload: DeleteEReaderPayload,
): DeleteEReaderRequest => ({
    type: eReaderTypes.DELETE_E_READER_REQUEST,
    payload,
})

export const SetEReadersAction = (payload: {
    eReaders: IEReader[] | undefined
}): SetEReaders => ({
    type: eReaderTypes.SET_E_READERS,
    payload,
})

export const SetEReaderAction = (payload: {
    eReader?: IEReader
}): SetEReader => ({
    type: eReaderTypes.SET_E_READER,
    payload,
})
