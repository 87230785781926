import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { accountTypes } from './actionTypes'
import { getSettings, editSettings } from '../service/account'

import { ISettings, FetchSettingsRequest, EditSettingsRequest } from './types'

function* fetchSettingsSaga(action: FetchSettingsRequest) {
    try {
        const response: AxiosResponse<ISettings> = yield call(getSettings)
        if (response.data) {
            yield put({
                type: accountTypes.SET_SETTINGS,
                payload: { settings: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editSettingsSaga(action: EditSettingsRequest) {
    try {
        const response: AxiosResponse<ISettings> = yield call(
            editSettings,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* accountSettingsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(accountTypes.FETCH_SETTINGS_REQUEST, fetchSettingsSaga),
    ])
    yield all([
        takeLatest(accountTypes.EDIT_SETTINGS_REQUEST, editSettingsSaga),
    ])
}

export default accountSettingsSaga
