import React from 'react'

const TaskHigh = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        className='m-task__high'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect width='22' height='22' fill='white' fillOpacity='0.01' />
        <path
            d='M11.0154 10.2502L5.09928 13.7931C4.99594 13.8567 4.88101 13.8992 4.76115 13.918C4.64129 13.9368 4.51888 13.9317 4.40102 13.9029C4.28316 13.874 4.17219 13.8221 4.07456 13.7501C3.97692 13.678 3.89456 13.5873 3.83225 13.4832C3.76994 13.3791 3.72892 13.2636 3.71157 13.1435C3.69422 13.0235 3.70088 12.9011 3.73117 12.7836C3.76146 12.6661 3.81477 12.5558 3.88801 12.4591C3.96126 12.3624 4.05298 12.2811 4.15786 12.2201L10.547 8.39394C10.6898 8.3087 10.8532 8.26392 11.0195 8.26441C11.1858 8.26489 11.3489 8.31062 11.4912 8.39669L17.8217 12.2219C18.0298 12.3478 18.1794 12.5511 18.2376 12.7872C18.2958 13.0233 18.2578 13.2729 18.132 13.481C18.0062 13.6891 17.8028 13.8387 17.5667 13.8969C17.3306 13.9551 17.081 13.9171 16.8729 13.7913L11.0154 10.2502Z'
            fill='#FF5630'
        />
    </svg>
)

export default TaskHigh
