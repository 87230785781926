import { forumThemeTypes } from './actionTypes'
import { ThemeState, ThemeActions } from './types'

const initialState: ThemeState = {
    themes: undefined,
    theme: undefined,
    users: undefined,
    user: undefined,
}

export default (state = initialState, action: ThemeActions): ThemeState => {
    switch (action.type) {
        case forumThemeTypes.SET_THEMES:
            return {
                ...state,
                themes: action.payload.themes,
            }
        case forumThemeTypes.SET_THEME:
            return {
                ...state,
                theme: action.payload.theme,
            }
        case forumThemeTypes.SET_FORUM_USERS:
            return {
                ...state,
                users: action.payload.users,
            }
        default:
            return {
                ...state,
            }
    }
}
