import React, { LegacyRef } from 'react'

import { Skeleton } from '@mui/material'
import { useClampText } from 'use-clamp-text/lib'
import parse from 'html-react-parser'

interface IClampedText {
    text: string
    lines?: number
}

const ClampedText = ({ text, lines }: IClampedText): React.ReactElement => {
    const [ref, { clampedText, key }] = useClampText({
        text,
        lines,
        ellipsis: '...',
        expanded: false,
    })
    return (
        <div
            style={{ wordBreak: 'break-all' }}
            key={key}
            ref={ref as LegacyRef<HTMLDivElement> | undefined}
        >
            {clampedText === '.' ? <Skeleton /> : parse(clampedText)}
        </div>
    )
}

ClampedText.defaultProps = {
    lines: 2,
}

export default ClampedText
