import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    // select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { testTypes } from './actionTypes'
import {
    getBuildDetails,
    createBuild,
    getAppleSettings,
    putAppleSettings,
} from '../service/test'

import {
    IBuild,
    IAppleSettings,
    GetAndroidAabDetailsRequest,
    GetAndroidApkDetailsRequest,
    GetAppleIpaDetailsRequest,
    GetAppleSettingsRequest,
    CreateAndroidApkBuildRequest,
    CreateAndroidAabBuildRequest,
    CreateAppleIpaBuildRequest,
    UpdateAppleSettingsRequest,
} from './types'

function* getAndroidApkDetailsSaga(action: GetAndroidApkDetailsRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            getBuildDetails,
            action.payload,
        )
        if (!response.data) return
        if (action.payload.callback) {
            action.payload.callback('success')
        }
        yield put({
            type: testTypes.SET_ANDROID_APK_DETAILS,
            payload: { buildDetails: response.data },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* getAndroidAabDetailsSaga(action: GetAndroidAabDetailsRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            getBuildDetails,
            action.payload,
        )
        if (!response.data) return
        if (action.payload.callback) {
            action.payload.callback('success')
        }
        yield put({
            type: testTypes.SET_ANDROID_AAB_DETAILS,
            payload: { buildDetails: response.data },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* getAppleIpaDetailsSaga(action: GetAppleIpaDetailsRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            getBuildDetails,
            action.payload,
        )
        if (!response.data) return
        if (action.payload.callback) {
            action.payload.callback('success')
        }
        yield put({
            type: testTypes.SET_APPLE_IPA_DETAILS,
            payload: { buildDetails: response.data },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* getAppleSettingsSaga(action: GetAppleSettingsRequest) {
    try {
        const response: AxiosResponse<IAppleSettings> = yield call(
            getAppleSettings,
            action.payload,
        )
        if (!response.data) return
        if (action.payload.callback) {
            action.payload.callback('success')
        }
        yield put({
            type: testTypes.SET_APPLE_SETTINGS,
            payload: { appleSettings: response.data },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createAndroidApkBuildSaga(action: CreateAndroidApkBuildRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            createBuild,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
        yield put({
            type: testTypes.SET_ANDROID_APK_DETAILS,
            payload: { buildDetails: { status: 'Requested' } },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createAndroidAabBuildSaga(action: CreateAndroidAabBuildRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            createBuild,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
        yield put({
            type: testTypes.SET_ANDROID_AAB_DETAILS,
            payload: { buildDetails: { status: 'Requested' } },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* updateAppleSettingsSaga(action: UpdateAppleSettingsRequest) {
    try {
        const response: AxiosResponse<IAppleSettings> = yield call(
            putAppleSettings,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createAppleIpaBuildSaga(action: CreateAppleIpaBuildRequest) {
    try {
        const response: AxiosResponse<IBuild> = yield call(
            createBuild,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
        yield put({
            type: testTypes.SET_APPLE_IPA_DETAILS,
            payload: { buildDetails: { status: 'Requested' } },
        })
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* testSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(testTypes.GET_ANDROID_APK_DETAILS, getAndroidApkDetailsSaga),
    ])
    yield all([
        takeLatest(testTypes.GET_ANDROID_AAB_DETAILS, getAndroidAabDetailsSaga),
    ])
    yield all([
        takeLatest(testTypes.GET_APPLE_IPA_DETAILS, getAppleIpaDetailsSaga),
    ])
    yield all([takeLatest(testTypes.GET_APPLE_SETTINGS, getAppleSettingsSaga)])
    yield all([
        takeLatest(
            testTypes.CREATE_ANDROID_APK_BUILD,
            createAndroidApkBuildSaga,
        ),
    ])
    yield all([
        takeLatest(
            testTypes.CREATE_ANDROID_AAB_BUILD,
            createAndroidAabBuildSaga,
        ),
    ])
    yield all([
        takeLatest(testTypes.CREATE_APPLE_IPA_BUILD, createAppleIpaBuildSaga),
    ])
    yield all([
        takeLatest(testTypes.UPDATE_APPLE_SETTINGS, updateAppleSettingsSaga),
    ])
}

export default testSaga
