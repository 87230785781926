/* eslint-disable max-len */
import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
// import { RootState } from 'redux/reducers/rootReducer'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import {
    fetchLinksAction,
    SetLinksAction,
} from 'containers/dashboard/features/billing/store/actions'

// import { IPaymentLink } from 'containers/dashboard/features/billing/store/types'

import './BillingPage.scss'

const BillingPage = (): React.ReactElement => {
    const { t } = useTranslation(['emulator', 'toast'])
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    // const { paymentLinks } = useSelector((state: RootState) => state.paymentLinks)

    const paymentLinks = [
        {
            id: '1',
            title: 'Community Support Fund: Providing Material Assistance to Those in Need',
            link: 'https://googledrive.com/dmf/ksm/ffm/dskl/fdsafadf/afdsfads/fdasfds/fadsgfd/gfzg/gzfgfgfsd',
            description:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation venia',
            imageUrl:
                'https://ymsadev.blob.core.windows.net/51516cd1-bb14-4aeb-9912-0c0c4eda3ae1/blog/g21a7da1317b48e3fb72ab9662a79a132562befd87042640443bfb4a9901a609e5255333111aa74d1966369cc4015706ffe533535e940938c0459ece4cb4b75dc_640_202310200843305327.jpg',
        },
        {
            id: '2',
            title: 'Community Support Fund: Providing Material Assistance to Those in Need',
            link: 'https://googledrive.com/dmf/ksm/ffm/dskl/fdsafadf/afdsfads/fdasfds/fadsgfd/gfzg/gzfgfgfsd',
            description:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation venia',
            imageUrl:
                'https://ymsadev.blob.core.windows.net/51516cd1-bb14-4aeb-9912-0c0c4eda3ae1/blog/g21a7da1317b48e3fb72ab9662a79a132562befd87042640443bfb4a9901a609e5255333111aa74d1966369cc4015706ffe533535e940938c0459ece4cb4b75dc_640_202310200843305327.jpg',
        },
        {
            id: '3',
            title: 'Community Support Fund: Providing Material Assistance to Those in Need',
            link: 'https://googledrive.com/dmf/ksm/ffm/dskl/fdsafadf/afdsfads/fdasfds/fadsgfd/gfzg/gzfgfgfsd',
            description:
                'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation venia',
            imageUrl:
                'https://ymsadev.blob.core.windows.net/51516cd1-bb14-4aeb-9912-0c0c4eda3ae1/blog/g21a7da1317b48e3fb72ab9662a79a132562befd87042640443bfb4a9901a609e5255333111aa74d1966369cc4015706ffe533535e940938c0459ece4cb4b75dc_640_202310200843305327.jpg',
        },
    ]

    useEffect(() => {
        if (pathname.includes('loader') && appId) {
            dispatch(
                fetchLinksAction({
                    appId,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }

        return () => {
            if (pathname.includes('loader')) {
                dispatch(
                    SetLinksAction({
                        paymentLinks: undefined,
                    }),
                )
            }
        }
    }, [appId, dispatch, pathname, t])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return (
        <div className='m-billing__wrapper'>
            {paymentLinks ? (
                paymentLinks.map(item => (
                    <button
                        type='button'
                        key={item.id}
                        className='m-billing new-loader--surface'
                        onClick={() =>
                            push(`${PATH_NEW_LOADER.pages.billing}/${item.id}`)
                        }
                    >
                        <CustomImage
                            src={item.imageUrl}
                            className='m-billing__image'
                        />
                        <div className='m-billing__info'>
                            <div className='m-billing__title new-loader--text'>
                                {item.title}
                            </div>
                            <div className='m-billing__desc new-loader--text'>
                                {item.description.replace(
                                    regexForHtmlCharts,
                                    ' ',
                                )}
                            </div>
                        </div>
                    </button>
                ))
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default BillingPage
