import React, { ReactNode } from 'react'

import './Overlay.scss'

interface IOverlay {
    children: ReactNode
    topLevel?: boolean
    center?: boolean
}

const Overlay = ({
    children,
    topLevel,
    center,
}: IOverlay): React.ReactElement => (
    <div className={`m-overlay ${topLevel ? 'm-overlay--top' : ''}`}>
        <div
            className={`m-overlay__wrapper ${
                center ? 'm-overlay__wrapper--center' : ''
            }`}
        >
            {children}
        </div>
    </div>
)

export default Overlay
