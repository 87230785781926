import CustomButton from 'components/custom-button/CustomButton'
import React, { useEffect, useMemo, useRef } from 'react'

import './CloudTabs.scss'
import { useTranslation } from 'react-i18next'

interface ICloudTab {
    name: string
    type: string
}

interface IProps {
    cloudTabs: ICloudTab[]
    activeTab: string
    setActiveTab: (name: string) => void
    emulatorType?: boolean
}

export const CloudTabs = ({
    cloudTabs,
    activeTab,
    setActiveTab,
    emulatorType,
}: IProps): React.ReactElement => {
    const { t } = useTranslation('cloud')
    const btnWrapperRef = useRef<HTMLDivElement>(null)

    const buttonsRef: Array<HTMLButtonElement | null> = useMemo(() => [], [])

    const setBtnCentered = (target: HTMLButtonElement) => {
        const paddingsSum = 25
        const { clientWidth, offsetLeft } = target

        if (clientWidth && offsetLeft && !!btnWrapperRef?.current) {
            const parentWidth = btnWrapperRef.current?.offsetWidth

            const scrollLeft =
                offsetLeft - paddingsSum - (parentWidth - clientWidth) / 2

            btnWrapperRef.current.scrollTo({
                behavior: 'smooth',
                left: scrollLeft,
            })
        }
    }

    const handleTabChange = (
        name: string,
        e?: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setActiveTab(name)
        if (e) setBtnCentered(e.currentTarget)
    }
    useEffect(() => {
        const activeBtn = buttonsRef.find(btn =>
            btn?.classList.contains('active'),
        )

        if (activeBtn) setBtnCentered(activeBtn)
    }, [activeTab, buttonsRef])

    return (
        <div
            className={`${
                emulatorType ? 'cloud-tabs__emulator' : 'cloud-tabs'
            }`}
        >
            <div className='cloud-tabs__wrapper' ref={btnWrapperRef}>
                {!emulatorType
                    ? cloudTabs.map(tab => (
                          <CustomButton
                              stackClassName='cloud-tabs__button'
                              size='small'
                              key={tab.name}
                              design={`${
                                  activeTab === tab.type ? 'secondary' : 'white'
                              }`}
                              handleClick={() => handleTabChange(tab.type)}
                          >
                              {t(`cloud:tabNames:${tab.type}`)}
                          </CustomButton>
                      ))
                    : cloudTabs.map(tab => (
                          <button
                              // eslint-disable-next-line max-len
                              className={`new-loader--bottom-border new-loader--primary-color cloud-tabs__button ${
                                  activeTab === tab.type ? 'active' : ''
                              }`}
                              type='button'
                              key={tab.name}
                              ref={node => buttonsRef.push(node)}
                              onClick={e => handleTabChange(tab.type, e)}
                          >
                              {t(`cloud:tabNames:${tab.type}`)}
                          </button>
                      ))}
            </div>
        </div>
    )
}

export default CloudTabs
