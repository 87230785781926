import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { GALLERY_URLS } from 'config/urls'

import { IGalleryItem } from '../store/types'

export const getGallery = ({
    language,
    appId,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IGalleryItem>> =>
    axiosInstance.get(`${appId}${GALLERY_URLS.gallery}/${language}`)
// axiosInstance.get(`${appId}${GALLERY_URLS.gallery}`)

export const getGalleryItem = ({
    language,
    galleryItemId,
    appId,
}: {
    galleryItemId: string
    appId: string | undefined
    language: string
}): Promise<AxiosResponse<IGalleryItem>> =>
    axiosInstance.get(
        `${appId}${GALLERY_URLS.gallery}/${language}/${galleryItemId}`,
    )
// axiosInstance.get(`${appId}${GALLERY_URLS.gallery}/${galleryItemId}`)

export const createGalleryItem = ({
    language,
    galleryItem,
    appId,
}: {
    galleryItem: IGalleryItem
    appId: string
    language: string
}): Promise<AxiosResponse<IGalleryItem>> =>
    axiosInstance.post(
        `${appId}${GALLERY_URLS.gallery}/${language}`,
        galleryItem,
    )
// axiosInstance.post(`${appId}${GALLERY_URLS.gallery}`, galleryItem)

export const editGalleryItem = ({
    language,
    galleryItemId,
    galleryItem,
    appId,
}: {
    galleryItem: IGalleryItem
    galleryItemId: string
    appId: string
    language: string
}): Promise<AxiosResponse<IGalleryItem>> =>
    axiosInstance.put(
        `${appId}${GALLERY_URLS.gallery}/${language}/${galleryItemId}`,
        galleryItem,
    )
// axiosInstance.put(
// `${appId}${GALLERY_URLS.gallery}/${galleryItemId}`,
// galleryItem,
// )

export const deleteGalleryItem = ({
    galleryItemId,
    appId,
}: {
    galleryItemId: string
    appId: string
}): Promise<AxiosResponse<IGalleryItem>> =>
    axiosInstance.delete(`${appId}${GALLERY_URLS.gallery}/${galleryItemId}`)
