import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const CalendarRoutes = [
    {
        id: 1,
        module: 'calendar',
        protected: true,
        exact: true,
        path: PATH_FEATURES.calendar.root,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/calendar/calendar-view/Calendar'
                ),
        ),
    },
    {
        id: 2,
        module: 'calendar',
        protected: true,
        exact: true,
        path: PATH_FEATURES.calendar.createEvent,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/calendar/event-add/EventAdd'
                ),
        ),
    },
    {
        id: 3,
        module: 'calendar',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.calendar.editEvent}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/calendar/event-edit/EventEdit'
                ),
        ),
    },
    {
        id: 4,
        module: 'calendar',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.calendar.proposed}`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/calendar/calendar-proposed/CalendarProposed'
                ),
        ),
    },
]
