import React from 'react'

import { useHistory } from 'react-router-dom'

import CustomImage from 'components/custom-image/CustomImage'

type TData = {
    id: string
    name: string
    url: string
}

interface IData {
    item: TData
    path: string
}

const GalleryItem = ({ item, path }: IData): React.ReactElement => {
    const { push } = useHistory()

    return (
        <button
            type='button'
            className='new-loader--surface m-gallery__item'
            onClick={() => push(`${path}/${item.id}`)}
            key={item.id}
        >
            <CustomImage src={item.url} className='m-gallery__image' />
            <div className='new-loader--text m-gallery__desc'>{item.name}</div>
        </button>
    )
}

export default GalleryItem
