import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getAppIdCookie } from 'utils/token'
import { TError } from 'config/types'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import {
    fetchLocationsAction,
    SetLocationsAction,
} from 'containers/dashboard/features/map/store/actions'

import { RootState } from 'redux/reducers/rootReducer'

import { PATH_NEW_LOADER } from 'config/paths'

import './MapPage.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

const MapPage = () => {
    const { t, i18n } = useTranslation(['toast'])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()

    const { pathname } = useLocation()
    const { locations } = useSelector((state: RootState) => state.map)

    useEffect(() => {
        if (
            appId &&
            (pathname.includes('loader') ||
                pathname.includes('map/create') ||
                pathname.includes('map/edit'))
        ) {
            setIsLoading(true)
            dispatch(
                fetchLocationsAction({
                    appId,
                    language: i18n.language,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                        setIsLoading(false)
                    },
                }),
            )
        }

        return () => {
            dispatch(
                SetLocationsAction({
                    locations: undefined,
                }),
            )
        }
    }, [appId, dispatch, t, i18n.language, pathname])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return !isLoading ? (
        locations && locations.length > 0 ? (
            <div className='m-map__wrapper'>
                {locations?.map(item => (
                    <button
                        type='button'
                        key={item.id}
                        className='m-map new-loader--surface'
                        onClick={() =>
                            push(`${PATH_NEW_LOADER.pages.map}/${item.id}`)
                        }
                    >
                        <CustomImage
                            src={item.imageUrl}
                            className='m-map__image'
                        />
                        <div className='m-map__info'>
                            <div className='m-map__title new-loader--text'>
                                {item.name}
                            </div>
                            <div className='m-map__address new-loader--text'>
                                {item.address}
                            </div>
                            <div className='m-map__desc new-loader--text'>
                                {item.description.replace(
                                    regexForHtmlCharts,
                                    ' ',
                                )}
                            </div>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-map__read-more new-loader--primary-color'>
                                {t('emulator:readMore')}
                            </div>
                        </div>
                    </button>
                ))}
            </div>
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}
export default MapPage as React.FC
