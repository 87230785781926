import React from 'react'

export const AnalyticsIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_5214_62050)'>
            <path
                d='M25.7852 0C25.0409 0 24.4375 0.603391 24.4375 1.34766V20.3047C24.4375 21.049 25.0409 21.6523 25.7852 21.6523H44.6523C45.3966 21.6523 46 21.049 46 20.3047C46 9.15283 36.9503 0 25.7852 0ZM27.1328 18.957V2.74688C35.7233 3.40679 42.6007 10.3215 43.2539 18.957H27.1328Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M28.2821 42.9808L21.7422 25.4521V6.82812C21.7422 6.08386 21.1388 5.48047 20.3945 5.48047C9.29667 5.48047 0 14.4781 0 25.6953C0 36.8287 9.20656 46 20.3945 46C22.8361 46 25.2248 45.5671 27.4941 44.7133C28.1893 44.4517 28.5418 43.6768 28.2821 42.9808ZM20.3945 43.3047C10.6352 43.3047 2.69531 35.4052 2.69531 25.6953C2.69531 16.4837 9.91462 8.90971 19.0469 8.22609V25.6953C19.0469 25.8561 19.0756 26.0157 19.1319 26.1665L25.269 42.6153C23.6917 43.0732 22.0577 43.3047 20.3945 43.3047Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M44.6523 24.3477H25.7851C25.3436 24.3477 24.9299 24.564 24.6782 24.9268C24.4263 25.2896 24.3682 25.7526 24.5226 26.1665L31.1476 43.9231C31.4092 44.624 32.1898 44.9748 32.8848 44.7133C40.7294 41.762 46 34.1192 46 25.6953C46 24.951 45.3966 24.3477 44.6523 24.3477ZM33.1818 41.665L27.7263 27.034H43.2527C42.7653 33.3488 38.8853 38.9914 33.1818 41.665Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
        </g>
        <defs>
            <clipPath id='clip0_5214_62050'>
                <rect width='46' height='46' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default AnalyticsIcon
