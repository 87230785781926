import React, { ChangeEventHandler } from 'react'

import './LoaderInput.scss'

interface ILoaderInput {
    onChange?: ChangeEventHandler<HTMLInputElement>
    value: string
    name: string
    placeholder?: string
    className?: string
    error?: string
    touched?: boolean
}

const LoaderInput = ({
    onChange,
    value,
    name,
    placeholder,
    className,
    error,
    touched,
}: ILoaderInput): React.ReactElement => (
    <div>
        <input
            type='text'
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            className={`new-loader_input new-loader--surface
            new-loader--text
            ${className}`}
            autoComplete='off'
        />
        {touched && error && (
            <div className='new-loader_input__error-message'>
                {touched && error}
            </div>
        )}
    </div>
)

export default LoaderInput
