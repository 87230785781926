import React from 'react'

import CircularProgress from '@mui/material/CircularProgress'

import './CustomButton.scss'
import { Stack } from '@mui/material'

interface ICustomButton {
    design:
        | 'primary'
        | 'secondary'
        | 'cancel'
        | 'delete'
        | 'disabled'
        | 'grey'
        | 'accept'
        | 'reject'
        | 'white'
    type?: 'button' | 'submit'
    size?: 'small' | 'medium' | 'large'
    loading?: boolean
    disabled?: boolean
    children: React.ReactNode
    handleClick?: (evt: React.MouseEvent) => void
    onKeyPress?: () => void
    notification?: string | number
    stackClassName?: string
}

export const CustomButton = ({
    design,
    type,
    size,
    disabled,
    children,
    loading,
    handleClick,
    onKeyPress,
    notification,
    stackClassName,
    ...props
}: ICustomButton): React.ReactElement => {
    const classes = [
        'custom-button',
        `custom-button-design--${design}`,
        `custom-button-size--${size}`,
    ]

    return (
        <div className={`${stackClassName} custom-button__wrapper`}>
            <button
                {...props}
                // TODO
                // eslint-disable-next-line react/button-has-type
                type={type}
                disabled={loading || disabled}
                className={classes.join(' ')}
                onClick={handleClick}
                onKeyPress={onKeyPress}
            >
                {loading ? (
                    <CircularProgress
                        color={design === 'primary' ? 'primary' : 'secondary'}
                        size='22px'
                    />
                ) : (
                    children
                )}
            </button>
            {notification && (
                <Stack
                    position='absolute'
                    alignSelf='flex-end'
                    width='22px'
                    fontSize='14px'
                    padding='2px'
                    height='22px'
                    borderRadius='50%'
                    className='acrom-bold'
                    color='#ffffff'
                    alignItems='center'
                    justifyContent='center'
                    style={{ backgroundColor: '#E22C2C' }}
                    top='-5px'
                    right='-5px'
                >
                    {notification}
                </Stack>
            )}
        </div>
    )
}

CustomButton.defaultProps = {
    type: 'button',
    size: 'medium',
    loading: false,
    disabled: false,
    handleClick: undefined,
}

export default CustomButton
