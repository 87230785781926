import React from 'react'

import { useTranslation } from 'react-i18next'
import { IconButton, Stack } from '@mui/material'

import Close from 'assets/images/Close.svg'
import CookieImg from 'assets/images/Cookie.png'

import CustomImage from 'components/custom-image/CustomImage'
import CustomButton from 'components/custom-button/CustomButton'

import './Cookie.scss'

interface ICookie {
    handleClick: () => void
    handleClose: () => void
}

export const Cookie = ({
    handleClick,
    handleClose,
}: ICookie): React.ReactElement => {
    const { t } = useTranslation(['popups'])
    return (
        <div className='cookie'>
            <div className='cookie__info'>
                <Stack position='absolute' right={-20} top={-22} margin='5px'>
                    <IconButton onClick={handleClose}>
                        <Stack width='12px'>
                            <CustomImage src={Close} />
                        </Stack>
                    </IconButton>
                </Stack>
                <CustomImage src={CookieImg} className='cookie__image' />
                <div>
                    <div className='cookie__header'>
                        {t('popups:cookie:title')}
                    </div>
                    <div className='cookie__text'>
                        {t('popups:cookie:text')}{' '}
                        <a
                            // eslint-disable-next-line max-len
                            href={`${process.env.REACT_APP_PROMO_REDIRECT_URL}/cookie-policy`}
                        >
                            {t('popups:cookie:link')}
                        </a>
                    </div>
                </div>
            </div>
            <CustomButton
                design='secondary'
                handleClick={handleClick}
                size='large'
            >
                {t('popups:cookie:accept')}
            </CustomButton>
        </div>
    )
}

export default Cookie
