import React from 'react'

import { Stack } from '@mui/material'

import './MasonryColumn.scss'

const MasonryColumn = ({
    children,
}: {
    children: React.ReactNode
}): React.ReactElement => (
    <Stack spacing='16px' className='masonry-column'>
        {children}
    </Stack>
)

export default MasonryColumn
