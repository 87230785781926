import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactCalendar from 'react-calendar'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'
import moment from 'moment'

import {
    fetchEventDatesAction,
    fetchEventsByDateAction,
    SetCurrentDayAction,
    SetActiveStartDateAction,
    SetEventsAction,
} from 'containers/dashboard/features/calendar/store/actions'

import { RootState } from 'redux/reducers/rootReducer'
import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

// import AddTask from 'assets/images/new-loader/todo/AddTask'

import CalendarContent from './CalendarContent'
import CalendarItem from './components/calendar-item/CalendarItem'
import AddEventPopup from './components/add-event-popup/AddEventPopup'

import './CalendarPage.scss'

const CalendarPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])

    const cookies = useCookies(['emulator_palette'])[0]

    const dispatch = useDispatch()
    const { events, datesWithEvents, currentDay, activeRangeDate } =
        useSelector((state: RootState) => state.calendar)

    const [isAdd, setIsAdd] = useState(false)

    const appId = getAppIdCookie()

    useEffect(() => {
        if (!appId) return undefined
        const currentDate = new Date(Date.now())
        dispatch(
            fetchEventDatesAction({
                appId,
                month: moment(currentDate).format('MM'),
                year: moment(currentDate).format('YYYY'),
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
        return () => {
            dispatch(
                SetEventsAction({
                    events: [],
                }),
            )
        }
    }, [appId, dispatch, t, i18n.language])

    const handleChooseDay = (value: Date) => {
        if (!appId) return
        dispatch(
            SetCurrentDayAction({
                currentDay: moment(value).format('yyyy-MM-DD'),
            }),
        )
        dispatch(
            fetchEventsByDateAction({
                appId,
                status: 'Confirmed',
                eventDate: moment(value).format('yyyy-MM-DD'),
                language: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }

    const tileContent = ({ date }: { date: Date }) => {
        const currentDate = new Date().toJSON().slice(0, 10)
        const day = moment(date).format('D')

        return (
            <div
                className={`calendar-tile ${
                    currentDate === moment(date).format('yyyy-MM-DD')
                        ? 'calendar-tile__today'
                        : ''
                } ${
                    currentDay === moment(date).format('yyyy-MM-DD')
                        ? 'calendar-tile__active'
                        : ''
                }`}
            >
                {day}
                {datesWithEvents?.includes(
                    moment(date).format('yyyy-MM-DD'),
                ) ? (
                    <div className='calendar-tile__dot' />
                ) : null}
            </div>
        )
    }

    const onActiveStartDateChange = ({
        activeStartDate,
    }: {
        activeStartDate: Date | null
    }) => {
        if (!activeStartDate) return
        dispatch(SetActiveStartDateAction({ activeRangeDate: activeStartDate }))
        if (!appId) return
        dispatch(
            fetchEventDatesAction({
                appId,
                month: moment(activeStartDate).format('MM'),
                year: moment(activeStartDate).format('YYYY'),
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }

    const currentDateEvents =
        events &&
        currentDay &&
        events.filter(
            item =>
                item.startTime !== null &&
                moment(new Date(item.startTime)).format('MM.DD.yyyy') ===
                    moment(new Date(currentDay)).format('MM.DD.yyyy'),
        )

    return (
        <>
            <Stack padding='12px 12px 65px 12px' spacing='15px'>
                <CalendarContent palette={cookies.emulator_palette}>
                    <ReactCalendar
                        locale={i18n.language === 'en' ? 'en-GB' : 'uk-UK'}
                        activeStartDate={
                            activeRangeDate || new Date(Date.now())
                        }
                        onActiveStartDateChange={onActiveStartDateChange}
                        value={currentDay}
                        tileContent={tileContent}
                        onClickDay={handleChooseDay}
                        navigationLabel={({ label }) => {
                            if (i18n.language === 'en') {
                                return <>{label}</>
                            }
                            return label.replace('р.', '')
                        }}
                    />
                    <div className='m-calendar__events'>
                        {currentDay ? (
                            currentDateEvents && currentDateEvents.length ? (
                                currentDateEvents
                                    .filter(
                                        item =>
                                            item.startTime !== null &&
                                            moment(
                                                new Date(item.startTime),
                                            ).format('MM.DD.yyyy') ===
                                                moment(
                                                    new Date(currentDay),
                                                ).format('MM.DD.yyyy'),
                                    )
                                    .map(item => (
                                        <CalendarItem
                                            event={item}
                                            key={item.id}
                                        />
                                    ))
                            ) : (
                                <div className='m-calendar__text'>
                                    {t('emulator:noEvents')}
                                </div>
                            )
                        ) : (
                            <div className=''>{t('emulator:chooseDate')}</div>
                        )}
                    </div>
                </CalendarContent>

                {/* <Stack
                    position='absolute'
                    bottom='26px'
                    right='26px'
                    alignSelf='flex-end'
                    spacing='16px'
                    alignItems='flex-end'
                >
                    <Stack
                        onClick={() => setIsAdd(true)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <AddTask />
                    </Stack>
                </Stack> */}
            </Stack>
            {isAdd && <AddEventPopup onClose={() => setIsAdd(false)} />}
        </>
    )
}
export default CalendarPage
