import React from 'react'

import SmmItem from '../smm-item/SmmItem'

import './SmmView.scss'

type TData = {
    type: string
    description: string
    url: string
    id: string
}

interface IData {
    data: TData[]
}

const SmmView = ({ data }: IData): React.ReactElement => (
    <div className='m-smm'>
        {data.map(item => (
            <SmmItem item={item} key={item.id} />
        ))}
    </div>
)

export default SmmView
