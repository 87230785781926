import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const BlogRoutes = [
    {
        id: 1,
        module: 'blog',
        protected: true,
        exact: true,
        path: PATH_FEATURES.blog.root,
        component: lazy(
            () => import('containers/dashboard/features/blog/Blog'),
        ),
    },
    {
        id: 2,
        module: 'blog',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.blog.edit}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/blog/EditBlog'),
        ),
    },
    {
        id: 3,
        module: 'blog',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.blog.create}`,
        component: lazy(
            () => import('containers/dashboard/features/blog/CreateBlogs'),
        ),
    },
]
