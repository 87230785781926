import { generationTypes } from './actionTypes'
import { GenerationActions, GeneretionState } from './types'

const initialState: GeneretionState = {
    generations: undefined,
}

export default (
    state = initialState,
    action: GenerationActions,
): GeneretionState => {
    switch (action.type) {
        case generationTypes.SET_GENERETIONS:
            return {
                ...state,
                generations: action.payload.generations,
            }
        default:
            return {
                ...state,
            }
    }
}
