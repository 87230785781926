import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { RootState } from 'redux/reducers/rootReducer'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError } from 'config/types'

import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import { fetchInformationAction } from 'containers/dashboard/features/about/store/actions'

import './AboutPage.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

const AboutPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast', 'emulator'])
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const { information } = useSelector((state: RootState) => state.information)

    useEffect(() => {
        if (
            appId &&
            (pathname.includes('loader') || pathname.includes('about/create'))
        ) {
            dispatch(
                fetchInformationAction({
                    appId,
                    language: i18n.language,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [appId, dispatch, pathname, t, i18n.language])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return (
        <div className='m-about'>
            {information ? (
                information.length > 0 ? (
                    <>
                        {information.map((item, index) => (
                            <div key={item.id}>
                                <div>
                                    {information[index - 1] &&
                                    information[index].type ===
                                        information[index - 1].type ? (
                                        ''
                                    ) : (
                                        <div className='m-about__category'>
                                            {information[index].type ===
                                            'Company'
                                                ? t('emulator:aboutCompany')
                                                : t('emulator:meetTeem')}
                                        </div>
                                    )}
                                </div>
                                <button
                                    type='button'
                                    className='m-about__item new-loader--surface'
                                    onClick={() =>
                                        push(
                                            // eslint-disable-next-line max-len
                                            `${PATH_NEW_LOADER.pages.about}/${item.id}`,
                                        )
                                    }
                                >
                                    <CustomImage
                                        src={item.imageUrl}
                                        className='m-about__image'
                                    />
                                    <div className='m-about__info'>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-about__title new-loader--text'>
                                            {item.title}
                                        </div>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-about__desc new-loader--text'>
                                            {item.description.replace(
                                                regexForHtmlCharts,
                                                ' ',
                                            )}
                                        </div>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-about__read-more new-loader--primary-color'>
                                            {t('emulator:readMore')}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        ))}
                    </>
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default AboutPage
