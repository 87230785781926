import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const BlogRoutes = [
    {
        id: 1,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.blog,
        component: lazy(
            () => import('containers/emulator/pages/blog/blog-page/BlogPage'),
        ),
    },
    {
        id: 2,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.blog}/:id`,
        component: lazy(
            () => import('containers/emulator/pages/blog/blog-item/BlogItem'),
        ),
    },
]
