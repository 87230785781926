import React from 'react'

import { links } from 'config/socials'

import Facebook from 'assets/images/icons/follow-us/Facebook'
import Instagram from 'assets/images/icons/follow-us/Instagram'
import LinkedIn from 'assets/images/icons/follow-us/LinkedIn'
import Twitter from 'assets/images/icons/follow-us/Twitter'
import Youtube from 'assets/images/icons/follow-us/Youtube'

import './FollowUs.scss'

const FollowUs = (): React.ReactElement => (
    <div className='follow-us'>
        <div className='follow-us__title'>Follow us</div>
        <div className='follow-us__socials'>
            <button
                type='button'
                onClick={() => {
                    window.open(
                        links.instagram,
                        '_blank',
                        'noopener,noreferrer',
                    )
                }}
            >
                <Instagram />
            </button>
            <button
                type='button'
                onClick={() => {
                    window.open(links.youtube, '_blank', 'noopener,noreferrer')
                }}
            >
                <Youtube />
            </button>
            <button
                type='button'
                onClick={() => {
                    window.open(links.facebook, '_blank', 'noopener,noreferrer')
                }}
            >
                <Facebook />
            </button>
            <button
                type='button'
                onClick={() => {
                    window.open(links.twitter, '_blank', 'noopener,noreferrer')
                }}
            >
                <Twitter />
            </button>
            <button
                type='button'
                onClick={() => {
                    window.open(links.linkedin, '_blank', 'noopener,noreferrer')
                }}
            >
                <LinkedIn />
            </button>
        </div>
    </div>
)

export default FollowUs
