import React from 'react'

import './LoaderButton.scss'

interface ILoaderButton {
    type?: 'button' | 'submit'
    children: React.ReactNode
    handleClick?: (evt: React.MouseEvent) => void
    disabled?: boolean
    className?: string
}

export const LoaderButton = ({
    type,
    children,
    handleClick,
    disabled,
    className,
    ...props
}: ILoaderButton): React.ReactElement => (
    <button
        {...props}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`new-loader--primary-background
        new-loader--contrast-color new-loader-button ${className}`}
        onClick={handleClick}
        disabled={disabled}
    >
        {children}
    </button>
)

LoaderButton.defaultProps = {
    type: 'button',
}

export default LoaderButton
