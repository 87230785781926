import { lazy } from 'react'

import { PATH_NOTIFICATIONS } from 'config/paths'

export const NotificationsRoutes = [
    {
        id: 1,
        module: 'notifications',
        protected: true,
        exact: true,
        path: PATH_NOTIFICATIONS.root,
        component: lazy(() => import('containers/notifications/Notifications')),
    },
]
