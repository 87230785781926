import { AccountRoutes } from './Account.routes'
import { BillingRoutes } from './Billing.routes'
import { WelcomeRoutes } from './Welcome.routes'
import { AppListRoutes } from './AppList.routes'
import { TutorialRoutes } from './Tutorial.routes'
import { LanguageRoutes } from './Language.routes'
import { NotificationsRoutes } from './Notifications.routes'

export default [
    ...AccountRoutes,
    ...BillingRoutes,
    ...WelcomeRoutes,
    ...AppListRoutes,
    ...TutorialRoutes,
    ...LanguageRoutes,
    ...NotificationsRoutes,
]
