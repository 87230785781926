import React, { useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import { ReactComponent as MarkIcon } from 'assets/images/icons/Mark.svg'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError, IPagination } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import { fetchBookingsAction } from 'containers/dashboard/features/booking/store/actions'

import {
    IBooking,
    ILocation,
} from 'containers/dashboard/features/booking/store/types'

import './BookingPage.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'
import BookingTab from '../components/booking-tab/BookingTab'

const tabs: ['locations', 'booked'] = ['locations', 'booked']

const BookingPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])
    const [tab, setTab] = useState<'locations' | 'booked'>('locations')
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const { bookings, locations } = useSelector(
        (state: RootState) => state.booking,
    )

    const [currentLocations, setCurrentLocations] = useState<ILocation[]>([])

    useEffect(() => {
        if (
            (pathname.includes('loader') ||
                pathname.includes('booking/create') ||
                pathname.includes('booking/edit') ||
                pathname.includes('booking/management')) &&
            appId
        ) {
            dispatch(
                fetchBookingsAction({
                    appId,
                    page: 1,
                    language: i18n.language,
                    callback: (
                        type: string,
                        data: IPagination<IBooking>,
                        errorArray?: TError[],
                    ) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [appId, dispatch, pathname, t, i18n.language])

    const isActiveTab = (name: string) => name === tab

    const setNewTab = (name: 'locations' | 'booked') => {
        setTab(name)
    }

    useEffect(() => {
        if (!locations?.length) return undefined
        const bookingLocationIds = bookings?.map(booking => booking.location.id)
        if (!bookingLocationIds?.length) return undefined

        const currentBookingLocs = locations.filter(({ id }) =>
            bookingLocationIds.includes(id),
        )

        setCurrentLocations(currentBookingLocs)

        return () => {
            setCurrentLocations([])
        }
    }, [locations, i18n.language, bookings])

    const getLocation = (id?: string): ILocation | undefined => {
        if (!id) return undefined
        return currentLocations.find(location => location.id === id)
    }

    return (
        <div className='m-booking__wrapper'>
            <BookingTab<'locations' | 'booked'>
                isActiveTab={isActiveTab}
                tabs={tabs}
                setTab={setNewTab}
            />
            {tab === 'locations' ? (
                bookings ? (
                    bookings.length > 0 ? (
                        bookings.map(item => {
                            const currentLocation = getLocation(
                                item.location.id,
                            )
                            return (
                                <button
                                    type='button'
                                    key={item.id}
                                    className='m-booking new-loader--surface'
                                    onClick={() =>
                                        push(
                                            `${PATH_NEW_LOADER.pages.booking}/${item.id}`,
                                        )
                                    }
                                >
                                    <CustomImage
                                        src={currentLocation?.photoUrl}
                                        className='m-booking__image'
                                        alt={t('booking:bookingImage', {
                                            name: currentLocation?.name,
                                        })}
                                    />
                                    <div className='m-booking__info'>
                                        <div className='m-blog__title new-loader--text'>
                                            {currentLocation?.name}
                                        </div>
                                        {/* eslint-disable-next-line max-len */}
                                        <div className='m-booking__location new-loader--text'>
                                            <MarkIcon />
                                            {currentLocation?.address}
                                        </div>
                                        <div className='m-booking__amount new-loader--text'>
                                            {item.servicesCount ? (
                                                <>
                                                    <div>
                                                        {item.servicesCount}{' '}
                                                        {item.servicesCount > 1
                                                            ? t(
                                                                  'booking:services',
                                                              )
                                                            : t(
                                                                  'booking:service',
                                                              )}{' '}
                                                    </div>
                                                    &bull;
                                                </>
                                            ) : null}
                                            <div>
                                                {item.schedule.length}{' '}
                                                {item.schedule.length > 1
                                                    ? t('booking:specialists')
                                                    : t('booking:specialist')}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            )
                        })
                    ) : (
                        <EmulatorEmpty />
                    )
                ) : (
                    <Preloader />
                )
            ) : (
                <div className='m-booking__further-steps'>
                    {t('booking:bookedService:furtherSteps')}
                </div>
            )}
        </div>
    )
}

export default BookingPage
