import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { generalSettingsTypes } from './actionTypes'
import {
    getGeneralSettings,
    editGeneralSettings,
} from '../service/generalSettings'

import {
    IGeneralSettings,
    FetchGeneralSettingsRequest,
    EditGeneralSettingsRequest,
} from './types'

function* fetchGeneralSettingsSaga(action: FetchGeneralSettingsRequest) {
    try {
        const response: AxiosResponse<IGeneralSettings> = yield call(
            getGeneralSettings,
            action.payload,
        )

        if (action.payload.callback) {
            action.payload.callback('success', [], response.data)
        }

        if (response.status === 204) {
            yield put({
                type: generalSettingsTypes.SET_GENERAL_SETTINGS,
                payload: {
                    links: {
                        firebaseUrl: undefined,
                        secretKey: undefined,
                        title: undefined,
                    },
                },
            })
        }
        if (response.data) {
            yield put({
                type: generalSettingsTypes.SET_GENERAL_SETTINGS,
                payload: { links: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error || 'errorTest')
    }
}

function* editGeneralSettingsSaga(action: EditGeneralSettingsRequest) {
    try {
        const response: AxiosResponse<IGeneralSettings> = yield call(
            editGeneralSettings,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            yield put({
                type: generalSettingsTypes.SET_GENERAL_SETTINGS,
                payload: { links: action.payload.links },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* generalSettingsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            generalSettingsTypes.FETCH_GENERAL_SETTINGS_REQUEST,
            fetchGeneralSettingsSaga,
        ),
        takeLatest(
            generalSettingsTypes.EDIT_GENERAL_SETTINGS_REQUEST,
            editGeneralSettingsSaga,
        ),
    ])
}

export default generalSettingsSaga
