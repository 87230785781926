import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { chatTypes } from './actionTypes'
import {
    getChats,
    editChat,
    deleteChat,
    createChat,
    getChatById,
} from '../service/chat'

import {
    IChat,
    FetchChatsRequest,
    EditChatRequest,
    CreateChatRequest,
    FetchChatbyIdRequest,
} from './types'

function* fetchChatsSaga(action: FetchChatsRequest) {
    try {
        const response: AxiosResponse<IChat> = yield call(
            getChats,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: chatTypes.SET_CHAT_LINKS,
                payload: { chatsDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchChatSaga(action: FetchChatbyIdRequest) {
    try {
        const response: AxiosResponse<IChat> = yield call(
            getChatById,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: chatTypes.SET_RELATIVE_CHAT,
                payload: { relativeChat: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editChatSaga(action: EditChatRequest) {
    try {
        const response: AxiosResponse<IChat> = yield call(
            editChat,
            action.payload,
        )
        if (response.status === 200) {
            const { chatsDetails } = yield select(state => state.chat)
            const chatIdx = chatsDetails.findIndex(
                (chat: IChat) => chat.id === action.payload.id,
            )
            const chatCopy = [...chatsDetails]
            chatCopy[chatIdx] = { ...action.payload.item }

            yield put({
                type: chatTypes.SET_CHAT_LINKS,
                payload: {
                    chatsDetails: chatCopy,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createChatSaga(action: CreateChatRequest) {
    try {
        const response: AxiosResponse<IChat[]> = yield call(
            createChat,
            action.payload,
        )
        if (response.status === 200) {
            const { chatsDetails }: { chatsDetails: IChat[] } = yield select(
                state => state.chat,
            )
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            let newItems: IChat[] = []
            if (chatsDetails.length) {
                newItems = response.data.filter(
                    item => item.language === chatsDetails[0].language,
                )
            } else {
                newItems = response.data.filter(
                    item => item.language === action.payload.language,
                )
            }

            yield put({
                type: chatTypes.SET_CHAT_LINKS,
                payload: {
                    chatsDetails: [...chatsDetails].concat(newItems),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteChatSaga(action: EditChatRequest) {
    try {
        const response: AxiosResponse<IChat> = yield call(
            deleteChat,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { chatsDetails } = yield select(state => state.chat)
            const updatedChats = chatsDetails.filter(
                (chat: IChat) => chat.id !== action.payload.id,
            )
            yield put({
                type: chatTypes.SET_CHAT_LINKS,
                payload: { chatsDetails: updatedChats },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* chatSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(chatTypes.FETCH_CHAT_LINKS_REQUEST, fetchChatsSaga),
        takeLatest(chatTypes.EDIT_CHAT_LINKS_REQUEST, editChatSaga),
        takeLatest(chatTypes.CREATE_CHAT_LINKS_REQUEST, createChatSaga),
        takeLatest(chatTypes.DELETE_CHAT_LINKS_REQUEST, deleteChatSaga),
        takeLatest(chatTypes.FETCH_CHAT_LINK_REQUEST, fetchChatSaga),
    ])
}

export default chatSaga
