import React, { useEffect } from 'react'

import { TError } from 'config/types'
// eslint-disable-next-line max-len
import { fetchDesignLogoAction } from 'containers/dashboard/design/icon/store/actions'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'
import { getAppIdCookie } from 'utils/token'
import { ReactComponent as EmptyIcon } from 'assets/images/icons/EmptyImage.svg'
import CustomImage from 'components/custom-image/CustomImage'

import './IconPage.scss'

const IconPage = () => {
    console.log('iconPage')
    const appId = getAppIdCookie()
    const dispatch = useDispatch()
    const [t] = useTranslation(['icon', 'toast'])

    const { designLogo } = useSelector((state: RootState) => state.designLogo)

    useEffect(() => {
        if (appId)
            dispatch(
                fetchDesignLogoAction({
                    appId,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
    }, [appId, dispatch, t])

    console.log(designLogo, 'designLogo')

    return (
        <div className='m-icon__page'>
            <div className='m-icon__page-title'>{t('icon:iconPreview')}</div>
            {designLogo?.image ? (
                <div
                    className='m-icon__page-preview'
                    style={{ backgroundColor: designLogo?.backgroundColor }}
                >
                    <CustomImage src={designLogo.image} />
                </div>
            ) : (
                <div
                    className='m-icon__page-noImage'
                    style={{ backgroundColor: designLogo?.backgroundColor }}
                >
                    <EmptyIcon />
                </div>
            )}
        </div>
    )
}

export default IconPage
