import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { forumThemeTypes } from './actionTypes'
import {
    getThemes,
    getThemeById,
    createTheme,
    editTheme,
    deleteTheme,
    getForumUsers,
} from '../../services/forum'

import {
    ITheme,
    FetchThemesRequest,
    FetchThemeByIdRequest,
    CreateThemeRequest,
    EditThemeRequest,
    DeleteThemeRequest,
    FetchUsersRequest,
    IUser,
} from './types'

function* fetchThemesSaga(action: FetchThemesRequest) {
    try {
        const response: AxiosResponse<ITheme> = yield call(
            getThemes,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: forumThemeTypes.SET_THEMES,
                payload: { themes: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchThemeByIdSaga(action: FetchThemeByIdRequest) {
    try {
        const response: AxiosResponse<ITheme> = yield call(
            getThemeById,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: forumThemeTypes.SET_THEME,
                payload: { theme: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createThemeSaga(action: CreateThemeRequest) {
    try {
        const response: AxiosResponse<ITheme> = yield call(
            createTheme,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editThemeSaga(action: EditThemeRequest) {
    try {
        const response: AxiosResponse<ITheme> = yield call(
            editTheme,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteThemeSaga(action: DeleteThemeRequest) {
    try {
        const response: AxiosResponse<ITheme> = yield call(
            deleteTheme,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { themes } = yield select(state => state.themes)
            const updatedThemes = themes.filter(
                (theme: ITheme) => theme.id !== action.payload.themeId,
            )

            yield put({
                type: forumThemeTypes.SET_THEMES,
                payload: { themes: updatedThemes },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchUsersSaga(action: FetchUsersRequest) {
    try {
        const response: AxiosResponse<IUser> = yield call(
            getForumUsers,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: forumThemeTypes.SET_FORUM_USERS,
                payload: { users: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* themesSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(forumThemeTypes.FETCH_THEMES_REQUEST, fetchThemesSaga),
    ])
    yield all([
        takeLatest(forumThemeTypes.FETCH_THEME_BY_ID, fetchThemeByIdSaga),
    ])
    yield all([
        takeLatest(forumThemeTypes.CREATE_THEME_REQUEST, createThemeSaga),
    ])
    yield all([takeLatest(forumThemeTypes.EDIT_THEME_REQUEST, editThemeSaga)])
    yield all([
        takeLatest(forumThemeTypes.DELETE_THEME_REQUEST, deleteThemeSaga),
    ])
    yield all([
        takeLatest(forumThemeTypes.FETCH_FORUM_USERS_REQUEST, fetchUsersSaga),
    ])
}

export default themesSaga
