import { mapTypes } from './actionTypes'
import { MapActions, MapState } from './types'

const initialState: MapState = {
    location: undefined,
    locations: undefined,
    relativeLocation: undefined,
}

export default (state = initialState, action: MapActions): MapState => {
    switch (action.type) {
        case mapTypes.SET_LOCATION:
            return {
                ...state,
                location: action.payload.location,
            }
        case mapTypes.SET_LOCATIONS:
            return {
                ...state,
                locations: action.payload.locations,
            }

        case mapTypes.SET_RELATIVE_LOCATION:
            return {
                ...state,
                relativeLocation: action.payload.relativeLocation,
            }
        default:
            return {
                ...state,
            }
    }
}
