import React from 'react'

const TaskLow = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        className='m-task__low'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect width='22' height='22' fill='white' fillOpacity='0.01' />
        <path
            d='M10.9966 12.706L16.9127 9.1631C17.0161 9.09951 17.131 9.05706 17.2509 9.03822C17.3707 9.01938 17.4931 9.02452 17.611 9.05335C17.7289 9.08218 17.8398 9.13412 17.9375 9.20616C18.0351 9.2782 18.1175 9.36891 18.1798 9.47302C18.2421 9.57714 18.2831 9.69258 18.3004 9.81267C18.3178 9.93276 18.3111 10.0551 18.2809 10.1726C18.2506 10.2901 18.1973 10.4004 18.124 10.4971C18.0508 10.5939 17.959 10.6751 17.8542 10.7361L11.465 14.5623C11.3223 14.6476 11.159 14.6926 10.9927 14.6923C10.8263 14.6919 10.6632 14.6464 10.5208 14.5604L4.18941 10.7343C3.98817 10.6052 3.84531 10.4027 3.79129 10.1698C3.73727 9.93689 3.77637 9.69212 3.90024 9.48765C4.02412 9.28318 4.22297 9.1352 4.4544 9.07526C4.68583 9.01531 4.93152 9.04815 5.13908 9.16677L10.9966 12.7069V12.706Z'
            fill='#0065FF'
        />
    </svg>
)

export default TaskLow
