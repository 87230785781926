import React from 'react'

const TodoArchive = (): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
    >
        <path
            d='M15.375 16.5C14.7537 16.5 14.25 17.0037 14.25 17.625C14.25 18.2463 14.7537 18.75 15.375 18.75H20.625C21.2463 18.75 21.75 18.2463 21.75 17.625C21.75 17.0037 21.2463 16.5 20.625 16.5H15.375ZM4.5 7.875C4.5 6.01104 6.01104 4.5 7.875 4.5H28.125C29.9889 4.5 31.5 6.01104 31.5 7.875V10.125C31.5 11.2951 30.9045 12.3262 30 12.9317V25.875C30 28.9816 27.4816 31.5 24.375 31.5H11.625C8.51839 31.5 6 28.9816 6 25.875V12.9317C5.09552 12.3262 4.5 11.2951 4.5 10.125V7.875ZM8.25 13.5V25.875C8.25 27.7389 9.76104 29.25 11.625 29.25H24.375C26.2389 29.25 27.75 27.7389 27.75 25.875V13.5H8.25ZM7.875 6.75C7.25368 6.75 6.75 7.25368 6.75 7.875V10.125C6.75 10.7463 7.25368 11.25 7.875 11.25H28.125C28.7463 11.25 29.25 10.7463 29.25 10.125V7.875C29.25 7.25368 28.7463 6.75 28.125 6.75H7.875Z'
            fill='currentColor'
        />
    </svg>
)

export default TodoArchive
