import { testTypes } from './actionTypes'
import { BuildDetailsActions, TestState } from './types'

const initialState: TestState = {
    appleIpaDetails: undefined,
    androidApkDetails: undefined,
    androidAabDetails: undefined,
    appleSettings: undefined,
}

export default (
    state = initialState,
    action: BuildDetailsActions,
): TestState => {
    switch (action.type) {
        case testTypes.SET_ANDROID_APK_DETAILS:
            return {
                ...state,
                androidApkDetails: action.payload.buildDetails,
            }
        case testTypes.SET_ANDROID_AAB_DETAILS:
            return {
                ...state,
                androidAabDetails: action.payload.buildDetails,
            }
        case testTypes.SET_APPLE_IPA_DETAILS:
            return {
                ...state,
                appleIpaDetails: action.payload.buildDetails,
            }
        case testTypes.SET_APPLE_SETTINGS:
            return {
                ...state,
                appleSettings: action.payload.appleSettings,
            }
        default:
            return {
                ...state,
            }
    }
}
