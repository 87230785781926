import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { cloudTypes } from './actionTypes'
import {
    getCloud,
    editCloud,
    deleteCloud,
    createCloud,
    getCloudById,
} from '../service/cloud'

import {
    ICloud,
    FetchCloudRequest,
    EditCloudRequest,
    CreateCloudRequest,
    FetchCloudByIdRequest,
} from './types'

function* fetchCloudSaga(action: FetchCloudRequest) {
    try {
        const response: AxiosResponse<ICloud[]> = yield call(
            getCloud,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: cloudTypes.SET_CLOUD,
                payload: { cloudDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchCloudByIdSaga(action: FetchCloudByIdRequest) {
    try {
        const response: AxiosResponse<ICloud> = yield call(
            getCloudById,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: cloudTypes.SET_RELATIVE_CLOUD,
                payload: { relativeCloud: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editCloudSaga(action: EditCloudRequest) {
    try {
        const response: AxiosResponse<ICloud> = yield call(
            editCloud,
            action.payload,
        )
        if (response.status === 200) {
            const { cloudDetails } = yield select(state => state.cloud)
            const cloudIdx = cloudDetails.findIndex(
                (cloud: ICloud) => cloud.id === action.payload.id,
            )
            const cloudCopy = [...cloudDetails]
            cloudCopy[cloudIdx] = { ...action.payload.item }

            yield put({
                type: cloudTypes.SET_CLOUD,
                payload: {
                    cloudDetails: cloudCopy,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createCloudSaga(action: CreateCloudRequest) {
    try {
        const response: AxiosResponse<ICloud[]> = yield call(
            createCloud,
            action.payload,
        )

        if (response.status === 200) {
            const { cloudDetails }: { cloudDetails: ICloud[] } = yield select(
                state => state.cloud,
            )
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            let newItems: ICloud[] = []
            if (cloudDetails.length) {
                newItems = response.data.filter(
                    item => item.language === cloudDetails[0].language,
                )
            } else {
                newItems = response.data.filter(
                    item => item.language === action.payload.language,
                )
            }

            yield put({
                type: cloudTypes.SET_CLOUD,
                payload: {
                    cloudDetails: [...cloudDetails].concat(newItems),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteCloudSaga(action: EditCloudRequest) {
    try {
        const response: AxiosResponse<ICloud> = yield call(
            deleteCloud,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { cloudDetails } = yield select(state => state.cloud)
            const updatedCloud = cloudDetails.filter(
                (cloud: ICloud) => cloud.id !== action.payload.id,
            )
            yield put({
                type: cloudTypes.SET_CLOUD,
                payload: { cloudDetails: updatedCloud },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* cloudSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(cloudTypes.FETCH_CLOUD, fetchCloudSaga),
        takeLatest(cloudTypes.EDIT_CLOUD, editCloudSaga),
        takeLatest(cloudTypes.CREATE_CLOUD, createCloudSaga),
        takeLatest(cloudTypes.DELETE_CLOUD, deleteCloudSaga),
        takeLatest(cloudTypes.FETCH_CLOUD_BY_ID, fetchCloudByIdSaga),
    ])
}

export default cloudSaga
