import React, { useEffect, useState } from 'react'

import Preloader from 'components/preloader/Preloader'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { getAppIdCookie } from 'utils/token'
import { useHistory, useLocation } from 'react-router'

import { fetchTestsAction } from 'containers/dashboard/features/premium/survey/store/actions'
import { TError } from 'config/types'
import CustomButton from 'components/custom-button/CustomButton'

import './SurveyPage.scss'
import { PATH_NEW_LOADER } from 'config/paths'

const SurveyPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { pathname } = useLocation()
    const { push } = useHistory()
    const { tests } = useSelector((state: RootState) => state.survey)
    const dispatch = useDispatch()
    const appId = getAppIdCookie()
    const { t, i18n } = useTranslation(['toast', 'survey'])

    useEffect(() => {
        if (
            appId &&
            (pathname.includes('loader') ||
                pathname.includes('survey/create') ||
                pathname.includes('/statistic') ||
                pathname.includes('/users'))
        ) {
            setIsLoading(true)
            dispatch(
                fetchTestsAction({
                    appId,
                    language: i18n.language,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err)
                            })
                        }
                        setIsLoading(false)
                    },
                }),
            )
        }
    }, [appId, i18n, dispatch, pathname])

    return (
        <div className='m-survey'>
            {!isLoading ? (
                tests && tests.length ? (
                    <div className='m-survey__items'>
                        {tests.map(test => (
                            <div
                                className='m-survey__item new-loader--surface'
                                key={test.id || test.name}
                            >
                                <div className='m-survey__item-heading'>
                                    <div className='m-survey__item-heading__questions new-loader--text'>
                                        {test.tests.length}{' '}
                                        {t(
                                            `survey:form:${
                                                test.tests.length > 1
                                                    ? 'questions'
                                                    : 'question'
                                            }`,
                                        )}
                                    </div>
                                </div>
                                <div className='m-survey__item-body'>
                                    <div className='m-survey__item-body__name new-loader--text'>
                                        {test.name}
                                    </div>
                                </div>
                                <div className='m-survey__item-footer'>
                                    <div className='m-survey__item-footer-additionals new-loader--primary-color'>
                                        {/* {test.anonymity ? ( */}
                                        <div className='m-survey__item-additional'>
                                            {t('survey:table:anonymity')}
                                        </div>
                                        {/* ) : null} */}
                                        {test.hide ? (
                                            <div className='m-survey__item-footer-additional'>
                                                {t('survey:table:hide')}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className='m-survey__item-footer-actions'>
                                        <CustomButton
                                            design='cancel'
                                            handleClick={() =>
                                                test.id &&
                                                push(
                                                    `${PATH_NEW_LOADER.pages.survey}/${test.id}`,
                                                )
                                            }
                                        >
                                            {t('survey:start')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default SurveyPage as React.FC
