import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { GENERATION_URLS } from 'config/urls'

import { IGeneration } from '../store/types'

export const getAllGeneratedText = (): Promise<AxiosResponse<IGeneration[]>> =>
    axiosInstance.get(`${GENERATION_URLS.main}`)

export const generateText = ({
    keywords,
    prompt,
    generationSize,
    featureType,
}: {
    keywords?: string[]
    prompt: string
    generationSize: string
    featureType: string
}): Promise<AxiosResponse<IGeneration[]>> =>
    axiosInstance.post(`${GENERATION_URLS.completion}`, {
        keywords,
        prompt,
        generationSize,
        featureType,
    })
