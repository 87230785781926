export enum paymentsTypes {
    FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST',
    FETCH_PAYMENT_BY_ID = 'FETCH_PAYMENT_BY_ID',
    CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST',
    EDIT_PAYMENT_REQUEST = 'EDIT_PAYMENT_REQUEST',
    DELETE_PAYMENT_REQUEST = 'DELETE_PAYMENT_REQUEST',
    SET_PAYMENTS = 'SET_PAYMENTS',
    SET_PAYMENT = 'SET_PAYMENT',
    SET_EXPANDED_VALUE = 'SET_EXPANDED_VALUE',
}
