import React, { ReactElement } from 'react'

import { useMediaQuery } from '@mui/material'
import { useSelector } from 'react-redux'

import { RootState } from 'redux/reducers/rootReducer'

import { MenuIconRender } from './MenuIconRender'
import { INotification } from './store/types'

interface IHeaderButtonRender {
    name: string | ReactElement
    icon: string
    onClick: () => void
}

const HeaderButtonRender = ({
    name,
    icon,
    onClick,
}: IHeaderButtonRender): React.ReactElement => {
    const isMobile = useMediaQuery('(max-width:800px)')

    const { notifications } = useSelector(
        (state: RootState) => state.notifications,
    )

    const checkForUnread = (element: INotification) => element.isRead === false

    const showUnreadMessages =
        notifications && notifications.some(checkForUnread)

    return (
        <button
            type='button'
            className='header-button side-menu__text'
            onClick={onClick}
        >
            <div className='side-menu__item'>
                <div className='side-menu__icon'>
                    <MenuIconRender
                        iconName={icon}
                        isMobile={isMobile}
                        showUnread={showUnreadMessages}
                    />
                </div>
                {typeof name === 'string' ? <span>{name}</span> : <>{name}</>}
            </div>
        </button>
    )
}

export default HeaderButtonRender
