import { notificationTypes } from './actionTypes'
import {
    INotification,
    FetchNotesRequest,
    DeleteNotePayload,
    DeleteNoteRequest,
    ReadNotePayload,
    ReadNoteRequest,
    ReadAllNoteRequest,
    SetNotes,
} from './types'

export const fetchNotesAction = (payload: {
    lang: string
}): FetchNotesRequest => ({
    type: notificationTypes.FETCH_NOTIFICATIONS_REQUEST,
    payload,
})

export const deleteNoteAction = (
    payload: DeleteNotePayload,
): DeleteNoteRequest => ({
    type: notificationTypes.DELETE_NOTIFICATION,
    payload,
})

export const readNoteAction = (payload: ReadNotePayload): ReadNoteRequest => ({
    type: notificationTypes.READ_NOTIFICATION,
    payload,
})

export const readAllNoteAction = (payload: {
    lang: string
}): ReadAllNoteRequest => ({
    type: notificationTypes.READ_ALL_NOTIFICATIONS,
    payload,
})

export const SetNotesAction = (payload: {
    notifications?: INotification[]
}): SetNotes => ({
    type: notificationTypes.SET_NOTIFICATIONS,
    payload,
})
