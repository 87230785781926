import { notificationTypes } from './actionTypes'
import { NotificationActions, NoteState } from './types'

const initialState: NoteState = {
    notifications: undefined,
}

export default (
    state = initialState,
    action: NotificationActions,
): NoteState => {
    switch (action.type) {
        case notificationTypes.SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.notifications,
            }
        default:
            return {
                ...state,
            }
    }
}
