import React from 'react'

export const AppsIcon = (): React.ReactElement => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5.29687 19C4.94573 19 4.65729 18.9221 4.43155 18.7663C4.21836 18.6104 4.08041 18.4006 4.0177 18.1369C3.96754 17.8732 4.02398 17.5795 4.18701 17.2559L9.0027 7.16874C9.20336 6.76118 9.44163 6.4675 9.71753 6.28769C9.99343 6.0959 10.3132 6 10.6769 6C11.0406 6 11.3604 6.0959 11.6363 6.28769C11.9122 6.4675 12.1442 6.76118 12.3323 7.16874L17.1856 17.2559C17.3487 17.5795 17.4051 17.8792 17.3549 18.1549C17.3173 18.4186 17.1919 18.6284 16.9787 18.7842C16.7655 18.9281 16.4896 19 16.151 19C15.7246 19 15.3923 18.9041 15.154 18.7123C14.9283 18.5205 14.7276 18.2208 14.552 17.8133L13.4234 15.314L14.5897 16.0871H6.74535L7.91165 15.314L6.80178 17.8133C6.61367 18.2328 6.41301 18.5385 6.19982 18.7303C5.99916 18.9101 5.69818 19 5.29687 19ZM10.6393 9.12863L8.23144 14.6127L7.72353 13.9115H13.6115L13.1036 14.6127L10.6769 9.12863H10.6393Z'
            fill='currentColor'
        />
        <path
            d='M20.5515 19C20.075 19 19.7113 18.8741 19.4605 18.6224C19.2097 18.3707 19.0842 18.0171 19.0842 17.5615V7.43845C19.0842 6.98294 19.2097 6.62932 19.4605 6.37759C19.7113 6.12586 20.075 6 20.5515 6C21.0155 6 21.373 6.12586 21.6238 6.37759C21.8746 6.62932 22 6.98294 22 7.43845V17.5615C22 18.0171 21.8746 18.3707 21.6238 18.6224C21.3855 18.8741 21.0281 19 20.5515 19Z'
            fill='currentColor'
        />
    </svg>
)

export default AppsIcon
