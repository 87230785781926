import React from 'react'

export const VideoIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.5652 42H7.47826C4.4527 42 2 39.68 2 36.8182V17.8182C2 14.9563 4.4527 12.6364 7.47826 12.6364H8.8732C8.00573 11.7196 7.47826 10.509 7.47826 9.18182C7.47826 6.31998 9.93096 4 12.9565 4C14.862 4 16.5403 4.92021 17.5217 6.31645C18.5032 4.92021 20.1815 4 22.087 4C25.1125 4 27.5652 6.31998 27.5652 9.18182C27.5652 10.509 27.0377 11.7196 26.1703 12.6364H27.5652C30.5908 12.6364 33.0435 14.9563 33.0435 17.8182V18.7498L41.7477 16.6022C42.896 16.3415 44 17.1648 44 18.2818V36.3687C44 37.4812 42.9044 38.3036 41.759 38.0508L33.0435 35.9606V36.8182C33.0435 39.68 30.5908 42 27.5652 42ZM27.5652 14.3636H7.47826C5.46122 14.3636 3.82609 15.9103 3.82609 17.8182V36.8182C3.82609 38.7261 5.46122 40.2727 7.47826 40.2727H27.5652C29.5823 40.2727 31.2174 38.7261 31.2174 36.8182V35.5227V19.2003V17.8182C31.2174 15.9103 29.5823 14.3636 27.5652 14.3636ZM41.0585 18.4897L33.0435 20.409V34.2272L41.0585 36.1465C41.6299 36.2693 42.1739 35.8586 42.1739 35.3044V19.3319C42.1739 18.7777 41.6299 18.3669 41.0585 18.4897ZM12.9565 12.6364C14.9736 12.6364 16.6087 11.0897 16.6087 9.18182C16.6087 7.27393 14.9736 5.72727 12.9565 5.72727C10.9395 5.72727 9.30435 7.27393 9.30435 9.18182C9.30435 11.0897 10.9395 12.6364 12.9565 12.6364ZM22.087 12.6364C24.104 12.6364 25.7391 11.0897 25.7391 9.18182C25.7391 7.27393 24.104 5.72727 22.087 5.72727C20.0699 5.72727 18.4348 7.27393 18.4348 9.18182C18.4348 11.0897 20.0699 12.6364 22.087 12.6364ZM18.0036 12.6364H17.0398C17.2148 12.4514 17.376 12.2546 17.5217 12.0472C17.6675 12.2546 17.8287 12.4514 18.0036 12.6364Z'
            fill='currentColor'
        />
        <path
            d='M7.47826 36.8182H27.5652C28.0695 36.8182 28.4783 37.2048 28.4783 37.6818C28.4783 38.1588 28.0695 38.5455 27.5652 38.5455H7.47826C6.974 38.5455 6.56522 38.1588 6.56522 37.6818C6.56522 37.2048 6.974 36.8182 7.47826 36.8182Z'
            fill='currentColor'
        />
    </svg>
)

export default VideoIcon
