import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { E_READER_URLS } from 'config/urls'

import { IEReader, IEReaderMultiLang } from '../store/types'

export const getEReaders = ({
    language,
    appId,
    page,
}: {
    appId: string
    page: number
    language: string
}): Promise<AxiosResponse<IEReader>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${E_READER_URLS.main}/language/${language}?pageNumber=${page}&pageSize=10`,
    )

export const getEReader = ({
    eReaderId,
    appId,
}: {
    eReaderId: string
    appId: string
}): Promise<AxiosResponse<IEReader>> =>
    axiosInstance.get(`${appId}${E_READER_URLS.main}/${eReaderId}`)

export const createEReader = ({
    eReader,
    appId,
}: {
    eReader: IEReaderMultiLang
    appId: string
}): Promise<AxiosResponse<IEReader>> =>
    // axiosInstance.post(`${appId}${E_READER_URLS.main}/${language}`, eReader)
    axiosInstance.post(`${appId}${E_READER_URLS.main}`, eReader)

export const editEReader = ({
    eReaderId,
    eReader,
    appId,
}: {
    eReader: IEReader
    eReaderId: string
    appId: string
}): Promise<AxiosResponse<IEReader>> =>
    axiosInstance.put(`${appId}${E_READER_URLS.main}/${eReaderId}`, eReader)

export const deleteEReader = ({
    eReaderId,
    appId,
}: {
    eReaderId: string | number | undefined
    appId: string
}): Promise<AxiosResponse<IEReader>> =>
    axiosInstance.delete(`${appId}${E_READER_URLS.main}/${eReaderId}`)

export const getFileUrl = ({
    data,
    appId,
}: {
    data: FormData
    appId: string
}): Promise<AxiosResponse<IEReader>> =>
    axiosInstance.post(`${appId}${E_READER_URLS.main}/file/upload`, data)
