import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const PaymentsRoutes = [
    {
        id: 1,
        module: 'payments',
        protected: true,
        exact: true,
        path: PATH_FEATURES.payments.root,
        component: lazy(
            () => import('containers/dashboard/features/payments/Payments'),
        ),
    },
    {
        id: 2,
        module: 'payments',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.payments.edit}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/payments/EditPayment'),
        ),
    },
    {
        id: 3,
        module: 'payments',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.payments.create}`,
        component: lazy(
            () =>
                import('containers/dashboard/features/payments/CreatePayments'),
        ),
    },
    {
        id: 4,
        module: 'payments',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.payments.payment}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/payments/PaymentItem'),
        ),
    },
]
