import { videoTypes } from './actionTypes'
import {
    FetchVideoRequest,
    EditVideoRequest,
    FetchVideoPayload,
    EditVideoPayload,
    CreateVideoPayload,
    CreateVideoRequest,
    DeleteVideoPayload,
    DeleteVideoRequest,
    SetVideo,
    IVideo,
    FetchVideoByIdPayload,
    FetchVideoByIdRequest,
} from './types'

export const fetchVideoRequest = (
    payload: FetchVideoPayload,
): FetchVideoRequest => ({
    type: videoTypes.FETCH_VIDEO_REQUEST,
    payload,
})

export const fetchVideoByIdRequest = (
    payload: FetchVideoByIdPayload,
): FetchVideoByIdRequest => ({
    type: videoTypes.FETCH_VIDEO_BY_ID_REQUEST,
    payload,
})

export const EditVideoAction = (
    payload: EditVideoPayload,
): EditVideoRequest => ({
    type: videoTypes.EDIT_VIDEO,
    payload,
})

export const createVideoAction = (
    payload: CreateVideoPayload,
): CreateVideoRequest => ({
    type: videoTypes.CREATE_VIDEO_REQUEST,
    payload,
})

export const DeleteVideoAction = (
    payload: DeleteVideoPayload,
): DeleteVideoRequest => ({
    type: videoTypes.DELETE_VIDEO_REQUEST,
    payload,
})

export const SetVideoAction = (payload: {
    videoDetails: IVideo[]
}): SetVideo => ({
    type: videoTypes.SET_VIDEO,
    payload,
})
