import React from 'react'

export const FeatureActivation = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '27' : '33'}
        style={{ marginTop: '10px' }}
        viewBox='0 0 46 33'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M33.8262 0H12.1738C5.46115 0 0 5.38317 0 12C0 18.6168 5.46115 24 12.1738 24H33.8262C40.5388 24 46 18.6168 46 12C46 5.38317 40.5388 0 33.8262 0ZM33.8262 21.3432C28.5997 21.3432 24.3477 17.1519 24.3477 12C24.3477 6.84824 28.5997 2.65683 33.8262 2.65683C39.0527 2.65683 43.3047 6.84824 43.3047 12C43.3047 17.1519 39.0527 21.3432 33.8262 21.3432Z'
            fill={isActive ? '#FFED00' : '#9D9E9E'}
        />
        <path
            d='M33.8262 21.3432C28.5997 21.3432 24.3477 17.1519 24.3477 12C24.3477 6.84824 28.5997 2.65683 33.8262 2.65683C39.0527 2.65683 43.3047 6.84824 43.3047 12C43.3047 17.1519 39.0527 21.3432 33.8262 21.3432Z'
            fill={isActive ? '#2B2A29' : 'white'}
        />
        <path
            d='M43.6855 20.1002C44.7117 20.1294 45.5 21.0187 45.5 22.0377V26.0734C45.5 29.6064 42.69 32.5 39.2106 32.5H34.7894C31.31 32.5 28.5 29.6064 28.5 26.0734V22.4526C28.5 21.4152 29.3266 20.5519 30.3682 20.5519H30.8521H31.1527L30.3521 21.052V21.0519H30.3682H30.8521V21.5519V24.263C30.8521 24.4894 31.0313 24.6728 31.2524 24.6728C31.4735 24.6728 31.6527 24.4894 31.6527 24.263V13.4006C31.6527 12.6283 32.2665 12 33.0209 12C33.7753 12 34.3891 12.6283 34.3891 13.4006V21.4867C34.3891 21.7131 34.5683 21.8965 34.7894 21.8965C35.0105 21.8965 35.1897 21.7131 35.1897 21.4867V19.2844C35.1897 18.903 35.3368 18.5464 35.6039 18.2804M43.6855 20.1002C43.6856 20.1002 43.6856 20.1002 43.6856 20.1002L43.6706 20.5999L43.6846 20.1001C43.6849 20.1001 43.6852 20.1001 43.6855 20.1002ZM43.6855 20.1002C43.1716 20.0847 42.6875 20.2808 42.325 20.6418C42.1758 20.7904 42.0543 20.9599 41.963 21.1437V20.2272C41.963 19.2081 41.1742 18.3186 40.1477 18.2897L40.147 18.2897C39.6351 18.2761 39.1514 18.4695 38.7881 18.8313C38.6388 18.98 38.5173 19.1495 38.426 19.3333V19.322C38.426 18.3029 37.6374 17.4134 36.6109 17.3845C36.6108 17.3845 36.6107 17.3845 36.6106 17.3845L36.5966 17.8843M36.5966 17.8843C36.2229 17.8737 35.8709 18.0145 35.6039 18.2804M36.5966 17.8843C37.3297 17.9049 37.926 18.5498 37.926 19.322V21.0948C37.926 21.3211 38.1053 21.5046 38.3264 21.5046C38.5475 21.5046 38.7267 21.3211 38.7267 21.0948V20.1896C38.7267 19.8082 38.8738 19.4516 39.1409 19.1856L36.5966 17.8843ZM35.6039 18.2804L35.2511 17.9261C35.2511 17.9261 35.2511 17.9261 35.2511 17.9262L35.6039 18.2804Z'
            fill={isActive ? '#2B2A29' : '#FAFAFA'}
            stroke={isActive ? '#FFED00' : '#9D9E9E'}
        />
    </svg>
)

export default FeatureActivation
