import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { todoPremiumTypes } from './actionTypes'
import { getTodoPremiumSettings, editTodoPremiums } from '../service/todo'

import {
    ITodoPremium,
    FetchTodoPremiumsRequest,
    EditTodoPremiumsRequest,
} from './types'

function* fetchTodoPremiumsSaga(action: FetchTodoPremiumsRequest) {
    try {
        const response: AxiosResponse<ITodoPremium> = yield call(
            getTodoPremiumSettings,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: todoPremiumTypes.SET_TODO_PREMIUM,
                payload: { settings: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editTodoPremiumSaga(action: EditTodoPremiumsRequest) {
    try {
        const response: AxiosResponse<ITodoPremium> = yield call(
            editTodoPremiums,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* todoPremiumSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            todoPremiumTypes.FETCH_TODO_PREMIUM_REQUEST,
            fetchTodoPremiumsSaga,
        ),
    ])
    yield all([
        takeLatest(
            todoPremiumTypes.EDIT_TODO_PREMIUM_REQUEST,
            editTodoPremiumSaga,
        ),
    ])
}

export default todoPremiumSaga
