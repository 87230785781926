import React from 'react'

export const AndroidIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '33' : '40'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 40 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5 29.4206C5 30.8234 3.875 31.9713 2.5 31.9713C1.125 31.9713 0 30.8234 0 29.4206V17.9425C0 16.5397 1.125 15.3918 2.5 15.3918C3.875 15.3918 5 16.5397 5 17.9425V29.4206ZM40 29.4206C40 30.8234 38.875 31.9713 37.5 31.9713C36.125 31.9713 35 30.8234 35 29.4206V17.9425C35 16.5397 36.125 15.3918 37.5 15.3918C38.875 15.3918 40 16.5397 40 17.9425V29.4206ZM17.5 43.4493C17.5 44.8522 16.375 46 15 46C13.625 46 12.5 44.8522 12.5 43.4493V31.9713C12.5 30.5684 13.625 29.4206 15 29.4206C16.375 29.4206 17.5 30.5684 17.5 31.9713V43.4493ZM27.5 43.4493C27.5 44.8522 26.375 46 25 46C23.625 46 22.5 44.8522 22.5 43.4493V31.9713C22.5 30.5684 23.625 29.4206 25 29.4206C26.375 29.4206 27.5 30.5684 27.5 31.9713V43.4493Z'
            fill={isActive ? '#FFED00' : '#9D9E9E'}
        />
        <path
            d='M7.5 15.3918V34.5219C7.5 35.9248 8.625 37.0726 10 37.0726H30C31.375 37.0726 32.5 35.9248 32.5 34.5219V15.3918H7.5ZM20 2.63843C12.5 2.63843 7.875 7.22965 7.5 12.8411H32.5C32.125 7.22965 27.5 2.63843 20 2.63843ZM15 9.78033C14.25 9.78033 13.75 9.27019 13.75 8.50499C13.75 7.73978 14.25 7.22965 15 7.22965C15.75 7.22965 16.25 7.73978 16.25 8.50499C16.25 9.14266 15.75 9.78033 15 9.78033ZM25 9.78033C24.25 9.78033 23.75 9.27019 23.75 8.50499C23.75 7.73978 24.25 7.22965 25 7.22965C25.75 7.22965 26.25 7.73978 26.25 8.50499C26.25 9.14266 25.75 9.78033 25 9.78033Z'
            fill={isActive ? '#FFED00' : '#9D9E9E'}
        />
        <path
            d='M25.375 5.8267C25.125 5.8267 24.875 5.69917 24.625 5.57164C24 5.18903 23.875 4.42383 24.25 3.78616L26.375 0.597815C26.75 -0.0398541 27.5 -0.167388 28.125 0.215213C28.75 0.597815 28.875 1.36302 28.5 2.00069L26.375 5.18903C26.25 5.57164 25.875 5.8267 25.375 5.8267ZM14.125 5.31657C13.75 5.31657 13.25 5.0615 13.125 4.6789L11.5 2.00069C11.125 1.36302 11.25 0.597815 11.875 0.215213C12.5 -0.167388 13.25 -0.0398541 13.625 0.597815L15.25 3.27603C15.625 3.9137 15.5 4.6789 14.875 5.0615C14.625 5.18903 14.375 5.31657 14.125 5.31657Z'
            fill={isActive ? '#FFED00' : '#9D9E9E'}
        />
    </svg>
)

export default AndroidIcon
