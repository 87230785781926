import React, { Dispatch, SetStateAction, useRef, useState } from 'react'

// eslint-disable-next-line max-len
import { ReactComponent as PlusIcon } from 'assets/images/new-loader/todo/PlusIcon.svg'

import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'
import { ITodo } from '../../TodoPage'
import CategoryPopup from '../popups/category-popup/CategoryPopup'
import TaskPopup from '../popups/task-popup/TaskPopup'
import AddItemPopup from '../popups/add-item-popup/AddItemPopup'

import './TodoAdd.scss'

interface IAddNewItem {
    setStorage: Dispatch<SetStateAction<ITodo>>
    storage: ITodo
    currentCategory: number
}

const AddNewItem = ({
    storage,
    setStorage,
    currentCategory,
}: IAddNewItem): React.ReactElement => {
    const [isAdd, setIsAdd] = useState(false)
    const [isAddTask, setIsAddTask] = useState(false)
    const [isAddCategory, setIsAddCategory] = useState(false)

    const addRef = useRef(null)
    useClickedOutsideHandler({ ref: addRef, func: () => setIsAdd(false) })

    return (
        <>
            {isAddTask && (
                <TaskPopup
                    onClose={() => setIsAddTask(false)}
                    storage={storage}
                    setStorage={setStorage}
                    isCreate
                    currentCategory={currentCategory}
                />
            )}
            {isAddCategory && (
                <CategoryPopup
                    onClose={() => setIsAddCategory(false)}
                    storage={storage}
                    setStorage={setStorage}
                    isCreate
                />
            )}
            {isAdd ? (
                <AddItemPopup
                    onClose={() => setIsAdd(false)}
                    onAddTask={() => setIsAddTask(true)}
                    onAddCategory={() => setIsAddCategory(true)}
                />
            ) : (
                <button
                    type='button'
                    onClick={e => {
                        e.stopPropagation()
                        setIsAdd(!isAdd)
                    }}
                    // eslint-disable-next-line max-len
                    className='m-todo-add new-loader--primary-background new-loader--contrast-color'
                    ref={addRef}
                >
                    <PlusIcon />
                </button>
            )}
        </>
    )
}

export default AddNewItem
