import React from 'react'

import './OpenButton.scss'

interface IOpenButton {
    type?: 'button' | 'submit'
    children: React.ReactNode
    handleClick?: (evt: React.MouseEvent) => void
    disabled?: boolean
    className?: string
}

export const OpenButton = ({
    type,
    children,
    handleClick,
    disabled,
    className,
    ...props
}: IOpenButton): React.ReactElement => (
    <button
        {...props}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={`new-loader--text new-loader-open-button ${className}`}
        onClick={handleClick}
        disabled={disabled}
    >
        {children}
    </button>
)

OpenButton.defaultProps = {
    type: 'button',
}

export default OpenButton
