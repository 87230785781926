// TODO
// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const sagaErrorHandle = (callback: any, error: any): void => {
    if (!callback) return
    if (!Array.isArray(error)) {
        callback('error', [
            { name: 'Default error', description: 'Error from backend' },
        ])
        return
    }
    callback('error', error)
}
