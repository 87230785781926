import React from 'react'

import moment from 'moment'
import 'moment/locale/uk'

import './CalendarItem.scss'

import CustomImage from 'components/custom-image/CustomImage'

// eslint-disable-next-line max-len
import { IEvent } from 'containers/dashboard/features/calendar/store/types'
import { useTranslation } from 'react-i18next'

interface ICalendarItem {
    event: IEvent
}

export const CalendarItem = ({ event }: ICalendarItem): React.ReactElement => {
    const { i18n } = useTranslation()

    moment.locale(i18n.language === 'en' ? 'en' : 'uk')
    const eventDuration =
        !event.startTime || !event.endTime
            ? 'No data'
            : event.startTime.toString().substring(5, 10) ===
              event.endTime.toString().substring(5, 10)
            ? `${moment(new Date(event.startTime)).format('HH:mm')} - ${moment(
                  new Date(event.endTime),
              ).format('HH:mm')}, ${moment(new Date(event.startTime)).format(
                  'D',
              )} ${moment(new Date(event.startTime)).format('MMMM')}`
            : `${moment(new Date(event.startTime)).format('HH:mm')}, ${moment(
                  new Date(event.startTime),
              ).format('D')} ${moment(new Date(event.startTime)).format(
                  'MMMM',
              )} - ${moment(new Date(event.endTime)).format('HH:mm')}, ${moment(
                  new Date(event.endTime),
              ).format('D')} ${moment(new Date(event.endTime)).format('MMMM')}`

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return (
        <div className='m-calendar-item new-loader--surface'>
            {event.pictureUrl && (
                <CustomImage
                    src={event.pictureUrl}
                    className='m-calendar-item__image'
                />
            )}
            <div className='m-calendar-item__block new-loader--text'>
                {/* eslint-disable-next-line max-len */}
                <div className='m-calendar-item__title'>{eventDuration}</div>
                <div className='m-calendar-item__info'>
                    {event.description?.replace(regexForHtmlCharts, ' ')}
                </div>
            </div>
        </div>
    )
}

export default CalendarItem
