import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { FORUM_URLS } from 'config/urls'

import { ITheme, IUser } from '../forum-themes/store/types'
import { IForumUser } from '../forum-users/store/types'

export const getThemes = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ITheme[]>> =>
    axiosInstance.get(`${appId}${FORUM_URLS.themes}/${language}`)

export const getThemeById = ({
    themeId,
    appId,
    language,
}: {
    themeId: string
    appId: string
    language: string
}): Promise<AxiosResponse<ITheme>> =>
    axiosInstance.get(`${appId}${FORUM_URLS.themes}/${language}/${themeId}`)

export const createTheme = ({
    theme,
    appId,
    language,
}: {
    theme: ITheme
    appId: string
    language: string
}): Promise<AxiosResponse<ITheme>> =>
    axiosInstance.post(`${appId}${FORUM_URLS.themes}/${language}`, theme)

export const editTheme = ({
    themeId,
    theme,
    appId,
    language,
}: {
    themeId: string
    theme: ITheme
    appId: string
    language: string
}): Promise<AxiosResponse<ITheme>> =>
    axiosInstance.put(
        `${appId}${FORUM_URLS.themes}/${language}/${themeId}`,
        theme,
    )

export const deleteTheme = ({
    themeId,
    appId,
}: {
    themeId: string | number | undefined
    appId: string
}): Promise<AxiosResponse<ITheme>> =>
    axiosInstance.delete(`${appId}${FORUM_URLS.themes}/${themeId}`)

export const getForumUsers = ({
    appId,
    themeId,
    language,
}: {
    themeId?: string | number
    appId: string
    language: string
}): Promise<AxiosResponse<IUser[]>> =>
    axiosInstance.get(
        `${appId}${FORUM_URLS.themes}/${language}/${themeId}/users`,
    )

export const getUserById = ({
    appId,
    userId,
}: {
    userId?: string | number
    appId: string
}): Promise<AxiosResponse<IForumUser>> =>
    axiosInstance.get(`${appId}${FORUM_URLS.users}/${userId}`)
