import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const LocationRoutes = [
    {
        id: 1,
        module: 'map',
        protected: true,
        exact: true,
        path: PATH_FEATURES.map.root,
        component: lazy(() => import('containers/dashboard/features/map/Map')),
    },
    {
        id: 2,
        module: 'map',
        protected: true,
        exact: true,
        path: PATH_FEATURES.map.create,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/map/CreateMap'
                ),
        ),
    },
    {
        id: 3,
        module: 'map',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.map.edit}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/map/edit-map-item/EditMapItem'
                ),
        ),
    },
]
