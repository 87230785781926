import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

// eslint-disable-next-line max-len
import { ReactComponent as PlusIcon } from 'assets/images/new-loader/todo/PlusIcon.svg'

import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'
import Overlay from 'containers/emulator/components/shared/overlay/Overlay'

interface IAddItemPopup {
    onClose: () => void
    onAddTask: () => void
    onAddCategory: () => void
}

const AddItemPopup = ({
    onClose,
    onAddTask,
    onAddCategory,
}: IAddItemPopup): React.ReactElement => {
    const { t } = useTranslation(['emulator'])
    const addRef = useRef(null)
    useClickedOutsideHandler({ ref: addRef, func: onClose })

    return (
        <Overlay>
            <button
                type='button'
                onClick={onClose}
                // eslint-disable-next-line max-len
                className='m-todo-add m-todo-add__close new-loader--primary-background new-loader--contrast-color'
                ref={addRef}
            >
                <PlusIcon />
            </button>
            <div
                // eslint-disable-next-line max-len
                className='m-todo-add__actions new-loader--surface new-loader--primary-color'
                ref={addRef}
            >
                <button
                    type='button'
                    onClick={() => {
                        onClose()
                        onAddTask()
                    }}
                >
                    {t('emulator:addTAsk')}
                </button>
                <button
                    type='button'
                    onClick={() => {
                        onClose()
                        onAddCategory()
                    }}
                >
                    {t('emulator:addCategory')}
                </button>
            </div>
        </Overlay>
    )
}

export default AddItemPopup
