import React, { Dispatch, SetStateAction, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Arrows from 'assets/images/new-loader/todo/Arrows'

import { ITodo } from '../../TodoPage'
import EditCategory from './edit-category/EditCategory'
// eslint-disable-next-line max-len
import { EmulatorIconRender } from '../../../../components/emulator-icon-render/EmulatorIconRender'

import './SortByPriority.scss'

interface ISortByPriority {
    currentCategory: number
    setCurrentCategory: Dispatch<SetStateAction<number>>
    storage: ITodo
    setStorage: Dispatch<SetStateAction<ITodo>>
}

const SortByPriority = ({
    currentCategory,
    setCurrentCategory,
    storage,
    setStorage,
}: ISortByPriority): React.ReactElement => {
    const { t } = useTranslation(['emulator'])

    const [isPSorted, setIsPSorted] = useState(false)

    const sortByPriority = (isSorted: boolean) => {
        const storageCopy = [...storage.tasks]
        storageCopy.sort((a, b) =>
            a.priority === b.priority
                ? 0
                : isSorted
                ? a.priority > b.priority
                    ? 1
                    : -1
                : a.priority < b.priority
                ? 1
                : -1,
        )
        setStorage({ ...storage, tasks: storageCopy })
        setIsPSorted(!isPSorted)
    }

    return (
        <div className='m-priority-todo'>
            <div className='m-priority-todo__category'>
                {storage.categories && (
                    <>
                        {storage.categories.find(
                            i => i.value === currentCategory,
                        )?.icon ? (
                            <EmulatorIconRender
                                icon={
                                    storage.categories.find(
                                        i => i.value === currentCategory,
                                    )?.icon || ''
                                }
                                className='new-loader--secondary-fill'
                            />
                        ) : null}
                    </>
                )}
                <div className='m-priority-todo__options new-loader--text'>
                    <div>
                        {currentCategory === 0
                            ? t('emulator:allTasks')
                            : storage.categories.find(
                                  i => i.value === currentCategory,
                              )?.label}
                    </div>
                    {storage &&
                        storage.categories &&
                        storage.categories.find(
                            i => i.value === currentCategory,
                        ) &&
                        !storage.categories.find(
                            i => i.value === currentCategory,
                        )?.icon && (
                            <EditCategory
                                storage={storage}
                                setStorage={setStorage}
                                todoCategory={storage.categories.find(
                                    i => i.value === currentCategory,
                                )}
                                setCurrentCategory={setCurrentCategory}
                            />
                        )}
                </div>
            </div>
            <button
                type='button'
                onClick={() => sortByPriority(isPSorted)}
                className='m-priority-todo__filter'
            >
                <div className='new-loader--text'>{t('emulator:priority')}</div>
                <Arrows className='new-loader--text' />
            </button>
        </div>
    )
}

export default SortByPriority
