import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { GENERATION_URLS } from 'config/urls'

export const getAllGeneratedItems = (): Promise<AxiosResponse<string[]>> =>
    axiosInstance.get(`${GENERATION_URLS.main}`)

export const generatePictures = ({
    prompt,
    pictureSize,
    featureType,
    pictureCount,
}: {
    prompt: string
    pictureSize: string
    featureType: string
    pictureCount: number
}): Promise<AxiosResponse<string[]>> =>
    axiosInstance.post(`${GENERATION_URLS.pictures}`, {
        prompt,
        pictureSize,
        featureType,
        pictureCount,
    })
