import * as Yup from 'yup'

import { MAIN_REGEX } from 'config/regex'
import { AnyObject } from 'yup/lib/types'

export const calendarEditSchema = Yup.object().shape({
    startDate: Yup.date()
        .min(new Date(), 'calendar:pastDateErr')
        .required('calendar:dateRequired'),
    endDate: Yup.date()
        .min(Yup.ref('startDate'), 'calendar:endDateErr')
        .required('calendar:dateRequired'),

    description: Yup.string()
        .required('calendar:descRequired')
        .test(
            'emptyHtml',
            'calendar:descRequired',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item, testContext: any) => {
                if (!testContext.originalValue) return true
                return (
                    testContext.originalValue.replace(MAIN_REGEX.htmlRegex, '')
                        .length > 0
                )
            },
        ),
})

const eventCreateSchema = Yup.object().shape({
    startDate: Yup.date()
        .min(new Date(), 'calendar:pastDateErr')
        .required('calendar:dateRequired'),
    endDate: Yup.date()
        .min(Yup.ref('startDate'), 'calendar:endDateErr')
        .required('calendar:dateRequired'),

    description: Yup.string()
        .required('calendar:descRequired')
        .test(
            'emptyHtml',
            'calendar:descRequired',
            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (_, testContext: AnyObject) => {
                if (!testContext.originalValue) return true
                return (
                    testContext.originalValue.replace(MAIN_REGEX.htmlRegex, '')
                        .length > 0
                )
            },
        ),
})

export const calendarCreateSchema = Yup.object().shape({
    events: Yup.array().of(
        Yup.object().shape({
            uk: eventCreateSchema.nullable(),
            en: eventCreateSchema.nullable(),
        }),
    ),
})

export const editCreateSchema = Yup.object().shape({
    events: Yup.array().of(
        Yup.object().shape({
            uk: eventCreateSchema.nullable(),
            en: eventCreateSchema.nullable(),
        }),
    ),
})
