import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { PUSH_URLS } from 'config/urls'

import { IPush } from '../store/types'

export const getPushNotifications = ({
    appId,
    page,
}: {
    appId: string
    page: number
}): Promise<AxiosResponse<IPush[]>> =>
    axiosInstance.get(
        `${appId}${PUSH_URLS.main}?pageNumber=${page}&pageSize=10`,
    )

export const getPushById = ({
    pushId,
    appId,
}: {
    pushId: string
    appId: string
}): Promise<AxiosResponse<IPush>> =>
    axiosInstance.get(`${appId}${PUSH_URLS.main}/${pushId}`)

export const createPushNotification = ({
    notification,
    appId,
}: {
    notification: IPush
    appId: string
}): Promise<AxiosResponse<IPush>> =>
    axiosInstance.post(`${appId}${PUSH_URLS.main}`, notification)

export const editPushNotification = ({
    pushId,
    notification,
    appId,
}: {
    appId: string
    pushId: string
    notification: IPush
}): Promise<AxiosResponse<IPush>> =>
    axiosInstance.put(`${appId}${PUSH_URLS.main}/${pushId}`, notification)

export const deletePushNotification = ({
    pushId,
    appId,
}: {
    appId: string
    pushId: string
}): Promise<AxiosResponse<IPush>> =>
    axiosInstance.delete(`${appId}${PUSH_URLS.main}/${pushId}`)
