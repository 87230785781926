import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { mapTypes } from './actionTypes'
import {
    getLocations,
    getLocationById,
    createLocations,
    editLocation,
    deleteLocation,
} from '../service/map'

import {
    ILocation,
    FetchLocationsRequest,
    FetchLocationByIdRequest,
    CreateLocationsRequest,
    EditLocationRequest,
    ILocationMultilang,
} from './types'

function* fetchLocationsSaga(action: FetchLocationsRequest) {
    try {
        const response: AxiosResponse<ILocation> = yield call(
            getLocations,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: mapTypes.SET_LOCATIONS,
                payload: { locations: response.data },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchLocationByIdSaga(action: FetchLocationByIdRequest) {
    try {
        const response: AxiosResponse<ILocation> = yield call(
            getLocationById,
            action.payload,
        )

        if (action.payload.isEdit) {
            const oppositeLang = response.data.language === 'en' ? 'uk' : 'en'

            let relativeLocation: ILocation = {
                id: '2',
                name: '',
                description: '',
                address: '',
                latitude: '',
                longitude: '',
                imageUrl: undefined,
                language: oppositeLang,
            }

            if (response.data) {
                yield put({
                    type: mapTypes.SET_LOCATION,
                    payload: { location: response.data },
                })
            }

            if (response.data.relativeId) {
                const props = {
                    locationId: response.data.relativeId,
                    appId: action.payload.appId,
                }

                const relativeResp: AxiosResponse<ILocation> = yield call(
                    getLocationById,
                    props,
                )

                if (relativeResp.status === 200) {
                    if (relativeResp.data) {
                        const { data } = relativeResp
                        relativeLocation = {
                            id: data.id || '2',
                            name: data?.name || '',
                            description: data?.description || '',
                            address: data?.address || '',
                            latitude: data?.latitude || '',
                            longitude: data?.longitude || '',
                            imageUrl: data?.imageUrl || undefined,
                            language: data.language,
                        }
                    }
                }
            }

            yield put({
                type: mapTypes.SET_RELATIVE_LOCATION,
                payload: { relativeLocation },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createLocationsSaga(action: CreateLocationsRequest) {
    try {
        const response: AxiosResponse<ILocationMultilang> = yield call(
            createLocations,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editLocationSaga(action: EditLocationRequest) {
    try {
        const response: AxiosResponse<ILocation> = yield call(
            editLocation,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteLocationSaga(action: EditLocationRequest) {
    try {
        const response: AxiosResponse<ILocation> = yield call(
            deleteLocation,
            action.payload,
        )
        if (response.status === 200) {
            const { locations } = yield select(state => state.map)
            console.log('locations', locations)
            const updatedLocations = locations.filter(
                (location: ILocation) =>
                    location.id !== action.payload.locationId,
            )

            console.log('updatedLocations', updatedLocations)

            yield put({
                type: mapTypes.SET_LOCATIONS,
                payload: { locations: updatedLocations },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* mapSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(mapTypes.FETCH_LOCATIONS_REQUEST, fetchLocationsSaga),
    ])
    yield all([
        takeLatest(mapTypes.FETCH_LOCATION_BY_ID, fetchLocationByIdSaga),
    ])
    yield all([
        takeLatest(mapTypes.CREATE_LOCATIONS_REQUEST, createLocationsSaga),
    ])
    yield all([takeLatest(mapTypes.EDIT_LOCATION_REQUEST, editLocationSaga)])
    yield all([
        takeLatest(mapTypes.DELETE_LOCATION_REQUEST, deleteLocationSaga),
    ])
}

export default mapSaga
