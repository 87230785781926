import React, { Dispatch, SetStateAction, useRef } from 'react'

import useDraggableScroll from 'use-draggable-scroll'
import { useTranslation } from 'react-i18next'

import { ICategory } from '../../TodoPage'
// eslint-disable-next-line max-len
import { EmulatorIconRender } from '../../../../components/emulator-icon-render/EmulatorIconRender'

import './CategoriesTodo.scss'

interface ICategoriesTodo {
    currentCategory: number
    setCurrentCategory: Dispatch<SetStateAction<number>>
    categories: ICategory[]
}

const CategoriesTodo = ({
    currentCategory,
    categories,
    setCurrentCategory,
}: ICategoriesTodo): React.ReactElement => {
    const { t } = useTranslation(['emulator'])
    const ref = useRef(null)
    const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' })

    return (
        <div
            className='m-categories-todo'
            ref={ref}
            onMouseDown={onMouseDown}
            role='presentation'
        >
            <div
                className={`new-loader--primary-color ${
                    currentCategory === 0 && 'm-categories-todo__active'
                }`}
            >
                <button
                    type='button'
                    // eslint-disable-next-line max-len
                    className='m-categories-todo__item new-loader--surface new-loader--text'
                    onClick={() => setCurrentCategory(0)}
                >
                    {t('emulator:allTasks')}
                </button>
            </div>

            {categories &&
                categories.map(item => (
                    <div
                        key={item.id}
                        className={`new-loader--primary-color ${
                            currentCategory === item.value &&
                            'm-categories-todo__active'
                        }`}
                    >
                        <button
                            type='button'
                            // eslint-disable-next-line max-len
                            className='m-categories-todo__item new-loader--surface new-loader--text'
                            onClick={() => setCurrentCategory(item.value)}
                        >
                            {item.icon ? (
                                <EmulatorIconRender
                                    icon={item.label}
                                    className='new-loader--primary-fill'
                                />
                            ) : null}
                            <div>{item.label}</div>
                        </button>
                    </div>
                ))}
        </div>
    )
}

export default CategoriesTodo
