import React from 'react'

export const FacebookIcon = (): React.ReactElement => (
    <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            // eslint-disable-next-line max-len

            d='M14.2222 19.7778H19.7778V4.22222H4.22222V19.7778H12V14.2222H9.77778V12H12V10.162C12 8.67617 12.1547 8.13736 12.4452 7.59414C12.7358 7.05093 13.162 6.62462 13.7052 6.33411C14.1304 6.10671 14.658 5.96947 15.5797 5.9106C15.9456 5.88723 16.419 5.91703 17 6V8.11111H16.4444C15.4252 8.11111 15.0047 8.15924 14.7532 8.2937C14.5973 8.3771 14.4882 8.4862 14.4048 8.64214C14.2703 8.89353 14.2222 9.14281 14.2222 10.162V12H17L16.4444 14.2222H14.2222V19.7778ZM3.11111 2H20.8889C21.5026 2 22 2.49747 22 3.11111V20.8889C22 21.5026 21.5026 22 20.8889 22H3.11111C2.49747 22 2 21.5026 2 20.8889V3.11111C2 2.49747 2.49747 2 3.11111 2Z'
            fill='white'
        />
    </svg>
)

export default FacebookIcon
