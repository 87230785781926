import { PATH_NEW_LOADER } from 'config/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { ReactComponent as Play } from 'assets/images/icons/Play.svg'

// eslint-disable-next-line max-len

type TData = {
    type: string
    description: string
    url: string
    id: string
}

interface IData {
    item: TData
}

const VideoItem = ({ item }: IData): React.ReactElement => {
    const { push } = useHistory()
    const { t } = useTranslation('cloud')

    return (
        <div className='new-loader--surface m-video__item'>
            <div className='m-video__header'>
                <button
                    type='button'
                    className='m-video__header-btn new-loader--surface'
                    onClick={() =>
                        push(`${PATH_NEW_LOADER.pages.video}/${item.id}`)
                    }
                >
                    <div className='m-video__header-title new-loader--text'>
                        {t('cloud:videoCoverText')}
                    </div>
                    <div className='m-video__icon new-loader--text'>
                        <Play />
                    </div>
                </button>
            </div>
            <div className='m-video__desc new-loader--text'>
                {item.description}
            </div>
        </div>
    )
}

export default VideoItem
