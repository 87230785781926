import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    // select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { pushNotificationsTypes } from './actionTypes'
import {
    getPushNotifications,
    getPushById,
    createPushNotification,
    editPushNotification,
    deletePushNotification,
} from '../service/push'

import {
    IPush,
    FetchPushesRequest,
    FetchPushByIdRequest,
    CreatePushRequest,
    EditPushRequest,
    DeletePushRequest,
} from './types'

function* fetchPushesSaga(action: FetchPushesRequest) {
    try {
        const response: AxiosResponse<IPagination<IPush>> = yield call(
            getPushNotifications,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: pushNotificationsTypes.SET_PUSHES,
                payload: { pushes: response.data.items },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchPushByIdSaga(action: FetchPushByIdRequest) {
    try {
        const response: AxiosResponse<IPush> = yield call(
            getPushById,

            action.payload,
        )
        if (response.data) {
            yield put({
                type: pushNotificationsTypes.SET_PUSH_BY_ID,
                payload: { pushInfo: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createPushNotificationSaga(action: CreatePushRequest) {
    try {
        const response: AxiosResponse<IPush> = yield call(
            createPushNotification,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editPushNotificationSaga(action: EditPushRequest) {
    try {
        const response: AxiosResponse<IPush> = yield call(
            editPushNotification,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deletePushNotificationSaga(action: DeletePushRequest) {
    try {
        const response: AxiosResponse<IPush> = yield call(
            deletePushNotification,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            // const { blogs } = yield select(state => state.blog)
            // const updatedBlogs = blogs.filter(
            //     (blog: IBlog) => blog.id !== action.payload.blogId,
            // )

            // yield put({
            //     type: blogTypes.SET_BLOGS,
            //     payload: { blogs: updatedBlogs },
            // })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* pushesSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(
            pushNotificationsTypes.FETCH_PUSHES_REQUEST,
            fetchPushesSaga,
        ),
    ])
    yield all([
        takeLatest(
            pushNotificationsTypes.FETCH_PUSHE_BY_ID_REQUEST,
            fetchPushByIdSaga,
        ),
    ])
    yield all([
        takeLatest(
            pushNotificationsTypes.CREATE_PUSH_REQUEST,
            createPushNotificationSaga,
        ),
    ])
    yield all([
        takeLatest(
            pushNotificationsTypes.EDIT_PUSH_REQUEST,
            editPushNotificationSaga,
        ),
    ])
    yield all([
        takeLatest(
            pushNotificationsTypes.DELETE_PUSH_REQUEST,
            deletePushNotificationSaga,
        ),
    ])
}

export default pushesSaga
