import React from 'react'

export const LinkedInIcon = (): React.ReactElement => (
    <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            // eslint-disable-next-line max-len
            d='M3.11111 2H20.8889C21.5026 2 22 2.49747 22 3.11111V20.8889C22 21.5026 21.5026 22 20.8889 22H3.11111C2.49746 22 2 21.5026 2 20.8889V3.11111C2 2.49747 2.49746 2 3.11111 2ZM4.22222 4.22222V19.7778H19.7778V4.22222H4.22222ZM7 8.66667C6.07952 8.66667 5.33333 7.92048 5.33333 7C5.33333 6.07952 6.07952 5.33333 7 5.33333C7.92047 5.33333 8.66667 6.07952 8.66667 7C8.66667 7.92048 7.92047 8.66667 7 8.66667ZM5.88889 9.77778H8.11111V18.1111H5.88889V9.77778ZM12 10.255C12.6494 9.62816 13.4061 9.22222 14.2222 9.22222C16.5234 9.22222 18.1111 11.0877 18.1111 13.3889V18.1111H15.8889V13.3889C15.8889 12.315 15.0184 11.4444 13.9445 11.4444C12.8706 11.4444 12 12.315 12 13.3889V18.1111H9.7778V9.77778H12V10.255Z'
            fill='white'
        />
    </svg>
)

export default LinkedInIcon
