import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import Preloader from 'components/preloader/Preloader'
// eslint-disable-next-line max-len
import { fetchVideoRequest } from 'containers/dashboard/features/cloud/components/tabs/video/store/actions'

// eslint-disable-next-line max-len
import { IVideo } from 'containers/dashboard/features/cloud/components/tabs/video/store/types'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

import VideoItem from './video-item/VideoItem'

import './VideoPage.scss'

const VideoPage = () => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation('toast')
    const { videoDetails } = useSelector((state: RootState) => state.video)

    const appId = getAppIdCookie()
    const { pathname } = useLocation()

    useEffect(() => {
        if (!appId || !pathname.includes('loader')) return
        dispatch(
            fetchVideoRequest({
                appId,
                language: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [dispatch, appId, t, i18n.language])

    return videoDetails ? (
        videoDetails.length > 0 ? (
            <div className='m-video'>
                {videoDetails.map((item: IVideo) => (
                    <div key={item.id}>
                        <VideoItem item={item} />
                    </div>
                ))}
            </div>
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}
export default VideoPage as React.FC
