/* eslint-disable max-len */
import React from 'react'

import BillingIcon from 'assets/images/dashboard/header-navigation/BillingIcon'
import AppsIcon from 'assets/images/dashboard/header-navigation/AppsIcon'
import TutorialIcon from 'assets/images/dashboard/header-navigation/TutorialIcon'
import ProfileIcon from 'assets/images/dashboard/header-navigation/ProfileIcon'
import LogOutIcon from 'assets/images/dashboard/header-navigation/LogOutIcon'
import BackMobile from 'assets/images/dashboard/header-navigation/BackMobile'
import { ReactComponent as EmulatorIcon } from 'assets/images/icons/Play.svg'
import NotificationIcon from 'assets/images/dashboard/header-navigation/Notification'
import AIIcon from 'assets/images/dashboard/header-navigation/AIIcon'
import AccountIcon from 'assets/images/dashboard/header-navigation/AccountIcon'
import { ReactComponent as WebIcon } from 'assets/images/icons/Web.svg'

interface Props {
    iconName: string
    isMobile?: boolean
    showUnread?: boolean
}

export const MenuIconRender = ({
    iconName,
    isMobile,
    showUnread,
}: Props): React.ReactElement => {
    switch (iconName) {
        case 'ai':
            return <AIIcon />
        case 'notifications':
            return <NotificationIcon isSmall showUnread={showUnread} />
        case 'show-emulator':
            return <EmulatorIcon />
        case 'billing':
            return <BillingIcon isMobile={isMobile} />
        case 'my-apps':
            return <AppsIcon />
        case 'to-promo':
            return <BackMobile />
        case 'account':
            return <AccountIcon />
        case 'profile':
            return <ProfileIcon />
        case 'tutorial':
            return <TutorialIcon isMobile={isMobile} />
        case 'language':
            return <WebIcon />
        case 'logout':
            return <LogOutIcon />
        default:
            return <svg width='24' height='24' />
    }
}
