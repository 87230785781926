import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { FEATURES_URLS } from 'config/urls'

import { IFeature, IFeatures } from '../store/types'

export const getFeatures = ({
    applicationId,
    lang,
}: {
    applicationId: string
    lang: string
}): Promise<AxiosResponse<IFeatures>> =>
    axiosInstance.get(`${applicationId}${FEATURES_URLS.main}/${lang}`)

export const editFeatures = ({
    applicationId,
    features,
}: {
    applicationId: string
    features: IFeature[]
}): Promise<AxiosResponse<IFeature[]>> =>
    axiosInstance.put(`${applicationId}${FEATURES_URLS.main}`, features)
