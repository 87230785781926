import { aiPictureActions } from './actionTypes'
import { IPictureGenerationState, aiPicturesActions } from './types'

const initialState: IPictureGenerationState = {
    generations: [],
    oldGenerations: [],
}

export default (
    state = initialState,
    action: aiPicturesActions,
): IPictureGenerationState => {
    switch (action.type) {
        case aiPictureActions.SET_AI_PICTURES:
            return {
                ...state,
                [action.payload.field]: action.payload.value,
            }
        default:
            return {
                ...state,
            }
    }
}
