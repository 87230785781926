export enum eReaderTypes {
    FETCH_E_READERS_REQUEST = 'FETCH_E_READERS_REQUEST',
    FETCH_E_READER_BY_ID = 'FETCH_E_READER_BY_ID',
    CREATE_E_READERS_REQUEST = 'CREATE_E_READERS_REQUEST',
    EDIT_E_READER_REQUEST = 'EDIT_E_READER_REQUEST',
    DELETE_E_READER_REQUEST = 'DELETE_E_READER_REQUEST',
    SET_E_READERS = 'SET_E_READERS',
    SET_E_READER = 'SET_E_READER',
    SET_RELATIVE_E_READER = 'SET_RELATIVE_E_READER',
}
