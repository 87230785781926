import React from 'react'

export const BackMobile = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11 20C15.9706 20 20 15.9706 20 11C20 6.0294 15.9706 2 11 2C6.0294 2 2 6.0294 2 11C2 15.9706 6.0294 20 11 20ZM9.2072 7.2072L7.4142 9H10.5C13.5376 9 16 11.4624 16 14.5V15C16 15.5522 15.5522 16 15 16C14.4478 16 14 15.5522 14 15V14.5C14 12.567 12.433 11 10.5 11H7.4142L9.2072 12.7928C9.5976 13.1834 9.5976 13.8166 9.2072 14.2072C8.8166 14.5976 8.1834 14.5976 7.7928 14.2072L4.2886 10.7028C4.1948 10.608 4.124 10.499 4.0758 10.3828C4.027 10.2648 4 10.1356 4 10C4 9.8644 4.027 9.7352 4.0758 9.6172C4.1246 9.4992 4.197 9.3888 4.2928 9.2928L7.7928 5.7928C8.1834 5.4024 8.8166 5.4024 9.2072 5.7928C9.5976 6.1834 9.5976 6.8166 9.2072 7.2072Z'
            fill='currentColor'
        />
    </svg>
)

export default BackMobile
