import React from 'react'

import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import store from 'redux/store'
import { CookiesProvider } from 'react-cookie'
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-time-picker/dist/TimePicker.css'
import 'react-calendar/dist/Calendar.css'
/* eslint-disable import/no-extraneous-dependencies */
import 'react-phone-number-input/style.css'

import App from './containers/app/App'
import theme from './theme'
import './i18n'

import reportWebVitals from './utils/statistics/reportWebVitals'

const options = {
    autoConfig: true,
    debug: false,
}

if (process.env.REACT_APP_FACEBOOK_PIXEL_ID) {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, options)
    ReactPixel.pageView()
}

if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS)
}

if (process.env.REACT_APP_GOOGLE_TAG) {
    const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GOOGLE_TAG,
    }

    TagManager.initialize(tagManagerArgs)
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(
    <React.StrictMode>
        <CookiesProvider>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </Provider>
        </CookiesProvider>
    </React.StrictMode>,
)

reportWebVitals()
