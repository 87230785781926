export enum informationTypes {
    FETCH_INFORMATION_REQUEST = 'FETCH_INFORMATION_REQUEST',
    FETCH_INFORMATION_ITEM_REQUEST = 'FETCH_INFORMATION_ITEM_REQUEST',
    CREATE_MULTIPLE_INFORMATION = 'CREATE_MULTIPLE_INFORMATION',
    CREATE_INFORMATION = 'CREATE_INFORMATION',
    EDIT_INFORMATION = 'EDIT_INFORMATION',
    DELETE_INFORMATION = 'DELETE_INFORMATION',
    SET_INFORMATION = 'SET_INFORMATION',
    SET_INFORMATION_ITEM = 'SET_INFORMATION_ITEM',
    SET_RELATIVE_INFORMATION = 'SET_RELATIVE_INFORMATION',
}
