import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { notificationTypes } from './actionTypes'
import {
    getNotifications,
    deleteNotification,
    readNotification,
    readAllNotifications,
} from '../service/notifications'

import {
    INotification,
    DeleteNoteRequest,
    ReadNoteRequest,
    FetchNotesRequest,
    ReadAllNoteRequest,
} from './types'

function* fetchNotesSaga(action: FetchNotesRequest) {
    try {
        const response: AxiosResponse<INotification> = yield call(
            getNotifications,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: notificationTypes.SET_NOTIFICATIONS,
                payload: { notifications: response.data },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteNoteSaga(action: DeleteNoteRequest) {
    try {
        const response: AxiosResponse<INotification> = yield call(
            deleteNotification,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { notifications } = yield select(state => state.notifications)
            const updatedNotes = notifications.filter(
                (note: INotification) => note.id !== action.payload.noteId,
            )

            yield put({
                type: notificationTypes.SET_NOTIFICATIONS,
                payload: { notifications: updatedNotes },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* readNoteSaga(action: ReadNoteRequest) {
    try {
        const response: AxiosResponse<INotification> = yield call(
            readNotification,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            // Note change
            const { notifications } = yield select(state => state.notifications)
            const updNotifications = [...notifications]
            const noteIndex = notifications.findIndex(
                (note: INotification) => note.id === action.payload.noteId,
            )
            const notification = updNotifications.find(
                note => note.id === action.payload.noteId,
            )
            const updNotification = { ...notification, isRead: true }
            updNotifications[noteIndex] = updNotification

            yield put({
                type: notificationTypes.SET_NOTIFICATIONS,
                payload: { notifications: updNotifications },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* readAllNoteSaga(action: ReadAllNoteRequest) {
    try {
        const response: AxiosResponse<INotification> = yield call(
            readAllNotifications,
            action.payload,
        )
        if (response.status === 200) {
            console.log(response.status)

            const { notifications } = yield select(state => state.notifications)
            const updNotifications = notifications.map(
                (note: INotification) => ({ ...note, isRead: true }),
            )

            yield put({
                type: notificationTypes.SET_NOTIFICATIONS,
                payload: { notifications: updNotifications },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* notificationsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            notificationTypes.FETCH_NOTIFICATIONS_REQUEST,
            fetchNotesSaga,
        ),
    ])
    yield all([
        takeLatest(notificationTypes.DELETE_NOTIFICATION, deleteNoteSaga),
    ])
    yield all([takeLatest(notificationTypes.READ_NOTIFICATION, readNoteSaga)])
    yield all([
        takeLatest(notificationTypes.READ_ALL_NOTIFICATIONS, readAllNoteSaga),
    ])
}

export default notificationsSaga
