import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const FeatureRootRoutes = [
    {
        id: 1,
        module: 'features',
        protected: true,
        exact: true,
        path: PATH_FEATURES.root,
        component: lazy(
            () => import('containers/dashboard/features/FeaturesRoot'),
        ),
    },
]
