import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    // select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { brandingTypes } from './actionTypes'
import { fetchBrandingRequest, editBrandingRequest } from '../service/branding'

import { EditBrandingRequest, FetchBrandingRequest, IBranding } from './types'

function* fetchBrandingSaga(action: FetchBrandingRequest) {
    try {
        const response: AxiosResponse<IBranding> = yield call(
            fetchBrandingRequest,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: brandingTypes.SET_BRANDING,
                payload: { branding: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editBrandingSaga(action: EditBrandingRequest) {
    try {
        const response: AxiosResponse<IBranding> = yield call(
            editBrandingRequest,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            yield put({
                type: brandingTypes.SET_BRANDING,
                payload: { branding: action.payload.branding },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* brandingSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(brandingTypes.FETCH_BRANDING_REQUEST, fetchBrandingSaga),
    ])
    yield all([
        takeLatest(brandingTypes.UPDATE_BRANDING_REQUEST, editBrandingSaga),
    ])
}

export default brandingSaga
