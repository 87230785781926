import React from 'react'

export const BookingIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M32.2754 16.7012C32.459 16.9004 32.7191 17 33.0557 17C33.4077 17 33.6716 16.9004 33.8475 16.7012C34.0235 16.4938 34.1115 16.2075 34.1115 15.8423V12.9295H34.1344L36.9344 16.3278C37.118 16.5519 37.2863 16.722 37.4393 16.8382C37.6 16.9461 37.8104 17 38.0705 17C38.3765 17 38.606 16.917 38.759 16.751C38.9197 16.5851 39 16.3776 39 16.1286C39 15.8714 38.9005 15.6224 38.7016 15.3817L36.1795 12.2904L38.5639 9.54357C38.7322 9.3444 38.8202 9.12863 38.8279 8.89627C38.8432 8.6556 38.7781 8.44813 38.6328 8.27386C38.4951 8.09129 38.2656 8 37.9443 8C37.7377 8 37.5503 8.04979 37.382 8.14938C37.2213 8.24066 37.0683 8.3693 36.923 8.53527L34.1344 11.8714H34.1115V9.15768C34.1115 8.77593 34.0197 8.48963 33.8361 8.29876C33.6601 8.09959 33.4 8 33.0557 8C32.7191 8 32.459 8.09959 32.2754 8.29876C32.0918 8.48963 32 8.77593 32 9.15768V15.8423C32 16.2075 32.0918 16.4938 32.2754 16.7012Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M18.75 17C18.0028 17 17.3464 16.8143 16.7807 16.4429C16.2151 16.0633 15.7751 15.5351 15.4609 14.8583C15.1536 14.1816 15 13.3934 15 12.4938C15 11.8171 15.0873 11.2022 15.2619 10.6492C15.4434 10.0963 15.6983 9.62173 16.0265 9.22558C16.3617 8.82944 16.7598 8.5282 17.2207 8.32187C17.6816 8.10729 18.1913 8 18.75 8C19.4972 8 20.1501 8.18569 20.7088 8.55708C21.2744 8.92847 21.7144 9.45254 22.0286 10.1293C22.3429 10.7978 22.5 11.586 22.5 12.4938C22.5 13.1706 22.4092 13.7854 22.2277 14.3384C22.0531 14.8913 21.7982 15.37 21.463 15.7744C21.1348 16.1706 20.7402 16.4759 20.2793 16.6905C19.8184 16.8968 19.3087 17 18.75 17ZM19.6927 14.784C19.4344 14.9904 19.1201 15.0935 18.75 15.0935C18.1983 15.0935 17.7689 14.8666 17.4616 14.4127C17.1543 13.9505 17.0007 13.3109 17.0007 12.4938C17.0007 11.9409 17.0705 11.4746 17.2102 11.0949C17.3499 10.707 17.5489 10.414 17.8073 10.216C18.0726 10.0096 18.3869 9.90646 18.75 9.90646C19.3017 9.90646 19.7311 10.1334 20.0384 10.5873C20.3457 11.0413 20.4993 11.6768 20.4993 12.4938C20.4993 13.0385 20.4295 13.5048 20.2898 13.8927C20.1501 14.2806 19.9511 14.5777 19.6927 14.784Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.12375 17C7.75697 17 7.47603 16.8979 7.28094 16.6936C7.09365 16.4809 7 16.1787 7 15.7872V9.21277C7 8.82128 7.09365 8.5234 7.28094 8.31915C7.47603 8.10638 7.75697 8 8.12375 8H10.9799C11.573 8 12.0764 8.09787 12.49 8.29362C12.9114 8.48085 13.2313 8.74894 13.4498 9.09787C13.6683 9.44681 13.7776 9.85532 13.7776 10.3234C13.7776 10.8511 13.6371 11.3021 13.3562 11.6766C13.1491 11.9605 12.8837 12.176 12.5599 12.3229C12.9655 12.4552 13.2894 12.6738 13.5318 12.9787C13.8439 13.3617 14 13.8511 14 14.4468C14 15.2383 13.7386 15.8638 13.2157 16.3234C12.7007 16.7745 11.9944 17 11.097 17H8.12375ZM10.8043 15.2766H9.0602V13.2979H10.8043C11.2023 13.2979 11.4911 13.3787 11.6706 13.5404C11.8579 13.7021 11.9515 13.9489 11.9515 14.2809C11.9515 14.6128 11.8579 14.8638 11.6706 15.034C11.4911 15.1957 11.2023 15.2766 10.8043 15.2766ZM10.5936 11.5745H9.0602V9.7234H10.5936C10.9838 9.7234 11.2687 9.8 11.4482 9.95319C11.6355 10.0979 11.7291 10.3277 11.7291 10.6426C11.7291 10.9574 11.6355 11.1915 11.4482 11.3447C11.2687 11.4979 10.9838 11.5745 10.5936 11.5745Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2 6C2 2.68629 4.68629 0 8 0H38C41.3137 0 44 2.68629 44 6V23C44 25.2208 42.7934 27.1599 41 28.1973L40.251 26.307C41.3068 25.5869 42 24.3744 42 23V6C42 3.79086 40.2091 2 38 2H8C5.79086 2 4 3.79086 4 6V23C4 25.2091 5.79086 27 8 27H25L25.5 29H8C4.68629 29 2 26.3137 2 23V6ZM8 23C7.44772 23 7 23.4477 7 24C7 24.5523 7.44772 25 8 25H24.5L24 23H8ZM38 25H28.5L28.1927 23H38C38.5523 23 39 23.4477 39 24C39 24.5523 38.5523 25 38 25ZM32.2754 16.7012C32.459 16.9004 32.7191 17 33.0557 17C33.4077 17 33.6716 16.9004 33.8475 16.7012C34.0235 16.4938 34.1115 16.2075 34.1115 15.8423V12.9295H34.1344L36.9344 16.3278C37.118 16.5519 37.2863 16.722 37.4393 16.8382C37.6 16.9461 37.8104 17 38.0705 17C38.3765 17 38.606 16.917 38.759 16.751C38.9197 16.5851 39 16.3776 39 16.1286C39 15.8714 38.9005 15.6224 38.7016 15.3817L36.1795 12.2904L38.5639 9.54357C38.7322 9.3444 38.8202 9.12863 38.8279 8.89627C38.8432 8.6556 38.7781 8.44813 38.6328 8.27386C38.4951 8.09129 38.2656 8 37.9443 8C37.7377 8 37.5503 8.04979 37.382 8.14938C37.2213 8.24066 37.0683 8.3693 36.923 8.53527L34.1344 11.8714H34.1115V9.15768C34.1115 8.77593 34.0197 8.48963 33.8361 8.29876C33.6601 8.09959 33.4 8 33.0557 8C32.7191 8 32.459 8.09959 32.2754 8.29876C32.0918 8.48963 32 8.77593 32 9.15768V15.8423C32 16.2075 32.0918 16.4938 32.2754 16.7012Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.25 17C26.5028 17 25.8464 16.8143 25.2807 16.4429C24.7151 16.0633 24.2751 15.5351 23.9609 14.8583C23.6536 14.1816 23.5 13.3934 23.5 12.4938C23.5 11.8171 23.5873 11.2022 23.7619 10.6492C23.9434 10.0963 24.1983 9.62173 24.5265 9.22558C24.8617 8.82944 25.2598 8.5282 25.7207 8.32187C26.1816 8.10729 26.6913 8 27.25 8C27.9972 8 28.6501 8.18569 29.2088 8.55708C29.7744 8.92847 30.2144 9.45254 30.5286 10.1293C30.8429 10.7978 31 11.586 31 12.4938C31 13.1706 30.9092 13.7854 30.7277 14.3384C30.5531 14.8913 30.2982 15.37 29.963 15.7744C29.6348 16.1706 29.2402 16.4759 28.7793 16.6905C28.3184 16.8968 27.8087 17 27.25 17ZM28.1927 14.784C27.9344 14.9904 27.6201 15.0935 27.25 15.0935C26.6983 15.0935 26.2689 14.8666 25.9616 14.4127C25.6543 13.9505 25.5007 13.3109 25.5007 12.4938C25.5007 11.9409 25.5705 11.4746 25.7102 11.0949C25.8499 10.707 26.0489 10.414 26.3073 10.216C26.5726 10.0096 26.8869 9.90646 27.25 9.90646C27.8017 9.90646 28.2311 10.1334 28.5384 10.5873C28.8457 11.0413 28.9993 11.6768 28.9993 12.4938C28.9993 13.0385 28.9295 13.5048 28.7898 13.8927C28.6501 14.2806 28.4511 14.5777 28.1927 14.784Z'
            fill='currentColor'
        />
        <path
            d='M28.0201 22.336C27.7542 21.3747 26.7341 20.8043 25.7415 21.0618C24.7489 21.3194 24.16 22.3074 24.4259 23.2686L27.792 35.7449L24.7626 32.813C24.4456 32.5063 24.0397 32.3005 23.5989 32.2228C23.1581 32.1452 22.7034 32.1996 22.2952 32.3786C21.1017 32.9022 20.6405 34.3127 21.3056 35.4063L24.0199 39.8689C26.4733 43.9024 31.4033 45.8557 36.0723 44.6441C41.0568 43.3506 44.0149 38.3888 42.6793 33.5615L41.2946 28.5568L41.0119 27.776C40.7486 26.8244 39.7387 26.2598 38.7563 26.5148C37.796 26.7639 37.2173 27.7036 37.4372 28.6343M28.0201 22.336L30.1208 29.7586M28.0201 22.336L29.7464 28.4357M30.1208 29.7586L30.5294 31.2355L29.7464 28.4357M30.1208 29.7586L29.7464 28.4357M37.6446 29.3889L37.4539 28.6994C37.4479 28.6777 37.4423 28.656 37.4372 28.6343M37.646 29.3889L37.4372 28.6343M29.7464 28.4357C29.4831 27.4842 30.0754 26.5092 31.058 26.2541C32.0405 25.9992 33.0504 26.5639 33.3136 27.5154L34.0878 30.3122L33.5964 28.536C33.3332 27.5844 33.9162 26.6063 34.8988 26.3514C35.8813 26.0964 36.8912 26.6611 37.1545 27.6126L37.4372 28.6343'
            stroke='currentColor'
            strokeWidth='2'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default BookingIcon
