import React from 'react'

export const TutorialIcon = ({
    isMobile,
}: {
    isMobile?: boolean
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_10065_5574)'>
            <path
                d='M20.5821 6.50287C20.3521 5.69662 19.6744 5.06165 18.8139 4.84616C17.2542 4.45459 11 4.45459 11 4.45459C11 4.45459 4.74586 4.45459 3.18613 4.84616C2.32565 5.06168 1.64794 5.69662 1.41792 6.50287C1 7.96425 1 11.0133 1 11.0133C1 11.0133 1 14.0623 1.41792 15.5237C1.64794 16.3299 2.32565 16.9384 3.18613 17.1539C4.74586 17.5455 11 17.5455 11 17.5455C11 17.5455 17.2541 17.5455 18.8139 17.1539C19.6744 16.9384 20.3521 16.3299 20.5821 15.5237C21 14.0623 21 11.0133 21 11.0133C21 11.0133 21 7.96425 20.5821 6.50287ZM8.95453 13.7816V8.24499L14.1818 11.0133L8.95453 13.7816Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_10065_5574'>
                <rect
                    width='20'
                    height='18'
                    fill='white'
                    transform='translate(1 2)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default TutorialIcon
