import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { conferenceTypes } from './actionTypes'
import {
    getConference,
    editConference,
    createConference,
    deleteConference,
    getConferenceById,
} from '../service/conference'

import {
    IConference,
    FetchConferenceRequest,
    EditConferenceRequest,
    CreateConferenceRequest,
    DeleteConferenceRequest,
    FetchConferenceByIdRequest,
} from './types'

function* fetchConferenceByIdSaga(action: FetchConferenceRequest) {
    try {
        const response: AxiosResponse<IConference[]> = yield call(
            getConference,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: conferenceTypes.SET_CONFERENCE,
                payload: { conferenceDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchConferenceById(action: FetchConferenceByIdRequest) {
    try {
        const response: AxiosResponse<IConference> = yield call(
            getConferenceById,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: conferenceTypes.SET_RELATIVE_CONERENCE,
                payload: { relativeConference: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editConferenceSaga(action: EditConferenceRequest) {
    try {
        const response: AxiosResponse<IConference> = yield call(
            editConference,
            action.payload,
        )
        if (response.status === 200) {
            const { conferenceDetails } = yield select(
                state => state.conference,
            )
            const conferenceIdx = conferenceDetails.findIndex(
                (conference: IConference) =>
                    conference.id === action.payload.id,
            )
            const conferenceCopy = [...conferenceDetails]
            conferenceCopy[conferenceIdx] = { ...action.payload.conference }

            yield put({
                type: conferenceTypes.SET_CONFERENCE,
                payload: { conferenceDetails: conferenceCopy },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createConferenceSaga(action: CreateConferenceRequest) {
    try {
        const response: AxiosResponse<IConference[]> = yield call(
            createConference,
            action.payload,
        )
        if (response.status === 200) {
            const { conferenceDetails }: { conferenceDetails: IConference[] } =
                yield select(state => state.conference)
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            let newItems: IConference[] = []
            if (conferenceDetails.length) {
                newItems = response.data.filter(
                    item => item.language === conferenceDetails[0].language,
                )
            } else {
                newItems = response.data.filter(
                    item => item.language === action.payload.language,
                )
            }

            yield put({
                type: conferenceTypes.SET_CONFERENCE,
                payload: {
                    conferenceDetails: [...conferenceDetails].concat(newItems),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteConferenceSaga(action: DeleteConferenceRequest) {
    try {
        const response: AxiosResponse<IConference> = yield call(
            deleteConference,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { conferenceDetails } = yield select(
                state => state.conference,
            )
            const updatedConferences = conferenceDetails.filter(
                (conference: IConference) =>
                    conference.id !== action.payload.id,
            )
            yield put({
                type: conferenceTypes.SET_CONFERENCE,
                payload: { conferenceDetails: updatedConferences },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* conferenceSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            conferenceTypes.FETCH_CONFERENCE_REQUEST,
            fetchConferenceByIdSaga,
        ),
        takeLatest(conferenceTypes.EDIT_CONFERENCE, editConferenceSaga),
        takeLatest(
            conferenceTypes.CREATE_CONFERENCE_REQUEST,
            createConferenceSaga,
        ),
        takeLatest(
            conferenceTypes.DELETE_CONFERENCE_REQUEST,
            deleteConferenceSaga,
        ),
        takeLatest(
            conferenceTypes.FETCH_CONFERENCE_BY_ID_REQUEST,
            fetchConferenceById,
        ),
    ])
}

export default conferenceSaga
