import { conferenceTypes } from './actionTypes'
import {
    FetchConferenceRequest,
    EditConferenceRequest,
    FetchConferencePayload,
    EditConferencePayload,
    CreateConferencePayload,
    CreateConferenceRequest,
    DeleteConferencePayload,
    DeleteConferenceRequest,
    SetConference,
    IConference,
    FetchConferenceByIdPayload,
    FetchConferenceByIdRequest,
} from './types'

export const fetchConferenceRequest = (
    payload: FetchConferencePayload,
): FetchConferenceRequest => ({
    type: conferenceTypes.FETCH_CONFERENCE_REQUEST,
    payload,
})

export const fetchConferenceByIdRequest = (
    payload: FetchConferenceByIdPayload,
): FetchConferenceByIdRequest => ({
    type: conferenceTypes.FETCH_CONFERENCE_BY_ID_REQUEST,
    payload,
})

export const EditConferenceAction = (
    payload: EditConferencePayload,
): EditConferenceRequest => ({
    type: conferenceTypes.EDIT_CONFERENCE,
    payload,
})

export const CreateConferenceAction = (
    payload: CreateConferencePayload,
): CreateConferenceRequest => ({
    type: conferenceTypes.CREATE_CONFERENCE_REQUEST,
    payload,
})

export const DeleteConferenceAction = (
    payload: DeleteConferencePayload,
): DeleteConferenceRequest => ({
    type: conferenceTypes.DELETE_CONFERENCE_REQUEST,
    payload,
})

export const SetConferenceAction = (payload: {
    conferenceDetails?: IConference[]
}): SetConference => ({
    type: conferenceTypes.SET_CONFERENCE,
    payload,
})
