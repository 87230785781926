import React, { useState, useEffect } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { Document, Page, pdfjs } from 'react-pdf'
import ePub from 'epubjs'

// eslint-disable-next-line max-len
import { IEReader } from 'containers/dashboard/features/e-reader/store/types'

// eslint-disable-next-line max-len
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const BookItem = ({ book }: { book: IEReader }): React.ReactElement => {
    const [bookCover, setBookCover] = useState<string>('')

    const displayBook = (url: string) => {
        const bookData = ePub(url)
        bookData.ready.then(() => {
            bookData.coverUrl().then(coverUrl => {
                if (!coverUrl) return
                setBookCover(coverUrl)
            })
        })
    }

    useEffect(() => {
        displayBook(book.fileUrl)
    }, [book.fileUrl])

    const loadBook = (url: string | null) => {
        if (url === null) {
            return (
                <div className='m-reader__no-title'>
                    <p>No title</p>
                </div>
            )
        }
        if (url === '') {
            return (
                <div className='m-reader__no-title'>
                    <CircularProgress
                        className='new-loader--primary'
                        size='22px'
                    />
                </div>
            )
        }
        return <img src={bookCover} alt={book.title} />
    }

    return (
        <div className='m-reader__item new-loader--surface'>
            {book.type === 'Epub' ? (
                loadBook(bookCover)
            ) : (
                <div>
                    <Document file={book.fileUrl} onLoadError={console.error}>
                        <Page pageNumber={1} />
                    </Document>
                </div>
            )}
            <p className='m-reader__title new-loader--text'>{book.title}</p>
        </div>
    )
}

export default BookItem
