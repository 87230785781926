import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { PATH_AUTH } from 'config/paths'

import SmileMinion from 'assets/images/minions/SmileMinion.webp'

import { TError } from 'config/types'

import CustomButton from 'components/custom-button/CustomButton'
import CustomImage from 'components/custom-image/CustomImage'
import CustomInput from 'components/custom-input/CustomInput'
import Popup from 'components/popup/Popup'

import { industryChoose } from '../../services/auth'

import './IndustryPopup.scss'

interface IIndustryPopup {
    showPopup: boolean
    setShowPopup: (showPopup: boolean) => void
    btnLoading: boolean
    setBtnLoading: (showPopup: boolean) => void
}

const IndustryPopup = ({
    showPopup,
    setShowPopup,
    btnLoading,
    setBtnLoading,
}: IIndustryPopup): React.ReactElement => {
    const { t } = useTranslation(['popups', 'toast'])
    const { push } = useHistory()

    const [choosenIndustry, setChoosenIndustry] = useState<string>('')
    const [specifiedIndustry, setSpecifiedIndustry] = useState<string>('')
    const [industryError, setIndustryError] = useState<string>('')

    const indystryData = [
        { id: 'industry1', title: t('popups:industryPopup:education') },
        { id: 'industry2', title: t('popups:industryPopup:design') },
        { id: 'industry3', title: t('popups:industryPopup:services') },
        { id: 'industry4', title: t('popups:industryPopup:marketing') },
        { id: 'industry5', title: t('popups:industryPopup:it') },
        { id: 'industry6', title: t('popups:industryPopup:law') },
        { id: 'industry8', title: t('popups:industryPopup:blogging') },
        { id: 'industry9', title: t('popups:industryPopup:photo') },
        { id: 'industry10', title: t('popups:industryPopup:travel') },
        { id: 'industry11', title: t('popups:industryPopup:hospital') },
        { id: 'industry12', title: t('popups:industryPopup:other') },
    ]

    const handleIndustryChoise = async () => {
        if (choosenIndustry === '') {
            setIndustryError(t('popups:industryPopup:industryErr'))
            return
        }
        if (choosenIndustry === 'industry12' && specifiedIndustry === '') {
            setIndustryError(t('popups:industryPopup:customIndustryErr'))
            return
        }
        setBtnLoading(true)
        try {
            const industry =
                choosenIndustry === 'industry12'
                    ? specifiedIndustry
                    : indystryData.find(ind => ind.id === choosenIndustry)
                          ?.title
            if (!industry) return
            const industryRes = await industryChoose(industry)
            if (industryRes) {
                setBtnLoading(false)
                setShowPopup(false)
                push(`${PATH_AUTH.login}`)
            }
        } catch (err) {
            setBtnLoading(false)
            if (Array.isArray(err)) {
                err.forEach((error: TError) => {
                    console.error(error)
                    toast.error(t('toast:backendError'))
                })
            }
        }
    }

    const getIndustryTitle = (id: string): string | undefined =>
        indystryData.find(e => e.id === id)?.title

    return (
        <Popup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            // fixedHeight
            title={t('popups:industryPopup:title')}
            topPart={
                <div className='industry-popup__image'>
                    <CustomImage src={SmileMinion} />
                </div>
            }
            topDesign='straight'
            bodyPart={
                <>
                    <div className='industry-popup__info'>
                        {indystryData.map(industry => (
                            <div
                                key={industry.id}
                                className={`industry-popup__item ${
                                    industryError === 'No industry'
                                        ? 'industry-popup__item--error'
                                        : ''
                                }`}
                            >
                                <input
                                    id={industry.id}
                                    type='radio'
                                    value={industry.title}
                                    checked={industry.id === choosenIndustry}
                                    onChange={() => {
                                        setIndustryError('')
                                        setChoosenIndustry(industry.id)
                                    }}
                                />
                                <label htmlFor={industry.id}>
                                    {industry.title}
                                </label>
                            </div>
                        ))}
                        {getIndustryTitle(choosenIndustry) ===
                        t('popups:industryPopup:other') ? (
                            <CustomInput
                                name='industry'
                                value={specifiedIndustry}
                                touched={
                                    industryError ===
                                    t('popups:industryPopup:customIndustryErr')
                                }
                                error={
                                    industryError ===
                                    t('popups:industryPopup:customIndustryErr')
                                        ? t('popups:industryPopup:noIndustry')
                                        : ''
                                }
                                onChange={event => {
                                    setIndustryError('')
                                    setSpecifiedIndustry(event.target.value)
                                }}
                                type='text'
                                placeholder={t('popups:industryPopup:other')}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    <p className='industry-popup__note'>
                        {t('popups:industryPopup:thankYou')}
                    </p>
                </>
            }
            buttons={[
                <CustomButton
                    type='button'
                    size='large'
                    design='secondary'
                    loading={btnLoading}
                    handleClick={() => handleIndustryChoise()}
                >
                    {t('popups:industryPopup:start')}
                </CustomButton>,
            ]}
            error={
                industryError === t('popups:industryPopup:industryErr')
                    ? t('popups:industryPopup:selectFieldErr')
                    : undefined
            }
        />
    )
}

export default IndustryPopup
