export enum calendarTypes {
    FETCH_EVENTS_BY_DATE_REQUEST = 'FETCH_EVENTS_BY_DATE_REQUEST',
    FETCH_DATES_WITH_EVENTS = 'FETCH_DATES_WITH_EVENTS',
    FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST',
    FETCH_UNCONFIRMED_EVENTS = 'FETCH_UNCONFIRMED_EVENTS',
    FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS',
    CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST',
    EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST',
    DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST',
    SET_EVENT_DATE = 'SET_EVENT_DATE',
    SET_EVENT = 'SET_EVENT',
    SET_EVENTS = 'SET_EVENTS',
    SET_DATES = 'SET_DATES',
    SET_CURRENT_DAY = 'SET_CURRENT_DAY',
    SET_ACTIVE_START_DATE = 'SET_ACTIVE_START_DATE',
    SET_UNCONFIRMED_EVENTS = 'SET_UNCONFIRMED_EVENTS',
    SET_ALL_EVENTS = 'SET_ALL_EVENTS',
    ACCEPT_CALENDAR_EVENT = 'ACCEPT_CALENDAR_EVENT',
    REJECT_CALENDAR_EVENT = 'REJECT_CALENDAR_EVENT',
    SET_RELATIVE_EVENT = 'SET_RELATIVE_EVENT',
}
