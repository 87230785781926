import { informationTypes } from './actionTypes'
import {
    IInformation,
    FetchInformationRequest,
    FetchInformationPayload,
    FetchInformationItemRequest,
    FetchInformationItemPayload,
    CreateMultipleInformationRequest,
    CreateMultipleInformationPayload,
    CreateInformationRequest,
    CreateInformationPayload,
    EditInformationRequest,
    EditInformationPayload,
    DeleteInformationRequest,
    DeleteInformationPayload,
    SetInformation,
    SetInformationItem,
} from './types'

export const fetchInformationAction = (
    payload: FetchInformationPayload,
): FetchInformationRequest => ({
    type: informationTypes.FETCH_INFORMATION_REQUEST,
    payload,
})

export const fetchInformationItemAction = (
    payload: FetchInformationItemPayload,
): FetchInformationItemRequest => ({
    type: informationTypes.FETCH_INFORMATION_ITEM_REQUEST,
    payload,
})

export const createMultipleInformationAction = (
    payload: CreateMultipleInformationPayload,
): CreateMultipleInformationRequest => ({
    type: informationTypes.CREATE_MULTIPLE_INFORMATION,
    payload,
})

export const createInformationAction = (
    payload: CreateInformationPayload,
): CreateInformationRequest => ({
    type: informationTypes.CREATE_INFORMATION,
    payload,
})

export const editInformationAction = (
    payload: EditInformationPayload,
): EditInformationRequest => ({
    type: informationTypes.EDIT_INFORMATION,
    payload,
})

export const deleteInformationAction = (
    payload: DeleteInformationPayload,
): DeleteInformationRequest => ({
    type: informationTypes.DELETE_INFORMATION,
    payload,
})

export const SetInformationAction = (payload: {
    information: IInformation[] | undefined
}): SetInformation => ({
    type: informationTypes.SET_INFORMATION,
    payload,
})

export const SetInformationItemAction = (payload: {
    informationDetails: IInformation | undefined
}): SetInformationItem => ({
    type: informationTypes.SET_INFORMATION_ITEM,
    payload,
})

export const SetRelativeDetailsItem = (payload: {
    relativeDetails: IInformation | undefined
}) => ({
    type: informationTypes.SET_RELATIVE_INFORMATION,
    payload,
})
