import React from 'react'

import CustomImage from 'components/custom-image/CustomImage'
import moment from 'moment'

import { ReactComponent as Star } from 'assets/images/icons/Star.svg'
import { ReactComponent as Clock } from 'assets/images/icons/Clock.svg'
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg'
import SwitchCheckbox from 'components/custom-switch/SwitchCheckbox'

import {
    IManagement,
    TManagementShcedule,
} from 'containers/dashboard/features/booking/store/types'
import { useTranslation } from 'react-i18next'

interface IProps {
    managementSlots: IManagement | undefined
    isTop: boolean
    handleSwitch: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleScheduleClick: (schedule: TManagementShcedule) => void
}

const currencies = [
    { value: 'EUR', label: '€' },
    { value: 'UAH', label: '₴' },
    { value: 'USD', label: '$' },
]

const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

const BookingServices = ({
    managementSlots,
    isTop,
    handleSwitch,
    handleScheduleClick,
}: IProps): React.ReactElement => {
    const { t } = useTranslation(['booking'])

    return (
        <>
            <div className='m-booking__managment-top new-loader--surface new-loader--text'>
                <Star />
                <div className='m-booking__managment-top-text '>
                    {t('booking:topText')}
                </div>
                <SwitchCheckbox
                    id='isTop'
                    checked={isTop}
                    onChange={handleSwitch}
                />
            </div>
            {managementSlots?.schedule &&
            managementSlots.schedule.length > 0 ? (
                <div className='m-booking__managment-items'>
                    {managementSlots.schedule.map(schedule => {
                        const {
                            employeeViewModel: { isTopSpecialist, services },
                            employeeId,
                        } = schedule
                        if (isTop && !isTopSpecialist) return null

                        const serviceCurrency = currencies.find(
                            currency =>
                                currency.value === services?.[0]?.currency,
                        )?.label

                        const startTime = moment('00:00', 'HH:mm')
                        const duration = startTime.add(
                            services?.[0]?.duration,
                            'minutes',
                        )

                        return services?.map(service => (
                            <button
                                className='m-schedule-item new-loader--surface'
                                type='button'
                                key={employeeId}
                                onClick={() => {
                                    handleScheduleClick(schedule)
                                }}
                            >
                                <div className='m-schedule-item__image'>
                                    <CustomImage
                                        alt={t('booking:serviceImage')}
                                        src={service?.imageUrl}
                                    />
                                </div>
                                <div className='m-schedule-item__info new-loader--text'>
                                    <div className='m-schedule-item__name'>
                                        {service?.name}
                                    </div>
                                    <div className='m-schedule-item__description'>
                                        {service?.description.replace(
                                            regexForHtmlCharts,
                                            ' ',
                                        )}
                                    </div>
                                    <div className='m-schedule-item__service-info'>
                                        <div className='m-schedule-item__service-price new-loader--primary-color'>
                                            {serviceCurrency}{' '}
                                            {service?.price ||
                                                'No price transl'}
                                        </div>
                                        <div className='m-schedule-item__service-dot'>
                                            <span className='custom-dot new-loader--text-background' />
                                        </div>
                                        <div className='m-schedule-item__service-termin'>
                                            <Clock />
                                            {duration.format('HH:mm')}
                                        </div>
                                    </div>
                                    <div className='m-schedule-item__more'>
                                        {t('booking:more')}
                                        <ArrowRight className='new-loader--primary-color' />
                                    </div>
                                </div>
                            </button>
                        ))
                    })}
                </div>
            ) : (
                <div className='no-items'>No items</div>
            )}
        </>
    )
}

export default BookingServices
