import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'

// eslint-disable-next-line max-len
import { fetchLocationAction } from 'containers/dashboard/features/map/store/actions'
// eslint-disable-next-line max-len
import CustomMap from 'containers/dashboard/features/map/components/map-view/MapView'
import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import { RootState } from 'redux/reducers/rootReducer'

import { getAppIdCookie } from 'utils/token'
import { TError } from 'config/types'

import './MapItemPage.scss'

type PageParams = {
    id: string
}

const MapItemPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast'])

    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { id } = useParams<PageParams>()
    const { location } = useSelector((state: RootState) => state.map)

    useEffect(() => {
        if (!appId) return
        if (!id) return
        dispatch(
            fetchLocationAction({
                locationId: id,
                appId,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    return (
        <>
            {location ? (
                <div className='m-map-item'>
                    <CustomMap
                        latitude={+location.latitude}
                        longitude={+location.longitude}
                    />
                    <div className='m-map-item__info new-loader--surface'>
                        <div className='m-map-item__title new-loader--text'>
                            {location.name}
                        </div>
                        <div className='m-map-item__subtitle new-loader--text'>
                            {location.address}
                        </div>
                        <CustomImage
                            className='m-map-item__image'
                            src={location.imageUrl}
                        />
                        <div className='m-map-item__desc new-loader--text'>
                            {parse(location.description)}
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default MapItemPage
