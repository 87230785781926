import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import { TError, IPagination } from 'config/types'
import { getAppIdCookie } from 'utils/token'

// eslint-disable-next-line max-len
import { fetchEReadersAction } from 'containers/dashboard/features/e-reader/store/actions'

import { IEReader } from 'containers/dashboard/features/e-reader/store/types'

import Preloader from 'components/preloader/Preloader'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

import ReaderView from './components/reader-view/ReaderView'

const EReaderPage = () => {
    const { t, i18n } = useTranslation(['toast'])
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const { pathname } = useLocation()

    const { eReaders } = useSelector((state: RootState) => state.eReader)

    const appId = getAppIdCookie()

    useEffect(() => {
        if (pathname.includes('loader/e-reader') && appId) {
            setIsLoading(true)
            dispatch(
                fetchEReadersAction({
                    appId,
                    page: 1,
                    language: i18n.language,
                    callback: (
                        type: string,
                        data: IPagination<IEReader>,
                        errorArray?: TError[],
                    ) => {
                        console.log(data)
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                        setIsLoading(false)
                    },
                }),
            )
        }
    }, [dispatch, appId, pathname, t, i18n.language])

    return !isLoading ? (
        eReaders && eReaders.length > 0 ? (
            <ReaderView books={eReaders} />
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}
export default EReaderPage as React.FC
