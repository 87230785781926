import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { USERS_URLS } from 'config/urls'

import { ISettings } from '../store/types'

export const getSettings = (): Promise<AxiosResponse<ISettings>> =>
    axiosInstance.get(`${USERS_URLS.user}`)

export const editSettings = ({
    settings,
}: {
    settings: ISettings
}): Promise<AxiosResponse<ISettings>> =>
    axiosInstance.put(`${USERS_URLS.user}`, settings)
