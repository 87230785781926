import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { launchScreenTypes } from './actionTypes'
import { getLaunchScreen, editLaunchScreen } from '../service/launchScreen'

import {
    ILaunchScreen,
    FetchLaunchScreenRequest,
    EditLaunchScreenRequest,
} from './types'

function* fetchLaunchScreenSaga(action: FetchLaunchScreenRequest) {
    try {
        const response: AxiosResponse<ILaunchScreen> = yield call(
            getLaunchScreen,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: launchScreenTypes.SET_LAUNCH_SCREEN,
                payload: { settings: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editLaunchScreenSaga(action: EditLaunchScreenRequest) {
    try {
        const response: AxiosResponse<ILaunchScreen> = yield call(
            editLaunchScreen,
            action.payload,
        )

        if (response.status === 200) {
            const { settings } = yield select(state => state.launchScreen)

            const newSettings = { ...settings, ...action.payload.settings }

            yield put({
                type: launchScreenTypes.SET_LAUNCH_SCREEN,
                payload: { settings: newSettings },
            })

            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* launchScreenSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            launchScreenTypes.FETCH_LAUNCH_SCREEN_REQUEST,
            fetchLaunchScreenSaga,
        ),
    ])
    yield all([
        takeLatest(
            launchScreenTypes.EDIT_LAUNCH_SCREEN_REQUEST,
            editLaunchScreenSaga,
        ),
    ])
}

export default launchScreenSaga
