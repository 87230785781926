import React from 'react'

const CategoryHome = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.9998 1.83276C10.8786 1.83278 10.7586 1.85684 10.6467 1.90355C10.5349 1.95026 10.4334 2.0187 10.3481 2.1049L1.1027 10.1723C1.04496 10.2149 0.99803 10.2705 0.965689 10.3345C0.933348 10.3986 0.916501 10.4693 0.916504 10.5411C0.916504 10.6627 0.964792 10.7792 1.05075 10.8652C1.1367 10.9511 1.25328 10.9994 1.37484 10.9994H3.6665V18.3328C3.6665 18.8388 4.07717 19.2494 4.58317 19.2494H8.24984C8.75584 19.2494 9.1665 18.8388 9.1665 18.3328V12.8328H12.8332V18.3328C12.8332 18.8388 13.2438 19.2494 13.7498 19.2494H17.4165C17.9225 19.2494 18.3332 18.8388 18.3332 18.3328V10.9994H20.6248C20.7464 10.9994 20.863 10.9511 20.9489 10.8652C21.0349 10.7792 21.0832 10.6627 21.0832 10.5411C21.0832 10.4693 21.0663 10.3986 21.034 10.3345C21.0016 10.2705 20.9547 10.2149 20.897 10.1723L11.6569 2.11027C11.6551 2.10847 11.6533 2.10668 11.6515 2.1049C11.5663 2.0187 11.4648 1.95026 11.3529 1.90355C11.2411 1.85684 11.1211 1.83278 10.9998 1.83276Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default CategoryHome
