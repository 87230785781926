export const categories = [
    {
        id: '1',
        value: 1,
        label: 'Home',
        icon: 'Home',
        isIconLeft: true,
    },
    {
        id: '2',
        value: 2,
        label: 'Office',
        icon: 'Office',
        isIconLeft: true,
    },
    {
        id: '3',
        value: 3,
        label: 'Purchases',
        icon: 'Purchases',
        isIconLeft: true,
    },
    {
        id: '4',
        value: 4,
        label: 'Wish list',
        icon: 'WishList',
        isIconLeft: true,
    },
]

export const priorities = [
    {
        id: '1',
        value: 1,
        label: 'High',
        icon: 'High',
        isIconLeft: false,
    },
    {
        id: '2',
        value: 2,
        label: 'Medium',
        icon: 'Medium',
        isIconLeft: false,
    },
    {
        id: '3',
        value: 3,
        label: 'Low',
        icon: 'Low',
        isIconLeft: false,
    },
]
