import {
    AllEffect,
    ForkEffect,
    all,
    call,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { IPagination } from 'config/types'
import { sagaErrorHandle } from 'utils/errHandle'
import { RootState } from 'redux/reducers/rootReducer'

import { paymentsTypes } from './actionTypes'
import {
    CreatePaymentRequest,
    DeletePaymentRequest,
    EditPaymentRequest,
    FetchPaymentRequest,
    FetchPaymentsRequest,
    IPayment,
} from './types'
import {
    createPayments,
    deletePayment,
    editPayment,
    getPayment,
    getPayments,
} from '../service/payment'

function* fetchPaymentSaga(action: FetchPaymentRequest) {
    try {
        const response: AxiosResponse<IPayment> = yield call(
            getPayment,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: paymentsTypes.SET_PAYMENT,
                payload: { payment: response.data },
            })
            if (action.payload.callback) action.payload.callback('success')
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchPaymentsSaga(action: FetchPaymentsRequest) {
    try {
        const response: AxiosResponse<IPagination<IPayment>> = yield call(
            getPayments,
            action.payload,
        )

        if (response.data) {
            const pageNumbers = response.data.pageNumber
                ? (response.data.pageNumber - 1) * 10
                : 0
            const newItems = response.data.items.map((item, index) => ({
                ...item,
                queryNumber: index + 1 + pageNumbers,
                isEditable: false,
            }))

            if (action.payload.callback) {
                action.payload.callback('success', response.data)
            }

            yield put({
                type: paymentsTypes.SET_PAYMENTS,
                payload: { payments: newItems },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createPaymentsSaga(action: CreatePaymentRequest) {
    try {
        const response: AxiosResponse<IPayment> = yield call(
            createPayments,
            action.payload,
        )

        console.log(response, 'response')

        if (response.status === 200 || response.status === 204) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editPaymentSaga(action: EditPaymentRequest) {
    try {
        const response: AxiosResponse<IPayment> = yield call(
            editPayment,
            action.payload,
        )

        if (response.status === 200) {
            console.log('edit saga', response)
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deletePaymentSaga(action: DeletePaymentRequest) {
    try {
        const response: AxiosResponse<IPayment> = yield call(
            deletePayment,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')

                const { payments } = yield select(
                    (state: RootState) => state.payments,
                )
                const updatedPayments = payments.filter(
                    (payment: IPayment) => payment.id !== action.payload.id,
                )
                yield put({
                    type: paymentsTypes.SET_PAYMENTS,
                    payload: { payments: updatedPayments },
                })
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* paymentsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(paymentsTypes.FETCH_PAYMENTS_REQUEST, fetchPaymentsSaga),
    ])
    yield all([takeLatest(paymentsTypes.FETCH_PAYMENT_BY_ID, fetchPaymentSaga)])
    yield all([
        takeLatest(paymentsTypes.CREATE_PAYMENT_REQUEST, createPaymentsSaga),
    ])
    yield all([takeLatest(paymentsTypes.EDIT_PAYMENT_REQUEST, editPaymentSaga)])
    yield all([
        takeLatest(paymentsTypes.DELETE_PAYMENT_REQUEST, deletePaymentSaga),
    ])
}

export default paymentsSaga
