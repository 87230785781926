import React from 'react'

export const MyAppsIcon = ({
    isActive,
    isSmall,
}: {
    isActive?: boolean
    isSmall?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : '46'}
        height={isSmall ? '25' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_5883_65373)'>
            <path
                d='M14.6944 26.8056C17.1797 26.8056 19.1944 28.8204 19.1944 31.3056V40.25C19.1944 42.7352 17.1797 44.75 14.6944 44.75H5.75C3.26473 44.75 1.25 42.7352 1.25 40.25V31.3056C1.25 28.8204 3.26473 26.8056 5.75 26.8056H14.6944ZM40.25 26.8056C42.7352 26.8056 44.75 28.8204 44.75 31.3056V40.25C44.75 42.7352 42.7352 44.75 40.25 44.75H31.3056C28.8204 44.75 26.8056 42.7352 26.8056 40.25V31.3056C26.8056 28.8204 28.8204 26.8056 31.3056 26.8056H40.25ZM14.6944 1.25C17.1797 1.25 19.1944 3.26472 19.1944 5.75V14.6944C19.1944 17.1797 17.1797 19.1944 14.6944 19.1944H5.75C3.26472 19.1944 1.25 17.1797 1.25 14.6944V5.75C1.25 3.26472 3.26472 1.25 5.75 1.25H14.6944ZM40.25 1.25C42.7352 1.25 44.75 3.26473 44.75 5.75V14.6944C44.75 17.1797 42.7352 19.1944 40.25 19.1944H31.3056C28.8204 19.1944 26.8056 17.1797 26.8056 14.6944V5.75C26.8056 3.26473 28.8204 1.25 31.3056 1.25H40.25Z'
                stroke={isActive ? '#FFED00' : '#2B2A29'}
                strokeWidth='2.5'
            />
        </g>
        <defs>
            <clipPath id='clip0_5883_65373'>
                <rect width='46' height='46' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default MyAppsIcon
