import { testSummaryTypes } from './actionTypes'
import { TestSummaryState, TestSummaryActions } from './types'

const initialState: TestSummaryState = {
    testSummary: undefined,
}

export default (
    state = initialState,
    action: TestSummaryActions,
): TestSummaryState => {
    switch (action.type) {
        case testSummaryTypes.SET_TEST_SUMMARY:
            return {
                ...state,
                testSummary: action.payload.testSummary,
            }
        default:
            return {
                ...state,
            }
    }
}
