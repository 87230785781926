import React from 'react'

export const IconIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M40.825 41H5.17498C2.32091 41 0 38.6665 0 35.7969V9.20309C0 6.33351 2.32091 4 5.17498 4H40.825C43.6791 4 46 6.33351 46 9.20309V35.7969C46 38.6665 43.6791 41 40.825 41ZM5.17498 7.46872C4.22278 7.46872 3.44999 8.24571 3.44999 9.20309V35.7969C3.44999 36.7543 4.22278 37.5313 5.17498 37.5313H40.825C41.7772 37.5313 42.55 36.7543 42.55 35.7969V9.20309C42.55 8.24571 41.7772 7.46872 40.825 7.46872H5.17498Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M11.5002 20.1877C8.9632 20.1877 6.90002 18.1133 6.90002 15.5626C6.90002 13.0119 8.9632 10.9375 11.5002 10.9375C14.0371 10.9375 16.0999 13.0119 16.0999 15.5626C16.0999 18.1133 14.0371 20.1877 11.5002 20.1877ZM11.5002 14.4062C10.8651 14.4062 10.35 14.9245 10.35 15.5626C10.35 16.2007 10.8651 16.719 11.5002 16.719C12.1348 16.719 12.6499 16.2007 12.6499 15.5626C12.6499 14.9245 12.1348 14.4062 11.5002 14.4062Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M1.77091 38.6405C1.32955 38.6405 0.887772 38.4716 0.552122 38.132C-0.121704 37.4545 -0.121704 36.3557 0.552122 35.6782L10.948 25.2259C12.5214 23.644 15.0811 23.644 16.6519 25.2259L19.4327 28.0218L27.9747 17.7126C28.7383 16.7921 29.8585 16.2602 31.0478 16.2535C32.3264 16.1819 33.3616 16.7667 34.1318 17.6758L45.5814 31.1065C46.2021 31.8327 46.1171 32.9289 45.3948 33.553C44.6705 34.1776 43.5826 34.0921 42.9615 33.3659L31.5077 19.9305C31.3557 19.7476 31.1695 19.7569 31.0659 19.7243C30.9674 19.7243 30.7762 19.7544 30.6246 19.9348L20.8726 31.7056C20.5618 32.0799 20.1091 32.3069 19.626 32.3276C19.1337 32.3552 18.6692 32.1655 18.3285 31.8212L14.2118 27.6818C13.9196 27.3879 13.6783 27.3879 13.386 27.6818L2.99011 38.132C2.65404 38.4716 2.21268 38.6405 1.77091 38.6405Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
    </svg>
)

export default IconIcon
