import React from 'react'

const DeleteIcon = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9.16666 4.58284H12.8333C12.8333 3.57032 12.0125 2.74951 11 2.74951C9.98744 2.74951 9.16666 3.57032 9.16666 4.58284ZM7.79166 4.58284C7.79166 2.81093 9.22808 1.37451 11 1.37451C12.7719 1.37451 14.2083 2.81093 14.2083 4.58284H19.4792C19.8588 4.58284 20.1667 4.89065 20.1667 5.27034C20.1667 5.65004 19.8588 5.95784 19.4792 5.95784H18.27L17.1957 17.0598C17.0252 18.8218 15.5444 20.1662 13.7741 20.1662H8.22588C6.45566 20.1662 4.97488 18.8218 4.80436 17.0598L3.72998 5.95784H2.52083C2.14114 5.95784 1.83333 5.65004 1.83333 5.27034C1.83333 4.89065 2.14114 4.58284 2.52083 4.58284H7.79166ZM9.625 8.93701C9.625 8.55732 9.31718 8.24951 8.9375 8.24951C8.5578 8.24951 8.25 8.55732 8.25 8.93701V15.812C8.25 16.1917 8.5578 16.4995 8.9375 16.4995C9.31718 16.4995 9.625 16.1917 9.625 15.812V8.93701ZM13.0625 8.24951C13.4422 8.24951 13.75 8.55732 13.75 8.93701V15.812C13.75 16.1917 13.4422 16.4995 13.0625 16.4995C12.6828 16.4995 12.375 16.1917 12.375 15.812V8.93701C12.375 8.55732 12.6828 8.24951 13.0625 8.24951ZM6.17298 16.9273C6.27528 17.9845 7.16374 18.7912 8.22588 18.7912H13.7741C14.8362 18.7912 15.7247 17.9845 15.827 16.9273L16.8886 5.95784H5.1114L6.17298 16.9273Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default DeleteIcon
