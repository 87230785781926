import React, { useRef, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { useCookies } from 'react-cookie'
import { toast } from 'react-toastify'

import {
    PATH_ACCOUNT,
    PATH_LANGUAGE,
    PATH_BILLING,
    PATH_AUTH,
    PATH_APPLICATIONS,
    PATH_TUTORIAL,
    PATH_NEW_LOADER,
    PATH_NOTIFICATIONS,
} from 'config/paths'
import { getAppIdCookie } from 'utils/token'
import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

import { ReactComponent as Play } from 'assets/images/icons/Play.svg'
import { ReactComponent as Close } from 'assets/images/icons/Close.svg'
// eslint-disable-next-line max-len
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRightVector.svg'

import { SetAiPopupAction } from 'layouts/dashboard/store/actions'
import { SetApplicationsAction } from 'containers/app-list/store/actions'
import CustomButton from 'components/custom-button/CustomButton'
import { RootState } from 'redux/reducers/rootReducer'

import HeaderButtonRender from '../../HeaderButtonRender'
import FollowUs from '../follow-us/FollowUs'
import Credits from '../credits/Credits'

import './SideMenu.scss'

const SideMenu = ({
    setShowSideMenu,
}: {
    setShowSideMenu: (sideMenu: boolean) => void
}): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast', 'nav'])

    const { showAd } = useSelector((state: RootState) => state.menus)

    const dispatch = useDispatch()
    const appId = getAppIdCookie()
    const cookies = useCookies(['isExpired', 'isTrialUsed'])[0]
    const queryMax = useMediaQuery('(max-width: 960px)')
    const min961 = useMediaQuery('(min-width: 961px)')
    const max1600 = useMediaQuery('(max-width: 1600px)')
    const removeCookie = useCookies([
        'token',
        'refresh_token',
        'applicationId',
        'paid_plan',
        'app_name',
        'active_features',
        'emulator_palette',
        'next_payment_date',
        'next_free',
        'saw_hints_mobile',
        'points',
        'isTrialUsed',
        'app_languages',
    ])[2]

    useEffect(() => {
        document.body.style.overflowY = 'hidden'

        return () => {
            document.body.style.overflowY = 'scroll'
        }
    }, [])

    const history = useHistory()

    const ref = useRef(null)

    useClickedOutsideHandler({ ref, func: () => setShowSideMenu(false) })
    console.log(cookies.isExpired, 'isExp')

    const menuButtons = [
        {
            name: t('nav:notifications'),
            icon: 'notifications',
            onClick: () => {
                setShowSideMenu(false)
                history.push(PATH_NOTIFICATIONS.root)
            },
        },
        {
            name: t('nav:myApps'),
            icon: 'my-apps',
            onClick: () => {
                setShowSideMenu(false)
                removeCookie('applicationId', { path: '/' })
                if (cookies.isExpired === 'true') {
                    toast.error(t('toast:subscribeError'))

                    history.push(PATH_BILLING.root)
                    return
                }
                history.push(PATH_APPLICATIONS.root)
            },
        },
        {
            name: t('nav:ai'),
            icon: 'ai',
            onClick: () => {
                setShowSideMenu(false)
                dispatch(
                    SetAiPopupAction({
                        isOpen: true,
                    }),
                )
            },
        },
        {
            name: t('nav:tutorial'),
            icon: 'tutorial',
            onClick: () => {
                setShowSideMenu(false)
                removeCookie('applicationId', { path: '/' })
                if (cookies.isExpired === 'true') {
                    toast.error(t('toast:subscribeError'))
                    history.push(PATH_BILLING.root)
                    return
                }
                history.push(PATH_TUTORIAL.root)
            },
        },
        {
            name: t('nav:billing'),
            icon: 'billing',
            onClick: () => {
                setShowSideMenu(false)
                removeCookie('applicationId', { path: '/' })
                history.push(PATH_BILLING.root)
            },
        },
        {
            name: t('nav:settings'),
            icon: 'account',
            onClick: () => {
                setShowSideMenu(false)
                removeCookie('applicationId', { path: '/' })
                if (cookies.isExpired === 'true') {
                    toast.error(t('toast:subscribeError'))
                    history.push(PATH_BILLING.root)
                    return
                }
                history.push(PATH_ACCOUNT.general)
            },
        },
        {
            name: (
                <div className='side-menu__name'>
                    <span className='side-menu__label'>
                        {t('nav:language')}
                    </span>
                    <span className='side-menu__value'>
                        {i18n.language === 'en' ? 'en' : 'ua'} <ArrowRight />
                    </span>
                </div>
            ),
            icon: 'language',
            onClick: () => {
                setShowSideMenu(false)
                removeCookie('applicationId', { path: '/' })
                if (cookies.isExpired === 'true') {
                    toast.error(t('toast:subscribeError'))
                    history.push(PATH_BILLING.root)
                    return
                }
                history.push(PATH_LANGUAGE.root)
            },
        },
        {
            name: t('nav:toPromo'),
            icon: 'to-promo',
            onClick: () => {
                if (!process.env.REACT_APP_PROMO_REDIRECT_URL) return
                window.location.href = process.env.REACT_APP_PROMO_REDIRECT_URL
            },
        },
        ...(max1600 && min961
            ? [
                  {
                      name: t('nav:emulator'),
                      icon: 'show-emulator',
                      onClick: () => {
                          setShowSideMenu(false)
                          if (cookies.isExpired === 'true') {
                              toast.error(t('toast:subscribeError'))
                              history.push(PATH_BILLING.root)
                              return
                          }
                          history.push(PATH_NEW_LOADER.welcome)
                      },
                  },
              ]
            : []),
        {
            name: t('nav:logout'),
            icon: 'logout',
            onClick: () => {
                setShowSideMenu(false)
                dispatch(
                    SetApplicationsAction({
                        applications: undefined,
                    }),
                )
                history.push(PATH_AUTH.login)
                // TODO create func for all at once
                removeCookie('token', { path: '/' })
                removeCookie('refresh_token', { path: '/' })
                removeCookie('applicationId', { path: '/' })
                removeCookie('paid_plan', { path: '/' })
                removeCookie('app_name', { path: '/' })
                removeCookie('active_features', { path: '/' })
                removeCookie('next_payment_date', { path: '/' })
                removeCookie('next_free', { path: '/' })
                removeCookie('emulator_palette', { path: '/' })
                // removeCookie('saw_hints_mobile', { path: '/' })
                removeCookie('points', { path: '/' })
                removeCookie('isTrialUsed', { path: '/' })
                removeCookie('app_languages', { path: '/' })
                localStorage.removeItem('applications')
            },
        },
    ]

    return (
        <div
            className={`side-menu ${
                // (cookies.isTrialUsed === 'false' ||
                //     cookies.isExpired === 'true') &&
                showAd ? 'side-menu--sale' : ''
            }`}
            ref={ref}
        >
            {queryMax && <div className='constructor-overlay' />}
            <div className='side-menu__wrapper'>
                {queryMax ? (
                    <div className='side-menu__close'>
                        <button
                            type='button'
                            onClick={() => {
                                setShowSideMenu(false)
                            }}
                        >
                            <Close />
                        </button>
                    </div>
                ) : null}
                {queryMax ? (
                    <div className='side-menu__credits'>
                        <Credits />
                        <p>{t('nav:creditsInfo')}</p>
                    </div>
                ) : null}
                <div className='side-menu__mobile'>
                    <div className='side-menu__items'>
                        {menuButtons.map(item => (
                            <HeaderButtonRender
                                key={item.icon}
                                name={item.name}
                                icon={item.icon}
                                onClick={item.onClick}
                            />
                        ))}
                        {queryMax && appId ? (
                            <div className='side-menu__emulator'>
                                <CustomButton
                                    design='secondary'
                                    handleClick={() => {
                                        setShowSideMenu(false)
                                        history.push(PATH_NEW_LOADER.welcome)
                                    }}
                                    size='small'
                                >
                                    <Play />
                                    {t('nav:emulator')}
                                </CustomButton>
                            </div>
                        ) : null}
                    </div>

                    {queryMax ? <FollowUs /> : null}
                </div>
            </div>
        </div>
    )
}

export default SideMenu
