import React, { CSSProperties } from 'react'

import GridLoader from 'react-spinners/GridLoader'

const override: CSSProperties = {
    display: 'block',
    margin: '150px auto',
}

const Preloader = (): React.ReactElement => (
    <>
        <GridLoader loading size={15} margin={2} cssOverride={override} />
    </>
)

export default Preloader as React.FC
