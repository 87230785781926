import React from 'react'

export const ConferenceIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M31.6037 38.8536C33.5634 39.647 35.86 40.1028 38.321 40.1028C38.9361 40.1028 39.4348 40.5276 39.4348 41.0514C39.4348 41.5753 38.9361 42 38.321 42C35.5275 42 32.9056 41.4766 30.6553 40.5655C28.415 39.6585 26.5402 38.3656 25.2233 36.8182H20.7767C19.4598 38.3656 17.585 39.6585 15.3447 40.5655C13.0944 41.4766 10.4725 42 7.67902 42C7.06392 42 6.56522 41.5753 6.56522 41.0514C6.56522 40.5276 7.06392 40.1028 7.67902 40.1028C10.14 40.1028 12.4366 39.647 14.3963 38.8536C15.7225 38.3167 16.8932 37.6257 17.8532 36.8182H7.47826C4.4527 36.8182 2 34.4982 2 31.6364V9.18182C2 6.31998 4.4527 4 7.47826 4H38.5217C41.5473 4 44 6.31998 44 9.18182V31.6364C44 34.4982 41.5473 36.8182 38.5217 36.8182H28.1468C29.1068 37.6257 30.2775 38.3167 31.6037 38.8536ZM38.5217 5.72727H7.47826C5.46122 5.72727 3.82609 7.27393 3.82609 9.18182V28.1818H42.1739V9.18182C42.1739 7.27393 40.5388 5.72727 38.5217 5.72727ZM7.47826 35.0909H38.5217C40.5388 35.0909 42.1739 33.5443 42.1739 31.6364V29.9091H3.82609V31.6364C3.82609 33.5443 5.46122 35.0909 7.47826 35.0909Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.2322 15.6936C27.0539 14.9164 27.5652 13.8282 27.5652 12.6364C27.5652 11.4445 27.0539 10.3564 26.2322 9.57909C25.5017 8.88819 24.5339 8.43909 23.4565 8.33546C23.3104 8.31818 23.1461 8.31818 23 8.31818C22.8539 8.31818 22.6896 8.31818 22.5435 8.33546C21.4661 8.43909 20.4983 8.88819 19.7678 9.57909C18.9461 10.3564 18.4348 11.4445 18.4348 12.6364C18.4348 13.8282 18.9461 14.9164 19.7678 15.6936C19.8432 15.7649 19.9213 15.8337 20.002 15.8999C19.1973 16.2811 18.4671 16.8129 17.8416 17.4653C16.521 18.8425 15.6957 20.7534 15.6957 22.8365V23.8636C15.6957 24.8176 16.5132 25.5909 17.5217 25.5909H28.4783C29.4868 25.5909 30.3043 24.8176 30.3043 23.8636V22.8365C30.3043 20.7362 29.479 18.8425 28.1584 17.4653C27.5385 16.8187 26.8058 16.286 25.9945 15.9028C26.0765 15.8357 26.1557 15.7659 26.2322 15.6936ZM23 15.2273C22.244 15.2273 21.5537 14.9371 21.0607 14.4707C20.5677 14.0044 20.2609 13.3515 20.2609 12.6364C20.2609 11.9213 20.5677 11.2684 21.0607 10.802C21.499 10.3875 22.0797 10.118 22.7261 10.0558C22.8137 10.0455 22.9123 10.0455 23 10.0455C23.0877 10.0455 23.1863 10.0455 23.2739 10.0558C23.9203 10.118 24.501 10.3875 24.9393 10.802C25.4323 11.2684 25.7391 11.9213 25.7391 12.6364C25.7391 13.3515 25.4323 14.0044 24.9393 14.4707C24.4463 14.9371 23.756 15.2273 23 15.2273ZM17.5217 22.0273C17.5217 20.6386 18.1408 19.3647 19.1312 18.4465C20.134 17.5169 21.4958 16.9545 22.9938 16.9545C24.5042 16.9545 25.8784 17.5284 26.8688 18.4465C27.8592 19.3647 28.4783 20.6272 28.4783 22.0273V23.4318C28.4783 23.6703 28.2739 23.8636 28.0217 23.8636H17.9783C17.7261 23.8636 17.5217 23.6703 17.5217 23.4318V22.0273Z'
            fill='currentColor'
        />
        <path
            d='M7.47826 31.6364H38.5217C39.026 31.6364 39.4348 32.023 39.4348 32.5C39.4348 32.977 39.026 33.3636 38.5217 33.3636H7.47826C6.974 33.3636 6.56522 32.977 6.56522 32.5C6.56522 32.023 6.974 31.6364 7.47826 31.6364Z'
            fill='currentColor'
        />
    </svg>
)

export default ConferenceIcon
