export enum employeeTypes {
    FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST',
    FETCH_EMPLOYEE_BY_ID = 'FETCH_EMPLOYEE_BY_ID',
    CREATE_EMPLOYEE_REQUEST = 'CREATE_EMPLOYEE_REQUEST',
    EDIT_EMPLOYEE_REQUEST = 'EDIT_EMPLOYEE_REQUEST',
    DELETE_EMPLOYEE_REQUEST = 'DELETE_EMPLOYEE_REQUEST',
    SET_EMPLOYEES = 'SET_EMPLOYEES',
    SET_EMPLOYEE = 'SET_EMPLOYEE',
    SET_REALTIVE_EMPLOYEE = 'SET_REALTIVE_EMPLOYEE',
}
