import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const GalleryRoutes = [
    {
        id: 1,
        module: 'loader-gallery',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.gallery,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/gallery/gallery-page/GalleryPage'
                ),
        ),
    },
    {
        id: 2,
        module: 'loader-gallery',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.gallery}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/gallery/image-page/ImagePage'
                ),
        ),
    },
]
