import React from 'react'

export const Firebase = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '27' : '34'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 34 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M0 37.1296L0.282562 36.7397L13.6491 11.7441L13.6774 11.4795L7.78509 0.57179C7.29143 -0.341924 5.90168 -0.110905 5.73967 0.911926L0 37.1296Z'
            fill={isActive ? '#FFA712' : '#9D9E9E'}
        />
        <path
            d='M0.166748 36.8325L0.380498 36.4217L13.607 11.688L7.72948 0.73596C7.241 -0.16818 6.00054 0.0623034 5.84023 1.07442L0.166748 36.8325Z'
            fill={isActive ? '#FFA712' : '#BABBBB'}
        />
        <path
            d='M17.9727 19.7302L22.3599 15.302L17.9714 7.04549C17.5548 6.26396 16.3916 6.2617 15.9795 7.04549L13.6349 11.4524V11.8277L17.9727 19.7302Z'
            fill={isActive ? '#FFA712' : '#9D9E9E'}
        />
        <path
            d='M17.8945 19.5456L22.1597 15.2406L17.8945 7.23638C17.4895 6.47658 16.5056 6.39698 16.1049 7.15897L13.7478 11.6341L13.6774 11.8629L17.8945 19.5456Z'
            fill={isActive ? '#FFA712' : '#9D9E9E'}
        />
        <path
            d='M0 37.1296L0.128081 37.0027L0.59344 36.8163L17.6972 20.0225L17.9139 19.4412L13.6472 11.4282L0 37.1296Z'
            fill={isActive ? '#FFA712' : '#9D9E9E'}
        />
        <path
            d='M18.5207 45.5993L34 37.0912L29.5796 10.2613C29.4413 9.42184 28.3964 9.08998 27.7867 9.69188L0 37.1298L15.3905 45.5985C16.3632 46.1337 17.5478 46.134 18.5207 45.5993Z'
            fill={isActive ? '#F6820C' : '#9D9E9E'}
        />
        <path
            d='M33.8613 37.0199L29.4745 10.3936C29.3372 9.56058 28.4683 9.21585 27.8633 9.81318L0.171631 37.0777L15.3931 45.4626C16.3584 45.9938 17.534 45.994 18.4995 45.4634L33.8613 37.0199Z'
            fill={isActive ? '#FCCA3F' : '#D7D7D7'}
        />
        <path
            d='M18.5207 45.3486C17.5478 45.8833 16.3632 45.8831 15.3905 45.3479L0.123962 37.0008L0 37.1298L15.3905 45.5985C16.3632 46.1337 17.5478 46.134 18.5207 45.5993L34 37.0912L33.9621 36.8613L18.5207 45.3486Z'
            fill={isActive ? '#FFA712' : '#9D9E9E'}
        />
    </svg>
)

export default Firebase
