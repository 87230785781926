import React from 'react'

import Preloader from 'components/preloader/Preloader'

import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'

import './MapView.scss'
import { LatLon } from 'use-places-autocomplete'
import { useTranslation } from 'react-i18next'

const libraries: (
    | 'places'
    | 'drawing'
    | 'geometry'
    | 'localContext'
    | 'visualization'
)[] = ['places']

const mapContainerStyle = {
    height: '300px',
    width: '100%',
}

const options = {
    disableDefaultUI: true,
    zoomControl: true,
}

interface IMap {
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onMapLoad?: (map: any) => void
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onMapClick?: (latLng: LatLon, address: string) => void
    longitude: number
    latitude: number
    // allowGeolocation?: boolean
    userCoordinats?: LatLon
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

const MapView = ({
    onMapClick,
    onMapLoad,
    latitude,
    longitude,
    // allowGeolocation,
    userCoordinats,
}: IMap): React.ReactElement => {
    const { isLoaded, loadError } = useLoadScript({
        // TODO
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY!,
        libraries,
    })

    const { i18n } = useTranslation()

    if (loadError) return <div>Error</div>
    if (!isLoaded) return <Preloader />

    const onClick = async (e: any) => {
        const { maps } = window.google
        const geocoder = new maps.Geocoder()

        let address = ''

        await geocoder.geocode(
            {
                location: new maps.LatLng(e.latLng.lat(), e.latLng.lng()),
                language: i18n.language === 'en' ? 'en' : 'uk',
            },
            resp => {
                if (resp?.length) {
                    address =
                        resp.find(
                            item =>
                                item.types.includes('street_address') ||
                                item.types.includes('route'),
                        )?.formatted_address || ''
                }
            },
        )

        const location = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        }

        if (onMapClick) onMapClick(location, address)
    }

    return (
        <>
            <GoogleMap
                id='map'
                mapContainerStyle={mapContainerStyle}
                zoom={8}
                center={userCoordinats}
                options={options}
                onClick={onClick}
                onLoad={onMapLoad}
            >
                <>
                    {latitude && longitude ? (
                        <MarkerF
                            position={{
                                lat: latitude,
                                lng: longitude,
                            }}
                        />
                    ) : null}
                </>
            </GoogleMap>
        </>
    )
}

export default MapView
