import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const BookingRoutes = [
    {
        id: 1,
        module: 'loader-booking',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.booking,
        component: lazy(
            () =>
                import(
                    //  eslint-disable-next-line max-len
                    'containers/emulator/pages/booking/booking-page/BookingPage'
                ),
        ),
    },
    {
        id: 2,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.booking}/:id`,
        component: lazy(
            () =>
                import(
                    'containers/emulator/pages/booking/booking-managment/BookingManagment'
                ),
        ),
    },

    {
        id: 3,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.bookingManagment}/:id`,
        component: lazy(
            () =>
                import(
                    'containers/emulator/pages/booking/booking-item/BookingItem'
                ),
        ),
    },

    {
        id: 4,
        module: 'loader-blog',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.bookingService}/:id`,
        component: lazy(
            () =>
                import(
                    'containers/emulator/pages/booking/booking-service/BookingService'
                ),
        ),
    },
]
