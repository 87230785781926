import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { LOCATION_URLS } from 'config/urls'

import { ILocation, ILocationMultilang } from '../store/types'

export const getLocations = ({
    language,
    appId,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ILocation[]>> =>
    axiosInstance.get(`${appId}${LOCATION_URLS.location}/language/${language}`)
// axiosInstance.get(`${appId}${LOCATION_URLS.location}`)

export const getLocationById = ({
    locationId,
    appId,
}: {
    locationId: string | number | undefined
    appId: string | undefined
}): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.get(`${appId}${LOCATION_URLS.location}/${locationId}`)

export const createLocations = ({
    location,
    appId,
}: {
    location: ILocationMultilang
    appId: string
}): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.post(`${appId}${LOCATION_URLS.location}`, location)
// axiosInstance.post(
// `${appId}${LOCATION_URLS.location}/${language}`,
// location,
// )

export const editLocation = ({
    locationId,
    location,
    appId,
}: {
    locationId: string
    location: ILocation
    appId: string
}): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.put(
        `${appId}${LOCATION_URLS.location}/${locationId}`,
        location,
    )

export const deleteLocation = ({
    locationId,
    appId,
}: {
    locationId: string
    appId: string
}): Promise<AxiosResponse<ILocation>> =>
    axiosInstance.delete(`${appId}${LOCATION_URLS.location}/${locationId}`)

// export const getCoordinates = ({
//     address,
//     appId,
// }: {
//     address: string
//     appId: string
// }): Promise<AxiosResponse<ICoordinates>> =>
//     axiosInstance.get(
//         `${LOCATION_URLS.coordinates}/${appId}?address=${address}`,
//     )

// export const getAddress = ({
//     coordinates,
//     appId,
// }: {
//     coordinates: ICoordinates
//     appId: string
// }): Promise<AxiosResponse<ICoordinates>> => {
//     return axiosInstance.get(
// eslint-disable-next-line max-len
//         `${LOCATION_URLS.address}/${appId}?latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`,
//     )
// }
