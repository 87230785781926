import React, { Dispatch, SetStateAction, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import CloseIcon from 'assets/images/new-loader/todo/CloseIcon'

import { todoCategorySchema } from 'schemas/emulator/todo'
import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

import Overlay from 'containers/emulator/components/shared/overlay/Overlay'
// eslint-disable-next-line max-len
import LoaderInput from 'containers/emulator/components/shared/input/LoaderInput'
import { useFormik, FormikProvider, Form } from 'formik'
// eslint-disable-next-line max-len
import LoaderButton from 'containers/emulator/components/shared/button/LoaderButton'

import { ICategory, ITodo } from '../../../TodoPage'

import './CategoryPopup.scss'

interface ICategoryPopup {
    onClose: () => void
    setStorage: Dispatch<SetStateAction<ITodo>>
    todoCategory?: ICategory
    storage: ITodo
    isCreate?: boolean
}

const CategoryPopup = ({
    onClose,
    todoCategory,
    storage,
    setStorage,
    isCreate,
}: ICategoryPopup): React.ReactElement => {
    const { t } = useTranslation(['emulator'])

    const getRandomValue = () => Math.floor(Math.random() * (1000000 - 10) + 10)

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: todoCategorySchema,
        initialValues: {
            id: todoCategory?.id || '',
            value: todoCategory?.value || 0,
            icon: todoCategory?.icon || '',
            label: todoCategory?.label || '',
            isIconLeft: todoCategory?.isIconLeft || true,
        },
        onSubmit: (category: ICategory) => {
            if (isCreate) {
                const newItem = {
                    ...category,
                    id: getRandomValue().toString(),
                    value: getRandomValue(),
                }
                const updatedCategories = [...storage.categories, newItem]
                setStorage({
                    ...storage,
                    categories: updatedCategories,
                })
                onClose()
            } else {
                const categoryIdx = storage.categories.findIndex(
                    i => i.id === category.id,
                )
                const categoryCopy = [...storage.categories]
                categoryCopy[categoryIdx] = { ...category }
                setStorage({ ...storage, categories: categoryCopy })
                onClose()
            }
        },
    })

    const { handleSubmit, getFieldProps, errors, touched, values } = formik

    const categoryRef = useRef(null)
    useClickedOutsideHandler({ ref: categoryRef, func: onClose })

    return (
        <Overlay topLevel center>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                    <div
                        className='new-loader--background m-category-popup'
                        ref={categoryRef}
                    >
                        <div className='m-category-popup__header'>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-category-popup__title new-loader--text'>
                                {isCreate
                                    ? t('emulator:addCategory')
                                    : t('emulator:editCategory')}
                            </div>
                            <button
                                type='button'
                                onClick={onClose}
                                className='new-loader--primary-color'
                            >
                                <CloseIcon />
                            </button>
                        </div>

                        <div className='m-category-popup__body'>
                            <LoaderInput
                                {...getFieldProps('label')}
                                placeholder='Enter category name'
                                error={errors.label}
                                touched={touched.label}
                            />
                            <LoaderButton
                                type='submit'
                                className='m-category-popup__button'
                                disabled={values.label === ''}
                            >
                                {isCreate
                                    ? t('emulator:create')
                                    : t('emulator:save')}
                            </LoaderButton>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
        </Overlay>
    )
}

export default CategoryPopup
