import React, { useEffect } from 'react'

import { ReactComponent as CloseIcon } from 'assets/images/icons/Close.svg'

import Overlay from '../overlay/Overlay'

import './Popup.scss'

interface IPopup {
    showPopup: boolean
    setShowPopup: (showPopup: boolean) => void
    title?: string
    topPart?: React.ReactNode
    topDesign?: 'straight' | 'rounded'
    bodyPart: React.ReactNode
    buttons: React.ReactNode[]
    fixedHeight?: boolean
    error?: string
    buttonsClass?: string
    noCloseIcon?: boolean
    additionalClass?: string
}

export const Popup = ({
    showPopup,
    setShowPopup,
    title,
    topPart,
    topDesign,
    bodyPart,
    buttons,
    fixedHeight,
    error,
    buttonsClass,
    noCloseIcon,
    additionalClass,
}: IPopup): React.ReactElement => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden'

        return () => {
            document.body.style.overflowY = 'scroll'
        }
    }, [])

    return (
        <>
            {showPopup ? (
                <Overlay>
                    <div
                        className={`popup ${
                            fixedHeight ? 'popup--fixed-height' : ''
                        } ${additionalClass}`}
                    >
                        <div className='popup__wrapper'>
                            {topPart ? (
                                <div
                                    className={`popup__top ${
                                        topDesign === 'straight'
                                            ? 'popup__top--straight'
                                            : 'popup__top--rounded'
                                    }`}
                                >
                                    {topPart}
                                </div>
                            ) : null}
                            {!noCloseIcon ? (
                                <button
                                    type='button'
                                    onClick={() => setShowPopup(false)}
                                    className='popup__close'
                                >
                                    <CloseIcon />
                                </button>
                            ) : null}
                            <div className='popup__body'>
                                {title ? (
                                    <div className='popup__title'>{title}</div>
                                ) : null}
                                <div className='popup__main'>{bodyPart}</div>
                            </div>
                            <div className='popup__bottom'>
                                <div
                                    className={`${buttonsClass} popup__buttons`}
                                >
                                    {buttons}
                                </div>
                                {error ? (
                                    <div className='popup__error'>{error}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Overlay>
            ) : null}
        </>
    )
}

export default Popup
