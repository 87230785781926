import React from 'react'

// eslint-disable-next-line max-len
import MasonryColumn from 'containers/emulator/hoc/masonry-column/MasonryColumn'

import GalleryItem from '../gallery-item/GalleryItem'

import './GalleryView.scss'

type TData = {
    id: string
    name: string
    url: string
}

interface IData {
    data: TData[]
    path: string
}

const GalleryBlockItem = ({ data, path }: IData): React.ReactElement => (
    <div className='m-gallery'>
        <MasonryColumn>
            {data.map((item, idx) =>
                !(idx % 2) ? (
                    <GalleryItem item={item} path={path} key={item.id} />
                ) : (
                    ''
                ),
            )}
        </MasonryColumn>
        <MasonryColumn>
            {data.map((item, idx) =>
                idx % 2 ? (
                    <GalleryItem item={item} path={path} key={item.id} />
                ) : (
                    ''
                ),
            )}
        </MasonryColumn>
    </div>
)

export default GalleryBlockItem
