export enum testTypes {
    GET_ANDROID_APK_DETAILS = 'GET_ANDROID_APK_DETAILS',
    GET_ANDROID_AAB_DETAILS = 'GET_ANDROID_AAB_DETAILS',
    GET_APPLE_SETTINGS = 'GET_APPLE_SETTINGS',
    GET_APPLE_IPA_DETAILS = 'GET_APPLE_IPA_DETAILS',
    CREATE_ANDROID_APK_BUILD = 'CREATE_ANDROID_APK_BUILD',
    CREATE_ANDROID_AAB_BUILD = 'CREATE_ANDROID_AAB_BUILD',
    CREATE_APPLE_IPA_BUILD = 'CREATE_APPLE_IPA_BUILD',
    UPDATE_APPLE_SETTINGS = 'UPDATE_APPLE_SETTINGS',
    SET_ANDROID_APK_DETAILS = 'SET_ANDROID_APK_DETAILS',
    SET_ANDROID_AAB_DETAILS = 'SET_ANDROID_AAB_DETAILS',
    SET_APPLE_IPA_DETAILS = 'SET_APPLE_IPA_DETAILS',
    SET_APPLE_SETTINGS = 'SET_APPLE_SETTINGS',
}
