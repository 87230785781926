import { blogTypes } from './actionTypes'
import {
    IBlog,
    FetchBlogByIdRequest,
    FetchBlogPayload,
    FetchBlogsPayload,
    FetchBlogsRequest,
    CreateBlogsRequest,
    CreateBlogsPayload,
    EditBlogRequest,
    EditBlogPayload,
    DeleteBlogRequest,
    DeleteBlogPayload,
    SetBlogs,
    SetBlog,
} from './types'

export const fetchBlogsAction = (
    payload: FetchBlogsPayload,
): FetchBlogsRequest => ({
    type: blogTypes.FETCH_BLOGS_REQUEST,
    payload,
})

export const fetchBlogAction = (
    payload: FetchBlogPayload,
): FetchBlogByIdRequest => ({
    type: blogTypes.FETCH_BLOG_BY_ID,
    payload,
})

export const createBlogsAction = (
    payload: CreateBlogsPayload,
): CreateBlogsRequest => ({
    type: blogTypes.CREATE_BLOGS_REQUEST,
    payload,
})

export const editBlogAction = (payload: EditBlogPayload): EditBlogRequest => ({
    type: blogTypes.EDIT_BLOG_REQUEST,
    payload,
})

export const deleteBlogAction = (
    payload: DeleteBlogPayload,
): DeleteBlogRequest => ({
    type: blogTypes.DELETE_BLOG_REQUEST,
    payload,
})

export const SetBlogsAction = (payload: {
    blogs: IBlog[] | undefined
}): SetBlogs => ({
    type: blogTypes.SET_BLOGS,
    payload,
})

export const SetBlogAction = (payload: { blog: IBlog }): SetBlog => ({
    type: blogTypes.SET_BLOG,
    payload,
})
