import React, { ChangeEvent, useRef } from 'react'

import { Stack } from '@mui/material'
import { toast } from 'react-toastify'

import './UploadImage.scss'

import { getImageUrl } from 'utils/pictureUrl'
import { getAppIdCookie } from 'utils/token'
import CustomImage from 'components/custom-image/CustomImage'
import ClampedText from 'components/clamped-text/ClampedText'
import DeleteIcon from 'assets/images/new-loader/todo/DeleteIcon'
import { useTranslation } from 'react-i18next'

interface IUploadImage {
    onChange: (value: string, newValue: string) => void
    pictureUrl: string
}

const UploadImage = ({
    onChange,
    pictureUrl,
}: IUploadImage): React.ReactElement => {
    const inputRef = useRef<HTMLInputElement>(null)
    const appId = getAppIdCookie()
    const { t } = useTranslation('toast')

    const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        if (!appId) return
        const query = {
            entityId: appId,
            type: 'Calendar',
        }
        const data = new FormData()

        data.append('file', event.target.files[0])

        const response = await getImageUrl({
            query,
            data,
        })
        if (response.status === 200) {
            onChange('pictureUrl', response.data)
            toast.success(t('toast:imageUpload'))
        }
    }

    const handleRemove = () => {
        if (inputRef?.current !== null) {
            inputRef.current.value = ''
        }
        onChange('pictureUrl', '')
    }

    return (
        <Stack spacing='5px'>
            <Stack fontSize='12px' fontWeight={600}>
                Upload image
            </Stack>
            {pictureUrl ? (
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing='5px'
                    className='new-loader--tertiary-background'
                    padding='10px'
                    justifyContent='space-between'
                    borderRadius='8px'
                >
                    <Stack direction='row' spacing='5px' alignItems='center'>
                        <CustomImage
                            src={pictureUrl}
                            className='loader-calendar-image'
                        />
                        <Stack fontSize='13px'>
                            <ClampedText
                                text={pictureUrl.substring(
                                    pictureUrl.lastIndexOf('_') + 1,
                                )}
                                lines={1}
                            />
                        </Stack>
                    </Stack>
                    <Stack onClick={handleRemove}>
                        <DeleteIcon />
                    </Stack>
                </Stack>
            ) : (
                <label
                    id='upload'
                    htmlFor='android__upload'
                    className='loader-calendar-image-upload new-loader--grey'
                >
                    Choose image
                    <input
                        accept='image/*'
                        ref={inputRef}
                        type='file'
                        id='android__upload'
                        hidden
                        onChange={event => handleImageChange(event)}
                    />
                </label>
            )}
        </Stack>
    )
}

export default UploadImage
