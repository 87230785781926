import React, { useState } from 'react'

// TODO, we have own hook
import useOnclickOutside from 'react-cool-onclickoutside'

// eslint-disable-next-line max-len
import { EmulatorIconRender } from 'containers/emulator/components/emulator-icon-render/EmulatorIconRender'

import ArrowDown from 'assets/images/new-loader/todo/ArrowDown'
import './LoaderSelect.scss'

interface ILoaderItems {
    value: number | string
    label: string
    icon: string
    isIconLeft: boolean
    id: string
}

interface ILoaderSelect {
    value: string | number
    // TODO
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleChange: any
    menuItems: ILoaderItems[]
    placeholder?: string
}

export const LoaderSelect = ({
    value,
    handleChange,
    menuItems,
    placeholder,
}: ILoaderSelect): React.ReactElement => {
    const [showOptions, setShowOptions] = useState<boolean>(false)

    const activeItem = menuItems.find(item => item.value === value)

    const menuRef = useOnclickOutside(() => {
        setShowOptions(false)
    })

    return (
        <div className='loader-select__wrapper' ref={menuRef}>
            <div
                className={`loader-select ${
                    !value ? 'loader-select--empty' : ''
                } new-loader--surface`}
            >
                {!value ? placeholder : activeItem?.label}
                <button
                    type='button'
                    onClick={() => setShowOptions(!showOptions)}
                    className={`loader-select__arrow ${
                        showOptions ? 'loader-select__arrow--up' : ''
                    } new-loader--primary-color`}
                >
                    <ArrowDown />
                </button>
            </div>
            {showOptions ? (
                <div className='loader-select__menu new-loader--surface'>
                    {menuItems.map(item => (
                        <button
                            type='button'
                            onClick={() => {
                                handleChange(item.value)
                                setShowOptions(false)
                            }}
                            key={item.id}
                            className={`loader-select__item ${
                                item.value === value
                                    ? 'loader-select__item--active'
                                    : ''
                            } new-loader--primary-color`}
                        >
                            <div
                                className={`loader-select__info ${
                                    item.isIconLeft
                                        ? 'loader-select__info--left'
                                        : 'loader-select__info--between'
                                }`}
                            >
                                {item.icon && (
                                    <div className='loader-select__item-icon'>
                                        <EmulatorIconRender
                                            icon={item.label}
                                            className='new-loader--primary'
                                        />
                                    </div>
                                )}
                                <div>{item.label}</div>
                            </div>
                        </button>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default LoaderSelect
