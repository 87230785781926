import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SURVEY_URLS } from 'config/urls'

import { ITest } from '../store/types'

export const getTests = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ITest[]>> =>
    axiosInstance.get(`${appId}${SURVEY_URLS.main}/${language}`)

export const getTestById = ({
    testId,
    appId,
    language,
}: {
    testId: string
    appId: string | undefined
    language: string
}): Promise<AxiosResponse<ITest>> =>
    axiosInstance.get(`${appId}${SURVEY_URLS.main}/${language}/${testId}`)

export const createTest = ({
    test,
    appId,
    language,
}: {
    language: string
    test: ITest
    appId: string
}): Promise<AxiosResponse<ITest>> =>
    axiosInstance.post(`${appId}${SURVEY_URLS.main}/${language}`, test)

export const editTest = ({
    appId,
    testId,
    test,
    language,
}: {
    appId: string | undefined
    testId: string
    test: ITest
    language: string
}): Promise<AxiosResponse<ITest>> =>
    axiosInstance.put(`${appId}${SURVEY_URLS.main}/${language}/${testId}`, test)

export const deleteTest = ({
    testId,
    appId,
    language,
}: {
    testId: string | undefined
    appId: string | undefined
    language: string
}): Promise<AxiosResponse<ITest>> =>
    axiosInstance.delete(`${appId}${SURVEY_URLS.main}/${language}/${testId}`)
