import { paymentsTypes } from './actionTypes'
import { IPaymentsState, PaymentActions } from './types'

const initialState: IPaymentsState = {
    payments: undefined,
    payment: undefined,
    keysExpanded: true,
}

export default (
    state = initialState,
    action: PaymentActions,
): IPaymentsState => {
    switch (action.type) {
        case paymentsTypes.SET_PAYMENTS:
            return {
                ...state,
                payments: action.payload.payments,
            }
        case paymentsTypes.SET_PAYMENT:
            return {
                ...state,
                payment: action.payload.payment,
            }
        case paymentsTypes.SET_EXPANDED_VALUE:
            return {
                ...state,
                keysExpanded: action.payload.isExpanded,
            }
        default:
            return {
                ...state,
            }
    }
}
