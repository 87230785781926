import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomImage from 'components/custom-image/CustomImage'

import SmileMinion from 'assets/images/minions/SmileMinion.webp'

import './EmulatorEmpty.scss'

const EmulatorEmpty = (): React.ReactElement => {
    const { t } = useTranslation(['emulator'])

    return (
        <div className='m-empty'>
            <div className='m-empty__title new-loader--text'>
                {t('emulator:empty:title')}
            </div>
            <div className='m-empty__minion'>
                <CustomImage src={SmileMinion} />
            </div>
        </div>
    )
}

export default EmulatorEmpty
