import Cookies from 'js-cookie'

// TODO
// Change file and all cookies save here
interface IUser {
    firstName: string
    lastName: string
    email: string
}

export const setToken: (token: string) => void = (token: string) => {
    Cookies.set('token', JSON.stringify(token))
}

export const getCookiesRefreshToken: () => string = () => {
    const user = JSON.parse(Cookies.get('user') || '{}')
    return user?.refreshToken
}

export const getCookiesAccessToken: () => string | undefined = () => {
    const token = Cookies.get('token')
    if (!token) return undefined
    return token
}

export const updateCookiesAccessToken: (token: string) => void = (
    token: string,
) => {
    const user = JSON.parse(Cookies.get('user') || '{}')
    user.accessToken = token
    Cookies.set('user', JSON.stringify(user))
}

export const getUser: () => void = () => JSON.parse(Cookies.get('user') || '{}')

export const setUser: (user: IUser) => void = (user: IUser) => {
    console.log(JSON.stringify(user))
    Cookies.set('user', JSON.stringify(user))
}

export const removeUser: () => void = () => {
    Cookies.remove('user')
}

export const removeToken: () => void = () => {
    Cookies.remove('token')
}

export const getAppIdCookie: () => string | undefined = () => {
    const applicationId = Cookies.get('applicationId')
    if (!applicationId) return undefined
    return applicationId
}

export const removeAppIdCookie: () => void = () => {
    Cookies.remove('applicationId')
}

export const removePlanCookie: () => void = () => {
    Cookies.remove('paid_plan')
}

export const removeNextPaymentCookie: () => void = () => {
    Cookies.remove('next_payment_date')
}

export const removeNextFreeCookie: () => void = () => {
    Cookies.remove('next_free')
}

export const removePaletteCookie: () => void = () => {
    Cookies.remove('emulator_palette')
}

export const removeAppName: () => void = () => {
    Cookies.remove('app_name')
}

export const removeActiveFeatures: () => void = () => {
    Cookies.remove('active_features')
}

export const removePoints: () => void = () => {
    Cookies.remove('points')
}

export const removeIsTrialUSed: () => void = () => {
    Cookies.remove('isTrialUsed')
}
