import React from 'react'

import CategoryHome from 'assets/images/new-loader/todo/category/CategoryHome'
// eslint-disable-next-line max-len
import CategoryOffice from 'assets/images/new-loader/todo/category/CategoryOffice'
// eslint-disable-next-line max-len
import CategoryPurchases from 'assets/images/new-loader/todo/category/CategoryPurchases'
// eslint-disable-next-line max-len
import CategoryWishList from 'assets/images/new-loader/todo/category/CategoryWishList'
import CustomImage from 'components/custom-image/CustomImage'
import { priority } from 'config/priority'

interface IEmulatorIconRender {
    icon: string
    className?: string
}

export const EmulatorIconRender = ({
    icon,
    className,
}: IEmulatorIconRender): React.ReactElement => {
    switch (icon) {
        case 'Home':
            return <CategoryHome className={className} />
        case 'Office':
            return <CategoryOffice className={className} />
        case 'Purchases':
            return <CategoryPurchases className={className} />
        case 'Wish list':
            return <CategoryWishList className={className} />
        case 'High':
            return <CustomImage src={priority.high} className={className} />
        case 'Low':
            return <CustomImage src={priority.low} className={className} />
        case 'Medium':
            return <CustomImage src={priority.medium} className={className} />
        default:
            return <></>
    }
}
