import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const GalleryRoutes = [
    {
        id: 1,
        module: 'gallery',
        protected: true,
        exact: true,
        path: PATH_FEATURES.gallery.root,
        component: lazy(
            () => import('containers/dashboard/features/gallery/Gallery'),
        ),
    },
    {
        id: 2,
        module: 'gallery',
        protected: true,
        exact: true,
        path: PATH_FEATURES.gallery.addPicture,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/gallery/components/add-picture/AddPicture'
                ),
        ),
    },
    {
        id: 3,
        module: 'gallery',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.gallery.edit}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/gallery/components/add-picture/AddPicture'
                ),
        ),
    },
]
