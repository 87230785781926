import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import Overlay from 'containers/emulator/components/shared/overlay/Overlay'
import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

import './DeleteItemPopup.scss'

interface IDeleteItemPopup {
    onClose: () => void
    isTask?: boolean
    handleDelete: () => void
}

const DeleteItemPopup = ({
    onClose,
    isTask,
    handleDelete,
}: IDeleteItemPopup): React.ReactElement => {
    const { t } = useTranslation(['emulator'])
    const deleteRef = useRef(null)
    useClickedOutsideHandler({ ref: deleteRef, func: onClose })

    return (
        <Overlay center topLevel>
            <div className='m-delete-popup new-loader--surface' ref={deleteRef}>
                <div className='m-delete-popup__top new-loader--text'>
                    {isTask
                        ? t('emulator:deleteTask')
                        : t('emulator:deleteCategory')}
                </div>
                <div className='m-delete-popup__bottom'>
                    <div className='m-delete-popup__btn new-loader--text'>
                        <button
                            type='button'
                            className='new-loader--primary-color'
                            onClick={() => {
                                onClose()
                                handleDelete()
                            }}
                        >
                            {t('emulator:delete')}
                        </button>
                    </div>
                    <div className='m-delete-popup__btn new-loader--text'>
                        <button
                            type='button'
                            className='new-loader--primary-color'
                            onClick={() => {
                                onClose()
                            }}
                        >
                            {t('emulator:cancel')}
                        </button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}

export default DeleteItemPopup
