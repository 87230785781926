import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const AboutRoutes = [
    {
        id: 1,
        module: 'information',
        protected: true,
        exact: true,
        path: PATH_FEATURES.information.root,
        component: lazy(
            () => import('containers/dashboard/features/about/Information'),
        ),
    },
    {
        id: 2,
        module: 'information',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.information.createMultiple}`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/about/CreateMultipleInfo'
                ),
        ),
    },
    {
        id: 3,
        module: 'information',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.information.edit}/:id`,
        component: lazy(
            () => import('containers/dashboard/features/about/EditInfo'),
        ),
    },
    {
        id: 4,
        module: 'information',
        protected: true,
        exact: true,
        path: `${PATH_FEATURES.information.create}`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/features/about/CreateInfo'
                ),
        ),
    },
]
