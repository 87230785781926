import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SURVEY_URLS } from 'config/urls'

import { ISurveyUsers } from '../store/types'

export const getSurveyUsers = ({
    applicationId,
    surveyId,
    lang,
}: {
    applicationId: string
    surveyId: string
    lang: string
}): Promise<AxiosResponse<ISurveyUsers[]>> =>
    axiosInstance.get(
        `${applicationId}${SURVEY_URLS.main}/${lang}/${surveyId}/surveyCompletions`,
    )
