import React from 'react'

export const AppsIcon = (): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_10065_6533)'>
            <path
                d='M7.75 12C8.99264 12 10 13.0074 10 14.25V17.75C10 18.9926 8.99264 20 7.75 20H4.25C3.00736 20 2 18.9926 2 17.75V14.25C2 13.0074 3.00736 12 4.25 12H7.75ZM17.75 12C18.9926 12 20 13.0074 20 14.25V17.75C20 18.9926 18.9926 20 17.75 20H14.25C13.0074 20 12 18.9926 12 17.75V14.25C12 13.0074 13.0074 12 14.25 12H17.75ZM7.75 2C8.99264 2 10 3.00736 10 4.25V7.75C10 8.99264 8.99264 10 7.75 10H4.25C3.00736 10 2 8.99264 2 7.75V4.25C2 3.00736 3.00736 2 4.25 2H7.75ZM17.75 2C18.9926 2 20 3.00736 20 4.25V7.75C20 8.99264 18.9926 10 17.75 10H14.25C13.0074 10 12 8.99264 12 7.75V4.25C12 3.00736 13.0074 2 14.25 2H17.75Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_10065_6533'>
                <rect
                    width='18'
                    height='18'
                    fill='white'
                    transform='translate(2 2)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default AppsIcon
