import React from 'react'

export const EReaderIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M38.3181 32.6597C38.8146 32.5746 39.2882 32.8971 39.3758 33.3803C39.4633 33.8634 39.1317 34.3241 38.6351 34.4093L27.8728 36.5688C27.3762 36.6539 26.9027 36.3314 26.8151 35.8482C26.7275 35.3651 27.0591 34.9044 27.5557 34.8192L38.3181 32.6597Z'
            fill='currentColor'
        />
        <path
            d='M27.5652 29.9284V17.4926C27.5652 17.002 27.974 16.6043 28.4783 16.6043L37.6087 14.8277C38.113 14.8277 38.5217 15.2254 38.5217 15.716C38.5217 16.2066 38.113 16.6043 37.6087 16.6043L29.3913 18.3808V22.8222L37.6087 21.0456C38.113 21.0456 38.5217 21.4433 38.5217 21.9339C38.5217 22.4245 38.113 22.8222 37.6087 22.8222L29.3913 24.5987V29.0401L37.6087 27.2636C38.113 27.2636 38.5217 27.6612 38.5217 28.1518C38.5217 28.6424 38.113 29.0401 37.6087 29.0401L28.4783 30.8166C27.974 30.8166 27.5652 30.4189 27.5652 29.9284Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M44 33.9863V12.7514C44 10.5738 42.0048 8.9085 39.7925 9.23962L39.4252 9.29459L37.6075 9.56666L26.6522 11.6995L35.0051 8.02533C36.1956 7.48028 37.5613 8.30168 37.6075 9.56666L39.4252 9.29459C39.2492 6.89008 36.6703 5.32114 34.3439 6.29111L24.3005 11.2401C23.627 11.5208 23.0724 11.9839 22.6867 12.555C22.1759 12.113 21.5339 11.8069 20.8162 11.6995L6.20751 9.23962C3.99518 8.9085 2 10.5738 2 12.7514V33.9863C2 35.74 3.31519 37.2314 5.09683 37.4981L19.7055 39.9579C20.9947 40.1509 22.2101 39.6659 23 38.7967C23.7899 39.6659 25.0053 40.1509 26.2945 39.9579L40.9032 37.4981C42.6848 37.2314 44 35.74 44 33.9863ZM22.087 15.4222V36.4461V36.6824C22.087 37.7908 21.0551 38.6286 19.9342 38.4303L5.32552 35.5233C4.45723 35.3697 3.82609 34.634 3.82609 33.7754V12.5151C3.82609 11.4067 4.85791 10.5689 5.97883 10.7672L20.5875 13.6743C21.4558 13.8279 22.087 14.5636 22.087 15.4222ZM40.0212 10.7672L25.4125 13.6743C24.5442 13.8279 23.913 14.5636 23.913 15.4222V36.6824C23.913 37.7908 24.9449 38.6286 26.0658 38.4303L40.6745 35.5233C41.5428 35.3697 42.1739 34.634 42.1739 33.7754V12.5151C42.1739 11.4067 41.1421 10.5689 40.0212 10.7672Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M38.6351 34.4093L27.8728 36.5688C27.3762 36.6539 26.9027 36.3314 26.8151 35.8482C26.7275 35.3651 27.0591 34.9044 27.5557 34.8192L38.3181 32.6597C38.8146 32.5746 39.2882 32.8971 39.3758 33.3803C39.4633 33.8634 39.1317 34.3241 38.6351 34.4093ZM27.5652 29.9284V17.4926C27.5652 17.002 27.974 16.6043 28.4783 16.6043L37.6087 14.8277C38.113 14.8277 38.5217 15.2254 38.5217 15.716C38.5217 16.2066 38.113 16.6043 37.6087 16.6043L29.3913 18.3808V22.8222L37.6087 21.0456C38.113 21.0456 38.5217 21.4433 38.5217 21.9339C38.5217 22.4245 38.113 22.8222 37.6087 22.8222L29.3913 24.5987V29.0401L37.6087 27.2636C38.113 27.2636 38.5217 27.6612 38.5217 28.1518C38.5217 28.6424 38.113 29.0401 37.6087 29.0401L28.4783 30.8166C27.974 30.8166 27.5652 30.4189 27.5652 29.9284Z'
            fill='currentColor'
        />
    </svg>
)

export default EReaderIcon
