import React, { useEffect, useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AlreadyConfirmed from 'assets/images/email/AlreadyConfirmed.webp'
import EmailConfirmed from 'assets/images/email/EmailConfirmed.webp'
import ConfirmError from 'assets/images/email/ConfirmError.webp'
import AuthModuleLogo from 'assets/images/AuthModuleLogo.svg'

import { PATH_AUTH } from 'config/paths'

import CustomButton from 'components/custom-button/CustomButton'
import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import { TError } from 'config/types'

import IndustryPopup from '../components/industry-popup/IndustryPopup'
import { confirmEmail } from '../services/auth'

import './ConfirmEmail.scss'
import '../style.scss'

const ResetPassword = (): React.ReactElement => {
    const { t } = useTranslation(['confirmEmail'])

    const [confirmedState, setConfirmedState] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [loading, setLoading] = useState(true)

    const { push } = useHistory()
    const { pathname } = useLocation()
    const pathEmail = pathname.split('/')[3]
    const pathToken = pathname.split('/')[4]

    useEffect(() => {
        const fetchData = async () => {
            const data = await confirmEmail(pathEmail, pathToken)
            if (data) {
                setLoading(false)
                setConfirmedState('confirmed')
            }
        }

        fetchData().catch(err => {
            setLoading(false)
            if (Array.isArray(err)) {
                err.forEach((error: TError) => {
                    setConfirmedState(error.description)
                })
            }
        })
    }, [pathEmail, pathToken])

    return (
        <>
            <div className='confirm-email'>
                <div className='confirm-email__header'>
                    <CustomImage
                        src={AuthModuleLogo}
                        className='confirm-email__logo'
                    />
                </div>
                {loading ? (
                    <div className='confirm-email__loader'>
                        <Preloader />
                    </div>
                ) : (
                    <div className='confirm-email__body'>
                        <CustomImage
                            src={
                                confirmedState === 'confirmed'
                                    ? EmailConfirmed
                                    : confirmedState ===
                                      'Email already confirmed'
                                    ? AlreadyConfirmed
                                    : ConfirmError
                            }
                            className={
                                confirmedState === 'confirmed'
                                    ? 'confirm-email__confirmed-logo'
                                    : 'confirm-email__err-logo'
                            }
                        />
                        <h2 className='confirm-email__title'>
                            {confirmedState === 'confirmed'
                                ? t('confirmEmail:confirmEmail')
                                : confirmedState === 'Email already confirmed'
                                ? t('confirmEmail:alreadyConfirmed')
                                : t('confirmEmail:smthWrong')}
                        </h2>
                        {confirmedState !== 'Email already confirmed' ? (
                            <p className='confirm-email__description'>
                                {confirmedState === 'confirmed'
                                    ? t('confirmEmail:confirmEmailInfo')
                                    : t('confirmEmail:smthWrongInfo')}
                            </p>
                        ) : null}
                        {confirmedState === 'confirmed' ||
                        confirmedState === 'Email already confirmed' ? (
                            <CustomButton
                                design='secondary'
                                handleClick={() => {
                                    if (confirmedState === 'confirmed') {
                                        setShowPopup(true)
                                    } else {
                                        push(`${PATH_AUTH.login}`)
                                    }
                                }}
                                size='large'
                            >
                                {t('confirmEmail:getStarted')}
                            </CustomButton>
                        ) : null}
                    </div>
                )}
            </div>
            {showPopup ? (
                <IndustryPopup
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    btnLoading={btnLoading}
                    setBtnLoading={setBtnLoading}
                />
            ) : null}
        </>
    )
}

export default ResetPassword as React.FC
