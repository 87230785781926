import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AxiosInterceptorsSetup } from './axiosInstance'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function InjectAxiosInterceptors() {
    const history = useHistory()

    useEffect(() => {
        AxiosInterceptorsSetup(history)
    }, [history])

    // not rendering anything
    return null
}

export default InjectAxiosInterceptors
