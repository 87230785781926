import { lazy } from 'react'

import { PATH_LANGUAGE } from 'config/paths'

export const LanguageRoutes = [
    {
        id: 1,
        module: 'lang',
        protected: true,
        exact: true,
        path: PATH_LANGUAGE.root,
        component: lazy(() => import('containers/language/Language')),
    },
]
