import React from 'react'

export const ConferenceIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <g clipPath='url(#clip0_13622_192)'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M38 23C34.6863 23 32 25.6863 32 29C32 30.9822 32.9612 32.7398 34.4429 33.8324C33.6866 34.2084 32.9982 34.7006 32.4012 35.2857C31.1539 33.2381 29.2566 31.6301 26.9958 30.7482C28.8116 29.4836 30 27.3806 30 25C30 21.134 26.866 18 23 18C19.134 18 16 21.134 16 25C16 27.3806 17.1884 29.4836 19.0042 30.7482C16.7434 31.6301 14.8461 33.2381 13.5988 35.2857C13.0018 34.7006 12.3134 34.2084 11.5571 33.8324C13.0388 32.7398 14 30.9822 14 29C14 25.6863 11.3137 23 8 23C4.68629 23 2 25.6863 2 29C2 30.9822 2.96118 32.7398 4.44288 33.8324C1.80956 35.1417 0 37.8595 0 41V46H12H16H30H34H46V41C46 37.8595 44.1904 35.1417 41.5571 33.8324C43.0388 32.7398 44 30.9822 44 29C44 25.6863 41.3137 23 38 23ZM38 33C40.2091 33 42 31.2091 42 29C42 26.7909 40.2091 25 38 25C35.7909 25 34 26.7909 34 29C34 31.2091 35.7909 33 38 33ZM8 33C10.2091 33 12 31.2091 12 29C12 26.7909 10.2091 25 8 25C5.79086 25 4 26.7909 4 29C4 31.2091 5.79086 33 8 33ZM44 44V41C44 37.6863 41.3137 35 38 35C36.1164 35 34.4355 35.868 33.3354 37.2258C33.7654 38.4029 34 39.6741 34 41V44H44ZM23 30C25.7614 30 28 27.7614 28 25C28 22.2386 25.7614 20 23 20C20.2386 20 18 22.2386 18 25C18 27.7614 20.2386 30 23 30ZM2 44V41C2 37.6863 4.68629 35 8 35C9.88364 35 11.5645 35.868 12.6646 37.2258C12.2346 38.4029 12 39.6741 12 41V44H2ZM32 41V44H30H16H14V41C14 39.5653 14.3357 38.209 14.9328 37.0054C16.4045 34.039 19.4641 32 23 32C26.5359 32 29.5955 34.039 31.0672 37.0054C31.6643 38.209 32 39.5653 32 41Z'
                fill='currentColor'
            />
            <path
                d='M17.0747 5.48379L17.1942 6.47662L17.0747 5.48379C16.1837 5.59104 15.7892 6.66259 16.3979 7.32202L18.7284 9.84677L18.3322 13.7097C18.242 14.5889 19.1732 15.2101 19.9504 14.7892L19.4741 13.9099L19.9504 14.7891L23 13.1373L26.0496 14.7891C26.8268 15.2101 27.758 14.589 27.6678 13.7097L27.2716 9.84677L29.6021 7.32201L28.8673 6.64373L29.6021 7.32201C30.2108 6.6626 29.8163 5.59104 28.9253 5.48379L25.5201 5.0739L23.9988 1.77767C23.6055 0.925622 22.3945 0.925616 22.0012 1.77767L20.4799 5.0739L17.0747 5.48379ZM27.0022 13.0306L27.001 13.0299L27.0022 13.0306ZM26.2703 9.47513L26.2614 9.46685L26.2703 9.47513ZM25.7307 5.53024L24.9135 5.90739L25.7307 5.53024L25.7303 5.52927C25.7304 5.52959 25.7306 5.52992 25.7307 5.53024ZM21.0865 5.90739L20.9789 5.01384L21.0865 5.90739Z'
                stroke='currentColor'
                strokeWidth='2'
            />
            <path
                d='M5.08949 18.9099L5.56578 19.7892C4.7886 20.2101 3.85743 19.589 3.94761 18.7097L4.26688 15.5968L2.39788 13.572C1.78917 12.9126 2.18371 11.841 3.0747 11.7338L3.19421 12.7266L3.0747 11.7338L5.7876 11.4072L7.00125 8.77767C7.3945 7.92562 8.6055 7.92561 8.99876 8.77767L10.2124 11.4072L12.9253 11.7338C13.8163 11.841 14.2108 12.9126 13.6021 13.572L11.7331 15.5968L12.0524 18.7097C12.1426 19.5889 11.2114 20.2101 10.4342 19.7892L5.08949 18.9099ZM5.08949 18.9099L5.56578 19.7891L8 18.4706M5.08949 18.9099L8 18.4706M8 18.4706L10.4342 19.7891L10.9105 18.9099L8 18.4706Z'
                stroke='currentColor'
                strokeWidth='2'
            />
            <path
                d='M39.0008 8.64048L39.0008 8.64047C38.6068 7.7865 37.3932 7.78651 36.9992 8.64047L36.9992 8.64048L35.7541 11.3394L32.9705 11.6746L32.9705 11.6746C32.0772 11.7821 31.683 12.856 32.2923 13.5163L34.2086 15.5933L33.8814 18.7852C33.7911 19.6653 34.7234 20.2891 35.5029 19.8667L35.0406 19.0136L35.5029 19.8667L38 18.5134L40.4971 19.8667C41.2767 20.2891 42.2089 19.6653 42.1186 18.7852L42.1186 18.7852L41.7914 15.5933L43.7077 13.5163C44.317 12.856 43.9228 11.7821 43.0295 11.6746L40.2459 11.3394L39.0008 8.64048ZM41.5169 15.8909L41.5169 15.8908L41.5169 15.8909ZM35.2116 15.2187L35.2181 15.2127L35.2116 15.2187Z'
                stroke='currentColor'
                strokeWidth='2'
            />
            <path
                d='M17.0747 5.48379L17.1942 6.47662L17.0747 5.48379C16.1837 5.59104 15.7892 6.66259 16.3979 7.32202L18.7284 9.84677L18.3322 13.7097C18.242 14.5889 19.1732 15.2101 19.9504 14.7892L19.4741 13.9099L19.9504 14.7891L23 13.1373L26.0496 14.7891C26.8268 15.2101 27.758 14.589 27.6678 13.7097L27.2716 9.84677L29.6021 7.32201L28.8673 6.64373L29.6021 7.32201C30.2108 6.6626 29.8163 5.59104 28.9253 5.48379L25.5201 5.0739L23.9988 1.77767C23.6055 0.925622 22.3945 0.925616 22.0012 1.77767L20.4799 5.0739L17.0747 5.48379ZM27.0022 13.0306L27.001 13.0299L27.0022 13.0306ZM26.2703 9.47513L26.2614 9.46685L26.2703 9.47513ZM25.7307 5.53024L24.9135 5.90739L25.7307 5.53024L25.7303 5.52927C25.7304 5.52959 25.7306 5.52992 25.7307 5.53024ZM21.0865 5.90739L20.9789 5.01384L21.0865 5.90739Z'
                stroke='currentColor'
                strokeWidth='2'
            />
            <path
                d='M5.08949 18.9099L5.56578 19.7892C4.7886 20.2101 3.85743 19.589 3.94761 18.7097L4.26688 15.5968L2.39788 13.572C1.78917 12.9126 2.18371 11.841 3.0747 11.7338L3.19421 12.7266L3.0747 11.7338L5.7876 11.4072L7.00125 8.77767C7.3945 7.92562 8.6055 7.92561 8.99876 8.77767L10.2124 11.4072L12.9253 11.7338C13.8163 11.841 14.2108 12.9126 13.6021 13.572L11.7331 15.5968L12.0524 18.7097C12.1426 19.5889 11.2114 20.2101 10.4342 19.7892L5.08949 18.9099ZM5.08949 18.9099L5.56578 19.7891L8 18.4706M5.08949 18.9099L8 18.4706M8 18.4706L10.4342 19.7891L10.9105 18.9099L8 18.4706Z'
                stroke='currentColor'
                strokeWidth='2'
            />
            <path
                d='M39.0008 8.64048L39.0008 8.64047C38.6068 7.7865 37.3932 7.78651 36.9992 8.64047L36.9992 8.64048L35.7541 11.3394L32.9705 11.6746L32.9705 11.6746C32.0772 11.7821 31.683 12.856 32.2923 13.5163L34.2086 15.5933L33.8814 18.7852C33.7911 19.6653 34.7234 20.2891 35.5029 19.8667L35.0406 19.0136L35.5029 19.8667L38 18.5134L40.4971 19.8667C41.2767 20.2891 42.2089 19.6653 42.1186 18.7852L42.1186 18.7852L41.7914 15.5933L43.7077 13.5163C44.317 12.856 43.9228 11.7821 43.0295 11.6746L40.2459 11.3394L39.0008 8.64048ZM41.5169 15.8909L41.5169 15.8908L41.5169 15.8909ZM35.2116 15.2187L35.2181 15.2127L35.2116 15.2187Z'
                stroke='currentColor'
                strokeWidth='2'
            />
        </g>
        <defs>
            <clipPath id='clip0_13622_192'>
                <rect width='46' height='46' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default ConferenceIcon
