import { PATH_NEW_LOADER } from 'config/paths'

export interface IFeatureMenu {
    key: string
    path: string
    icon: string
    name: string
    profileEnabled: boolean
}

export const data = [
    {
        key: 'feature1',
        path: PATH_NEW_LOADER.pages.blog,
        icon: 'blog',
        type: 'Blog',
        name: 'emulator:header:blog',
        profileEnabled: true,
    },
    {
        key: 'features6',
        path: PATH_NEW_LOADER.pages.calendar,
        icon: 'calendar',
        type: 'Calendar',
        name: 'emulator:header:calendar',
        profileEnabled: true,
    },
    {
        key: 'features8',
        path: PATH_NEW_LOADER.pages.gallery,
        icon: 'gallery',
        type: 'Gallery',
        name: 'Gallery',
        profileEnabled: true,
    },
    {
        key: 'features5',
        path: PATH_NEW_LOADER.pages.about,
        icon: 'about',
        type: 'Info',
        name: 'emulator:header:about',
        profileEnabled: true,
    },
    {
        key: 'features4',
        path: PATH_NEW_LOADER.pages.map,
        icon: 'map',
        type: 'Map',
        name: 'emulator:header:map',
        profileEnabled: true,
    },
    {
        key: 'features9',
        path: PATH_NEW_LOADER.pages.eReader,
        icon: 'ereader',
        type: 'EReader',
        name: 'emulator:header:eReader',
        profileEnabled: true,
    },
    // {
    //     key: 'features14',
    //     path: PATH_NEW_LOADER.pages.forum,
    //     icon: 'forum',
    //     name: 'Forum',
    //     type: 'Forum',
    //     profileEnabled: true,
    // },
    {
        key: 'features7',
        path: PATH_NEW_LOADER.pages.cloud,
        icon: 'cloud',
        type: 'CloudContent',
        name: 'emulator:header:cloud',
        profileEnabled: true,
    },
    // {
    //     key: 'features3',
    //     path: PATH_NEW_LOADER.pages.smm,
    //     icon: 'smm',
    //     type: 'Smm',
    //     name: 'Smm',
    //     profileEnabled: true,
    // },
    // {
    //     key: 'feature11',
    //     path: PATH_NEW_LOADER.pages.chat,
    //     icon: 'chat',
    //     type: 'Chat',
    //     name: 'Chat',
    //     profileEnabled: true,
    // },
    // {
    //     key: 'features10',
    //     path: PATH_NEW_LOADER.pages.videoConference,
    //     icon: 'conference',
    //     type: 'VideoConference',
    //     name: 'Conference',
    //     profileEnabled: true,
    // },
    // {
    //     key: 'features2',
    //     path: PATH_NEW_LOADER.pages.video,
    //     icon: 'video',
    //     type: 'Blog',
    //     name: 'Video',
    //     profileEnabled: true,
    // },
    // {
    //     key: 'features15',
    //     path: PATH_NEW_LOADER.pages.survey,
    //     icon: faMarker,
    //     name: 'Survey',
    //     type: 'Survey',
    //     profileEnabled: true,
    // },
    // {
    //     key: 'features12',
    //     path: PATH_NEW_LOADER.pages.chatPremium,
    //     icon: 'chat-premium',
    //     type: 'LiveChat',
    //     name: 'Live chat',
    //     profileEnabled: true,
    // },

    // {
    //     key: 'features13',
    //     path: PATH_NEW_LOADER.pages.todo.root,
    //     icon: 'todo',
    //     type: 'Todo',
    //     name: 'Todo',
    //     profileEnabled: false,
    // },
    {
        key: 'features16',
        path: PATH_NEW_LOADER.pages.services,
        icon: 'services',
        name: 'emulator:header:services',
        type: 'Service',
        profileEnabled: true,
    },
    {
        key: 'features17',
        path: PATH_NEW_LOADER.pages.employee,
        icon: 'employee',
        type: 'Employee',
        name: 'emulator:header:employee',
        profileEnabled: true,
    },
    {
        key: 'features18',
        path: PATH_NEW_LOADER.pages.booking,
        icon: 'booking',
        name: 'emulator:header:booking',
        type: 'Booking',
        profileEnabled: true,
    },
    {
        key: 'feature19',
        path: PATH_NEW_LOADER.pages.news,
        icon: 'news',
        type: 'NewsAndPromotions',
        name: 'emulator:header:news',
        profileEnabled: true,
    },
    {
        key: 'feature20',
        path: PATH_NEW_LOADER.pages.billing,
        icon: 'billing',
        name: 'Billing',
        type: 'paymentLink',
        profileEnabled: true,
    },
    {
        key: 'feature21',
        path: PATH_NEW_LOADER.pages.payments,
        icon: 'payments',
        name: 'emulator:header:payments',
        type: 'payments',
        profileEnabled: true,
    },
]
