import React from 'react'
import parse from 'html-react-parser'
import './CustomCheckbox.scss'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Warning } from 'assets/images/icons/Warning.svg'

interface ICustomCheckbox {
    id?: string
    name?: string
    isChecked: boolean
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    text?: string
    readonly?: boolean
    isGrey?: boolean
    isDefault?: boolean
    withIcon?: boolean
    touched?: boolean
    error?: string
}

export const CustomCheckbox = ({
    id,
    name,
    isChecked,
    handleChange,
    text,
    readonly,
    isGrey,
    isDefault,
    error,
    touched,
    withIcon,
}: ICustomCheckbox): React.ReactElement => {
    const { t } = useTranslation()
    return (
        <>
            <label
                className={`custom-checkbox ${touched && error ? 'error' : ''}`}
                htmlFor={id}
            >
                <input
                    name={name}
                    id={id}
                    type='checkbox'
                    onChange={handleChange}
                    checked={isChecked}
                    readOnly={readonly}
                    className={isDefault ? 'checkmark-default--input' : ''}
                />
                <span
                    className={`checkmark ${isGrey && 'grey'} ${
                        isDefault && 'checkmark-default'
                    }`}
                />
                <div className='checkmark__text'>{parse(text || '')}</div>
            </label>
            {touched && error && (
                <p className='custom-input__error-message'>
                    {withIcon && <Warning />}
                    {t(error)}
                </p>
            )}
        </>
    )
}

CustomCheckbox.defaultProps = {
    id: 'checkbox',
    name: 'checkbox',
    text: '',
    handleChange: undefined,
    readonly: false,
    isGrey: false,
}

export default CustomCheckbox
