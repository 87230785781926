import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { DESIGN_URLS, ICON_URLS } from 'config/urls'

import { IDesignLogo } from '../store/types'

export const getDesignLogo = ({
    appId,
}: {
    appId: string
}): Promise<AxiosResponse<IDesignLogo>> =>
    axiosInstance.get(`${appId}/${DESIGN_URLS.designLogo}/settings`)

export const editDesignLogo = ({
    appId,
    designLogo,
}: {
    appId: string
    designLogo: IDesignLogo
}): Promise<AxiosResponse<IDesignLogo>> =>
    axiosInstance.post(`${appId}${DESIGN_URLS.designLogo}/settings`, designLogo)

export const createFinalLogo = ({
    appId,
    logoUrl,
}: {
    appId: string
    logoUrl: string
}): Promise<AxiosResponse<IDesignLogo>> =>
    axiosInstance.post(`${appId}${DESIGN_URLS.designLogo}/${logoUrl}`)

export const saveFinalLogo = ({
    appId,
    iconUrl,
}: {
    appId: string
    iconUrl: string
}): Promise<AxiosResponse<IDesignLogo>> =>
    axiosInstance.post(`${appId}${ICON_URLS.main}`, { iconUrl })
