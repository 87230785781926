import React, { useState, useEffect } from 'react'

type ReturnType<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export const useLocalStorageObject = <T,>(
    key: string,
    initialValue?: T,
): ReturnType<T> => {
    const [state, setState] = useState<T>(() => {
        if (!initialValue) return
        try {
            const value = localStorage.getItem(key)
            // TODO
            // eslint-disable-next-line consistent-return
            return value ? JSON.parse(value) : initialValue
        } catch (err) {
            // TODO
            // eslint-disable-next-line consistent-return
            return initialValue
        }
    })

    useEffect(() => {
        if (state) {
            try {
                localStorage.setItem(key, JSON.stringify(state))
            } catch (err) {
                console.log(err)
            }
        }
    }, [state, key])

    return [state, setState]
}
