import { eReaderTypes } from './actionTypes'
import { EReaderActions, EReaderState } from './types'

const initialState: EReaderState = {
    eReaders: undefined,
    eReader: undefined,
    relativeEReader: undefined,
}

export default (state = initialState, action: EReaderActions): EReaderState => {
    switch (action.type) {
        case eReaderTypes.SET_E_READERS:
            return {
                ...state,
                eReaders: action.payload.eReaders,
            }
        case eReaderTypes.SET_E_READER:
            return {
                ...state,
                eReader: action.payload.eReader,
            }

        case eReaderTypes.SET_RELATIVE_E_READER:
            return {
                ...state,
                relativeEReader: action.payload.relativeEReader,
            }
        default:
            return {
                ...state,
            }
    }
}
