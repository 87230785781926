import { todoPremiumTypes } from './actionTypes'
import { TodoPremiumActions, TodoPremiumState } from './types'

const initialState: TodoPremiumState = {
    settings: undefined,
}

export default (
    state = initialState,
    action: TodoPremiumActions,
): TodoPremiumState => {
    switch (action.type) {
        case todoPremiumTypes.SET_TODO_PREMIUM:
            return {
                ...state,
                settings: action.payload.settings,
            }
        default:
            return {
                ...state,
            }
    }
}
