import { lazy } from 'react'

import { PATH_ANALYTICS } from 'config/paths'

export const AnalyticsRoutes = [
    {
        id: 1,
        module: 'analytics',
        protected: true,
        exact: true,
        path: PATH_ANALYTICS.root,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/analytics/analytics-main/AnalyticsMain'
                ),
        ),
    },
    {
        id: 2,
        module: 'analytics',
        protected: true,
        exact: true,
        path: PATH_ANALYTICS.setup,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/dashboard/analytics/analytics-setup/AnalyticsSetup'
                ),
        ),
    },
]
