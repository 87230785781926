import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError, IPagination } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import { fetchBlogsAction } from 'containers/dashboard/features/blog/store/actions'

import { IBlog } from 'containers/dashboard/features/blog/store/types'

import './BlogPage.scss'
// eslint-disable-next-line max-len
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

const BlogPage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const { blogs } = useSelector((state: RootState) => state.blog)

    const isBlog = pathname.includes('blog')

    useEffect(() => {
        if (
            (pathname.includes('loader') || pathname.includes('news/create')) &&
            appId
        ) {
            dispatch(
                fetchBlogsAction({
                    appId,
                    page: 1,
                    language: i18n.language,
                    callback: (
                        type: string,
                        data: IPagination<IBlog>,
                        errorArray?: TError[],
                    ) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [appId, dispatch, pathname, t, i18n.language])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return (
        <div className='m-blog__wrapper'>
            {blogs ? (
                blogs.length > 0 ? (
                    blogs.map(item => (
                        <button
                            type='button'
                            key={item.id}
                            className='m-blog new-loader--surface'
                            onClick={() =>
                                push(
                                    isBlog
                                        ? // eslint-disable-next-line max-len
                                          `${PATH_NEW_LOADER.pages.blog}/${item.id}`
                                        : // eslint-disable-next-line max-len
                                          `${PATH_NEW_LOADER.pages.news}/${item.id}`,
                                )
                            }
                        >
                            <CustomImage
                                src={item.imageUrl || item.images?.[0]}
                                className='m-blog__image'
                            />
                            <div className='m-blog__info'>
                                <div className='m-blog__title new-loader--text'>
                                    {item.title}
                                </div>
                                <div className='m-blog__desc new-loader--text'>
                                    {item.description?.replace(
                                        regexForHtmlCharts,
                                        ' ',
                                    )}
                                </div>
                                {/* eslint-disable-next-line max-len */}
                                <div className='m-blog__read-more new-loader--primary-color'>
                                    {t('emulator:readMore')}
                                </div>
                            </div>
                        </button>
                    ))
                ) : (
                    <EmulatorEmpty />
                )
            ) : (
                <Preloader />
            )}
        </div>
    )
}

export default BlogPage
