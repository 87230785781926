import { menuTypes } from './actionTypes'
import {
    SetMenuOpen,
    SetHeaderMenuOpen,
    SetAiPopupOpen,
    SetShowAd,
} from './types'

export const SetOpenMenuAction = (payload: {
    isOpen: boolean
}): SetMenuOpen => ({
    type: menuTypes.SET_MENU_OPEN,
    payload,
})

export const SetHeaderMenuAction = (payload: {
    isOpen: boolean
}): SetHeaderMenuOpen => ({
    type: menuTypes.SET_HEADER_MENU_OPEN,
    payload,
})

export const SetAiPopupAction = (payload: {
    isOpen: boolean
}): SetAiPopupOpen => ({
    type: menuTypes.SET_AI_POPUP_OPEN,
    payload,
})

export const SetShowAdAction = (payload: { showAd: boolean }): SetShowAd => ({
    type: menuTypes.SET_SHOW_AD,
    payload,
})
