import { smmTypes } from './actionTypes'
import { SmmActions, SmmState } from './types'

const initialState: SmmState = {
    smmDetails: undefined,
}

export default (state = initialState, action: SmmActions): SmmState => {
    switch (action.type) {
        case smmTypes.SET_SMM:
            return {
                ...state,
                smmDetails: action.payload.smmDetails,
            }
        default:
            return {
                ...state,
            }
    }
}
