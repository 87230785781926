import React from 'react'

export const CloseIcon = (): React.ReactElement => (
    <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M0.235913 0.435489L0.329502 0.329502C0.735053 -0.076038 1.37319 -0.107234 1.81451 0.235913L1.9205 0.329502L9 7.40813L16.0795 0.329502C16.5188 -0.109833 17.2312 -0.109833 17.6705 0.329502C18.1098 0.768848 18.1098 1.48115 17.6705 1.9205L10.5919 9L17.6705 16.0795C18.0761 16.4851 18.1072 17.1232 17.7641 17.5645L17.6705 17.6705C17.2649 18.0761 16.6268 18.1072 16.1855 17.7641L16.0795 17.6705L9 10.5919L1.9205 17.6705C1.48115 18.1098 0.768848 18.1098 0.329502 17.6705C-0.109833 17.2312 -0.109833 16.5188 0.329502 16.0795L7.40813 9L0.329502 1.9205C-0.076038 1.51495 -0.107234 0.876815 0.235913 0.435489L0.329502 0.329502L0.235913 0.435489Z'
            fill='currentColor'
        />
    </svg>
)

export default CloseIcon
