import React, { ReactNode, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

// eslint-disable-next-line max-len
import { SetActiveTab } from 'containers/dashboard/features/cloud/components/tabs/cloud/store/actions'
import { RootState } from 'redux/reducers/rootReducer'

import { cloudTabs, cloudTabsType } from 'config/cloudTabs'
// eslint-disable-next-line max-len
import CloudTabs from 'containers/dashboard/features/cloud/components/cloud-tabs/CloudTabs'
import CloudTab from './tabs/cloud/Cloud'
import VideoPage from './tabs/video/VideoPage'
import ChatPage from './tabs/chat/ChatPage'
import SmmPage from './tabs/smm/SmmPage'
import VideoConferencePage from './tabs/conference/ConferencePage'

const CloudPage = (): React.ReactElement => {
    const dispatch = useDispatch()

    const { activeTab } = useSelector((state: RootState) => state.cloud)

    const setActiveTab = (type: string) => {
        dispatch(
            SetActiveTab({
                activeTab: type,
            }),
        )
    }

    useEffect(() => {
        if (!activeTab) {
            dispatch(
                SetActiveTab({
                    activeTab: cloudTabsType.VIDEO,
                }),
            )
        }
    }, [activeTab, dispatch])

    const getCurrentTab = (): ReactNode => {
        switch (activeTab) {
            case cloudTabsType.CHAT:
                return <ChatPage />
            case cloudTabsType.CLOUD:
                return <CloudTab />
            case cloudTabsType.CONFERENCE:
                return <VideoConferencePage />
            case cloudTabsType.SMM:
                return <SmmPage />
            case cloudTabsType.VIDEO:
                return <VideoPage />
            default:
                return <VideoPage />
        }
    }

    return (
        <>
            <CloudTabs
                cloudTabs={cloudTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                emulatorType
            />
            <div className='page'>{getCurrentTab()}</div>
        </>
    )
}
export default CloudPage
