import { lazy } from 'react'

import { PATH_FEATURES } from 'config/paths'

export const CloudRoute = [
    {
        id: 1,
        module: 'app-list',
        protected: true,
        exact: false,
        path: PATH_FEATURES.cloud.root,
        component: lazy(
            () => import('containers/dashboard/features/cloud/Cloud'),
        ),
    },
]
