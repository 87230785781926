import React, { Dispatch, SetStateAction } from 'react'

import { useFormik, FormikProvider, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import CloseIcon from 'assets/images/new-loader/todo/CloseIcon'

// eslint-disable-next-line max-len
import LoaderSelect from 'containers/emulator/components/shared/select/LoaderSelect'
// eslint-disable-next-line max-len
import LoaderButton from 'containers/emulator/components/shared/button/LoaderButton'
// eslint-disable-next-line max-len
import LoaderInput from 'containers/emulator/components/shared/input/LoaderInput'
import Overlay from 'containers/emulator/components/shared/overlay/Overlay'

import { todoTaskSchema } from 'schemas/emulator/todo'
import { priorities } from '../../../data'
import { ITasks, ITodo } from '../../../TodoPage'

import './TaskPopup.scss'

interface ITaskPopup {
    onClose: () => void
    setStorage: Dispatch<SetStateAction<ITodo>>
    todoItem?: ITasks
    storage: ITodo
    isCreate?: boolean
    currentCategory: number
}

const TaskPopup = ({
    onClose,
    todoItem,
    storage,
    setStorage,
    isCreate,
    currentCategory,
}: ITaskPopup): React.ReactElement => {
    const { t } = useTranslation(['emulator'])
    // Move to utils
    const getRandomValue = () => Math.floor(Math.random() * (1000000 - 10) + 10)

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: todoTaskSchema,
        initialValues: {
            id: todoItem?.id || '',
            value: todoItem?.value || '',
            priority: todoItem?.priority || 0,
            category: todoItem?.category || currentCategory,
            isActive: todoItem?.isActive || isCreate || false,
            isArchive: todoItem?.isArchive || false,
        },
        onSubmit: (task: ITasks) => {
            if (isCreate) {
                const newItem = {
                    ...task,
                    id: getRandomValue().toString(),
                }
                const updatedTodo = [...storage.tasks, newItem]
                setStorage({ ...storage, tasks: updatedTodo })
                onClose()
            } else {
                const taskIdx = storage.tasks.findIndex(i => i.id === task.id)
                const todoCopy = [...storage.tasks]
                todoCopy[taskIdx] = { ...task }
                setStorage({ ...storage, tasks: todoCopy })
                onClose()
            }
        },
    })

    const {
        values,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        errors,
        touched,
    } = formik

    return (
        <Overlay topLevel center>
            <FormikProvider value={formik}>
                <Form noValidate autoComplete='off' onSubmit={handleSubmit}>
                    <div className='new-loader--background m-task-popup'>
                        <div className='m-task-popup__header'>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-task-popup__title new-loader--text'>
                                {t('emulator:addTAsk')}
                            </div>
                            <button
                                type='button'
                                onClick={onClose}
                                className='new-loader--primary-color'
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className='m-task-popup__body'>
                            <LoaderInput
                                {...getFieldProps('value')}
                                placeholder='Enter task name'
                                error={errors?.value}
                                touched={touched?.value}
                            />
                            <LoaderSelect
                                value={values.priority}
                                menuItems={priorities}
                                handleChange={(value: number) => {
                                    setFieldValue('priority', value)
                                }}
                                placeholder='Select priority'
                            />
                            <LoaderSelect
                                value={values.category}
                                handleChange={(value: number) => {
                                    setFieldValue('category', value)
                                }}
                                menuItems={storage.categories}
                                placeholder='Select category'
                            />
                            <LoaderButton
                                type='submit'
                                disabled={
                                    values.value === '' ||
                                    values.category === 0 ||
                                    values.priority === 0
                                }
                                className='m-task-popup__button'
                            >
                                {isCreate
                                    ? t('emulator:create')
                                    : t('emulator:save')}
                            </LoaderButton>
                        </div>
                    </div>
                </Form>
            </FormikProvider>
        </Overlay>
    )
}

export default TaskPopup
