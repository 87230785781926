import React, { ChangeEventHandler } from 'react'

import { useTranslation } from 'react-i18next'

import { ReactComponent as Warning } from 'assets/images/icons/Warning.svg'

import './CustomInput.scss'

interface ICustomInput {
    onChange?: ChangeEventHandler<HTMLInputElement> | undefined
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    value: string | number
    name: string
    type: string
    placeholder?: string
    touched?: boolean
    error?: string
    className?: string
    disabled?: boolean
    label?: string
    readOnly?: boolean
    pattern?: string
    withIcon?: boolean
    max?: number
}

const CustomInput = ({
    value,
    name,
    placeholder,
    type,
    error,
    touched,
    onChange,
    handleBlur,
    className,
    disabled,
    label,
    readOnly,
    pattern,
    withIcon,
    max,
}: ICustomInput): React.ReactElement => {
    const { t } = useTranslation()

    return (
        <div className='custom-input__wrapper'>
            {label ? <div className='custom-label'>{label}</div> : null}
            <input
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                className={`custom-input ${
                    touched && error && 'custom-input__error'
                } ${className}`}
                autoComplete='off'
                disabled={disabled}
                readOnly={readOnly}
                pattern={pattern}
                onBlur={handleBlur}
                max={max}
                maxLength={max}
            />
            {touched && error && (
                <p className='custom-input__error-message'>
                    {withIcon && <Warning />}
                    {t(error)}
                </p>
            )}
        </div>
    )
}

CustomInput.defaultProps = {
    placeholder: '',
    className: undefined,
    disabled: false,
}

export default CustomInput
