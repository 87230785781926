import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { CONFERENCE_URLS } from 'config/urls'

import { IConference, IConferenceMultilang } from '../store/types'

export const getConference = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IConference[]>> =>
    axiosInstance.get(`${appId}${CONFERENCE_URLS.main}/language/${language}`)

export const getConferenceById = ({
    appId,
    conferenceId,
}: {
    appId: string
    conferenceId: string
}) => axiosInstance.get(`${appId}${CONFERENCE_URLS.main}/${conferenceId}}`)

export const editConference = ({
    conference,
    appId,
    id,
}: {
    conference: IConference
    appId: string
    id: string
}): Promise<AxiosResponse<IConference>> =>
    axiosInstance.put(`${appId}${CONFERENCE_URLS.main}/${id}`, conference)

export const createConference = ({
    item,
    appId,
}: {
    item: IConferenceMultilang
    appId: string
}): Promise<AxiosResponse<IConference>> =>
    axiosInstance.post(`${appId}${CONFERENCE_URLS.main}`, item)

export const deleteConference = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<IConference>> =>
    axiosInstance.delete(`${appId}${CONFERENCE_URLS.main}/${id}`)
