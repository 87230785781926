import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const BillingRoutes = [
    {
        id: 1,
        module: 'loader-billing',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.billing,
        component: lazy(
            () =>
                import(
                    'containers/emulator/pages/billing/billing-page/BillingPage'
                ),
        ),
    },
    // {
    //     id: 2,
    //     module: 'loader-billing',
    //     protected: false,
    //     exact: true,
    //     path: `${PATH_NEW_LOADER.pages.billing}/:id`,
    //     component: lazy(
    // eslint-disable-next-line max-len
    //         () => import('containers/emulator/pages/billing/billing-item/BillingItem'),
    //     ),
    // },
]
