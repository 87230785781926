import React from 'react'

const CheckboxBlank = (): React.ReactElement => (
    <svg
        className='m-task__blank'
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='9' cy='9' r='8' strokeWidth='2' stroke='currentColor' />
    </svg>
)

export default CheckboxBlank
