import React from 'react'

// eslint-disable-next-line max-len
// import MasonryColumn from 'containers/emulator/hoc/masonry-column/MasonryColumn'

import CloudItem from '../cloud-item/CloudItem'

import './CloudView.scss'

type TData = {
    id: string
    type: string
    description: string
    url: string
}

interface IData {
    data: TData[]
}

const CloudBlockItem = ({ data }: IData): React.ReactElement => (
    <div className='m-cloud'>
        {data.map(item => (
            <CloudItem item={item} key={item.id} />
        ))}
        {/* <MasonryColumn>
            {data.map((item, idx) =>
                !(idx % 2) ? <CloudItem item={item} key={item.id} /> : '',
            )}
        </MasonryColumn>
        <MasonryColumn>
            {data.map((item, idx) =>
                idx % 2 ? <CloudItem item={item} key={item.id} /> : '',
            )}
        </MasonryColumn> */}
    </div>
)

export default CloudBlockItem
