import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const ChatPremiumRoutes = [
    {
        id: 1,
        module: 'loader-prem-chat',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.chatPremium,
        component: lazy(
            () => import('containers/emulator/pages/premium-chat/PremiumChat'),
        ),
    },
]
