import React from 'react'

import { useSelector } from 'react-redux'
// import { useCookies } from 'react-cookie'

import { RootState } from 'redux/reducers/rootReducer'

import './RightPanel.scss'
import RightEmulator from './components/RightEmulator'

const RightPanel = (): React.ReactElement => {
    const { showAd } = useSelector((state: RootState) => state.menus)

    // const cookies = useCookies(['isExpired', 'isTrialUsed'])[0]

    return (
        <div className='right-panel__wrapper'>
            {/* standard case */}
            <div
                className={`right-panel ${
                    // (cookies.isTrialUsed === 'false' ||
                    //     cookies.isExpired === 'true') &&
                    showAd ? 'right-panel--sale' : ''
                }
    `}
            >
                {/* sale case */}
                {/* <div
                className={`right-panel ${showAd ? 'right-panel--sale' : ''}
    `} */}
                {/* > */}
                <RightEmulator />
            </div>
        </div>
    )
}

export default RightPanel
