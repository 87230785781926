import { accountTypes } from './actionTypes'
import { AccountActions, AccountState } from './types'

const initialState: AccountState = {
    settings: undefined,
}

export default (state = initialState, action: AccountActions): AccountState => {
    switch (action.type) {
        case accountTypes.SET_SETTINGS:
            return {
                ...state,
                settings: action.payload.settings,
            }
        default:
            return {
                ...state,
            }
    }
}
