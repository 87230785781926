import React, { useState } from 'react'

import useOnclickOutside from 'react-cool-onclickoutside'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line max-len
import { ReactComponent as ArrowLeft } from 'assets/images/button-icons/ArrowLeft.svg'
import { ReactComponent as Web } from 'assets/images/icons/Web.svg'

import { ReactComponent as Britain } from 'assets/images/flags/Britain.svg'
import { ReactComponent as Ukraine } from 'assets/images/flags/Ukraine.svg'

import './Language.scss'

const Language = ({
    design,
}: {
    design: 'white' | 'black'
}): React.ReactElement => {
    const { i18n } = useTranslation(['menu'])

    const [showList, setShowList] = useState(false)

    const listRef = useOnclickOutside(() => {
        setShowList(false)
    })

    const languages = [
        {
            lngShortName: 'en',
            lngName: 'English',
            flag: <Britain />,
        },
        {
            lngShortName: 'uk',
            lngName: 'Українська',
            flag: <Ukraine />,
        },
    ]

    return (
        <div className='language-choose__wrapper' ref={listRef}>
            <button
                type='button'
                onClick={() => setShowList(!showList)}
                className={`language-choose language-choose--${design}`}
            >
                <Web />
                {i18n.language.toUpperCase() === 'EN' ? 'EN' : 'UA'}
                <div
                    className={
                        showList
                            ? 'language-choose__arrow-up'
                            : 'language-choose__arrow-down'
                    }
                >
                    <ArrowLeft />
                </div>
            </button>
            {showList ? (
                <div
                    // eslint-disable-next-line max-len
                    className={`language-choose__list language-choose__list--${design}`}
                >
                    {languages.map(lng => (
                        <button
                            key={lng.lngShortName}
                            type='button'
                            onClick={() => {
                                localStorage.setItem(
                                    'userLanguage',
                                    lng.lngShortName,
                                )
                                i18n.changeLanguage(lng.lngShortName)
                                setShowList(false)
                            }}
                        >
                            <input
                                id={lng.lngName}
                                type='radio'
                                checked={lng.lngShortName === i18n.language}
                                readOnly
                            />
                            {lng.flag}
                            {lng.lngName}
                        </button>
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default Language
