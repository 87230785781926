import React from 'react'

export const Firebase = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M12.8571 38.1739H24C24.4997 38.1739 24.9048 38.5827 24.9048 39.087C24.9048 39.5912 24.4997 40 24 40H12.8571C12.3575 40 11.9524 39.5912 11.9524 39.087C11.9524 38.5827 12.3575 38.1739 12.8571 38.1739Z'
            fill='currentColor'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M28.5 2H8.5C6.84315 2 5.5 3.34315 5.5 5V41C5.5 42.6569 6.84315 44 8.5 44H28.5C30.1569 44 31.5 42.6569 31.5 41V33.874C33.2252 33.4299 34.5 31.8638 34.5 30V29H39.5C40.0523 29 40.5 28.5523 40.5 28C40.5 27.4477 40.0523 27 39.5 27H38.5V20C38.5 15.9204 35.4463 12.554 31.5 12.0619V5C31.5 3.34315 30.1569 2 28.5 2ZM28.5 42H8.5C7.94772 42 7.5 41.5523 7.5 41V32H27.0351C27.5654 32.9168 28.4492 33.6035 29.5 33.874V41C29.5 41.5523 29.0523 42 28.5 42ZM28.5 4C29.0523 4 29.5 4.44772 29.5 5V12.0619C25.5537 12.554 22.5 15.9204 22.5 20V27H21.5C20.9477 27 20.5 27.4477 20.5 28C20.5 28.5523 20.9477 29 21.5 29H26.5V30H7.5V5C7.5 4.44772 7.94772 4 8.5 4H12.5V6C12.5 7.10457 13.3954 8 14.5 8H22.5C23.6046 8 24.5 7.10457 24.5 6V4H28.5ZM14.5 4H22.5V5C22.5 5.55228 22.0523 6 21.5 6H15.5C14.9477 6 14.5 5.55228 14.5 5V4ZM28.5 30V29H31.5H32.5V30C32.5 31.1046 31.6046 32 30.5 32C29.3954 32 28.5 31.1046 28.5 30ZM35.9172 17.4172C36.2908 18.1994 36.5 19.0753 36.5 20V27H24.5V20C24.5 17.027 26.6623 14.559 29.5 14.083C29.504 14.0823 29.5079 14.0816 29.5119 14.081C29.5486 14.0749 29.5854 14.0691 29.6224 14.0637C29.9088 14.0217 30.2019 14 30.5 14C30.8407 14 31.1748 14.0284 31.5 14.083C32.0321 14.1722 32.5404 14.3315 33.0152 14.551C33.0378 14.5615 33.0604 14.5721 33.0828 14.5828C34.3178 15.1727 35.3196 16.1725 35.9119 17.4061L35.9172 17.4172Z'
            fill='currentColor'
        />
    </svg>
)

export default Firebase
