// This is to know where to remove right pannel
export const data = [
    {
        key: 'app-name',
        path: 'app-name',
    },
    {
        key: 'settings',
        path: 'settings',
    },
    {
        key: 'analytics',
        path: 'analytics',
    },
    {
        key: 'build',
        path: 'build',
    },
    {
        key: 'publishing',
        path: 'publishing',
    },
    {
        key: 'loader',
        path: 'loader',
    },
    {
        key: 'constructor',
        path: 'constructor',
    },
]
