import React from 'react'

import CustomImage from 'components/custom-image/CustomImage'
import socialMediaIcons from 'config/socialMediaIcons'

import './LinkIconRender.scss'

interface ILinkIconRender {
    title: string
    text: 'small' | 'medium' | 'large'
    description?: string
    isBold?: boolean
}

const renderIcon = (title: string) => {
    switch (title) {
        case 'FacebookMessenger':
            return socialMediaIcons.chat.messenger
        case 'WhatsApp':
            return socialMediaIcons.chat.whatsApp
        case 'Viber':
            return socialMediaIcons.chat.viber
        case 'Telegram':
            return socialMediaIcons.chat.telegram
        case 'Email':
            return socialMediaIcons.chat.email
        case 'Facebook':
            return socialMediaIcons.smm.facebook
        case 'LinkedIn':
            return socialMediaIcons.smm.linkedIn
        case 'Pinterest':
            return socialMediaIcons.smm.pinterest
        case 'Instagram':
            return socialMediaIcons.smm.instagram
        case 'Twitter':
            return socialMediaIcons.smm.twitter
        case 'MXTakatak':
            return socialMediaIcons.smm.mXTakatak
        case 'Moj':
            return socialMediaIcons.smm.moj
        case 'Discord':
            return socialMediaIcons.smm.discord
        case 'TikTok':
            return socialMediaIcons.smm.tikTok
        case 'Reddit':
            return socialMediaIcons.smm.reddit
        case 'Youtube':
            return socialMediaIcons.smm.youTube
        case 'YouTube':
            return socialMediaIcons.smm.youTube
        case 'GoogleMeet':
            return socialMediaIcons.conference.googleMeet
        case 'Skype':
            return socialMediaIcons.conference.skype
        case 'TeamViewer':
            return socialMediaIcons.conference.teamViewer
        case 'Zoom':
            return socialMediaIcons.conference.zoom
        case 'AdobeConnect':
            return socialMediaIcons.conference.adobe
        case 'Intermedia':
            return socialMediaIcons.conference.intermedia
        case 'Teams':
            return socialMediaIcons.conference.microsoftTeams
        case 'Genesys':
            return socialMediaIcons.conference.genesys
        case 'Twitch':
            return socialMediaIcons.video.twitch
        case 'DailyMotion':
            return socialMediaIcons.video.dailymotion
        case 'Ted':
            return socialMediaIcons.video.ted
        case 'Vimeo':
            return socialMediaIcons.video.vimeo
        case 'Amazon':
            return socialMediaIcons.cloud.amazon
        case 'Dropbox':
            return socialMediaIcons.cloud.dropbox
        case 'GoogleDrive':
            return socialMediaIcons.cloud.googleDrive
        case 'iCloud':
            return socialMediaIcons.cloud.iCloud
        case 'iDrive':
            return socialMediaIcons.cloud.iDrive
        case 'Mega':
            return socialMediaIcons.cloud.mega
        case 'OneDrive':
            return socialMediaIcons.cloud.oneDrive
        case 'Sync':
            return socialMediaIcons.cloud.sync
        case 'Tresorit':
            return socialMediaIcons.cloud.tresorit

        default:
            return ''
    }
}

const renderTitle = (title: string) => {
    switch (title) {
        case 'FacebookMessenger':
            return 'Messenger'
        case 'MXTakatak':
            return 'MX Takatak'
        case 'TikTok':
            return 'Tik Tok'
        case 'Youtube':
            return 'YouTube'
        default:
            return title
    }
}

const LinkIconRender = ({
    title,
    text,
    description,
    isBold,
}: ILinkIconRender): React.ReactElement => (
    <div className='new-loader--text link-icon-text'>
        <div className={`link-icon-text__title link-icon-text--${text}`}>
            <CustomImage src={renderIcon(title)} />
            <span className={isBold ? 'acrom-bold' : ''}>
                {renderTitle(title)}
            </span>
        </div>
        {description ? (
            <div className='link-icon-text__desc'>{description}</div>
        ) : null}
    </div>
)

export default LinkIconRender
