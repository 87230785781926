import React from 'react'

const CloseIcon = (): React.ReactElement => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1.20166 14.8135C1.51709 15.1372 2.04004 15.1289 2.35547 14.8135L8 9.16895L13.6445 14.8135C13.9517 15.1289 14.4746 15.1372 14.79 14.8135C15.1055 14.5063 15.0972 13.9751 14.79 13.668L9.14551 8.02344L14.79 2.37891C15.0972 2.07178 15.1055 1.54053 14.79 1.2334C14.4746 0.909668 13.9517 0.917969 13.6445 1.2334L8 6.87793L2.35547 1.2334C2.04004 0.917969 1.51709 0.909668 1.20166 1.2334C0.88623 1.54053 0.894531 2.07178 1.20166 2.37891L6.84619 8.02344L1.20166 13.668C0.894531 13.9751 0.88623 14.5063 1.20166 14.8135Z'
            fill='currentColor'
        />
    </svg>
)

export default CloseIcon
