import React from 'react'

export const BlogIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M8.52381 39.4348C8.52381 38.9305 8.92889 38.5217 9.42857 38.5217H36.5714C37.0711 38.5217 37.4762 38.9305 37.4762 39.4348C37.4762 39.939 37.0711 40.3478 36.5714 40.3478H9.42857C8.92889 40.3478 8.52381 39.939 8.52381 39.4348Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.3708 20.3144C26.6155 20.529 27.7317 21.1173 28.5913 21.9551C29.687 23.0227 30.3672 24.4963 30.3672 26.118C30.3672 27.7397 29.687 29.2134 28.5913 30.281C27.4957 31.3487 25.9833 32.0114 24.3191 32.0114H16.6068C16.0687 32.0114 15.6325 31.5863 15.6325 31.062V11.2859C15.6325 10.7616 16.0687 10.3365 16.6068 10.3365H21.0194C22.6837 10.3365 24.1961 10.9994 25.2917 12.0669C26.3874 13.1346 27.0676 14.6083 27.0676 16.23C27.0676 17.8122 26.42 19.2536 25.3708 20.3144ZM21.0194 20.2246H17.5811V12.2352H21.0194C22.1458 12.2352 23.1706 12.685 23.914 13.4094C24.6574 14.1338 25.119 15.1324 25.119 16.23C25.119 17.3275 24.6574 18.3261 23.914 19.0505C23.1706 19.7749 22.1458 20.2246 21.0194 20.2246ZM24.3191 22.1234C25.4455 22.1234 26.4702 22.5731 27.2136 23.2975C27.9571 24.0219 28.4186 25.0204 28.4186 26.1181C28.4186 27.2156 27.9571 28.2141 27.2136 28.9385C26.4702 29.663 25.4455 30.1127 24.3191 30.1127H17.5811V22.1234H24.3191Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.42857 2C6.43045 2 4 4.4527 4 7.47826V38.5217C4 41.5473 6.43045 44 9.42857 44H36.5714C39.5695 44 42 41.5473 42 38.5217V7.47826C42 4.4527 39.5695 2 36.5714 2H9.42857ZM36.5714 3.82609H9.42857C7.42983 3.82609 5.80952 5.46122 5.80952 7.47826V38.5217C5.80952 40.5388 7.42983 42.1739 9.42857 42.1739H36.5714C38.5702 42.1739 40.1905 40.5388 40.1905 38.5217V7.47826C40.1905 5.46122 38.5702 3.82609 36.5714 3.82609ZM9.42857 38.5217H36.5714C37.0711 38.5217 37.4762 38.9305 37.4762 39.4348C37.4762 39.939 37.0711 40.3478 36.5714 40.3478H9.42857C8.92889 40.3478 8.52381 39.939 8.52381 39.4348C8.52381 38.9305 8.92889 38.5217 9.42857 38.5217ZM25.3708 20.3144C26.6155 20.529 27.7317 21.1173 28.5913 21.9551C29.687 23.0227 30.3672 24.4963 30.3672 26.118C30.3672 27.7397 29.687 29.2134 28.5913 30.281C27.4957 31.3487 25.9833 32.0114 24.3191 32.0114H16.6068C16.0687 32.0114 15.6325 31.5863 15.6325 31.062V11.2859C15.6325 10.7616 16.0687 10.3365 16.6068 10.3365H21.0194C22.6837 10.3365 24.1961 10.9994 25.2917 12.0669C26.3874 13.1346 27.0676 14.6083 27.0676 16.23C27.0676 17.8122 26.42 19.2536 25.3708 20.3144ZM17.5811 22.1234V30.1127H24.3191C25.4455 30.1127 26.4702 29.663 27.2136 28.9385C27.9571 28.2141 28.4186 27.2156 28.4186 26.1181C28.4186 25.0204 27.9571 24.0219 27.2136 23.2975C26.4702 22.5731 25.4455 22.1234 24.3191 22.1234H17.5811ZM21.0194 20.2246H17.5811V12.2352H21.0194C22.1458 12.2352 23.1706 12.685 23.914 13.4094C24.6574 14.1338 25.119 15.1324 25.119 16.23C25.119 17.3275 24.6574 18.3261 23.914 19.0505C23.1706 19.7749 22.1458 20.2246 21.0194 20.2246Z'
            fill='currentColor'
        />
    </svg>
)

export default BlogIcon
