/* eslint-disable max-len */
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { fetchConferenceRequest } from 'containers/dashboard/features/cloud/components/tabs/video-conference/store/actions'
import { RootState } from 'redux/reducers/rootReducer'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import Preloader from 'components/preloader/Preloader'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

import ConferenceBlockItem from './components/conference-view/ConferenceView'

import './ConferencePage.scss'

const VideoConferencePage = () => {
    const { t, i18n } = useTranslation(['toast'])
    const dispatch = useDispatch()

    const { conferenceDetails } = useSelector(
        (state: RootState) => state.conference,
    )

    const appId = getAppIdCookie()

    useEffect(() => {
        if (!appId) return
        dispatch(
            fetchConferenceRequest({
                appId,
                language: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [dispatch, appId, t, i18n.language])

    return conferenceDetails ? (
        conferenceDetails.length > 0 ? (
            <ConferenceBlockItem data={conferenceDetails} />
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}
export default VideoConferencePage as React.FC
