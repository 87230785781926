import React from 'react'
import { useTranslation } from 'react-i18next'

interface IProps<T> {
    isActiveTab: (name: T) => boolean
    setTab: (name: T) => void
    tabs: T[]
}

export default function BookingTab<T>({
    isActiveTab,
    setTab,
    tabs,
}: IProps<T>) {
    const { t } = useTranslation(['booking'])
    return (
        <div className='m-booking__managment-tabs'>
            {tabs.map((item, i) => (
                <button
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className={`m-booking__managment-tab ${
                        isActiveTab(item)
                            ? 'new-loader--primary-color active'
                            : 'new-loader--text'
                    }`}
                    type='button'
                    onClick={() => setTab(item)}
                >
                    {t(`booking:tabs:${item}`)}
                </button>
            ))}
        </div>
    )
}
