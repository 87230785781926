import { chatTypes } from './actionTypes'
import {
    FetchChatsRequest,
    FetchChatsPayload,
    EditChatPayload,
    EditChatRequest,
    SetChats,
    IChat,
    CreateChatPayload,
    CreateChatRequest,
    DeleteChatPayload,
    DeleteChatRequest,
    FetchChatByIdPayload,
    FetchChatbyIdRequest,
} from './types'

export const fetchChatsAction = (
    payload: FetchChatsPayload,
): FetchChatsRequest => ({
    type: chatTypes.FETCH_CHAT_LINKS_REQUEST,
    payload,
})

export const fetchChatAction = (
    payload: FetchChatByIdPayload,
): FetchChatbyIdRequest => ({
    type: chatTypes.FETCH_CHAT_LINK_REQUEST,
    payload,
})

export const editChatsAction = (payload: EditChatPayload): EditChatRequest => ({
    type: chatTypes.EDIT_CHAT_LINKS_REQUEST,
    payload,
})

export const SetChatsAction = (payload: {
    chatsDetails?: IChat[]
}): SetChats => ({
    type: chatTypes.SET_CHAT_LINKS,
    payload,
})

export const createChatsAction = (
    payload: CreateChatPayload,
): CreateChatRequest => ({
    type: chatTypes.CREATE_CHAT_LINKS_REQUEST,
    payload,
})

export const DeleteChatsAction = (
    payload: DeleteChatPayload,
): DeleteChatRequest => ({
    type: chatTypes.DELETE_CHAT_LINKS_REQUEST,
    payload,
})
