import { FeatureRootRoutes } from './FeatureRoot.routes'
import { LocationRoutes } from './Location.routes'
// import { ChatRoute } from './Chat.routes'
import { CalendarRoutes } from './Calendar.routes'
import { GalleryRoutes } from './Gallery.routes'
import { EmployeeRoute } from './Employees.routes'
import { AboutRoutes } from './About.routes'
// import { VideoConferenceRoute } from './VideoConference.routes'
// import { SmmRoutes } from './Smm.routes'
import { BlogRoutes } from './Blog.routes'
import { ServicesRoutes } from './Services.routes'
import { BookingRoutes } from './Booking.routes'
import { CloudRoute } from './Cloud.routes'
import { SettingsRoutes } from './Settings.routes'
import { BrandingRoutes } from './Branding.routes'
import { DesignRoutes } from './Design.routes'
import { EReaderRoutes } from './Ereader.routes'
import { TodoPremiumRoutes } from './TodoPremium.routes'
import { AnalyticsRoutes } from './Analytics.routes'
import { ForumRoutes } from './Forum.routes'
import { BuildRoutes } from './Build.routes'
import { TestRoutes } from './Test.routes'
import { NewsRoutes } from './News.routes'
import { PaymentLinkRoutes } from './Billing.routes'
import { PaymentsRoutes } from './Payments.routes'

export default [
    ...FeatureRootRoutes,
    ...CalendarRoutes,
    ...LocationRoutes,
    ...GalleryRoutes,
    ...AboutRoutes,
    ...EmployeeRoute,
    // ...SmmRoutes,
    ...BlogRoutes,
    ...ServicesRoutes,
    ...BookingRoutes,
    // ...VideoConferenceRoute,
    ...PaymentLinkRoutes,
    // ...ChatRoute,
    ...CloudRoute,
    ...SettingsRoutes,
    ...BrandingRoutes,
    ...DesignRoutes,
    ...NewsRoutes,
    ...EReaderRoutes,
    ...TodoPremiumRoutes,
    ...AnalyticsRoutes,
    ...ForumRoutes,
    ...BuildRoutes,
    ...TestRoutes,
    ...PaymentsRoutes,
]
