import { PATH_WELCOME } from 'config/paths'
import { lazy } from 'react'

export const WelcomeRoutes = [
    {
        id: 1,
        module: 'welcome-page',
        protected: false,
        exact: true,
        path: PATH_WELCOME.root,
        component: lazy(() => import('containers/welcome-page/WelcomePage')),
    },
]
