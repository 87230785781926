import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import {
    CreateAiPicturesRequest,
    IGenerationItem,
    IGenerationImage,
} from './types'
import { generatePictures, getAllGeneratedItems } from '../service/aiPictures'
import { aiPictureActions } from './actionTypes'

function* fetchGenerationsSaga() {
    try {
        const response: AxiosResponse<IGenerationItem[]> = yield call(
            getAllGeneratedItems,
        )

        if (response.data) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const images = <any[]>[]

            response.data.forEach(item => {
                if (
                    item.generationType.toLowerCase() === 'picture' &&
                    item.fileUrl
                ) {
                    images.push(item.fileUrl)
                }
            })

            yield put({
                type: aiPictureActions.SET_AI_PICTURES,
                payload: { field: 'oldGenerations', value: images },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

function* createPicturesSaga(action: CreateAiPicturesRequest) {
    try {
        const response: AxiosResponse<IGenerationImage> = yield call(
            generatePictures,
            action.payload,
        )
        if (response && response.status === 200) {
            yield put({
                type: aiPictureActions.SET_AI_PICTURES,
                payload: { field: 'generations', value: response.data.images },
            })

            if (action.payload.callback) action.payload.callback(response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* aiPictures(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(
            aiPictureActions.FETCH_AI_PICTURES_REQUEST,
            fetchGenerationsSaga,
        ),
    ])
    yield all([
        takeLatest(
            aiPictureActions.CREATE_AI_PICTURES_REQUEST,
            createPicturesSaga,
        ),
    ])
}

export default aiPictures
