import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import parse from 'html-react-parser'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import './AboutItem.scss'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

// eslint-disable-next-line max-len
import { fetchInformationItemAction } from 'containers/dashboard/features/about/store/actions'

type PageParams = {
    id: string
}

const AboutItem = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast'])
    const dispatch = useDispatch()
    const appId = getAppIdCookie()
    const { id } = useParams<PageParams>()

    const { informationDetails } = useSelector(
        (state: RootState) => state.information,
    )

    useEffect(() => {
        if (!appId) return
        if (!id) return
        dispatch(
            fetchInformationItemAction({
                infoId: id,
                appId,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    return (
        <>
            {informationDetails ? (
                <div className='m-about-item'>
                    <CustomImage src={informationDetails.imageUrl} />
                    <div className='m-about-item__info new-loader--surface'>
                        <div className='m-about-item__title new-loader--text'>
                            {informationDetails.title}
                        </div>
                        {/* eslint-disable-next-line max-len */}
                        <div className='m-about-item__subtitle new-loader--text'>
                            {informationDetails.type === 'Company'
                                ? 'About company'
                                : 'Meet the team'}
                        </div>
                        <div className='m-about-item__desc new-loader--text'>
                            {parse(informationDetails.description)}
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default AboutItem
