import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const MapRoutes = [
    {
        id: 1,
        module: 'loader-map',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.map,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/map/map-page/MapPage'
                ),
        ),
    },
    {
        id: 2,
        module: 'loader-map',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.map}/:id`,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/map/map-item/MapItemPage'
                ),
        ),
    },
]
