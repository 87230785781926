import { AxiosResponse } from 'axios'
import moment from 'moment'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { calendarTypes } from './actionTypes'
import {
    getEventsByDate,
    getDatesByYearMonth,
    getEventById,
    createEvent,
    editEvent,
    deleteEvent,
    getUnconfirmedEvents,
    getAllEvents,
    acceptEvent,
    rejectEvent,
} from '../services/calendar'

import {
    IEvent,
    IProposedEvent,
    FetchEventDates,
    FetchEventsByDateRequest,
    FetchEventRequest,
    CreateEventsRequest,
    EditEventRequest,
    DeleteEventRequest,
    FetchUnconfirmedEventsRequest,
    FetchAllEventsRequest,
    AcceptEventRequest,
    RejectEventRequest,
    IEventData,
} from './types'

function* fetchDatesWithEventsSaga(action: FetchEventDates) {
    try {
        const response: AxiosResponse<Date[]> = yield call(
            getDatesByYearMonth,
            action.payload,
        )
        if (response.data) {
            const updatedData = response.data.map((date: Date) =>
                moment(date).format('yyyy-MM-DD'),
            )
            yield put({
                type: calendarTypes.SET_DATES,
                payload: { datesWithEvents: updatedData },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchEventsByDateSaga(action: FetchEventsByDateRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            getEventsByDate,
            action.payload,
        )
        if (response.data) {
            console.log(action.payload, 'payload')

            console.log(response.data, 'resp')

            yield put({
                type: calendarTypes.SET_EVENTS,
                payload: { events: response.data },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchEventByIdSaga(action: FetchEventRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            getEventById,
            action.payload,
        )
        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'ua' : 'en'
                let relativeEvent: IEventData = {
                    id: '2',
                    relativeId: response.data.id,
                    description: '',
                    startDate: '',
                    startTime: '',
                    endDate: '',
                    endTime: '',
                    pictureUrl: '',
                    status: '',
                    language: oppositeLang,
                }
                if (response.data.relativeId) {
                    const props = {
                        eventId: response.data.relativeId,
                        appId: action.payload.appId,
                    }
                    try {
                        const relativeReps: AxiosResponse<IEventData> =
                            yield call(getEventById, props)
                        const { data } = relativeReps
                        if (data) {
                            relativeEvent = {
                                id: data.id || '2',
                                description: data.description || '',
                                relativeId: response.data.id,
                                startDate: data.startDate || '',
                                startTime: data.startTime || '',
                                endDate: data.endDate || '',
                                endTime: data.endTime || '',
                                pictureUrl: data.pictureUrl || '',
                                status: data.status || '',
                                language: data.language,
                            }
                        }
                    } catch (error) {
                        sagaErrorHandle(action.payload.callback, error)
                    }
                }

                yield put({
                    type: calendarTypes.SET_RELATIVE_EVENT,
                    payload: { relativeEvent },
                })
            }

            console.log(response.data, 'response.data')

            yield put({
                type: calendarTypes.SET_EVENT,
                payload: { calendarEvent: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createEventSaga(action: CreateEventsRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            createEvent,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editEventSaga(action: EditEventRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            editEvent,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteEventSaga(action: DeleteEventRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            deleteEvent,
            action.payload,
        )
        if (response.status === 200) {
            console.log('payload', action.payload)
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { events, datesWithEvents } = yield select(
                state => state.calendar,
            )
            const updatedEvents = events.filter(
                (event: IEvent) => event.id !== action.payload.event.id,
            )

            if (!updatedEvents.length) {
                const updatedDates = datesWithEvents.filter(
                    (date: string) =>
                        date !==
                        moment(action.payload.event.startTime).format(
                            'yyyy-MM-DD',
                        ),
                )
                yield put({
                    type: calendarTypes.SET_DATES,
                    payload: { datesWithEvents: updatedDates },
                })
            }

            yield put({
                type: calendarTypes.SET_EVENTS,
                payload: { events: updatedEvents },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchAllEventsSaga(action: FetchAllEventsRequest) {
    try {
        const response: AxiosResponse<IProposedEvent[]> = yield call(
            getAllEvents,
            action.payload,
        )
        if (response.data) {
            const updatedData = response.data.map(ev => ({
                ...ev,
                showMore: false,
            }))
            yield put({
                type: calendarTypes.SET_ALL_EVENTS,
                payload: { allEvents: updatedData },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchUnconfirmedEventsSaga(action: FetchUnconfirmedEventsRequest) {
    try {
        const response: AxiosResponse<number> = yield call(
            getUnconfirmedEvents,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: calendarTypes.SET_UNCONFIRMED_EVENTS,
                payload: { unconfirmedEvents: response.data },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* acceptEventSaga(action: AcceptEventRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            acceptEvent,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { allEvents } = yield select(state => state.calendar)
            const updatedEvents = allEvents.filter(
                (event: IEvent) => event.id !== action.payload.eventId,
            )
            yield put({
                type: calendarTypes.SET_ALL_EVENTS,
                payload: { allEvents: updatedEvents },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* rejectEventSaga(action: RejectEventRequest) {
    try {
        const response: AxiosResponse<IEvent> = yield call(
            rejectEvent,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { allEvents } = yield select(state => state.calendar)
            const updatedEvents = allEvents.filter(
                (event: IEvent) => event.id !== action.payload.eventId,
            )
            yield put({
                type: calendarTypes.SET_ALL_EVENTS,
                payload: { allEvents: updatedEvents },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* calendarSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            calendarTypes.FETCH_DATES_WITH_EVENTS,
            fetchDatesWithEventsSaga,
        ),
    ])
    yield all([
        takeLatest(
            calendarTypes.FETCH_EVENTS_BY_DATE_REQUEST,
            fetchEventsByDateSaga,
        ),
    ])
    yield all([
        takeLatest(calendarTypes.FETCH_EVENT_REQUEST, fetchEventByIdSaga),
    ])
    yield all([takeLatest(calendarTypes.CREATE_EVENT_REQUEST, createEventSaga)])
    yield all([takeLatest(calendarTypes.EDIT_EVENT_REQUEST, editEventSaga)])
    yield all([takeLatest(calendarTypes.DELETE_EVENT_REQUEST, deleteEventSaga)])
    yield all([
        takeLatest(
            calendarTypes.FETCH_UNCONFIRMED_EVENTS,
            fetchUnconfirmedEventsSaga,
        ),
    ])
    yield all([takeLatest(calendarTypes.FETCH_ALL_EVENTS, fetchAllEventsSaga)])
    yield all([
        takeLatest(calendarTypes.ACCEPT_CALENDAR_EVENT, acceptEventSaga),
    ])
    yield all([
        takeLatest(calendarTypes.REJECT_CALENDAR_EVENT, rejectEventSaga),
    ])
}

export default calendarSaga
