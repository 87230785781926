import React, { useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'
import { useCookies } from 'react-cookie'
import moment from 'moment'

import { PATH_NEW_LOADER, PATH_NOTIFICATIONS, PATH_WELCOME } from 'config/paths'

import CloseIcon from 'assets/images/dashboard/header-navigation/CloseIcon'
import BackIcon from 'assets/images/dashboard/header-navigation/BackIcon.svg'
import ProfileIcon from 'assets/images/dashboard/header-navigation/ProfileIcon'
// eslint-disable-next-line max-len
import NotificationIcon from 'assets/images/dashboard/header-navigation/Notification'

// import dashboardRoutes from 'router/routes/dashboard'

import { RootState } from 'redux/reducers/rootReducer'

import {
    SetOpenMenuAction,
    SetHeaderMenuAction,
} from 'layouts/dashboard/store/actions'

import CustomImage from 'components/custom-image/CustomImage'
import CustomButton from 'components/custom-button/CustomButton'
import { ReactComponent as Play } from 'assets/images/icons/Play.svg'
// import MenuHint from './components/menu-hint/MenuHint'

import Notifications from './components/notifications/Notifications'
import Language from './components/language/Language'
import SideMenu from './components/side-menu/SideMenu'
// import MobileSale from './components/mobile-sale/MobileSale'
import Credits from './components/credits/Credits'
import { INotification } from './store/types'
// import FridaySale from './components/friday-sale/FridaySale'

import './HeaderNavigation.scss'
// import NewYearBanner from './components/new-year-banner/NewYearBanner'

const HeaderNavigation = (): React.ReactElement => {
    const { t } = useTranslation(['nav'])

    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const history = useHistory()

    const [showNotifications, setShowNotifications] = useState(false)
    const [showSideMenu, setShowSideMenu] = useState(false)

    const { isOpen, showAd } = useSelector((state: RootState) => state.menus)
    const { notifications } = useSelector(
        (state: RootState) => state.notifications,
    )

    const queryMax = useMediaQuery('(max-width:960px)')
    const query560 = useMediaQuery('(max-width:560px)')

    const setCookie = useCookies()[1]
    const cookies = useCookies([
        'applicationId',
        'isExpired',
        'next_payment_date',
        'isTrialUsed',
        'next_free',
        'saw_hints_mobile',
    ])[0]

    const handleHeaderButtonClick = () => {
        if (queryMax) {
            if (!isOpen) {
                document.body.style.overflowY = 'hidden'
            }
            dispatch(
                SetHeaderMenuAction({
                    isOpen: false,
                }),
            )

            dispatch(
                SetOpenMenuAction({
                    isOpen: !isOpen,
                }),
            )

            return
        }
        window.open(process.env.REACT_APP_PROMO_REDIRECT_URL, '_blank')
    }

    // const dashboardPaths = dashboardRoutes.map(route => route.path)

    const checkForUnread = (element: INotification) => element.isRead === false

    const showUnreadMessages =
        notifications && notifications.some(checkForUnread)

    const timeLeft = Date.parse(cookies.next_payment_date) - Date.now()

    const daysLeft = moment.duration(timeLeft).asDays()

    return (
        <>
            {/* {(cookies.isTrialUsed === 'false' ||
                cookies.isExpired === 'true') &&
            showAd ? (
                <MobileSale />
            ) : null} */}

            {/* {showAd ? <NewYearBanner /> : null} */}
            <div
                className={`navigation ${
                    // (cookies.isTrialUsed === 'false' ||
                    //     cookies.isExpired === 'true') &&
                    showAd ? 'navigation__sale' : ''
                }`}
            >
                {/* sale case */}
                {/* {showAd ? <FridaySale /> : null} */}

                <div className='navigation__wrapper'>
                    {queryMax ? (
                        <>
                            {/* {Math.ceil(daysLeft) >= 0 &&
                            cookies.isTrialUsed === 'false' ? (
                                <p className='navigation__trial'>{`${Math.ceil(
                                    daysLeft,
                                )} ${
                                    Math.ceil(daysLeft) > 1
                                        ? t('nav:days')
                                        : t('nav:day')
                                } ${t('nav:left')}`}</p>
                            ) : null} */}

                            {cookies.next_payment_date &&
                                cookies.isExpired === 'false' &&
                                cookies.isTrialUsed === 'true' && (
                                    <p className='navigation__trial'>{`${t(
                                        'nav:nextPayment',
                                    )} ${moment(
                                        cookies.next_payment_date,
                                    ).format('DD.MM.YYYY')}`}</p>
                                )}
                            {Math.ceil(daysLeft) <= 0 &&
                                cookies.isTrialUsed === 'true' && (
                                    <p className='navigation__trial'>
                                        {t('nav:trialOver')}
                                    </p>
                                )}
                        </>
                    ) : null}
                    <div className='relative'>
                        {queryMax ? (
                            <>
                                <CustomButton
                                    design='secondary'
                                    size='small'
                                    stackClassName='navigation__header-preview'
                                    handleClick={() =>
                                        history.push(PATH_NEW_LOADER.welcome)
                                    }
                                >
                                    <Play />
                                    {t('nav:preview')}
                                </CustomButton>
                            </>
                        ) : (
                            <button
                                type='button'
                                // eslint-disable-next-line max-len
                                className='navigation__back-to-promo header-button'
                                onClick={() => {
                                    handleHeaderButtonClick()
                                    if (queryMax) {
                                        setCookie('saw_hints_mobile', 3, {
                                            path: '/',
                                        })
                                    }
                                }}
                            >
                                <CustomImage src={BackIcon} />
                                <span>{t('nav:toPromo')}</span>
                            </button>
                        )}

                        {/* {(!cookies.saw_hints_mobile ||
                            cookies.saw_hints_mobile === '2') &&
                        dashboardPaths.indexOf(pathname) !== -1 &&
                        queryMax ? (
                            <MenuHint />
                        ) : null} */}
                    </div>

                    {!queryMax ? (
                        <>
                            {/* {Math.ceil(daysLeft) >= 0 &&
                            cookies.isTrialUsed === 'false' ? (
                                <p className='navigation__trial'>{`${Math.ceil(
                                    daysLeft,
                                )} ${
                                    Math.ceil(daysLeft) > 1
                                        ? t('nav:days')
                                        : t('nav:day')
                                } ${t('nav:left')}`}</p>
                            ) : null} */}

                            {cookies.next_payment_date &&
                                !cookies.next_free &&
                                cookies.isExpired === 'false' &&
                                cookies.isTrialUsed === 'true' && (
                                    <p className='navigation__trial'>{`${t(
                                        'nav:nextPayment',
                                    )} ${moment(
                                        cookies.next_payment_date,
                                    ).format('DD.MM.YYYY')}`}</p>
                                )}
                            {Math.ceil(daysLeft) <= 0 &&
                                cookies.isTrialUsed === 'true' && (
                                    <p className='navigation__trial'>
                                        {t('nav:trialOver')}
                                    </p>
                                )}
                        </>
                    ) : null}

                    <div className='navigation__account-info'>
                        <Language design='black' />
                        {queryMax || pathname === PATH_WELCOME.root ? null : (
                            <Credits />
                        )}
                        {pathname !== PATH_WELCOME.root ? (
                            <div className='navigation__icons'>
                                <button
                                    type='button'
                                    // eslint-disable-next-line max-len
                                    className='header-button header-button--yellow'
                                    onClick={() => {
                                        if (query560) {
                                            history.push(
                                                PATH_NOTIFICATIONS.root,
                                            )
                                            return
                                        }
                                        setShowNotifications(true)
                                    }}
                                >
                                    <NotificationIcon
                                        showUnread={showUnreadMessages}
                                    />
                                </button>
                                <button
                                    type='button'
                                    // eslint-disable-next-line max-len
                                    className='header-button header-button--yellow'
                                    onClick={() => {
                                        // Change with clickOutside
                                        dispatch(
                                            SetOpenMenuAction({
                                                isOpen: false,
                                            }),
                                        )
                                        setShowSideMenu(!showSideMenu)
                                    }}
                                >
                                    {showSideMenu ? (
                                        <div className='header-button__close'>
                                            <CloseIcon />
                                        </div>
                                    ) : (
                                        <ProfileIcon />
                                    )}
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {showNotifications ? (
                <Notifications setShowNotifications={setShowNotifications} />
            ) : null}
            {showSideMenu ? (
                <SideMenu setShowSideMenu={setShowSideMenu} />
            ) : null}
        </>
    )
}

export default HeaderNavigation
