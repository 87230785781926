import { mapTypes } from './actionTypes'
import {
    ILocation,
    FetchLocationByIdRequest,
    FetchLocationPayload,
    FetchLocationsPayload,
    FetchLocationsRequest,
    CreateLocationsRequest,
    CreateLocationsPayload,
    EditLocationRequest,
    EditLocationPayload,
    DeleteLocationRequest,
    DeleteLocationPayload,
    SetLocations,
    SetLocation,
} from './types'

export const fetchLocationsAction = (
    payload: FetchLocationsPayload,
): FetchLocationsRequest => ({
    type: mapTypes.FETCH_LOCATIONS_REQUEST,
    payload,
})

export const fetchLocationAction = (
    payload: FetchLocationPayload,
): FetchLocationByIdRequest => ({
    type: mapTypes.FETCH_LOCATION_BY_ID,
    payload,
})

export const createLocationsAction = (
    payload: CreateLocationsPayload,
): CreateLocationsRequest => ({
    type: mapTypes.CREATE_LOCATIONS_REQUEST,
    payload,
})

export const editLocationAction = (
    payload: EditLocationPayload,
): EditLocationRequest => ({
    type: mapTypes.EDIT_LOCATION_REQUEST,
    payload,
})

export const deleteLocationAction = (
    payload: DeleteLocationPayload,
): DeleteLocationRequest => ({
    type: mapTypes.DELETE_LOCATION_REQUEST,
    payload,
})

export const SetLocationsAction = (payload: {
    locations: ILocation[] | undefined
}): SetLocations => ({
    type: mapTypes.SET_LOCATIONS,
    payload,
})

export const SetLocationAction = (payload: {
    location: ILocation | undefined
}): SetLocation => ({
    type: mapTypes.SET_LOCATION,
    payload,
})
