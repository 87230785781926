import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
    select,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { smmTypes } from './actionTypes'
import {
    getSmm,
    editSmm,
    createSmm,
    deleteSmm,
    getSmmById,
} from '../service/smm'

import {
    ISmm,
    FetchSmmByIdRequest,
    EditSmmRequest,
    CreateSmmRequest,
    FetchSmmRequest,
} from './types'

function* fetchSmmSaga(action: FetchSmmRequest) {
    try {
        const response: AxiosResponse<ISmm[]> = yield call(
            getSmm,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: smmTypes.SET_SMM,
                payload: { smmDetails: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchSmmByIdSaga(action: FetchSmmByIdRequest) {
    try {
        const response: AxiosResponse<ISmm> = yield call(
            getSmmById,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: smmTypes.SET_RELATIVE_SMM,
                payload: { relativeSmm: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editSmmSaga(action: EditSmmRequest) {
    try {
        const response: AxiosResponse<ISmm> = yield call(
            editSmm,
            action.payload,
        )
        if (response.status === 200) {
            const { smmDetails } = yield select(state => state.smm)
            const smmIdx = smmDetails.findIndex(
                (smm: ISmm) => smm.id === action.payload.id,
            )
            const smmCopy = [...smmDetails]
            smmCopy[smmIdx] = { ...action.payload.item }

            yield put({
                type: smmTypes.SET_SMM,
                payload: {
                    smmDetails: smmCopy,
                },
            })
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        console.log('edit smm saga err', error)
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createSmmSaga(action: CreateSmmRequest) {
    try {
        const response: AxiosResponse<ISmm[]> = yield call(
            createSmm,
            action.payload,
        )
        if (response.status === 200) {
            const { smmDetails }: { smmDetails: ISmm[] } = yield select(
                state => state.smm,
            )
            if (action.payload.callback) {
                action.payload.callback('success')
            }

            let newItems: ISmm[] = []
            if (smmDetails.length) {
                newItems = response.data.filter(
                    item => item.language === smmDetails[0].language,
                )
            } else {
                newItems = response.data.filter(
                    item => item.language === action.payload.language,
                )
            }

            yield put({
                type: smmTypes.SET_SMM,
                payload: {
                    smmDetails: [...smmDetails].concat(newItems),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteSmmSaga(action: EditSmmRequest) {
    try {
        const response: AxiosResponse<ISmm> = yield call(
            deleteSmm,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { smmDetails } = yield select(state => state.smm)
            const updatedSmm = smmDetails.filter(
                (smm: ISmm) => smm.id !== action.payload.id,
            )
            yield put({
                type: smmTypes.SET_SMM,
                payload: { smmDetails: updatedSmm },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* smmSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([
        takeLatest(smmTypes.FETCH_SMM_REQUEST, fetchSmmSaga),
        takeLatest(smmTypes.EDIT_SMM, editSmmSaga),
        takeLatest(smmTypes.CREATE_SMM, createSmmSaga),
        takeLatest(smmTypes.DELETE_SMM, deleteSmmSaga),
        takeLatest(smmTypes.FETCH_SMM_BY_ID_REQUEST, fetchSmmByIdSaga),
    ])
}

export default smmSaga
