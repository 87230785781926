import React from 'react'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { PATH_BILLING } from 'config/paths'

import CustomButton from 'components/custom-button/CustomButton'
import CustomImage from 'components/custom-image/CustomImage'

import Coin from 'assets/images/Coin.png'

import './Credits.scss'
import { useDispatch } from 'react-redux'
import { SetAiPopupAction } from 'layouts/dashboard/store/actions'

const HeaderButtonRender = (): React.ReactElement => {
    const { t } = useTranslation(['billing'])

    const dispatch = useDispatch()
    const cookies = useCookies(['credits', 'isTrialUsed'])[0]

    const { push } = useHistory()

    const handleClickAi = () => {
        dispatch(
            SetAiPopupAction({
                isOpen: true,
            }),
        )
    }

    const credits =
        cookies.credits && cookies.credits !== 'undefined' ? cookies.credits : 0
    return (
        <div
            className={`credits ${
                cookies.isTrialUsed === 'false' ? 'credits--trial' : ''
            }`}
        >
            <button
                type='button'
                className='credits__ai-target'
                onClick={() => handleClickAi()}
            >
                <CustomImage src={Coin} className='credits__coin' />
                <span className='credits__amount'>{credits}</span>
            </button>
            {cookies.isTrialUsed === 'true' ? (
                <CustomButton
                    design='primary'
                    handleClick={() => push(`${PATH_BILLING.root}`)}
                    size='small'
                >
                    {t('billing:buyCredits')}
                </CustomButton>
            ) : null}
        </div>
    )
}

export default HeaderButtonRender
