import { generalSettingsTypes } from './actionTypes'
import { GeneralSettingsActions, GeneralSettingsState } from './types'

const initialState: GeneralSettingsState = {
    links: undefined,
}

export default (
    state = initialState,
    action: GeneralSettingsActions,
): GeneralSettingsState => {
    switch (action.type) {
        case generalSettingsTypes.SET_GENERAL_SETTINGS:
            return {
                ...state,
                links: action.payload.links,
            }
        default:
            return {
                ...state,
            }
    }
}
