import React, { useState } from 'react'

import { Stack } from '@mui/material'

import { getAppIdCookie } from 'utils/token'
import { useLocalStorageObject } from 'hooks/useLocalStorageObject'
import { useLocation } from 'react-router'
import { PATH_NEW_LOADER } from 'config/paths'
import Task from './components/task/Task'
import { categories } from './data'
import AddNewItem from './components/todo-add/TodoAdd'
import CategoriesTodo from './components/categories-todo/CategoriesTodo'
import SortByPriority from './components/sort-by-priority/SortByPriority'
import SwipePopup from './components/popups/swipe-popup/SwipePopup'

import './TodoPage.scss'

export interface ITasks {
    id: string
    value: string
    isActive: boolean
    priority: number
    category: number
    isArchive: boolean
}

export interface ICategory {
    id: string
    value: number
    label: string
    icon: string
    isIconLeft: boolean
}

export interface ITodo {
    tasks: ITasks[]
    categories: ICategory[]
    showHelp: boolean
}

const TodoPage = (): React.ReactElement => {
    const appId = getAppIdCookie()
    const { pathname } = useLocation()

    const [storageState, setStorageState] = useLocalStorageObject<ITodo>(
        `todo-${appId}`,
        { tasks: [], categories, showHelp: true },
    )

    const [currentCategory, setCurrentCategory] = useState(0)

    const activeStorage = storageState.tasks.filter(i => !i.isArchive)

    const archivedStorage = storageState.tasks.filter(i => i.isArchive)

    const currentStorage = pathname.includes(PATH_NEW_LOADER.pages.todo.archive)
        ? archivedStorage
        : activeStorage

    const filteredArray =
        currentCategory === 0
            ? currentStorage
            : currentStorage.filter(i => i.category === currentCategory)

    const sortedState = filteredArray.sort((a: ITasks, b: ITasks) =>
        a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1,
    )

    return (
        <div className='m-todo'>
            <Stack spacing='16px'>
                <CategoriesTodo
                    categories={storageState.categories}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                />
                <SortByPriority
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    storage={storageState}
                    setStorage={setStorageState}
                />
                {sortedState.map((item: ITasks) => (
                    <Stack key={item.id}>
                        <Task
                            todoItem={item}
                            setStorage={setStorageState}
                            storage={storageState}
                            currentCategory={currentCategory}
                        />
                    </Stack>
                ))}
            </Stack>
            {pathname.includes(PATH_NEW_LOADER.pages.todo.root) && (
                <AddNewItem
                    storage={storageState}
                    setStorage={setStorageState}
                    currentCategory={currentCategory}
                />
            )}
            {storageState.showHelp && storageState.tasks.length > 0 && (
                <SwipePopup
                    onClose={() =>
                        setStorageState({ ...storageState, showHelp: false })
                    }
                />
            )}
        </div>
    )
}

export default TodoPage
