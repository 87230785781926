import React from 'react'

// eslint-disable-next-line max-len
import MasonryColumn from 'containers/emulator/hoc/masonry-column/MasonryColumn'
// eslint-disable-next-line max-len
import { IEReader } from 'containers/dashboard/features/e-reader/store/types'

import Book from '../book/Book'

import './ReaderView.scss'

const ReaderView = ({ books }: { books: IEReader[] }): React.ReactElement => (
    <div className='m-reader'>
        <MasonryColumn>
            {books.map((book, idx) => (!(idx % 2) ? <Book book={book} /> : ''))}
        </MasonryColumn>
        <MasonryColumn>
            {books.map((book, idx) => (idx % 2 ? <Book book={book} /> : ''))}
        </MasonryColumn>
    </div>
)

export default ReaderView
