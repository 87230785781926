export enum cloudTabsType {
    VIDEO = 'video',
    CHAT = 'chat',
    CLOUD = 'cloud',
    SMM = 'smm',
    CONFERENCE = 'conference',
}

export const cloudTabs = [
    {
        name: 'Video',
        type: 'video',
    },
    {
        name: 'Chat',
        type: 'chat',
    },
    {
        name: 'Cloud',
        type: 'cloud',
    },
    {
        name: 'SMM',
        type: 'smm',
    },
    {
        name: 'Conference',
        type: 'conference',
    },
]
