import React from 'react'

import { useCookies } from 'react-cookie'

import PhoneSmall from 'assets/images/new-loader/PhoneSmall'
import PhoneBig from 'assets/images/new-loader/PhoneBig'
import Phone from 'assets/images/new-loader/Phone'

// eslint-disable-next-line max-len
import EmulatorHeader from 'containers/emulator/components/header/EmulatorHeader'

import './NewLoader.scss'

import NewLoaderContent from './components/content/NewLoaderContent'
import NewLoaderBackground from './components/content/NewLoaderBackground'

interface ILoader {
    children: React.ReactNode
}

const NewLoader = ({ children }: ILoader): React.ReactElement => {
    const cookies = useCookies(['emulator_palette'])[0]

    const renderPhone =
        window.innerHeight >= 1100 ? (
            <PhoneBig />
        ) : window.innerHeight >= 800 ? (
            <Phone />
        ) : (
            <PhoneSmall />
        )
    return (
        <div className='new-loader__centered'>
            <div className='new-loader__wrapper'>
                {renderPhone}
                <NewLoaderBackground palette={cookies.emulator_palette}>
                    <NewLoaderContent palette={cookies.emulator_palette}>
                        <EmulatorHeader profileEnabled />
                        <div className='new-loader__content'>{children}</div>
                    </NewLoaderContent>
                </NewLoaderBackground>
            </div>
        </div>
    )
}

export default NewLoader
