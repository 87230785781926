import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './translatePages'

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: localStorage.getItem('userLanguage') || 'en',
    ns: [
        'menu',
        'pickturePicker',
        'tutorial',
        'notifications',
        'testTutorials',
        'settingsTutorials',
        'publishingTutorials',
        'designTutorial',
        'billingTutorial',
        'managingApps',
        'featureTutorials',
        'appNameTutorial',
        'mainComponents',
        'auth',
        'confirmEmail',
        'welcomePage',
        'billing',
        'accountSettings',
        'buttons',
        'appName',
        'generalDesign',
        'welcomeScreen',
        'ownDesign',
        'icon',
        'popups',
        'blog',
        'payments',
        'premium',
        'news',
        'booking',
        'toast',
        'ai',
        'about',
        'employees',
        'calendar',
        'chat',
        'ereader',
        'gallery',
        'map',
        'smm',
        'video',
        'conference',
        'liveChat',
        'todo',
        'generalSettings',
        'featureActiv',
        'userRoles',
        'build',
        'emulator',
        'appList',
        'nav',
        'placing',
        'survey',
    ],
    debug: true,
    resources,
})

export default i18n
