import { servicesTypes } from './actionTypes'
import { ServiceActions, ServiceState } from './types'

const initialState: ServiceState = {
    services: undefined,
    service: undefined,
    relativeService: undefined,
}

export default (state = initialState, action: ServiceActions): ServiceState => {
    switch (action.type) {
        case servicesTypes.SET_SERVICES:
            return {
                ...state,
                services: action.payload.services,
            }
        case servicesTypes.SET_SERVICE:
            return {
                ...state,
                service: action.payload.service,
            }
        case servicesTypes.SET_RELATIVE_SERVICE:
            return {
                ...state,
                relativeService: action.payload.relativeService,
            }
        default:
            return {
                ...state,
            }
    }
}
