import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import parse from 'html-react-parser'
import { RootState } from 'redux/reducers/rootReducer'

import { ReactComponent as Price } from 'assets/images/new-loader/Price.svg'
import { ReactComponent as Clock } from 'assets/images/new-loader/Clock.svg'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import './ServiceItem.scss'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

// eslint-disable-next-line max-len
import { fetchServiceAction } from 'containers/dashboard/features/services/store/actions'

type PageParams = {
    id: string
}

const BlogItem = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['toast'])
    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { id } = useParams<PageParams>()
    const { service } = useSelector((state: RootState) => state.services)

    useEffect(() => {
        console.log(i18n.language, 'i18n.language')
        console.log(id, 'id')

        if (!appId) return
        if (!id) return
        dispatch(
            fetchServiceAction({
                serviceId: id,
                appId,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    const displayCurrency = (currency: string) => {
        switch (currency) {
            case 'UAH':
                return <>&#x20b4;</>
            case 'USD':
                return <>&#x24;</>
            case 'EUR':
                return <>&#8364;</>
            case 'IRP':
                return <>&#8377;</>
            default:
                return ''
        }
    }

    return (
        <>
            {service ? (
                <div className='m-service-item'>
                    <CustomImage src={service.imageUrl} />
                    <div className='m-service-item__info new-loader--surface'>
                        <div className='m-service-item__title new-loader--text'>
                            {service.name}
                        </div>
                        <div className='m-service-item__values'>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-service-item__tag new-loader--primary-color'>
                                <Price />
                                {displayCurrency(service.currency)}
                                {service.price}
                                {/* eslint-disable-next-line max-len */}
                                <div className='m-service-item__background new-loader--primary-background' />
                            </div>
                            {service.duration ? (
                                // eslint-disable-next-line max-len
                                <div className='m-service-item__tag new-loader--primary-color'>
                                    <Clock />
                                    {`${service.hours}:${service.minutes}`}
                                    {/* eslint-disable-next-line max-len */}
                                    <div className='m-service-item__background new-loader--primary-background' />
                                </div>
                            ) : null}
                        </div>
                        {/* eslint-disable-next-line max-len */}
                        <div className='m-service-item__border new-loader--text' />
                        <div className='m-service-item__desc new-loader--text'>
                            {parse(service.description)}
                        </div>
                    </div>
                </div>
            ) : (
                <Preloader />
            )}
        </>
    )
}

export default BlogItem
