import React from 'react'

const FromArchiveIcon = ({ fill }: { fill?: string }): React.ReactElement => (
    <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.9583 12.8333C11.4751 12.8333 11.0833 13.2251 11.0833 13.7083C11.0833 14.1916 11.4751 14.5833 11.9583 14.5833H15.5708C15.9845 14.2393 16.4353 13.9385 16.9164 13.6877C16.9055 13.214 16.518 12.8333 16.0417 12.8333H11.9583ZM9.04167 22.75H13.1991C13.4001 23.3724 13.6795 23.9596 14.0254 24.5H9.04167C6.62542 24.5 4.66667 22.5413 4.66667 20.125V10.058C3.96318 9.58706 3.5 8.78512 3.5 7.875V6.125C3.5 4.67525 4.67525 3.5 6.125 3.5H21.875C23.3247 3.5 24.5 4.67525 24.5 6.125V7.875C24.5 8.78512 24.0368 9.58706 23.3333 10.058V13.4146C22.7801 13.1838 22.1937 13.0167 21.5833 12.9225V10.5H6.41667V20.125C6.41667 21.5747 7.59192 22.75 9.04167 22.75ZM6.125 5.25C5.64176 5.25 5.25 5.64176 5.25 6.125V7.875C5.25 8.35825 5.64176 8.75 6.125 8.75H21.875C22.3582 8.75 22.75 8.35825 22.75 7.875V6.125C22.75 5.64176 22.3582 5.25 21.875 5.25H6.125ZM26.8333 20.4167C26.8333 23.9605 23.9605 26.8333 20.4167 26.8333C16.8728 26.8333 14 23.9605 14 20.4167C14 16.8728 16.8728 14 20.4167 14C23.9605 14 26.8333 16.8728 26.8333 20.4167ZM19.0792 17.9125C19.3069 17.6847 19.3069 17.3153 19.0792 17.0875C18.8514 16.8597 18.482 16.8597 18.2541 17.0875L16.5041 18.8375C16.2764 19.0653 16.2764 19.4347 16.5041 19.6625L18.2541 21.4125C18.482 21.6403 18.8514 21.6403 19.0792 21.4125C19.3069 21.1847 19.3069 20.8153 19.0792 20.5875L18.325 19.8333H20.7083C22.158 19.8333 23.3333 21.0086 23.3333 22.4583V22.75C23.3333 23.0721 23.5946 23.3333 23.9167 23.3333C24.2388 23.3333 24.5 23.0721 24.5 22.75V22.4583C24.5 20.3643 22.8024 18.6667 20.7083 18.6667H18.325L19.0792 17.9125Z'
            fill={fill || 'white'}
        />
    </svg>
)

export default FromArchiveIcon
