import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
// import { useCookies } from 'react-cookie'

import { ReactComponent as CloseIcon } from 'assets/images/icons/Close.svg'

import CustomButton from 'components/custom-button/CustomButton'
import Preloader from 'components/preloader/Preloader'
import { RootState } from 'redux/reducers/rootReducer'

import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

import Notification from '../notification/Notification'
import EmptyNotification from '../empty-notification/EmptyNotification'

import './Notifications.scss'

import { INotification } from '../../store/types'
import { readAllNoteAction } from '../../store/action'

const Notifications = ({
    setShowNotifications,
}: {
    setShowNotifications: (show: boolean) => void
}): React.ReactElement => {
    const { t, i18n } = useTranslation(['notifications'])
    // const cookies = useCookies(['isExpired', 'isTrialUsed'])[0]

    const ref = useRef(null)

    useEffect(() => {
        document.body.style.overflowY = 'hidden'

        return () => {
            document.body.style.overflowY = 'scroll'
        }
    }, [])

    const dispatch = useDispatch()

    useClickedOutsideHandler({ ref, func: () => setShowNotifications(false) })

    const { notifications } = useSelector(
        (state: RootState) => state.notifications,
    )

    const { showAd } = useSelector((state: RootState) => state.menus)

    return (
        <div
            className={`notifications__wrapper ${
                // (cookies.isTrialUsed === 'false' ||
                //     cookies.isExpired === 'true') &&
                showAd ? 'notifications__wrapper--sale' : ''
            }`}
            ref={ref}
        >
            {/* Use same overlay as for menu */}
            <div className='notifications__overlay' />
            <div className='notifications'>
                <div className='notifications__header'>
                    <div>
                        <p className='notifications__header__title'>
                            {t('notifications:notificationTitle')}
                        </p>
                        <CustomButton
                            size='small'
                            type='button'
                            design='cancel'
                            handleClick={() => {
                                dispatch(
                                    readAllNoteAction({ lang: i18n.language }),
                                )
                            }}
                        >
                            {t('notifications:readAll')}
                        </CustomButton>
                    </div>
                    <button
                        type='button'
                        onClick={() => setShowNotifications(false)}
                        className='notifications__close'
                    >
                        <CloseIcon />
                    </button>
                </div>
                <div className='notifications__messages'>
                    {notifications ? (
                        <>
                            {notifications.length > 0 ? (
                                <>
                                    {notifications.map((not: INotification) => (
                                        <Notification notification={not} />
                                    ))}
                                </>
                            ) : (
                                <EmptyNotification />
                            )}
                        </>
                    ) : (
                        <Preloader />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Notifications
