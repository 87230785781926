/* eslint-disable max-len */
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { TError } from 'config/types'
import { PATH_BUILD } from 'config/paths'

// eslint-disable-next-line max-len
import { ReactComponent as DownloadIcon } from 'assets/images/icons/Download.svg'
import { ReactComponent as Delete } from 'assets/images/icons/Delete.svg'
import BuildFailed from 'assets/images/notifications/BuildFailed.svg'
import BuildSuccess from 'assets/images/notifications/BuildSuccess.svg'
import SubEnd from 'assets/images/notifications/SubEnd.svg'

import CustomImage from 'components/custom-image/CustomImage'
import CustomButton from 'components/custom-button/CustomButton'

import './Notification.scss'

import { readNoteAction, deleteNoteAction } from '../../store/action'
import { INotification } from '../../store/types'

const Notification = ({
    notification,
}: {
    notification: INotification
}): React.ReactElement => {
    const { t, i18n } = useTranslation(['notifications', 'toast'])

    const [fullView, setFullView] = useState(false)
    const setCookie = useCookies()[1]

    const { push } = useHistory()
    const dispatch = useDispatch()

    const readNotification = (noteId: string) => {
        // read first then dispatch
        dispatch(
            readNoteAction({
                noteId,
                lang: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }

    const deleteNotification = () => {
        dispatch(
            deleteNoteAction({
                noteId: notification.id,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }

    const downloadBuild = (name: string, linkUrl?: string) => {
        if (!linkUrl) return
        const link = document.createElement('a')
        link.href = linkUrl
        link.setAttribute('download', name)

        document.body.appendChild(link)

        link.click()

        link?.parentNode?.removeChild(link)
    }

    const goToPage = (type: string) => {
        const applications = localStorage.getItem('applications')
        if (!applications) return
        const app = JSON.parse(applications).find(
            (application: INotification) =>
                application.id === notification.applicationId,
        )
        setCookie('emulator_palette', app.colorTemplateModel, {
            path: '/',
        })
        setCookie('active_features', app.features.activeFeatures, {
            path: '/',
        })
        setCookie('app_name', app.name, {
            path: '/',
        })
        setCookie('applicationId', app.id, {
            path: '/',
        })
        if (type === 'testing') {
            push(`${PATH_BUILD.android}?buildtype=apk`)
        }
        if (type === 'publishing') {
            push(`${PATH_BUILD.android}?buildtype=aab`)
        }
        if (type === 'apple') {
            push(`${PATH_BUILD.ios}`)
        }
    }

    const displayNotification = () => {
        if (notification.type === 'Build') {
            if (notification.buildPlatform === 'AndroidApk') {
                return (
                    <>
                        <div className='notification__image'>
                            <CustomImage
                                src={
                                    notification.buildStatus === 'Success'
                                        ? BuildSuccess
                                        : BuildFailed
                                }
                            />
                        </div>

                        <div>
                            <p className='notification__header'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:buildReady')
                                    : t('notifications:buildFailed')}
                            </p>
                            <p className='notification__description'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:downloadApk')
                                    : t('notifications:againApk')}
                                <button
                                    type='button'
                                    className='notification__more'
                                    onClick={() => {
                                        if (notification.isRead === false) {
                                            readNotification(notification.id)
                                        }
                                        setFullView(!fullView)
                                    }}
                                >
                                    {fullView
                                        ? t('notifications:hide')
                                        : t('notifications:showMore')}
                                </button>
                            </p>
                            {fullView ? (
                                <div className='notification__actions'>
                                    <CustomButton
                                        design='primary'
                                        handleClick={() => goToPage('testing')}
                                        size='small'
                                    >
                                        {t('notifications:goToBuild')}
                                    </CustomButton>
                                    {notification.url ? (
                                        <CustomButton
                                            design='secondary'
                                            handleClick={() =>
                                                downloadBuild(
                                                    'Build.apk',
                                                    notification.url,
                                                )
                                            }
                                            size='small'
                                        >
                                            <DownloadIcon />
                                            {t('notifications:download')}
                                        </CustomButton>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </>
                )
            }
            if (notification.buildPlatform === 'AndroidAab') {
                return (
                    <>
                        <div className='notification__image'>
                            <CustomImage
                                src={
                                    notification.buildStatus === 'Success'
                                        ? BuildSuccess
                                        : BuildFailed
                                }
                            />
                        </div>
                        <div>
                            <p className='notification__header'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:buildReady')
                                    : t('notifications:buildFailed')}
                            </p>
                            <p className='notification__description'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:downloadAab')
                                    : t('notifications:againAab')}
                                <button
                                    type='button'
                                    className='notification__more'
                                    onClick={() => {
                                        if (notification.isRead === false) {
                                            readNotification(notification.id)
                                        }
                                        setFullView(!fullView)
                                    }}
                                >
                                    {fullView
                                        ? t('notifications:hide')
                                        : t('notifications:showMore')}
                                </button>
                            </p>
                            {fullView ? (
                                <div className='notification__actions'>
                                    <CustomButton
                                        design='primary'
                                        handleClick={() =>
                                            goToPage('publishing')
                                        }
                                        size='small'
                                    >
                                        {t('notifications:goToBuild')}
                                    </CustomButton>
                                    {notification.url ? (
                                        <CustomButton
                                            design='secondary'
                                            handleClick={() =>
                                                downloadBuild(
                                                    'Build.aab',
                                                    notification.url,
                                                )
                                            }
                                            size='small'
                                        >
                                            <DownloadIcon />
                                            {t('notifications:download')}
                                        </CustomButton>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </>
                )
            }
            if (notification.buildPlatform === 'Apple') {
                return (
                    <>
                        <div className='notification__image'>
                            <CustomImage
                                src={
                                    notification.buildStatus === 'Success'
                                        ? BuildSuccess
                                        : BuildFailed
                                }
                            />
                        </div>
                        <div>
                            <p className='notification__header'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:buildReady')
                                    : t('notifications:buildFailed')}
                            </p>
                            <p className='notification__description'>
                                {notification.buildStatus === 'Success'
                                    ? t('notifications:downloadIos')
                                    : t('notifications:againIos')}
                                <button
                                    type='button'
                                    className='notification__more'
                                    onClick={() => {
                                        if (notification.isRead === false) {
                                            readNotification(notification.id)
                                        }
                                        setFullView(!fullView)
                                    }}
                                >
                                    {fullView
                                        ? t('notifications:hide')
                                        : t('notifications:showMore')}
                                </button>
                            </p>
                            {fullView ? (
                                <div className='notification__actions'>
                                    <CustomButton
                                        design='primary'
                                        handleClick={() => goToPage('apple')}
                                        size='small'
                                    >
                                        {t('notifications:goToBuild')}
                                    </CustomButton>
                                    {notification.url ? (
                                        <CustomButton
                                            design='secondary'
                                            handleClick={() =>
                                                downloadBuild(
                                                    'archive',
                                                    notification.url,
                                                )
                                            }
                                            size='small'
                                        >
                                            <DownloadIcon />
                                            {t('notifications:download')}
                                        </CustomButton>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                    </>
                )
            }
        }
        if (notification.type === 'sub_end') {
            return (
                <>
                    <CustomImage src={SubEnd} />
                </>
            )
        }
        return <></>
    }
    return (
        <div
            className={`notification ${
                notification.isRead
                    ? 'notification--read'
                    : 'notification--unread'
            }`}
        >
            {!notification.isRead ? (
                <div className='notification__dot' />
            ) : null}
            <button
                className='notification__delete'
                type='button'
                onClick={() => deleteNotification()}
            >
                <Delete />
            </button>

            {displayNotification()}
        </div>
    )
}

export default Notification
