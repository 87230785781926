import React, { ChangeEventHandler } from 'react'

import './LoaderTextArea.scss'

interface ILoaderTextArea {
    onChange?: ChangeEventHandler<HTMLTextAreaElement>
    value?: string
    name: string
    placeholder?: string
    className?: string
    error?: string
    touched?: boolean
}

const LoaderTextArea = ({
    onChange,
    value,
    name,
    placeholder,
    className,
    error,
    touched,
}: ILoaderTextArea): React.ReactElement => (
    <div>
        <textarea
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            // eslint-disable-next-line max-len
            className={`new-loader_textarea new-loader--text new-loader--surface ${className}`}
        />
        {touched && error && (
            <div className='new-loader_textarea__error-message'>
                {touched && error}
            </div>
        )}
    </div>
)

LoaderTextArea.defaultProps = {
    value: '',
}

export default LoaderTextArea
