import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { PATH_APPLICATIONS } from 'config/paths'
import { IMainItem, IItem } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import { SetOpenMenuAction } from 'layouts/dashboard/store/actions'

import { IconRender } from '../navigation/IconRender'

import './MenuItem.scss'

interface IMenuItem {
    menuItem: IMainItem | IItem
}

const MenuItem = ({ menuItem }: IMenuItem): React.ReactElement => {
    const { t } = useTranslation(['menu', 'toast'])

    const dispatch = useDispatch()
    const history = useHistory()
    const { pathname } = useLocation()
    const appId = getAppIdCookie()

    const pushOnPage = (url: string) => {
        history.push(url)
    }

    const chooseCategory = () => {
        const { path } = menuItem
        // eslint-disable-next-line dot-notation
        if (!menuItem['includes'] || menuItem['includes'].length === 0) {
            dispatch(
                SetOpenMenuAction({
                    isOpen: false,
                }),
            )
        }
        pushOnPage(path)
    }

    return (
        <li
            className={`menu-item__wrapper ${
                menuItem.icon === 'loader' && 'preview-item'
            }`}
            key={menuItem.key}
        >
            {menuItem.comingSoon ? (
                <div className='menu-item__soon'>
                    <p className='menu-item__soon-text'>
                        {t('menu:comingSoon')}
                    </p>
                </div>
            ) : null}
            <div className='menu-item'>
                <button
                    type='button'
                    className={`menu-item__button ${
                        pathname.includes(menuItem.icon)
                            ? 'menu-item__button--active'
                            : ''
                    }`}
                    onClick={() => {
                        if (appId) {
                            chooseCategory()
                        } else {
                            history.push(PATH_APPLICATIONS.root)
                            toast.error(t('toast:chooseAppError'))
                        }
                    }}
                >
                    <div
                        className={`${
                            pathname.includes(menuItem.icon)
                                ? 'menu-item__icon--active'
                                : 'menu-item__icon'
                        }`}
                    >
                        <IconRender
                            iconName={menuItem.icon}
                            isActive={pathname.includes(menuItem.icon)}
                        />
                    </div>
                    <div className='menu-item__name'>
                        {t(`menu:${menuItem.name}`)}
                    </div>
                </button>
            </div>
        </li>
    )
}

export default MenuItem
