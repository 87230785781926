import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { CLOUD_URLS } from 'config/urls'

import { ICloud, ICloudMultilang } from '../store/types'

export const getCloud = ({
    language,
    appId,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ICloud[]>> =>
    axiosInstance.get(`${appId}${CLOUD_URLS.main}/language/${language}`)

export const getCloudById = ({
    appId,
    cloudId,
}: {
    appId: string
    cloudId: string
}) => axiosInstance.get(`${appId}${CLOUD_URLS.main}/${cloudId}`)

export const editCloud = ({
    item,
    appId,
    id,
}: {
    item: ICloud
    appId: string
    id: string
}): Promise<AxiosResponse<ICloud>> =>
    axiosInstance.put(`${appId}${CLOUD_URLS.main}/${id}`, item)

export const createCloud = ({
    item,
    appId,
}: {
    item: ICloudMultilang
    appId: string
}): Promise<AxiosResponse<ICloud>> =>
    axiosInstance.post(`${appId}${CLOUD_URLS.main}`, item)

export const deleteCloud = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<ICloud>> =>
    axiosInstance.delete(`${appId}${CLOUD_URLS.main}/${id}`)
