/* eslint-disable max-len */
import React, { useEffect } from 'react'

import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import './WelcomePage.scss'

import { useDispatch, useSelector } from 'react-redux'
import { fetchLaunchScreenAction } from 'containers/dashboard/design/welcome-screen/store/actions'
import { getAppIdCookie } from 'utils/token'
import { TError } from 'config/types'
import { RootState } from 'redux/reducers/rootReducer'
import CustomImage from 'components/custom-image/CustomImage'

import { data } from '../../components/burger-menu/data'

const WelcomePage = () => {
    const cookies = useCookies(['active_features'])[0]
    const { push } = useHistory()
    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { t, i18n } = useTranslation(['emulator'])

    const { settings } = useSelector((state: RootState) => state.launchScreen)

    const goToFirstPage = () => {
        // To go on btn click
        const firstFeature = data.find(
            feature =>
                feature.type.toLowerCase() ===
                cookies.active_features[0]?.type.toLowerCase(),
        )

        console.log(
            cookies.active_features[0]?.type.toLowerCase(),
            'cookies.active_features[0]',
        )

        if (!firstFeature) {
            toast.error(t('emulator:notActiveFeature'))
            return
        }
        push(firstFeature.path)
    }

    useEffect(() => {
        if (!appId) return
        dispatch(
            fetchLaunchScreenAction({
                appId,
                lang: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
    }, [appId, dispatch, t, i18n])

    console.log(settings, 'settings')

    return (
        <Stack className='mobile-welcome' justifyContent='space-between'>
            <Stack>
                {settings.imageUrl ? (
                    <CustomImage
                        className='mobile-welcome__logo-img'
                        alt={settings.title}
                        src={settings.imageUrl}
                    />
                ) : (
                    <p className='new-loader--text mobile-welcome__logo' />
                )}
                <p className='new-loader--text mobile-welcome__heading'>
                    {settings.title || t('emulator:welcome:heading')}
                </p>
                <p className='new-loader--text mobile-welcome__subheading'>
                    {settings.slogan || t('emulator:welcome:subheading')}
                </p>
            </Stack>

            <button
                type='button'
                onClick={() => goToFirstPage()}
                className='new-loader--primary-background new-loader--contrast-color'
            >
                {t('emulator:start')}
            </button>
        </Stack>
    )
}

export default WelcomePage as React.FC
