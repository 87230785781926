import { lazy } from 'react'

import { PATH_BUILD } from 'config/paths'

export const BuildRoutes = [
    {
        id: 1,
        module: 'build',
        protected: true,
        exact: true,
        path: PATH_BUILD.android,
        component: lazy(
            () => import('containers/dashboard/testing/android/Android'),
        ),
    },
    {
        id: 2,
        module: 'build',
        protected: true,
        exact: true,
        path: PATH_BUILD.ios,
        component: lazy(() => import('containers/dashboard/testing/ios/IOS')),
    },
]
