import { bookingTypes } from './actionTypes'
import {
    IBooking,
    IManagement,
    FetchBookingsRequest,
    FetchBookingsPayload,
    FetchManagementRequest,
    FetchManagementPayload,
    FetchBookingByIdRequest,
    FetchBookingPayload,
    CreateBookingRequest,
    CreateBookingPayload,
    EditBookingRequest,
    EditBookingPayload,
    DeleteBookingRequest,
    DeleteBookingPayload,
    SetBookings,
    SetManagement,
    SetBooking,
    TManagementShcedule,
    SetSchedule,
    GetBillingPayload,
    GetBillingRequest,
    SetBillingRoute,
    GetLocationsPayload,
    FetchLocationRequest,
    FetchLocationsRequest,
    GetLocationPayload,
    CreateLocationPayload,
    UpdateLocationRequest,
    CreateLocationRequest,
    ILocation,
    SetLocations,
    UpdateLocationPayload,
    SetLocation,
} from './types'

export const fetchBookingsAction = (
    payload: FetchBookingsPayload,
): FetchBookingsRequest => ({
    type: bookingTypes.FETCH_BOOKING_REQUEST,
    payload,
})

export const fetchManagementAction = (
    payload: FetchManagementPayload,
): FetchManagementRequest => ({
    type: bookingTypes.FETCH_MANAGEMENT_REQUEST,
    payload,
})

export const fetchBookingAction = (
    payload: FetchBookingPayload,
): FetchBookingByIdRequest => ({
    type: bookingTypes.FETCH_BOOKING_BY_ID,
    payload,
})

export const createBookingAction = (
    payload: CreateBookingPayload,
): CreateBookingRequest => ({
    type: bookingTypes.CREATE_BOOKING_REQUEST,
    payload,
})

export const editBookingAction = (
    payload: EditBookingPayload,
): EditBookingRequest => ({
    type: bookingTypes.EDIT_BOOKING_REQUEST,
    payload,
})

export const deleteBookingAction = (
    payload: DeleteBookingPayload,
): DeleteBookingRequest => ({
    type: bookingTypes.DELETE_BOOKING_REQUEST,
    payload,
})

export const SetBookingsAction = (payload: {
    bookings: IBooking[] | undefined
}): SetBookings => ({
    type: bookingTypes.SET_BOOKINGS,
    payload,
})

export const SetManagementAction = (payload: {
    managementSlots: IManagement | undefined
}): SetManagement => ({
    type: bookingTypes.SET_MANAGEMENT,
    payload,
})

export const SetBookingAction = (payload: {
    booking: IBooking
}): SetBooking => ({
    type: bookingTypes.SET_BOOKING,
    payload,
})

export const SetScheduleAction = (payload: {
    selectedSchedule: TManagementShcedule
}): SetSchedule => ({
    type: bookingTypes.SET_SCHEDULE,
    payload,
})

export const GetBillingAction = (
    payload: GetBillingPayload,
): GetBillingRequest => ({
    type: bookingTypes.GET_BILLING_REQUEST,
    payload,
})

export const SetBillingAction = (payload: {
    billingUrl?: string
}): SetBillingRoute => ({
    type: bookingTypes.SET_BILLING_REQUEST,
    payload,
})

export const GetLocationsAction = (
    payload: GetLocationsPayload,
): FetchLocationsRequest => ({
    type: bookingTypes.GET_LOCATIONS_REQUEST,
    payload,
})

export const GetLocationAction = (
    payload: GetLocationPayload,
): FetchLocationRequest => ({
    type: bookingTypes.GET_LOCATION_REQUEST,
    payload,
})

export const EditLocationAction = (
    payload: UpdateLocationPayload,
): UpdateLocationRequest => ({
    type: bookingTypes.UPDATE_LOCATION_REQUEST,
    payload,
})

export const CreateLocationAction = (
    payload: CreateLocationPayload,
): CreateLocationRequest => ({
    type: bookingTypes.CREATE_LOCATION_REQUEST,
    payload,
})

export const SetLocationsAction = (payload: {
    locations: ILocation[]
}): SetLocations => ({
    type: bookingTypes.SET_LOCATIONS,
    payload,
})

export const SetLocationAction = (payload: {
    location?: ILocation
}): SetLocation => ({
    type: bookingTypes.SET_LOCATION,
    payload,
})
