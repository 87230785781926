import { billingTypes } from './actionTypes'
import { BillingActions, BillingState } from './types'

const initialState: BillingState = {
    customerDetails: undefined,
    subscriptions: undefined,
    invoices: undefined,
    credits: undefined,
    placingTypes: [],
}

export default (state = initialState, action: BillingActions): BillingState => {
    switch (action.type) {
        case billingTypes.SET_CUSTOMER:
            return {
                ...state,
                customerDetails: action.payload.customerDetails,
            }
        case billingTypes.SET_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.payload.subscriptions,
            }
        case billingTypes.SET_POINT_PACKS:
            return {
                ...state,
                credits: action.payload.credits,
            }
        case billingTypes.SET_INVOICES:
            return {
                ...state,
                invoices: action.payload.invoices,
            }
        case billingTypes.SET_PLACING_TYPES:
            return {
                ...state,
                placingTypes: action.payload.placingTypes,
            }
        default:
            return {
                ...state,
            }
    }
}
