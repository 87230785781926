import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    // select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { paletteTypes } from './actionTypes'
import {
    getTemplates,
    getChosenPalette,
    editChosenPalette,
} from '../service/palette'

import {
    IPalette,
    FetchTemplatesRequest,
    FetchChosenPaletteRequest,
    EditPaletteRequest,
} from './types'

function* fetchTemplatesSaga(action: FetchTemplatesRequest) {
    try {
        const response: AxiosResponse<IPalette[]> = yield call(getTemplates)
        if (response.data) {
            yield put({
                type: paletteTypes.SET_TEMPLATES,
                payload: { templates: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchChosenPaletteSaga(action: FetchChosenPaletteRequest) {
    try {
        const response: AxiosResponse<IPalette> = yield call(
            getChosenPalette,
            action.payload,
        )
        console.log('response', response)
        if (response.status === 204) {
            yield put({
                type: paletteTypes.SET_PALETTE,
                payload: {
                    palette: {
                        background: '',
                    },
                },
            })
        }
        if (response.status === 200) {
            yield put({
                type: paletteTypes.SET_PALETTE,
                payload: { palette: response.data },
            })
        }
        if (action.payload.callback) {
            action.payload.callback('success', undefined, response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editPaletteSaga(action: EditPaletteRequest) {
    try {
        const response: AxiosResponse<IPalette> = yield call(
            editChosenPalette,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback(
                    'success',
                    undefined,
                    action.payload.palette,
                )
            }

            yield put({
                type: paletteTypes.SET_PALETTE,
                payload: { palette: action.payload.palette },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* paletteSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(paletteTypes.FETCH_TEMPLATES_REQUEST, fetchTemplatesSaga),
    ])
    yield all([
        takeLatest(paletteTypes.FETCH_PALETTE_REQUEST, fetchChosenPaletteSaga),
    ])
    yield all([
        takeLatest(paletteTypes.UPDATE_PALETTE_REQUEST, editPaletteSaga),
    ])
}

export default paletteSaga
