import React from 'react'

import ConferenceItem from '../conference-item/ConferenceItem'

import './ConferenceView.scss'

type TData = {
    type: string
    description: string
    url: string
}

interface IData {
    data: TData[]
}

const ConferenceView = ({ data }: IData): React.ReactElement => (
    <div className='m-conference'>
        {data.map(item => (
            <ConferenceItem item={item} key={item.url} />
        ))}
    </div>
)

export default ConferenceView
