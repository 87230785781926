import React from 'react'

export const CalendarIcon = ({
    isSmall,
    isMedium,
}: {
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
    >
        <path
            d='M18.8741 21.5638C19.4061 21.5638 19.8375 21.1206 19.8375 20.5739C19.8375 19.7752 20.18 19.0501 20.7336 18.524C21.3068 17.9793 22.1078 17.6422 23.0003 17.6422C23.8927 17.6422 24.6937 17.9793 25.2669 18.524C25.8206 19.0501 26.163 19.7752 26.163 20.5739C26.163 21.3091 25.8717 21.982 25.3923 22.4944C24.8889 23.0325 24.1812 23.3986 23.3805 23.4869C23.3444 23.4908 23.1394 23.4951 22.8841 23.4985C22.5456 23.5032 22.1416 23.5064 21.8695 23.5082L21.5729 23.5057V23.5171C21.0491 23.5423 20.6323 23.974 20.6323 24.5028C20.6323 25.0479 21.0751 25.4898 21.6212 25.4898C21.652 25.4898 21.6825 25.4883 21.7125 25.4856C22.0041 25.4863 22.6023 25.4886 22.8801 25.4924C23.1373 25.496 23.3444 25.5001 23.3805 25.5041C24.1812 25.5925 24.8889 25.9586 25.3923 26.4966C25.8717 27.009 26.163 27.682 26.163 28.4171C26.163 29.2158 25.8206 29.9409 25.2669 30.4671C24.6937 31.0118 23.8927 31.3488 23.0003 31.3488C22.1078 31.3488 21.3068 31.0118 20.7336 30.4671C20.18 29.9409 19.8375 29.2158 19.8375 28.4171C19.8375 27.8705 19.4061 27.4272 18.8741 27.4272C18.3421 27.4272 17.9107 27.8705 17.9107 28.4171C17.9107 29.7842 18.492 31.0201 19.4317 31.9132C20.3516 32.7876 21.6141 33.3286 23.0003 33.3286C24.3865 33.3286 25.6489 32.7876 26.5689 31.9132C27.5086 31.0201 28.0898 29.7842 28.0898 28.4171C28.0898 27.1527 27.5936 26.0004 26.7771 25.1278C26.5601 24.8959 26.3203 24.6841 26.0611 24.4955C26.3203 24.3071 26.5601 24.0952 26.7771 23.8633C27.5936 22.9906 28.0898 21.8383 28.0898 20.5739C28.0898 19.2068 27.5086 17.9708 26.5689 17.0778C25.6489 16.2034 24.3865 15.6624 23.0003 15.6624C21.6141 15.6624 20.3516 16.2034 19.4317 17.0778C18.492 17.9708 17.9107 19.2068 17.9107 20.5739C17.9107 21.1206 18.3421 21.5638 18.8741 21.5638Z'
            fill='currentColor'
        />
        <path
            d='M8.52381 39.3333C8.52381 38.8179 8.92889 38.4 9.42857 38.4H36.5714C37.0711 38.4 37.4762 38.8179 37.4762 39.3333C37.4762 39.8488 37.0711 40.2667 36.5714 40.2667H9.42857C8.92889 40.2667 8.52381 39.8488 8.52381 39.3333Z'
            fill='currentColor'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M42 38.4V9.46667C42 6.37387 39.5695 3.86667 36.5714 3.86667H34.7619V2.93333C34.7619 2.41787 34.3568 2 33.8571 2C33.3575 2 32.9524 2.41787 32.9524 2.93333V3.86667H29.3333V2.93333C29.3333 2.41787 28.9283 2 28.4286 2C27.9289 2 27.5238 2.41787 27.5238 2.93333V3.86667H23.9048V2.93333C23.9048 2.41787 23.4997 2 23 2C22.5003 2 22.0952 2.41787 22.0952 2.93333V3.86667H18.4762V2.93333C18.4762 2.41787 18.0711 2 17.5714 2C17.0717 2 16.6667 2.41787 16.6667 2.93333V3.86667V4.8V5.73333V6.66667C16.6667 7.18213 17.0717 7.6 17.5714 7.6C18.0711 7.6 18.4762 7.18213 18.4762 6.66667V5.73333H22.0952V6.66667C22.0952 7.18213 22.5003 7.6 23 7.6C23.4997 7.6 23.9048 7.18213 23.9048 6.66667V5.73333H27.5238V6.66667C27.5238 7.18213 27.9289 7.6 28.4286 7.6C28.9283 7.6 29.3333 7.18213 29.3333 6.66667V5.73333H32.9524V6.66667C32.9524 7.18213 33.3575 7.6 33.8571 7.6C34.3568 7.6 34.7619 7.18213 34.7619 6.66667V5.73333H36.5714C38.5702 5.73333 40.1905 7.4048 40.1905 9.46667H5.80952C5.80952 7.4048 7.42983 5.73333 9.42857 5.73333H11.2381V6.66667C11.2381 7.18213 11.6432 7.6 12.1429 7.6C12.6425 7.6 13.0476 7.18213 13.0476 6.66667V5.73333H16.6667V4.8V3.86667H13.0476V2.93333C13.0476 2.41787 12.6425 2 12.1429 2C11.6432 2 11.2381 2.41787 11.2381 2.93333V3.86667H9.42857C6.43045 3.86667 4 6.37387 4 9.46667V38.4C4 41.4928 6.43045 44 9.42857 44H36.5714C39.5695 44 42 41.4928 42 38.4ZM9.42857 42.1333H36.5714C38.5702 42.1333 40.1905 40.4619 40.1905 38.4V11.3333H5.80952V38.4C5.80952 40.4619 7.42983 42.1333 9.42857 42.1333Z'
            fill='currentColor'
        />
    </svg>
)

export default CalendarIcon
