import { testTypes } from './actionTypes'
import { TestActions, TestState } from './types'

const initialState: TestState = {
    tests: undefined,
    test: undefined,
}

export default (state = initialState, action: TestActions): TestState => {
    console.log('action', action)
    switch (action.type) {
        case testTypes.SET_TESTS:
            return {
                ...state,
                tests: action.payload.tests,
            }
        case testTypes.SET_TEST:
            return {
                ...state,
                test: action.payload.test,
            }
        default:
            return {
                ...state,
            }
    }
}
