import { blogTypes } from './actionTypes'
import { BlogActions, BlogState } from './types'

const initialState: BlogState = {
    blogs: undefined,
    blog: undefined,
    relativeBlog: undefined,
}

export default (state = initialState, action: BlogActions): BlogState => {
    switch (action.type) {
        case blogTypes.SET_BLOGS:
            return {
                ...state,
                blogs: action.payload.blogs,
            }
        case blogTypes.SET_BLOG:
            return {
                ...state,
                blog: action.payload.blog,
            }

        case blogTypes.SET_RELATIVE:
            return {
                ...state,
                relativeBlog: action.payload.relative,
            }
        default:
            return {
                ...state,
            }
    }
}
