import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'

import { getAppIdCookie } from 'utils/token'
import {
    SetScheduleAction,
    fetchBookingAction,
    fetchManagementAction,
} from 'containers/dashboard/features/booking/store/actions'
import { TError } from 'config/types'
import { toast } from 'react-toastify'

import './BookingManagment.scss'
import { PATH_NEW_LOADER } from 'config/paths'
import { TManagementShcedule } from 'containers/dashboard/features/booking/store/types'
import BookingServices from '../components/booking-managments/BookingServices'
import BookingTab from '../components/booking-tab/BookingTab'

const tabs: ['services', 'booked'] = ['services', 'booked']

const BookingManagment = () => {
    const [isTop, setIsTop] = useState(false)
    const [tab, setTab] = useState<'services' | 'booked'>('services')

    const { t, i18n } = useTranslation(['booking'])

    const { pathname } = useLocation()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const { id } = useParams<{ id: string }>()

    const { managementSlots } = useSelector((state: RootState) => state.booking)
    const dispatch = useDispatch()

    useEffect(() => {
        if (appId && id && pathname.includes('loader')) {
            dispatch(
                fetchBookingAction({
                    appId,
                    bookingId: id,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [appId, dispatch, id, t, i18n.language])

    useEffect(() => {
        if (appId && pathname.includes('loader') && id) {
            dispatch(
                fetchManagementAction({
                    appId,
                    bookingId: id,
                    callback: (type: string, errorArray?: TError[]) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                        }
                    },
                }),
            )
        }
    }, [appId, dispatch, pathname, i18n, t])

    const handleSwitch = ({
        target: { checked },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setIsTop(checked)
    }

    const handleScheduleClick = (schedule: TManagementShcedule) => {
        dispatch(
            SetScheduleAction({
                selectedSchedule: schedule,
            }),
        )
        push(`${PATH_NEW_LOADER.pages.bookingManagment}/${id}`)
    }

    const isActiveTab = (name: string) => name === tab

    const setNewTab = (name: 'services' | 'booked') => {
        setTab(name)
    }

    return (
        <div className='m-booking__managment'>
            <BookingTab<'services' | 'booked'>
                isActiveTab={isActiveTab}
                setTab={setNewTab}
                tabs={tabs}
            />
            {tab === 'services' ? (
                <BookingServices
                    handleScheduleClick={handleScheduleClick}
                    handleSwitch={handleSwitch}
                    isTop={isTop}
                    managementSlots={managementSlots}
                />
            ) : (
                <div className='m-booking__further-steps'>
                    {t('booking:bookedService:furtherSteps')}
                </div>
            )}
        </div>
    )
}

export default BookingManagment
