import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'

import { PATH_NEW_LOADER } from 'config/paths'
import { TError, IPagination } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'

import {
    fetchEmployeesAction,
    SetEmployeesAction,
} from 'containers/dashboard/features/employees/store/actions'

import { IEmployee } from 'containers/dashboard/features/employees/store/types'

import './EmployeePage.scss'
import EmulatorEmpty from 'containers/emulator/components/emulator-empty/EmulatorEmpty'

const EmployeePage = (): React.ReactElement => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const { push } = useHistory()
    const appId = getAppIdCookie()
    const { employees } = useSelector((state: RootState) => state.employees)

    useEffect(() => {
        if (pathname.includes('loader') && appId) {
            dispatch(
                fetchEmployeesAction({
                    appId,
                    page: 1,
                    language: i18n.language,
                    callback: (
                        type: string,
                        data: IPagination<IEmployee>,
                        errorArray?: TError[],
                    ) => {
                        if (type === 'error') {
                            errorArray?.forEach(err => {
                                console.error(err.description)
                                toast.error(t('toast:backendError'))
                            })
                            return
                        }
                        SetEmployeesAction({
                            employees: data.items,
                        })
                    },
                }),
            )
        }

        return () => {
            if (pathname.includes('loader')) {
                dispatch(
                    SetEmployeesAction({
                        employees: undefined,
                    }),
                )
            }
        }
    }, [appId, dispatch, pathname, t, i18n.language])

    const regexForHtmlCharts = /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g

    return employees ? (
        employees.length > 0 ? (
            <div className='m-employee__wrapper'>
                {employees.map(item => (
                    <button
                        type='button'
                        key={item.id}
                        className='m-employee new-loader--surface'
                        onClick={() =>
                            push(`${PATH_NEW_LOADER.pages.employee}/${item.id}`)
                        }
                    >
                        <CustomImage
                            src={item.imageUrl}
                            className='m-employee__image'
                        />
                        <div className='m-employee__info'>
                            <div className='m-employee__title new-loader--text'>
                                {`${item.firstName} ${item.lastName}`}
                            </div>
                            {/* eslint-disable-next-line max-len */}
                            <div className='m-employee__position new-loader--text'>
                                {item.description.replace(
                                    regexForHtmlCharts,
                                    ' ',
                                )}
                            </div>
                        </div>
                    </button>
                ))}
            </div>
        ) : (
            <EmulatorEmpty />
        )
    ) : (
        <Preloader />
    )
}

export default EmployeePage
