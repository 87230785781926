import React, { Dispatch, SetStateAction, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import PensileIcon from 'assets/images/new-loader/todo/PensileIcon'
import DeleteIcon from 'assets/images/new-loader/todo/DeleteIcon'
import MenuIcon from 'assets/images/new-loader/todo/MenuIcon'

import { useClickedOutsideHandler } from 'hooks/useClickedOutsideHandler'

import NewCategoryPopup from '../../popups/category-popup/CategoryPopup'
import { ICategory, ITodo } from '../../../TodoPage'
import DeleteItemPopup from '../../popups/delete-item-popup/DeleteItemPopup'

import './EditCategory.scss'

interface IEditCategory {
    storage: ITodo
    setStorage: Dispatch<SetStateAction<ITodo>>
    todoCategory?: ICategory
    setCurrentCategory: Dispatch<SetStateAction<number>>
}

const EditCategory = ({
    storage,
    setStorage,
    todoCategory,
    setCurrentCategory,
}: IEditCategory): React.ReactElement => {
    const { t } = useTranslation(['emulator'])
    const [isOpen, setIsOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    const handleDelete = () => {
        const categoriesCopy = [...storage.categories]
        const tasksCopy = [...storage.tasks]
        const updatedCategories = categoriesCopy.filter(
            i => i.value !== todoCategory?.value,
        )
        const updatedTasks = tasksCopy.filter(
            i => i.category !== todoCategory?.value,
        )
        setStorage({
            ...storage,
            categories: updatedCategories,
            tasks: updatedTasks,
        })
        setCurrentCategory(0)
    }

    const categoryERef = useRef(null)
    useClickedOutsideHandler({
        ref: categoryERef,
        func: () => setIsOpen(false),
    })

    return (
        <>
            {isEdit && (
                <NewCategoryPopup
                    onClose={() => setIsEdit(false)}
                    storage={storage}
                    setStorage={setStorage}
                    todoCategory={todoCategory}
                />
            )}
            {isDelete && (
                <DeleteItemPopup
                    onClose={() => setIsDelete(false)}
                    handleDelete={handleDelete}
                />
            )}
            <div className='m-edit-category__wrapper'>
                <button
                    className='m-edit-category__menu'
                    type='button'
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <MenuIcon />
                </button>
                {isOpen && (
                    <div
                        className='m-edit-category new-loader--surface 
                        new-loader--primary-color'
                        ref={categoryERef}
                    >
                        <button
                            type='button'
                            // eslint-disable-next-line max-len
                            className='new-loader--primary-color m-edit-category__action'
                            onClick={() => {
                                setIsEdit(true)
                                setIsOpen(false)
                            }}
                        >
                            <PensileIcon />
                            <div>{t('emulator:edit')}</div>
                        </button>
                        <button
                            type='button'
                            // eslint-disable-next-line max-len
                            className='new-loader--primary-color m-edit-category__action'
                            onClick={() => {
                                setIsDelete(true)
                                setIsOpen(false)
                            }}
                        >
                            <DeleteIcon />
                            <div>{t('emulator:delete')}</div>
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default EditCategory
