import { IPalette } from 'containers/dashboard/design/color-palette/store/types'
import styled from 'styled-components'

interface INewLoaderContent {
    palette?: IPalette
}

const NewLoaderBackground = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: ${(props: INewLoaderContent) =>
        props.palette?.background || '#EDEDED'};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 31px;

    &::-webkit-scrollbar {
        display: none;
    }
`

export default NewLoaderBackground
