import * as Yup from 'yup'

export const todoCategorySchema = Yup.object().shape({
    label: Yup.string().matches(
        /^([ ]+)?.{1,30}([ ]+)?$/,
        'Max length 30 chars!',
    ),
})

export const todoTaskSchema = Yup.object().shape({
    value: Yup.string().matches(
        /^([ ]+)?.{1,100}([ ]+)?$/,
        'Max length 100 chars!',
    ),
})
