/* eslint-disable max-len */
import { all, fork, AllEffect, ForkEffect } from 'redux-saga/effects'
import applicationsSaga from 'containers/app-list/store/saga'
import accountSettingsSaga from 'containers/account/store/saga'
import cloudSaga from 'containers/dashboard/features/cloud/components/tabs/cloud/store/saga'
import chatSaga from 'containers/dashboard/features/cloud/components/tabs/chat/store/saga'
import mapSaga from 'containers/dashboard/features/map/store/saga'
import blogSaga from 'containers/dashboard/features/blog/store/saga'
import bookingSaga from 'containers/dashboard/features/booking/store/saga'
import employeesSaga from 'containers/dashboard/features/employees/store/saga'
import servicesSaga from 'containers/dashboard/features/services/store/saga'
import informationSaga from 'containers/dashboard/features/about/store/saga'
import calendarSaga from 'containers/dashboard/features/calendar/store/saga'
import todoPremiumSaga from 'containers/dashboard/features/premium/todo/store/saga'
import generalSettingsSaga from 'containers/dashboard/settings/app-settings/store/saga'
import pushNotificationsSaga from 'containers/dashboard/settings/push-notifications/store/saga'
import launchScreenSaga from 'containers/dashboard/design/welcome-screen/store/saga'
import designLogoSaga from 'containers/dashboard/design/icon/store/saga'
import paletteSaga from 'containers/dashboard/design/color-palette/store/saga'
import billingSaga from 'containers/billing-information/store/saga'
import smmSaga from 'containers/dashboard/features/cloud/components/tabs/smm/store/saga'
import eReaderSaga from 'containers/dashboard/features/e-reader/store/saga'
// uncoment when backend ready
// import paymentLinkSaga from 'containers/dashboard/features/billing/store/saga'
import conferenceSaga from 'containers/dashboard/features/cloud/components/tabs/video-conference/store/saga'
import gallerySaga from 'containers/dashboard/features/gallery/store/saga'
import videoSaga from 'containers/dashboard/features/cloud/components/tabs/video/store/saga'
import forumThemeSaga from 'containers/dashboard/features/premium/forum/forum-themes/store/saga'
import surveySaga from 'containers/dashboard/features/premium/survey/store/saga'
import surveyUsersSaga from 'containers/dashboard/features/premium/survey/survey-users/store/saga'
import testAnalyticsSaga from 'containers/dashboard/features/premium/survey/test-analytics/store/saga'
import userSaga from 'containers/dashboard/features/premium/forum/forum-users/store/userSaga'
import testSaga from 'containers/dashboard/testing/store/saga'
import surveySummarySaga from 'containers/dashboard/features/premium/survey/test-summary/store/saga'
import brandingSaga from 'containers/dashboard/app-name/store/saga'
import featuresSaga from 'containers/dashboard/settings/features-activation/store/saga'
import userRolesSaga from 'containers/dashboard/settings/user-roles/store/saga'
import notesSaga from 'layouts/header-navigation/store/saga'
import aiSaga from 'components/ai/store/saga'
import aiPicturesSaga from 'components/ai-pictures/store/saga'
import paymentSaga from 'containers/dashboard/features/payments/store/saga'

export function* rootSaga(): Generator<
    AllEffect<ForkEffect<void>>,
    void,
    unknown
> {
    yield all([
        fork(applicationsSaga),
        fork(cloudSaga),
        fork(chatSaga),
        fork(mapSaga),
        fork(blogSaga),
        // fork(paymentLinkSaga),
        fork(bookingSaga),
        fork(employeesSaga),
        fork(servicesSaga),
        fork(informationSaga),
        fork(calendarSaga),
        fork(todoPremiumSaga),
        fork(generalSettingsSaga),
        fork(accountSettingsSaga),
        fork(pushNotificationsSaga),
        fork(launchScreenSaga),
        fork(designLogoSaga),
        fork(paletteSaga),
        fork(billingSaga),
        fork(smmSaga),
        fork(eReaderSaga),
        fork(conferenceSaga),
        fork(gallerySaga),
        fork(videoSaga),
        fork(forumThemeSaga),
        fork(surveySaga),
        fork(surveyUsersSaga),
        fork(testAnalyticsSaga),
        fork(userSaga),
        fork(testSaga),
        fork(surveySummarySaga),
        fork(brandingSaga),
        fork(featuresSaga),
        fork(userRolesSaga),
        fork(notesSaga),
        fork(aiSaga),
        fork(paymentSaga),
        fork(aiPicturesSaga),
    ])
}
