import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'
import { LAUNCH_SCREEN_URLS } from 'config/urls'

import { ILaunchScreen } from '../store/types'

export const getLaunchScreen = ({
    appId,
    lang,
}: {
    appId: string
    lang: string
}): Promise<AxiosResponse<ILaunchScreen>> =>
    axiosInstance.get(`${appId}${LAUNCH_SCREEN_URLS.main}/${lang}`)

export const editLaunchScreen = ({
    appId,
    lang,
    settings,
}: {
    appId: string
    lang: string
    settings: ILaunchScreen
}): Promise<AxiosResponse<ILaunchScreen>> =>
    axiosInstance.post(`${appId}${LAUNCH_SCREEN_URLS.main}/${lang}`, settings)
