import axios from 'axios'

import {
    getCookiesAccessToken,
    removeToken,
    removeAppIdCookie,
    removeNextPaymentCookie,
    removeNextFreeCookie,
    removePaletteCookie,
    removePlanCookie,
    removeIsTrialUSed,
    removePoints,
    removeActiveFeatures,
    removeAppName,
} from 'utils/token'
import { PATH_BILLING } from 'config/paths'

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL,
})

// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const AxiosInterceptorsSetup = (history: any): void => {
    axiosInstance.interceptors.request.use(
        config => {
            const token = getCookiesAccessToken()

            if (token) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        },
        error => Promise.reject(error),
    )
    axiosInstance.interceptors.response.use(
        res => res,
        async err => {
            console.log('err', err.response)

            // if (!err.response) {
            //     return Promise.reject(err[0].description)
            // }

            if (err.response?.status === 401) {
                removeToken()
                removeAppIdCookie()
                removeNextPaymentCookie()
                removeNextFreeCookie()
                removePaletteCookie()
                removeActiveFeatures()
                removeAppName()
                removePlanCookie()
                removeIsTrialUSed()
                removePoints()
                localStorage.removeItem('applications')
                history.push('/login')
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject([
                    {
                        name: 'Anauthorized',
                        description: 'Your session has been expired',
                    },
                ])
            }
            if (err.response?.status === 403) {
                removeAppIdCookie()
                removePaletteCookie()
                removeActiveFeatures()
                removeAppName()
                history.push(PATH_BILLING.root)
                // console.log(err.response.data.exceptionsList)
                // return undefined
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(err.response.data.exceptionsList)
            }
            console.log(err.response?.data?.exceptionsList, 'err.response.data')

            return Promise.reject(err.response?.data.exceptionsList || err)
        },
    )
}
