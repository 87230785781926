import React from 'react'

export const NotificationIcon = ({
    showUnread,
    isSmall,
}: {
    showUnread?: boolean
    isSmall?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '22' : '46'}
        height={isSmall ? '22' : '46'}
        viewBox={isSmall ? '10 12 24 24' : '0 0 46 46'}
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_8991_121593)'>
            <path
                d='M23.5 11C22.6703 11 22 11.6703 22 12.5V13.4C18.5781 14.0938 16 17.1219 16 20.75V21.6313C16 23.8344 15.189 25.9625 13.7265 27.6125L13.3796 28.0016C12.9859 28.4422 12.8921 29.075 13.1312 29.6141C13.3703 30.1531 13.9093 30.5 14.5 30.5H32.5C33.0906 30.5 33.625 30.1531 33.8687 29.6141C34.1125 29.075 34.014 28.4422 33.6203 28.0016L33.2734 27.6125C31.8109 25.9625 31 23.8391 31 21.6313V20.75C31 17.1219 28.4218 14.0938 25 13.4V12.5C25 11.6703 24.3296 11 23.5 11ZM25.6234 34.1234C26.1859 33.5609 26.5 32.7969 26.5 32H23.5H20.5C20.5 32.7969 20.814 33.5609 21.3765 34.1234C21.939 34.6859 22.7031 35 23.5 35C24.2968 35 25.0609 34.6859 25.6234 34.1234Z'
                fill='currentColor'
            />
            {showUnread ? (
                <circle cx='30' cy='15' r='4' fill='#E73F3F' />
            ) : null}
        </g>
        <defs>
            <clipPath id='clip0_8991_121593'>
                <rect
                    width='21'
                    height='24'
                    fill='white'
                    transform='translate(13 11)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default NotificationIcon
