import { applicationTypes } from './actionTypes'
import {
    IApplication,
    FetchAppsRequest,
    FetchAppsPayload,
    FetchApplicationByIdRequest,
    FetchApplicationByIdPayload,
    FetchAppTypesRequest,
    EditAppsRequest,
    CreateAppsRequest,
    CreateApplicationPayload,
    EditApplicationPayload,
    SetApplicationDetails,
    SetApplications,
    SetAppTypes,
    DeleteApplicationPayload,
    DeleteApplicationRequest,
    IAppTypes,
} from './types'

export const fetchApplicationsRequest = (
    payload: FetchAppsPayload,
): FetchAppsRequest => ({
    type: applicationTypes.FETCH_APPLICATIONS_REQUEST,
    payload,
})

export const fetchAppTypesRequest = (payload: {
    lang: string
}): FetchAppTypesRequest => ({
    type: applicationTypes.FETCH_APPLICATION_TYPES,
    payload,
})

export const fetchApplicationByIdRequest = (
    payload: FetchApplicationByIdPayload,
): FetchApplicationByIdRequest => ({
    type: applicationTypes.FETCH_APPLICATION_BY_ID,
    payload,
})

export const CreateApplicationRequest = (
    payload: CreateApplicationPayload,
): CreateAppsRequest => ({
    type: applicationTypes.CREATE_APPLICATION,
    payload,
})

export const UpdateApplicationRequest = (
    payload: EditApplicationPayload,
): EditAppsRequest => ({
    type: applicationTypes.EDIT_APPLICATION,
    payload,
})

export const DeleteApplicationAction = (
    payload: DeleteApplicationPayload,
): DeleteApplicationRequest => ({
    type: applicationTypes.DELETE_APPLICATION,
    payload,
})

export const SetApplicationDetailsAction = (payload: {
    applicationDetails: IApplication
}): SetApplicationDetails => ({
    type: applicationTypes.SET_APPLICATION_DETAILS,
    payload,
})

export const SetApplicationsAction = (payload: {
    applications?: IApplication[]
}): SetApplications => ({
    type: applicationTypes.SET_APPLICATIONS,
    payload,
})

export const SetAppTypesAction = (payload: {
    appTypes?: IAppTypes[]
}): SetAppTypes => ({
    type: applicationTypes.SET_APP_TYPES,
    payload,
})
