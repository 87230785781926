import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface Palette {
        white: Palette['primary']
        black: Palette['primary']
    }
    interface PaletteOptions {
        white: PaletteOptions['primary']
        black: PaletteOptions['primary']
    }
    interface BreakpointOverrides {
        xl: false
    }
}
const theme = createTheme({
    palette: {
        primary: {
            main: '#ffed00',
            dark: '#d0a906',
        },
        secondary: {
            light: '#d6d6d6',
            main: '#000000',
            dark: '#929598',
        },
        error: {
            main: '#f12727',
        },
        white: {
            main: '#ffffff',
        },
        black: {
            main: '#000000',
        },
    },
    breakpoints: {
        values: {
            xs: 400,
            sm: 800,
            md: 1200,
            lg: 1600,
        },
    },
})

export default theme
