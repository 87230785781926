import React from 'react'

const PensileIcon = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M19.2059 2.79388C17.9248 1.51282 15.8478 1.51289 14.5668 2.79402L3.61259 13.7495C3.24012 14.122 2.97832 14.5905 2.85631 15.1031L1.85202 19.321C1.79673 19.5533 1.86587 19.7976 2.03469 19.9664C2.20351 20.1352 2.44782 20.2044 2.68006 20.149L6.89788 19.1448C7.41048 19.0227 7.87915 18.7608 8.25173 18.3882L19.206 7.43269C20.4868 6.15166 20.4868 4.07484 19.2059 2.79388ZM15.5391 3.76624C16.2831 3.02212 17.4895 3.02209 18.2336 3.76616C18.9775 4.51017 18.9776 5.71643 18.2337 6.46048L17.4166 7.27763L14.7222 4.58324L15.5391 3.76624ZM13.75 5.55557L16.4444 8.24997L7.2794 17.416C7.08676 17.6087 6.84443 17.7441 6.5794 17.8072L3.4484 18.5527L4.19392 15.4215C4.257 15.1566 4.39235 14.9143 4.58492 14.7217L13.75 5.55557Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default PensileIcon
