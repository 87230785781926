import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const EmployeeRoutes = [
    {
        id: 1,
        module: 'loader-employee',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.employee,
        component: lazy(
            () =>
                import(
                    //  eslint-disable-next-line max-len
                    'containers/emulator/pages/employees/employee-page/EmployeePage'
                ),
        ),
    },
    {
        id: 2,
        module: 'loader-employee',
        protected: false,
        exact: true,
        path: `${PATH_NEW_LOADER.pages.employee}/:id`,
        component: lazy(
            () =>
                import(
                    //  eslint-disable-next-line max-len
                    'containers/emulator/pages/employees/employee-item/EmployeeItem'
                ),
        ),
    },
]
