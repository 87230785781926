export enum cloudTypes {
    FETCH_CLOUD = 'FETCH_CLOUD',
    FETCH_CLOUD_BY_ID = 'FETCH_CLOUD_BY_ID',
    EDIT_CLOUD = 'EDIT_CLOUD',
    CREATE_CLOUD = 'CREATE_CLOUD',
    DELETE_CLOUD = 'DELETE_CLOUD',
    SET_CLOUD = 'SET_CLOUD',
    SET_RELATIVE_CLOUD = 'SET_RELATIVE_CLOUD',
    SET_TAB = 'SET_TAB',
}
