import { servicesTypes } from './actionTypes'
import {
    FetchServicesRequest,
    FetchServicesPayload,
    FetchServiceByIdRequest,
    FetchServicePayload,
    CreateServicesPayload,
    CreateServicesRequest,
    EditServiceRequest,
    EditServicePayload,
    DeleteServiceRequest,
    DeleteServicePayload,
    SetServices,
    SetService,
    IService,
} from './types'

export const fetchServicesAction = (
    payload: FetchServicesPayload,
): FetchServicesRequest => ({
    type: servicesTypes.FETCH_SERVICES_REQUEST,
    payload,
})

export const fetchServiceAction = (
    payload: FetchServicePayload,
): FetchServiceByIdRequest => ({
    type: servicesTypes.FETCH_SERVICE_BY_ID,
    payload,
})

export const createServicesAction = (
    payload: CreateServicesPayload,
): CreateServicesRequest => ({
    type: servicesTypes.CREATE_SERVICES_REQUEST,
    payload,
})

export const editServiceAction = (
    payload: EditServicePayload,
): EditServiceRequest => ({
    type: servicesTypes.EDIT_SERVICE_REQUEST,
    payload,
})

export const deleteServiceAction = (
    payload: DeleteServicePayload,
): DeleteServiceRequest => ({
    type: servicesTypes.DELETE_SERVICE_REQUEST,
    payload,
})

export const SetServicesAction = (payload: {
    services: IService[] | undefined
}): SetServices => ({
    type: servicesTypes.SET_SERVICES,
    payload,
})

export const SetServiceAction = (payload: {
    service: IService
}): SetService => ({
    type: servicesTypes.SET_SERVICE,
    payload,
})
