import React from 'react'

export const GeneralDesignIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_5225_63091)'>
            <path
                d='M9.79969 17.5747C7.05547 17.5747 4.82294 19.8072 4.82294 22.5515C4.82294 25.2957 7.05547 27.5282 9.79969 27.5282C12.5439 27.5282 14.7764 25.2957 14.7764 22.5515C14.7764 19.8072 12.5439 17.5747 9.79969 17.5747ZM9.79969 24.8225C8.54749 24.8225 7.52864 23.8037 7.52864 22.5515C7.52864 21.2992 8.54749 20.2804 9.79969 20.2804C11.0519 20.2804 12.0707 21.2992 12.0707 22.5515C12.0707 23.8037 11.0519 24.8225 9.79969 24.8225Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M18.6127 29.3291C15.8686 29.3291 13.636 31.5616 13.636 34.3059C13.636 37.05 15.8686 39.2825 18.6127 39.2825C21.3569 39.2825 23.5895 37.05 23.5895 34.3059C23.5895 31.5616 21.3569 29.3291 18.6127 29.3291ZM18.6127 36.5768C17.3605 36.5768 16.3417 35.558 16.3417 34.3058C16.3417 33.0535 17.3605 32.0347 18.6127 32.0347C19.865 32.0347 20.8838 33.0535 20.8838 34.3058C20.8838 35.558 19.865 36.5768 18.6127 36.5768Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M44.7535 29.2861C44.7446 29.2773 44.7357 29.2687 44.7266 29.26L40.0419 24.8394C42.6643 22.8565 43.8489 19.3467 42.7716 16.0998C37.6928 0.777057 18.1765 -4.08415 6.54763 7.36904C-2.16179 15.9469 -2.08053 29.7778 6.18288 38.2949C13.6065 45.9464 25.3038 47.021 33.9372 41.3696C37.9802 38.724 36.1215 32.4265 31.2835 32.4265C27.6703 32.4265 27.0631 27.3745 30.4762 26.5095L38.7294 35.2561C38.7381 35.2652 38.7467 35.2743 38.7557 35.2832C40.4101 36.9414 43.0976 36.9459 44.7564 35.287C46.415 33.6285 46.415 30.9439 44.7535 29.2861ZM27.2734 33.5348C28.3604 34.5649 29.7845 35.1321 31.2836 35.1321C33.4444 35.1321 34.259 37.9257 32.4556 39.1058C24.8994 44.0521 14.6369 43.1229 8.1248 36.4109C0.820901 28.8829 0.863924 16.8017 8.4762 9.31394C18.6767 -0.719655 35.7378 3.47807 40.2035 16.9515C40.9601 19.2319 40.0146 21.7099 38.0314 22.9422L30.5168 15.8511C30.9717 13.6667 30.3416 11.3639 28.6907 9.71305C26.6186 7.64089 20.0908 6.60805 17.2618 6.30076C16.4038 6.20731 15.6773 6.93267 15.7706 7.79197C16.0805 10.632 17.1072 17.1449 19.183 19.2208C20.8351 20.8727 23.139 21.5015 25.3217 21.0466L28.487 24.4012C25.1164 26.231 24.432 30.8421 27.2734 33.5348ZM21.0962 17.3076C20.0767 16.2881 19.1658 12.4106 18.6849 9.2149C21.8807 9.69574 25.7581 10.6067 26.7775 11.6262C28.3473 13.196 28.3476 15.7373 26.7775 17.3076C25.2113 18.874 22.6694 18.8808 21.0962 17.3076ZM42.8433 33.3738C42.2472 33.97 41.2822 33.9727 40.6825 33.3835C39.99 32.6496 28.5711 20.5479 27.9348 19.8736C28.4686 19.4772 28.947 18.9988 29.344 18.4645L42.8539 31.2131C43.4437 31.8134 43.4404 32.7766 42.8433 33.3738Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
        </g>
        <defs>
            <clipPath id='clip0_5225_63091'>
                <rect width='46' height='46' fill='white' />
            </clipPath>
        </defs>
    </svg>
)

export default GeneralDesignIcon
