import React from 'react'

const CategoryOffice = ({
    className,
}: {
    className?: string
}): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9.16683 2.7496C9.0454 2.74795 8.92486 2.77044 8.8122 2.81577C8.69954 2.8611 8.59701 2.92835 8.51056 3.01364C8.42411 3.09892 8.35546 3.20053 8.30861 3.31257C8.26176 3.4246 8.23763 3.54483 8.23763 3.66626H3.66683C2.65391 3.66626 1.8335 4.48668 1.8335 5.4996V10.9996C1.8335 12.0107 2.65575 12.8329 3.66683 12.8329H18.3335C19.3446 12.8329 20.1668 12.0107 20.1668 10.9996V5.4996C20.1668 4.48668 19.3464 3.66626 18.3335 3.66626H13.7627C13.7627 3.54483 13.7386 3.4246 13.6917 3.31257C13.6449 3.20053 13.5762 3.09892 13.4898 3.01364C13.4033 2.92835 13.3008 2.8611 13.1881 2.81577C13.0755 2.77044 12.9549 2.74795 12.8335 2.7496H9.16683ZM11.0002 9.16626C11.5062 9.16626 11.9168 9.57693 11.9168 10.0829C11.9168 10.5889 11.5062 10.9996 11.0002 10.9996C10.4942 10.9996 10.0835 10.5889 10.0835 10.0829C10.0835 9.57693 10.4942 9.16626 11.0002 9.16626ZM1.8335 14.156V16.4996C1.8335 17.5125 2.65391 18.3329 3.66683 18.3329H18.3335C19.3464 18.3329 20.1668 17.5125 20.1668 16.4996V14.156C19.6251 14.4713 19.0045 14.6663 18.3335 14.6663H3.66683C2.99583 14.6663 2.37525 14.4713 1.8335 14.156Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default CategoryOffice
