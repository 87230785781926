import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SERVICES_URLS } from 'config/urls'

import { IService, IServiceMultiLang } from '../store/types'

export const getServices = ({
    language,
    appId,
    page,
}: {
    appId: string
    page: number | string
    language: string
}): Promise<AxiosResponse<IService[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${SERVICES_URLS.services}/language/${language}?pageNumber=${page}&pageSize=10`,
    )
// axiosInstance.get(
// `${appId}${SERVICES_URLS.services}?pageNumber=${page}&pageSize=10`,
// )

export const getServiceById = ({
    serviceId,
    appId,
}: {
    serviceId: string
    appId: string
}): Promise<AxiosResponse<IService>> =>
    axiosInstance.get(`${appId}${SERVICES_URLS.services}/${serviceId}`)
// axiosInstance.get(`${appId}${SERVICES_URLS.services}/${serviceId}`)

export const createServices = ({
    services,
    appId,
}: {
    services: IServiceMultiLang[]
    appId: string
}): Promise<AxiosResponse<IServiceMultiLang[]>> =>
    axiosInstance.post(`${appId}${SERVICES_URLS.services}/bulk`, services)
// axiosInstance.post(`${appId}${SERVICES_URLS.services}/bulk`, services)

export const editService = ({
    serviceId,
    service,
    appId,
}: {
    serviceId: string
    service: IService
    appId: string
}): Promise<AxiosResponse<IService>> =>
    axiosInstance.put(`${appId}${SERVICES_URLS.services}/${serviceId}`, service)
// axiosInstance.put(`${appId}${SERVICES_URLS.services}/${serviceId}`, service)

export const deleteService = ({
    serviceId,
    appId,
}: {
    appId: string
    serviceId: string
}): Promise<AxiosResponse<IService>> =>
    axiosInstance.delete(`${appId}${SERVICES_URLS.services}/${serviceId}`)
