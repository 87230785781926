import { featuresTypes } from './actionTypes'
import { FeaturesActions, FeaturesState } from './types'

const initialState: FeaturesState = {
    activeFeatures: undefined,
    inactiveFeatures: undefined,
}

export default (
    state = initialState,
    action: FeaturesActions,
): FeaturesState => {
    switch (action.type) {
        case featuresTypes.SET_FEATURES_ACTIVE:
            return {
                ...state,
                activeFeatures: action.payload.activeFeatures,
            }
        case featuresTypes.SET_FEATURES_INACTIVE:
            return {
                ...state,
                inactiveFeatures: action.payload.inactiveFeatures,
            }
        default:
            return {
                ...state,
            }
    }
}
