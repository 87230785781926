import React from 'react'

export const ProfileIcon = (): React.ReactElement => (
    <svg
        width='46'
        height='46'
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_8991_121600)'>
            <path
                d='M23.5 23C25.0913 23 26.6174 22.3679 27.7426 21.2426C28.8679 20.1174 29.5 18.5913 29.5 17C29.5 15.4087 28.8679 13.8826 27.7426 12.7574C26.6174 11.6321 25.0913 11 23.5 11C21.9087 11 20.3826 11.6321 19.2574 12.7574C18.1321 13.8826 17.5 15.4087 17.5 17C17.5 18.5913 18.1321 20.1174 19.2574 21.2426C20.3826 22.3679 21.9087 23 23.5 23ZM21.3578 25.25C16.7406 25.25 13 28.9906 13 33.6078C13 34.3766 13.6234 35 14.3922 35H32.6078C33.3766 35 34 34.3766 34 33.6078C34 28.9906 30.2594 25.25 25.6422 25.25H21.3578Z'
                fill='currentColor'
            />
        </g>
        <defs>
            <clipPath id='clip0_8991_121600'>
                <rect
                    width='21'
                    height='24'
                    fill='white'
                    transform='translate(13 11)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default ProfileIcon
