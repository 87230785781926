import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { VIDEO_URLS } from 'config/urls'
import { IVideo, IVideoMultilang } from '../store/types'

export const getVideo = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IVideo[]>> =>
    axiosInstance.get(`${appId}${VIDEO_URLS.main}/language/${language}`)
// axiosInstance.get(`${appId}${VIDEO_URLS.main}`)

export const getVideoByID = ({
    appId,
    videoId,
}: {
    appId: string
    videoId: string
}) => axiosInstance.get(`${appId}${VIDEO_URLS.main}/${videoId}`)

export const editVideo = ({
    item,
    appId,
    id,
}: {
    item: IVideo
    appId: string
    id: string
}): Promise<AxiosResponse<IVideo[]>> =>
    axiosInstance.put(`${appId}${VIDEO_URLS.main}/${id}`, item)

export const createVideo = ({
    item,
    appId,
}: {
    item: IVideoMultilang
    appId: string
}): Promise<AxiosResponse<IVideo>> =>
    axiosInstance.post(`${appId}${VIDEO_URLS.main}`, item)
// axiosInstance.post(`${appId}${VIDEO_URLS.main}`, video)

export const deleteVideo = ({
    appId,
    id,
}: {
    appId: string
    id: string
}): Promise<AxiosResponse<IVideo>> =>
    axiosInstance.delete(`${appId}${VIDEO_URLS.main}/${id}`)
