import React, { Dispatch, SetStateAction, useState, useRef } from 'react'

import useDraggableScroll from 'use-draggable-scroll'

import TaskMedium from 'assets/images/new-loader/todo/priority/TaskMedium'
import CheckboxFilled from 'assets/images/new-loader/todo/CheckboxFilled'
import CheckboxBlank from 'assets/images/new-loader/todo/CheckboxBlank'
import TaskHigh from 'assets/images/new-loader/todo/priority/TaskHigh'
import TaskLow from 'assets/images/new-loader/todo/priority/TaskLow'
import PensileIcon from 'assets/images/new-loader/todo/PensileIcon'
import DeleteIcon from 'assets/images/new-loader/todo/DeleteIcon'

import ToArchiveIcon from 'assets/images/new-loader/todo/ToArchiveIcon'
import FromArchiveIcon from 'assets/images/new-loader/todo/FromArchiveIcon'
import { useLocation } from 'react-router'
import { PATH_NEW_LOADER } from 'config/paths'
import { ITasks, ITodo } from '../../TodoPage'
import TaskPopup from '../popups/task-popup/TaskPopup'
import DeleteItemPopup from '../popups/delete-item-popup/DeleteItemPopup'

import './Task.scss'

interface ITask {
    todoItem: ITasks
    setStorage: Dispatch<SetStateAction<ITodo>>
    storage: ITodo
    currentCategory: number
}

const Task = ({
    todoItem,
    setStorage,
    storage,
    currentCategory,
}: ITask): React.ReactElement => {
    const ref = useRef<HTMLDivElement>(null)
    const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' })

    const { pathname } = useLocation()

    const [isAdd, setIsAdd] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    const handleTaskUpdate = (key: string, field: string) => {
        const updatedTasks = storage.tasks.map(item => {
            if (item.id === key) {
                return { ...item, [field]: !item[field] }
            }
            return item
        })
        setStorage({ ...storage, tasks: updatedTasks })
    }

    const handleDelete = () => {
        const tasksCopy = [...storage.tasks]
        const updatedTasks = tasksCopy.filter(i => i.id !== todoItem?.id)
        setStorage({
            ...storage,
            tasks: updatedTasks,
        })
    }

    return (
        <>
            {isAdd && (
                <TaskPopup
                    onClose={() => setIsAdd(false)}
                    storage={storage}
                    setStorage={setStorage}
                    todoItem={todoItem}
                    currentCategory={currentCategory}
                />
            )}
            {isDelete && (
                <DeleteItemPopup
                    isTask
                    onClose={() => setIsDelete(false)}
                    handleDelete={handleDelete}
                />
            )}
            <div
                className='m-task'
                ref={ref}
                onMouseDown={onMouseDown}
                role='presentation'
            >
                <div className='m-task__wrapper new-loader--surface'>
                    <div className='m-task__info new-loader--text'>
                        <button
                            type='button'
                            className='m-task__check new-loader--text'
                            onClick={() =>
                                handleTaskUpdate(todoItem.id, 'isActive')
                            }
                        >
                            {todoItem.isActive ? (
                                <CheckboxBlank />
                            ) : (
                                <CheckboxFilled />
                            )}
                        </button>
                        <div
                            className={`m-task__text ${
                                todoItem.isActive ? '' : 'm-task__text--done'
                            }`}
                        >
                            {todoItem.value}
                        </div>
                    </div>
                    <div className='m-task__actions'>
                        <div className='m-task__priority'>
                            {todoItem.priority === 1 ? (
                                <TaskHigh />
                            ) : todoItem.priority === 2 ? (
                                <TaskMedium />
                            ) : (
                                <TaskLow />
                            )}
                        </div>
                        {pathname.includes(PATH_NEW_LOADER.pages.todo.root) ? (
                            <button
                                type='button'
                                className='m-task__button new-loader--text'
                                onClick={() => setIsAdd(true)}
                            >
                                {/* eslint-disable-next-line max-len */}
                                <PensileIcon className='new-loader--primary-color' />
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className='m-task__moves'>
                    <button
                        type='button'
                        className='m-task__move m-task__button new-loader--text'
                        onClick={() =>
                            handleTaskUpdate(todoItem.id, 'isArchive')
                        }
                    >
                        {todoItem.isArchive ? (
                            <FromArchiveIcon />
                        ) : (
                            <ToArchiveIcon />
                        )}
                    </button>
                    <button
                        type='button'
                        className='m-task__move m-task__button new-loader--text'
                        onClick={() => setIsDelete(true)}
                    >
                        <DeleteIcon />
                    </button>
                </div>
            </div>
        </>
    )
}

export default Task
