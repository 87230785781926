import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { getAppIdCookie } from 'utils/token'
import { TError } from 'config/types'

import {
    fetchGalleryAction,
    SetGalleryAction,
} from 'containers/dashboard/features/gallery/store/actions'

import { PATH_NEW_LOADER } from 'config/paths'

import Preloader from 'components/preloader/Preloader'
import { RootState } from 'redux/reducers/rootReducer'

import GalleryView from '../components/gallery-view/GalleryView'

const GalleryPage = () => {
    const { t, i18n } = useTranslation(['toast'])

    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { gallery } = useSelector((state: RootState) => state.gallery)

    useEffect(() => {
        if (!appId) return
        dispatch(
            fetchGalleryAction({
                appId,
                language: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
        dispatch(
            SetGalleryAction({
                gallery: undefined,
            }),
        )
    }, [appId, dispatch, t, i18n.language])

    return gallery ? (
        <GalleryView data={gallery} path={PATH_NEW_LOADER.pages.gallery} />
    ) : (
        <Preloader />
    )
}
export default GalleryPage as React.FC
