import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { APPLICATION_URLS } from 'config/urls'

import { IBranding } from '../store/types'

export const fetchBrandingRequest = ({
    appId,
}: {
    appId: string
}): Promise<AxiosResponse<IBranding>> =>
    axiosInstance.get(`${APPLICATION_URLS.applications}/${appId}/branding`)

export const editBrandingRequest = ({
    appId,
    branding,
}: {
    appId: string
    branding: IBranding
}): Promise<AxiosResponse<IBranding>> =>
    axiosInstance.put(`${APPLICATION_URLS.applications}/${appId}`, branding)
