import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { TError } from 'config/types'
import { getAppIdCookie } from 'utils/token'

import {
    fetchGalleryItemAction,
    SetGalleryItemAction,
} from 'containers/dashboard/features/gallery/store/actions'
import CustomImage from 'components/custom-image/CustomImage'
import Preloader from 'components/preloader/Preloader'
import { RootState } from 'redux/reducers/rootReducer'

import './ImagePage.scss'

type GalleryParams = {
    id: string
}

const GalleryItemPage = () => {
    const { t, i18n } = useTranslation(['emulator', 'toast'])

    const dispatch = useDispatch()
    const appId = getAppIdCookie()

    const { galleryItem } = useSelector((state: RootState) => state.gallery)
    const { id } = useParams<GalleryParams>()

    useEffect(() => {
        if (!id) return
        dispatch(
            fetchGalleryItemAction({
                galleryItemId: id,
                appId,
                language: i18n.language,
                callback: (type: string, errorArray?: TError[]) => {
                    if (type === 'error') {
                        errorArray?.forEach(err => {
                            console.error(err.description)
                            toast.error(t('toast:backendError'))
                        })
                    }
                },
            }),
        )
        dispatch(
            SetGalleryItemAction({
                galleryItem: undefined,
            }),
        )
    }, [appId, dispatch, id, t, i18n.language])

    return galleryItem ? (
        <div className='m-image-page'>
            <CustomImage src={galleryItem.url} />
            <div className='m-image-page__info new-loader--surface'>
                <div className='m-image-page__title new-loader--text'>
                    {t('emulator:description')}
                </div>
                <div className='m-image-page__desc new-loader--text'>
                    {galleryItem.name}
                </div>
            </div>
        </div>
    ) : (
        <Preloader />
    )
}
export default GalleryItemPage as React.FC
