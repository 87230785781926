import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { SURVEY_URLS } from 'config/urls'

import { ITestSummary } from '../store/types'

export const getTestSummary = ({
    applicationId,
    surveyId,
    lang,
}: {
    applicationId: string
    surveyId: string
    lang: string
}): Promise<AxiosResponse<ITestSummary[]>> =>
    axiosInstance.get(
        `${applicationId}${SURVEY_URLS.main}/${lang}/${surveyId}/statistics`,
    )
