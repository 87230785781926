import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@mui/material'

const NotFound = (): React.ReactElement => {
    const { goBack } = useHistory()

    return (
        <Button onClick={goBack} variant='contained'>
            Back
        </Button>
    )
}

export default NotFound as React.FC
