import { designLogoTypes } from './actionTypes'
import { DesignLogoActions, DesignLogoState } from './types'

const initialState: DesignLogoState = {
    designLogo: undefined,
}

export default (
    state = initialState,
    action: DesignLogoActions,
): DesignLogoState => {
    switch (action.type) {
        case designLogoTypes.SET_DESIGN_LOGO:
            return {
                ...state,
                designLogo: action.payload.designLogo,
            }
        default:
            return {
                ...state,
            }
    }
}
