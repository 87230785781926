import { calendarTypes } from './actionTypes'
import { CalendarActions, CalendarState } from './types'

const initialState: CalendarState = {
    eventDate: undefined,
    datesWithEvents: undefined,
    events: undefined,
    calendarEvent: undefined,
    unconfirmedEvents: undefined,
    allEvents: undefined,
    currentDay: undefined,
    activeRangeDate: undefined,
    calendarRelativeEvent: undefined,
}

export default (
    state = initialState,
    action: CalendarActions,
): CalendarState => {
    switch (action.type) {
        case calendarTypes.SET_DATES:
            return {
                ...state,
                datesWithEvents: action.payload.datesWithEvents,
            }
        case calendarTypes.SET_EVENT_DATE:
            return {
                ...state,
                eventDate: action.payload.eventDate,
            }
        case calendarTypes.SET_EVENTS:
            return {
                ...state,
                events: action.payload.events,
            }
        case calendarTypes.SET_EVENT:
            return {
                ...state,
                calendarEvent: action.payload.calendarEvent,
            }
        case calendarTypes.SET_UNCONFIRMED_EVENTS:
            return {
                ...state,
                unconfirmedEvents: action.payload.unconfirmedEvents,
            }
        case calendarTypes.SET_ALL_EVENTS:
            return {
                ...state,
                allEvents: action.payload.allEvents,
            }
        case calendarTypes.SET_CURRENT_DAY:
            return {
                ...state,
                currentDay: action.payload.currentDay,
            }
        case calendarTypes.SET_ACTIVE_START_DATE:
            return {
                ...state,
                activeRangeDate: action.payload.activeRangeDate,
            }

        case calendarTypes.SET_RELATIVE_EVENT:
            return {
                ...state,
                calendarRelativeEvent: action.payload.relativeEvent,
            }
        default:
            return {
                ...state,
            }
    }
}
