import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { getFeatures, editFeatures } from '../service/features'
import { featuresTypes } from './actionTypes'
import {
    FetchFeaturesRequest,
    EditFeaturesRequest,
    IFeature,
    IFeatures,
} from './types'

const filteredData = (features: IFeature[]) =>
    features.filter(
        feature =>
            feature.type.toLowerCase() !== 'livechat' &&
            feature.type.toLowerCase() !== 'todo',
    )

function* fetchFeaturesSaga(action: FetchFeaturesRequest) {
    try {
        const response: AxiosResponse<IFeatures> = yield call(
            getFeatures,
            action.payload,
        )

        if (response.data) {
            yield put({
                type: featuresTypes.SET_FEATURES_ACTIVE,
                payload: {
                    activeFeatures:
                        response.data.activeFeatures &&
                        filteredData(response.data.activeFeatures),
                },
            })
            yield put({
                type: featuresTypes.SET_FEATURES_INACTIVE,
                payload: {
                    inactiveFeatures:
                        response.data.inactiveFeatures &&
                        filteredData(response.data.inactiveFeatures),
                },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editFeaturesSaga(action: EditFeaturesRequest) {
    try {
        const response: AxiosResponse<IFeature[]> = yield call(
            editFeatures,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* featuresSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(featuresTypes.FETCH_FEATURES_REQUEST, fetchFeaturesSaga),
    ])
    yield all([
        takeLatest(featuresTypes.EDIT_FEATURES_REQUEST, editFeaturesSaga),
    ])
}

export default featuresSaga
