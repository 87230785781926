import { testTypes } from './actionTypes'
import {
    ITest,
    FetchTestsRequest,
    FetchTestsPayload,
    FetchTestByIdRequest,
    FetchTestPayload,
    CreateTestRequest,
    CreateTestPayload,
    EditTestRequest,
    EditTestPayload,
    DeleteTestRequest,
    DeleteTestPayload,
    SetTests,
    SetTest,
} from './types'

export const fetchTestsAction = (
    payload: FetchTestsPayload,
): FetchTestsRequest => ({
    type: testTypes.FETCH_TESTS_REQUEST,
    payload,
})

export const fetchTestAction = (
    payload: FetchTestPayload,
): FetchTestByIdRequest => ({
    type: testTypes.FETCH_TEST_BY_ID,
    payload,
})

export const createTestAction = (
    payload: CreateTestPayload,
): CreateTestRequest => ({
    type: testTypes.CREATE_TEST_REQUEST,
    payload,
})

export const editTestAction = (payload: EditTestPayload): EditTestRequest => ({
    type: testTypes.EDIT_TEST_REQUEST,
    payload,
})

export const deleteTestAction = (
    payload: DeleteTestPayload,
): DeleteTestRequest => ({
    type: testTypes.DELETE_TEST_REQUEST,
    payload,
})

export const SetTestsAction = (payload: {
    tests: ITest[] | undefined
}): SetTests => ({
    type: testTypes.SET_TESTS,
    payload,
})

export const SetTestAction = (payload: {
    test: ITest | undefined
}): SetTest => ({
    type: testTypes.SET_TEST,
    payload,
})
