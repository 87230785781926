import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { blogTypes } from './actionTypes'
import {
    getBlogs,
    getBlogById,
    createBlogs,
    editBlog,
    deleteBlog,
} from '../service/blog'

import {
    IBlog,
    FetchBlogsRequest,
    FetchBlogByIdRequest,
    CreateBlogsRequest,
    EditBlogRequest,
} from './types'

function* fetchBlogsSaga(action: FetchBlogsRequest) {
    try {
        const response: AxiosResponse<IPagination<IBlog>> = yield call(
            getBlogs,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: blogTypes.SET_BLOGS,
                payload: { blogs: response.data.items },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchBlogByIdSaga(action: FetchBlogByIdRequest) {
    try {
        const response: AxiosResponse<IBlog> = yield call(
            getBlogById,
            action.payload,
        )

        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'
                let relativeBlog: IBlog = {
                    id: '2',
                    title: '',
                    description: '',
                    publicationDate: new Date(Date.now()),
                    images: [],
                    status: 'Hide',
                    showDatePicker: false,
                    language: oppositeLang,
                    applicationId: action.payload.appId,
                }
                const { relativeId } = response.data
                if (relativeId) {
                    const props = {
                        appId: action.payload.appId,
                        blogId: relativeId,
                    }
                    const relativeResp: AxiosResponse<IBlog> = yield call(
                        getBlogById,
                        props,
                    )
                    if (relativeResp.data) {
                        relativeBlog = relativeResp.data
                    }
                }
                yield put({
                    type: blogTypes.SET_RELATIVE,
                    payload: { relative: relativeBlog },
                })
            }

            if (action.payload.callback) {
                action.payload.callback('success')
            }
            yield put({
                type: blogTypes.SET_BLOG,
                payload: { blog: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createBlogsSaga(action: CreateBlogsRequest) {
    try {
        const response: AxiosResponse<IBlog[]> = yield call(
            createBlogs,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editBlogSaga(action: EditBlogRequest) {
    try {
        const response: AxiosResponse<IBlog> = yield call(
            editBlog,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteBlogSaga(action: EditBlogRequest) {
    try {
        const response: AxiosResponse<IBlog> = yield call(
            deleteBlog,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { blogs } = yield select(state => state.blog)
            const updatedBlogs = blogs.filter(
                (blog: IBlog) => blog.id !== action.payload.blogId,
            )

            yield put({
                type: blogTypes.SET_BLOGS,
                payload: { blogs: updatedBlogs },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* blogSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
    yield all([takeLatest(blogTypes.FETCH_BLOGS_REQUEST, fetchBlogsSaga)])
    yield all([takeLatest(blogTypes.FETCH_BLOG_BY_ID, fetchBlogByIdSaga)])
    yield all([takeLatest(blogTypes.CREATE_BLOGS_REQUEST, createBlogsSaga)])
    yield all([takeLatest(blogTypes.EDIT_BLOG_REQUEST, editBlogSaga)])
    yield all([takeLatest(blogTypes.DELETE_BLOG_REQUEST, deleteBlogSaga)])
}

export default blogSaga
