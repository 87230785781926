import React from 'react'

const ArrowDown = (props: any): React.ReactElement => (
    <svg
        {...props}
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='new-loader--primary-color'
    >
        <path
            d='M11 15.4312C11.2075 15.4312 11.415 15.3481 11.5562 15.1904L17.981 8.60791C18.1221 8.4668 18.2051 8.28418 18.2051 8.07666C18.2051 7.64502 17.8813 7.31299 17.4497 7.31299C17.2422 7.31299 17.0513 7.396 16.9102 7.52881L11 13.5718L5.08154 7.52881C4.94873 7.396 4.75781 7.31299 4.54199 7.31299C4.11035 7.31299 3.78662 7.64502 3.78662 8.07666C3.78662 8.28418 3.86963 8.4668 4.01074 8.61621L10.4355 15.1904C10.5933 15.3481 10.7842 15.4312 11 15.4312Z'
            fill='currentColor'
        />
    </svg>
)

export default ArrowDown
