import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    select,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { IPagination } from 'config/types'
import { eReaderTypes } from './actionTypes'
import {
    getEReaders,
    getEReader,
    createEReader,
    editEReader,
    deleteEReader,
} from '../service/eReader'

import {
    IEReader,
    FetchEReadersRequest,
    FetchEReaderByIdRequest,
    CreateEReadersRequest,
    EditEReaderRequest,
    IEReaderMultiLang,
} from './types'

function* fetchEReadersSaga(action: FetchEReadersRequest) {
    try {
        const response: AxiosResponse<IPagination<IEReader>> = yield call(
            getEReaders,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: eReaderTypes.SET_E_READERS,
                payload: { eReaders: response.data.items },
            })
            action.payload.callback('success', response.data)
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* fetchEReaderByIdSaga(action: FetchEReaderByIdRequest) {
    try {
        const response: AxiosResponse<IEReader> = yield call(
            getEReader,
            action.payload,
        )
        if (response.data) {
            if (action.payload.isEdit) {
                const oppositeLang =
                    response.data.language === 'en' ? 'uk' : 'en'
                let relativeEReader: IEReader = {
                    title: '',
                    fileUrl: '',
                    type: '',
                    size: 0,
                    id: '2',
                    language: oppositeLang,
                }

                if (response.data.relativeId) {
                    const props = {
                        appId: action.payload.appId,
                        eReaderId: response.data.relativeId,
                    }
                    const relativeData: AxiosResponse<IEReader> = yield call(
                        getEReader,
                        props,
                    )
                    if (relativeData.data) {
                        relativeEReader = relativeData.data
                    }
                }

                yield put({
                    type: eReaderTypes.SET_RELATIVE_E_READER,
                    payload: { relativeEReader },
                })
            }
            yield put({
                type: eReaderTypes.SET_E_READER,
                payload: { eReader: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* createEReadersSaga(action: CreateEReadersRequest) {
    try {
        const response: AxiosResponse<IEReaderMultiLang> = yield call(
            createEReader,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* editEReaderSaga(action: EditEReaderRequest) {
    try {
        const response: AxiosResponse<IEReader> = yield call(
            editEReader,
            action.payload,
        )

        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* deleteEReaderSaga(action: EditEReaderRequest) {
    try {
        const response: AxiosResponse<IEReader> = yield call(
            deleteEReader,
            action.payload,
        )
        if (response.status === 200) {
            if (action.payload.callback) {
                action.payload.callback('success')
            }
            const { eReaders } = yield select(state => state.eReader)
            const updatedEReaders = eReaders.filter(
                (eReader: IEReader) => eReader.id !== action.payload.eReaderId,
            )

            yield put({
                type: eReaderTypes.SET_E_READERS,
                payload: { eReaders: updatedEReaders },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* eReaderSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(eReaderTypes.FETCH_E_READERS_REQUEST, fetchEReadersSaga),
    ])
    yield all([
        takeLatest(eReaderTypes.FETCH_E_READER_BY_ID, fetchEReaderByIdSaga),
    ])
    yield all([
        takeLatest(eReaderTypes.CREATE_E_READERS_REQUEST, createEReadersSaga),
    ])
    yield all([takeLatest(eReaderTypes.EDIT_E_READER_REQUEST, editEReaderSaga)])
    yield all([
        takeLatest(eReaderTypes.DELETE_E_READER_REQUEST, deleteEReaderSaga),
    ])
}

export default eReaderSaga
