import { PATH_NEW_LOADER } from 'config/paths'

// TODO translate
export const data = [
    {
        title: 'Gallery',
        url: PATH_NEW_LOADER.pages.gallery,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'To Do',
        url: PATH_NEW_LOADER.pages.todo.root,
        isHeaderVisible: true,
        isStatusVisible: true,
        isArchiveVisible: true,
    },
    {
        title: 'To Do Archive',
        url: PATH_NEW_LOADER.pages.todo.archive,
        isHeaderVisible: true,
        isStatusVisible: true,
        isArchiveVisible: true,
    },
    {
        title: 'Chat',
        url: PATH_NEW_LOADER.pages.chat,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:cloud',
        url: PATH_NEW_LOADER.pages.cloud,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'Video Conference',
        url: PATH_NEW_LOADER.pages.videoConference,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'SMM',
        url: PATH_NEW_LOADER.pages.smm,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:blog',
        url: PATH_NEW_LOADER.pages.blog,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:services',
        url: PATH_NEW_LOADER.pages.services,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:employee',
        url: PATH_NEW_LOADER.pages.employee,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:booking',
        url: PATH_NEW_LOADER.pages.booking,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:calendar',
        url: PATH_NEW_LOADER.pages.calendar,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:video',
        url: PATH_NEW_LOADER.pages.video,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:about',
        url: PATH_NEW_LOADER.pages.about,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:map',
        url: PATH_NEW_LOADER.pages.map,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:eReader',
        url: PATH_NEW_LOADER.pages.eReader,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'Chat Premium',
        url: PATH_NEW_LOADER.pages.chatPremium,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:news',
        url: PATH_NEW_LOADER.pages.news,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
    {
        title: 'emulator:header:payments',
        url: PATH_NEW_LOADER.pages.payments,
        isHeaderVisible: true,
        isStatusVisible: true,
    },
]
