import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { APPLICATION_URLS } from 'config/urls'

import { IPalette } from '../store/types'

export const getTemplates = (): Promise<AxiosResponse<IPalette[]>> =>
    axiosInstance.get(`${APPLICATION_URLS.applications}/colors`)

export const getChosenPalette = ({
    appId,
}: {
    appId: string
}): Promise<AxiosResponse<IPalette>> =>
    axiosInstance.get(`${APPLICATION_URLS.applications}/${appId}/colors`)

export const editChosenPalette = ({
    appId,
    palette,
}: {
    appId: string
    palette: IPalette
}): Promise<AxiosResponse<IPalette>> =>
    axiosInstance.put(
        `${APPLICATION_URLS.applications}/${appId}/colors`,
        palette,
    )
