import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { CALENDAR_URLS } from 'config/urls'

import { IEvent, IEventMultiLang, IProposedEvent } from '../store/types'

export const getEvents = ({
    language,
    appId,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<IEvent[]>> =>
    axiosInstance.get(`${appId}${CALENDAR_URLS.events}/dates/${language}`)
// axiosInstance.get(`${appId}${CALENDAR_URLS.events}/dates`)

export const getEventsByDate = ({
    language,
    appId,
    eventDate,
    status,
}: {
    appId: string
    eventDate: string | Date
    status: string
    language: string
}): Promise<AxiosResponse<IEvent[]>> =>
    axiosInstance.get(
        // eslint-disable-next-line max-len
        `${appId}${CALENDAR_URLS.events}/${language}/${eventDate}?status=${status}`,
    )
// axiosInstance.get(
// `${appId}${CALENDAR_URLS.events}/${eventDate}?status=${status}`,
// )

export const getDatesByYearMonth = ({
    appId,
    year,
    month,
}: {
    appId: string
    year: string
    month: string
}): Promise<AxiosResponse<IEvent[]>> =>
    axiosInstance.get(`${appId}${CALENDAR_URLS.events}/${year}/${month}`)
// axiosInstance.get(`${appId}${CALENDAR_URLS.events}/${year}/${month}`)

export const getEventById = ({
    eventId,
    appId,
}: {
    eventId: string
    appId?: string
}): Promise<AxiosResponse<IEvent>> =>
    // eslint-disable-next-line max-len
    axiosInstance.get(`${appId}${CALENDAR_URLS.events}/${eventId}`)
// axiosInstance.get(`${appId}${CALENDAR_URLS.events}/${eventId}`)

export const createEvent = ({
    event,
    appId,
}: {
    event: IEventMultiLang[]
    appId?: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.post(`${appId}${CALENDAR_URLS.events}`, event)

export const editEvent = ({
    language,
    eventId,
    event,
    appId,
}: {
    eventId: string
    event: IEvent
    appId?: string
    language: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.put(
        `${appId}${CALENDAR_URLS.events}/${language}/${eventId}`,
        event,
    )
// axiosInstance.put(`${appId}${CALENDAR_URLS.events}/${eventId}`, event)

export const deleteEvent = ({
    event,
    appId,
    language,
}: {
    event: IEvent
    appId?: string
    language: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.delete(
        `${appId}${CALENDAR_URLS.events}/${language}/${event.id}`,
    )

export const getUnconfirmedEvents = ({
    language,
    appId,
}: {
    appId?: string
    language: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.get(
        `${appId}${CALENDAR_URLS.events}/${language}/unconfirmed/count`,
    )
// axiosInstance.get(`${appId}${CALENDAR_URLS.events}/unconfirmed/count`)

export const getAllEvents = ({
    appId,
    status,
}: {
    appId?: string
    status: string
}): Promise<AxiosResponse<IProposedEvent[]>> =>
    axiosInstance.get(`${appId}${CALENDAR_URLS.events}?status=${status}`)
// axiosInstance.get(`${appId}${CALENDAR_URLS.events}/${language}?status=${status}`)

export const acceptEvent = ({
    language,
    eventId,
    appId,
}: {
    eventId?: string
    appId?: string
    language: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.put(
        `${appId}${CALENDAR_URLS.events}/${language}/${eventId}/confirm`,
    )
// axiosInstance.put(`${appId}${CALENDAR_URLS.events}/${eventId}/confirm`)

export const rejectEvent = ({
    language,
    eventId,
    appId,
}: {
    eventId?: string
    appId?: string
    language: string
}): Promise<AxiosResponse<IEvent>> =>
    axiosInstance.put(
        `${appId}${CALENDAR_URLS.events}/${language}/${eventId}/decline`,
    )
// axiosInstance.put(`${appId}${CALENDAR_URLS.events}/${eventId}/decline`)
