import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

type JsonPrimitive = null | string | number | boolean

type JsonSerializable =
    | JsonPrimitive
    | JsonSerializable[]
    | {
          [key: string]: JsonSerializable
      }

export const useLocalStorageBoolean = <T = JsonSerializable>(
    key: string,
    initialDefault: T,
): [T, Dispatch<SetStateAction<T>>] => {
    const [val, setVal] = useState<T>(() => {
        const localStorageVal = localStorage.getItem(key)

        return localStorageVal !== null
            ? JSON.parse(localStorageVal)
            : initialDefault
    })

    useEffect(() => {
        if (localStorage.getItem(key) === null) {
            setVal(initialDefault)
        }
    }, [key, initialDefault])

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(val))
    }, [val, key])

    return [val, setVal] as [T, React.Dispatch<React.SetStateAction<T>>]
}
