import { testAnalyticsTypes } from './actionTypes'
import { TestAnalyticsActions, TestAnalyticsState } from './types'

const initialState: TestAnalyticsState = {
    testAnalytics: undefined,
}

export default (
    state = initialState,
    action: TestAnalyticsActions,
): TestAnalyticsState => {
    switch (action.type) {
        case testAnalyticsTypes.SET_TEST_ANALYTICS:
            return {
                ...state,
                testAnalytics: action.payload.testAnalytics,
            }
        default:
            return {
                ...state,
            }
    }
}
