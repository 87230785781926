import { launchScreenTypes } from './actionTypes'
import {
    FetchLaunchScreenRequest,
    FetchLaunchScreenPayload,
    EditLaunchScreenPayload,
    EditLaunchScreenRequest,
    SetLaunchScreen,
    ILaunchScreen,
} from './types'

export const fetchLaunchScreenAction = (
    payload: FetchLaunchScreenPayload,
): FetchLaunchScreenRequest => ({
    type: launchScreenTypes.FETCH_LAUNCH_SCREEN_REQUEST,
    payload,
})

export const editLaunchScreenAction = (
    payload: EditLaunchScreenPayload,
): EditLaunchScreenRequest => ({
    type: launchScreenTypes.EDIT_LAUNCH_SCREEN_REQUEST,
    payload,
})

export const SetLaunchScreenAction = (payload: {
    settings: ILaunchScreen
}): SetLaunchScreen => ({
    type: launchScreenTypes.SET_LAUNCH_SCREEN,
    payload,
})
