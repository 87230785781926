export enum mapTypes {
    FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST',
    FETCH_LOCATION_BY_ID = 'FETCH_LOCATION_BY_ID',
    CREATE_LOCATIONS_REQUEST = 'CREATE_LOCATIONS_REQUEST',
    EDIT_LOCATION_REQUEST = 'EDIT_LOCATION_REQUEST',
    DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST',
    SET_LOCATION = 'SET_LOCATION',
    SET_LOCATIONS = 'SET_LOCATIONS',
    SET_RELATIVE_LOCATION = 'SET_RELATIVE_LOCATION',
}
