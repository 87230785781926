import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { testSummaryTypes } from './actionTypes'
import { getTestSummary } from '../service/testSummary'

import { ITestSummary, FetchTestSummaryRequest } from './types'

function* fetchTestSummarySaga(action: FetchTestSummaryRequest) {
    try {
        const response: AxiosResponse<ITestSummary[]> = yield call(
            getTestSummary,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: testSummaryTypes.SET_TEST_SUMMARY,
                payload: { testSummary: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* surveySummarySaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            testSummaryTypes.FETCH_TEST_SUMMARY_REQUEST,
            fetchTestSummarySaga,
        ),
    ])
}

export default surveySummarySaga
