/* eslint-disable max-len */
import { combineReducers } from 'redux'
import applicationsReducer from 'containers/app-list/store/reducer'
import calendarReducer from 'containers/dashboard/features/calendar/store/reducer'
import smmReducer from 'containers/dashboard/features/cloud/components/tabs/smm/store/reducer'
import cloudReducer from 'containers/dashboard/features/cloud/components/tabs/cloud/store/reducer'
import chatReducer from 'containers/dashboard/features/cloud/components/tabs/chat/store/reducer'
import mapReducer from 'containers/dashboard/features/map/store/reducer'
import blogReducer from 'containers/dashboard/features/blog/store/reducer'
import bookingReducer from 'containers/dashboard/features/booking/store/reducer'
import employeeReducer from 'containers/dashboard/features/employees/store/reducer'
import servicesReducer from 'containers/dashboard/features/services/store/reducer'
import informationReducer from 'containers/dashboard/features/about/store/reducer'
import todoPremiumReducer from 'containers/dashboard/features/premium/todo/store/reducer'
import generalSettingsReducer from 'containers/dashboard/settings/app-settings/store/reducer'
import accountSettingsReducer from 'containers/account/store/reducer'
import launchScreenReducer from 'containers/dashboard/design/welcome-screen/store/reducer'
import designLogoReducer from 'containers/dashboard/design/icon/store/reducer'
import paletteReducer from 'containers/dashboard/design/color-palette/store/reducer'
import billingReducer from 'containers/billing-information/store/reducer'
import eReaderReducer from 'containers/dashboard/features/e-reader/store/reducer'
import conferenceReducer from 'containers/dashboard/features/cloud/components/tabs/video-conference/store/reducer'
import galleryReducer from 'containers/dashboard/features/gallery/store/reducer'
import videoReducer from 'containers/dashboard/features/cloud/components/tabs/video/store/reducer'
import paymentLinksReducer from 'containers/dashboard/features/billing/store/reducer'
import forumThemeReducer from 'containers/dashboard/features/premium/forum/forum-themes/store/reducer'
import surveyReducer from 'containers/dashboard/features/premium/survey/store/reducer'
import surveyUsersReducer from 'containers/dashboard/features/premium/survey/survey-users/store/reducer'
import testAnalyticsReducer from 'containers/dashboard/features/premium/survey/test-analytics/store/reducer'
import userReducer from 'containers/dashboard/features/premium/forum/forum-users/store/reducer'
import testingReducer from 'containers/dashboard/testing/store/reducer'
import surveySummaryReducer from 'containers/dashboard/features/premium/survey/test-summary/store/reducer'
import brandingReducer from 'containers/dashboard/app-name/store/reducer'
import featuresReducer from 'containers/dashboard/settings/features-activation/store/reducer'
import userRolesReducer from 'containers/dashboard/settings/user-roles/store/reducer'
import pusNotificationsReducer from 'containers/dashboard/settings/push-notifications/store/reducer'
import menuReducer from 'layouts/dashboard/store/reducer'
import notificationsReducer from 'layouts/header-navigation/store/reducer'
import aiReducer from 'components/ai/store/reducer'
import aiPicturesReducer from 'components/ai-pictures/store/reducer'
import paymentReducer from 'containers/dashboard/features/payments/store/reducer'

const rootReducer = combineReducers({
    applications: applicationsReducer,
    calendar: calendarReducer,
    smm: smmReducer,
    cloud: cloudReducer,
    chat: chatReducer,
    map: mapReducer,
    blog: blogReducer,
    paymentLinks: paymentLinksReducer,
    employees: employeeReducer,
    booking: bookingReducer,
    services: servicesReducer,
    information: informationReducer,
    todoPremium: todoPremiumReducer,
    generalSettings: generalSettingsReducer,
    accountSettings: accountSettingsReducer,
    launchScreen: launchScreenReducer,
    designLogo: designLogoReducer,
    palette: paletteReducer,
    billing: billingReducer,
    eReader: eReaderReducer,
    conference: conferenceReducer,
    gallery: galleryReducer,
    video: videoReducer,
    themes: forumThemeReducer,
    survey: surveyReducer,
    surveyUsers: surveyUsersReducer,
    testAnalytics: testAnalyticsReducer,
    user: userReducer,
    test: testingReducer,
    surveySummary: surveySummaryReducer,
    branding: brandingReducer,
    features: featuresReducer,
    userRoles: userRolesReducer,
    pushes: pusNotificationsReducer,
    menus: menuReducer,
    notifications: notificationsReducer,
    ai: aiReducer,
    payments: paymentReducer,
    aiPictures: aiPicturesReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
