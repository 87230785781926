import { brandingTypes } from './actionTypes'
import { BrandingActions, BrandingState } from './types'

const initialState: BrandingState = {
    branding: undefined,
}

export default (
    state = initialState,
    action: BrandingActions,
): BrandingState => {
    switch (action.type) {
        case brandingTypes.SET_BRANDING:
            return {
                ...state,
                branding: { ...action.payload.branding },
            }
        default:
            return {
                ...state,
            }
    }
}
