import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { IMAGE_UPLOAD } from 'config/urls'

export type TQuery = {
    entityId?: string
    type: string
}

export const getImageUrl = ({
    query,
    data,
}: {
    query: TQuery
    data: FormData
}): Promise<AxiosResponse<string>> =>
    axiosInstance.post(
        `${IMAGE_UPLOAD.main}?entityId=${query.entityId}&type=${query.type}`,
        data,
    )
