import { paletteTypes } from './actionTypes'
import { PaletteActions, PaletteState } from './types'

const initialState: PaletteState = {
    palette: undefined,
    templates: [],
}

export default (state = initialState, action: PaletteActions): PaletteState => {
    switch (action.type) {
        case paletteTypes.SET_PALETTE:
            return {
                ...state,
                palette: action.payload.palette
                    ? { ...action.payload.palette }
                    : undefined,
            }
        case paletteTypes.SET_TEMPLATES:
            return {
                ...state,
                templates: action.payload.templates,
            }
        default:
            return {
                ...state,
            }
    }
}
