import axios from 'axios'
import { axiosInstance } from 'axiosInstance'
import { USER_URLS } from 'config/urls'
import { removeUser } from 'utils/token'

// TODO
// any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = (): any => {
    removeUser()
}
// TODO
// any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const login = (formData: URLSearchParams): any =>
    axios.post(`${process.env.REACT_APP_LOGIN_URL}/connect/token`, formData)

export const register = (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    // TODO
    // any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
    axiosInstance.post('/sign-up', {
        firstName,
        lastName,
        email,
        password,
    })

export const confirmEmail = (
    email: string,
    validationToken: string,
    // TODO
    // any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => axiosInstance.put(`/confirm-email/${email}/${validationToken}`)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createConfirmEmailLink = (email?: string): any =>
    axiosInstance.put(`/user/resend-confirm-email?email=${email}`)

export const resetPasswordByEmail = (
    email: string,
    // TODO
    // any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => axiosInstance.put(`/user/reset-pwd/${email}`)

export const resetPassword = (
    email: string,
    validationToken: string,
    formData: URLSearchParams,
    // TODO
    // any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
    axiosInstance.put(
        `${USER_URLS.recoveryPassword}/${email}/${validationToken}`,
        formData,
    )

export const industryChoose = (
    industry: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => axiosInstance.put(`/user/industry?userIndustry=${industry}`)
