import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { PATH_BILLING } from 'config/paths'

import { RootState } from 'redux/reducers/rootReducer'
import AiMinion from 'assets/images/minions/AiMinion.webp'
import Coin from 'assets/images/Coin.png'

import CustomButton from 'components/custom-button/CustomButton'
import CustomImage from 'components/custom-image/CustomImage'
import Popup from 'components/popup/Popup'

import { SetAiPopupAction } from 'layouts/dashboard/store/actions'

import './AiPopup.scss'

const AiPopup = (): React.ReactElement => {
    const { t } = useTranslation(['popups'])

    const dispatch = useDispatch()
    const { push } = useHistory()

    const { showAiPopup } = useSelector((state: RootState) => state.menus)

    const handleShowPopup = (showPopup: boolean) => {
        dispatch(
            SetAiPopupAction({
                isOpen: showPopup,
            }),
        )
    }

    return (
        <Popup
            showPopup={showAiPopup}
            setShowPopup={handleShowPopup}
            topPart={
                <div className='ai-popup__image'>
                    <CustomImage src={AiMinion} />
                </div>
            }
            topDesign='straight'
            bodyPart={
                <>
                    <p className='ai-popup__info'>
                        {t('popups:aiPopup:description')}
                    </p>
                    <div className='ai-popup__column'>
                        <div className='ai-popup__title'>
                            {t('popups:aiPopup:textGeneration')}
                        </div>
                        <ul className='ai-popup__list'>
                            <li>
                                <div>
                                    {t('popups:aiPopup:sizeS')}{' '}
                                    <CustomImage
                                        src={Coin}
                                        className='ai-popup__coin'
                                    />{' '}
                                    {t('popups:aiPopup:credits', { amount: 3 })}
                                </div>
                            </li>
                            <li>
                                <div>
                                    {t('popups:aiPopup:sizeM')}{' '}
                                    <CustomImage
                                        src={Coin}
                                        className='ai-popup__coin'
                                    />{' '}
                                    {t('popups:aiPopup:credits', { amount: 6 })}
                                </div>
                            </li>
                        </ul>
                    </div>
                </>
            }
            buttons={[
                <CustomButton
                    type='button'
                    size='large'
                    design='cancel'
                    handleClick={() => handleShowPopup(false)}
                >
                    {t('popups:aiPopup:cancel')}
                </CustomButton>,
                <CustomButton
                    type='button'
                    size='large'
                    design='secondary'
                    handleClick={() => {
                        push(PATH_BILLING.root)
                        handleShowPopup(false)
                    }}
                >
                    {t('popups:aiPopup:buy')}
                </CustomButton>,
            ]}
        />
    )
}

export default AiPopup
