import React from 'react'

const ToArchiveIcon = ({ fill }: { fill?: string }): React.ReactElement => (
    <svg
        width='28'
        height='28'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11.9583 12.8333C11.4751 12.8333 11.0833 13.2251 11.0833 13.7083C11.0833 14.1916 11.4751 14.5833 11.9583 14.5833H16.0417C16.5249 14.5833 16.9167 14.1916 16.9167 13.7083C16.9167 13.2251 16.5249 12.8333 16.0417 12.8333H11.9583ZM3.5 6.125C3.5 4.67525 4.67525 3.5 6.125 3.5H21.875C23.3247 3.5 24.5 4.67525 24.5 6.125V7.875C24.5 8.78512 24.0368 9.58706 23.3333 10.058V20.125C23.3333 22.5413 21.3746 24.5 18.9583 24.5H9.04167C6.62542 24.5 4.66667 22.5413 4.66667 20.125V10.058C3.96318 9.58706 3.5 8.78512 3.5 7.875V6.125ZM6.41667 10.5V20.125C6.41667 21.5747 7.59192 22.75 9.04167 22.75H18.9583C20.408 22.75 21.5833 21.5747 21.5833 20.125V10.5H6.41667ZM6.125 5.25C5.64175 5.25 5.25 5.64175 5.25 6.125V7.875C5.25 8.35824 5.64175 8.75 6.125 8.75H21.875C22.3582 8.75 22.75 8.35824 22.75 7.875V6.125C22.75 5.64175 22.3582 5.25 21.875 5.25H6.125Z'
            fill={fill || 'white'}
        />
    </svg>
)

export default ToArchiveIcon
