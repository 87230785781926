import React from 'react'

export const OwnDesignIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 41.3335C10.0011 42.3056 10.382 43.2375 11.0589 43.9249C11.7359 44.6123 12.6537 44.999 13.6111 45.0002H32.3889C33.3463 44.999 34.2641 44.6123 34.9411 43.9249C35.618 43.2375 35.9989 42.3056 36 41.3335V8.87847C36 8.4796 35.6766 8.15625 35.2778 8.15625C34.8789 8.15625 34.5556 8.4796 34.5556 8.87847V30.3334H19.7399H11.4444V8.87847C11.4444 8.4796 11.1211 8.15625 10.7222 8.15625C10.3234 8.15625 10 8.4796 10 8.87847V41.3335ZM13.6111 43.5335H32.3889C32.9635 43.5335 33.5146 43.3017 33.921 42.8891C34.3273 42.4765 34.5556 41.917 34.5556 41.3335V31.8001H18.8111H11.4444V41.3335C11.4444 41.917 11.6727 42.4765 12.079 42.8891C12.4854 43.3017 13.0365 43.5335 13.6111 43.5335Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M11.4444 30.3334H19.7399H34.5556M11.4444 30.3334V8.87847C11.4444 8.4796 11.1211 8.15625 10.7222 8.15625V8.15625C10.3234 8.15625 10 8.4796 10 8.87847V41.3335C10.0011 42.3056 10.382 43.2375 11.0589 43.9249C11.7359 44.6123 12.6537 44.999 13.6111 45.0002H32.3889C33.3463 44.999 34.2641 44.6123 34.9411 43.9249C35.618 43.2375 35.9989 42.3056 36 41.3335V8.87847C36 8.4796 35.6766 8.15625 35.2778 8.15625V8.15625C34.8789 8.15625 34.5556 8.4796 34.5556 8.87847V30.3334M11.4444 30.3334V31.8001M34.5556 31.8001V41.3335C34.5556 41.917 34.3273 42.4765 33.921 42.8891C33.5146 43.3017 32.9635 43.5335 32.3889 43.5335H13.6111C13.0365 43.5335 12.4854 43.3017 12.079 42.8891C11.6727 42.4765 11.4444 41.917 11.4444 41.3335V31.8001M34.5556 31.8001H18.8111H11.4444M34.5556 31.8001V30.3334'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='0.6'
        />
        <path
            d='M28.0556 40.6001H17.9445C17.7529 40.6001 17.5692 40.6774 17.4338 40.8149C17.2983 40.9524 17.2222 41.1389 17.2222 41.3334C17.2222 41.5279 17.2983 41.7145 17.4338 41.852C17.5692 41.9895 17.7529 42.0668 17.9445 42.0668H28.0556C28.2471 42.0668 28.4308 41.9895 28.5663 41.852C28.7017 41.7145 28.7778 41.5279 28.7778 41.3334C28.7778 41.1389 28.7017 40.9524 28.5663 40.8149C28.4308 40.6774 28.2471 40.6001 28.0556 40.6001Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M32.3889 1H13.6111C12.6537 1.00116 11.7359 1.38785 11.0589 2.07523C10.382 2.76262 10.0011 3.69457 10 4.66668L10 9.27778C10 9.67665 10.3234 10 10.7222 10C11.1211 10 11.4444 9.67665 11.4444 9.27778V4.66668C11.4444 4.0832 11.6727 3.52362 12.079 3.11104C12.4854 2.69846 13.0365 2.46667 13.6111 2.46667H16.4889C16.8939 2.46667 17.2222 2.795 17.2222 3.20001C17.2222 3.78349 17.4505 4.34307 17.8568 4.75565C18.2632 5.16823 18.8143 5.40002 19.3889 5.40002H26.6111C27.1857 5.40002 27.7368 5.16823 28.1432 4.75565C28.5495 4.34307 28.7778 3.78349 28.7778 3.20001C28.7778 2.795 29.1061 2.46667 29.5111 2.46667H32.3889C32.9635 2.46667 33.5146 2.69846 33.921 3.11104C34.3273 3.52362 34.5556 4.0832 34.5556 4.66668V9.27778C34.5556 9.67665 34.8789 10 35.2778 10C35.6767 10 36 9.67665 36 9.27778V4.66668C35.9989 3.69457 35.618 2.76262 34.9411 2.07523C34.2641 1.38785 33.3463 1.00116 32.3889 1ZM27.3333 3.20001C27.3333 2.795 27.005 2.46667 26.6 2.46667H19.4C18.995 2.46667 18.6667 2.795 18.6667 3.20001C18.6667 3.3945 18.7428 3.58103 18.8782 3.71855C19.0136 3.85608 19.1973 3.93334 19.3889 3.93334H26.6111C26.8027 3.93334 26.9864 3.85608 27.1218 3.71855C27.2572 3.58103 27.3333 3.3945 27.3333 3.20001Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='0.6'
        />
        <g clipPath='url(#clip0_5225_63033)'>
            <path
                d='M18.4883 15.5742L19.3789 17.3555H21.0664V10.9102H19.7773V15.2696L18.4883 12.6914L17.1992 15.2696V10.9102H15.9102V17.3555H17.5976L18.4883 15.5742Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M24.9336 16.0664H23V14.7773H24.2891V13.4883H23V12.1992H24.9336V10.9102H21.7109C21.7109 11.4049 21.7109 17.6781 21.7109 17.3555H24.9336V16.0664Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M25.5781 10.9102V17.3555H28.8008V16.0664H26.8672V10.9102H25.5781Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M15.9102 19.9336C16.2658 19.9336 16.5547 20.2225 16.5547 20.5781H17.8438C17.8438 19.5119 16.9764 18.6445 15.9102 18.6445C14.8439 18.6445 13.9766 19.5119 13.9766 20.5781V23.1562C13.9766 24.2225 14.8439 25.0898 15.9102 25.0898C16.9764 25.0898 17.8438 24.2225 17.8438 23.1562H16.5547C16.5547 23.5119 16.2658 23.8008 15.9102 23.8008C15.5545 23.8008 15.2656 23.5119 15.2656 23.1562V20.5781C15.2656 20.2225 15.5545 19.9336 15.9102 19.9336Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M20.4219 18.6445C19.3556 18.6445 18.4883 19.5119 18.4883 20.5781V23.1562C18.4883 24.2225 19.3556 25.0898 20.4219 25.0898C21.4881 25.0898 22.3555 24.2225 22.3555 23.1562V20.5781C22.3555 19.5119 21.4881 18.6445 20.4219 18.6445ZM21.0664 23.1562C21.0664 23.5119 20.7775 23.8008 20.4219 23.8008C20.0663 23.8008 19.7773 23.5119 19.7773 23.1562V20.5781C19.7773 20.2225 20.0663 19.9336 20.4219 19.9336C20.7775 19.9336 21.0664 20.2225 21.0664 20.5781V23.1562Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M25.5781 20.4258L24.6875 18.6445H23V25.0898H24.2891V20.7304L25.5781 23.3086L26.8672 20.7304V25.0898H28.1562V18.6445H26.4688L25.5781 20.4258Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M32.0234 23.8008H30.0898V22.5117H31.3789V21.2227H30.0898V19.9336H32.0234V18.6445H28.8008C28.8008 19.1393 28.8008 25.4125 28.8008 25.0898H32.0234V23.8008Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M28.8008 13.4883H31.3789V14.7773H28.8008V13.4883Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M11.9999 7.91162L12.9112 7.00035L15.2096 9.29881L14.2983 10.2101L11.9999 7.91162Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M30.7876 9.30078L33.0861 7.00231L33.9973 7.91359L31.6989 10.2121L30.7876 9.30078Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M11.9979 28.0864L14.2963 25.788L15.2076 26.6992L12.9091 28.9977L11.9979 28.0864Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M30.7856 26.7012L31.6969 25.7899L33.9954 28.0884L33.0841 28.9996L30.7856 26.7012Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M12.6445 19.9336C13.0005 19.9336 13.2891 19.645 13.2891 19.2891C13.2891 18.9331 13.0005 18.6445 12.6445 18.6445C12.2886 18.6445 12 18.9331 12 19.2891C12 19.645 12.2886 19.9336 12.6445 19.9336Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M25.5781 8.28906C25.9341 8.28906 26.2227 8.0005 26.2227 7.64453C26.2227 7.28857 25.9341 7 25.5781 7C25.2222 7 24.9336 7.28857 24.9336 7.64453C24.9336 8.0005 25.2222 8.28906 25.5781 8.28906Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M20.4219 29C20.7778 29 21.0664 28.7114 21.0664 28.3555C21.0664 27.9995 20.7778 27.7109 20.4219 27.7109C20.0659 27.7109 19.7773 27.9995 19.7773 28.3555C19.7773 28.7114 20.0659 29 20.4219 29Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
            <path
                d='M33.3555 17.3555C33.7114 17.3555 34 17.0669 34 16.7109C34 16.355 33.7114 16.0664 33.3555 16.0664C32.9995 16.0664 32.7109 16.355 32.7109 16.7109C32.7109 17.0669 32.9995 17.3555 33.3555 17.3555Z'
                fill={isActive ? '#FFED00' : '#2B2A29'}
            />
        </g>
        <defs>
            <clipPath id='clip0_5225_63033'>
                <rect
                    width='22'
                    height='22'
                    fill='white'
                    transform='translate(12 7)'
                />
            </clipPath>
        </defs>
    </svg>
)

export default OwnDesignIcon
