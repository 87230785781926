import React from 'react'

import CustomImage from 'components/custom-image/CustomImage'

import NoNotif from 'assets/images/dashboard/header-navigation/NoNotif.png'

import './EmptyNotification.scss'
import { useTranslation } from 'react-i18next'

const EmptyNotification = (): React.ReactElement => {
    const { t } = useTranslation('notifications')
    return (
        <div className='empty-notification'>
            <CustomImage src={NoNotif} />
            <p className='empty-notification__message'>
                {t('notifications:emptyNotifications')}
            </p>
        </div>
    )
}

export default EmptyNotification
