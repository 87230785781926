import { AxiosResponse } from 'axios'
import { axiosInstance } from 'axiosInstance'

import { TODO_PREMIUM_URLS } from 'config/urls'

import { ITodoPremium } from '../store/types'

export const getTodoPremiumSettings = ({
    appId,
    language,
}: {
    appId: string
    language: string
}): Promise<AxiosResponse<ITodoPremium>> =>
    axiosInstance.get(`${appId}${TODO_PREMIUM_URLS.main}/${language}`)

export const editTodoPremiums = ({
    appId,
    settings,
    language,
}: {
    appId: string
    settings: ITodoPremium
    language: string
}): Promise<AxiosResponse<ITodoPremium>> =>
    axiosInstance.post(
        `${appId}${TODO_PREMIUM_URLS.main}/${language}`,
        settings,
    )
