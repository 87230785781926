import { lazy } from 'react'

import { PATH_DESIGN } from 'config/paths'

export const DesignRoutes = [
    {
        id: 1,
        module: 'design',
        protected: true,
        exact: true,
        path: PATH_DESIGN.launchScreen,
        component: lazy(
            () =>
                import(
                    'containers/dashboard/design/welcome-screen/WelcomeScreen'
                ),
        ),
    },
    {
        id: 2,
        module: 'design',
        protected: true,
        exact: true,
        path: PATH_DESIGN.background,
        component: lazy(
            () => import('containers/dashboard/design/own-design/OwnDesign'),
        ),
    },
    {
        id: 3,
        module: 'design',
        protected: true,
        exact: true,
        path: PATH_DESIGN.icon,
        component: lazy(() => import('containers/dashboard/design/icon/Icon')),
    },
    {
        id: 4,
        module: 'design',
        protected: true,
        exact: true,
        path: PATH_DESIGN.colorPalette,
        component: lazy(
            () =>
                import(
                    'containers/dashboard/design/color-palette/ColorPalette'
                ),
        ),
    },
]
