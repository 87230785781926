import React from 'react'
import HeaderNavigation from 'layouts/header-navigation/HeaderNavigation'

const Placement = ({ children }: { children: React.ReactNode }) => (
    <>
        <HeaderNavigation />
        <div className='placement'>{children}</div>
    </>
)
export default Placement
