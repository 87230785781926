import React from 'react'

export const TestingIcon = ({
    isActive,
    isSmall,
    isMedium,
}: {
    isActive?: boolean
    isSmall?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.7334 1H4.66668C3.69457 1.00116 2.76262 1.38785 2.07523 2.07523C1.38785 2.76262 1.00116 3.69457 1 4.66668L1 41.3335C1.00116 42.3056 1.38785 43.2375 2.07523 43.9249C2.76262 44.6123 3.69457 44.999 4.66668 45.0002H23.7334C24.7055 44.999 25.6375 44.6123 26.3249 43.9249C27.0122 43.2375 27.3989 42.3056 27.4001 41.3335V31.8001H25.9334V41.3335C25.9334 41.917 25.7016 42.4765 25.2891 42.8891C24.8765 43.3017 24.3169 43.5335 23.7334 43.5335H4.66668C4.0832 43.5335 3.52362 43.3017 3.11104 42.8891C2.69846 42.4765 2.46667 41.917 2.46667 41.3335V31.8001H9.9467H21.595L19 30.3334H10.8898H2.46667V4.66668C2.46667 4.0832 2.69846 3.52362 3.11104 3.11104C3.52362 2.69846 4.0832 2.46667 4.66668 2.46667H8.33336V3.20001C8.33336 3.78349 8.56514 4.34307 8.97773 4.75565C9.39031 5.16823 9.94989 5.40002 10.5334 5.40002H17.8667C18.4502 5.40002 19.0098 5.16823 19.4224 4.75565C19.8349 4.34307 20.0667 3.78349 20.0667 3.20001V2.46667H23.7334C24.3169 2.46667 24.8765 2.69846 25.2891 3.11104C25.7016 3.52362 25.9334 4.0832 25.9334 4.66668V10H27.4001V4.66668C27.3989 3.69457 27.0122 2.76262 26.3249 2.07523C25.6375 1.38785 24.7055 1.00116 23.7334 1ZM18.6001 2.46667H9.80003V3.20001C9.80003 3.3945 9.87729 3.58103 10.0148 3.71855C10.1523 3.85608 10.3389 3.93334 10.5334 3.93334H17.8667C18.0612 3.93334 18.2477 3.85608 18.3853 3.71855C18.5228 3.58103 18.6001 3.3945 18.6001 3.20001V2.46667Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M21.595 31.8001H25.9334M21.595 31.8001H9.9467H2.46667V41.3335C2.46667 41.917 2.69846 42.4765 3.11104 42.8891C3.52362 43.3017 4.0832 43.5335 4.66668 43.5335H23.7334C24.3169 43.5335 24.8765 43.3017 25.2891 42.8891C25.7016 42.4765 25.9334 41.917 25.9334 41.3335V31.8001M21.595 31.8001L19 30.3334H10.8898H2.46667V4.66668C2.46667 4.0832 2.69846 3.52362 3.11104 3.11104C3.52362 2.69846 4.0832 2.46667 4.66668 2.46667H8.33336V3.20001C8.33336 3.78349 8.56514 4.34307 8.97773 4.75565C9.39031 5.16823 9.94989 5.40002 10.5334 5.40002H17.8667C18.4502 5.40002 19.0098 5.16823 19.4224 4.75565C19.8349 4.34307 20.0667 3.78349 20.0667 3.20001V2.46667H23.7334C24.3169 2.46667 24.8765 2.69846 25.2891 3.11104C25.7016 3.52362 25.9334 4.0832 25.9334 4.66668V10H27.4001V4.66668C27.3989 3.69457 27.0122 2.76262 26.3249 2.07523C25.6375 1.38785 24.7055 1.00116 23.7334 1H4.66668C3.69457 1.00116 2.76262 1.38785 2.07523 2.07523C1.38785 2.76262 1.00116 3.69457 1 4.66668L1 41.3335C1.00116 42.3056 1.38785 43.2375 2.07523 43.9249C2.76262 44.6123 3.69457 44.999 4.66668 45.0002H23.7334C24.7055 44.999 25.6375 44.6123 26.3249 43.9249C27.0122 43.2375 27.3989 42.3056 27.4001 41.3335V31.8001H25.9334M9.80003 2.46667H18.6001V3.20001C18.6001 3.3945 18.5228 3.58103 18.3853 3.71855C18.2477 3.85608 18.0612 3.93334 17.8667 3.93334H10.5334C10.3389 3.93334 10.1523 3.85608 10.0148 3.71855C9.87729 3.58103 9.80003 3.3945 9.80003 3.20001V2.46667Z'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='0.6'
        />
        <path
            d='M19.3334 40.6001H9.06668C8.87219 40.6001 8.68566 40.6774 8.54813 40.8149C8.41061 40.9524 8.33334 41.1389 8.33334 41.3334C8.33334 41.5279 8.41061 41.7145 8.54813 41.852C8.68566 41.9895 8.87219 42.0668 9.06668 42.0668H19.3334C19.5279 42.0668 19.7144 41.9895 19.8519 41.852C19.9895 41.7145 20.0667 41.5279 20.0667 41.3334C20.0667 41.1389 19.9895 40.9524 19.8519 40.8149C19.7144 40.6774 19.5279 40.6001 19.3334 40.6001Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
        <path
            d='M21.2447 21.8297L24.2976 24.803C24.4451 24.9471 24.6411 25.017 24.8353 24.9965C25.0307 24.9761 25.21 24.8672 25.3313 24.6952L29.8471 18.2898C30.0913 17.9436 30.0372 17.4424 29.7263 17.1704C29.4155 16.8983 28.9657 16.9585 28.7216 17.3048L24.6716 23.0495L22.1864 20.6291C21.889 20.3393 21.4368 20.373 21.1768 20.7047C20.9167 21.0363 20.9471 21.54 21.2447 21.8297V21.8297Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='0.8'
        />
        <path
            d='M34.4692 11.6849C38.4368 15.6659 39.2843 21.7958 36.5458 26.7045L43.9951 34.1542C44.8605 35.018 45.1994 36.278 44.8843 37.4595C44.5691 38.641 43.6477 39.5646 42.4671 39.8823C41.2865 40.2001 40.0261 39.8637 39.1607 39L31.7069 31.5502C26.0354 34.7216 18.8822 33.0476 15.2061 27.6887C11.5299 22.3299 12.5422 15.0519 17.5412 10.9002C22.5402 6.74855 29.8786 7.09116 34.4692 11.6906V11.6849ZM40.7801 37.3802C41.226 37.8241 41.9468 37.8241 42.3927 37.3802C42.6101 37.1707 42.7329 36.8818 42.7329 36.58C42.7329 36.2781 42.6101 35.9892 42.3927 35.7798L35.2418 28.6272C35.0006 28.9153 34.7503 29.1988 34.4783 29.4708C34.2063 29.7429 33.9229 29.9932 33.6349 30.2344L40.7801 37.3802ZM18.3112 27.8487C22.3281 31.8665 28.8407 31.8664 32.8576 27.8487C36.8745 23.8309 36.8745 17.3168 32.8576 13.299C28.8407 9.28123 22.3281 9.28121 18.3112 13.299C14.2972 17.3181 14.2951 23.8293 18.3066 27.851L18.3112 27.8487Z'
            fill={isActive ? '#FFED00' : '#2B2A29'}
        />
    </svg>
)

export default TestingIcon
