import React from 'react'

const Arrows = ({ className }: { className?: string }): React.ReactElement => (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M15.8133 3.66675L15.72 3.67303C15.3844 3.71855 15.1258 4.00619 15.1258 4.35425L15.125 15.9886L12.1734 13.0391L12.0963 12.9726C11.8271 12.773 11.4451 12.7954 11.2011 13.0395C10.9328 13.3082 10.933 13.7434 11.2016 14.0118L15.3301 18.1368L15.4072 18.2033C15.6764 18.4029 16.0583 18.3805 16.3023 18.1364L20.4239 14.0114L20.4903 13.9342C20.69 13.665 20.6676 13.283 20.4234 13.0391L20.3463 12.9726C20.0771 12.773 19.6951 12.7954 19.4511 13.0395L16.5 15.9922L16.5008 4.35425L16.4945 4.26096C16.449 3.92539 16.1614 3.66675 15.8133 3.66675ZM5.69654 3.86813L1.57635 7.98859L1.50979 8.0657C1.31009 8.33486 1.33229 8.71679 1.57638 8.96087L1.65349 9.02743C1.92264 9.22716 2.30458 9.20488 2.54865 8.96084L5.49725 6.01077L5.49786 17.6504L5.50413 17.7437C5.54966 18.0793 5.83731 18.3379 6.18536 18.3379L6.27865 18.3317C6.61422 18.2861 6.87286 17.9985 6.87286 17.6504L6.87225 6.0126L9.82658 8.96138L9.90376 9.02787C10.1731 9.22725 10.555 9.2047 10.7988 8.96033C11.067 8.69155 11.0666 8.25624 10.7978 7.98805L6.6683 3.86758L6.59118 3.80114C6.32203 3.60185 5.94043 3.62423 5.69654 3.86813Z'
            fill='currentColor'
            className={className}
        />
    </svg>
)

export default Arrows
