import { chatTypes } from './actionTypes'
import { ChatActions, ChatState } from './types'

const initialState: ChatState = {
    chatsDetails: undefined,
}

export default (state = initialState, action: ChatActions): ChatState => {
    switch (action.type) {
        case chatTypes.SET_CHAT_LINKS:
            return {
                ...state,
                chatsDetails: action.payload.chatsDetails,
            }
        default:
            return {
                ...state,
            }
    }
}
