import { videoTypes } from './actionTypes'
import { VideoActions, VideoState } from './types'

const initialState: VideoState = {
    videoDetails: undefined,
}

export default (state = initialState, action: VideoActions): VideoState => {
    switch (action.type) {
        case videoTypes.SET_VIDEO:
            return {
                ...state,
                videoDetails: action.payload.videoDetails,
            }
        default:
            return {
                ...state,
            }
    }
}
