import { galleryTypes } from './actionTypes'
import { GalleryItemActions, GalleryItemState } from './types'

const initialState: GalleryItemState = {
    gallery: undefined,
    galleryItem: undefined,
}

export default (
    state = initialState,
    action: GalleryItemActions,
): GalleryItemState => {
    switch (action.type) {
        case galleryTypes.SET_GALLERY:
            return {
                ...state,
                gallery: action.payload.gallery,
            }
        case galleryTypes.SET_GALLERY_ITEM:
            return {
                ...state,
                galleryItem: action.payload.galleryItem,
            }
        default:
            return {
                ...state,
            }
    }
}
