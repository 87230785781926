import React from 'react'

export const PreviewIcon = ({
    isSmall,
    isMedium,
    isActive,
}: {
    isSmall?: boolean
    isActive?: boolean
    isMedium?: boolean
}): React.ReactElement => (
    <svg
        width={isSmall ? '25' : isMedium ? '35' : '46'}
        height={isSmall ? '25' : isMedium ? '35' : '46'}
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M6 36.0979V9.90412C6 7.7442 6 6.66304 6.45357 6.02397C6.84944 5.46621 7.45962 5.1 8.13703 5.01244C8.91289 4.91217 9.86449 5.42074 11.7649 6.43645L36.2702 19.5333L36.2791 19.5373C38.3793 20.6598 39.4299 21.2213 39.7745 21.9697C40.0752 22.6225 40.0752 23.3761 39.7745 24.029C39.4294 24.7784 38.3763 25.3422 36.2702 26.4679L11.7649 39.5648C9.86313 40.5812 8.91317 41.0878 8.13703 40.9875C7.45962 40.9 6.84944 40.5338 6.45357 39.9761C6 39.337 6 38.2578 6 36.0979Z'
            stroke={isActive ? '#FFED00' : '#2B2A29'}
            strokeWidth='2.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
)

export default PreviewIcon
