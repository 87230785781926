import { pushNotificationsTypes } from './actionTypes'
import { PushActions, PushState } from './types'

const initialState: PushState = {
    pushes: undefined,
    pushInfo: undefined,
}

export default (state = initialState, action: PushActions): PushState => {
    switch (action.type) {
        case pushNotificationsTypes.SET_PUSHES:
            return {
                ...state,
                pushes: action.payload.pushes,
            }
        case pushNotificationsTypes.SET_PUSH_BY_ID:
            return {
                ...state,
                pushInfo: action.payload.pushInfo,
            }
        default:
            return {
                ...state,
            }
    }
}
