import { lazy } from 'react'

import { PATH_NEW_LOADER } from 'config/paths'

export const TodoRoutes = [
    {
        id: 1,
        module: 'loader-todo',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.todo.root,
        component: lazy(
            () => import('containers/emulator/pages/todo/TodoPage'),
        ),
    },
    {
        id: 2,
        module: 'loader-todo',
        protected: false,
        exact: true,
        path: PATH_NEW_LOADER.pages.todo.archive,
        component: lazy(
            () =>
                import(
                    // eslint-disable-next-line max-len
                    'containers/emulator/pages/todo/TodoPage'
                ),
        ),
    },
]
