import { informationTypes } from './actionTypes'
import { InformationState, InformationActions } from './types'

const initialState: InformationState = {
    information: undefined,
    informationDetails: undefined,
    relativeDetails: undefined,
}

export default (
    state = initialState,
    action: InformationActions,
): InformationState => {
    switch (action.type) {
        case informationTypes.SET_INFORMATION:
            return {
                ...state,
                information: action.payload.information,
            }
        case informationTypes.SET_INFORMATION_ITEM:
            return {
                ...state,
                informationDetails: action.payload.informationDetails,
            }
        case informationTypes.SET_RELATIVE_INFORMATION:
            return {
                ...state,
                relativeDetails: action.payload.relativeDetails,
            }

        default:
            return {
                ...state,
            }
    }
}
