import { AxiosResponse } from 'axios'
import {
    all,
    call,
    put,
    takeLatest,
    AllEffect,
    ForkEffect,
} from 'redux-saga/effects'
import { sagaErrorHandle } from 'utils/errHandle'
import { getTestAnalyticsById } from '../service/testAnalytics'
import { testAnalyticsTypes } from './actionTypes'
import { FetchTestAnalyticsByIdRequest, ITestAnalytics } from './types'

function* fetchTestAnalyticsByIdSaga(action: FetchTestAnalyticsByIdRequest) {
    try {
        const response: AxiosResponse<ITestAnalytics> = yield call(
            getTestAnalyticsById,
            action.payload,
        )
        if (response.data) {
            yield put({
                type: testAnalyticsTypes.SET_TEST_ANALYTICS,
                payload: { testAnalytics: response.data },
            })
        }
    } catch (error) {
        sagaErrorHandle(action.payload.callback, error)
    }
}

function* testAnalyticsSaga(): Generator<
    AllEffect<ForkEffect<never>>,
    void,
    unknown
> {
    yield all([
        takeLatest(
            testAnalyticsTypes.FETCH_TEST_ANALYTICS_BY_ID,
            fetchTestAnalyticsByIdSaga,
        ),
    ])
}

export default testAnalyticsSaga
